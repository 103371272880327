import FadeTransition from 'components/FadeTransition';
import Tabs from 'components/Tabs';
import HistorySidebarQueries from 'pages/queries/HistorySidebar/HistorySidebarQueries';
import { HistorySidebarProps } from 'pages/queries/HistorySidebar/types';
import { Creator } from 'pages/queries/queries';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const HistorySidebarContent: React.FunctionComponent<HistorySidebarProps> =
  function HistorySidebarContent({
    selectedQueryId,
    selectQuery,
    selectedTab,
  }) {
    const navigate = useNavigate();

    return (
      <>
        <div className="flex-shrink-0 px-4 py-5 flex items-center shadow">
          <h2 className="font-semibold">Query Execution History</h2>
        </div>
        <Tabs
          tabs={[{ name: 'All Queries' }, { name: 'My Queries' }]}
          active={selectedTab === Creator.Me ? 'My Queries' : 'All Queries'}
          onClick={(t) =>
            navigate(
              `/queries/${(t === 'My Queries'
                ? Creator.Me
                : Creator.All
              ).toString()}`,
            )
          }
          space="space-x-0"
          tabPadding="p-4"
        />
        <FadeTransition
          appear
          show={selectedTab === Creator.Me}
          exit={false}
          className="history-sidebar-content h-full"
        >
          {selectedTab === Creator.Me && (
            <HistorySidebarQueries
              tab={Creator.Me}
              selectedQueryId={selectedQueryId}
              selectQuery={selectQuery}
            />
          )}
        </FadeTransition>
        <FadeTransition
          appear
          show={selectedTab === Creator.All}
          exit={false}
          className="history-sidebar-content h-full"
        >
          {selectedTab === Creator.All && (
            <HistorySidebarQueries
              tab={Creator.All}
              selectedQueryId={selectedQueryId}
              selectQuery={selectQuery}
            />
          )}
        </FadeTransition>
      </>
    );
  };

export default HistorySidebarContent;
