import React, { useContext } from 'react';
import SidebarContext from 'context/SidebarContext';
import classNames from 'classnames';
import { MetadataFilter } from 'types/metadata';
import Dropdown, { DropdownOption } from './Dropdown';

interface DropdownInputProps {
  dropdownOptions: DropdownOption<string>[];
  option: MetadataFilter;
  setOption: (option: MetadataFilter) => void;
  handleKeyPress?: (event: React.KeyboardEvent) => void;
}

const DropdownInput: React.FC<DropdownInputProps> = ({
  dropdownOptions,
  handleKeyPress,
  option,
  setOption,
}) => {
  const { rightSidebarOpen } = useContext(SidebarContext);

  return (
    <div className="flex flex-row items-center border border-gray-300 rounded-md">
      <Dropdown
        border="border-transparent"
        className={classNames(rightSidebarOpen ? 'w-32' : 'w-36')}
        focus="focus:border-transparent focus:ring-0"
        rounded="rounded-l-md"
        options={dropdownOptions}
        onChange={(v) =>
          setOption({
            ...option,
            key: v,
          })
        }
        placeholder="Not selected"
      />
      <input
        className={classNames(
          ' h-8 bg-white rounded-r-md border-solid border-0 border-l  focus:border-transparent focus:ring-emerald-500 border-gray-300 focus:z-10 focus:border-green-500 focus:ring-1 text-left text-sm',
          rightSidebarOpen
            ? '2xl:w-80 xl:w-48 lg:w-30 md:w-32 sm:w-20'
            : 'w-80',
        )}
        type="text"
        placeholder="Enter value"
        onKeyDown={handleKeyPress || undefined}
        onChange={(e) =>
          setOption({
            ...option,
            value: e.target.value,
          })
        }
      />
    </div>
  );
};

DropdownInput.defaultProps = {
  handleKeyPress: undefined,
};

export default DropdownInput;
