import React from 'react';

const VIDEO_ASSET_TYPE = 'video/mp4';

function getTimeHash(
  startTime: number | undefined,
  endTime: number | undefined,
): string {
  if (!startTime && !endTime) {
    return '';
  }
  return `#t=${startTime ? Math.floor(startTime / 1000) : ''}${
    endTime ? `,${Math.ceil(endTime / 1000)}` : ''
  }`;
}

const ProtectedVideo: React.FC<{
  url: string;
  posterImageUrl?: string;
  startTime?: number;
  endTime?: number;
  className?: string;
  autoPlay?: boolean;
  muted?: boolean;
}> = function ProtectedImage({
  url,
  posterImageUrl,
  startTime,
  endTime,
  className,
  autoPlay,
  muted,
}) {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      src={`${url}${getTimeHash(startTime, endTime)}`}
      controls
      autoPlay={autoPlay}
      muted={muted}
      // Necessary for poster image to be shown until the video plays
      preload={posterImageUrl ? 'none' : 'auto'}
      width="100%"
      poster={posterImageUrl}
      className={className}
    >
      <source
        src={`${url}${getTimeHash(startTime, endTime)}`}
        type={VIDEO_ASSET_TYPE}
      />
    </video>
  );
};

ProtectedVideo.defaultProps = {
  posterImageUrl: undefined,
  startTime: undefined,
  endTime: undefined,
  className: undefined,
  autoPlay: false,
  muted: false,
};

export default ProtectedVideo;
