import Page404 from 'pages/Page404';

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateDynamicTag from './create/CreateDynamicTag';
import CreateDynamicTagCategory from './create/CreateDynamicTagCategory';
import DynamicTagDetail from './detail';
import DynamicTagAllCategoriesPage from './list/DynamicTagAllCategoriesPage';
import DynamicTagsCategoryPage from './list/DynamicTagsCategoryPage';

const DynamicTagsRouter = function DynamicTagsRouter() {
  return (
    <Routes>
      <Route path="" element={<DynamicTagAllCategoriesPage />} />
      <Route path="/add" element={<CreateDynamicTagCategory />} />
      <Route path="/:categoryId" element={<DynamicTagsCategoryPage />} />
      <Route
        path="/:categoryId/dynamic-tag/add"
        element={<CreateDynamicTag />}
      />
      <Route
        path="/:categoryId/dynamic-tag/:dynamicTagId"
        element={<DynamicTagDetail />}
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default DynamicTagsRouter;
