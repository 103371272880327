/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
} from './Asset';

/**
 * Preview images for the original asset
 * @export
 * @interface PreviewImages
 */
export interface PreviewImages {
    /**
     * 
     * @type {Asset}
     * @memberof PreviewImages
     */
    thumb: Asset;
    /**
     * 
     * @type {Asset}
     * @memberof PreviewImages
     */
    small: Asset;
    /**
     * 
     * @type {Asset}
     * @memberof PreviewImages
     */
    regular: Asset;
    /**
     * 
     * @type {Asset}
     * @memberof PreviewImages
     */
    full: Asset;
}

export function PreviewImagesFromJSON(json: any): PreviewImages {
    return PreviewImagesFromJSONTyped(json, false);
}

export function PreviewImagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviewImages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'thumb': AssetFromJSON(json['thumb']),
        'small': AssetFromJSON(json['small']),
        'regular': AssetFromJSON(json['regular']),
        'full': AssetFromJSON(json['full']),
    };
}

export function PreviewImagesToJSON(value?: PreviewImages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'thumb': AssetToJSON(value.thumb),
        'small': AssetToJSON(value.small),
        'regular': AssetToJSON(value.regular),
        'full': AssetToJSON(value.full),
    };
}

