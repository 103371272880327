import CodeMirrorExt, {
  EditorState,
  EditorView,
  ViewUpdate,
} from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import React, { MutableRefObject, useRef } from 'react';
import Resizer from 'components/Resizer';
import './CodeMirror.scss';

interface CodeMirrorProps {
  value?: string | undefined;
  onChange?: (val: string) => void;
  onUpdate?: () => void;
  onResize?: (height: number) => void;
  height?: string;
  defaultHeight?: string;
  editable?: boolean;
  heightPreferenceKey?: string;
  stateRef: MutableRefObject<EditorState | null | undefined>;
  viewRef: MutableRefObject<EditorView | null | undefined>;
}

const LS_HEIGHT = 'coactive-cm-height';

const CodeMirror: React.FunctionComponent<CodeMirrorProps> =
  function CodeMirror({
    onChange,
    onUpdate,
    value,
    onResize,
    defaultHeight,
    height,
    editable,
    heightPreferenceKey,
    stateRef,
    viewRef,
  }) {
    const sRef = stateRef;
    const vRef = viewRef;
    const valueRef = useRef<string>('');
    const containerRef = useRef<HTMLElement | null>();
    const heightRef = useRef<number>();

    let heightPreference: string = defaultHeight || '';
    if (heightPreferenceKey) {
      heightPreference =
        localStorage.getItem(heightPreferenceKey) || heightPreference;
    }
    return (
      <div
        ref={(r) => {
          containerRef.current = r;
        }}
        className="coactive-cm-container"
        style={{
          width: '100%',
          height: height || heightPreference,
        }}
      >
        <CodeMirrorExt
          ref={(r) => {
            sRef.current = r?.state;
            vRef.current = r?.view;
          }}
          value={value || ''}
          className="coactive-cm shadow-md text-sm"
          extensions={[
            sql({
              upperCaseKeywords: true,
              schema: { Flowers: ['color'] },
            }),
          ]}
          onChange={(val: string) => {
            valueRef.current = val;
            onChange?.(val);
          }}
          onUpdate={(e: ViewUpdate) => {
            sRef.current = e.state;
            vRef.current = e.view;
            onUpdate?.();
          }}
          editable={editable}
        />
        <Resizer
          type="bottom"
          element={containerRef}
          onResize={(h: number) => {
            heightRef.current = h;
            if (heightPreferenceKey) {
              localStorage.setItem(heightPreferenceKey, `${h}px`);
            }
            onResize?.(h);
          }}
        />
      </div>
    );
  };

CodeMirror.defaultProps = {
  value: undefined,
  onChange: undefined,
  onUpdate: undefined,
  onResize: undefined,
  height: undefined,
  defaultHeight: undefined,
  editable: true,
  heightPreferenceKey: LS_HEIGHT,
};

export default CodeMirror;
