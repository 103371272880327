/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetMetadata,
    AssetMetadataFromJSON,
    AssetMetadataFromJSONTyped,
    AssetMetadataToJSON,
} from './AssetMetadata';
import {
    FullConceptResponse,
    FullConceptResponseFromJSON,
    FullConceptResponseFromJSONTyped,
    FullConceptResponseToJSON,
} from './FullConceptResponse';

/**
 * Abstract base class for generic types.
 * 
 * A generic type is typically declared by inheriting from
 * this class parameterized with one or more type variables.
 * For example, a generic mapping type might be defined as::
 * 
 *   class Mapping(Generic[KT, VT]):
 *       def __getitem__(self, key: KT) -> VT:
 *           ...
 *       # Etc.
 * 
 * This class can then be used as follows::
 * 
 *   def lookup_name(mapping: Mapping[KT, VT], key: KT, default: VT) -> VT:
 *       try:
 *           return mapping[key]
 *       except KeyError:
 *           return default
 * @export
 * @interface ConceptTableRow
 */
export interface ConceptTableRow {
    /**
     * Row index
     * @type {number}
     * @memberof ConceptTableRow
     */
    row: number;
    /**
     * 
     * @type {AssetMetadata}
     * @memberof ConceptTableRow
     */
    asset?: AssetMetadata;
    /**
     * 
     * @type {FullConceptResponse}
     * @memberof ConceptTableRow
     */
    data: FullConceptResponse;
}

export function ConceptTableRowFromJSON(json: any): ConceptTableRow {
    return ConceptTableRowFromJSONTyped(json, false);
}

export function ConceptTableRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConceptTableRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'row': json['row'],
        'asset': !exists(json, 'asset') ? undefined : AssetMetadataFromJSON(json['asset']),
        'data': FullConceptResponseFromJSON(json['data']),
    };
}

export function ConceptTableRowToJSON(value?: ConceptTableRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'row': value.row,
        'asset': AssetMetadataToJSON(value.asset),
        'data': FullConceptResponseToJSON(value.data),
    };
}

