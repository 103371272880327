import React, {
  useContext,
  createContext,
  useMemo,
  PropsWithChildren,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import getApiConf from 'utils/ApiConfiguration';
import { ConfigurationParameters } from 'api/generated';
import useLoginWithRedirect from 'hooks/useLoginWithRedirect';

type Constructor<T> = new (...args: any[]) => T;

export interface APIContextState {
  initializeAPI: <T>(
    Api: Constructor<T>,
    configuration?: ConfigurationParameters,
    authenticated?: boolean,
  ) => Promise<T>;
}

const APIContext = createContext({
  initializeAPI: function initializeAPI<T>(
    Api: Constructor<T>,
    configuration?: ConfigurationParameters,
  ): Promise<T> {
    return Promise.resolve(new Api(configuration));
  },
} as APIContextState);

export const APIContextWrapper: React.FC<PropsWithChildren> =
  function APIContextWrapper({ children }) {
    const { isLoading, getAccessTokenSilently } = useAuth0();
    const loginWithRedirect = useLoginWithRedirect();

    const value = useMemo(() => {
      const initializeAPI = async function initializeAPI<T>(
        Api: Constructor<T>,
        configuration: ConfigurationParameters = {},
        authenticated: boolean = true,
      ): Promise<T> {
        if (!authenticated) {
          return new Api(getApiConf({ ...configuration }));
        }
        try {
          const token = await getAccessTokenSilently();
          const conf = getApiConf({ accessToken: token, ...configuration });
          return new Api(conf);
        } catch (error) {
          loginWithRedirect();
        }
        throw Error();
      };
      return { initializeAPI };
    }, [getAccessTokenSilently, isLoading]);

    return <APIContext.Provider value={value}>{children}</APIContext.Provider>;
  };

export function useAPIContext() {
  return useContext(APIContext);
}

export default APIContext;
