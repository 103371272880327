import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
} from 'react';
import classNames from 'classnames';

interface SectionHeaderProps extends PropsWithChildren<{}> {
  title: string;
  className?: string;
  icon?: FunctionComponent<{ className: string }>;
  actions?: ReactElement<any, any> | undefined;
}

const SectionHeader: React.FunctionComponent<SectionHeaderProps> =
  function SectionHeader({ actions, className, title, children, ...props }) {
    return (
      <div
        className={classNames(
          'my-4 md:flex md:items-center md:justify-between',
          className,
        )}
      >
        <div className="flex flex-1 min-w-0">
          <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-xl sm:truncate flex items-center">
            {props.icon && <props.icon className="mr-2 h-5 w-5" />}
            {title}
          </h2>
          {children}
        </div>
        {actions && (
          <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
            {actions}
          </div>
        )}
      </div>
    );
  };

SectionHeader.defaultProps = {
  actions: undefined,
  className: undefined,
  icon: undefined,
};

export default SectionHeader;
