/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface Auth0OrgInvitationRecipient
 */
export interface Auth0OrgInvitationRecipient {
    /**
     * The invited email
     * @type {string}
     * @memberof Auth0OrgInvitationRecipient
     */
    email: string;
    /**
     * The name of the invitee
     * @type {string}
     * @memberof Auth0OrgInvitationRecipient
     */
    name?: string | null;
}

export function Auth0OrgInvitationRecipientFromJSON(json: any): Auth0OrgInvitationRecipient {
    return Auth0OrgInvitationRecipientFromJSONTyped(json, false);
}

export function Auth0OrgInvitationRecipientFromJSONTyped(json: any, ignoreDiscriminator: boolean): Auth0OrgInvitationRecipient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function Auth0OrgInvitationRecipientToJSON(value?: Auth0OrgInvitationRecipient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'name': value.name,
    };
}

