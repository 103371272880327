import { DataSourceTypeEnum, UpdateDatasetUpdateResponse } from 'api/generated';
import { useAddAssetsNavigationContext } from 'pages/datasets/add-assets/context/AddAssetsNavigationContext';
import {
  invalidateDatasetUpdateQueries,
  useCreateDatasetUpdateProgress,
  useGetDatasetUpdate,
  useUpdateDatasetUpdateProgress,
} from 'queries/dataset-update';
import { invalidateDatasetQueries, useGetDatasetById } from 'queries/datasets';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

export interface AddAssetsActionsContextState {
  accessError: string | undefined;
  error: string | undefined;
  inviteTeammateToContinue: (teammateEmail: string) => Promise<void>;
  provideOtherDataSource: (dataSource: string) => Promise<void>;
  saveCurrentUsersProgress: (updates: {
    dataSourceType?: DataSourceTypeEnum;
    dataPath?: string | null;
    credentialsId?: string;
  }) => Promise<void>;
}

const AddAssetsActionsContext = createContext({
  accessError: undefined,
  error: undefined,
  inviteTeammateToContinue: () => Promise.reject(),
  provideOtherDataSource: () => Promise.reject(),
  saveCurrentUsersProgress: () => Promise.reject(),
} as AddAssetsActionsContextState);

export const AddAssetsActionsContextWrapper: React.FC<PropsWithChildren> =
  function AddAssetsActionsContextWrapper({ children }) {
    const { activeStep, flowEndReason, setFlowEndReason } =
      useAddAssetsNavigationContext();
    const [error, setError] = useState<string>();
    const [accessError, setAccessError] = useState<string>();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { datasetId, datasetUpdateId } = useParams();
    const { refetch: refetchDatasetUpdate } =
      useGetDatasetUpdate(datasetUpdateId);
    const { refetch: refetchDataset } = useGetDatasetById(datasetId);
    const { mutateAsync: createDatasetUpdate } =
      useCreateDatasetUpdateProgress();
    const { mutateAsync: updateDatasetUpdate } =
      useUpdateDatasetUpdateProgress();

    // Reset API error state when the step changes
    useEffect(() => {
      setError(undefined);
      setAccessError(undefined);
    }, [activeStep]);

    const createOrUpdateDatasetUpdate = useCallback(
      (updates: {
        dataSourceType?: DataSourceTypeEnum;
        dataPath?: string | null;
        credentialsId?: string;
        teammateEmail?: string;
        otherDataSource?: string;
      }) => {
        setError(undefined);
        setAccessError(undefined);
        const onSuccess = async (response: UpdateDatasetUpdateResponse) => {
          await invalidateDatasetUpdateQueries(
            queryClient,
            datasetId,
            datasetUpdateId,
          );
          if (response.testDataAccessError) {
            setAccessError(response.testDataAccessError);
          }
          if (
            !flowEndReason &&
            updates.teammateEmail &&
            response.invitedUserEmail
          ) {
            setFlowEndReason('teammate invited');
          } else if (
            !flowEndReason &&
            updates.otherDataSource &&
            response.unsupportedDataSource
          ) {
            setFlowEndReason('other data source');
          } else if (!flowEndReason && response.testDataAccessSuccess) {
            setFlowEndReason('configured');
            invalidateDatasetQueries(queryClient, datasetId);
          }
          navigate(
            `/datasets/${response.datasetId}/add-assets/${response.id}`,
            { replace: true },
          );

          if (datasetUpdateId) {
            refetchDatasetUpdate();
          }
          refetchDataset();
        };
        const onError = async (e: any) => {
          setError((await e.response.json()).detail);
        };
        if (datasetUpdateId && datasetId) {
          updateDatasetUpdate(
            {
              datasetUpdateId,
              dataPath: updates.dataPath,
              dataSourceType: updates.dataSourceType,
              credentialsId: updates.credentialsId,
              invitedUserEmail: updates.teammateEmail,
              unsupportedDataSource: updates.otherDataSource,
            },
            { onSuccess, onError },
          );
        } else if (datasetId) {
          createDatasetUpdate(
            {
              datasetId,
              dataPath: updates.dataPath,
              dataSourceType: updates.dataSourceType,
              invitedUserEmail: updates.teammateEmail,
              unsupportedDataSource: updates.otherDataSource,
            },
            { onSuccess, onError },
          );
        }
      },
      [datasetId, datasetUpdateId, flowEndReason, setFlowEndReason],
    );

    const inviteTeammateToContinue = useCallback(
      async (teammateEmail: string) =>
        createOrUpdateDatasetUpdate({ teammateEmail }),
      [createOrUpdateDatasetUpdate],
    );

    const provideOtherDataSource = useCallback(
      async (dataSource: string) =>
        createOrUpdateDatasetUpdate({
          otherDataSource: dataSource,
        }),
      [createOrUpdateDatasetUpdate],
    );

    const saveCurrentUsersProgress = useCallback(
      async (updates: {
        dataSourceType?: DataSourceTypeEnum;
        dataPath?: string | null;
        credentialsId?: string;
      }) => createOrUpdateDatasetUpdate({ ...updates }),
      [createOrUpdateDatasetUpdate],
    );

    const value = useMemo(
      () => ({
        accessError,
        error,
        inviteTeammateToContinue,
        provideOtherDataSource,
        saveCurrentUsersProgress,
      }),
      [
        accessError,
        error,
        inviteTeammateToContinue,
        provideOtherDataSource,
        saveCurrentUsersProgress,
      ],
    );

    return (
      <AddAssetsActionsContext.Provider value={value}>
        {children}
      </AddAssetsActionsContext.Provider>
    );
  };

export function useAddAssetsActionsContext() {
  return useContext(AddAssetsActionsContext);
}

export default AddAssetsActionsContext;
