import classNames from 'classnames';
import React, { useState } from 'react';

interface SwitchComponentOption {
  icon: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>;
  label: string;
  onClick: () => void;
}

interface SwitchComponentProps {
  options: Array<SwitchComponentOption>;
  selectedOptionIndex?: number;
}

export const SwitchComponent: React.FunctionComponent<SwitchComponentProps> = ({
  options,
  selectedOptionIndex,
}) => {
  const [selectedItemIdx, setSelectedItemIdx] = useState<number>(
    selectedOptionIndex !== undefined ? selectedOptionIndex : 0,
  );

  const onClick = (idx: number, onSelect: () => void): void => {
    if (selectedItemIdx === idx) return;
    setSelectedItemIdx(idx);
    onSelect();
  };

  return (
    <div className="p-1 w-fit-content border-[1px] rounded border-gray-200">
      {options.map((option, idx) => {
        const isSelected = idx === selectedItemIdx;
        const Icon = option.icon;

        return (
          <button
            className={classNames(
              {
                'border-solid border-green-400 bg-green-100': isSelected,
                'border-none hover:bg-slate-50': !isSelected,
              },
              'rounded-md text-sm border-[1px] text-gray-600',
            )}
            onClick={() => onClick(idx, option.onClick)}
            type="button"
          >
            <span className="inline-flex items-center justify-center font-medium focus:outline-none transition-all relative px-4 py-2">
              <Icon className="-ml-0.5 h-5 w-5 mr-2" />
              {option.label}
            </span>
          </button>
        );
      })}
    </div>
  );
};

SwitchComponent.defaultProps = {
  selectedOptionIndex: undefined,
};
