import { scaleLog } from 'd3-scale';
import colors from 'tailwindcss/colors';

export const LOG_SCALE = scaleLog().base(10);
export const TICK_REF_LINE_COLOR = colors.slate[300];
export const LABEL_AXIS_COLOR = colors.gray[600];
export const BAR_CHART_MARGINS = {
  right: 70,
  left: 125,
};
export const Y_AXIS_PADDING = { top: 36, bottom: 36 };
export const BAR_SIZE = 35;

// creates an array of powers of 10 with x amount in this case 8
const POWERS_OF_10_COUNT = 8;

export const powersOf10List = Array.from(
  { length: POWERS_OF_10_COUNT },
  (_, index) => 10 ** (index + 1),
);
