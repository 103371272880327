import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import Clipboard from 'react-clipboard.js';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import { v4 as uuidv4 } from 'uuid';
import Button, { ButtonStyle } from 'components/Button';
import { Square2StackIcon } from '@heroicons/react/24/outline';

const TOOLTIP_CLOSE_ON_COPY_DELAY = 1000;

interface CopyActionButtonProps {
  id: string;
  text: string;
  className?: string;
  tooltipText?: string;
  buttonStyle?: ButtonStyle;
  icon?: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>;
  textColor?: string;
  textHoverColor?: string;
}

const CopyActionButton: FunctionComponent<
  PropsWithChildren<CopyActionButtonProps>
> = function ClipboardIcon({
  id,
  text,
  className,
  tooltipText,
  buttonStyle,
  icon,
  textColor,
  textHoverColor,
  children,
}) {
  const [showCopyText, setShowCopyText] = useState({
    id: uuidv4(),
    show: false,
  });
  const fadeCopyTooltipTimeoutRef = useRef<any>();
  const containerRef = useRef<any>();

  useEffect(() => {
    if (showCopyText.show) {
      fadeCopyTooltipTimeoutRef.current = setTimeout(() => {
        setShowCopyText({ id: uuidv4(), show: false });
      }, TOOLTIP_CLOSE_ON_COPY_DELAY);
    } else {
      clearTimeout(fadeCopyTooltipTimeoutRef.current);
    }
  }, [showCopyText.show]);

  return (
    <div
      ref={(ref) => {
        containerRef.current = ref;
      }}
      className={classNames('relative outline-offset-[.22rem]', className)}
    >
      <Clipboard
        component="div"
        className="transition-all inline-block"
        data-clipboard-text={text}
        data-tooltip-id={`copy-${id}`}
        data-tooltip-content={showCopyText.show ? 'Copied!' : tooltipText}
        data-tooltip-place="bottom"
        onSuccess={() => setShowCopyText((ct) => ({ ...ct, show: true }))}
        onClick={(e) => e.stopPropagation()}
      >
        <Button
          icon={icon}
          onClick={() => null}
          className="whitespace-nowrap"
          buttonStyle={buttonStyle}
          textColor={textColor}
          textHoverColor={textHoverColor}
        >
          {children}
        </Button>
      </Clipboard>
      <Tooltip
        key={showCopyText.id}
        id={`copy-${id}`}
        isOpen={showCopyText.show ? true : undefined}
      >
        <span>{showCopyText.show ? 'Copied!' : tooltipText}</span>
      </Tooltip>
    </div>
  );
};

CopyActionButton.defaultProps = {
  className: undefined,
  tooltipText: 'Copy to Clipboard',
  buttonStyle: 'primary',
  icon: Square2StackIcon,
  textColor: undefined,
  textHoverColor: undefined,
};

export default CopyActionButton;
