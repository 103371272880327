import React from 'react';
import classNames from 'classnames';
import createPlaceholderArray from 'utils/createPlaceholderArray';
import LoadingBlock from 'components/LoadingBlock';

interface LoadingTableProps {
  columns?: number;
  rows?: number;
}

const LoadingTable: React.FC<LoadingTableProps> = function LoadingTable({
  columns,
  rows,
}) {
  const numColumns = columns ?? 3;
  const numRows = rows ?? 5;
  return (
    <div className="flex flex-col overflow-x-hidden overflow-y-hidden">
      <div className="-my-2 overflow-x-hidden sm:-mx-6 lg:-mx-8">
        <div className="flex py-3 align-middle sm:px-6 lg:px-8">
          <div className="flex overflow-x-hidden relative shadow bg-white border-b border-gray-200 sm:rounded-lg w-full">
            <table
              className={classNames('min-w-full divide-y divide-gray-200')}
            >
              <thead className="bg-gray-50">
                <tr>
                  {createPlaceholderArray(numColumns).map((key) => (
                    <th
                      key={key}
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider max-w-xs"
                    >
                      <LoadingBlock className="h-2 w-[40%]" />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {createPlaceholderArray(numRows).map((key) => (
                  <tr key={key}>
                    {createPlaceholderArray(numColumns).map((key2) => (
                      <td
                        key={key2}
                        className="whitespace-nowrap text-sm font-medium text-ellipsis overflow-hidden max-w-xs px-4 py-5"
                      >
                        <LoadingBlock
                          style={{ width: `${Math.random() * 60 + 40}%` }}
                          className="h-3"
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

LoadingTable.defaultProps = {
  columns: 3,
  rows: 5,
};

export default LoadingTable;
