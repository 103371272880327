/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { QueryResponseUI, QueryStatus } from 'api/generated';
import classNames from 'classnames';
import ClipboardIconButton from 'components/ClipboardIconButton';
import DeleteIconButton from 'components/DeleteIconButton';
import RelativeTimeEyebrow from 'components/RelativeTimeEyebrow';
import StopIconButton from 'components/StopIconButton';
import useDuration from 'hooks/useDuration';
import { DateTime } from 'luxon';
import { Creator, useGetQueryWithoutResults } from 'pages/queries/queries';
import React, { useEffect, useRef, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

interface QueryHistoryRowProps {
  active: boolean;
  query: QueryResponseUI;
  onSelected: (queryId: string, error: boolean) => void;
  stopMutation: any;
  deleteQueryMutation: any;
  creator: Creator;
}

const propsAreEqual = (
  prevProps: QueryHistoryRowProps,
  nextProps: QueryHistoryRowProps,
) => {
  if (
    prevProps.active !== nextProps.active ||
    prevProps.query.status !== nextProps.query.status
  ) {
    return false;
  }
  return true;
};
const QueryHistoryRow: React.FunctionComponent<QueryHistoryRowProps> =
  React.memo(
    ({
      active,
      onSelected,
      query,
      stopMutation,
      deleteQueryMutation,
      creator,
    }) => {
      const { data: fetchedQuery } = useGetQueryWithoutResults(query.queryId, {
        enabled: false,
        notifyOnChangeProps: ['data'],
      });
      const containerRef = useRef<HTMLLIElement | null>();

      const [latestQuery, setLatestQuery] = useState<QueryResponseUI>(query);

      useEffect(() => {
        if (fetchedQuery) {
          setLatestQuery(fetchedQuery);
        }
      }, [fetchedQuery]);

      useEffect(() => {
        if (query) {
          setLatestQuery(query);
        }
      }, [query]);

      useEffect(() => {
        if (active && containerRef.current) {
          containerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, []);

      const { status, isActive, endDt, createdDt, startDt } = latestQuery;

      const timeQueued = useDuration(createdDt, startDt ?? endDt);
      const timeRunning = useDuration(startDt, endDt);
      const end = endDt && DateTime.fromISO(endDt, { zone: 'utc' });

      return (
        <li
          ref={(ref) => {
            containerRef.current = ref;
          }}
          className={classNames(
            'first:border-t border-b border-gray-200 p-4 cursor-pointer group',
            active && 'bg-blue-100',
          )}
          onClick={() => onSelected(query.queryId, query.status === 'Error')}
        >
          <dl className="mt-1 flex-grow flex flex-col justify-between">
            <dt className="sr-only">Date Executed</dt>
            <dd className="text-gray-500 text-xs mb-2">
              <RelativeTimeEyebrow timestamp={createdDt} />
            </dd>
            <dt className="sr-only">Dataset</dt>
            <dd className="text-gray-500 text-sm font-medium mb-2">
              {query.dataset.name}
            </dd>
            <div className="relative">
              <div>
                <dt className="sr-only">Query</dt>
                <dd className="text-gray-900 text-sm font-medium">
                  <LinesEllipsis
                    text={query.query}
                    maxLine="2"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </dd>
              </div>
            </div>
            <dd className="text-gray-500 text-sm mt-2">
              {status === QueryStatus.Queued && (
                <span>
                  {timeQueued
                    ? `Time queued: ${timeQueued.toHuman()}`
                    : 'Waiting'}
                </span>
              )}
              {timeRunning && isActive && (
                <span>Time running: {timeRunning.toHuman()}</span>
              )}
              {timeRunning && status === QueryStatus.Stopped && (
                <span>Stopped after {timeRunning.toHuman()}</span>
              )}
              {timeRunning && status !== QueryStatus.Stopped && end && (
                <span>Finished in {timeRunning.toHuman()}</span>
              )}
            </dd>
            <div className="flex flex-row justify-between mt-3">
              <dt className="sr-only">Status</dt>
              <dd>
                <span
                  className={classNames(
                    'px-2 py-1  text-xs font-medium rounded-full',
                    status === QueryStatus.Running && 'animate-pulse',
                    (status === QueryStatus.Running ||
                      status === QueryStatus.Queued) &&
                      active &&
                      'bg-blue-200 text-blue-900',
                    (status === QueryStatus.Running ||
                      status === QueryStatus.Queued) &&
                      !active &&
                      'bg-blue-100 text-blue-800',
                    status === QueryStatus.Stopping &&
                      active &&
                      'bg-orange-200 text-orange-900',
                    status === QueryStatus.Stopping &&
                      !active &&
                      'bg-orange-100 text-orange-800',
                    status === QueryStatus.Stopped &&
                      active &&
                      'bg-white text-gray-800',
                    status === QueryStatus.Stopped &&
                      !active &&
                      'bg-gray-100 text-gray-800',
                    status === QueryStatus.Complete &&
                      'bg-green-100 text-green-800',
                    status === QueryStatus.Error && 'bg-red-100 text-red-800',
                  )}
                >
                  {status}
                </span>
              </dd>
              <div className="flex flex-row items-center justify-end">
                {(status === QueryStatus.Running ||
                  status === QueryStatus.Queued) &&
                  creator === Creator.Me && (
                    <StopIconButton
                      id={query.queryId}
                      status={status}
                      className="opacity-0 group-hover:opacity-100 transition-opacity"
                      onClick={(e) => {
                        e.stopPropagation();
                        stopMutation.mutate({ queryId: query.queryId });
                      }}
                    />
                  )}
                {!isActive && creator === Creator.Me && (
                  <DeleteIconButton
                    id={query.queryId}
                    className="opacity-0 group-hover:opacity-100 transition-opacity"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteQueryMutation.mutate({ queryId: query.queryId });
                    }}
                  />
                )}
                {query && (
                  <ClipboardIconButton
                    id={query.queryId}
                    text={query.query}
                    className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity"
                    padding="p-0"
                    hoverColor="hover:text-blue-600"
                    size="medium"
                  />
                )}
                <div />
              </div>
            </div>
          </dl>
          <button
            type="button"
            className="sr-only"
            onClick={() => onSelected(query.queryId, query.status === 'Error')}
          >
            View Query
          </button>
        </li>
      );
    },
    propsAreEqual,
  );

export default QueryHistoryRow;
