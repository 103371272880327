import Stepper from 'components/Stepper';
import { useAddAssetsNavigationContext } from 'pages/datasets/add-assets/context/AddAssetsNavigationContext';
import FooterToolbar from 'pages/datasets/components/FooterToolbar';
import { useIsDatasetUpdateMutatingOrFetching } from 'queries/dataset-update';
import { useIsDatasetMutatingOrFetching } from 'queries/datasets';
import React, { PropsWithChildren, useMemo } from 'react';

interface AddAssetsViewContainerProps {
  title: string | React.ReactElement;
  description?: string | React.ReactElement;
  error?: string;
  icon?: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>;
  isCreate?: boolean;
}

/**
 * A container for the left-panel of the create dataset/add assets flow.
 */
const AddAssetsViewContainer: React.FC<
  PropsWithChildren<AddAssetsViewContainerProps>
> = function AddAssetsViewContainer({
  children,
  description,
  error,
  icon,
  isCreate,
  title,
}) {
  const { activeStep, next, previous } = useAddAssetsNavigationContext();
  const datasetUpdateLoading = useIsDatasetUpdateMutatingOrFetching();
  const datasetLoading = useIsDatasetMutatingOrFetching();

  const Icon = icon;
  const steps = useMemo(
    () =>
      isCreate ? ['Name', 'Data Source', 'Access'] : ['Data Source', 'Access'],
    [isCreate],
  );
  const stepperIndex = activeStep ? steps.indexOf(activeStep) : -1;
  return (
    <div className="pt-16 px-8 pb-8 mx-auto max-w-screen-lg w-full">
      <div className="flex flex-row">
        {activeStep && stepperIndex >= 0 && (
          <div className="pr-20">
            <Stepper activeStep={stepperIndex} steps={steps} />
          </div>
        )}
        {Icon && (
          <div className="pr-6">
            <div className="mt-[-0.4rem] h-10 w-10 flex items-center justify-center bg-green-200 text-green-500 rounded-full">
              <Icon className="w-5 h-5 [&>path]:stroke-[2] [&>path]:stroke-green-500" />
            </div>
          </div>
        )}
        <div className="grow">
          <h1 className="text-xl font-medium">{title}</h1>
          {typeof description === 'string' ? <p>{description}</p> : description}
          {children}
          {(next || previous) && activeStep !== 'Confirmation' && (
            <FooterToolbar
              previous={previous}
              next={next}
              error={error}
              loading={datasetUpdateLoading || datasetLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

AddAssetsViewContainer.defaultProps = {
  description: undefined,
  icon: undefined,
  error: undefined,
  isCreate: false,
};

export default AddAssetsViewContainer;
