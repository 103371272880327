import { Permissions } from 'api/generated';
import classNames from 'classnames';
import React, { Suspense } from 'react';
import { NavLink } from 'react-router-dom';

export interface NavigationItem {
  permissions?: Array<Permissions>;
  title: string;
  href: string;
  icon?: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>;
}

interface VerticalNavigationProps {
  items: Array<NavigationItem>;
}

const VerticalNavigation: React.FC<VerticalNavigationProps> = ({ items }) => (
  <div className="w-64" aria-label="Sidebar">
    <div className="px-0 py-4 overflow-y-auto rounded-sm">
      <ul className="space-y-2">
        {items.map((item) => {
          const Icon = item.icon;
          return (
            <NavLink
              key={item.href}
              to={item.href}
              className={({ isActive }) =>
                classNames(
                  isActive ? 'bg-gray-200' : '',
                  'flex items-center p-2 text-sm font-normal text-gray-900 rounded-lg hover:bg-gray-300',
                )
              }
            >
              <Suspense fallback={<div>Loading...</div>}>
                {Icon && (
                  <Icon className="w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 mr-2" />
                )}
              </Suspense>
              {item.title}
            </NavLink>
          );
        })}
      </ul>
    </div>
  </div>
);

export default VerticalNavigation;
