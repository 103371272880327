/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConceptParameter,
    ConceptParameterFromJSON,
    ConceptParameterFromJSONTyped,
    ConceptParameterToJSON,
} from './ConceptParameter';
import {
    MetadataParameter,
    MetadataParameterFromJSON,
    MetadataParameterFromJSONTyped,
    MetadataParameterToJSON,
} from './MetadataParameter';
import {
    SQLOperand,
    SQLOperandFromJSON,
    SQLOperandFromJSONTyped,
    SQLOperandToJSON,
} from './SQLOperand';

/**
 * 
 * @export
 * @interface NoCodeQueryClause
 */
export interface NoCodeQueryClause {
    /**
     * A list of nested parameters to filter on
     * @type {Array<NoCodeQueryClause>}
     * @memberof NoCodeQueryClause
     */
    parameters?: Array<NoCodeQueryClause> | null;
    /**
     * A list of concept parameters to filter on
     * @type {Array<ConceptParameter>}
     * @memberof NoCodeQueryClause
     */
    conceptParameters?: Array<ConceptParameter> | null;
    /**
     * A list of metadata parameters to filter on
     * @type {Array<MetadataParameter>}
     * @memberof NoCodeQueryClause
     */
    metadataParameters?: Array<MetadataParameter> | null;
    /**
     * 
     * @type {SQLOperand}
     * @memberof NoCodeQueryClause
     */
    operand?: SQLOperand;
}

export function NoCodeQueryClauseFromJSON(json: any): NoCodeQueryClause {
    return NoCodeQueryClauseFromJSONTyped(json, false);
}

export function NoCodeQueryClauseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoCodeQueryClause {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : (json['parameters'] === null ? null : (json['parameters'] as Array<any>).map(NoCodeQueryClauseFromJSON)),
        'conceptParameters': !exists(json, 'conceptParameters') ? undefined : (json['conceptParameters'] === null ? null : (json['conceptParameters'] as Array<any>).map(ConceptParameterFromJSON)),
        'metadataParameters': !exists(json, 'metadataParameters') ? undefined : (json['metadataParameters'] === null ? null : (json['metadataParameters'] as Array<any>).map(MetadataParameterFromJSON)),
        'operand': !exists(json, 'operand') ? undefined : SQLOperandFromJSON(json['operand']),
    };
}

export function NoCodeQueryClauseToJSON(value?: NoCodeQueryClause | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': value.parameters === undefined ? undefined : (value.parameters === null ? null : (value.parameters as Array<any>).map(NoCodeQueryClauseToJSON)),
        'conceptParameters': value.conceptParameters === undefined ? undefined : (value.conceptParameters === null ? null : (value.conceptParameters as Array<any>).map(ConceptParameterToJSON)),
        'metadataParameters': value.metadataParameters === undefined ? undefined : (value.metadataParameters === null ? null : (value.metadataParameters as Array<any>).map(MetadataParameterToJSON)),
        'operand': SQLOperandToJSON(value.operand),
    };
}

