import { AssetResponse } from 'api/generated';
import classNames from 'classnames';
import Button from 'components/Button';
import ProtectedImage from 'components/Image/ProtectedImage';
import React, { JSX, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ClipLoader from 'react-spinners/ClipLoader';
import colors from 'tailwindcss/colors';
import './index.scss';
import { ColumnCount } from 'types/grids';
import { createColumnCountClass } from 'utils/GridUtils';
import { UnifiedAssetResponse } from 'types/image';
import Tile from './Tile';

type ImageGridType = 'cascading' | 'uniform';

interface ImageGridProps<T> {
  columnCount?: ColumnCount;
  images: (AssetResponse | undefined)[] | undefined;
  data?: { [cid: string]: T };
  type?: ImageGridType;
  effects?: string[];
  selected?: AssetResponse[];
  onClick?: (img: AssetResponse) => void;
  popoverComponent?: React.FC<{ image: AssetResponse }>;
  popoverClassName?: string;
  fetchNextImages?: () => void;
  hasMore?: boolean;
  isLoadingMore?: boolean;
  loadMoreButton?: boolean | string;
  prefix?: JSX.Element;
  detailComponent?: React.FC<{ data: T | undefined }>;
  selectStyle?: 'numbered' | 'default';
  tileIcon?: JSX.Element;
  tileButton?: (img: AssetResponse) => JSX.Element;
  tileFooter?: (img: UnifiedAssetResponse) => JSX.Element;
  disabledTiles?: AssetResponse[];
  disabledMessage?: string;
  // Prop for temporary fix for column count issue on dynamic tags
  placeholders?: boolean;
  dataTestId?: string;
}

const ImageGrid: React.FunctionComponent<ImageGridProps<any>> =
  function ImageGrid({
    columnCount,
    selected,
    images,
    data,
    type,
    effects,
    onClick,
    popoverComponent,
    popoverClassName,
    fetchNextImages,
    hasMore,
    isLoadingMore,
    loadMoreButton,
    detailComponent,
    selectStyle,
    tileFooter,
    prefix,
    tileIcon,
    disabledTiles,
    disabledMessage,
    placeholders,
    tileButton,
    dataTestId,
  }) {
    const orderMap = useMemo(() => {
      const map = {};
      selected?.forEach((s, idx) => {
        if (s) {
          map[s.coactiveImageId] = idx;
        }
      });
      return map;
    }, [selected]);

    const imagesList = images?.map((image, idx) => {
      const key =
        image?.coactiveImageId ?? image?.previewImages.thumb.url ?? idx;
      if (type === 'cascading' && image) {
        return (
          <ProtectedImage
            key={key}
            url={image.previewImages.thumb.url}
            sensitive={image.sensitive}
            coactiveImageId={image.coactiveImageId}
          />
        );
      }
      const isSelected = Boolean(
        image &&
          selected?.find(
            (img) => img && img.coactiveImageId === image.coactiveImageId,
          ),
      );
      const cid = image?.coactiveImageId;

      const isTileSelectable = Boolean(
        (!disabledTiles && onClick) ||
          !disabledTiles?.find(
            (tile) => tile.coactiveImageId === image?.coactiveImageId,
          ),
      );

      return (
        <Tile
          key={key}
          data={cid && data ? data[cid] : undefined}
          image={image}
          selected={isSelected}
          selectable={isTileSelectable}
          effects={effects}
          onClick={onClick}
          showMenu={false}
          popoverComponent={popoverComponent}
          popoverClassName={popoverClassName}
          assetType={image?.assetType}
          detailComponent={detailComponent}
          selectStyle={selectStyle}
          order={cid ? orderMap[cid] : undefined}
          footer={tileFooter}
          tileButton={tileButton}
          icon={tileIcon}
          disabledMessage={
            !isTileSelectable && disabledMessage ? disabledMessage : undefined
          }
          dataTestId={`${dataTestId}-tile`}
        />
      );
    });

    return fetchNextImages && !loadMoreButton ? (
      <InfiniteScroll
        style={{ overflow: 'visible' }}
        dataLength={images?.length || 0}
        next={fetchNextImages}
        loader={<span />} // TODO - created loader for infinite scroll grid
        hasMore={hasMore || false}
      >
        <ul
          data-cy={
            dataTestId ? `${dataTestId}-infinite-scroll-grid` : undefined
          }
          className={classNames({
            'cascading-image-container': type === 'cascading',
            'grid grid-cols-2 gap-3 xs:grid-cols:2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7':
              type === 'uniform',
          })}
        >
          {/* TODO: Loading state for cascading type */}
          {imagesList}
        </ul>
      </InfiniteScroll>
    ) : (
      <>
        <ul
          className={classNames('w-full', {
            'cascading-image-container': type === 'cascading',
            [createColumnCountClass(columnCount!)]:
              type === 'uniform' && columnCount,
            'grid gap-3 ': type === 'uniform',
            'grid-cols-2 xs:grid-cols:2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7':
              type === 'uniform' && !columnCount,
          })}
          data-cy={dataTestId}
        >
          {prefix && <li className="aspect-square h-full">{prefix}</li>}
          {imagesList}
          {/* Temporary fix for column count issue on dynamic tags */}
          {placeholders &&
            imagesList?.length! < 6 &&
            [...Array(6).keys()].map(() => (
              <li className="bg-transparent w-full h-full aspect-square rounded-md" />
            ))}
        </ul>
        {fetchNextImages && loadMoreButton ? (
          <div className="text-center pt-4">
            <Button
              type="button"
              onClick={fetchNextImages}
              buttonStyle="secondary"
            >
              {typeof loadMoreButton === 'string' ? loadMoreButton : 'See more'}
              {Boolean(isLoadingMore) && (
                <ClipLoader
                  cssOverride={{ textAlign: 'center', marginLeft: '0.5rem' }}
                  color={colors.blue['500']}
                  loading
                  size={20}
                  speedMultiplier={0.75}
                />
              )}
            </Button>
          </div>
        ) : undefined}
      </>
    );
  };

ImageGrid.defaultProps = {
  columnCount: undefined,
  effects: undefined,
  selected: undefined,
  onClick: undefined,
  type: 'uniform',
  popoverComponent: undefined,
  popoverClassName: undefined,
  fetchNextImages: undefined,
  hasMore: undefined,
  isLoadingMore: false,
  loadMoreButton: false,
  detailComponent: undefined,
  data: undefined,
  selectStyle: undefined,
  tileFooter: undefined,
  prefix: undefined,
  tileIcon: undefined,
  disabledTiles: undefined,
  disabledMessage: undefined,
  placeholders: false,
  tileButton: undefined,
  dataTestId: undefined,
};

export default ImageGrid;
