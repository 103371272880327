import {
  DeleteQueryByIdRequest,
  ExecuteQueryRequest,
  QueryApi,
  QueryResultResponseUI,
  StopQueryExecutionRequest,
} from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import { useUserContext } from 'context/UserContext';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  useQueryClient,
} from 'react-query';
import { toast } from 'react-toastify';
import toNumber from 'utils/ToNumber';

const PAGE_SIZE = 50;

export enum Creator {
  Me = 'me',
  Others = 'others',
  All = 'all',
}

export const useGetQueryWithoutResultsQueryKey = (queryId: string) => [
  'getQueryByIdExcludeResults',
  queryId,
];

export const useGetQueryWithoutResults = (
  queryId: string,
  options:
    | {
        enabled?: boolean;
        onSuccess?: (response: QueryResultResponseUI) => void;
        onError?: () => void;
        notifyOnChangeProps?: any[];
      }
    | undefined = undefined,
) => {
  const { initializeAPI } = useAPIContext();
  const key = useGetQueryWithoutResultsQueryKey(queryId);

  const queryFn: QueryFunction<QueryResultResponseUI, QueryKey> = async ({
    signal,
  }) => {
    const api = await initializeAPI<QueryApi>(QueryApi);
    return api.getQueryById(
      {
        queryId,
        excludeResults: true,
      },
      { signal },
    );
  };

  return useQuery(key, queryFn, options);
};

export const useGetQueryHistoryInfiniteQueryInfiniteQueryKey = (creator) => [
  'getQueries',
  creator,
];

export const useGetQueryHistoryInfiniteQuery = (creator: Creator) => {
  const { initializeAPI } = useAPIContext();
  const { auth0User } = useUserContext();
  const query = useInfiniteQuery(
    useGetQueryHistoryInfiniteQueryInfiniteQueryKey(creator),
    async ({ signal, pageParam }) => {
      const api = await initializeAPI<QueryApi>(QueryApi);
      return api.getQueries(
        {
          offset: PAGE_SIZE * toNumber(pageParam || 0),
          limit: PAGE_SIZE,
          userIds: creator === Creator.Me ? [auth0User?.sub!] : undefined,
        },
        { signal },
      );
    },
    {
      enabled: true,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.meta?.page?.currentPage,
    },
  );
  return query;
};

export const useRunQuery = () => {
  const queryClient = useQueryClient();
  const { initializeAPI } = useAPIContext();
  return useMutation(
    ['executeQuery'],
    async (variables: ExecuteQueryRequest) => {
      const api = await initializeAPI<QueryApi>(QueryApi);
      return api.executeQuery(variables);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          useGetQueryHistoryInfiniteQueryInfiniteQueryKey(Creator.Me),
        );
      },
      onError: () => {
        /* TODO COA-37 */
      },
    },
  );
};

export const useStopQuery = () => {
  const queryClient = useQueryClient();
  const { initializeAPI } = useAPIContext();
  return useMutation(
    ['stopQueryExecution'],
    async (variables: StopQueryExecutionRequest) => {
      const api = await initializeAPI<QueryApi>(QueryApi);
      return api.stopQueryExecution(variables);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          useGetQueryHistoryInfiniteQueryInfiniteQueryKey(Creator.Me),
        );
      },
      onError: () => {
        toast.error('Unable to stop the query. Try again.');
      },
    },
  );
};

export const useDeleteQuery = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { initializeAPI } = useAPIContext();
  return useMutation(
    ['deleteQueryById'],
    async (variables: DeleteQueryByIdRequest) => {
      const api = await initializeAPI<QueryApi>(QueryApi);
      return api.deleteQueryById(variables);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          useGetQueryHistoryInfiniteQueryInfiniteQueryKey(Creator.Me),
        );
        onSuccess?.();
        toast.success('Successfully deleted query');
      },
      onError: () => {
        toast.error('Unable to delete the query. Try again.');
      },
    },
  );
};

export const getQueryResultsQueryKey = (
  queryId: string | undefined,
  offset: number | undefined,
  limit: number | undefined,
) => ['getQueryById', queryId, offset, limit];

export const useGetQueryResults = (
  queryId: string | undefined,
  offset: number | undefined,
  limit: number | undefined,
  options?: { onSuccess: () => void },
) => {
  const { initializeAPI } = useAPIContext();
  const query = useQuery(
    getQueryResultsQueryKey(queryId, offset, limit),
    async ({ signal }) => {
      if (!queryId) {
        return Promise.resolve(undefined);
      }
      const api = await initializeAPI<QueryApi>(QueryApi);
      return api.getQueryById(
        {
          queryId,
          offset,
          limit,
        },
        { signal },
      );
    },
    {
      enabled: true,
      ...options,
    },
  );

  return query;
};

export const useGetQueryDownloadUrl = (queryId: string | undefined) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    ['getQueryResultsCsvDownloadUrl', queryId],
    async ({ signal }) => {
      if (!queryId) {
        return Promise.resolve(undefined);
      }
      const api = await initializeAPI<QueryApi>(QueryApi);
      return api.getQueryResultsCsvDownloadUrl({ queryId }, { signal });
    },
    { cacheTime: 0, enabled: false },
  );
};
