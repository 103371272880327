import { Popover as HeadlessUiPopup } from '@headlessui/react';
import {
  ConceptData,
  FilterCondition,
  MetadataData,
  SearchMode,
} from 'components/DatasetSearch/AdvancedSearch/types';
import { DropdownOption } from 'components/Dropdown';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NoCodeQuerySortItem, SQLOperand, SortDirection } from 'api/generated';
import classNames from 'classnames';
import FilterPills from 'components/DatasetSearch/AdvancedSearch/FilterPills';
import {
  useGetDatasetAdvancedSearchHistoryInfiniteQueryKey,
  useGetDatasetAdvancedSearchQuery,
  useGetDatasetSearchParametersQuery,
} from 'components/DatasetSearch/AdvancedSearch/queries';
import SearchContainer from 'components/SearchContainer';
import {
  convertParametersToFilterConditions,
  createEmptyFilterRow,
  getValidFilterRows,
  getValidSortItem,
} from 'components/DatasetSearch/utils';
import { useRunNoCodeQuery } from 'pages/datasets/detail/components/queries';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import AdvancedSearchPopover from 'components/DatasetSearch/AdvancedSearch/AdvancedSearchPopover';
import { Tab } from 'pages/queries/ExecutedQueryView/types';

const POPUP_PADDING = 300;

interface AdvancedSearchProps {
  datasetId: string;
  setSearchMode: (searchMode: SearchMode) => void;
  searchModeOptions: DropdownOption<SearchMode>[];
  setPopupHeight?: (popupHeight: number) => void;
  searchContainerClassName?: string;
}

const AdvancedSearch: React.FC<AdvancedSearchProps> = function AdvancedSearch({
  datasetId,
  setSearchMode,
  searchModeOptions,
  setPopupHeight,
  searchContainerClassName,
}) {
  const popoverRef = useRef<HTMLDivElement>(null);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const [operand, setOperand] = useState<SQLOperand>(SQLOperand.And);
  const [filterRows, setFilterRows] = useState<Partial<FilterCondition>[]>([
    createEmptyFilterRow(),
  ]);
  const [sortItem, setSortItem] = useState<
    Partial<NoCodeQuerySortItem> | undefined
  >({
    direction: SortDirection.Desc,
  });
  const params = useParams();

  const { data: columns, isLoading: columnsLoading } =
    useGetDatasetSearchParametersQuery(datasetId);
  const { data: query, isLoading: queryIsLoading } =
    useGetDatasetAdvancedSearchQuery(params.queryId);

  const runNoCodeQueryMutation = useRunNoCodeQuery();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    if (query) {
      setFilterRows(convertParametersToFilterConditions(query?.parameters));
      const si = query?.parameters?.sort;
      setSortItem(si ? { ...si } : undefined);
      setOperand(query?.parameters?.clause?.operand ?? SQLOperand.And);
    }
  }, [query]);

  const concepts = columns?.concepts ?? [];
  const metadataKeys = columns?.metadataKeys ?? [];

  const usedConceptIds = new Set(
    filterRows
      .map((fr) =>
        fr?.concepts?.length ? fr?.concepts[0].conceptId : undefined,
      )
      .filter((c) => c),
  );
  const availableConcepts = concepts.filter(
    (c) => !usedConceptIds.has(c.conceptId),
  );

  const unavailableMetadataKeys = new Set(
    filterRows
      .map((fr) =>
        fr?.metadataKeys?.length && fr.metadataKeys[0].type === 'datetime'
          ? fr?.metadataKeys[0].key
          : undefined,
      )
      .filter((c) => c),
  );
  const availableMetadataKeys = metadataKeys.filter(
    (c) => !unavailableMetadataKeys.has(c.key),
  );

  const removeFilterCondition = (id: string | undefined) => () => {
    if (id) {
      const rows = [...filterRows].filter((row) => row.id !== id);
      setFilterRows(rows);
    }
  };

  const validFilterConditions = getValidFilterRows(filterRows);
  const validSortItem = getValidSortItem(sortItem);

  const searchModeCount = searchModeOptions?.length ?? 0;

  const searchButtonDisabled =
    !columns ||
    (!validFilterConditions?.length && !validSortItem) ||
    queryIsLoading;

  const adjustHeight = useCallback(() => {
    if (setPopupHeight) {
      // POPUP_PADDING is to account for the height of the popups that open inside the main popup
      setPopupHeight((popoverRef.current?.offsetHeight ?? 0) + POPUP_PADDING);
    }
  }, [setPopupHeight]);

  useEffect(() => {
    adjustHeight();
  });

  return (
    <div className={searchContainerClassName}>
      <SearchContainer
        searchMode="advanced-visual"
        setSearchMode={setSearchMode}
        searchModeOptions={searchModeOptions}
        buttonDisabled={searchButtonDisabled}
        executeSearch={() => {
          if (!searchButtonDisabled) {
            runNoCodeQueryMutation.mutate(
              {
                noCodeRunQueryRequest: {
                  startDt: null,
                  endDt: null,
                  sort: validSortItem,
                  clause: {
                    operand,
                    conceptParameters: validFilterConditions
                      .map((fc) => fc.concepts?.filter((c) => c))
                      .filter((cs) => cs !== undefined)
                      .flat()
                      .filter((cs) => cs) as ConceptData[],
                    metadataParameters: validFilterConditions
                      .map(
                        (fc) =>
                          fc.metadataKeys?.filter(
                            (mk) =>
                              mk && mk.value !== undefined && mk.value !== null,
                          ),
                      )
                      .flat()
                      .filter((mk) => mk) as MetadataData[],
                  },
                  embeddingId: columns.embeddingId,
                },
              },
              {
                onSuccess: (result) => {
                  queryClient.invalidateQueries(
                    useGetDatasetAdvancedSearchHistoryInfiniteQueryKey(
                      datasetId,
                    ),
                  );
                  navigate(
                    `/datasets/${datasetId}/search/${
                      result.queryId
                    }/${Tab.Content.toLowerCaseString()}`,
                  );
                },
              },
            );
          }
        }}
      >
        <HeadlessUiPopup className="relative grow min-w-0">
          <HeadlessUiPopup.Button
            as="div"
            tabIndex={0}
            className={classNames(
              'cursor-pointer w-full bg-white min-h-[1rem] pt-4 pb-0 text-sm text-left flex flex-wrap items-center px-2 rounded-tr-md rounded-br-md h-full border-0 outline-none overflow-x-auto',
              searchModeCount < 2 && 'rounded-tl-md rounded-bl-md',
            )}
          >
            <FilterPills
              filterConditions={validFilterConditions}
              removeSortItem={() => setSortItem(undefined)}
              removeFilterCondition={removeFilterCondition}
              operand={operand}
              sortItem={sortItem}
              placeholder="Add filter conditions ..."
            />
          </HeadlessUiPopup.Button>
          <HeadlessUiPopup.Panel
            className="absolute z-10 bg-white rounded-md shadow-lg w-full mt-1"
            ref={popoverRef}
          >
            {({ close }) => (
              <AdvancedSearchPopover
                close={close}
                datasetId={datasetId}
                columnsLoading={columnsLoading}
                metadataKeys={metadataKeys}
                availableMetadataKeys={availableMetadataKeys}
                availableConcepts={availableConcepts}
                concepts={concepts}
                filterRows={filterRows}
                setFilterRows={setFilterRows}
                removeFilterCondition={removeFilterCondition}
                operand={operand}
                setOperand={setOperand}
                sortItem={sortItem}
                setSortItem={setSortItem}
                adjustHeight={adjustHeight}
              />
            )}
          </HeadlessUiPopup.Panel>
        </HeadlessUiPopup>
      </SearchContainer>
    </div>
  );
};

AdvancedSearch.defaultProps = {
  setPopupHeight: undefined,
  searchContainerClassName: undefined,
};

export default AdvancedSearch;
