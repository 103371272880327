import { ArrowPathIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import {
  AssetResponse,
  FullConceptVersionLabelResponse,
  FullVersionResponse,
  LabelEnum,
  PagedConceptVersionLabelsResponse,
} from 'api/generated';
import classNames from 'classnames';
import ErrorStateMessage from 'components/ErrorStateMessage';
import FadeTransition from 'components/FadeTransition';
import FilterPill from 'components/FilterPill';
import ImageGrid from 'components/ImageGrid';
import SectionHeader from 'components/SectionHeader';
import { useRefineSidebarContext } from 'context/RefineSidebarContext';
import SidebarContext from 'context/SidebarContext';
import ExamplesGrid from 'pages/concepts/detail/tabs/Examples/ExamplesGrid';
import { ConceptActionState, LabelCandidateStatus } from 'pages/concepts/types';
import React, { ReactElement, useContext, useMemo } from 'react';
import { InfiniteData } from 'react-query';
import { ClipLoader } from 'react-spinners';
import colors from 'tailwindcss/colors';

interface ExamplesProps {
  actionState: ConceptActionState;
  version: FullVersionResponse;
  data: InfiniteData<PagedConceptVersionLabelsResponse> | undefined;
  isLoading: boolean;
  isRefetching: boolean;
  isError?: boolean;
  labelFilters: LabelEnum[];
  setLabelFilters: (filters: LabelEnum[]) => void;
  fetchNextPage: () => void;
  loadingGrid?: ReactElement<any, any>;
  hasHeadLoadingIndicator?: boolean;
  labelCandidatesStatus?: LabelCandidateStatus;
  setLabelCandidatesStatus?: (
    labelCandidatesStatus: LabelCandidateStatus,
  ) => void;
  dataTestId?: string;
}

const Examples: React.FC<ExamplesProps> = function Examples({
  actionState,
  version,
  data,
  isLoading,
  isRefetching,
  isError,
  labelFilters,
  setLabelFilters,
  fetchNextPage,
  loadingGrid,
  hasHeadLoadingIndicator,
  labelCandidatesStatus,
  setLabelCandidatesStatus,
  dataTestId,
}) {
  const { setRightSidebarOpen } = useContext(SidebarContext);
  const {
    setImage,
    setView,
    setDisabled: setSidebarDisabled,
  } = useRefineSidebarContext();

  const [examples, totalExamples] = useMemo<
    [FullConceptVersionLabelResponse[], number]
  >(
    () => [
      data?.pages.reduce(
        (acc: FullConceptVersionLabelResponse[], item) => [
          ...acc,
          ...item.data,
        ],
        [],
      ) || [],
      data?.pages.length
        ? data.pages[data.pages.length - 1].meta?.page?.total || 0
        : 0,
    ],
    [data],
  );

  const hasMore = totalExamples > examples?.length;

  const showLoading = isLoading || version.jobStatus === 'Running';

  const displayLoadingIndicator = hasHeadLoadingIndicator || isRefetching;

  const tileFooter = (img) => {
    if (!img.score) {
      return <div />;
    }
    const roundedScore = img.score.toFixed(2);
    return (
      <div className="flex -mt-1.5 justify-between w-full px-3 py-2 text-sm border-t border-gray-200">
        <p>Concept score</p>
        <p>{roundedScore}</p>
      </div>
    );
  };

  const handleLabelFilterChange = (labelFilter: LabelEnum[]) => {
    setLabelFilters(labelFilter);
    setLabelCandidatesStatus?.('none');
  };

  return (
    <div data-cy={dataTestId}>
      <SectionHeader title="">
        <FilterPill
          selected={
            !labelFilters.includes(LabelEnum._0) &&
            labelFilters.includes(LabelEnum._1)
          }
          onClick={() => handleLabelFilterChange([LabelEnum._1])}
          icon={CheckIcon}
          className="mr-2"
          dataTestId={`${dataTestId}-positive-btn`}
        >
          Positive ({version.positiveLabelsCount})
        </FilterPill>
        <FilterPill
          selected={
            labelFilters.includes(LabelEnum._0) &&
            !labelFilters.includes(LabelEnum._1)
          }
          onClick={() => handleLabelFilterChange([LabelEnum._0])}
          icon={XMarkIcon}
          className="mr-2"
          dataTestId={`${dataTestId}-negative-btn`}
        >
          Negative ({version.negativeLabelsCount})
        </FilterPill>
        <FilterPill
          selected={
            labelFilters.includes(LabelEnum._0) &&
            labelFilters.includes(LabelEnum._1)
          }
          onClick={() => handleLabelFilterChange([LabelEnum._1, LabelEnum._0])}
          className="mr-2"
          dataTestId={`${dataTestId}-all-btn`}
        >
          All ({version.positiveLabelsCount + version.negativeLabelsCount})
        </FilterPill>

        {displayLoadingIndicator && (
          <div className="mr-2 mt-1">
            <ClipLoader
              color={colors.emerald['500']}
              loading
              size={22}
              speedMultiplier={0.75}
            />
          </div>
        )}
        {labelCandidatesStatus !== 'none' && (
          <div className="mt-1">
            <p
              className={classNames(
                labelCandidatesStatus === 'error'
                  ? 'text-red-500'
                  : 'text-emerald-500',
              )}
            >
              {labelCandidatesStatus === 'error'
                ? 'An error occurred while fetching examples to label.'
                : 'All the assets in the dataset have been labeled.'}
            </p>
          </div>
        )}
      </SectionHeader>
      {showLoading && loadingGrid}
      {showLoading && !loadingGrid && (
        <FadeTransition
          appear
          show={showLoading}
          exit={false}
          enterDelayMs={500}
        >
          {showLoading && (
            <ImageGrid
              images={new Array(20).fill(undefined)}
              fetchNextImages={fetchNextPage}
            />
          )}
        </FadeTransition>
      )}
      {version && !version.isQueryable && !isRefetching && (
        <div className="mb-4 rounded-md bg-gray-100 p-4 text-sm inline-block">
          <strong className="text-yellow-500">Warning:</strong> This concept
          cannot be queried. It must have at least one positive and one negative
          example.
        </div>
      )}
      {!showLoading && !isError && (
        <ExamplesGrid
          tileFooter={tileFooter}
          transitionKey="examples"
          images={examples}
          fetchNextExamples={fetchNextPage}
          hasMore={hasMore}
          onClick={
            actionState === ConceptActionState.View
              ? (image: AssetResponse) => {
                  setSidebarDisabled(false);
                  setImage(image);
                  setView('update-label');
                  setRightSidebarOpen(true);
                }
              : undefined
          }
          dataTestId={`${dataTestId}-results`}
        />
      )}
      {isError && (
        <ErrorStateMessage
          message="We were unable to load the concept labels."
          action={{
            icon: ArrowPathIcon,
            label: 'Refresh the Page',
            execute: () => window.location.reload(),
          }}
        />
      )}
    </div>
  );
};

Examples.defaultProps = {
  loadingGrid: undefined,
  isError: false,
  hasHeadLoadingIndicator: false,
  labelCandidatesStatus: undefined,
  setLabelCandidatesStatus: undefined,
  dataTestId: undefined,
};

export default Examples;
