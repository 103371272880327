import { useAuth0 } from '@auth0/auth0-react';
import { useUserContext } from 'context/UserContext';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import FullScreenLoadingPage from 'components/FullScreenLoadingPage';

const INVITATION_QUERY_PARAM = 'invitation';
const ORG_QUERY_PARAM = 'organization';

const AuthLoadingWrapper = ({ children }) => {
  const { authCookieSet } = useUserContext();
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const { state, pathname } = useLocation();

  const navigate = useNavigate();
  const initialRoute = useRef(pathname);

  const [searchParams] = useSearchParams();

  const initialRouteWasLogin = initialRoute.current.startsWith('/login');
  const isLogin = pathname.startsWith('/login');

  useEffect(() => {
    const redirectPath = sessionStorage.getItem('redirectPath');
    if (redirectPath && isAuthenticated) {
      navigate(redirectPath);
      sessionStorage.removeItem('redirectPath');
    } else if (initialRouteWasLogin || isLogin) {
      const invitation = searchParams.get(INVITATION_QUERY_PARAM);
      const organization = searchParams.get(ORG_QUERY_PARAM);
      if (invitation && organization) {
        loginWithRedirect({
          authorizationParams: { invitation, organization },
        });
      } else if (isAuthenticated) {
        navigate('/');
      } else if (!isLoading) {
        loginWithRedirect({
          appState: {
            redirectUri: (state as any)?.redirectUri || window.location.origin,
          },
        });
      }
    }
  }, [isLogin, isAuthenticated, isLoading]);

  const loadingPage = (
    <FullScreenLoadingPage color={initialRouteWasLogin ? 'green' : 'white'} />
  );

  if (
    isLogin ||
    isLoading ||
    !authCookieSet ||
    !isAuthenticated ||
    Boolean(sessionStorage.getItem('redirectPath'))
  ) {
    return loadingPage;
  }
  return children;
};

export default AuthLoadingWrapper;
