import memoize from 'fast-memoize';
import { useCallback } from 'react';

interface ValidationResponse {
  valid: {
    value: boolean;
    message?: string | null;
  };
  unique: {
    value: boolean;
    message?: string | null;
  };
}

export const isValidationResponseInvalid = (
  response: ValidationResponse | undefined,
): boolean =>
  response ? !(response.unique.value && response.valid.value) : true;

// Uses the fast-memoize package to make this more efficient
// https://stackoverflow.com/questions/61473676/how-to-return-a-react-memoized-callback-from-factory-function
export function inputValidationCallbackFactory<T>(
  currentValue: T | undefined,
  validationFn: (...args: any[]) => void,
  validationPayloadFn: (value: T) => any,
  objectsToWatch: Array<any>,
) {
  return useCallback(
    memoize(
      async (value: any) =>
        new Promise<ValidationResponse>((resolve, reject) => {
          if (value === currentValue) {
            resolve({
              valid: { value: true },
              unique: { value: true },
            });
          }
          validationFn(validationPayloadFn(value), {
            onSuccess: (response) => {
              resolve(response);
            },
            onError: () => {
              reject();
            },
          });
        }),
    ),
    objectsToWatch,
  );
}
