/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EmbeddingListResponse,
  EmbeddingResponse,
  ErrorResponse,
  HTTPValidationError,
  UpdateEmbeddingRequest,
} from '../models';
import {
    EmbeddingListResponseFromJSON,
    EmbeddingListResponseToJSON,
    EmbeddingResponseFromJSON,
    EmbeddingResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    UpdateEmbeddingRequestFromJSON,
    UpdateEmbeddingRequestToJSON,
} from '../models';

export interface GetEmbeddingByIdRequest {
    embeddingId: string;
}

export interface GetEmbeddingsByDatasetIdRequest {
    datasetId: string;
    offset?: number | null;
    limit?: number | null;
}

export interface GetEncoderForEmbeddingRequest {
    embeddingId: string;
}

export interface UpdateEmbeddingByIdRequest {
    embeddingId: string;
    updateEmbeddingRequest: UpdateEmbeddingRequest;
}

/**
 * EmbeddingApi - interface
 * 
 * @export
 * @interface EmbeddingApiInterface
 */
export interface EmbeddingApiInterface {
    /**
     * Get embedding by id
     * @summary Get embedding by id
     * @param {string} embeddingId The unique identifier for the embedding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingApiInterface
     */
    getEmbeddingByIdRaw(requestParameters: GetEmbeddingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmbeddingResponse>>;

    /**
     * Get embedding by id
     * Get embedding by id
     */
    getEmbeddingById(requestParameters: GetEmbeddingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmbeddingResponse>;

    /**
     * Get embeddings by dataset id
     * @summary Get embeddings by dataset id
     * @param {string} datasetId The unique identifier for the dataset
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingApiInterface
     */
    getEmbeddingsByDatasetIdRaw(requestParameters: GetEmbeddingsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmbeddingListResponse>>;

    /**
     * Get embeddings by dataset id
     * Get embeddings by dataset id
     */
    getEmbeddingsByDatasetId(requestParameters: GetEmbeddingsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmbeddingListResponse>;

    /**
     * Get encoder for embedding
     * @summary Get encoder for embedding
     * @param {string} embeddingId The unique identifier for the embedding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingApiInterface
     */
    getEncoderForEmbeddingRaw(requestParameters: GetEncoderForEmbeddingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Get encoder for embedding
     * Get encoder for embedding
     */
    getEncoderForEmbedding(requestParameters: GetEncoderForEmbeddingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Update embedding by id
     * @summary Update embedding by id
     * @param {string} embeddingId The unique identifier for the embedding
     * @param {UpdateEmbeddingRequest} updateEmbeddingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingApiInterface
     */
    updateEmbeddingByIdRaw(requestParameters: UpdateEmbeddingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmbeddingResponse>>;

    /**
     * Update embedding by id
     * Update embedding by id
     */
    updateEmbeddingById(requestParameters: UpdateEmbeddingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmbeddingResponse>;

}

/**
 * 
 */
export class EmbeddingApi extends runtime.BaseAPI implements EmbeddingApiInterface {

    /**
     * Get embedding by id
     * Get embedding by id
     */
    async getEmbeddingByIdRaw(requestParameters: GetEmbeddingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmbeddingResponse>> {
        if (requestParameters.embeddingId === null || requestParameters.embeddingId === undefined) {
            throw new runtime.RequiredError('embeddingId','Required parameter requestParameters.embeddingId was null or undefined when calling getEmbeddingById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/embeddings/{embedding_id}`.replace(`{${"embedding_id"}}`, encodeURIComponent(String(requestParameters.embeddingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmbeddingResponseFromJSON(jsonValue));
    }

    /**
     * Get embedding by id
     * Get embedding by id
     */
    async getEmbeddingById(requestParameters: GetEmbeddingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmbeddingResponse> {
        const response = await this.getEmbeddingByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get embeddings by dataset id
     * Get embeddings by dataset id
     */
    async getEmbeddingsByDatasetIdRaw(requestParameters: GetEmbeddingsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmbeddingListResponse>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling getEmbeddingsByDatasetId.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets/{dataset_id}/embeddings`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmbeddingListResponseFromJSON(jsonValue));
    }

    /**
     * Get embeddings by dataset id
     * Get embeddings by dataset id
     */
    async getEmbeddingsByDatasetId(requestParameters: GetEmbeddingsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmbeddingListResponse> {
        const response = await this.getEmbeddingsByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get encoder for embedding
     * Get encoder for embedding
     */
    async getEncoderForEmbeddingRaw(requestParameters: GetEncoderForEmbeddingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.embeddingId === null || requestParameters.embeddingId === undefined) {
            throw new runtime.RequiredError('embeddingId','Required parameter requestParameters.embeddingId was null or undefined when calling getEncoderForEmbedding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/embeddings/{embedding_id}/encoder`.replace(`{${"embedding_id"}}`, encodeURIComponent(String(requestParameters.embeddingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get encoder for embedding
     * Get encoder for embedding
     */
    async getEncoderForEmbedding(requestParameters: GetEncoderForEmbeddingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getEncoderForEmbeddingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update embedding by id
     * Update embedding by id
     */
    async updateEmbeddingByIdRaw(requestParameters: UpdateEmbeddingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmbeddingResponse>> {
        if (requestParameters.embeddingId === null || requestParameters.embeddingId === undefined) {
            throw new runtime.RequiredError('embeddingId','Required parameter requestParameters.embeddingId was null or undefined when calling updateEmbeddingById.');
        }

        if (requestParameters.updateEmbeddingRequest === null || requestParameters.updateEmbeddingRequest === undefined) {
            throw new runtime.RequiredError('updateEmbeddingRequest','Required parameter requestParameters.updateEmbeddingRequest was null or undefined when calling updateEmbeddingById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/embeddings/{embedding_id}`.replace(`{${"embedding_id"}}`, encodeURIComponent(String(requestParameters.embeddingId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmbeddingRequestToJSON(requestParameters.updateEmbeddingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmbeddingResponseFromJSON(jsonValue));
    }

    /**
     * Update embedding by id
     * Update embedding by id
     */
    async updateEmbeddingById(requestParameters: UpdateEmbeddingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmbeddingResponse> {
        const response = await this.updateEmbeddingByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
