/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppSharedV0SchemaPagedMetaResponse,
    AppSharedV0SchemaPagedMetaResponseFromJSON,
    AppSharedV0SchemaPagedMetaResponseFromJSONTyped,
    AppSharedV0SchemaPagedMetaResponseToJSON,
} from './AppSharedV0SchemaPagedMetaResponse';
import {
    DatasetResponse,
    DatasetResponseFromJSON,
    DatasetResponseFromJSONTyped,
    DatasetResponseToJSON,
} from './DatasetResponse';

/**
 * A paginated list of datasets
 * @export
 * @interface DatasetListResponse
 */
export interface DatasetListResponse {
    /**
     * 
     * @type {AppSharedV0SchemaPagedMetaResponse}
     * @memberof DatasetListResponse
     */
    meta: AppSharedV0SchemaPagedMetaResponse;
    /**
     * The paginated datasets
     * @type {Array<DatasetResponse>}
     * @memberof DatasetListResponse
     */
    data: Array<DatasetResponse>;
}

export function DatasetListResponseFromJSON(json: any): DatasetListResponse {
    return DatasetListResponseFromJSONTyped(json, false);
}

export function DatasetListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasetListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': AppSharedV0SchemaPagedMetaResponseFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(DatasetResponseFromJSON)),
    };
}

export function DatasetListResponseToJSON(value?: DatasetListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': AppSharedV0SchemaPagedMetaResponseToJSON(value.meta),
        'data': ((value.data as Array<any>).map(DatasetResponseToJSON)),
    };
}

