/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppSharedV1SchemaPagedMetaResponse,
    AppSharedV1SchemaPagedMetaResponseFromJSON,
    AppSharedV1SchemaPagedMetaResponseFromJSONTyped,
    AppSharedV1SchemaPagedMetaResponseToJSON,
} from './AppSharedV1SchemaPagedMetaResponse';
import {
    Column,
    ColumnFromJSON,
    ColumnFromJSONTyped,
    ColumnToJSON,
} from './Column';
import {
    DynamicTagTableRow,
    DynamicTagTableRowFromJSON,
    DynamicTagTableRowFromJSONTyped,
    DynamicTagTableRowToJSON,
} from './DynamicTagTableRow';

/**
 * A response containing data to be represented in a table
 * @export
 * @interface DynamicTagTableResponse
 */
export interface DynamicTagTableResponse {
    /**
     * 
     * @type {AppSharedV1SchemaPagedMetaResponse}
     * @memberof DynamicTagTableResponse
     */
    meta: AppSharedV1SchemaPagedMetaResponse;
    /**
     * The table rows
     * @type {Array<DynamicTagTableRow>}
     * @memberof DynamicTagTableResponse
     */
    data: Array<DynamicTagTableRow>;
    /**
     * The table columns
     * @type {Array<Column>}
     * @memberof DynamicTagTableResponse
     */
    columns: Array<Column>;
}

export function DynamicTagTableResponseFromJSON(json: any): DynamicTagTableResponse {
    return DynamicTagTableResponseFromJSONTyped(json, false);
}

export function DynamicTagTableResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DynamicTagTableResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': AppSharedV1SchemaPagedMetaResponseFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(DynamicTagTableRowFromJSON)),
        'columns': ((json['columns'] as Array<any>).map(ColumnFromJSON)),
    };
}

export function DynamicTagTableResponseToJSON(value?: DynamicTagTableResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': AppSharedV1SchemaPagedMetaResponseToJSON(value.meta),
        'data': ((value.data as Array<any>).map(DynamicTagTableRowToJSON)),
        'columns': ((value.columns as Array<any>).map(ColumnToJSON)),
    };
}

