import React from 'react';
import FadeTransition from 'components/FadeTransition';
import { QueryResultResponseUI } from 'api/generated';
import Chart from 'pages/queries/Chart';

interface QueryChartResultsProps {
  show: boolean;
  queryResult: QueryResultResponseUI | undefined;
}

const QueryChartResults: React.FC<QueryChartResultsProps> =
  function ExecutedQueryView({ show, queryResult }: QueryChartResultsProps) {
    return (
      <FadeTransition appear show={show} exit={false}>
        {Boolean(
          queryResult?.results?.columns && queryResult.results?.data,
        ) && (
          <Chart
            columns={queryResult?.results?.columns!}
            data={queryResult?.results?.data!}
          />
        )}
      </FadeTransition>
    );
  };

export default QueryChartResults;
