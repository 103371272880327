/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const AssetType = {
    Image: 'image',
    Video: 'video',
    Keyframe: 'keyframe'
} as const;
export type AssetType = typeof AssetType[keyof typeof AssetType];


export function AssetTypeFromJSON(json: any): AssetType {
    return AssetTypeFromJSONTyped(json, false);
}

export function AssetTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetType {
    return json as AssetType;
}

export function AssetTypeToJSON(value?: AssetType | null): any {
    return value as any;
}

