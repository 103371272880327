import React, { PropsWithChildren } from 'react';

/**
 * An ordered list for access configuration instruction steps.
 */
const AccessInstructionsList: React.FC<PropsWithChildren> =
  function AccessInstructionsList({ children }) {
    return (
      <ol className="list-lower-alpha-no-period list-inside [&>li]:my-2">
        {children}
      </ol>
    );
  };

export default AccessInstructionsList;
