/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppSharedV0SchemaPagedMetaResponse,
    AppSharedV0SchemaPagedMetaResponseFromJSON,
    AppSharedV0SchemaPagedMetaResponseFromJSONTyped,
    AppSharedV0SchemaPagedMetaResponseToJSON,
} from './AppSharedV0SchemaPagedMetaResponse';
import {
    SimilaritySearchItem,
    SimilaritySearchItemFromJSON,
    SimilaritySearchItemFromJSONTyped,
    SimilaritySearchItemToJSON,
} from './SimilaritySearchItem';

/**
 * Similar assets are returned in decreasing similarity
 * @export
 * @interface SimilaritySearchResponse
 */
export interface SimilaritySearchResponse {
    /**
     * 
     * @type {AppSharedV0SchemaPagedMetaResponse}
     * @memberof SimilaritySearchResponse
     */
    meta: AppSharedV0SchemaPagedMetaResponse;
    /**
     * List of similarity search items
     * @type {Array<SimilaritySearchItem>}
     * @memberof SimilaritySearchResponse
     */
    data: Array<SimilaritySearchItem>;
    /**
     * A metadata property to use to group the similarity search results
     * @type {string}
     * @memberof SimilaritySearchResponse
     */
    groupBy?: string | null;
}

export function SimilaritySearchResponseFromJSON(json: any): SimilaritySearchResponse {
    return SimilaritySearchResponseFromJSONTyped(json, false);
}

export function SimilaritySearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilaritySearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': AppSharedV0SchemaPagedMetaResponseFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(SimilaritySearchItemFromJSON)),
        'groupBy': !exists(json, 'groupBy') ? undefined : json['groupBy'],
    };
}

export function SimilaritySearchResponseToJSON(value?: SimilaritySearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': AppSharedV0SchemaPagedMetaResponseToJSON(value.meta),
        'data': ((value.data as Array<any>).map(SimilaritySearchItemToJSON)),
        'groupBy': value.groupBy,
    };
}

