/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface UpdateUserRoleRequest
 */
export interface UpdateUserRoleRequest {
    /**
     * User id
     * @type {string}
     * @memberof UpdateUserRoleRequest
     */
    userId: string;
}

export function UpdateUserRoleRequestFromJSON(json: any): UpdateUserRoleRequest {
    return UpdateUserRoleRequestFromJSONTyped(json, false);
}

export function UpdateUserRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserRoleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
    };
}

export function UpdateUserRoleRequestToJSON(value?: UpdateUserRoleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
    };
}

