import classNames from 'classnames';
import React, { ChangeEvent } from 'react';

interface CheckboxGroupProps {
  title: string;
  items: {
    key: string;
    label: string;
    description?: string;
    checked: boolean;
    disabled?: boolean;
  }[];
  colsStyle?: string;
  showTitle?: boolean;
  onChange: (e: { checked: boolean; key: string }) => void;
}

const CheckboxGroup = function CheckboxGroup({
  items,
  title,
  onChange,
  colsStyle,
  showTitle,
}: CheckboxGroupProps) {
  return (
    <fieldset className="space-y-5">
      <legend
        className={classNames('block text-sm font-medium text-gray-700', {
          'sr-only': !showTitle,
        })}
      >
        {title}
      </legend>
      <div className={classNames('grid gap-4', colsStyle)}>
        {items.map((item) => (
          <div key={item.key} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onChange({ checked: e.target.checked, key: item.key })
                }
                id={item.key}
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded disabled:text-gray-400"
                defaultChecked={item.checked}
                disabled={item.disabled ?? false}
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="comments" className="font-medium text-gray-700">
                {item.label}
              </label>
              <span id="comments-description" className="text-gray-500">
                <span className="sr-only">{item.label} </span>
                {item.description}
              </span>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

CheckboxGroup.defaultProps = {
  showTitle: true,
  colsStyle: 'grid-cols-1',
};

export default CheckboxGroup;
