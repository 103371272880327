import React, { ReactElement } from 'react';
import colors from 'tailwindcss/colors';

const PlaceholderLabels = ({ x, y, index }: any): ReactElement<SVGElement> => {
  // values based on prediction labels width
  const widths = [125, 150, 0, 150, 135];
  const getXOffsetPosition = (xPosition: number, idx: number) =>
    widths.map((width) => xPosition - (width + 15))[idx];

  return (
    <svg
      x={getXOffsetPosition(x, index)}
      y={y}
      dy={-4}
      width="200"
      height="200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={`${widths[index]}`}
        height="40"
        rx="8"
        ry="8"
        className="animate-pulse"
        fill={index !== 2 ? colors.gray[200] : 'transparent'}
      />
    </svg>
  );
};

export default PlaceholderLabels;
