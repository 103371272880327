import React, { FunctionComponent } from 'react';

const PageDotsSeparator: FunctionComponent = function PageDotsSeparator() {
  return (
    <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 cursor-default">
      ...
    </span>
  );
};

export default PageDotsSeparator;
