import { Column, DynamicTagCategoryTableRow } from 'api/generated';
import EmptyStateMessage from 'components/EmptyStateMessage';
import FadeTransition from 'components/FadeTransition';
import LoadingTable from 'components/LoadingTable';
import Table from 'components/Table';
import { FeatureFlag } from 'feature_flags/FeatureFlagConfig';
import { evaluateFlag } from 'feature_flags/FeatureFlags';
import { PaginationHook } from 'hooks/usePagination';
import DeleteCategoryModal from 'pages/dynamic-tags/components/DeleteCategoryModal';
import { CategoryToDelete } from 'pages/dynamic-tags/types';
import { useDeleteCategoryMutation } from 'queries/dynamic-tags';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PaginationState } from 'types/pagination';
import { AppendedCellContent } from 'types/table';

interface CategoryTableViewProps {
  columns: Column[];
  categories: DynamicTagCategoryTableRow[];
  pagination: PaginationHook;
  paginationState: PaginationState;
  isLoading: boolean;
  total: number;
  refetchCategories?: () => void;
}

export const CategoryTableView: React.FunctionComponent<CategoryTableViewProps> =
  function CategoryTableView({
    columns,
    categories,
    pagination,
    paginationState,
    isLoading,
    total,
    refetchCategories,
  }) {
    const loadingTable = useMemo(() => <LoadingTable />, []);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const { mutate: deleteCategory } = useDeleteCategoryMutation();
    const [appendedCellContent, setAppendedCellContent] =
      useState<AppendedCellContent>(null);
    const [categoryToDelete, setCategoryToDelete] =
      useState<CategoryToDelete | null>(null);
    const isDeleteDynamicTagsEnabled = evaluateFlag(
      FeatureFlag.DELETE_DYNAMIC_TAGS_WIP,
    );

    const handleOpenDeleteModal = (rowData: CategoryToDelete) => {
      setCategoryToDelete(rowData);
      setIsDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = (isOpen: boolean) => {
      setCategoryToDelete(null);
      // HeadlessDialog component inside the modal requires this to be the boolean parameter instead of false in this case
      setIsDeleteModalOpen(isOpen);
    };

    const handleDeleteCategory = async () => {
      setIsDeleteModalOpen(false);
      setAppendedCellContent({
        cellIds: [`${categoryToDelete?.categoryId!}-name`],
        text: 'Deleting...',
        className: 'ml-2 text-yellow-500',
      });

      try {
        await new Promise<void>((resolve, reject) => {
          deleteCategory(
            { categoryId: categoryToDelete?.categoryId! },
            {
              onSuccess: () => {
                toast.success('Done! Your category is being deleted.');
                resolve();
              },
              onError: () => {
                toast.error(
                  'Something went wrong while deleting your category. Please try again.',
                );
                reject();
              },
            },
          );
        });

        refetchCategories?.();
        setCategoryToDelete(null);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setAppendedCellContent(null);
      }
    };

    return (
      <>
        <DeleteCategoryModal
          dataTestId="categories-table-delete-modal"
          onClose={handleCloseDeleteModal}
          onConfirm={handleDeleteCategory}
          shouldDisplay={isDeleteModalOpen}
          categoryToDelete={categoryToDelete!}
        />
        <FadeTransition show={Boolean(!isLoading && categories)} appear>
          <Table
            dataTestId="categories"
            appendedCellContent={appendedCellContent}
            columns={columns}
            data={categories as any}
            idKey="categoryId"
            actions={
              isDeleteDynamicTagsEnabled
                ? [
                    {
                      dataTestId: 'delete-category-btn',
                      key: 'delete',
                      label: (
                        <span className="text-red-500">Delete category</span>
                      ),
                      action: (row) => handleOpenDeleteModal(row.data),
                    },
                  ]
                : undefined
            }
            total={total}
            emptyMessage={
              <div className="text-center py-12 px-2 text-sm">
                <EmptyStateMessage>
                  <p className="pb-4">No categories to show</p>
                </EmptyStateMessage>
                <Link
                  to="/dynamic-tag-categories/add"
                  className="text-blue-500 hover:text-blue-600 font-medium"
                >
                  Create a category
                </Link>
              </div>
            }
            loadStrategy="paginated"
            activePage={paginationState.page}
            pageSize={paginationState.size}
            setPageSize={pagination.setSize}
            loadPage={pagination.setPage}
          />
        </FadeTransition>
        <FadeTransition
          show={Boolean(isLoading)}
          appear
          enterDelayMs={500}
          exit={false}
        >
          {!!isLoading && loadingTable}
        </FadeTransition>
      </>
    );
  };

CategoryTableView.defaultProps = {
  refetchCategories: undefined,
};
