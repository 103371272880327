import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { FieldValidation } from 'api/generated';
import classNames from 'classnames';
import ErrorText from 'components/ErrorText';
import useMounted from 'hooks/useMounted';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  ClipboardEvent,
  ForwardedRef,
  PropsWithRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

const VALIDATION_DELAY_MS = 200;

interface TextInputProps {
  ref?: ForwardedRef<HTMLInputElement | undefined>;
  id: string;
  label?: string;
  name: string;
  description?: string;
  type?: string;
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  error?: string;
  setHasError?: (hasError: boolean) => void;
  icon?: ReactNode;
  submitButton?: {
    component: ReactElement<any, any>;
    onClick: (value: string | undefined) => void;
    disabled?: boolean;
  };
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  // A function that returns a promise of an error message.
  validation?: (
    value: string,
  ) => Promise<{ valid: FieldValidation; unique: FieldValidation } | undefined>;
  executeValidationOnBlur?: boolean;
  textInputStyle?: 'default' | 'inline';
  prefix?: string;
  readOnlyPrefix?: boolean;
  suffix?: string;
  readOnlySuffix?: boolean;
  optional?: boolean;
  inputClassName?: string;
  containerClassName?: string;
  onInputClick?: React.MouseEventHandler<HTMLInputElement>;
  dataTestId?: string;
  actionButtons?: React.ReactNode;
}

const TextInput: React.FC<PropsWithRef<TextInputProps>> = React.forwardRef(
  (
    props: PropsWithRef<TextInputProps>,
    forwardRef: React.ForwardedRef<HTMLInputElement | undefined>,
  ) => {
    const {
      id,
      name,
      type,
      defaultValue: defaultValueProp,
      value,
      placeholder,
      error,
      setHasError,
      label,
      description,
      submitButton,
      icon,
      onChange: onChangeProp,
      onKeyUp: onKeyUpProp,
      onKeyDown,
      disabled,
      validation,
      executeValidationOnBlur,
      textInputStyle,
      prefix,
      readOnlyPrefix,
      suffix,
      readOnlySuffix,
      optional,
      inputClassName,
      containerClassName,
      onInputClick,
      dataTestId,
      actionButtons,
    } = props;
    const [unique, setUnique] = useState<FieldValidation | undefined>();
    const [valid, setValid] = useState<FieldValidation | undefined>();
    const { mounted } = useMounted();
    const internalRef = React.useRef<HTMLInputElement>();
    useImperativeHandle(forwardRef, () => internalRef.current);

    const changeRef = React.useRef<any>();

    useEffect(
      () => () => {
        clearTimeout(changeRef.current);
      },
      [],
    );

    const getFullInputValue = useCallback(
      (v?: string) => {
        let newValue = v;
        if (prefix && v && !readOnlyPrefix) newValue = prefix + v;
        if (suffix && v && !readOnlySuffix) newValue += suffix;
        return newValue;
      },
      [prefix, readOnlyPrefix, suffix, readOnlySuffix],
    );

    const onKeyUp = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (submitButton?.onClick) {
          if (submitButton && e.key === 'Enter' && !submitButton.disabled) {
            submitButton?.onClick(
              getFullInputValue(internalRef.current?.value),
            );
          }
        }
      },
      [submitButton],
    );

    const validate = useCallback(
      (v: string) => {
        if (validation) {
          changeRef.current = setTimeout(async () => {
            const response = await validation?.(v);
            if (mounted.current) {
              setUnique(response?.unique);
              setValid(response?.valid);
              setHasError?.(
                response?.unique?.value === false ||
                  response?.valid?.value === false,
              );
            }
          }, VALIDATION_DELAY_MS);
        }
      },
      [validation, executeValidationOnBlur, setUnique, setValid, setHasError],
    );

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        clearTimeout(changeRef.current);
        if (
          prefix &&
          !readOnlyPrefix &&
          e.target.value &&
          e.target.value.startsWith(prefix)
        ) {
          e.target.value = e.target.value.slice(prefix.length);
        }
        if (
          suffix &&
          e.target.value &&
          e.target.value.endsWith(suffix) &&
          !readOnlySuffix
        ) {
          e.target.value = e.target.value.slice(0, -suffix.length);
        }
        const v = getFullInputValue(e.target.value) ?? '';

        if (!executeValidationOnBlur) {
          validate(v);
        }

        onChangeProp?.({
          ...e,
          target: { ...e.target, value: v },
        });
      },
      [
        executeValidationOnBlur,
        onChangeProp,
        prefix,
        readOnlyPrefix,
        suffix,
        readOnlySuffix,
        validate,
        getFullInputValue,
      ],
    );

    const defaultValue = useMemo(() => {
      let newValue = defaultValueProp;
      if (
        prefix &&
        !readOnlyPrefix &&
        defaultValueProp &&
        defaultValueProp.startsWith(prefix)
      ) {
        newValue = defaultValueProp.slice(prefix.length);
      }
      if (
        suffix &&
        !readOnlySuffix &&
        defaultValueProp &&
        defaultValueProp.endsWith(suffix)
      ) {
        newValue = newValue?.slice(0, -suffix.length);
      }
      return newValue;
    }, [defaultValueProp, prefix, readOnlyPrefix, suffix, readOnlySuffix]);

    const onBlur = useCallback(async () => {
      if (!executeValidationOnBlur) return;
      if (internalRef.current?.value) {
        const v = getFullInputValue(internalRef.current?.value);
        const response = await validation?.(v ?? '');
        if (mounted.current) {
          setUnique(response?.unique);
          setValid(response?.valid);
          setHasError?.(
            response?.unique?.value === false ||
              response?.valid?.value === false,
          );
        }
      } else {
        setUnique({ value: true });
        setValid({ value: true });
        setHasError?.(false);
      }
    }, [executeValidationOnBlur, validation, prefix, suffix]);

    const onPaste = useCallback(
      (e: ClipboardEvent<HTMLInputElement>) => {
        // TODO: Account for cursor position & text selection
        const clipboardData =
          e.clipboardData ||
          (e as any).originalEvent.clipboardData ||
          (window as any).clipboardData;
        if (clipboardData) {
          let text = clipboardData.getData('text');
          if (prefix && !readOnlyPrefix && text.startsWith(prefix)) {
            text = text.slice(prefix.length);
          }
          if (suffix && !readOnlySuffix && text.endsWith(suffix)) {
            text = text.slice(0, -suffix.length);
          }
          (e.target as any).value = text;
          e.preventDefault();
          onChange({ target: { value: text } } as any);
        }
      },
      [prefix, readOnlyPrefix, suffix, readOnlySuffix, onChange],
    );

    // If a default value is provided, validate it on mount.
    useEffect(() => {
      if (defaultValue) {
        const fullValue = getFullInputValue(defaultValue);
        validate(fullValue!);
      }
    }, [defaultValue, getFullInputValue, validate]);

    const errorMessage = valid?.message || unique?.message || error;
    const hasActionButtonsOrSubmitButton = actionButtons || submitButton;

    return (
      <div className={textInputStyle === 'inline' ? 'inline' : 'block'}>
        {Boolean(label) && (
          <label
            htmlFor={id}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}
        {Boolean(description) && <span>{description}</span>}
        <div
          className={classNames(
            'mt-1 rounded-md shadow-sm relative',
            containerClassName,
            textInputStyle === 'inline' ? 'inline-flex' : 'flex',
          )}
        >
          <div
            className={classNames(
              'relative items-stretch flex-grow focus-within:ring-1 rounded-md ring-offset-0',
              {
                'inline-flex': textInputStyle === 'inline',
                flex: textInputStyle !== 'inline',
                'focus-within:ring-red-500 focus-within:border-red-500':
                  errorMessage,
                'focus-within:ring-emerald-600 focus-within:border-emerald-600':
                  !errorMessage,
              },
            )}
          >
            {prefix && (
              <div className="flex-grow sm:text-sm left-0 px-3 flex items-center pointer-events-none bg-gray-50 border border-gray-300 rounded-l-md text-gray-500">
                <span>{prefix}</span>
              </div>
            )}
            {icon && (
              <div
                className={classNames(
                  'absolute inset-y-0  pl-3 flex items-center pointer-events-none',
                  prefix ? 'left-14' : 'left-0',
                )}
              >
                {icon}
              </div>
            )}

            <input
              data-cy={dataTestId}
              onClick={onInputClick}
              ref={(r) => {
                if (r) {
                  internalRef.current = r;
                }
              }}
              type={type}
              name={name}
              id={id}
              className={classNames(
                `[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none sm:text-sm focus:outline-none focus:ring-0 focus:border-gray-300 ${
                  inputClassName || ''
                }`,
                {
                  'pr-10 border-red-300 text-red-900 placeholder-red-300':
                    errorMessage,
                  'placeholder-gray-600 placeholder:italic  sm:text-sm border-gray-300':
                    !errorMessage,
                  'disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200':
                    Boolean(disabled),
                  'rounded-md':
                    !hasActionButtonsOrSubmitButton && !prefix && !suffix,
                  'rounded-l-md  border-r-0':
                    hasActionButtonsOrSubmitButton || suffix,
                  'rounded-none rounded-l-md':
                    hasActionButtonsOrSubmitButton && !prefix,
                  'border-l-0 rounded-l-none': prefix,
                  'rounded-r-md': !suffix && !hasActionButtonsOrSubmitButton,
                  'pl-10': icon,
                  'block w-full': textInputStyle !== 'inline',
                },
              )}
              placeholder={placeholder}
              defaultValue={defaultValue}
              value={value}
              aria-invalid={Boolean(errorMessage)}
              aria-describedby={`${id}-error`}
              onKeyDown={(e) => onKeyDown?.(e)}
              onKeyUp={onKeyUpProp ?? onKeyUp}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              onPaste={prefix && !readOnlyPrefix ? onPaste : undefined}
            />
            {suffix && (
              <div
                className={classNames(
                  'flex-grow sm:text-sm left-0 px-3 flex items-center pointer-events-none bg-gray-50 border border-gray-300 rounded-r-md text-gray-500',
                  {
                    'rounded-r-none': hasActionButtonsOrSubmitButton,
                  },
                )}
              >
                <span>{suffix}</span>
              </div>
            )}
            {hasActionButtonsOrSubmitButton && (
              <div
                className={classNames(
                  '-ml-px  relative inline-flex border-l-0 rounded-r-md items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium focus:outline-none',
                )}
              >
                {actionButtons}
                {submitButton && (
                  <button
                    disabled={submitButton.disabled}
                    type="button"
                    onClick={() =>
                      submitButton.onClick?.(internalRef.current?.value)
                    }
                  >
                    {submitButton.component}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        {optional && (
          <div className="text-sm mt-1.5 text-gray-500 text-normal">
            Optional
          </div>
        )}
        {Boolean(errorMessage) && (
          <ErrorText id={`${id}-error`} margin={optional ? 'mt-1' : undefined}>
            {errorMessage}
            <span className="ml-2 inline-block align-text-bottom">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </span>
          </ErrorText>
        )}
      </div>
    );
  },
);

TextInput.defaultProps = {
  ref: undefined,
  defaultValue: undefined,
  placeholder: '',
  containerClassName: undefined,
  error: undefined,
  setHasError: undefined,
  type: 'text',
  icon: undefined,
  submitButton: undefined,
  onChange: undefined,
  onKeyUp: undefined,
  onKeyDown: undefined,
  disabled: false,
  validation: undefined,
  executeValidationOnBlur: false,
  value: undefined,
  textInputStyle: 'default',
  prefix: undefined,
  readOnlyPrefix: false,
  suffix: undefined,
  readOnlySuffix: false,
  description: undefined,
  optional: undefined,
  onInputClick: undefined,
  dataTestId: undefined,
  actionButtons: undefined,
};

export default TextInput;
