import { AssetResponse } from 'api/generated';
import EmptyStateMessage from 'components/EmptyStateMessage';
import FadeTransition from 'components/FadeTransition';
import ImageGrid from 'components/ImageGrid';
import { ScrollToTop } from 'components/ScrollToTop';
import { useSidebarAssetContext } from 'context/SidebarAssetContext';
import React, { ReactElement, useMemo } from 'react';
import { useGetImagesForDatasetApiInfiniteQuery } from '../../queries';

const DatasetImages = function DatasetImages({
  datasetId,
  loadingView,
  onClick,
  hideCount,
}: {
  datasetId: string;
  loadingView: ReactElement<any, any>;
  onClick: (img: AssetResponse) => void;
  hideCount?: boolean;
}) {
  const { data, fetchNextPage, isLoading } =
    useGetImagesForDatasetApiInfiniteQuery(datasetId);
  const { selectedAsset: sidebarAsset } = useSidebarAssetContext();

  const [images, totalImages] = useMemo(
    () => [
      data?.pages.reduce(
        (acc: AssetResponse[], item) => [...acc, ...item.data],
        [],
      ) || [],
      data?.pages.length
        ? data.pages[data.pages.length - 1].meta?.page?.total || 0
        : 0,
    ],
    [data],
  );
  const hasMore = useMemo(
    () => images.length < totalImages,
    [images, totalImages],
  );

  return (
    <div>
      {!isLoading && Boolean(totalImages) && (
        <>
          {!hideCount && (
            <div className="text-sm text-gray-500 my-4 font-bold">
              <span data-cy="dataset-image-count">
                {totalImages.toLocaleString()}
              </span>{' '}
              images
            </div>
          )}
          <ImageGrid
            images={images}
            fetchNextImages={fetchNextPage}
            hasMore={hasMore}
            onClick={onClick}
            selected={sidebarAsset ? [sidebarAsset] : undefined}
            type="uniform"
            dataTestId="dataset-images"
          />
          <ScrollToTop />
        </>
      )}
      {!isLoading && !totalImages && (
        <div className="text-center py-12 px-2 text-sm">
          <EmptyStateMessage dataTestId="dataset-images">
            <p>No images in dataset</p>
          </EmptyStateMessage>
        </div>
      )}
      {isLoading && (
        <FadeTransition appear show={isLoading} exit={false} enterDelayMs={500}>
          <div className="mt-4">{loadingView}</div>
        </FadeTransition>
      )}
    </div>
  );
};

DatasetImages.defaultProps = {
  hideCount: false,
};

export default DatasetImages;
