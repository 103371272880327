import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import { TrashIcon } from '@heroicons/react/24/solid';

interface DeleteIconButtonProps {
  id: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

const DeleteIconButton: React.FC<DeleteIconButtonProps> =
  function DeleteIconButton({ id, onClick, className }: DeleteIconButtonProps) {
    return (
      <>
        <button
          type="button"
          data-tip
          data-for={`deletequery-${id}`}
          className={classNames(
            'delete-button-outer inline-flex items-center',
            className,
          )}
          onClick={onClick}
        >
          <TrashIcon className="text-gray-500 delete-button-inner h-6 w-6 justify-center rounded-[.05rem] transition-all hover:text-red-600" />
        </button>
        <Tooltip id={`deletequery-${id}`}>
          <span>Delete Query</span>
        </Tooltip>
      </>
    );
  };

DeleteIconButton.defaultProps = {
  className: undefined,
};

export default DeleteIconButton;
