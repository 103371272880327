import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import ActionsMenu from 'components/ActionsMenu';
import Button, { ButtonProps, ButtonSize } from 'components/Button';
import React, { PropsWithChildren } from 'react';
import { Action } from 'types/action';

function getPadding(size?: ButtonSize) {
  switch (size) {
    case 'small':
      return 'px-2 py-1';
    default:
      return 'px-4 py-2';
  }
}

function getChevronPadding(size?: ButtonSize) {
  switch (size) {
    case 'small':
      return 'px-2 py-1';
    default:
      return 'px-3 py-2';
  }
}

type DropdownButtonProps = PropsWithChildren<
  {
    actions: Action[];
    mainButtonDataTestId?: string;
  } & ButtonProps
>;

const DropdownButton: React.FunctionComponent<DropdownButtonProps> =
  function DropdownButton({
    actions,
    children,
    size,
    mainButtonDataTestId,
    ...props
  }: DropdownButtonProps) {
    return (
      <div className="flex bg-emerald-600 rounded-md">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Button {...props} size={size || 'default'} padding={getPadding(size)}>
          <span data-cy={mainButtonDataTestId}>{children}</span>
        </Button>
        <ActionsMenu
          actions={actions}
          menuStyle="z-50 mt-10"
          button={
            <div className="border-l border-gray-300 border-opacity-40 flex items-center justify-center">
              <Menu.Button
                className={classNames(
                  'p-2 flex rounded-md items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 text-white',
                  getChevronPadding(size),
                )}
              >
                <span className="sr-only">Open options</span>
                <ChevronDownIcon className="w-5" />
              </Menu.Button>
            </div>
          }
        />
      </div>
    );
  };

DropdownButton.defaultProps = {
  mainButtonDataTestId: undefined,
};

export default DropdownButton;
