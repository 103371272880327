import React, { Fragment, FunctionComponent, useMemo, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { findByIdInGroup } from 'utils/ListUtils';
import Tabs from 'components/Tabs';
import { ClipLoader } from 'react-spinners';
import colors from 'tailwindcss/colors';
import EmptyStateMessage from 'components/EmptyStateMessage';

export interface CategorizedDropdownOptionIcon {
  component: FunctionComponent<{ alt?: string; className: string }> | string;
  alt?: string;
  fill?: string;
}

export interface CategorizedDropdownOption {
  label: string;
  value: string;
  icon?: CategorizedDropdownOptionIcon;
}

export interface CategorizedDropdownOptionGroup<T extends string> {
  label: string;
  value: T;
  options: CategorizedDropdownOption[];
  icon?: CategorizedDropdownOptionIcon;
}

interface CategorizedDropdownProps<T extends string> {
  label?: string;
  optionsGroups: CategorizedDropdownOptionGroup<T>[];
  defaultSelectedGroup: T;
  selected?: string;
  disabled?: boolean;
  onChange?: (group: T, value: string) => void;
  dropdownStyle?: 'default' | 'compressed' | 'inline';
  menuPosition?: 'top' | 'bottom' | 'right' | 'left';
  className?: string;
  placeholder?: string;
  loading?: boolean;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function getInitialSelectGroup<T extends string>(
  optionsGroups: CategorizedDropdownOptionGroup<T>[],
  selectedOption: string | undefined,
) {
  return findByIdInGroup<
    T,
    CategorizedDropdownOption,
    CategorizedDropdownOptionGroup<T>
  >(optionsGroups, selectedOption)[0];
}

const CategorizedDropdown = function CategorizedDropdown<T extends string>({
  disabled: disabledProp,
  label,
  optionsGroups,
  defaultSelectedGroup,
  selected,
  onChange: onChangeHandler,
  dropdownStyle,
  menuPosition,
  className,
  placeholder,
  loading,
}: CategorizedDropdownProps<T>) {
  const [activeGroup, setActiveGroup] = useState(defaultSelectedGroup);

  const selectedGroup: T = useMemo(
    () =>
      getInitialSelectGroup(optionsGroups, selected) ?? defaultSelectedGroup,
    [selected, defaultSelectedGroup],
  );

  const onChange = useMemo(
    () => (value: string) => {
      onChangeHandler?.(selectedGroup, value);
    },
    [selectedGroup, onChangeHandler],
  );

  const [, selectedOption] = findByIdInGroup<
    T,
    CategorizedDropdownOption,
    CategorizedDropdownOptionGroup<T>
  >(optionsGroups, selected);

  return (
    <Listbox value={selected} onChange={onChange} disabled={disabledProp}>
      {({ open, disabled }) => (
        <>
          {!!label && (
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              {label}
            </Listbox.Label>
          )}
          <div
            className={classNames(
              'relative',
              className,
              !!label && 'mt-1',
              dropdownStyle === 'inline' ? 'inline-block' : 'block',
            )}
          >
            <Listbox.Button
              className={classNames(
                'relative bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-8 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm',
                Boolean(disabled) &&
                  'disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none',
                dropdownStyle === 'inline' ? 'inline' : 'w-full',
              )}
            >
              <div className="flex items-center">
                {selectedOption ? (
                  <>
                    <span className="uppercase text-blue-500 font-medium">
                      {selectedGroup}:{' '}
                    </span>
                    {selectedOption.icon?.component && (
                      <selectedOption.icon.component
                        alt={selectedOption.icon.alt}
                        className={classNames(
                          'flex-shrink-0 h-6 w-6 rounded-full',
                          selectedOption.icon.fill ?? 'fill-slate-400',
                        )}
                      />
                    )}
                    <span
                      title={selectedOption.label}
                      className={classNames(
                        Boolean(selectedOption.icon?.component) && 'ml-2',
                        'truncate',
                      )}
                    >
                      {selectedOption.label}
                    </span>
                  </>
                ) : (
                  <span className="text-gray-500">
                    {placeholder ?? 'Select One'}
                  </span>
                )}
              </div>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className={classNames(
                  menuPosition === 'top' ? 'bottom-12' : '',
                  'absolute min-w-full mt-1 z-10 bg-white shadow-lg max-h-60 rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm',
                )}
              >
                {optionsGroups.map(({ value, options }) =>
                  value === activeGroup ? (
                    <Listbox.Options key={value}>
                      <div className="mb-1">
                        <Tabs
                          active={activeGroup}
                          tabs={optionsGroups.map(({ value: buttonLabel }) => ({
                            name: buttonLabel,
                          }))}
                          onClick={(name: T) => setActiveGroup(name)}
                          space="space-x-0"
                          tabPadding="px-5 py-2"
                          disabled={loading}
                        />
                      </div>
                      {loading && (
                        <div className="w-full h-20 flex items-center justify-center">
                          <ClipLoader
                            color={colors.gray['300']}
                            loading
                            size={22}
                            speedMultiplier={0.75}
                          />
                        </div>
                      )}
                      {!loading && !options?.length && (
                        <div className="w-full h-20 flex items-center justify-center">
                          <EmptyStateMessage>No items</EmptyStateMessage>
                        </div>
                      )}
                      {!loading &&
                        Boolean(options?.length) &&
                        options.map((option) => (
                          <Listbox.Option
                            key={option.value}
                            title={option.label}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'text-white bg-blue-500'
                                  : 'text-gray-900',
                                'cursor-default select-none relative py-2 pl-3 group',
                                dropdownStyle === 'compressed'
                                  ? 'pr-3'
                                  : 'pr-9',
                              )
                            }
                            value={option.value}
                          >
                            {({ selected: optionSelected, active }) => (
                              <>
                                <div className="flex items-center">
                                  {option.icon?.component && (
                                    <option.icon.component
                                      alt={option.icon.alt}
                                      className={classNames(
                                        'flex-shrink-0 h-6 w-6 rounded-full',
                                        option.icon.fill ?? 'fill-slate-400',
                                      )}
                                    />
                                  )}
                                  <span
                                    className={classNames(
                                      'ml-2 block truncate',
                                      optionSelected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                      dropdownStyle === 'compressed' &&
                                        optionSelected &&
                                        !active
                                        ? 'text-blue-500'
                                        : '',
                                      dropdownStyle === 'compressed' &&
                                        optionSelected &&
                                        active
                                        ? 'text-white'
                                        : '',
                                    )}
                                  >
                                    {option.label}
                                  </span>
                                </div>

                                {optionSelected &&
                                dropdownStyle !== 'compressed' ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-blue-500',
                                      'absolute inset-y-0 right-0 flex items-center pr-4',
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  ) : undefined,
                )}
              </div>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

CategorizedDropdown.defaultProps = {
  selected: undefined,
  disabled: false,
  onChange: undefined,
  dropdownStyle: 'default',
  menuPosition: 'bottom',
  label: undefined,
  className: undefined,
  placeholder: undefined,
  loading: false,
};

export default CategorizedDropdown;
