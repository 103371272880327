import {
  Comparator,
  ConceptParameter,
  MetadataParameter,
  MetadataType,
  NoCodeQuerySortItem,
  QueryParameters,
} from 'api/generated';
import {
  ConceptFilterType,
  FilterCondition,
} from 'components/DatasetSearch/AdvancedSearch/types';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

export function getValidSortItem(
  sortItem: Partial<NoCodeQuerySortItem> | undefined,
): NoCodeQuerySortItem | undefined {
  return sortItem && sortItem.direction && sortItem.field
    ? {
        direction: sortItem.direction,
        field: sortItem.field,
        conceptId: sortItem.conceptId,
      }
    : undefined;
}

export function getConceptFilterType(
  data: ConceptParameter[],
): ConceptFilterType {
  if (!data.length || data.length > 2) {
    return ConceptFilterType.Positive;
  }
  if (data.length === 2) {
    return ConceptFilterType.ProbBetween;
  }
  if (
    data[0].value === data[0].threshold &&
    data[0].comparator === Comparator.GreaterThan
  ) {
    return ConceptFilterType.Positive;
  }
  if (
    data[0].value === data[0].threshold &&
    data[0].comparator === Comparator.LessThan
  ) {
    return ConceptFilterType.Negative;
  }
  if (data[0].comparator === Comparator.GreaterThan) {
    return ConceptFilterType.ProbGreaterThan;
  }
  if (data[0].comparator === Comparator.LessThan) {
    return ConceptFilterType.ProbLessThan;
  }
  return ConceptFilterType.Positive;
}

function convertConceptParametersToFilterConditions(
  parameters: QueryParameters | undefined,
): FilterCondition[] {
  return (
    Object.values(
      parameters?.clause.conceptParameters?.reduce(
        (
          acc: { [key: string]: ConceptParameter[] },
          curr: ConceptParameter,
        ) => {
          if (acc[curr.conceptId]) {
            acc[curr.conceptId].push(curr);
            acc[curr.conceptId].sort((c1) =>
              c1.comparator === Comparator.GreaterThan ? 0 : 1,
            );
          } else {
            acc[curr.conceptId] = [curr];
          }
          return acc;
        },
        {},
      ) ?? {},
    ).map((ps: ConceptParameter[]) => {
      const t = getConceptFilterType(ps);
      return {
        id: uuidv4(),
        concepts: ps.map((p) => ({ ...p, type: t })),
      };
    }) ?? []
  );
}

function convertMetadataParametersToFilterConditions(
  parameters: QueryParameters | undefined,
): FilterCondition[] {
  return Object.values(
    parameters?.clause.metadataParameters?.reduce(
      (acc: MetadataParameter[][], curr: MetadataParameter) => {
        if (curr.type === 'datetime') {
          const existingEntryIdx = acc.findIndex(
            (mk) => mk[0].key === curr.key,
          );
          if (existingEntryIdx >= 0) {
            acc[existingEntryIdx].push(curr);
          } else {
            acc.push([curr]);
          }
        } else {
          acc.push([curr]);
        }
        return acc;
      },
      [],
    ) ?? {},
  )
    .map((ps: MetadataParameter[]) =>
      ps.sort((c1) => (c1.comparator === Comparator.GreaterThan ? -1 : 1)),
    )
    .map((ps: MetadataParameter[]) => ({
      id: uuidv4(),
      metadataKeys: ps,
    }))
    .sort((i1, i2) =>
      i1.metadataKeys[0].key < i2.metadataKeys[0].key ? -1 : 1,
    );
}

export function convertParametersToFilterConditions(
  parameters: QueryParameters | undefined,
): FilterCondition[] {
  return [
    ...convertConceptParametersToFilterConditions(parameters),
    ...convertMetadataParametersToFilterConditions(parameters),
  ];
}

export enum MetadataComparator {
  InDateRange = 'in_range',
}

export function getMetadataMatchOptions(
  type: MetadataType,
): (Comparator | MetadataComparator)[] {
  switch (type) {
    case 'number':
      return [
        Comparator.Equals,
        Comparator.NotEqual,
        Comparator.GreaterThan,
        Comparator.GreaterThanOrEqualTo,
        Comparator.LessThan,
        Comparator.LessThanOrEqualTo,
      ];
    case 'string':
      return [
        Comparator.Equals,
        Comparator.NotEqual,
        Comparator.EndsWith,
        Comparator.StartsWith,
        Comparator.Contains,
        Comparator.GreaterThan,
        Comparator.GreaterThanOrEqualTo,
        Comparator.LessThan,
        Comparator.LessThanOrEqualTo,
      ];
    case 'datetime':
      return [
        Comparator.GreaterThan,
        Comparator.LessThan,
        MetadataComparator.InDateRange,
      ];
    default:
      return [Comparator.Equals, Comparator.NotEqual];
  }
}

export function getLabelForComparator(
  comparator: Comparator | MetadataComparator,
  type?: MetadataType,
): string {
  if (type === 'datetime') {
    if (comparator === Comparator.LessThan) {
      return 'before';
    }
    if (comparator === Comparator.GreaterThan) {
      return 'after';
    }
  }
  switch (comparator) {
    case Comparator.LessThan:
      return 'is less than';
    case Comparator.LessThanOrEqualTo:
      return 'is less than or equal to';
    case Comparator.GreaterThan:
      return 'is greater than';
    case Comparator.GreaterThanOrEqualTo:
      return 'is greater than or equal to';
    case Comparator.Equals:
      return 'equals';
    case Comparator.NotEqual:
      return 'does not equal';
    case Comparator.StartsWith:
      return 'starts with';
    case Comparator.EndsWith:
      return 'ends with';
    case Comparator.Contains:
      return 'contains';
    case MetadataComparator.InDateRange:
      return 'in range';
    default:
      return comparator;
  }
}

export function createEmptyFilterRow(): Partial<FilterCondition> {
  return { id: uuidv4() };
}

export function getValidFilterRows(
  filterRows: Partial<FilterCondition>[],
): FilterCondition[] {
  return filterRows.filter(
    (row) =>
      (row.concepts?.length &&
        row.concepts[0]?.conceptId &&
        row.concepts[0].comparator &&
        (row.concepts[0].type !== ConceptFilterType.ProbBetween ||
          (row.concepts.length > 1 &&
            row.concepts[0].value !== null &&
            row.concepts[1].value !== null &&
            row.concepts[0].value !== undefined &&
            row.concepts[1].value !== undefined &&
            row.concepts[0].value < row.concepts[1].value))) ||
      (row.metadataKeys?.length &&
        row.metadataKeys[0]?.key &&
        row.metadataKeys[0].comparator &&
        row.metadataKeys[0].value),
  ) as FilterCondition[];
}

export function convertLocalDateToUTC(date: string): string | null {
  return DateTime.fromISO(date).setZone('utc').toISO({ includeOffset: false });
}

export function convertUTCDateToLocal(date: string): string | null {
  return DateTime.fromISO(date, { zone: 'utc' })
    .setZone('local')
    .toISO({ suppressMilliseconds: true, includeOffset: true });
}
