import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

interface ProgressBarProps {
  progress: number; // 0 - 100
  onComplete: () => void;
  started: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = function ProgressBar({
  progress,
  onComplete,
  started,
}) {
  const [initialBarMount, setInitialBarMount] = useState(true);
  const [leaveReady, setLeaveReady] = useState(false);
  const leaveReadyTimeout = useRef<any>();
  const complete = started && progress >= 100;

  useEffect(() => {
    if (started) {
      setInitialBarMount(false);
    }
  }, [started]);

  useEffect(() => {
    if (complete && !leaveReady && !leaveReadyTimeout.current) {
      leaveReadyTimeout.current = setTimeout(() => setLeaveReady(true), 300);
    }
    return () => clearTimeout(leaveReadyTimeout.current);
  }, [complete]);

  // TODO: Do we need this useCallback? Can we reference onComplete directly?
  const afterLeave = useCallback(() => {
    onComplete?.();
  }, [onComplete]);

  const value = Math.min(progress, 100);
  return (
    <div
      className={classNames('rounded-md w-full', {
        'bg-gray-200': !complete || !leaveReady,
        'bg-transparent': complete && leaveReady,
      })}
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin={0}
      aria-valuemax={100}
    >
      <Transition
        as="div"
        show={
          ((!complete || initialBarMount) && started) ||
          (started && complete && !leaveReady)
        }
        leave="transition ease-linear duration-300 delay-1000"
        leaveFrom="ml-auto scale-x-100 origin-right"
        leaveTo="ml-auto scale-x-0 origin-right"
        afterLeave={afterLeave}
        unmount={false}
      >
        <div
          className="rounded-md bg-blue-400 h-2 transition-width duration-300"
          style={{ width: initialBarMount ? `0%` : `${value}%` }}
        />
      </Transition>
    </div>
  );
};

export default ProgressBar;
