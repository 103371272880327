/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Possible concept label values. "skip" values are disregarded.
 * @export
 */
export const LabelEnum = {
    _1: '1',
    _0: '0',
    Skip: 'skip'
} as const;
export type LabelEnum = typeof LabelEnum[keyof typeof LabelEnum];


export function LabelEnumFromJSON(json: any): LabelEnum {
    return LabelEnumFromJSONTyped(json, false);
}

export function LabelEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelEnum {
    return json as LabelEnum;
}

export function LabelEnumToJSON(value?: LabelEnum | null): any {
    return value as any;
}

