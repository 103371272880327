/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetType,
    AssetTypeFromJSON,
    AssetTypeFromJSONTyped,
    AssetTypeToJSON,
} from './AssetType';
import {
    AudioSegment,
    AudioSegmentFromJSON,
    AudioSegmentFromJSONTyped,
    AudioSegmentToJSON,
} from './AudioSegment';
import {
    LabelEnum,
    LabelEnumFromJSON,
    LabelEnumFromJSONTyped,
    LabelEnumToJSON,
} from './LabelEnum';
import {
    PreviewImages,
    PreviewImagesFromJSON,
    PreviewImagesFromJSONTyped,
    PreviewImagesToJSON,
} from './PreviewImages';
import {
    Shot,
    ShotFromJSON,
    ShotFromJSONTyped,
    ShotToJSON,
} from './Shot';
import {
    VideoAsset,
    VideoAssetFromJSON,
    VideoAssetFromJSONTyped,
    VideoAssetToJSON,
} from './VideoAsset';

/**
 * An label response that also includes metadata of its associated image or video keyframe
 * @export
 * @interface FullConceptVersionLabelResponse
 */
export interface FullConceptVersionLabelResponse {
    /**
     * 
     * @type {AssetType}
     * @memberof FullConceptVersionLabelResponse
     */
    assetType: AssetType;
    /**
     * The id of the labelled image or video keyframe
     * @type {string}
     * @memberof FullConceptVersionLabelResponse
     */
    coactiveImageId: string;
    /**
     * 
     * @type {PreviewImages}
     * @memberof FullConceptVersionLabelResponse
     */
    previewImages: PreviewImages;
    /**
     * The original path of the asset. This is the video path if the asset_type is 'keyframe'.
     * @type {string}
     * @memberof FullConceptVersionLabelResponse
     */
    path?: string | null;
    /**
     * 
     * @type {VideoAsset}
     * @memberof FullConceptVersionLabelResponse
     */
    video?: VideoAsset;
    /**
     * 
     * @type {Shot}
     * @memberof FullConceptVersionLabelResponse
     */
    shot?: Shot;
    /**
     * Flag for a sensitive content warning
     * @type {boolean}
     * @memberof FullConceptVersionLabelResponse
     */
    sensitive?: boolean | null;
    /**
     * 
     * @type {AudioSegment}
     * @memberof FullConceptVersionLabelResponse
     */
    audioSegment?: AudioSegment;
    /**
     * Similarity search score of the asset, if it comes from intelligent search.
     * @type {number}
     * @memberof FullConceptVersionLabelResponse
     */
    similaritySearchScore?: number | null;
    /**
     * 
     * @type {LabelEnum}
     * @memberof FullConceptVersionLabelResponse
     */
    label: LabelEnum;
    /**
     * The label id
     * @type {string}
     * @memberof FullConceptVersionLabelResponse
     */
    labelId: string;
    /**
     * The image or keyframe metadata
     * @type {object}
     * @memberof FullConceptVersionLabelResponse
     */
    metadata?: object | null;
    /**
     * The classification score in the range [0, 1]
     * @type {number}
     * @memberof FullConceptVersionLabelResponse
     */
    score?: number | null;
    /**
     * The coactive image id
     * @type {string}
     * @memberof FullConceptVersionLabelResponse
     */
    conceptVersionId?: string | null;
    /**
     * The created timestamp of the label
     * @type {Date}
     * @memberof FullConceptVersionLabelResponse
     */
    createdDt: string;
    /**
     * The updated timestamp of the label
     * @type {Date}
     * @memberof FullConceptVersionLabelResponse
     */
    updatedDt: string;
}

export function FullConceptVersionLabelResponseFromJSON(json: any): FullConceptVersionLabelResponse {
    return FullConceptVersionLabelResponseFromJSONTyped(json, false);
}

export function FullConceptVersionLabelResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullConceptVersionLabelResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetType': AssetTypeFromJSON(json['assetType']),
        'coactiveImageId': json['coactiveImageId'],
        'previewImages': PreviewImagesFromJSON(json['previewImages']),
        'path': !exists(json, 'path') ? undefined : json['path'],
        'video': !exists(json, 'video') ? undefined : VideoAssetFromJSON(json['video']),
        'shot': !exists(json, 'shot') ? undefined : ShotFromJSON(json['shot']),
        'sensitive': !exists(json, 'sensitive') ? undefined : json['sensitive'],
        'audioSegment': !exists(json, 'audioSegment') ? undefined : AudioSegmentFromJSON(json['audioSegment']),
        'similaritySearchScore': !exists(json, 'similaritySearchScore') ? undefined : json['similaritySearchScore'],
        'label': LabelEnumFromJSON(json['label']),
        'labelId': json['labelId'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'conceptVersionId': !exists(json, 'conceptVersionId') ? undefined : json['conceptVersionId'],
        'createdDt': json['createdDt'],
        'updatedDt': json['updatedDt'],
    };
}

export function FullConceptVersionLabelResponseToJSON(value?: FullConceptVersionLabelResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assetType': AssetTypeToJSON(value.assetType),
        'coactiveImageId': value.coactiveImageId,
        'previewImages': PreviewImagesToJSON(value.previewImages),
        'path': value.path,
        'video': VideoAssetToJSON(value.video),
        'shot': ShotToJSON(value.shot),
        'sensitive': value.sensitive,
        'audioSegment': AudioSegmentToJSON(value.audioSegment),
        'similaritySearchScore': value.similaritySearchScore,
        'label': LabelEnumToJSON(value.label),
        'labelId': value.labelId,
        'metadata': value.metadata,
        'score': value.score,
        'conceptVersionId': value.conceptVersionId,
        'createdDt': value.createdDt,
        'updatedDt': value.updatedDt,
    };
}

