import { Disclosure } from '@headlessui/react';
import React, { PropsWithChildren } from 'react';

import { ChevronUpIcon } from '@heroicons/react/20/solid';
import Button from 'components/Button';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { MultiStepFlowAction } from 'types/action';

interface CollapsibleSectionProps {
  title: string;
  defaultOpen?: boolean;
  description?: string;
  primaryAction?: Partial<MultiStepFlowAction> &
    Pick<MultiStepFlowAction, 'action'>;
  secondaryAction?: Partial<MultiStepFlowAction> &
    Pick<MultiStepFlowAction, 'action'>;
  stepNumber?: number;
}

const CollapsibleSection: React.FC<PropsWithChildren<CollapsibleSectionProps>> =
  function CollapsibleSection({
    children,
    defaultOpen,
    description,
    primaryAction,
    secondaryAction,
    stepNumber,
    title,
  }: PropsWithChildren<CollapsibleSectionProps>) {
    const hasStepNumber = stepNumber !== undefined && stepNumber !== null;
    return (
      <div className="mx-auto w-full rounded-2xl bg-white p-2 shadow-sm border-[1px] border-gray-200">
        <Disclosure defaultOpen={defaultOpen}>
          {({ open }) => (
            <>
              <Disclosure.Button className="w-full rounded-lg p-2 text-left text-sm font-medium text-gray-900 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <div className="flex w-full justify-between items-center">
                  <div className="flex items-center grow">
                    <div className="flex flex-row items-center">
                      {hasStepNumber && (
                        <div className="inline rounded-full h-7 w-7 flex items-center justify-center bg-slate-100 mr-4">
                          {stepNumber}
                        </div>
                      )}
                      <span className="font-medium text-xl">{title}</span>
                    </div>
                  </div>
                  <ChevronUpIcon
                    className={classNames(
                      {
                        'rotate-180 transform': open,
                      },
                      'h-5 w-5 text-gray-500',
                    )}
                  />
                </div>
                {description && <div className="pl-11 pt-2">{description}</div>}
              </Disclosure.Button>
              <Disclosure.Panel
                className={classNames(
                  'px-4 pb-2 text-sm text-gray-800',
                  hasStepNumber ? 'pl-11' : '',
                )}
              >
                {children}
                {(primaryAction || secondaryAction) && (
                  <div
                    className={classNames(
                      'flex flex-row pt-4',
                      primaryAction && !secondaryAction
                        ? 'justify-end'
                        : 'justify-between',
                    )}
                  >
                    {secondaryAction && (
                      <Disclosure.Button
                        as={Button}
                        buttonStyle="secondary"
                        onClick={secondaryAction.action}
                        icon={
                          secondaryAction.leftIcon === undefined
                            ? ArrowUpIcon
                            : secondaryAction.leftIcon
                        }
                        rightIcon={secondaryAction.rightIcon}
                        disabled={secondaryAction.disabled}
                      >
                        {secondaryAction.label ?? 'Previous'}
                      </Disclosure.Button>
                    )}
                    {primaryAction && (
                      <Disclosure.Button
                        as={Button}
                        buttonStyle="secondary"
                        onClick={primaryAction.action}
                        icon={primaryAction.leftIcon}
                        rightIcon={
                          primaryAction.rightIcon === undefined
                            ? ArrowDownIcon
                            : primaryAction.rightIcon
                        }
                        disabled={primaryAction.disabled}
                      >
                        {primaryAction.label ?? 'Next'}
                      </Disclosure.Button>
                    )}
                  </div>
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    );
  };

CollapsibleSection.defaultProps = {
  stepNumber: undefined,
  defaultOpen: true,
  description: undefined,
  primaryAction: undefined,
  secondaryAction: undefined,
};

export default CollapsibleSection;
