/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LabelEnum,
    LabelEnumFromJSON,
    LabelEnumFromJSONTyped,
    LabelEnumToJSON,
} from './LabelEnum';

/**
 * An request to associate an image or video keyframe to a concept
 * @export
 * @interface ConceptLabelRequest
 */
export interface ConceptLabelRequest {
    /**
     * The id of image or video keyframe to label
     * @type {string}
     * @memberof ConceptLabelRequest
     */
    coactiveImageId?: string | null;
    /**
     * The path of an image to label
     * @type {string}
     * @memberof ConceptLabelRequest
     */
    imagePath?: string | null;
    /**
     * 
     * @type {LabelEnum}
     * @memberof ConceptLabelRequest
     */
    label?: LabelEnum;
}

export function ConceptLabelRequestFromJSON(json: any): ConceptLabelRequest {
    return ConceptLabelRequestFromJSONTyped(json, false);
}

export function ConceptLabelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConceptLabelRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coactiveImageId': !exists(json, 'coactiveImageId') ? undefined : json['coactiveImageId'],
        'imagePath': !exists(json, 'imagePath') ? undefined : json['imagePath'],
        'label': !exists(json, 'label') ? undefined : LabelEnumFromJSON(json['label']),
    };
}

export function ConceptLabelRequestToJSON(value?: ConceptLabelRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coactiveImageId': value.coactiveImageId,
        'imagePath': value.imagePath,
        'label': LabelEnumToJSON(value.label),
    };
}

