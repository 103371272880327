import { RefObject, useCallback, useEffect, useRef } from 'react';

/**
 * Dynamically adds height to a an empty component on the page so that a large popup can expand downward without being cut off
 * @param containerRef the container of the popup anchor and all elements below the popup anchor on the page
 * @param paddingRef the empty element to add padding to
 * @returns a function to set the current height of the popup (used to determine if additional padding on the page is required)
 */
function usePopupPadding(
  containerRef: RefObject<HTMLElement>,
  paddingRef: RefObject<HTMLElement>,
): { setPopupHeight: (height: number) => void } {
  const searchHeight = useRef(0);

  const adjustHeight = () => {
    const containerHeight = containerRef.current?.offsetHeight ?? 0;
    const spaceRequired = Math.max(0, searchHeight.current - containerHeight);
    paddingRef.current?.setAttribute('style', `height: ${spaceRequired}px;`);
  };

  const setPopupHeight = useCallback((height: number) => {
    searchHeight.current = height;
    adjustHeight();
  }, []);

  useEffect(() => {
    adjustHeight();
  });

  return { setPopupHeight };
}

export default usePopupPadding;
