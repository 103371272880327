import {
  DatasetStatusEnum,
  ErrorResponse,
  UploadResponse,
} from 'api/generated';
import CsvConceptsIllustration from 'assets/illustrations/csv-concepts.svg';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import DatasetDropdown from 'components/DatasetDropdown';
import ErrorText from 'components/ErrorText';
import FadeTransition from 'components/FadeTransition';
import FileUpload, { CompleteUpload } from 'components/FileUpload';
import Main from 'components/Main';
import { useCreateConceptsFromCsvMutation } from 'pages/concepts/create/queries';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EmbeddingDropdown from './components/EmbeddingDropdown';

const CreateConcepts = function CreateConcepts() {
  const navigate = useNavigate();
  const [datasetId, setDatasetId] = useState<string | null>(null);
  const [embeddingId, setEmbeddingId] = useState<string | null>(null);
  const [csvPath, setCsvPath] = useState<string | undefined>();
  const [appendEmbeddingName, setAppendEmbeddingName] =
    useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string | undefined>();
  const [submitError, setSubmitError] = useState<string | undefined>();
  const { mutate: createConcepts, isLoading } =
    useCreateConceptsFromCsvMutation();

  const submit = () => {
    if (embeddingId && csvPath) {
      createConcepts(
        {
          createConceptsFromCsvRequest: {
            embeddingId,
            csvPath,
            appendEmbeddingName,
          },
        },
        {
          onSuccess: async () => {
            navigate('/concepts');
          },
          onError: async (error: any) => {
            setSubmitError((await error.response.json()).detail);
          },
        },
      );
    }
  };

  return (
    <Main backButtonTo="/concepts" title={<h1>Create Concepts From CSV</h1>}>
      <div className="pb-24">
        <div className="px-6 py-4 bg-white rounded-md 2xl:max-w-8xl mx-auto">
          <div className="py-2 max-w-sm">
            <DatasetDropdown
              selectedId={datasetId}
              setSelectedId={(selectedId) => {
                setEmbeddingId(null);
                setDatasetId(selectedId);
              }}
              statusesToInclude={[DatasetStatusEnum.Ready]}
            />
          </div>
          <div className="py-2 max-w-sm">
            <EmbeddingDropdown
              datasetId={datasetId}
              selectedId={embeddingId}
              setSelectedId={(selectedId) => {
                setEmbeddingId(selectedId);
              }}
            />
          </div>

          <div className="py-2 max-w-sm">
            <Checkbox
              id="append-embedding-name"
              name="Append ambedding name"
              label="Append embedding name to concept names"
              checked={appendEmbeddingName}
              onChange={(e) => setAppendEmbeddingName(e.target.checked)}
            />
          </div>

          <div className="pt-8">
            <FadeTransition appear show={Boolean(embeddingId)}>
              {embeddingId && (
                <div className="max-w-4xl pb-6">
                  <FileUpload
                    uploadUrl={`${process.env.REACT_APP_API_HOST}/api/ui/concepts/upload/csv`}
                    onUploadSuccess={(
                      uploads: CompleteUpload<UploadResponse>[],
                    ) => setCsvPath(uploads[0].response.filename)}
                    onUploadError={(uploads: CompleteUpload<ErrorResponse>[]) =>
                      setUploadError(uploads[0].response.detail)
                    }
                    acceptedTypes={[
                      'text/csv',
                      'image/jpeg',
                      'image/gif',
                      'image/png',
                    ]}
                    illustrations={[
                      {
                        primary: CsvConceptsIllustration,
                        secondary: CsvConceptsIllustration,
                      },
                    ]}
                    error={uploadError}
                  >
                    <p className="max-w-80 pointer-events-none">
                      Drop a CSV with concept data or{' '}
                      <strong className="text-blue-500">browse</strong>.
                    </p>
                  </FileUpload>
                </div>
              )}
            </FadeTransition>
          </div>

          <div className="pt-5 flex">
            <Button
              type="submit"
              disabled={!csvPath}
              onClick={submit}
              loading={isLoading}
            >
              Create
            </Button>
            <Button
              type="button"
              buttonStyle="secondary"
              className="ml-3"
              onClick={() => navigate('/concepts')}
            >
              Cancel
            </Button>
          </div>
          <ErrorText errorStyle="form">{submitError}</ErrorText>
        </div>
      </div>
    </Main>
  );
};

export default CreateConcepts;
