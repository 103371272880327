import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { useUserContext } from 'context/UserContext';
import {
  CreateDatasetStep,
  FlowEndReason,
} from 'pages/datasets/add-assets/types';
import {
  getCurrentStep,
  getPreviousStep,
} from 'pages/datasets/add-assets/utils';
import {
  useGetDatasetUpdate,
  useIsDatasetUpdateMutatingOrFetching,
} from 'queries/dataset-update';
import {
  useGetDatasetById,
  useIsDatasetMutatingOrFetching,
} from 'queries/datasets';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MultiStepFlowAction } from 'types/action';

export interface AddAssetsNavigationContextState {
  activeStep: CreateDatasetStep | undefined;
  flowEndReason: FlowEndReason | undefined;
  next: MultiStepFlowAction | undefined;
  previous: MultiStepFlowAction | undefined;
  setActiveStep: (step: CreateDatasetStep) => void;
  setFlowEndReason: (flowEndReason: FlowEndReason) => void;
  setNext: (
    next?: Partial<MultiStepFlowAction> & Pick<MultiStepFlowAction, 'action'>,
  ) => void;
}

const AddAssetsNavigationContext = createContext({
  activeStep: 'Name',
  flowEndReason: undefined,
  next: undefined,
  previous: undefined,
  setActiveStep: () => null,
  setFlowEndReason: () => null,
  setNext: () => null,
} as AddAssetsNavigationContextState);

export const AddAssetsNavigationContextWrapper: React.FC<PropsWithChildren> =
  function AddAssetsNavigationContextWrapper({ children }) {
    const navigate = useNavigate();
    const { auth0User } = useUserContext();
    const { datasetId, datasetUpdateId } = useParams();
    const { data: datasetUpdate } = useGetDatasetUpdate(datasetUpdateId);
    const { data: dataset } = useGetDatasetById(datasetId);
    const datasetUpdateLoading = useIsDatasetUpdateMutatingOrFetching();
    const datasetLoading = useIsDatasetMutatingOrFetching();
    const [activeStep, setActiveStep] = useState<CreateDatasetStep>();
    const [flowEndReason, setFlowEndReason] = useState<FlowEndReason>();
    const [next, setNext] = useState<MultiStepFlowAction>();

    useEffect(() => {
      if (!datasetUpdateLoading && !datasetLoading) {
        if (!flowEndReason && datasetUpdate?.configuredDt) {
          navigate(`/datasets/${datasetUpdate.datasetId}`, { replace: true });
        } else {
          const nextStep = flowEndReason
            ? 'Confirmation'
            : getCurrentStep(
                dataset!,
                datasetUpdate,
                auth0User?.iss,
                auth0User?.email,
              );
          setActiveStep(nextStep);
        }
      }
    }, [
      auth0User,
      dataset?.status,
      !datasetUpdateLoading && !datasetLoading,
      datasetUpdate,
      flowEndReason,
    ]);

    useEffect(() => {
      if (
        !flowEndReason &&
        dataset?.status &&
        dataset?.status !== 'Incomplete'
      ) {
        navigate(`/datasets/${datasetId}`, { replace: true });
      }
    }, [dataset?.status, flowEndReason]);

    const previous = useMemo(() => {
      const previousStep = getPreviousStep(activeStep);
      const action = () => {
        setActiveStep(previousStep);
      };
      return previousStep
        ? {
            dataTestId: 'create-dataset-previous',
            key: 'previous',
            action,
            label: 'Previous',
            leftIcon: ArrowLeftIcon,
          }
        : undefined;
    }, [activeStep, setActiveStep]);

    const setNextCallback = useCallback(
      (
        n?: Partial<MultiStepFlowAction> & Pick<MultiStepFlowAction, 'action'>,
      ) => {
        setNext(
          n
            ? {
                ...{
                  dataTestId: 'create-dataset-next',
                  label: 'Next',
                  rightIcon: ArrowRightIcon,
                  loadingMessage: 'Saving progress...',
                },
                ...n,
              }
            : undefined,
        );
      },
      [],
    );

    const value = useMemo(
      () => ({
        activeStep,
        flowEndReason,
        next,
        previous,
        setActiveStep,
        setFlowEndReason,
        setNext: setNextCallback,
      }),
      [
        activeStep,
        flowEndReason,
        next,
        previous,
        setActiveStep,
        setFlowEndReason,
        setNext,
      ],
    );

    return (
      <AddAssetsNavigationContext.Provider value={value}>
        {children}
      </AddAssetsNavigationContext.Provider>
    );
  };

export function useAddAssetsNavigationContext() {
  return useContext(AddAssetsNavigationContext);
}

export default AddAssetsNavigationContext;
