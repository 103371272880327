/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Get dynamic tag prompts request object
 * @export
 * @interface GetDynamicTagPromptsRequest
 */
export interface GetDynamicTagPromptsRequest {
    /**
     * A list of dynamic tag ids to fetch prompts for
     * @type {Array<string>}
     * @memberof GetDynamicTagPromptsRequest
     */
    dynamicTagIds: Array<string>;
}

export function GetDynamicTagPromptsRequestFromJSON(json: any): GetDynamicTagPromptsRequest {
    return GetDynamicTagPromptsRequestFromJSONTyped(json, false);
}

export function GetDynamicTagPromptsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDynamicTagPromptsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dynamicTagIds': json['dynamicTagIds'],
    };
}

export function GetDynamicTagPromptsRequestToJSON(value?: GetDynamicTagPromptsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dynamicTagIds': value.dynamicTagIds,
    };
}

