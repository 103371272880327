import { ClassificationApi } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useQuery from 'hooks/useQuery';

export const useFetchClassificationScores = (coactiveImageId: string) => {
  const { initializeAPI } = useAPIContext();

  return useQuery(['fetchClassificationScores', coactiveImageId], async () => {
    const api = await initializeAPI<ClassificationApi>(ClassificationApi);
    return api.classifyImage({
      classificationRequest: {
        coactiveImageId,
      },
    });
  });
};
