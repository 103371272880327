/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetType,
    AssetTypeFromJSON,
    AssetTypeFromJSONTyped,
    AssetTypeToJSON,
} from './AssetType';
import {
    AudioSegment,
    AudioSegmentFromJSON,
    AudioSegmentFromJSONTyped,
    AudioSegmentToJSON,
} from './AudioSegment';
import {
    PreviewImages,
    PreviewImagesFromJSON,
    PreviewImagesFromJSONTyped,
    PreviewImagesToJSON,
} from './PreviewImages';
import {
    Shot,
    ShotFromJSON,
    ShotFromJSONTyped,
    ShotToJSON,
} from './Shot';
import {
    VideoAsset,
    VideoAssetFromJSON,
    VideoAssetFromJSONTyped,
    VideoAssetToJSON,
} from './VideoAsset';

/**
 * Metadata about an image, video, or a keyframe of a video
 * @export
 * @interface AssetMetadata
 */
export interface AssetMetadata {
    /**
     * 
     * @type {AssetType}
     * @memberof AssetMetadata
     */
    assetType: AssetType;
    /**
     * The coactive image id (if the asset_type is 'image' or 'keyframe')
     * @type {string}
     * @memberof AssetMetadata
     */
    coactiveImageId: string;
    /**
     * 
     * @type {PreviewImages}
     * @memberof AssetMetadata
     */
    previewImages: PreviewImages;
    /**
     * The original path of the asset. This is the video path if the asset_type is 'keyframe'.
     * @type {string}
     * @memberof AssetMetadata
     */
    path?: string | null;
    /**
     * 
     * @type {VideoAsset}
     * @memberof AssetMetadata
     */
    video?: VideoAsset;
    /**
     * 
     * @type {Shot}
     * @memberof AssetMetadata
     */
    shot?: Shot;
    /**
     * Flag for a sensitive content warning
     * @type {boolean}
     * @memberof AssetMetadata
     */
    sensitive?: boolean | null;
    /**
     * 
     * @type {AudioSegment}
     * @memberof AssetMetadata
     */
    audioSegment?: AudioSegment;
    /**
     * Similarity search score of the asset, if it comes from intelligent search.
     * @type {number}
     * @memberof AssetMetadata
     */
    similaritySearchScore?: number | null;
}

export function AssetMetadataFromJSON(json: any): AssetMetadata {
    return AssetMetadataFromJSONTyped(json, false);
}

export function AssetMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetType': AssetTypeFromJSON(json['assetType']),
        'coactiveImageId': json['coactiveImageId'],
        'previewImages': PreviewImagesFromJSON(json['previewImages']),
        'path': !exists(json, 'path') ? undefined : json['path'],
        'video': !exists(json, 'video') ? undefined : VideoAssetFromJSON(json['video']),
        'shot': !exists(json, 'shot') ? undefined : ShotFromJSON(json['shot']),
        'sensitive': !exists(json, 'sensitive') ? undefined : json['sensitive'],
        'audioSegment': !exists(json, 'audioSegment') ? undefined : AudioSegmentFromJSON(json['audioSegment']),
        'similaritySearchScore': !exists(json, 'similaritySearchScore') ? undefined : json['similaritySearchScore'],
    };
}

export function AssetMetadataToJSON(value?: AssetMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assetType': AssetTypeToJSON(value.assetType),
        'coactiveImageId': value.coactiveImageId,
        'previewImages': PreviewImagesToJSON(value.previewImages),
        'path': value.path,
        'video': VideoAssetToJSON(value.video),
        'shot': ShotToJSON(value.shot),
        'sensitive': value.sensitive,
        'audioSegment': AudioSegmentToJSON(value.audioSegment),
        'similaritySearchScore': value.similaritySearchScore,
    };
}

