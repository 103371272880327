import TextInput from 'components/TextInput';
import React, { ChangeEvent, useCallback } from 'react';

interface GsDataSourceInputProps {
  defaultValue: string | null | undefined;
  setPrefix: (prefix: string) => void;
}

/**
 * The input component for Google Storage prefixes.
 */
const GsDataSourceInput = function GsDataSourceInput({
  defaultValue,
  setPrefix,
}: GsDataSourceInputProps) {
  const validateBucketPath = useCallback((value: string) => {
    const regex = /^gs:\/\/([^/]+)((\/(.*?([^/]+)))*(\/?))$/;
    const valid = !value || regex.test(value);
    return Promise.resolve({
      valid: {
        value: valid,
        message: valid
          ? undefined
          : 'The provided Google Storage prefix is not valid',
      },
      unique: { value: true },
    });
  }, []);

  // TODO
  return (
    <TextInput
      label="Google Storage bucket prefix"
      id="bucket-path"
      name="bucket path"
      placeholder="bucket-name/path/prefix/"
      onChange={(e: ChangeEvent<HTMLInputElement>) => setPrefix(e.target.value)}
      validation={validateBucketPath}
      prefix="gs://"
      defaultValue={defaultValue ?? ''}
    />
  );
};

export default GsDataSourceInput;
