import { DateTime } from 'luxon';
import React from 'react';

const RelativeTimeEyebrow: React.FC<{ timestamp: string }> = ({
  timestamp,
}) => {
  const created = DateTime.fromISO(timestamp, { zone: 'utc' });

  const now = DateTime.now().toUTC();
  return (
    <span>
      {now.toSeconds() - created.toSeconds() < 120
        ? 'A moment ago'
        : created.toRelative()}
    </span>
  );
};

export default RelativeTimeEyebrow;
