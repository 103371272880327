import {
  AssetResponse,
  FullDatasetResponse,
  QueryStatus,
  QueryTableRowUI,
} from 'api/generated';
import { SearchMode } from 'components/DatasetSearch/AdvancedSearch/types';
import DatasetSearch from 'components/DatasetSearch/index';
import SearchResultsHeader from 'components/TextSearch/SearchResultsHeader';
import { DropdownOption } from 'components/Dropdown';
import EmptyStateMessage from 'components/EmptyStateMessage';
import FadeTransition from 'components/FadeTransition';
import QueryResult from 'components/QueryResult';
import RelativeTimeEyebrow from 'components/RelativeTimeEyebrow';
import usePopupPadding from 'hooks/usePopupPadding';
import { Duration } from 'luxon';
import { useGetQueryWithoutResults } from 'pages/queries/queries';
import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const QuerySearchResults: React.FC<{
  setSelectedAsset: (data: QueryTableRowUI, embeddingId?: string) => void;
}> = ({ setSelectedAsset }) => {
  const { queryId } = useParams();
  const {
    refetch,
    data: query,
    isLoading,
    isLoadingError,
  } = useGetQueryWithoutResults(queryId || '', {
    enabled: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (query?.status === QueryStatus.Error) {
      navigate(
        `/datasets/${query?.dataset
          .id}/search/${queryId}/${QueryStatus.Error.toLowerCase()}`,
      );
    }
  }, [queryId, query?.status]);

  const queryDeleted = isLoadingError;

  return queryId ? (
    <div className="min-h-[10rem]">
      {queryDeleted && (
        <div className="text-center py-12 px-2 text-sm">
          <EmptyStateMessage>
            <p>Search Not Found</p>
          </EmptyStateMessage>
        </div>
      )}
      {!queryDeleted && !query?.isActive && query?.createdDt && (
        <div className="text-sm pb-2">
          <RelativeTimeEyebrow timestamp={query?.createdDt} />
        </div>
      )}
      {!queryDeleted && (
        <QueryResult
          queryId={queryId}
          query={query}
          isLoading={isLoading}
          refetch={refetch}
          setSidebarData={(asset) =>
            setSelectedAsset(asset, query?.embedding.id)
          }
          runQuery={() => null}
          embeddingId={query?.embedding.id}
          basePath=""
          updateIntervalMs={1000}
          queuedMessage="Your search is queued. We'll begin executing it shortly."
          stoppingMessage="Your search is stopping."
          runningMessage={(timeElapsed: Duration | undefined) =>
            `Your search is ${
              timeElapsed && timeElapsed.seconds > 60 ? 'still ' : ''
            }executing.`
          }
          cancelSearchMessage="Cancel Search"
          stopSearchMessage="Cancel Search"
          showConceptDetail
        />
      )}
    </div>
  ) : null;
};

const AdvancedSearchResults: React.FC<{
  datasetId: string | undefined;
  dataset: FullDatasetResponse | undefined;
  searchActive: boolean;
  setSelectedAsset: (selected: {
    asset?: AssetResponse;
    data?: QueryTableRowUI;
    embeddingId?: string;
  }) => void;
  setSearchMode: (mode: SearchMode) => void;
  searchModeOptions: DropdownOption<SearchMode>[];
  setTextSearchActive: (active: boolean) => void;
  loadingGrid: any;
}> = ({
  datasetId,
  dataset,
  searchActive,
  setSelectedAsset,
  setSearchMode,
  searchModeOptions,
  setTextSearchActive,
  loadingGrid,
}) => {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const extraSpaceRef = useRef<HTMLDivElement>(null);
  const { setPopupHeight } = usePopupPadding(containerRef, extraSpaceRef);

  return dataset ? (
    <>
      <div ref={containerRef}>
        {dataset.isSearchable ? (
          <DatasetSearch
            searchMode="advanced-visual"
            searchModeOptions={searchModeOptions}
            setSearchMode={setSearchMode}
            datasetId={datasetId || ''}
            onClick={(asset) => setSelectedAsset({ asset })}
            setTextSearchActive={setTextSearchActive}
            setPopupHeight={setPopupHeight}
          />
        ) : undefined}
        <SearchResultsHeader
          clearSearch={() => navigate(`/datasets/${datasetId}`)}
        />
        <QuerySearchResults
          setSelectedAsset={(data, embeddingId) =>
            setSelectedAsset({ data, embeddingId })
          }
        />
      </div>
      <div ref={extraSpaceRef} />
    </>
  ) : (
    <FadeTransition
      appear
      show={!dataset && !searchActive}
      exit={false}
      enterDelayMs={500}
    >
      {!dataset && loadingGrid}
    </FadeTransition>
  );
};

export default AdvancedSearchResults;
