import React, { PropsWithChildren } from 'react';

interface EmptyStateMessageProps {
  dataTestId?: string;
}

const EmptyStateMessage: React.FC<PropsWithChildren<EmptyStateMessageProps>> =
  function EmptyStateMessage({ children, dataTestId }) {
    return (
      <div
        data-cy={dataTestId ? `${dataTestId}-empty-state` : undefined}
        className="text-sm font-medium text-gray-400 uppercase tracking-wider"
      >
        {children}
      </div>
    );
  };

EmptyStateMessage.defaultProps = {
  dataTestId: undefined,
};

export default EmptyStateMessage;
