import { DatasetUpdateResponse } from 'api/generated';
import Button from 'components/Button';
import { useUserContext } from 'context/UserContext';
import { useResendDatasetUpdateInvitationLinks } from 'queries/dataset-update';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toEnglishList } from 'utils/ListUtils';

interface IncompleteDatasetUpdatesProps {
  updates: DatasetUpdateResponse[];
}

const IncompleteDatasetUpdates: React.FC<IncompleteDatasetUpdatesProps> =
  function IncompleteDatasetUpdates({ updates }) {
    const { auth0User } = useUserContext();
    const navigate = useNavigate();
    const { mutate: resendDatasetUpdateInvitationLinks } =
      useResendDatasetUpdateInvitationLinks();

    const datasetUpdatesWithInvitations = useMemo(() => {
      const currentUserEmail = auth0User?.email;
      if (updates?.length) {
        return updates?.filter(
          (update) =>
            update.invitedUserEmail &&
            update.invitedUserEmail !== currentUserEmail,
        );
      }
      return [];
    }, [updates, auth0User?.email]);

    const createDatasetHelpRequested = updates?.find(
      (update) => update.unsupportedDataSource,
    );

    const mainDatasetUpdate = updates?.[0];
    const pendingInvitationEmailAddresses = Array.from(
      new Set(
        datasetUpdatesWithInvitations
          .filter((update) => update.invitedUserEmail)
          .map((update) => update.invitedUserEmail!),
      ).values(),
    );

    return (
      <div className="pb-8">
        {datasetUpdatesWithInvitations?.length > 0 && (
          <div className="text-sm mb-4">
            {toEnglishList(pendingInvitationEmailAddresses, 'font-semibold')}{' '}
            {datasetUpdatesWithInvitations?.length > 1 ? 'have' : 'has'} been
            invited to complete creating this dataset
          </div>
        )}
        {createDatasetHelpRequested && (
          <div className="text-sm mb-4">
            Someone from Coactive will follow up through email within 1-2
            business days to help you add your data so you can start searching
            through your dataset.
          </div>
        )}
        <div className="flex flex-row items-center">
          <Button
            buttonStyle="secondary"
            onClick={() =>
              navigate(
                `/datasets/${mainDatasetUpdate.datasetId}/add-assets/${mainDatasetUpdate.id}`,
              )
            }
          >
            Complete dataset
          </Button>
          {datasetUpdatesWithInvitations?.length > 0 && (
            <Button
              buttonStyle="link"
              onClick={async () => {
                resendDatasetUpdateInvitationLinks(
                  {
                    resendDatasetUpdateInvitationsRequest: {
                      datasetUpdateIds: datasetUpdatesWithInvitations.map(
                        (update) => update.id,
                      ),
                    },
                  },
                  {
                    onSuccess: () => {
                      toast.success(
                        <span>
                          {`Invite${
                            pendingInvitationEmailAddresses?.length > 1
                              ? 's'
                              : ''
                          } resent to `}
                          {toEnglishList(pendingInvitationEmailAddresses)}
                        </span>,
                        { position: toast.POSITION.BOTTOM_CENTER },
                      );
                    },
                    onError: () => {
                      toast.error(
                        <span>
                          {`Unable to resend invitation${
                            pendingInvitationEmailAddresses?.length > 1
                              ? 's'
                              : ''
                          } to `}
                          {toEnglishList(pendingInvitationEmailAddresses)}. Try
                          again.
                        </span>,
                        { position: toast.POSITION.BOTTOM_CENTER },
                      );
                    },
                  },
                );
              }}
              className="ml-2"
            >
              Resend invitation link
              {pendingInvitationEmailAddresses.length > 1 ? 's' : ''}
            </Button>
          )}
        </div>
      </div>
    );
  };

export default IncompleteDatasetUpdates;
