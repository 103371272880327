import createPlaceholderArray from 'utils/createPlaceholderArray';
import React from 'react';
import LoadingBlock from 'components/LoadingBlock';

const LoadingList = function LoadingList() {
  return (
    <div>
      {createPlaceholderArray(9).map((key) => (
        <LoadingBlock
          key={key}
          style={{ width: `${Math.random() * 60 + 40}%` }}
          className="h-3 my-5"
        />
      ))}
    </div>
  );
};

export default LoadingList;
