/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ColumnDataType = {
    Date: 'date'
} as const;
export type ColumnDataType = typeof ColumnDataType[keyof typeof ColumnDataType];


export function ColumnDataTypeFromJSON(json: any): ColumnDataType {
    return ColumnDataTypeFromJSONTyped(json, false);
}

export function ColumnDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnDataType {
    return json as ColumnDataType;
}

export function ColumnDataTypeToJSON(value?: ColumnDataType | null): any {
    return value as any;
}

