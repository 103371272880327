/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldValidation,
    FieldValidationFromJSON,
    FieldValidationFromJSONTyped,
    FieldValidationToJSON,
} from './FieldValidation';

/**
 * All properties included to validate names via the UI
 * @export
 * @interface ValidateDatasetNameResponse
 */
export interface ValidateDatasetNameResponse {
    /**
     * 
     * @type {FieldValidation}
     * @memberof ValidateDatasetNameResponse
     */
    valid: FieldValidation;
    /**
     * 
     * @type {FieldValidation}
     * @memberof ValidateDatasetNameResponse
     */
    unique: FieldValidation;
}

export function ValidateDatasetNameResponseFromJSON(json: any): ValidateDatasetNameResponse {
    return ValidateDatasetNameResponseFromJSONTyped(json, false);
}

export function ValidateDatasetNameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateDatasetNameResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valid': FieldValidationFromJSON(json['valid']),
        'unique': FieldValidationFromJSON(json['unique']),
    };
}

export function ValidateDatasetNameResponseToJSON(value?: ValidateDatasetNameResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valid': FieldValidationToJSON(value.valid),
        'unique': FieldValidationToJSON(value.unique),
    };
}

