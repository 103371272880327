import {
  DatasetStatusEnum,
  EncoderNameEnum,
  KeyframeSamplingMethodEnum,
} from 'api/generated';
import { RadioTabsGroupOption } from 'components/RadioTabsGroup';
import TextInput from 'components/TextInput';
import { useUserContext } from 'context/UserContext';
import { useAddAssetsNavigationContext } from 'pages/datasets/add-assets/context/AddAssetsNavigationContext';
import AddAssetsViewContainer from 'pages/datasets/components/AddAssetsViewContainer';
import DatasetAdvancedSettings from 'pages/datasets/components/DatasetAdvancedSettings';
import {
  invalidateDatasetQueries,
  useCreateDatasetMutation,
  useGetDatasetById,
  useUpdateDatasetMutation,
  useValidateDatasetNameMutation,
} from 'queries/datasets';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { inputValidationCallbackFactory } from 'utils/InputValidationUtils';

/**
 * The main view of the "Name" step in the create dataset flow.
 */
const SetupView = function SetupView() {
  const { datasetId } = useParams();
  const { data: dataset } = useGetDatasetById(datasetId);
  const { setNext, setActiveStep } = useAddAssetsNavigationContext();
  const queryClient = useQueryClient();

  const { me } = useUserContext();
  const [name, setName] = useState<string>(dataset?.name ?? '');
  const [nameHasError, setNameHasError] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>();
  const [description, setDescription] = useState<string>(
    dataset?.description ?? '',
  );
  const { mutate: validateDatasetName, isLoading: isDatasetNameValidating } =
    useValidateDatasetNameMutation();
  const { mutate: createDataset, isLoading: isDatasetCreating } =
    useCreateDatasetMutation();
  const { mutate: updateDataset } = useUpdateDatasetMutation();
  const navigate = useNavigate();
  const [encoder, setEncoder] = useState<EncoderNameEnum>(
    EncoderNameEnum.MultimodalTxLarge3,
  );
  const [samplingRate, setSamplingRate] = useState<number | null>(null);

  const encoderOptions: { label: string; value: EncoderNameEnum }[] = [
    {
      label: `${EncoderNameEnum.MultimodalTxLarge3} (default)`,
      value: EncoderNameEnum.MultimodalTxLarge3,
    },
    { label: EncoderNameEnum.Multimodal, value: EncoderNameEnum.Multimodal },
    {
      label: EncoderNameEnum.MultimodalTxSmall1,
      value: EncoderNameEnum.MultimodalTxSmall1,
    },
    {
      label: EncoderNameEnum.MultimodalTxSmall2,
      value: EncoderNameEnum.MultimodalTxSmall2,
    },
    {
      label: EncoderNameEnum.MultimodalTxLarge1,
      value: EncoderNameEnum.MultimodalTxLarge1,
    },
    {
      label: EncoderNameEnum.MultimodalTxLarge2,
      value: EncoderNameEnum.MultimodalTxLarge2,
    },
  ];

  // needs to be a useMemo or RadioTabGroup wont pick up checked options styles
  const samplingMethodOptions: RadioTabsGroupOption<KeyframeSamplingMethodEnum>[] =
    useMemo(
      () => [
        {
          name: 'Intelligent sampling',
          value: KeyframeSamplingMethodEnum.Intelligent,
        },
        { name: 'Uniform sampling', value: KeyframeSamplingMethodEnum.Uniform },
      ],
      [],
    );

  const [samplingMethod, setSamplingMethod] = useState<
    RadioTabsGroupOption<KeyframeSamplingMethodEnum>
  >(samplingMethodOptions[0]);

  const inputValidation = inputValidationCallbackFactory<string>(
    dataset?.name,
    validateDatasetName,
    (value: string) => ({ validateDatasetNameRequest: { name: value } }),
    [dataset],
  );

  useEffect(() => {
    const action = () => {
      if (!name) {
        setSubmitError('Dataset name is required');
      } else if (name && !dataset) {
        createDataset(
          {
            createDatasetRequest: {
              name,
              description,
              encoder,
              keyframeSamplingMethod: samplingMethod.value,
              keyframeUniformFpsSamplingRate:
                samplingMethod.value === KeyframeSamplingMethodEnum.Uniform
                  ? samplingRate
                  : null,
              status: DatasetStatusEnum.Incomplete,
            },
          },
          {
            onSuccess: (response) => {
              navigate(`/datasets/${response?.datasetId}/add-assets`, {
                replace: true,
              });
            },
            onError: async (error: any) => {
              setSubmitError((await error.response.json()).detail);
            },
          },
        );
      } else if (
        dataset &&
        (dataset.name !== name || dataset.description !== description)
      ) {
        updateDataset(
          {
            datasetId: dataset.datasetId,
            updateDatasetRequest: { name, description },
          },
          {
            onSuccess: (response) => {
              setActiveStep('Data Source');
              invalidateDatasetQueries(queryClient, response.datasetId);
            },
            onError: async (error: any) => {
              setSubmitError((await error.response.json()).detail);
            },
          },
        );
      } else {
        // No changes to an existing dataset - move to next step.
        setActiveStep('Data Source');
      }
    };

    const samplingRateInRange =
      samplingRate !== null && samplingRate >= 0 && samplingRate <= 60;

    const invalidSamplingRate =
      samplingMethod.value === KeyframeSamplingMethodEnum.Uniform &&
      (samplingRate === null || !samplingRateInRange);

    const disabled =
      !name ||
      isDatasetCreating ||
      isDatasetNameValidating ||
      nameHasError ||
      invalidSamplingRate;

    setNext({ disabled, action });
  }, [
    dataset?.name,
    dataset?.description,
    name,
    description,
    encoder,
    samplingMethod,
    samplingRate,
    isDatasetCreating,
    isDatasetNameValidating,
    nameHasError,
  ]);

  const orgName = me?.organization.displayName.replaceAll(
    /[^a-zA-Z0-9_]/gi,
    '',
  );

  return (
    <AddAssetsViewContainer
      title="Name your dataset"
      description="A name that gives a nice overview of what's in your dataset."
      isCreate
      error={submitError}
    >
      <div className="py-6">
        <TextInput
          dataTestId="dataset-name"
          label="Dataset name"
          id="dataset-name"
          name="dataset name"
          placeholder={`${orgName ? `${orgName}_` : ''}Coactive_trial`}
          value={name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
          validation={inputValidation}
          setHasError={setNameHasError}
        />
      </div>
      <div className="py-2">
        <TextInput
          dataTestId="dataset-description"
          label="Description"
          id="description"
          name="description"
          value={description}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setDescription(e.target.value)
          }
          executeValidationOnBlur
          optional
        />
      </div>
      <div className="mt-14">
        <DatasetAdvancedSettings
          encoderOptions={encoderOptions}
          encoder={encoder}
          setEncoder={setEncoder}
          samplingMethodOptions={samplingMethodOptions}
          samplingMethod={samplingMethod}
          setSamplingMethod={setSamplingMethod}
          setSamplingRate={setSamplingRate}
        />
      </div>
    </AddAssetsViewContainer>
  );
};

export default SetupView;
