import { DeleteQueryByIdRequest, QueryApi } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import { useUserContext } from 'context/UserContext';
import useInfiniteQuery from 'hooks/useInfiniteQuery';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import toNumber from 'utils/ToNumber';

const HISTORY_PAGE_SIZE = 40;

export const getDatasetSearchParametersQueryKey = (datasetId: string) => [
  'getDatasetSearchParameters',
  datasetId,
];

export const useGetDatasetSearchParametersQuery = (datasetId: string) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    getDatasetSearchParametersQueryKey(datasetId),
    async ({ signal }) => {
      const api = await initializeAPI<QueryApi>(QueryApi);
      return api.getQueryableColumns({ datasetId }, { signal });
    },
    { cacheTime: 0 },
  );
};

export const getDatasetAdvancedSearchQueryKey = (
  queryId: string | undefined,
) => ['getQueryById', queryId];

export const useGetDatasetAdvancedSearchQuery = (
  queryId: string | undefined,
) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    getDatasetAdvancedSearchQueryKey(queryId),
    async ({ signal }) => {
      const api = await initializeAPI<QueryApi>(QueryApi);
      return api.getQueryById(
        { queryId: queryId!, excludeResults: true },
        { signal },
      );
    },
    { cacheTime: 0, enabled: Boolean(queryId) },
  );
};

export const useGetDatasetAdvancedSearchHistoryInfiniteQueryKey = (
  datasetId: string,
) => ['getQueries', datasetId];

export const useGetDatasetAdvancedSearchHistoryInfiniteQuery = (
  datasetId: string,
) => {
  const { initializeAPI } = useAPIContext();
  const { auth0User } = useUserContext();
  const query = useInfiniteQuery(
    useGetDatasetAdvancedSearchHistoryInfiniteQueryKey(datasetId),
    async ({ queryKey, signal, pageParam }) => {
      const [, _datasetId] = queryKey;
      if (typeof _datasetId === 'undefined') {
        throw new Error('datasetId not set');
      }
      const api = await initializeAPI<QueryApi>(QueryApi);
      return api.getQueries(
        {
          datasetId: _datasetId.toString(),
          userIds: [auth0User?.sub!],
          excludeCode: true,
          excludeNoCode: false,
          offset: HISTORY_PAGE_SIZE * toNumber(pageParam || 0),
          limit: HISTORY_PAGE_SIZE,
        },
        { signal },
      );
    },
    {
      enabled: true,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.meta?.page?.currentPage,
    },
  );
  return query;
};

export const useDeleteDatasetSearchQuery = (datasetId: string) => {
  const queryClient = useQueryClient();
  const { initializeAPI } = useAPIContext();
  return useMutation(
    ['deleteQueryById'],
    async (variables: DeleteQueryByIdRequest) => {
      const api = await initializeAPI<QueryApi>(QueryApi);
      return api.deleteQueryById(variables);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          useGetDatasetAdvancedSearchHistoryInfiniteQueryKey(datasetId),
        );
      },
      onError: () => {
        toast.error('Unable to delete the search item. Try again.');
      },
    },
  );
};
