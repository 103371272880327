/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateVersionRequest,
  ErrorResponse,
  FullVersionResponse,
  GetVersionListResponse,
  HTTPValidationError,
} from '../models';
import {
    CreateVersionRequestFromJSON,
    CreateVersionRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FullVersionResponseFromJSON,
    FullVersionResponseToJSON,
    GetVersionListResponseFromJSON,
    GetVersionListResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface CreateVersionOperationRequest {
    createVersionRequest: CreateVersionRequest;
}

export interface DeleteVersionByIdRequest {
    versionId: string;
}

export interface GetLatestConceptVersionByIdRequest {
    conceptId: string;
}

export interface GetVersionByIdRequest {
    versionId: string;
}

export interface GetVersionCsvRequest {
    versionId: string;
    offset?: number | null;
    limit?: number | null;
}

export interface GetVersionsRequest {
    conceptId: string;
    offset?: number | null;
    limit?: number | null;
}

/**
 * VersionApi - interface
 * 
 * @export
 * @interface VersionApiInterface
 */
export interface VersionApiInterface {
    /**
     * Create concept version
     * @summary Create concept version
     * @param {CreateVersionRequest} createVersionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApiInterface
     */
    createVersionRaw(requestParameters: CreateVersionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullVersionResponse>>;

    /**
     * Create concept version
     * Create concept version
     */
    createVersion(requestParameters: CreateVersionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullVersionResponse>;

    /**
     * Delete concept version by id
     * @summary Delete concept version by id
     * @param {string} versionId The concept version id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApiInterface
     */
    deleteVersionByIdRaw(requestParameters: DeleteVersionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Delete concept version by id
     * Delete concept version by id
     */
    deleteVersionById(requestParameters: DeleteVersionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Get latest concept version by concept id
     * @summary Get latest concept version by concept id
     * @param {string} conceptId The unique identifier of the concept to get the latest version for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApiInterface
     */
    getLatestConceptVersionByIdRaw(requestParameters: GetLatestConceptVersionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullVersionResponse>>;

    /**
     * Get latest concept version by concept id
     * Get latest concept version by concept id
     */
    getLatestConceptVersionById(requestParameters: GetLatestConceptVersionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullVersionResponse>;

    /**
     * Get concept version by id
     * @summary Get concept version by id
     * @param {string} versionId The concept version id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApiInterface
     */
    getVersionByIdRaw(requestParameters: GetVersionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullVersionResponse>>;

    /**
     * Get concept version by id
     * Get concept version by id
     */
    getVersionById(requestParameters: GetVersionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullVersionResponse>;

    /**
     * Concept version labels as CSV
     * @summary Concept version labels as CSV
     * @param {string} versionId The concept version id
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApiInterface
     */
    getVersionCsvRaw(requestParameters: GetVersionCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Concept version labels as CSV
     * Concept version labels as CSV
     */
    getVersionCsv(requestParameters: GetVersionCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Get a paginated list of versions
     * @summary Get a paginated list of versions
     * @param {string} conceptId The unique identifier of the concept to get versions for
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApiInterface
     */
    getVersionsRaw(requestParameters: GetVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetVersionListResponse>>;

    /**
     * Get a paginated list of versions
     * Get a paginated list of versions
     */
    getVersions(requestParameters: GetVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetVersionListResponse>;

}

/**
 * 
 */
export class VersionApi extends runtime.BaseAPI implements VersionApiInterface {

    /**
     * Create concept version
     * Create concept version
     */
    async createVersionRaw(requestParameters: CreateVersionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullVersionResponse>> {
        if (requestParameters.createVersionRequest === null || requestParameters.createVersionRequest === undefined) {
            throw new runtime.RequiredError('createVersionRequest','Required parameter requestParameters.createVersionRequest was null or undefined when calling createVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/versions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateVersionRequestToJSON(requestParameters.createVersionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullVersionResponseFromJSON(jsonValue));
    }

    /**
     * Create concept version
     * Create concept version
     */
    async createVersion(requestParameters: CreateVersionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullVersionResponse> {
        const response = await this.createVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete concept version by id
     * Delete concept version by id
     */
    async deleteVersionByIdRaw(requestParameters: DeleteVersionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling deleteVersionById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/versions/{version_id}`.replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete concept version by id
     * Delete concept version by id
     */
    async deleteVersionById(requestParameters: DeleteVersionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteVersionByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get latest concept version by concept id
     * Get latest concept version by concept id
     */
    async getLatestConceptVersionByIdRaw(requestParameters: GetLatestConceptVersionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullVersionResponse>> {
        if (requestParameters.conceptId === null || requestParameters.conceptId === undefined) {
            throw new runtime.RequiredError('conceptId','Required parameter requestParameters.conceptId was null or undefined when calling getLatestConceptVersionById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/concepts/{concept_id}/versions/latest`.replace(`{${"concept_id"}}`, encodeURIComponent(String(requestParameters.conceptId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullVersionResponseFromJSON(jsonValue));
    }

    /**
     * Get latest concept version by concept id
     * Get latest concept version by concept id
     */
    async getLatestConceptVersionById(requestParameters: GetLatestConceptVersionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullVersionResponse> {
        const response = await this.getLatestConceptVersionByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get concept version by id
     * Get concept version by id
     */
    async getVersionByIdRaw(requestParameters: GetVersionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullVersionResponse>> {
        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling getVersionById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/versions/{version_id}`.replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullVersionResponseFromJSON(jsonValue));
    }

    /**
     * Get concept version by id
     * Get concept version by id
     */
    async getVersionById(requestParameters: GetVersionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullVersionResponse> {
        const response = await this.getVersionByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Concept version labels as CSV
     * Concept version labels as CSV
     */
    async getVersionCsvRaw(requestParameters: GetVersionCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.versionId === null || requestParameters.versionId === undefined) {
            throw new runtime.RequiredError('versionId','Required parameter requestParameters.versionId was null or undefined when calling getVersionCsv.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/versions/{version_id}/csv`.replace(`{${"version_id"}}`, encodeURIComponent(String(requestParameters.versionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Concept version labels as CSV
     * Concept version labels as CSV
     */
    async getVersionCsv(requestParameters: GetVersionCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getVersionCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a paginated list of versions
     * Get a paginated list of versions
     */
    async getVersionsRaw(requestParameters: GetVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetVersionListResponse>> {
        if (requestParameters.conceptId === null || requestParameters.conceptId === undefined) {
            throw new runtime.RequiredError('conceptId','Required parameter requestParameters.conceptId was null or undefined when calling getVersions.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/concepts/{concept_id}/versions`.replace(`{${"concept_id"}}`, encodeURIComponent(String(requestParameters.conceptId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetVersionListResponseFromJSON(jsonValue));
    }

    /**
     * Get a paginated list of versions
     * Get a paginated list of versions
     */
    async getVersions(requestParameters: GetVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetVersionListResponse> {
        const response = await this.getVersionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
