import { ErrorResponse, UploadResponse } from 'api/generated';
import ImageSunsetIllustration from 'assets/illustrations/image-sunset.svg';
import ImageIllustration from 'assets/illustrations/image.svg';
import FadeTransition from 'components/FadeTransition';
import FileUpload, { CompleteUpload } from 'components/FileUpload';
import LoadingText from 'components/LoadingText';
import LogoLoader from 'components/LogoLoader';
import SimilarImages from 'pages/concepts/detail/tabs/Examples/AddExamples/FindSimilarImages/SimilarImages';
import { useGetCandidatesFromUploads } from 'pages/concepts/detail/tabs/Examples/AddExamples/queries';
import React, { useCallback, useEffect, useState } from 'react';

const NUM_REQUIRED_POSITIVE_LABELS = 1;

interface FindSimilarImagesProps {
  conceptId: string;
  conceptVersionId: string;
  conceptVersionPositiveLabelsCount: number;
  addLabels: (positive: string[], negative: string[]) => void;
  isAddingLabels: boolean;
  exit: () => void;
}

const FindSimilarImages: React.FC<FindSimilarImagesProps> =
  function FindSimilarImages({
    conceptId,
    conceptVersionId,
    conceptVersionPositiveLabelsCount,
    addLabels,
    isAddingLabels,
    exit,
  }) {
    const [error, setError] = useState<string>();
    const [uploadedFilenames, setUploadedFilenames] = useState<string[]>([]);

    const {
      data: candidatesToLabel,
      refetch: getCandidatesFromUploads,
      isLoading: areCandidatesFetching,
    } = useGetCandidatesFromUploads(conceptVersionId, uploadedFilenames);

    useEffect(() => {
      // Upon change of files, fetch candidates for labelling
      if (uploadedFilenames?.length) {
        getCandidatesFromUploads();
      }
    }, [uploadedFilenames]);

    const onUploadSuccess = useCallback(
      (uploads: CompleteUpload<UploadResponse>[]) => {
        setUploadedFilenames(uploads.map((upload) => upload.response.filename));
      },
      [],
    );

    const onUploadError = useCallback(
      (uploads: CompleteUpload<ErrorResponse>[]) => {
        setError(uploads[0].response.detail);
      },
      [],
    );

    const showFileUploadWidget = Boolean(
      conceptId &&
        conceptVersionId &&
        !areCandidatesFetching &&
        (!candidatesToLabel?.similarImages || !candidatesToLabel?.imagePaths),
    );

    return (
      <>
        <FadeTransition show={showFileUploadWidget} appear exit={false}>
          {showFileUploadWidget && (
            <div className="mx-auto max-w-4xl">
              <FileUpload
                acceptedTypes={['image/jpeg', 'image/gif', 'image/png']}
                uploadUrl={`${process.env.REACT_APP_API_HOST}/api/ui/versions/${conceptVersionId}/labels/upload/image`}
                multiple
                maxFiles={100}
                onUploadSuccess={onUploadSuccess}
                onUploadError={onUploadError}
                onFileUploadStarted={() => setError(undefined)}
                illustrations={[
                  {
                    primary: ImageIllustration,
                    secondary: ImageSunsetIllustration,
                  },
                ]}
                error={error}
              >
                <p className="max-w-80 pointer-events-none">
                  Drop images to search your dataset or{' '}
                  <strong className="text-blue-500">browse</strong>.
                </p>
              </FileUpload>
            </div>
          )}
        </FadeTransition>
        <FadeTransition show={areCandidatesFetching} appear exit={false}>
          {areCandidatesFetching && (
            <div className="py-8 text-xl text-center">
              <LogoLoader className="h-40 m-auto" />
              <LoadingText>Loading similar images</LoadingText>
            </div>
          )}
        </FadeTransition>
        {!areCandidatesFetching &&
          candidatesToLabel?.similarImages &&
          candidatesToLabel?.imagePaths && (
            <SimilarImages
              conceptVersionId={conceptVersionId}
              imagePaths={candidatesToLabel.imagePaths}
              images={candidatesToLabel.similarImages}
              addLabels={addLabels}
              isAddingLabels={isAddingLabels}
              cancel={exit}
              numRequiredPositiveLabels={Math.max(
                0,
                NUM_REQUIRED_POSITIVE_LABELS -
                  conceptVersionPositiveLabelsCount,
              )}
            />
          )}
      </>
    );
  };

export default FindSimilarImages;
