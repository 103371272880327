import { createContext } from 'react';

const SidebarContext = createContext({
  rightSidebarOpen: false,
  setRightSidebarOpen: () => {},
  leftSidebarOpen: false,
  setLeftSidebarOpen: () => {},
} as {
  rightSidebarOpen: boolean;
  setRightSidebarOpen: (open: boolean) => void;
  leftSidebarOpen: boolean;
  setLeftSidebarOpen: (open: boolean) => void;
});

export default SidebarContext;
