export function getFileExtensionFromUrl(url: string): string | undefined {
  const urlSegments = url.split(/[#?]/);
  return urlSegments.length
    ? urlSegments[0].split('.').pop()?.trim()
    : undefined;
}

export function getFilenameFromUrl(url: string): string | undefined {
  const urlSegments = url.split(/[#?]/);
  return urlSegments.length
    ? urlSegments[0].split('/').pop()?.trim()
    : undefined;
}

export function getUrlParams(search: string) {
  const params = new URLSearchParams(search);
  const result = {};

  params.forEach((value, key) => {
    result[key] = value;
  });

  return result;
}

export function isInvitationUrl(search: string) {
  return search.includes('invitation');
}

export function isInvitationRedirectUrl(search: string) {
  return isInvitationUrl(search) && 'redirect' in getUrlParams(search);
}
