/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface QueryRequestUI
 */
export interface QueryRequestUI {
    /**
     * The SQL query
     * @type {string}
     * @memberof QueryRequestUI
     */
    query: string;
    /**
     * The id of the embedding to query
     * @type {string}
     * @memberof QueryRequestUI
     */
    embeddingId: string;
}

export function QueryRequestUIFromJSON(json: any): QueryRequestUI {
    return QueryRequestUIFromJSONTyped(json, false);
}

export function QueryRequestUIFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryRequestUI {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'query': json['query'],
        'embeddingId': json['embeddingId'],
    };
}

export function QueryRequestUIToJSON(value?: QueryRequestUI | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query': value.query,
        'embeddingId': value.embeddingId,
    };
}

