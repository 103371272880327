/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The most basic description of an entity
 * @export
 * @interface BasicDescription
 */
export interface BasicDescription {
    /**
     * The id of the resource
     * @type {string}
     * @memberof BasicDescription
     */
    id: string;
    /**
     * The name of the resource
     * @type {string}
     * @memberof BasicDescription
     */
    name: string;
}

export function BasicDescriptionFromJSON(json: any): BasicDescription {
    return BasicDescriptionFromJSONTyped(json, false);
}

export function BasicDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasicDescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
    };
}

export function BasicDescriptionToJSON(value?: BasicDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}

