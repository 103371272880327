import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import colors from 'tailwindcss/colors';
import { abbreviatedNumberFormatter } from 'utils/ChartUtils';
import { v4 as uuidv4 } from 'uuid';
import ConceptPredictionSkeletonLoader from './ConceptPredictionSkeletonLoader';
import PredictionChartCustomTick from './PredictionChartCustomTick';
import {
  BAR_CHART_MARGINS,
  BAR_SIZE,
  LABEL_AXIS_COLOR,
  LOG_SCALE,
  TICK_REF_LINE_COLOR,
  Y_AXIS_PADDING,
  powersOf10List,
} from './constants';
import { convertAssetCountToDomainEnd } from './utils';

type ChartData = {
  name: string;
  value: number;
  label: string;
};

interface ConceptPredictionChartProps {
  assetCount: number;
  chartData?: ChartData[];
}

const ConceptPredictionChart: React.FC<ConceptPredictionChartProps> = ({
  chartData,
  assetCount,
}) => {
  const [isLoaderHidden, setIsLoaderHidden] = useState(false);
  const domainEnd = convertAssetCountToDomainEnd(assetCount);
  const referenceLinesCoordinates = [
    ...powersOf10List.filter((number) => number <= domainEnd),
  ];

  useEffect(() => {
    if (!chartData) {
      setIsLoaderHidden(false);
    }
  }, [chartData]);

  return (
    <div
      className="w-full min-h-[20rem] relative"
      data-cy="concept-detail-page-prediction-chart"
    >
      <ResponsiveContainer
        className="absolute"
        width="100%"
        height="100%"
        minHeight="20rem"
      >
        <BarChart data={chartData} layout="vertical" margin={BAR_CHART_MARGINS}>
          <XAxis
            axisLine={false}
            scale={LOG_SCALE}
            domain={[1, domainEnd]}
            type="number"
            tickLine={{ stroke: 'white' }}
            interval={0}
            tick={
              <PredictionChartCustomTick
                placeholder={!chartData}
                referenceLinesCoordinates={referenceLinesCoordinates}
              />
            }
          />
          <YAxis
            padding={Y_AXIS_PADDING}
            tickLine={false}
            yAxisId={0}
            tick={false}
            type="category"
          />
          {referenceLinesCoordinates.map((coord) => (
            <ReferenceLine
              key={uuidv4()}
              x={coord}
              stroke={TICK_REF_LINE_COLOR}
            />
          ))}
          <Bar
            barSize={BAR_SIZE}
            dataKey="value"
            fill={colors.emerald[500]}
            radius={[0, 5, 5, 0]}
            onAnimationStart={() => setIsLoaderHidden(false)}
            onAnimationEnd={() => setIsLoaderHidden(true)}
          >
            <LabelList
              className="text"
              dataKey="name"
              position="left"
              fill={colors.gray[800]}
              width={500}
              offset={15}
              fontSize={16}
              fontWeight={500}
            />
            <LabelList
              dataKey="label"
              position="right"
              formatter={abbreviatedNumberFormatter}
              fill={LABEL_AXIS_COLOR}
              width={500}
              offset={10}
              fontSize={14}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      {!isLoaderHidden && (
        <ConceptPredictionSkeletonLoader
          domainEnd={domainEnd}
          referenceLinesCoordinates={referenceLinesCoordinates}
        />
      )}
    </div>
  );
};

ConceptPredictionChart.defaultProps = {
  chartData: undefined,
};

export default ConceptPredictionChart;
