import {
  AssetResponse,
  FullConceptResponseUI,
  FullVersionResponse,
} from 'api/generated';
import AssetDetailSidebar from 'components/Sidebar/AssetDetailSidebar';
import RefineSidebar from 'components/Sidebar/RefineSidebar';
import useSidebarAssetPreview, {
  SidebarAsset,
} from 'hooks/useSidebarAssetPreview';
import { ConceptActionState, TabsValues } from 'pages/concepts/types';
import React, { useCallback } from 'react';

interface UseGetConceptSidebarComponentProps {
  activeTab: TabsValues;
  conceptId: string;
  conceptVersionId: string;
  concept: FullConceptResponseUI;
  version: FullVersionResponse;
  actionState?: ConceptActionState;
  hasFetchingLoadingIndicator: boolean;
  setShouldRefetchPredictions: (value: boolean) => void;
  finishLabelingCandidates: () => void;
  sidebarAsset?: AssetResponse;
  sidebarPreviewAsset?: SidebarAsset;
}

const useGetConceptSidebarComponent = ({
  activeTab,
  conceptId,
  conceptVersionId,
  concept,
  version,
  actionState,
  hasFetchingLoadingIndicator,
  setShouldRefetchPredictions,
  finishLabelingCandidates,
  sidebarAsset,
  sidebarPreviewAsset,
}: UseGetConceptSidebarComponentProps) => {
  const { openSidebarAssetPreview } = useSidebarAssetPreview();

  return useCallback(() => {
    const shouldDisplayRefineSidebar =
      activeTab === TabsValues.Labels && concept && conceptVersionId;
    const shouldDisplayDetailSideBar =
      activeTab === TabsValues.TopContent && sidebarPreviewAsset;

    if (shouldDisplayRefineSidebar) {
      return (
        <RefineSidebar
          conceptId={conceptId}
          hasLoadingIndicator={hasFetchingLoadingIndicator}
          conceptName={concept?.name!}
          conceptVersionId={conceptVersionId}
          setShouldRefetchPredictions={setShouldRefetchPredictions}
          onClose={
            actionState === ConceptActionState.Label
              ? finishLabelingCandidates
              : undefined
          }
        />
      );
    }

    if (shouldDisplayDetailSideBar) {
      return (
        <AssetDetailSidebar
          asset={
            (sidebarPreviewAsset.asset as any) ??
            sidebarPreviewAsset.data?.asset
          }
          datasetId={version.dataset.id ?? null}
          defaultMetadataTitle={
            sidebarPreviewAsset.data ? 'Other metadata' : 'Metadata'
          }
          setShouldRefetchPredictions={setShouldRefetchPredictions}
          fromDatasetView
          metadata={sidebarPreviewAsset?.data?.data}
          metadataKeyOrder={['row']}
          metadataTitle="Search data"
          showDefaultMetadata={!sidebarPreviewAsset.data}
          setSelectedAsset={openSidebarAssetPreview}
        />
      );
    }

    return undefined;
  }, [
    activeTab,
    sidebarAsset,
    hasFetchingLoadingIndicator,
    openSidebarAssetPreview,
    sidebarPreviewAsset,
  ]);
};

export default useGetConceptSidebarComponent;
