import { Column } from 'api/generated';
import { DateTime, Duration } from 'luxon';

/**
 * Add data of the date in ticks,
 * if there is no data in that date in `data`.
 *
 * @param Array<number> _ticks
 * @param {*} data
 */
export function fillTicksData(_ticks, key: string, data: any[]): any[] {
  const ticks = [..._ticks];
  const filled: any[] = [];
  let currentTick: Date = ticks.shift();
  let lastData: any = null;
  data.forEach((it) => {
    if (ticks.length && it[key] > currentTick && lastData) {
      filled.push({ ...lastData, ...{ [key]: currentTick } });
      currentTick = ticks.shift();
    } else if (ticks.length && it.date === currentTick) {
      currentTick = ticks.shift();
    }

    filled.push(it);
    lastData = it;
  });

  return filled;
}

export function getTicks(
  startDate: Date,
  endDate: Date,
  numTicks: number,
): Date[] {
  const diffMs = DateTime.fromJSDate(endDate)
    .diff(DateTime.fromJSDate(startDate))
    .toObject().milliseconds;
  const diffDays = diffMs ? diffMs / 8.64e7 : undefined;

  if (!diffDays) return [];

  const current = DateTime.fromJSDate(startDate);
  const velocity = Math.round(diffDays / (numTicks - 1));

  const ticks = [startDate];

  for (let i = 1; i < numTicks - 1; i += 1) {
    ticks.push(
      current.plus(Duration.fromObject({ days: i * velocity })).toJSDate(),
    );
  }

  ticks.push(endDate);
  return ticks;
}

export function getAxisTypeFromData(
  axis: string | undefined,
  data: any[],
): 'number' | 'category' | undefined {
  if (!data?.length || !axis || !(axis in data[0])) {
    return undefined;
  }
  if (typeof data[0][axis] === 'number') {
    return 'number';
  }
  return 'category';
}

export function massageData(
  data: any[],
  columns: Column[],
  xAxis: string | undefined,
): any[] {
  const colsMap = {};
  columns.forEach((col) => {
    colsMap[col.key] = col;
  });

  const massagedData = data?.map(({ data: d }) => {
    const dx = {};
    Object.entries(d).forEach(([key, value]) => {
      dx[key] =
        colsMap?.[key]?.data_type === 'date' ? new Date(value as any) : value;
    });
    return dx;
  });

  if (xAxis && getAxisTypeFromData(xAxis, massagedData) === 'number') {
    massagedData.sort((a, b) => (a[xAxis] as number) - (b[xAxis] as number));
  }
  return massagedData;
}

export function getAxisScaleFromData(
  axis: string | undefined,
  cols: Column[],
): 'time' | undefined {
  return cols?.find((col) => col.key === axis)?.dataType === 'date'
    ? 'time'
    : undefined;
}

export function dateFormatter(date): string {
  return DateTime.fromJSDate(date).toFormat('MM/dd/yy');
}

export function getAxisDomainFromData(
  axis: string | undefined,
  data: any[],
): [number, number] | undefined {
  if (
    data?.length &&
    axis &&
    data[0][axis] &&
    typeof data[0][axis] === 'number'
  ) {
    const points: number[] = data.map((d) => d[axis]);
    return [Math.min(...points), Math.max(...points)];
  }
  return undefined;
}
