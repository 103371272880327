import React from 'react';
import Dropdown from 'components/Dropdown';
import TextInput from 'components/TextInput';
import {
  ConceptData,
  ConceptFilterType,
} from 'components/DatasetSearch/AdvancedSearch/types';
import { Comparator } from 'api/generated';

function getConceptDataValue(
  option: ConceptFilterType,
  data?: Partial<ConceptData>[],
): ConceptData[] {
  const dataRow1 = data && data.length ? data[0] : undefined;
  const conceptId = dataRow1?.conceptId!;
  const name = dataRow1?.name;
  const threshold = dataRow1?.threshold!;
  const dataRow2 = data && data.length > 0 ? data[1] : undefined;
  switch (option) {
    case ConceptFilterType.ProbBetween:
      return [
        {
          conceptId,
          name,
          comparator: Comparator.GreaterThan,
          value: dataRow1?.value,
          threshold,
          type: option,
        },
        {
          conceptId,
          name,
          comparator: Comparator.LessThan,
          value: dataRow2?.value,
          threshold,
          type: option,
        },
      ];
    case ConceptFilterType.Negative:
      return [
        {
          conceptId,
          name,
          comparator: Comparator.LessThan,
          value: threshold,
          threshold,
          type: option,
        },
      ];
    case ConceptFilterType.Positive:
      return [
        {
          conceptId,
          name,
          comparator: Comparator.GreaterThanOrEqualTo,
          value: threshold,
          threshold,
          type: option,
        },
      ];
    case ConceptFilterType.ProbGreaterThan:
      return [
        {
          conceptId,
          name,
          comparator: Comparator.GreaterThan,
          value: dataRow1?.value,
          threshold,
          type: option,
        },
      ];
    case ConceptFilterType.ProbLessThan:
      return [
        {
          conceptId,
          name,
          comparator: Comparator.LessThan,
          value: dataRow1?.value,
          threshold,
          type: option,
        },
      ];
    default:
      return [
        {
          conceptId,
          name,
          comparator: Comparator.GreaterThanOrEqualTo,
          value: threshold,
          threshold,
          type: option,
        },
      ];
  }
}

const ConceptFilter: React.FC<{
  data: ConceptData[];
  onChange: (d: ConceptData[]) => void;
}> = function ConceptFilter({ data, onChange }) {
  const conceptName = data.length ? data[0].name : '';
  const selectedOption = data.length ? data[0].type : undefined;
  return (
    <div className="inline space-x-2">
      <Dropdown
        selected={selectedOption}
        onChange={(v) => onChange(getConceptDataValue(v, data))}
        options={[
          { value: ConceptFilterType.Positive, label: 'is positive' },
          { value: ConceptFilterType.Negative, label: 'is negative' },
          {
            value: ConceptFilterType.ProbGreaterThan,
            label: 'has probability greater than',
          },
          {
            value: ConceptFilterType.ProbLessThan,
            label: 'has probability less than',
          },
          {
            value: ConceptFilterType.ProbBetween,
            label: 'has probability between',
          },
        ]}
        dropdownStyle="inline"
      />
      {data.length &&
        (selectedOption === ConceptFilterType.ProbGreaterThan ||
          selectedOption === ConceptFilterType.ProbLessThan ||
          selectedOption === ConceptFilterType.ProbBetween) && (
          <div className="inline">
            <TextInput
              placeholder={
                selectedOption === ConceptFilterType.ProbBetween
                  ? 'min (exclusive)'
                  : `${data[0].threshold} (default)`
              }
              id={`${conceptName}-threshold-1`}
              label=""
              name={`${conceptName} classification threshold`}
              defaultValue={`${data[0].value ?? ''}`}
              onChange={(e) => {
                const flt = parseFloat(e.target.value.trim());
                const newRow1 = { ...data[0] };
                newRow1.value = flt ? Math.min(1, Math.max(0, flt)) : flt;
                onChange(
                  getConceptDataValue(
                    selectedOption,
                    data.length === 2 ? [newRow1, data[1]] : [newRow1],
                  ),
                );
              }}
              textInputStyle="inline"
            />
          </div>
        )}
      {data.length > 1 && selectedOption === ConceptFilterType.ProbBetween && (
        <>
          <span>and</span>
          <div className="inline">
            <TextInput
              placeholder={
                selectedOption === ConceptFilterType.ProbBetween
                  ? 'max (exclusive)'
                  : `${data[1].threshold} (default)`
              }
              id={`${conceptName}-threshold-2`}
              label=""
              name={`${conceptName} classification threshold 2`}
              defaultValue={`${data[1].value ?? ''}`}
              onChange={(e) => {
                const flt = parseFloat(e.target.value.trim());
                const newRow2 = { ...data[1] };
                newRow2.value = flt ? Math.min(1, Math.max(0, flt)) : flt;
                onChange(
                  getConceptDataValue(selectedOption, [data[0], newRow2]),
                );
              }}
              textInputStyle="inline"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ConceptFilter;
