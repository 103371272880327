import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { ConceptsTableResponse } from 'api/generated';

interface TileViewProps {
  concepts: ConceptsTableResponse | undefined;
}

const TileView: React.FunctionComponent<TileViewProps> = function TileView({
  concepts,
}) {
  return (
    <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      {concepts?.data?.map((concept) => (
        <li key={concept.data?.conceptId} className="relative">
          <Link to={`/concepts/${concept.data?.conceptId}`}>
            {/* <Tile
              title={concept.name}
              source={concept?.image?.small}
              size={concept.description}
            /> */}
            {/* Delete this when tile is ready */}
            {concept.data?.name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

interface TileProperties {
  title: string;
  size: string;
  source?: string;
}

const Tile: FunctionComponent<TileProperties> = function Tile({
  title,
  size,
  source,
}) {
  return (
    <>
      <div className="group h-72 block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-blue-600 overflow-hidden">
        <img
          src={source}
          alt=""
          className="object-cover pointer-events-none group-hover:opacity-75"
        />
        <button type="button" className="absolute inset-0 focus:outline-none">
          <span className="sr-only">View details for {title}</span>
        </button>
      </div>
      <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
        {title}
      </p>
      <p className="block text-sm font-medium text-gray-500 pointer-events-none">
        {size}
      </p>
    </>
  );
};

Tile.defaultProps = {
  source: '',
};

export default TileView;
