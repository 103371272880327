import TextSearchResults from 'components/DatasetSearch/DatasetTextSearch/TextSearchResults';
import { useSearchImagesQuery } from 'pages/datasets/detail/components/queries';
import React from 'react';
import { AssetResponse } from 'api/generated';
import ClipLoader from 'react-spinners/ClipLoader';
import colors from 'tailwindcss/colors';

interface TopContentProps {
  conceptId: string;
  datasetId: string;
  enabled: boolean;
  onAssetClick: (asset: AssetResponse) => void;
}

const TopContent: React.FC<TopContentProps> = ({
  conceptId,
  datasetId,
  enabled,
  onAssetClick,
}: TopContentProps) => {
  const { data, isLoading, isRefetching } = useSearchImagesQuery(
    '',
    datasetId,
    'visual',
    undefined,
    [conceptId],
    undefined,
    enabled,
  );

  return (
    <div className="mt-8">
      {isLoading && (
        <div
          className="w-full flex items-center justify-center h-[50vh]"
          data-cy="concept-detail-page-top-content-loader"
        >
          <ClipLoader
            cssOverride={{ textAlign: 'center' }}
            color={colors.green['500']}
            loading
            size={40}
            speedMultiplier={0.75}
          />
        </div>
      )}
      {data && (
        <TextSearchResults
          onClick={onAssetClick}
          results={data}
          isLoading={isLoading}
          isRefetching={isRefetching}
          clearSearch={() => null}
          hideHeader
          isLabelingResults={false}
          dataTestId="concept-detail-page-top-content-results"
        />
      )}
    </div>
  );
};

export default TopContent;
