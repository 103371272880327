import { SimilaritySearchApi, GetSimilarImagesRequest } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useInfiniteQuery from 'hooks/useInfiniteQuery';
import useQuery from 'hooks/useQuery';
import toNumber from 'utils/ToNumber';

export const getGetSimilarImagesGroupedInfiniteQueryKey = (
  embeddingId: string | null,
  file: Blob | null,
  groupBy: string | null | undefined,
) => ['getSimilarImagesGrouped', embeddingId, JSON.stringify(file), groupBy];

export const useGetSimilarImagesGroupedInfiniteQuery = (
  pageSize: number,
  embeddingId: string | null,
  file: Blob | null,
  groupBy?: string | null,
) => {
  const { initializeAPI } = useAPIContext();
  return useInfiniteQuery(
    getGetSimilarImagesGroupedInfiniteQueryKey(embeddingId, file, groupBy),
    async ({ signal, pageParam }) => {
      const api = await initializeAPI<SimilaritySearchApi>(SimilaritySearchApi);
      return api.getSimilarImagesGroupedUi(
        {
          embeddingId: embeddingId!,
          file: file!,
          groupBy,
          limit: pageSize * toNumber((pageParam || 0) + 1),
        },
        { signal },
      );
    },
    {
      cacheTime: 0,
      enabled: false,
      getNextPageParam: (lastPage) =>
        (lastPage?.meta?.page.limit ?? 0) / pageSize,
    },
  );
};

export const getSimilarImagesQueryKey = (
  request: GetSimilarImagesRequest,
  pageSize?: number,
  page?: number,
) => ['getSimilarImagesUi', JSON.stringify(request), pageSize, page];

export const useGetSimilarImages = (
  request: GetSimilarImagesRequest,
  pageSize?: number,
  page?: number,
  shouldFetch: boolean = true,
) => {
  const { initializeAPI } = useAPIContext();

  return useQuery(
    getSimilarImagesQueryKey(request, pageSize, page),
    async () => {
      const api = await initializeAPI<SimilaritySearchApi>(SimilaritySearchApi);
      return api.getSimilarImages({
        ...request,
        offset: pageSize ? pageSize * ((page ?? 1) - 1) : 0,
        limit: pageSize,
      });
    },
    {
      enabled: shouldFetch,
    },
  );
};
