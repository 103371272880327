import {
  OrgInvitationsTableResponse,
  OrgInvitationTableRow,
} from 'api/generated';
import EmptyStateMessage from 'components/EmptyStateMessage';
import LoadingTable from 'components/LoadingTable';
import Table from 'components/Table';
import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import {
  useCreateOrgInvitationMutation,
  useDeleteOrgInvitationMutation,
} from './queries';

const OrgInvitationsTable: React.FC<{
  invitations: OrgInvitationsTableResponse | undefined;
  refetchInvitations: () => void;
}> = ({ invitations, refetchInvitations }) => {
  const { mutate: createInvitation } = useCreateOrgInvitationMutation();
  const { mutate: deleteInvitation } = useDeleteOrgInvitationMutation();
  const loadingTable = useMemo(() => <LoadingTable />, []);
  return (
    <>
      {invitations && Boolean(invitations?.data?.length) && (
        <Table
          idKey="row"
          columns={invitations.columns}
          data={invitations.data}
          total={invitations.meta.page.total ?? invitations.data.length}
          actions={[
            {
              key: 'resend',
              label: 'Resend Invitation',
              action: (row: OrgInvitationTableRow) => {
                const splitName = row.data.invitee.name?.split(' ');
                const givenName = splitName?.length === 2 ? splitName[0] : '';
                const familyName = splitName?.length === 2 ? splitName[1] : '';
                createInvitation(
                  {
                    createOrgInvitationRequest: {
                      givenName,
                      familyName,
                      email: row.data.invitee.email,
                    },
                  },
                  {
                    onSuccess: () => {
                      toast.success(
                        `Invitation resent to ${row.data.invitee.email}`,
                      );
                      refetchInvitations();
                    },
                    onError: () => {
                      toast.error(
                        `Unable to resend invitation to ${row.data.invitee.email}. Try Again.`,
                      );
                    },
                  },
                );
              },
            },
            {
              key: 'revoke',
              label: 'Delete Invitation',
              action: (row: OrgInvitationTableRow) => {
                deleteInvitation(
                  {
                    invitationId: `${row.data.id}`,
                  },
                  {
                    onSuccess: () => {
                      toast.success(
                        `Invitation successfully deleted for ${row.data.invitee.email}`,
                      );
                      refetchInvitations();
                    },
                    onError: () => {
                      toast.error(
                        `Unable to delete invitation for ${row.data.invitee.email}. Try Again.`,
                      );
                    },
                  },
                );
              },
              danger: true,
            },
          ]}
          // activePage={pagination.page}
          // pageSize={pagination.size}
          // showRowCount
          // setPageSize={setPageSize}
          // loadPage={loadPage}
          // onClickImagePreview={setSidebarData}
        />
      )}
      {!invitations && loadingTable}
      {invitations?.data?.length === 0 && (
        <div className="text-center py-12 px-2 text-sm">
          <EmptyStateMessage>
            <p className="pb-4">No pending invitations</p>
          </EmptyStateMessage>
        </div>
      )}
    </>
  );
};

export default OrgInvitationsTable;
