import React, { ReactElement } from 'react';
import { abbreviatedNumberFormatter } from 'utils/ChartUtils';
import colors from 'tailwindcss/colors';
import classNames from 'classnames';
import { LABEL_AXIS_COLOR, TICK_REF_LINE_COLOR } from './constants';

const PredictionChartCustomTick = ({
  index,
  x,
  y,
  payload: { value },
  referenceLinesCoordinates,
  placeholder,
}: any): ReactElement<SVGElement> => {
  // y position of tick label
  const textYOffset = y + 20;

  const getTickLabels = (tickValue: number) => {
    if (referenceLinesCoordinates.includes(tickValue)) {
      return abbreviatedNumberFormatter(tickValue);
    }
    return '';
  };

  return (
    // y1, y2 adjust tick y-position and height
    <>
      <line
        x1={x}
        y1={y - 2}
        x2={x}
        y2={y - 8}
        className={classNames({ 'animate-pulse': placeholder })}
        stroke={index === 0 ? LABEL_AXIS_COLOR : TICK_REF_LINE_COLOR}
      />
      {!placeholder && (
        <text
          x={x}
          y={textYOffset}
          textAnchor="middle"
          fill={colors.slate[500]}
          fontSize={14}
        >
          {getTickLabels(value)}
        </text>
      )}
    </>
  );
};

export default PredictionChartCustomTick;
