/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface ConceptPredictionResponse
 */
export interface ConceptPredictionResponse {
    /**
     * Each tuple represents the bounds of a bin we estimated number of assets for
     * @type {Array<Array<number>>}
     * @memberof ConceptPredictionResponse
     */
    probabilityRanges: Array<Array<number>>;
    /**
     * Number of assets belonging to each probability range
     * @type {Array<number>}
     * @memberof ConceptPredictionResponse
     */
    estimates: Array<number>;
    /**
     * Lower/upper bounds representing respective ranges that the actual number of assets is in
     * @type {Array<Array<number>>}
     * @memberof ConceptPredictionResponse
     */
    confidenceIntervals?: Array<Array<number>> | null;
}

export function ConceptPredictionResponseFromJSON(json: any): ConceptPredictionResponse {
    return ConceptPredictionResponseFromJSONTyped(json, false);
}

export function ConceptPredictionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConceptPredictionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'probabilityRanges': json['probabilityRanges'],
        'estimates': json['estimates'],
        'confidenceIntervals': !exists(json, 'confidenceIntervals') ? undefined : json['confidenceIntervals'],
    };
}

export function ConceptPredictionResponseToJSON(value?: ConceptPredictionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'probabilityRanges': value.probabilityRanges,
        'estimates': value.estimates,
        'confidenceIntervals': value.confidenceIntervals,
    };
}

