import React from 'react';
import { CircleStackIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

interface NavigateToDatasetButtonProps {
  datasetName: string;
  path: string;
  dataTestId?: string;
}

export const NavigateToDatasetButton: React.FunctionComponent<NavigateToDatasetButtonProps> =
  function NavigateToDatasetButton({ datasetName, path, dataTestId }) {
    return (
      <Link
        className="text-gray-500 inline-flex items-center justify-center font-medium focus:outline-none transition-all relative bg-slate-100  border rounded-md border-slate-300 focus:ring-0 focus:bg-emerald-50 focus:border-emerald-600 focus:text-emerald-700 py-1 shadow-sm hover:bg-slate-50 text-sm p-4 w-auto"
        to={path}
      >
        <CircleStackIcon className="mr-1.5 h-4 w-4" />
        <span data-cy={dataTestId}>{datasetName}</span>
      </Link>
    );
  };

NavigateToDatasetButton.defaultProps = {
  dataTestId: undefined,
};
