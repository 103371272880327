/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface CreateConceptsFromCsvRequest
 */
export interface CreateConceptsFromCsvRequest {
    /**
     * The embedding id
     * @type {string}
     * @memberof CreateConceptsFromCsvRequest
     */
    embeddingId: string;
    /**
     * Path to the csv file
     * @type {string}
     * @memberof CreateConceptsFromCsvRequest
     */
    csvPath: string;
    /**
     * Set to true to add embedding name to the concept
     * @type {boolean}
     * @memberof CreateConceptsFromCsvRequest
     */
    appendEmbeddingName?: boolean | null;
}

export function CreateConceptsFromCsvRequestFromJSON(json: any): CreateConceptsFromCsvRequest {
    return CreateConceptsFromCsvRequestFromJSONTyped(json, false);
}

export function CreateConceptsFromCsvRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateConceptsFromCsvRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'embeddingId': json['embeddingId'],
        'csvPath': json['csvPath'],
        'appendEmbeddingName': !exists(json, 'appendEmbeddingName') ? undefined : json['appendEmbeddingName'],
    };
}

export function CreateConceptsFromCsvRequestToJSON(value?: CreateConceptsFromCsvRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'embeddingId': value.embeddingId,
        'csvPath': value.csvPath,
        'appendEmbeddingName': value.appendEmbeddingName,
    };
}

