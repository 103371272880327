import { DatasetResponse, DatasetUpdateResponse } from 'api/generated';
import {
  CreateDatasetStep,
  DataSource,
  PersonToConfigure,
} from 'pages/datasets/add-assets/types';

export function getPreviousStep(
  step: CreateDatasetStep | undefined,
): CreateDatasetStep | undefined {
  switch (step) {
    case 'Data Source':
      return 'Name';
    case 'Access':
      return 'Data Source';
    default:
      return undefined;
  }
}

export function getCurrentStep(
  dataset: DatasetResponse,
  datasetUpdate: DatasetUpdateResponse | undefined,
  userId: string | undefined,
  userEmail: string | undefined,
): CreateDatasetStep {
  const datasetUpdateRequester = datasetUpdate?.createdUserId;
  const datasetUpdateRequestee = datasetUpdate?.invitedUserEmail;
  if (
    datasetUpdate &&
    datasetUpdateRequestee === userEmail &&
    userId === datasetUpdateRequester
  ) {
    // The user quit the flow before they configured access
    return 'Access';
  }
  if (datasetUpdate?.dataPath && datasetUpdate?.dataSourceType) {
    // A prefix was provided, but access is required to continue.
    return 'Access';
  }
  if (
    datasetUpdate &&
    datasetUpdateRequestee === userEmail &&
    datasetUpdate.dataSourceType
  ) {
    // The was invited to configure access the data source
    return 'Access';
  }
  if (!dataset) {
    return 'Name';
  }
  return 'Data Source';
}

export function getS3BucketName(
  dataPath: string | undefined,
): string | undefined {
  return /^s3:\/\/[^\\/]+/i.test(dataPath ?? '')
    ? dataPath?.replace(/^s3:\/\//i, '').split('/')[0]
    : undefined;
}

export function isDataSourceProvided(
  csvPath: string | null | undefined,
  dataSource: DataSource | null | undefined,
  gsPrefix: string | null | undefined,
  otherDataSource: string | null | undefined,
  personToSelectDataSource: PersonToConfigure,
  s3Prefix: string | null | undefined,
  teammateEmail: string | null | undefined,
  teammateEmailIsValid: boolean,
  zipPath: string | null | undefined,
) {
  if (personToSelectDataSource === 'me' && dataSource === 's3' && s3Prefix) {
    const regex = /^s3:\/\/([^/]+)((\/(.*?([^/]+)))*(\/?))$/;
    if (s3Prefix && regex.test(s3Prefix)) {
      return true;
    }
  }
  if (personToSelectDataSource === 'me' && dataSource === 'gs' && gsPrefix) {
    const regex = /^gs:\/\/([^/]+)((\/(.*?([^/]+)))*(\/?))$/;
    if (gsPrefix && regex.test(gsPrefix)) {
      return true;
    }
  }
  if (csvPath || zipPath) {
    return true;
  }
  if (
    personToSelectDataSource === 'someone else' &&
    teammateEmail &&
    teammateEmailIsValid
  ) {
    return true;
  }
  return otherDataSource && dataSource === 'other';
}

export function getPathForDataSource(
  dataSource: DataSource | undefined,
  s3Prefix: string | null | undefined,
  gsPrefix: string | null | undefined,
  csvPath: string | null | undefined,
  zipPath: string | null | undefined,
): string | null | undefined {
  switch (dataSource) {
    case 's3':
      return s3Prefix;
    case 'gs':
      return gsPrefix;
    case 'csv':
      return csvPath;
    case 'zip':
      return zipPath;
    default:
      return undefined;
  }
}
