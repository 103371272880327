import { SimilaritySearchApi } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useQuery from 'hooks/useQuery';

export const useFetchMetadataFilters = (datasetId: string) => {
  const { initializeAPI } = useAPIContext();

  return useQuery(['useFetchMetadataFilters', datasetId], async () => {
    const api = await initializeAPI<SimilaritySearchApi>(SimilaritySearchApi);
    return api.getSearchFilters({
      datasetId,
    });
  });
};
