import { ConceptApi } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useQuery from 'hooks/useQuery';

export const useGetConceptPrediction = (
  conceptId: string,
  threshold?: number | null,
  confidenceLevel?: number | null,
  estimationFidelity?: number | null,
  enabled?: boolean,
) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    ['getConceptPerformance', conceptId],
    async () => {
      const api = await initializeAPI<ConceptApi>(ConceptApi);
      return api.getConceptPredictionEstimation({
        conceptId,
        threshold,
        confidenceLevel,
        estimationFidelity,
      });
    },
    {
      enabled: enabled || false,
    },
  );
};
