/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const Comparator = {
    LessThan: '<',
    LessThanOrEqualTo: '<=',
    GreaterThan: '>',
    GreaterThanOrEqualTo: '>=',
    Equals: '==',
    NotEqual: '!=',
    StartsWith: 'starts_with',
    EndsWith: 'ends_with',
    Contains: 'contains'
} as const;
export type Comparator = typeof Comparator[keyof typeof Comparator];


export function ComparatorFromJSON(json: any): Comparator {
    return ComparatorFromJSONTyped(json, false);
}

export function ComparatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Comparator {
    return json as Comparator;
}

export function ComparatorToJSON(value?: Comparator | null): any {
    return value as any;
}

