import Button from 'components/Button';
import Table from 'components/Table';
import React from 'react';
import EmptyStateMessage from 'components/EmptyStateMessage';
import LoadingTable from 'components/LoadingTable';
import PageHeader from 'components/PageHeader';
import { useCreateSdkCredentials, useGetSdkCredentials } from './queries';

const Credentials = () => {
  const {
    mutate,
    data: mutateData,
    isLoading: mutateIsLoading,
  } = useCreateSdkCredentials();
  const { data: getData, isLoading: getIsLoading } = useGetSdkCredentials();
  const data = mutateData || getData;
  const isLoading = getIsLoading || mutateIsLoading;
  return (
    <div>
      <div className="max-w-8xl mx-auto">
        <PageHeader title={<h1>Credentials</h1>} backButtonTo="" />
        <div>
          {isLoading ? (
            <div className="pt-8">
              <LoadingTable columns={2} rows={1} />
            </div>
          ) : (
            <Table
              columns={[
                { key: 'clientId', label: 'Client Id' },
                {
                  key: 'clientSecret',
                  label: 'Client Secret',
                  displayType: 'secret',
                },
              ]}
              data={data ? [{ data, row: 0 }] : []}
              idKey="clientId"
              loadStrategy="scroll"
              total={data ? 1 : 0}
              showRowCount
              emptyMessage={
                <div className="text-center py-12 px-2 text-sm">
                  <EmptyStateMessage>
                    <p className="pb-4">No credentials to show</p>
                    {data || isLoading ? undefined : (
                      <Button onClick={() => mutate()}>
                        Create credentials
                      </Button>
                    )}
                  </EmptyStateMessage>
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Credentials;
