import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, { FC, Fragment } from 'react';

enum StepStatus {
  Active = 'active',
  Completed = 'completed',
  Pending = 'pending',
}

const STEPPER_STYLES = {
  [StepStatus.Active]: {
    bullet: 'bg-green-600',
    label: 'border-b-[1px] border-green-600 text-green-600',
  },
  [StepStatus.Completed]: {
    bullet:
      'bg-white bg-zinc-700 border border-zinc-700 border-[1px] border-solid flex items-center justify-center',
    label: 'text-zinc-700',
  },
  [StepStatus.Pending]: {
    bullet: 'bg-white border border-gray-400 border-[1px] border-solid',
    label: 'text-gray-400',
  },
};

const getStepStatus = (activeStep: number, idx: number): StepStatus => {
  if (activeStep < idx) {
    return StepStatus.Pending;
  }
  if (activeStep === idx) {
    return StepStatus.Active;
  }
  return StepStatus.Completed;
};

interface StepperProps {
  activeStep: number;
  horizontal?: boolean;
  steps: string[];
}

const Stepper: FC<StepperProps> = ({ activeStep, horizontal, steps }) => (
  <div
    className={classNames('my-2', {
      'flex items-center justify-between mx-2': horizontal,
    })}
  >
    {steps.map((step, idx) => {
      const hasStepConnector = idx !== steps.length - 1 && steps.length > 1;

      return (
        <Fragment key={`step-${step}`}>
          <div
            className={classNames('flex', {
              'mb-12': !horizontal && hasStepConnector,
              'mx-3': horizontal,
            })}
          >
            <div className="mr-3 relative">
              {!horizontal && hasStepConnector && (
                <div className="absolute bg-slate-200 h-20 left-1/2 top-0 transform -translate-x-1/2 w-[2px]" />
              )}
              <div
                className={`h-4 relative rounded-full w-4 z-10 ${
                  STEPPER_STYLES[getStepStatus(activeStep, idx)].bullet
                }`}
              >
                {activeStep > idx && (
                  <CheckIcon className="h-2.5 text-white w-2.5 font-bold [&>path]:stroke-[2] [&>path]:stroke-white" />
                )}
              </div>
            </div>
            <p
              className={`leading-4 pb-1 text-xs font-medium whitespace-nowrap ${
                STEPPER_STYLES[getStepStatus(activeStep, idx)].label
              }`}
            >
              {step}
            </p>
          </div>
          {horizontal && hasStepConnector && (
            <div className="bg-slate-200 flex-grow h-1 w-full" />
          )}
        </Fragment>
      );
    })}
  </div>
);

Stepper.defaultProps = {
  horizontal: false,
};

export default Stepper;
