import {
  MutationFunction,
  MutationKey,
  useMutation as useRQMutation,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';

function useMutation<TData = unknown, TVariables = void, TContext = unknown>(
  mutationKey: MutationKey,
  mutationFn?: MutationFunction<TData, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, Response, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<TData, Response, TVariables, TContext> {
  return useRQMutation(mutationKey, mutationFn, options);
}

export default useMutation;
