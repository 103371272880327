/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddAssetItem,
    AddAssetItemFromJSON,
    AddAssetItemFromJSONTyped,
    AddAssetItemToJSON,
} from './AddAssetItem';
import {
    StorageTypeEnum,
    StorageTypeEnumFromJSON,
    StorageTypeEnumFromJSONTyped,
    StorageTypeEnumToJSON,
} from './StorageTypeEnum';

/**
 * A request to add additional images and videos to an existing dataset
 * @export
 * @interface AddAssetsToDatasetRequest
 */
export interface AddAssetsToDatasetRequest {
    /**
     * A list of assets to add with optional metadata, asset_paths will get deprecated and this key is the one that will be used going forward
     * @type {Array<AddAssetItem>}
     * @memberof AddAssetsToDatasetRequest
     */
    items?: Array<AddAssetItem> | null;
    /**
     * DEPRECATION WARNING! (This input will become deprecated in a future release). A list of full, absolute image or videos paths to add to the dataset
     * @type {Array<string>}
     * @memberof AddAssetsToDatasetRequest
     * @deprecated
     */
    assetPaths?: Array<string> | null;
    /**
     * The id of the credentials to replace on the dataset model
     * @type {string}
     * @memberof AddAssetsToDatasetRequest
     */
    credentialsId?: string;
    /**
     * 
     * @type {StorageTypeEnum}
     * @memberof AddAssetsToDatasetRequest
     */
    storageType?: StorageTypeEnum;
    /**
     * An S3 or GCS path prefix for all images and videos to add to the dataset
     * @type {string}
     * @memberof AddAssetsToDatasetRequest
     */
    dataPath?: string | null;
    /**
     * An path to a previously uploaded CSV file containing data paths
     * @type {string}
     * @memberof AddAssetsToDatasetRequest
     */
    csvPath?: string | null;
    /**
     * An path to a previously uploaded ZIP file containing assets
     * @type {string}
     * @memberof AddAssetsToDatasetRequest
     */
    zipPath?: string | null;
    /**
     * A user action id from which to extract the storage type and data path
     * @type {string}
     * @memberof AddAssetsToDatasetRequest
     */
    datasetUpdateId?: string | null;
}

export function AddAssetsToDatasetRequestFromJSON(json: any): AddAssetsToDatasetRequest {
    return AddAssetsToDatasetRequestFromJSONTyped(json, false);
}

export function AddAssetsToDatasetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddAssetsToDatasetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(AddAssetItemFromJSON)),
        'assetPaths': !exists(json, 'assetPaths') ? undefined : json['assetPaths'],
        'credentialsId': !exists(json, 'credentialsId') ? undefined : json['credentialsId'],
        'storageType': !exists(json, 'storageType') ? undefined : StorageTypeEnumFromJSON(json['storageType']),
        'dataPath': !exists(json, 'dataPath') ? undefined : json['dataPath'],
        'csvPath': !exists(json, 'csvPath') ? undefined : json['csvPath'],
        'zipPath': !exists(json, 'zipPath') ? undefined : json['zipPath'],
        'datasetUpdateId': !exists(json, 'datasetUpdateId') ? undefined : json['datasetUpdateId'],
    };
}

export function AddAssetsToDatasetRequestToJSON(value?: AddAssetsToDatasetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(AddAssetItemToJSON)),
        'assetPaths': value.assetPaths,
        'credentialsId': value.credentialsId,
        'storageType': StorageTypeEnumToJSON(value.storageType),
        'dataPath': value.dataPath,
        'csvPath': value.csvPath,
        'zipPath': value.zipPath,
        'datasetUpdateId': value.datasetUpdateId,
    };
}

