/* eslint-disable no-restricted-globals */
import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import DropzoneJS, { DropzoneFile } from 'dropzone';
import { fromEvent } from 'file-selector';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  allFilesAccepted,
  FileUploadStatus,
  getFileKey,
} from 'utils/FileUtils';

DropzoneJS.autoDiscover = false;

const ADDED_FILES_EVT = 'addedfiles';

interface DropzoneProps {
  id: string;
  files: File[];
  setFiles: (files: File[]) => void;
  fileUploadStatus: FileUploadStatus;
  setFileUploadStatus: React.Dispatch<React.SetStateAction<FileUploadStatus>>;
  uploadUrl?: string;
  multiple?: boolean;
  acceptedTypes?: string[];
  className?: string;
  maxFiles?: number;
  onFileSuccessResponse?: (file: DropzoneFile, response: any) => void;
  onFileErrorResponse?: (file: DropzoneFile, response: any) => void;
  onFileUploadStarted?: (file: DropzoneFile) => void;
}

const Dropzone: React.FunctionComponent<PropsWithChildren<DropzoneProps>> =
  function Dropzone({
    id,
    acceptedTypes,
    children,
    className,
    multiple,
    maxFiles,
    uploadUrl,
    onFileSuccessResponse,
    onFileErrorResponse,
    onFileUploadStarted,
    files,
    setFiles,
    fileUploadStatus,
    setFileUploadStatus,
  }) {
    const staticId = useRef(id);
    const { getAccessTokenSilently } = useAuth0();
    const dropzone = useRef<DropzoneJS>();
    const [isDragActive, setIsDragActive] = useState(false);
    const [isDragAccept, setIsDragAccept] = useState(false);
    const [isDragReject, setIsDragReject] = useState(false);
    const acceptedFiles = acceptedTypes?.join(',');
    const interval = useRef<any>();
    const addedFilesCb = useRef<any>();

    useEffect(
      () => () => {
        dropzone.current?.destroy();
      },
      [],
    );

    useEffect(() => {
      clearInterval(interval.current);

      const updateStatus = () => {
        setFileUploadStatus((currState) => {
          const status = { ...currState };
          dropzone.current?.files?.forEach((file) => {
            status[getFileKey(file)] = Number.isNaN(
              parseInt(status[getFileKey(file)] as any, 10),
            )
              ? status[getFileKey(file)]
              : file.upload?.progress || 0;
          });
          return status;
        });
      };
      if (files?.length) {
        interval.current = setInterval(() => {
          const allFilesIncluded = dropzone.current?.files?.every(
            (file) => fileUploadStatus[getFileKey(file)],
          );
          const anyIncompleteUploads = Object.values(fileUploadStatus).find(
            (value) => typeof value === 'number' && value !== 100,
          );
          if (!allFilesIncluded || anyIncompleteUploads) {
            updateStatus();
          } else {
            // All uploads are complete
            clearInterval(interval.current);
          }
        }, 100);
      }
      return () => clearInterval(interval.current);
    }, [files, fileUploadStatus, setFileUploadStatus]);

    const resetAddedFilesCallback = () => {
      dropzone.current?.off(ADDED_FILES_EVT, addedFilesCb.current);

      addedFilesCb.current = (addedFiles: DropzoneFile[]) => {
        const addedFilesArr = Array.from(addedFiles);
        if (!multiple && files?.length) {
          setFiles([...addedFilesArr]);
        } else {
          // TODO: Check for duplicates
          setFiles([...files, ...addedFilesArr]);
        }

        const updatedFileUploadStatus = multiple ? { ...fileUploadStatus } : {};
        addedFilesArr.forEach((file) => {
          updatedFileUploadStatus[getFileKey(file)] = 0;
        });
        setFileUploadStatus(updatedFileUploadStatus);

        addedFilesArr.forEach((file) => onFileUploadStarted?.(file));
      };
      dropzone.current?.on(ADDED_FILES_EVT, addedFilesCb.current);
    };

    const initializeDropZone = useCallback(() => {
      getAccessTokenSilently().then((token) => {
        dropzone.current?.destroy();
        dropzone.current = new DropzoneJS(`#${staticId.current}`, {
          chunking: false, // enable chunking
          // forceChunking: false, // force chunking for every file so that we don't need to support two different types of upload schemas
          // parallelChunkUploads: false, // allows chunks to be uploaded in parallel (this is independent of the parallelUploads option)
          // retryChunks: true, // retry chunks on failure,
          url: uploadUrl,
          parallelUploads: 1, // TODO: Enable parallel uploads
          acceptedFiles,
          previewsContainer: false,
          headers: { Authorization: `Bearer ${token}` },
          maxFiles,
          maxFilesize: 10000,
        });

        dropzone.current.on('dragenter', async (event: DragEvent) => {
          event.preventDefault();
          // Persist here because we need the event later after getFilesFromEvent() is done
          (event as any).persist?.();

          const draggedFiles = (await fromEvent(event)) as any[];
          setIsDragAccept(false);

          const hasAtLeasOneNonDuplicate = Boolean(
            draggedFiles.filter(
              (f) => fileUploadStatus[getFileKey(f)] === undefined,
            )?.length,
          );
          const accepted =
            draggedFiles.length > 0 &&
            hasAtLeasOneNonDuplicate &&
            allFilesAccepted({
              files: draggedFiles,
              acceptedFiles,
              minSize: 0,
              maxSize: Number.MAX_SAFE_INTEGER,
              multiple,
              maxFiles,
            });

          const rejected =
            draggedFiles.length > 0 && hasAtLeasOneNonDuplicate && !accepted;
          setIsDragAccept(accepted);
          setIsDragReject(rejected);
          setIsDragActive(true);
        });

        dropzone.current.on('dragleave', () => {
          setIsDragAccept(false);
          setIsDragReject(false);
          setIsDragActive(false);
        });

        dropzone.current.on('success', (file, response) => {
          onFileSuccessResponse?.(file, response);
          setFileUploadStatus((currState) => ({
            ...currState,
            [getFileKey(file)]: 'success',
          }));
        });

        dropzone.current.on('error', (file, response) => {
          onFileErrorResponse?.(file, response);
          setFileUploadStatus((currState) => ({
            ...currState,
            [getFileKey(file)]: 'error',
          }));
          if (!multiple) {
            dropzone.current?.destroy();
            dropzone.current = undefined;
            initializeDropZone();
          }
        });

        resetAddedFilesCallback();
      });
    }, []);

    useEffect(() => initializeDropZone(), []);

    useEffect(() => {
      resetAddedFilesCallback();
    }, [files, setFiles, fileUploadStatus]);

    const disabled =
      maxFiles &&
      (files?.filter((f) => fileUploadStatus[getFileKey(f)] !== 'error')
        ?.length ?? 0) >= maxFiles;
    return (
      <div className="overflow-hidden transition-all duration-1000">
        <form
          action={uploadUrl}
          encType="multipart/form-data"
          method="post"
          id={staticId.current}
          className={classNames(
            'flex relative block w-full border-[1px] rounded-lg p-8 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 cursor-pointer',
            {
              'pointer-events-none opacity-80 bg-gray-100': disabled,
              'bg-blue-50': isDragActive && isDragAccept && !disabled,
              'bg-red-50': isDragActive && isDragReject && !disabled,
              'border-gray-300 hover:border-gray-400': isDragAccept,
              'border-red-200 hover:border-red-400': isDragReject,
            },
            className,
          )}
        >
          {children}
        </form>
      </div>
    );
  };

Dropzone.defaultProps = {
  acceptedTypes: ['*'],
  className: undefined,
  multiple: true,
  maxFiles: Number.MAX_SAFE_INTEGER,
  uploadUrl: undefined,
  onFileSuccessResponse: undefined,
  onFileErrorResponse: undefined,
  onFileUploadStarted: undefined,
};

export default Dropzone;
