import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/24/solid';
import CheckboxGroup from 'components/CheckboxGroup';
import { Column } from 'api/generated';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

interface ColumnFiltersProps {
  columns: Column[];
  hiddenColumns: Set<string>;
  onChange: (event: { key: string; checked: boolean }) => void;
}

const ColumnFilters = function ColumnFilters({
  columns,
  onChange,
  hiddenColumns,
}: ColumnFiltersProps) {
  const checkboxes = columns.map((col) => ({
    key: col.key,
    label: col.label || col.key,
    checked: !hiddenColumns.has(col.key),
  }));

  return (
    <Popover className="relative h-[1.75rem] py-1">
      {({ open }) => {
        let textClasses = 'text-gray-500 hover:text-gray-600';
        if (hiddenColumns?.size) {
          textClasses = 'text-blue-500 text-bold';
        } else if (open) {
          textClasses = 'text-gray-600';
        }
        return (
          <>
            <Popover.Button
              className={classNames(
                open ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                textClasses,
                'group px-1 rounded-md inline-flex items-center text-sm text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 relative',
              )}
            >
              <FunnelIcon
                className={classNames(
                  'mr-1 h-4 w-4 transition ease-in-out duration-150',
                )}
                aria-hidden="true"
              />
              <span>Filter</span>
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 right-0 mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-3xl">
                <div className="bg-white px-4 py-4 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <p className="mb-2 text-lg font-semibold">Filter Columns</p>
                  <CheckboxGroup
                    title="Filter Columns"
                    items={checkboxes}
                    onChange={onChange}
                    showTitle={false}
                    colsStyle="grid-cols-2"
                  />
                </div>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};

export default ColumnFilters;
