import CoactiveLogo from 'assets/logos/coactive-logo-stacked-rgb.svg';
import React, { useEffect, useMemo } from 'react';
import Logo from 'assets/logos/coactive-bright-green.svg';
import classNames from 'classnames';

interface FullScreenLoadingPageProps {
  color: 'white' | 'green';
}

const BACKGROUND_WHITE = 'bg-white';
const BACKGROUND_GRAY = 'bg-gray-50';
const BACKGROUND_GREEN = 'bg-[#28A862]';

const FullScreenLoadingPage: React.FC<FullScreenLoadingPageProps> = ({
  color,
}: FullScreenLoadingPageProps) => {
  // Reset main page background color
  useEffect(() => {
    const classToAdd = color === 'green' ? BACKGROUND_GREEN : BACKGROUND_WHITE;
    const classToRemove =
      color === 'green' ? BACKGROUND_WHITE : BACKGROUND_GREEN;
    document.documentElement.classList.remove(classToRemove);
    document.documentElement.classList.add(classToAdd);
    document.documentElement.classList.remove(BACKGROUND_GRAY);
    return () => {
      document.documentElement.classList.remove(BACKGROUND_WHITE);
      document.documentElement.classList.remove(BACKGROUND_GREEN);
      document.documentElement.classList.add(BACKGROUND_GRAY);
    };
  }, [color]);

  const loadingPage = useMemo(
    () => (
      <div
        className={classNames('min-h-full h-full', {
          'bg-[#28A862]': color === 'green',
          'bg-white': color === 'white',
        })}
      >
        <div className="absolute top-0 right-0 left-0 bottom-0 h-full flex items-center justify-center">
          {color === 'green' && (
            <img
              src={Logo}
              alt="loading"
              className="w-48 h-48 animate-pulse-fast"
            />
          )}
          {color === 'white' && (
            <img src={CoactiveLogo} alt="Logo" className="w-60 pb-6" />
          )}
        </div>
      </div>
    ),
    [],
  );
  return loadingPage;
};

export default FullScreenLoadingPage;
