import ImageSunsetIllustration from 'assets/illustrations/image-sunset.svg';
import ImageIllustration from 'assets/illustrations/image.svg';
import Button from 'components/Button';
import FadeTransition from 'components/FadeTransition';
import FileUpload from 'components/FileUpload';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AddAssets = function AddAssets({ datasetId }: { datasetId: string }) {
  const navigate = useNavigate();

  const [uploadError, setUploadError] = useState<string>();

  const done = () => navigate(`/datasets/${datasetId}`);

  return (
    <div>
      <div className="flex justify-end pb-2">
        <Button type="button" onClick={done} buttonStyle="secondary">
          Done
        </Button>
      </div>
      <FadeTransition show appear>
        <FileUpload
          acceptedTypes={['image/jpeg', 'image/gif', 'image/png', 'image/webp']}
          multiple
          maxFiles={200}
          uploadUrl={`${process.env.REACT_APP_API_HOST}/api/ui/datasets/${datasetId}/add_assets/upload`}
          onUploadSuccess={() => {}}
          onUploadError={(uploads) => {
            setUploadError(uploads[0].response?.detail);
          }}
          illustrations={[
            {
              primary: ImageIllustration,
              secondary: ImageSunsetIllustration,
            },
          ]}
          error={uploadError}
        >
          <p className="font-bold">Add Assets</p>
          <p className="max-w-80 pointer-events-none">
            Drop image(s) or <strong className="text-blue-500">browse</strong>.
          </p>
        </FileUpload>
      </FadeTransition>
    </div>
  );
};

export default AddAssets;
