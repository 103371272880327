/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppSharedV0SchemaPagedLinksResponse,
    AppSharedV0SchemaPagedLinksResponseFromJSON,
    AppSharedV0SchemaPagedLinksResponseFromJSONTyped,
    AppSharedV0SchemaPagedLinksResponseToJSON,
} from './AppSharedV0SchemaPagedLinksResponse';
import {
    AppSharedV0SchemaPagedPageDetailsResponse,
    AppSharedV0SchemaPagedPageDetailsResponseFromJSON,
    AppSharedV0SchemaPagedPageDetailsResponseFromJSONTyped,
    AppSharedV0SchemaPagedPageDetailsResponseToJSON,
} from './AppSharedV0SchemaPagedPageDetailsResponse';
import {
    SortItem,
    SortItemFromJSON,
    SortItemFromJSONTyped,
    SortItemToJSON,
} from './SortItem';

/**
 * Metadata about the paginated response
 * @export
 * @interface AppSharedV0SchemaPagedMetaResponse
 */
export interface AppSharedV0SchemaPagedMetaResponse {
    /**
     * 
     * @type {AppSharedV0SchemaPagedPageDetailsResponse}
     * @memberof AppSharedV0SchemaPagedMetaResponse
     */
    page: AppSharedV0SchemaPagedPageDetailsResponse;
    /**
     * 
     * @type {AppSharedV0SchemaPagedLinksResponse}
     * @memberof AppSharedV0SchemaPagedMetaResponse
     */
    links: AppSharedV0SchemaPagedLinksResponse;
    /**
     * Ordered list of fields to sort on, order of list determines priority
     * @type {Array<SortItem>}
     * @memberof AppSharedV0SchemaPagedMetaResponse
     */
    sort: Array<SortItem>;
}

export function AppSharedV0SchemaPagedMetaResponseFromJSON(json: any): AppSharedV0SchemaPagedMetaResponse {
    return AppSharedV0SchemaPagedMetaResponseFromJSONTyped(json, false);
}

export function AppSharedV0SchemaPagedMetaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppSharedV0SchemaPagedMetaResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': AppSharedV0SchemaPagedPageDetailsResponseFromJSON(json['page']),
        'links': AppSharedV0SchemaPagedLinksResponseFromJSON(json['links']),
        'sort': ((json['sort'] as Array<any>).map(SortItemFromJSON)),
    };
}

export function AppSharedV0SchemaPagedMetaResponseToJSON(value?: AppSharedV0SchemaPagedMetaResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': AppSharedV0SchemaPagedPageDetailsResponseToJSON(value.page),
        'links': AppSharedV0SchemaPagedLinksResponseToJSON(value.links),
        'sort': ((value.sort as Array<any>).map(SortItemToJSON)),
    };
}

