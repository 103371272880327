import { DateTime, Duration } from 'luxon';
import { useEffect, useRef, useState } from 'react';

export function calculateTimeElapsed(
  start: DateTime,
  end: DateTime | null | undefined,
): Duration {
  const startRounded = Math.round(start.toSeconds());
  const endRounded = Math.round((end || DateTime.now().toUTC()).toSeconds());
  return DateTime.fromSeconds(endRounded).diff(
    DateTime.fromSeconds(startRounded),
    ['minutes', 'seconds'],
  );
}

const useDuration = (
  start: string | null | undefined = undefined,
  end: string | null | undefined = undefined,
) => {
  const firstRender = useRef(true);
  const startDt = start ? DateTime.fromISO(start, { zone: 'utc' }) : undefined;
  const endDt = end ? DateTime.fromISO(end, { zone: 'utc' }) : undefined;

  const initialDuration = startDt
    ? calculateTimeElapsed(startDt, endDt)
    : undefined;
  const [duration, setDuration] = useState<Duration | undefined>(
    initialDuration,
  );

  useEffect(() => {
    if (!firstRender.current) {
      setDuration(startDt ? calculateTimeElapsed(startDt, endDt) : undefined);
    }
    firstRender.current = false;

    let interval;
    if (startDt && !endDt) {
      interval = setInterval(() => {
        setDuration(calculateTimeElapsed(startDt, endDt));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [start, end]);

  return duration;
};

export default useDuration;
