import React from 'react';

/**
 * The right-hand column in the create dataset/add assets flow.
 */
const SupportColumn: React.FC = function SupportColumn() {
  return (
    <div className="pt-16 pb-8 px-8 max-w-md grow-0">
      <h2 className="font-medium">Not sure where to start?</h2>
      <p className="text-sm pt-3 pb-6">
        <a
          className="font-medium text-green-600"
          href="mailto:support@coactive.ai"
        >
          Contact us
        </a>{' '}
        for a live demo or custom guidance on setting up.
      </p>
      <h2 className="font-medium">Coactive is SOC-2 compliant</h2>
      <p className="text-sm pt-3 pb-6">
        We do not store and silo your data.{' '}
        <a
          className="font-medium text-green-600"
          href="https://coactive.ai/security"
        >
          Learn more
        </a>{' '}
        about our data security policy.
      </p>
    </div>
  );
};

export default SupportColumn;
