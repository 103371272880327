/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A request to update a dataset embedding space
 * @export
 * @interface UpdateEmbeddingRequest
 */
export interface UpdateEmbeddingRequest {
    /**
     * The description of the embedding
     * @type {string}
     * @memberof UpdateEmbeddingRequest
     */
    description: string;
}

export function UpdateEmbeddingRequestFromJSON(json: any): UpdateEmbeddingRequest {
    return UpdateEmbeddingRequestFromJSONTyped(json, false);
}

export function UpdateEmbeddingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmbeddingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
    };
}

export function UpdateEmbeddingRequestToJSON(value?: UpdateEmbeddingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
    };
}

