import { PlusCircleIcon } from '@heroicons/react/24/solid';
import {
  Concept,
  MetadataKey,
  NoCodeQuerySortItem,
  SQLOperand,
} from 'api/generated';
import Button from 'components/Button';
import FilterRow from 'components/DatasetSearch/AdvancedSearch/FilterRow';
import SearchHistory from 'components/DatasetSearch/AdvancedSearch/SearchHistory/index';
import SortByRow from 'components/DatasetSearch/AdvancedSearch/SortByRow';
import { FilterCondition } from 'components/DatasetSearch/AdvancedSearch/types';
import { createEmptyFilterRow } from 'components/DatasetSearch/utils';
import Dropdown from 'components/Dropdown';
import Tabs from 'components/Tabs';
import React, { useEffect, useState } from 'react';

const NEW_SEARCH = 'New Search';
const SEARCH_HISTORY = 'Search History';

const AdvancedSearchPopover: React.FC<{
  close: () => void;
  datasetId: string;
  columnsLoading: boolean;
  metadataKeys: MetadataKey[];
  availableMetadataKeys: MetadataKey[];
  concepts: Concept[];
  availableConcepts: Concept[];
  filterRows: Partial<FilterCondition>[];
  setFilterRows: (filterRows: Partial<FilterCondition>[]) => void;
  removeFilterCondition: (id: string | undefined) => () => void;
  operand: SQLOperand;
  setOperand: (operand: SQLOperand) => void;
  sortItem: Partial<NoCodeQuerySortItem> | undefined;
  setSortItem: (sortItem: Partial<NoCodeQuerySortItem> | undefined) => void;
  adjustHeight: () => void;
}> = function PopoverPanelContent({
  close,
  datasetId,
  columnsLoading,
  metadataKeys,
  availableMetadataKeys,
  concepts,
  availableConcepts,
  filterRows,
  setFilterRows,
  removeFilterCondition,
  operand,
  setOperand,
  sortItem,
  setSortItem,
  adjustHeight,
}) {
  const [tab, setTab] = useState<string>(NEW_SEARCH);

  useEffect(() => {
    adjustHeight();
    // Adjust height on popup unmount (aka popup close)
    return () => adjustHeight();
  });

  return (
    <>
      <Tabs
        active={tab}
        tabs={[{ name: NEW_SEARCH }, { name: SEARCH_HISTORY }]}
        onClick={(n) => setTab(n)}
        padding="px-4"
      />
      {tab === NEW_SEARCH && (
        <div className="py-4">
          <div className="my-2 text-sm px-4">
            Match{' '}
            <Dropdown
              options={[
                { label: 'ALL', value: SQLOperand.And },
                { label: 'ANY', value: SQLOperand.Or },
              ]}
              dropdownStyle="inline"
              onChange={(v: SQLOperand) => setOperand(v)}
              selected={operand}
              className="mr-2"
            />
            of the following conditions:
          </div>
          <ul className="mb-4">
            {filterRows?.map((row, idx) => (
              <li key={row.id}>
                <FilterRow
                  availableConcepts={availableConcepts}
                  availableMetadataKeys={availableMetadataKeys}
                  columnsLoading={columnsLoading}
                  row={row}
                  onChange={(value) => {
                    const rows = [...filterRows];
                    rows[idx] = value;
                    setFilterRows(rows);
                  }}
                  remove={removeFilterCondition(row.id)}
                />
              </li>
            ))}
          </ul>
          <div className="px-4">
            <Button
              buttonStyle="link"
              icon={PlusCircleIcon}
              onClick={() =>
                setFilterRows([...filterRows, createEmptyFilterRow()])
              }
              padding="px-0"
            >
              Add a filter condition
            </Button>
          </div>
          <div className="my-4 text-sm px-4">Sort by</div>
          <SortByRow
            onChange={setSortItem}
            concepts={concepts}
            metadataKeys={metadataKeys}
            value={sortItem}
            columnsLoading={columnsLoading}
          />
        </div>
      )}
      {tab === SEARCH_HISTORY && (
        <SearchHistory
          datasetId={datasetId}
          columnInfoLoading={columnsLoading}
          onSelect={() => {
            close();
            setTab(NEW_SEARCH);
          }}
        />
      )}
    </>
  );
};

export default AdvancedSearchPopover;
