export const API_BASE_PATH = process.env.REACT_APP_API_HOST;

const COACTIVE_DEV_ORG = 'org_AHqxz0XDRvEVg7P2';

export const toggles = {
  // View concepts as a grid (alternative to the table view)
  conceptsGridView: () => false,
  // Enable versioning for concepts
  versioning: () => Boolean(process.env.REACT_APP_VERSIONS_TOGGLE === 'true'),
  // Show an OCR checkbox on the create dataset page
  ocr: (orgId: string) => orgId === COACTIVE_DEV_ORG,
};
