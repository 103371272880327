/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const MetadataTypeEnum = {
    String: 'STRING'
} as const;
export type MetadataTypeEnum = typeof MetadataTypeEnum[keyof typeof MetadataTypeEnum];


export function MetadataTypeEnumFromJSON(json: any): MetadataTypeEnum {
    return MetadataTypeEnumFromJSONTyped(json, false);
}

export function MetadataTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetadataTypeEnum {
    return json as MetadataTypeEnum;
}

export function MetadataTypeEnumToJSON(value?: MetadataTypeEnum | null): any {
    return value as any;
}

