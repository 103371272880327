import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import { AssetResponse, LabelEnum } from 'api/generated';
import classNames from 'classnames';
import Button from 'components/Button';
import React from 'react';
import { LabeledAssetResponse } from 'types/image';

interface UpdateLabelsButtonControlsProps {
  image: LabeledAssetResponse;
  labelImage: (img: AssetResponse | undefined, label: LabelEnum) => void;
  removeLabel?: () => void;
  continueLabeling?: boolean;
  disabled?: boolean;
}

const UpdateLabelsButtonControls: React.FC<UpdateLabelsButtonControlsProps> = ({
  image,
  labelImage,
  removeLabel,
  continueLabeling,
  disabled,
}) => (
  <>
    {[LabelEnum._1, LabelEnum._0].map((label) => (
      <Button
        key={label}
        type="button"
        textColor={classNames({
          'text-slate-700 focus:none': continueLabeling,
          'text-emerald-500':
            label === LabelEnum._1 && image?.label === LabelEnum._1,
          'text-red-500':
            label === LabelEnum._0 && image?.label === LabelEnum._0,
        })}
        color={classNames({
          'bg-white': continueLabeling,
          'bg-emerald-50':
            label === LabelEnum._1 && image?.label === LabelEnum._1,
          'bg-red-50': label === LabelEnum._0 && image?.label === LabelEnum._0,
        })}
        onClick={() => labelImage(image, label)}
        buttonStyle="secondary"
        iconColor={label === LabelEnum._1 ? 'text-emerald-500' : 'text-red-500'}
        rightIcon={label === LabelEnum._1 ? PlusCircleIcon : MinusCircleIcon}
        disabled={disabled}
      >
        {label === LabelEnum._1 ? 'Yes' : 'No'}
      </Button>
    ))}
    {continueLabeling ? (
      <Button
        className="text-black justify-center"
        onClick={() => labelImage(image, LabelEnum.Skip)}
        disabled={disabled}
        buttonStyle="secondary"
      >
        Skip
      </Button>
    ) : (
      <Button type="button" onClick={removeLabel!} buttonStyle="secondary">
        Remove label
      </Button>
    )}
  </>
);

UpdateLabelsButtonControls.defaultProps = {
  removeLabel: undefined,
  continueLabeling: false,
  disabled: false,
};

export default UpdateLabelsButtonControls;
