/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const CoactiveTablesEnum = {
    Adv: 'coactive_table_adv',
    Shot: 'coactive_table_shot',
    Video: 'coactive_table_video',
    Audio: 'coactive_table_audio'
} as const;
export type CoactiveTablesEnum = typeof CoactiveTablesEnum[keyof typeof CoactiveTablesEnum];


export function CoactiveTablesEnumFromJSON(json: any): CoactiveTablesEnum {
    return CoactiveTablesEnumFromJSONTyped(json, false);
}

export function CoactiveTablesEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoactiveTablesEnum {
    return json as CoactiveTablesEnum;
}

export function CoactiveTablesEnumToJSON(value?: CoactiveTablesEnum | null): any {
    return value as any;
}

