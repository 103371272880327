import {
  Column,
  ColumnDisplayType,
  QueryResultResponseUI,
  QueryStatus,
  QueryTableRowUI,
} from 'api/generated';
import ImageGrid from 'components/ImageGrid';
import usePagination from 'hooks/usePagination';
import ErrorLogs from 'pages/queries/ExecutedQueryView/ErrorLogs';
import QueryChartResults from 'pages/queries/ExecutedQueryView/QueryResults/QueryChartResults';
import QueryImageResults from 'pages/queries/ExecutedQueryView/QueryResults/QueryImageResults';
import QueryTableResults from 'pages/queries/ExecutedQueryView/QueryResults/QueryTableResults';
import { Tab } from 'pages/queries/ExecutedQueryView/types';
import {
  getQueryResultsQueryKey,
  useGetQueryDownloadUrl,
  useGetQueryResults,
} from 'pages/queries/queries';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import { TablePageSize } from 'types/table';

interface QueryResultsProps {
  query: QueryResultResponseUI | undefined;
  setSidebarData: (data: QueryTableRowUI) => void;
  basePath: string;
  setHasImages?: (hasImages: boolean) => void;
  activeTab?: Tab;
  showConceptDetail?: boolean;
}

const QueryResults: React.FC<QueryResultsProps> = function ExecutedQueryView({
  query,
  setSidebarData,
  setHasImages,
  activeTab,
  basePath,
  showConceptDetail,
}: QueryResultsProps) {
  const [hiddenColumns, setHiddenColumns] = useState(new Set<string>());
  const pagination = usePagination();
  const queryClient = useQueryClient();

  // TODO: Use active tab from the path, not props
  const offset =
    activeTab === Tab.Chart
      ? undefined
      : (pagination.page - 1) * pagination.size;
  const limit = activeTab === Tab.Chart ? undefined : pagination.size;

  const {
    data: queryResult,
    refetch: refetchQueryResult,
    isLoading,
  } = useGetQueryResults(query?.queryId, offset, limit);

  const { refetch: fetchQueryResultsDownloadUrl } = useGetQueryDownloadUrl(
    query?.queryId,
  );

  const getDownloadUrl = useCallback(async () => {
    const downloadUrlResponse = await fetchQueryResultsDownloadUrl();
    return downloadUrlResponse.data?.downloadUrl || '';
  }, [fetchQueryResultsDownloadUrl]);

  useEffect(
    () =>
      setHasImages?.(
        Boolean(
          queryResult?.results?.columns?.find(
            (col: Column) => col.displayType === ColumnDisplayType.Image,
          ),
        ),
      ),
    [queryResult],
  );

  const setPageSize = (newSize: TablePageSize) => {
    pagination.setSize(newSize);
  };
  const loadPage = (newPage: number) => {
    pagination.setPage(newPage);
  };

  const loadingGrid = useMemo(
    () => <ImageGrid images={new Array(50).fill(undefined)} />,
    [],
  );

  useEffect(
    () => () => {
      // Cancel pending requests.
      const queryKey = getQueryResultsQueryKey(query?.queryId, offset, limit);
      queryClient.cancelQueries(queryKey);
    },
    [query?.queryId, offset, limit],
  );

  useEffect(() => {
    refetchQueryResult();
  }, [query?.isActive]);

  return (
    <Routes>
      {query?.status === QueryStatus.Complete && (
        <Route
          path={`${basePath}${Tab.Results.toLowerCaseString()}`}
          element={
            <QueryTableResults
              show
              queryResult={queryResult}
              pagination={pagination}
              setPageSize={setPageSize}
              loadPage={loadPage}
              downloadCsvUrl={getDownloadUrl}
              downloadCsvUrlAuthenticated={false}
              setSidebarData={setSidebarData}
              hiddenColumns={hiddenColumns}
              setHiddenColumns={setHiddenColumns}
            />
          }
        />
      )}
      {query?.status === QueryStatus.Complete && (
        <Route
          path={`${basePath}${Tab.Content.toLowerCaseString()}`}
          element={
            isLoading ? (
              loadingGrid
            ) : (
              <QueryImageResults
                show
                queryResult={queryResult}
                pagination={pagination}
                setPageSize={setPageSize}
                loadPage={loadPage}
                setSidebarData={setSidebarData}
                showConceptDetail={showConceptDetail}
              />
            )
          }
        />
      )}
      {query?.status === QueryStatus.Complete && (
        <Route
          path={`${basePath}${Tab.Chart.toLowerCaseString()}`}
          element={<QueryChartResults show queryResult={queryResult} />}
        />
      )}
      {query?.status === QueryStatus.Error && (
        <Route
          path={`${basePath}${Tab.Error.toLowerCaseString()}`}
          element={<ErrorLogs show logs={queryResult?.error} />}
        />
      )}
    </Routes>
  );
};

QueryResults.defaultProps = {
  activeTab: undefined,
  setHasImages: undefined,
  showConceptDetail: false,
};

export default QueryResults;
