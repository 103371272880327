import { EditorState } from '@uiw/react-codemirror';

export function editorHasSelection(state: EditorState) {
  return state.selection.ranges.some((r) => !r.empty);
}

export function getSelectedText(state: EditorState) {
  const { selection } = state;
  return state.sliceDoc(selection.main.from, selection.main.to);
}
