/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const KeyframeSamplingMethodEnum = {
    Intelligent: 'intelligent',
    Uniform: 'uniform'
} as const;
export type KeyframeSamplingMethodEnum = typeof KeyframeSamplingMethodEnum[keyof typeof KeyframeSamplingMethodEnum];


export function KeyframeSamplingMethodEnumFromJSON(json: any): KeyframeSamplingMethodEnum {
    return KeyframeSamplingMethodEnumFromJSONTyped(json, false);
}

export function KeyframeSamplingMethodEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyframeSamplingMethodEnum {
    return json as KeyframeSamplingMethodEnum;
}

export function KeyframeSamplingMethodEnumToJSON(value?: KeyframeSamplingMethodEnum | null): any {
    return value as any;
}

