/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextPrompt,
    TextPromptFromJSON,
    TextPromptFromJSONTyped,
    TextPromptToJSON,
} from './TextPrompt';
import {
    VisualPrompt,
    VisualPromptFromJSON,
    VisualPromptFromJSONTyped,
    VisualPromptToJSON,
} from './VisualPrompt';

/**
 * The base model for any single resource HTTP response
 * @export
 * @interface DynamicTagTableRowData
 */
export interface DynamicTagTableRowData {
    /**
     * The user that created the resource
     * @type {string}
     * @memberof DynamicTagTableRowData
     */
    createdUserId: string;
    /**
     * The created datetime of the resource
     * @type {Date}
     * @memberof DynamicTagTableRowData
     */
    createdDt: string;
    /**
     * The user that last updated the resource
     * @type {string}
     * @memberof DynamicTagTableRowData
     */
    updatedUserId: string;
    /**
     * The datetime the resource was last updated
     * @type {Date}
     * @memberof DynamicTagTableRowData
     */
    updatedDt: string;
    /**
     * The unique identifier of the dynamic tag
     * @type {string}
     * @memberof DynamicTagTableRowData
     */
    dynamicTagId: string;
    /**
     * The name of the dynamic tag
     * @type {string}
     * @memberof DynamicTagTableRowData
     */
    name: string;
    /**
     * The list of text prompts for this dynamic tag
     * @type {Array<TextPrompt>}
     * @memberof DynamicTagTableRowData
     */
    textPrompts: Array<TextPrompt> | null;
    /**
     * The list of visual prompts for this dynamic tag
     * @type {Array<VisualPrompt>}
     * @memberof DynamicTagTableRowData
     */
    visualPrompts: Array<VisualPrompt> | null;
    /**
     * The category id
     * @type {string}
     * @memberof DynamicTagTableRowData
     */
    categoryId: string;
    /**
     * The category name
     * @type {string}
     * @memberof DynamicTagTableRowData
     */
    categoryName: string;
}

export function DynamicTagTableRowDataFromJSON(json: any): DynamicTagTableRowData {
    return DynamicTagTableRowDataFromJSONTyped(json, false);
}

export function DynamicTagTableRowDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DynamicTagTableRowData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdUserId': json['createdUserId'],
        'createdDt': json['createdDt'],
        'updatedUserId': json['updatedUserId'],
        'updatedDt': json['updatedDt'],
        'dynamicTagId': json['dynamicTagId'],
        'name': json['name'],
        'textPrompts': (json['textPrompts'] === null ? null : (json['textPrompts'] as Array<any>).map(TextPromptFromJSON)),
        'visualPrompts': (json['visualPrompts'] === null ? null : (json['visualPrompts'] as Array<any>).map(VisualPromptFromJSON)),
        'categoryId': json['categoryId'],
        'categoryName': json['categoryName'],
    };
}

export function DynamicTagTableRowDataToJSON(value?: DynamicTagTableRowData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdUserId': value.createdUserId,
        'createdDt': value.createdDt,
        'updatedUserId': value.updatedUserId,
        'updatedDt': value.updatedDt,
        'dynamicTagId': value.dynamicTagId,
        'name': value.name,
        'textPrompts': (value.textPrompts === null ? null : (value.textPrompts as Array<any>).map(TextPromptToJSON)),
        'visualPrompts': (value.visualPrompts === null ? null : (value.visualPrompts as Array<any>).map(VisualPromptToJSON)),
        'categoryId': value.categoryId,
        'categoryName': value.categoryName,
    };
}

