import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  useRequestTrialExtensionMutation,
  useUserContext,
} from 'context/UserContext';
import CoactiveLogo from 'assets/logos/coactive.svg';
import { CheckIcon } from '@heroicons/react/24/outline';

const EndOfTrialModal: React.FC = function EndOfTrialModal() {
  const { isTrialOver, didRequestExtension } = useUserContext();
  const { mutate: updateUser } = useRequestTrialExtensionMutation();

  const [didRequestExtensionState, setDidRequestExtensionState] =
    useState(didRequestExtension);

  const handleTrialExtensionRequest = () => {
    setDidRequestExtensionState(true);
    updateUser();
  };

  const handleSendFeedback = () => {
    window.open(
      'https://surveys.hotjar.com/f5614c75-0a68-4c4b-8e06-c122589e52a5',
      '_blank',
    );
  };

  return (
    <Transition.Root show={isTrialOver} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        open={isTrialOver}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-7 py-7 text-left shadow-xl transition-all max-h-35 my-8 max-w-xl">
                <div>
                  <div className="flex items-start justify-start">
                    <img src={CoactiveLogo} alt="Logo" className="h-6" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-medium leading-6 text-gray-900 text-left"
                    >
                      Thank you for using our private alpha
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-md text-gray-700 text-left">
                        Your trial has ended. Learn more about what will happen
                        to your data{' '}
                        <a href="https://coactive.ai/">
                          <u>here.</u>
                        </a>
                      </p>
                      <br />
                      <p className="text-lg font-medium leading-6 text-gray-900 text-left">
                        One more thing...
                      </p>
                      <p className="text-md text-gray-700 text-left">
                        We are actively working on our features - your feedback
                        would be very impactful.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 sm:col-start-2"
                    onClick={handleSendFeedback}
                  >
                    Share feedback
                  </button>

                  {didRequestExtensionState ? (
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-100 sm:col-start-1 sm:mt-0"
                      disabled
                    >
                      <CheckIcon className="h-5 w-5 mx-2" />
                      Request sent
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={handleTrialExtensionRequest}
                    >
                      Request trial extension
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EndOfTrialModal;
