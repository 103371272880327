import { useAuth0 } from '@auth0/auth0-react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import FileSaver from 'file-saver';
import React from 'react';
import { getFilenameFromUrl } from 'utils/UrlUtils';
import Button from './Button';

const ProtectedFileDownload: React.FC<{
  url: string | (() => Promise<string>);
  filename?: string | ((url: string) => string);
  useAuth?: boolean;
}> = function ProtectedFileDownload({
  url: urlProp,
  filename: filenameProp,
  useAuth,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const fetchFile = async () => {
    const url = typeof urlProp === 'string' ? urlProp : await urlProp();
    const res = await fetch(url, {
      headers: useAuth
        ? { Authorization: `Bearer ${await getAccessTokenSilently()}` }
        : {},
    });
    const filenameFromProp =
      typeof filenameProp === 'string' ? filenameProp : filenameProp?.(url);
    const filename =
      filenameFromProp ??
      getFilenameFromUrl(url) ??
      `${Math.floor(Math.random() * 1e12)}`;
    const blob = await res.blob();
    FileSaver.saveAs(blob, filename);
  };
  return (
    <Button
      type="button"
      className="h-8 py-1"
      onClick={fetchFile}
      buttonStyle="secondary"
      icon={ArrowDownTrayIcon}
      size="small"
    >
      Download CSV
    </Button>
  );
};

ProtectedFileDownload.defaultProps = {
  filename: undefined,
  useAuth: true,
};

export default ProtectedFileDownload;
