/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Concept,
    ConceptFromJSON,
    ConceptFromJSONTyped,
    ConceptToJSON,
} from './Concept';
import {
    MetadataKey,
    MetadataKeyFromJSON,
    MetadataKeyFromJSONTyped,
    MetadataKeyToJSON,
} from './MetadataKey';

/**
 * 
 * @export
 * @interface DatasetQuerySearchParametersResponse
 */
export interface DatasetQuerySearchParametersResponse {
    /**
     * Embedding id used
     * @type {string}
     * @memberof DatasetQuerySearchParametersResponse
     */
    embeddingId: string;
    /**
     * Concepts available to query
     * @type {Array<Concept>}
     * @memberof DatasetQuerySearchParametersResponse
     */
    concepts: Array<Concept>;
    /**
     * Metadata keys available to query
     * @type {Array<MetadataKey>}
     * @memberof DatasetQuerySearchParametersResponse
     */
    metadataKeys: Array<MetadataKey>;
}

export function DatasetQuerySearchParametersResponseFromJSON(json: any): DatasetQuerySearchParametersResponse {
    return DatasetQuerySearchParametersResponseFromJSONTyped(json, false);
}

export function DatasetQuerySearchParametersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasetQuerySearchParametersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'embeddingId': json['embeddingId'],
        'concepts': ((json['concepts'] as Array<any>).map(ConceptFromJSON)),
        'metadataKeys': ((json['metadataKeys'] as Array<any>).map(MetadataKeyFromJSON)),
    };
}

export function DatasetQuerySearchParametersResponseToJSON(value?: DatasetQuerySearchParametersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'embeddingId': value.embeddingId,
        'concepts': ((value.concepts as Array<any>).map(ConceptToJSON)),
        'metadataKeys': ((value.metadataKeys as Array<any>).map(MetadataKeyToJSON)),
    };
}

