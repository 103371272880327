import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  FunnelIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import {
  Comparator,
  MetadataType,
  NoCodeQuerySortItem,
  SQLOperand,
  SortDirection,
} from 'api/generated';
import Button from 'components/Button';
import {
  ConceptData,
  ConceptFilterType,
  FilterCondition,
  MetadataData,
} from 'components/DatasetSearch/AdvancedSearch/types';
import {
  convertUTCDateToLocal,
  getValidSortItem,
} from 'components/DatasetSearch/utils';
import { DateTime } from 'luxon';
import React, { PropsWithChildren } from 'react';

function getTextForComparator(comparator: Comparator, type?: MetadataType) {
  if (type === 'datetime') {
    if (comparator === Comparator.GreaterThan) {
      return 'after';
    }
    if (comparator === Comparator.LessThan) {
      return 'before';
    }
  }
  switch (comparator) {
    case Comparator.EndsWith:
      return 'ends with';
    case Comparator.Equals:
      return 'is';
    case Comparator.NotEqual:
      return 'is not';
    case Comparator.StartsWith:
      return 'starts with';
    default:
      return comparator;
  }
}

const PILL_DATE_FORMAT = 'MMM dd, yyyy h:mma';

function formatUTCTime(datetime: string | null | undefined) {
  const utcDatetime = datetime ? convertUTCDateToLocal(datetime) : null;
  return utcDatetime
    ? DateTime.fromISO(utcDatetime)?.toFormat(PILL_DATE_FORMAT)
    : '';
}

const FilterPill: React.FC<PropsWithChildren<{ remove?: () => void }>> = ({
  children,
  remove,
}) => (
  <div className="rounded-md p-2 bg-gray-200 inline-flex group cursor-default m-0.5 text-sm items-center whitespace-nowrap">
    {children}
    {remove && (
      <div className="pl-2 shrink-0 grow-0 hidden group-hover:flex items-center justify-center">
        <Button
          icon={XMarkIcon}
          onClick={remove}
          buttonStyle="link"
          padding="p-0"
          textColor="text-gray-400"
          textHoverColor="hover:text-gray-500"
        />
      </div>
    )}
  </div>
);

FilterPill.defaultProps = {
  remove: undefined,
};

const PillIcon: React.FC<{
  icon: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>;
}> = ({ icon }) => {
  const Icon = icon;
  return (
    <Icon className="flex-shrink-0 h-5 w-5 rounded-full fill-slate-400 mr-1" />
  );
};

const SortOrderPill: React.FC<
  PropsWithChildren<{
    label: string;
    order: SortDirection;
    remove: (() => void) | undefined;
  }>
> = ({ label, order, remove }) => (
  <FilterPill remove={remove}>
    <PillIcon icon={order === 'desc' ? BarsArrowDownIcon : BarsArrowUpIcon} />
    <span>{label}</span>
  </FilterPill>
);

const MetadataFilterPill: React.FC<{
  data: MetadataData[];
  remove: (() => void) | undefined;
}> = ({ data, remove }) => {
  const { key, comparator, value } = data[0];
  const secondValue = data.length > 1 ? data[1].value : undefined;
  return (
    <FilterPill remove={remove}>
      <PillIcon icon={FunnelIcon} />
      {value && secondValue ? (
        <span>
          {key} in range {key === 'created_dt' ? formatUTCTime(value) : value}{' '}
          to {key === 'created_dt' ? formatUTCTime(secondValue) : value}
        </span>
      ) : (
        <span>
          {key}{' '}
          {comparator
            ? `${getTextForComparator(comparator, data[0].type)} `
            : ''}
          {key === 'created_dt' ? formatUTCTime(value) : value}
        </span>
      )}
    </FilterPill>
  );
};

const ConceptFilterPill: React.FC<{
  data: ConceptData[];
  remove: (() => void) | undefined;
}> = ({ data, remove }) => {
  const { name, value, threshold, type } = data[0];
  const maxProb = data.length > 1 ? data[1].value : undefined;
  return (
    <FilterPill remove={remove}>
      <PillIcon icon={FunnelIcon} />
      <span>
        {name}
        {type === ConceptFilterType.Positive && ' is positive'}
        {type === ConceptFilterType.Negative && ' is negative'}
        {type === ConceptFilterType.ProbGreaterThan &&
          ` greater than ${value ?? threshold}`}
        {type === ConceptFilterType.ProbLessThan &&
          ` less than ${value ?? threshold}`}
        {type === ConceptFilterType.ProbBetween &&
          ` between ${value} and ${maxProb}`}
      </span>
    </FilterPill>
  );
};

interface FilterPillsProps {
  filterConditions: FilterCondition[];
  operand: string;
  sortItem: Partial<NoCodeQuerySortItem> | undefined;
  removeSortItem?: () => void;
  removeFilterCondition?: (id: string) => () => void;
  placeholder?: string;
}

const FilterPills: React.FC<FilterPillsProps> = function FilterPills({
  filterConditions,
  operand,
  sortItem,
  removeFilterCondition,
  removeSortItem,
  placeholder,
}) {
  const isSortItemValid = Boolean(getValidSortItem(sortItem));
  return (
    <>
      {!filterConditions?.length && !isSortItemValid && (
        <span className="text-gray-500 px-2">{placeholder}</span>
      )}
      {Boolean(filterConditions?.length) && filterConditions?.length > 1 && (
        <FilterPill>
          Match:{operand === SQLOperand.And ? 'ALL' : 'ANY'}
        </FilterPill>
      )}
      {filterConditions.map((row) => {
        if (row.concepts) {
          return (
            <ConceptFilterPill
              key={`${row.id}-${row.concepts[0]?.conceptId}`}
              data={row.concepts}
              remove={removeFilterCondition?.(row.id)}
            />
          );
        }
        if (row.metadataKeys) {
          return (
            <MetadataFilterPill
              key={`${row.id}-${row.metadataKeys[0]?.key}`}
              data={row.metadataKeys}
              remove={removeFilterCondition?.(row.id)}
            />
          );
        }
        return null;
      })}
      {isSortItemValid && (
        <SortOrderPill
          remove={removeSortItem}
          label={sortItem?.field!}
          order={sortItem?.direction!}
        />
      )}
    </>
  );
};

FilterPills.defaultProps = {
  removeSortItem: undefined,
  removeFilterCondition: undefined,
  placeholder: undefined,
};

export default FilterPills;
