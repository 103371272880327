/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Error validation for an input field
 * @export
 * @interface FieldValidation
 */
export interface FieldValidation {
    /**
     * Whether the field value is valid
     * @type {boolean}
     * @memberof FieldValidation
     */
    value: boolean;
    /**
     * The validation error message if the field is invalid
     * @type {string}
     * @memberof FieldValidation
     */
    message?: string | null;
}

export function FieldValidationFromJSON(json: any): FieldValidation {
    return FieldValidationFromJSONTyped(json, false);
}

export function FieldValidationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldValidation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function FieldValidationToJSON(value?: FieldValidation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'message': value.message,
    };
}

