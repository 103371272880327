import FadeTransition from 'components/FadeTransition';
import ImageGrid from 'components/ImageGrid';
import Main from 'components/Main';
import { RefineSidebarContextWrapper } from 'context/RefineSidebarContext';
import {
  useGetLatestConceptVersionQuery,
  useGetVersionQuery,
  useGetConceptQuery,
} from 'pages/concepts/queries';
import Page404 from 'pages/Page404';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import ConceptContent from './Content';

const Concept = function Concept() {
  const { conceptId: _conceptId, conceptVersionId: _conceptVersionId } =
    useParams();
  const [conceptId, setConceptId] = useState('');
  const [conceptVersionId, setVersionId] = useState<string | 'latest'>('');
  const {
    data: latestVersion,
    refetch: fetchLatestVersion,
    isLoading: isLatestVersionLoading,
    error: latestVersionError,
  } = useGetLatestConceptVersionQuery(conceptId);
  const {
    data: version,
    refetch: fetchVersionById,
    isLoading: isVersionLoading,
    error: versionError,
  } = useGetVersionQuery(conceptVersionId);
  const {
    data: concept,
    refetch: fetchConcept,
    isLoading: isConceptLoading,
    error: conceptError,
  } = useGetConceptQuery(conceptId);
  const navigate = useNavigate();
  const conceptIsNotFound = useMemo(() => {
    if (
      latestVersionError &&
      ((latestVersionError as any).response as Response).status === 404
    ) {
      return true;
    }
    if (
      versionError &&
      ((versionError as any).response as Response).status === 404
    ) {
      return true;
    }
    if (
      conceptError &&
      ((conceptError as any).response as Response).status === 404
    ) {
      return true;
    }
    return false;
  }, [latestVersionError, versionError, conceptError]);
  useEffect(() => {
    if (!_conceptVersionId || !_conceptId) {
      navigate('/concepts');
      return;
    }
    if (uuidValidate(_conceptId)) {
      setConceptId(_conceptId);
    } else {
      navigate('/concepts');
    }
    if (_conceptVersionId === 'latest') {
      setVersionId('latest');
    } else if (uuidValidate(_conceptVersionId)) {
      setVersionId(_conceptVersionId);
    } else {
      navigate('/concepts');
    }
  }, [_conceptId, _conceptVersionId]);

  useEffect(() => {
    if (uuidValidate(conceptId)) {
      fetchConcept();
      if (conceptVersionId === 'latest') {
        fetchLatestVersion();
      }
    }
  }, [conceptId, conceptVersionId]);

  useEffect(() => {
    if (uuidValidate(conceptVersionId)) {
      fetchVersionById();
    }
  }, [conceptVersionId]);

  const refreshVersion = async () => {
    try {
      if (conceptVersionId === 'latest') {
        return fetchLatestVersion();
      }
      return fetchVersionById();
    } catch (error) {
      navigate('/concepts');
      return undefined;
    }
  };

  const showLoading =
    isLatestVersionLoading || isConceptLoading || isVersionLoading;

  const loadingGrid = useMemo(
    () => (
      <FadeTransition appear show={showLoading} exit={false} enterDelayMs={500}>
        <ImageGrid images={new Array(20).fill(undefined)} />
      </FadeTransition>
    ),
    [showLoading],
  );
  if (conceptIsNotFound) {
    return <Page404 />;
  }
  return (
    <RefineSidebarContextWrapper>
      {(latestVersion || version) && concept ? (
        <ConceptContent
          conceptId={conceptId}
          conceptVersionId={latestVersion?.versionId || conceptVersionId}
          version={latestVersion || version!}
          concept={concept}
          refreshVersion={refreshVersion}
          loadingGrid={loadingGrid}
        />
      ) : (
        <FadeTransition show={showLoading} appear enterDelayMs={500}>
          {showLoading && (
            <Main title="loading" breadcrumbs={[]} loading>
              <div className="pt-32">{loadingGrid}</div>
            </Main>
          )}
        </FadeTransition>
      )}
    </RefineSidebarContextWrapper>
  );
};

export default Concept;
