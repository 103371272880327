import Dropdown from 'components/Dropdown';
import { DateTime } from 'luxon';
import {
  dateFormatter,
  getAxisDomainFromData,
  getAxisScaleFromData,
  getAxisTypeFromData,
  getTicks,
  massageData,
} from 'pages/queries/Chart/util';
import React, { useMemo, useState } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  Scatter,
  ScatterChart,
  // AreaChart,
  // Area,
  BarChart,
  Bar,
} from 'recharts';
import BarChartIcon from 'assets/icons/bar-chart.png';
import ScatterIcon from 'assets/icons/scatter-graph.png';
// import AreaIcon from 'assets/icons/area-chart.png';
import LineIcon from 'assets/icons/graph.png';
import classNames from 'classnames';
import { Column } from 'api/generated';

const NUM_X_TICKS = 5;

function sanitizeValue(value: any): string {
  try {
    const dateStr = DateTime.fromJSDate(value).toFormat('MM/dd/yy');
    return dateStr === 'Invalid DateTime' ? value : dateStr;
  } catch (e) {
    return value;
  }
}

const CustomTooltip = function CustomTooltip({ active, payload }: any) {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none p-4">
        {Object.entries(payload?.[0]?.payload).map(([key, value]) => (
          <p key={key} className="">
            <span className="font-semibold">{key}</span>: {sanitizeValue(value)}
            {/* {dim.unit} */}
          </p>
        ))}
        {/* <p className="label">{`${label} : ${payload[0].value}`}</p>
        <p className="intro">{getIntroOfPage(label)}</p>
        <p className="desc">Anything you want can be displayed here.</p> */}
      </div>
    );
  }

  return null;
};

interface ChartProps {
  data: { [key: string]: any }[];
  columns: Column[];
  x?: string;
  y?: string;
  defaultChartType?: 'bar' | 'line' | 'scatter';
}

// https://codesandbox.io/s/recharts-area-chart-with-date-axis-6o55k?file=/src/DateArea.js:3377-3381

const Chart: React.FC<ChartProps> = function Chart({
  data,
  columns,
  x,
  y,
  defaultChartType,
}) {
  const [xAxis, setXAxis] = useState(x);
  const [yAxis, setYAxis] = useState(y);
  const [type, setType] = useState(defaultChartType || '');

  const options = columns.map((col) => ({
    ...col,
    value: col.key,
    label: col.label || col.key,
  }));

  const massagedData = useMemo(
    () => massageData(data, columns, xAxis),
    [data, columns, xAxis, type === 'line'],
  );

  const xAxisCol = columns.find((col) => col.key === xAxis);
  const yAxisCol = columns.find((col) => col.key === yAxis);

  const xAxisScale = getAxisScaleFromData(xAxis, columns);
  const yAxisScale = getAxisScaleFromData(yAxis, columns);

  const colsMap = {};
  columns.forEach((col) => {
    colsMap[col.key] = col;
  });

  const xTicks =
    xAxis && xAxisScale === 'time'
      ? getTicks(
          massagedData?.[0][xAxis],
          massagedData?.[data.length - 1][xAxis],
          NUM_X_TICKS,
        )
      : undefined;

  const yTicks =
    yAxis && yAxisScale === 'time'
      ? getTicks(
          massagedData?.[0][yAxis].getTime(),
          massagedData?.[data.length - 1][yAxis].getTime(),
          10,
        )
      : undefined;

  const chartTypeOptions = [
    {
      label: 'Line Chart',
      id: 'line',
      icon: LineIcon,
    },
    {
      label: 'Scatter Chart',
      id: 'scatter',
      icon: ScatterIcon,
    },
    {
      label: 'Bar Chart',
      id: 'bar',
      icon: BarChartIcon,
    },
    // {
    //   label: 'Area Chart',
    //   id: 'area',
    //   icon: AreaIcon,
    // },
  ];

  return (
    <div className="pt-4 pb-60">
      <div className="pb-8 flex">
        <div className="inline-block max-w-xs w-72">
          <Dropdown label="X Axis" options={options} onChange={setXAxis} />
        </div>
        <div className="inline-block max-w-xs w-72 pl-4">
          <Dropdown label="Y Axis" options={options} onChange={setYAxis} />
        </div>
        <div className="inline-block max-w-xs w-72 pl-4">
          <fieldset className="inline-block">
            <legend className="block text-sm font-medium text-gray-700">
              Chart Type
            </legend>
            <div className="flex border border-gray-300 rounded-md mt-1">
              {chartTypeOptions.map((opt) => (
                <div key={opt.id} className="block">
                  <input
                    type="radio"
                    className="sr-only"
                    name="chart-type"
                    id={opt.id}
                    value={opt.id}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setType(opt.id);
                      }
                    }}
                  />
                  <label
                    htmlFor={opt.id}
                    className={classNames(
                      'block box-content bg-center bg-no-repeat h-6 w-6 px-2 py-1.5 cursor-pointer border-1 border-blue-500 rounded-md',
                      {
                        'bg-green-500': type === opt.id,
                      },
                    )}
                  >
                    <div
                      style={{ backgroundImage: `url(${opt.icon})` }}
                      className={classNames('h-full w-full bg-contain', {
                        invert: type === opt.id,
                        'opacity-50': type !== opt.id,
                      })}
                    />
                    <span className="sr-only">{opt.label}</span>
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>
      </div>

      {xAxis && yAxis && type === 'scatter' && (
        <ResponsiveContainer width="100%" height="100%" minHeight="20rem">
          <ScatterChart
            width={400}
            height={400}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid />
            <XAxis
              type={getAxisTypeFromData(xAxis, massagedData)}
              label={{
                value: xAxisCol?.label,
                position: 'bottom',
                dy: 0,
              }}
              dataKey={xAxis}
              name={xAxisCol?.label ?? undefined}
              unit={xAxisCol?.unit ?? undefined}
              scale={xAxisScale}
              ticks={xAxisScale === 'time' ? (xTicks as any) : undefined}
              tickFormatter={xAxisScale === 'time' ? dateFormatter : undefined}
              domain={getAxisDomainFromData(xAxis, massagedData)}
            />
            <YAxis
              type={getAxisTypeFromData(yAxis, massagedData)}
              label={{
                value: yAxisCol?.label,
                position: 'left',
                angle: -90,
              }}
              dataKey={yAxis}
              name={yAxisCol?.label ?? undefined}
              unit={yAxisCol?.unit ?? undefined}
              ticks={yAxisScale === 'time' ? (yTicks as any) : undefined}
              scale={getAxisScaleFromData(yAxis, massagedData)}
              tickFormatter={yAxisScale === 'time' ? dateFormatter : undefined}
            />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={CustomTooltip}
              // getTranslate={({ key }) =>
              //   colsMap[key]?.data_type === 'date'
              //     ? (d) => DateTime.fromJSDate(d).toFormat('MM/dd')
              //     : (v) => v
              // }
            />
            <Scatter name="A school" data={massagedData} fill="#3b82f6" />
          </ScatterChart>
        </ResponsiveContainer>
      )}
      {/* 
      {xAxis && yAxis && type === 'area' && (
        <ResponsiveContainer width="100%" height="100%" minHeight="20rem">
          <AreaChart
            width={400}
            height={400}
            data={fillTicksData(xTicks, xAxis, massagedData)}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid />
            <XAxis
              type={getAxisTypeFromData(xAxis, massagedData)}
              dataKey={xAxis}
              name={xAxisCol?.label}
              unit={xAxisCol?.unit}
              scale={xAxisScale}
              ticks={xAxisScale === 'time' ? xTicks : undefined}
              tickFormatter={xAxisScale === 'time' ? dateFormatter : undefined}
            />
            <YAxis
              type={getAxisTypeFromData(yAxis, massagedData)}
              dataKey={yAxis}
              name={yAxisCol?.label}
              unit={yAxisCol?.unit}
              ticks={yAxisScale === 'time' ? yTicks : undefined}
              scale={getAxisScaleFromData(yAxis, massagedData)}
              tickFormatter={yAxisScale === 'time' ? dateFormatter : undefined}
            />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              getTranslate={({ key }) =>
                colsMap[key]?.data_type === 'date'
                  ? (d) => DateTime.fromJSDate(d).toFormat('MM/dd')
                  : (v) => v
              }
            />
            <Area
              type="monotone"
              dataKey="val"
              stroke="#ff7300"
              fill="#ff7300"
              fillOpacity={0.9}
            />
          </AreaChart>
        </ResponsiveContainer>
      )} */}

      {xAxis && yAxis && type === 'line' && (
        <ResponsiveContainer
          width="100%"
          height="100%"
          minHeight="20rem"
          className="text-sm"
        >
          <LineChart
            width={400}
            height={400}
            data={massagedData}
            margin={{
              top: 20,
              right: 20,
              bottom: 40,
              left: 20,
            }}
          >
            <CartesianGrid />
            <XAxis
              type={getAxisTypeFromData(xAxis, massagedData)}
              dataKey={xAxis}
              label={{
                value: xAxisCol?.label,
                position: 'bottom',
                dy: 0,
              }}
              name={xAxisCol?.label ?? undefined}
              unit={xAxisCol?.unit ?? undefined}
              scale={xAxisScale}
              ticks={xAxisScale === 'time' ? (xTicks as any) : undefined}
              tickFormatter={xAxisScale === 'time' ? dateFormatter : undefined}
              domain={getAxisDomainFromData(xAxis, massagedData)}
            />
            <YAxis
              type={getAxisTypeFromData(yAxis, massagedData)}
              dataKey={yAxis}
              label={{
                value: yAxisCol?.label,
                position: 'left',
                angle: -90,
              }}
              name={yAxisCol?.label ?? undefined}
              unit={yAxisCol?.unit ?? undefined}
              ticks={yAxisScale === 'time' ? (yTicks as any) : undefined}
              scale={getAxisScaleFromData(yAxis, massagedData)}
              tickFormatter={yAxisScale === 'time' ? dateFormatter : undefined}
            />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              // ={({ key }) =>
              //   colsMap[key]?.data_type === 'date'
              //     ? (d) => DateTime.fromJSDate(d).toFormat('MM/dd')
              //     : (v) => v
              // }
              content={CustomTooltip}
            />
            <Line
              type="monotone"
              dataKey={yAxis}
              stroke="#3b82f6"
              // activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}

      {xAxis && yAxis && type === 'bar' && (
        <ResponsiveContainer width="100%" height="100%" minHeight="20rem">
          <BarChart
            width={500}
            height={300}
            data={massagedData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={xAxis}
              tickFormatter={xAxisScale === 'time' ? dateFormatter : undefined}
              label={{
                value: xAxisCol?.label,
                position: 'bottom',
                dy: 0,
              }}
            />
            <YAxis
              label={{
                value: yAxisCol?.label,
                position: 'left',
                angle: -90,
              }}
            />
            <Tooltip />
            <Legend layout="horizontal" verticalAlign="top" align="right" />
            <Bar dataKey={yAxis} fill="#3b82f6" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

Chart.defaultProps = {
  x: undefined,
  y: undefined,
  defaultChartType: undefined,
};

export default Chart;
