/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddAssetsToDatasetRequest,
  AddMetadataRequest,
  CreateDatasetRequest,
  CreateDynamicTagTableRequest,
  DatasetListResponse,
  DatasetResponse,
  DatasetStatusEnum,
  DatasetTableResponse,
  EncoderListResponse,
  ErrorResponse,
  FullDatasetResponse,
  HTTPValidationError,
  MetadataCsvPreviewRequest,
  MetadataPreviewResponse,
  MetadataUploadResponse,
  PagedAssetsResponse,
  UpdateDatasetRequest,
  UploadResponse,
  ValidateDatasetNameRequest,
  ValidateDatasetNameResponse,
} from '../models';
import {
    AddAssetsToDatasetRequestFromJSON,
    AddAssetsToDatasetRequestToJSON,
    AddMetadataRequestFromJSON,
    AddMetadataRequestToJSON,
    CreateDatasetRequestFromJSON,
    CreateDatasetRequestToJSON,
    CreateDynamicTagTableRequestFromJSON,
    CreateDynamicTagTableRequestToJSON,
    DatasetListResponseFromJSON,
    DatasetListResponseToJSON,
    DatasetResponseFromJSON,
    DatasetResponseToJSON,
    DatasetStatusEnumFromJSON,
    DatasetStatusEnumToJSON,
    DatasetTableResponseFromJSON,
    DatasetTableResponseToJSON,
    EncoderListResponseFromJSON,
    EncoderListResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FullDatasetResponseFromJSON,
    FullDatasetResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    MetadataCsvPreviewRequestFromJSON,
    MetadataCsvPreviewRequestToJSON,
    MetadataPreviewResponseFromJSON,
    MetadataPreviewResponseToJSON,
    MetadataUploadResponseFromJSON,
    MetadataUploadResponseToJSON,
    PagedAssetsResponseFromJSON,
    PagedAssetsResponseToJSON,
    UpdateDatasetRequestFromJSON,
    UpdateDatasetRequestToJSON,
    UploadResponseFromJSON,
    UploadResponseToJSON,
    ValidateDatasetNameRequestFromJSON,
    ValidateDatasetNameRequestToJSON,
    ValidateDatasetNameResponseFromJSON,
    ValidateDatasetNameResponseToJSON,
} from '../models';

export interface AddAssetsToDatasetOperationRequest {
    datasetId: string;
    addAssetsToDatasetRequest: AddAssetsToDatasetRequest;
}

export interface AddMetadataOperationRequest {
    datasetId: string;
    addMetadataRequest: AddMetadataRequest;
}

export interface CreateDatasetOperationRequest {
    createDatasetRequest: CreateDatasetRequest;
}

export interface DeleteDatasetRequest {
    datasetId: string;
}

export interface GetAvailableEncodersRequest {
    offset?: number | null;
    limit?: number | null;
}

export interface GetDatasetRequest {
    datasetId: string;
}

export interface GetDatasetImagesRequest {
    datasetId: string;
    offset?: number | null;
    limit?: number | null;
}

export interface GetDatasetVideosRequest {
    datasetId: string;
    offset?: number | null;
    limit?: number | null;
}

export interface GetDatasetsRequest {
    offset?: number | null;
    limit?: number | null;
    statusesToInclude?: Array<DatasetStatusEnum> | null;
}

export interface GetDatasetsAsTableRequest {
    offset?: number | null;
    limit?: number | null;
    statusesToInclude?: Array<DatasetStatusEnum> | null;
    regexpPattern?: string | null;
}

export interface GetMetadataPreviewRequest {
    datasetId: string;
    metadataCsvPreviewRequest: MetadataCsvPreviewRequest;
    offset?: number | null;
    limit?: number | null;
}

export interface LegacyCreateDynamicTagTableRequest {
    datasetId: string;
    createDynamicTagTableRequest: CreateDynamicTagTableRequest;
}

export interface UpdateDatasetOperationRequest {
    datasetId: string;
    updateDatasetRequest: UpdateDatasetRequest;
}

export interface UploadAssetToAddRequest {
    datasetId: string;
    file: Blob;
}

export interface UploadDatasetDataSourceCsvRequest {
    file: Blob;
    dzuuid?: string | null;
    dzchunkindex?: number | null;
    dzchunkbyteoffset?: number | null;
    dztotalchunkcount?: number | null;
    dztotalfilesize?: number | null;
}

export interface UploadMetadataCsvRequest {
    datasetId: string;
    file: Blob;
    offset?: number | null;
    limit?: number | null;
    dzuuid?: string | null;
    dzchunkindex?: number | null;
    dzchunkbyteoffset?: number | null;
    dztotalchunkcount?: number | null;
    dztotalfilesize?: number | null;
}

export interface ValidateDatasetNameOperationRequest {
    validateDatasetNameRequest: ValidateDatasetNameRequest;
}

/**
 * DatasetApi - interface
 * 
 * @export
 * @interface DatasetApiInterface
 */
export interface DatasetApiInterface {
    /**
     * Add assets to an existing dataset
     * @summary Add assets to an existing dataset
     * @param {string} datasetId The unique identifier for the dataset
     * @param {AddAssetsToDatasetRequest} addAssetsToDatasetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    addAssetsToDatasetRaw(requestParameters: AddAssetsToDatasetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetResponse>>;

    /**
     * Add assets to an existing dataset
     * Add assets to an existing dataset
     */
    addAssetsToDataset(requestParameters: AddAssetsToDatasetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetResponse>;

    /**
     * Add metadata to dataset
     * @summary Add metadata to dataset
     * @param {string} datasetId The unique identifier for the dataset
     * @param {AddMetadataRequest} addMetadataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    addMetadataRaw(requestParameters: AddMetadataOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Add metadata to dataset
     * Add metadata to dataset
     */
    addMetadata(requestParameters: AddMetadataOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Create a new dataset. The dataset will not be available to query until the status is \'ready\'.
     * @summary Create a new dataset
     * @param {CreateDatasetRequest} createDatasetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    createDatasetRaw(requestParameters: CreateDatasetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetResponse>>;

    /**
     * Create a new dataset. The dataset will not be available to query until the status is \'ready\'.
     * Create a new dataset
     */
    createDataset(requestParameters: CreateDatasetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetResponse>;

    /**
     * Delete dataset by id
     * @summary Delete dataset by id
     * @param {string} datasetId The unique identifier for the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    deleteDatasetRaw(requestParameters: DeleteDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Delete dataset by id
     * Delete dataset by id
     */
    deleteDataset(requestParameters: DeleteDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Get all available encoders
     * @summary Get all available encoders
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    getAvailableEncodersRaw(requestParameters: GetAvailableEncodersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EncoderListResponse>>;

    /**
     * Get all available encoders
     * Get all available encoders
     */
    getAvailableEncoders(requestParameters: GetAvailableEncodersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EncoderListResponse>;

    /**
     * Get dataset by id
     * @summary Get dataset by id
     * @param {string} datasetId The unique identifier for the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    getDatasetRaw(requestParameters: GetDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullDatasetResponse>>;

    /**
     * Get dataset by id
     * Get dataset by id
     */
    getDataset(requestParameters: GetDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullDatasetResponse>;

    /**
     * Get images by dataset id
     * @summary Get images by dataset id
     * @param {string} datasetId The unique identifier for the dataset
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    getDatasetImagesRaw(requestParameters: GetDatasetImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedAssetsResponse>>;

    /**
     * Get images by dataset id
     * Get images by dataset id
     */
    getDatasetImages(requestParameters: GetDatasetImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedAssetsResponse>;

    /**
     * Get videos by dataset id
     * @summary Get videos by dataset id
     * @param {string} datasetId The unique identifier for the dataset
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    getDatasetVideosRaw(requestParameters: GetDatasetVideosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedAssetsResponse>>;

    /**
     * Get videos by dataset id
     * Get videos by dataset id
     */
    getDatasetVideos(requestParameters: GetDatasetVideosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedAssetsResponse>;

    /**
     * Get all datasets paginated, optionally filtered by status
     * @summary Get all datasets paginated
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {Array<DatasetStatusEnum>} [statusesToInclude] Dataset status to include in the list response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    getDatasetsRaw(requestParameters: GetDatasetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetListResponse>>;

    /**
     * Get all datasets paginated, optionally filtered by status
     * Get all datasets paginated
     */
    getDatasets(requestParameters: GetDatasetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetListResponse>;

    /**
     * Get all datasets paginated in table format
     * @summary Get all datasets in table format
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {Array<DatasetStatusEnum>} [statusesToInclude] Dataset status to include in the list response
     * @param {string} [regexpPattern] Regexp pattern to filter datasets by name (case-insensitive)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    getDatasetsAsTableRaw(requestParameters: GetDatasetsAsTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetTableResponse>>;

    /**
     * Get all datasets paginated in table format
     * Get all datasets in table format
     */
    getDatasetsAsTable(requestParameters: GetDatasetsAsTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetTableResponse>;

    /**
     * Get metadata preview
     * @summary Get metadata preview
     * @param {string} datasetId The unique identifier for the dataset
     * @param {MetadataCsvPreviewRequest} metadataCsvPreviewRequest 
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    getMetadataPreviewRaw(requestParameters: GetMetadataPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MetadataPreviewResponse>>;

    /**
     * Get metadata preview
     * Get metadata preview
     */
    getMetadataPreview(requestParameters: GetMetadataPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MetadataPreviewResponse>;

    /**
     * Create a dynamic tag table that records scores for each tag for each asset
     * @summary Create dynamic tag table for dataset
     * @param {string} datasetId The unique identifier for the dataset
     * @param {CreateDynamicTagTableRequest} createDynamicTagTableRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    legacyCreateDynamicTagTableRaw(requestParameters: LegacyCreateDynamicTagTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Create a dynamic tag table that records scores for each tag for each asset
     * Create dynamic tag table for dataset
     */
    legacyCreateDynamicTagTable(requestParameters: LegacyCreateDynamicTagTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Update dataset by id
     * @summary Update dataset by id
     * @param {string} datasetId The unique identifier for the dataset
     * @param {UpdateDatasetRequest} updateDatasetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    updateDatasetRaw(requestParameters: UpdateDatasetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetResponse>>;

    /**
     * Update dataset by id
     * Update dataset by id
     */
    updateDataset(requestParameters: UpdateDatasetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetResponse>;

    /**
     * Upload assets to add to dataset
     * @summary Upload assets to add to dataset
     * @param {string} datasetId The unique identifier for the dataset
     * @param {Blob} file An asset file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    uploadAssetToAddRaw(requestParameters: UploadAssetToAddRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     * Upload assets to add to dataset
     * Upload assets to add to dataset
     */
    uploadAssetToAdd(requestParameters: UploadAssetToAddRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * Upload a CSV containing full S3 paths or public URLs to be used to create or update a dataset
     * @summary Upload a CSV with data paths
     * @param {Blob} file CSV file containing full S3 or public URLs to be used for creating or updating a dataset
     * @param {string} [dzuuid] Chunked upload id
     * @param {number} [dzchunkindex] Chunked upload index of chunk
     * @param {number} [dzchunkbyteoffset] Chunked upload bytes offset of chunk
     * @param {number} [dztotalchunkcount] Chunked upload total number of chunks
     * @param {number} [dztotalfilesize] Chunked upload total file size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    uploadDatasetDataSourceCsvRaw(requestParameters: UploadDatasetDataSourceCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadResponse>>;

    /**
     * Upload a CSV containing full S3 paths or public URLs to be used to create or update a dataset
     * Upload a CSV with data paths
     */
    uploadDatasetDataSourceCsv(requestParameters: UploadDatasetDataSourceCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadResponse>;

    /**
     * Upload dataset metadata
     * @summary Upload dataset metadata
     * @param {string} datasetId The unique identifier for the dataset
     * @param {Blob} file Additional metadata for the dataset
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {string} [dzuuid] Chunked upload id
     * @param {number} [dzchunkindex] Chunked upload index of chunk
     * @param {number} [dzchunkbyteoffset] Chunked upload bytes offset of chunk
     * @param {number} [dztotalchunkcount] Chunked upload total number of chunks
     * @param {number} [dztotalfilesize] Chunked upload total file size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    uploadMetadataCsvRaw(requestParameters: UploadMetadataCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MetadataUploadResponse>>;

    /**
     * Upload dataset metadata
     * Upload dataset metadata
     */
    uploadMetadataCsv(requestParameters: UploadMetadataCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MetadataUploadResponse>;

    /**
     * Validate the dataset name contains allowable characters and check for uniqueness
     * @summary Validate dataset name
     * @param {ValidateDatasetNameRequest} validateDatasetNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApiInterface
     */
    validateDatasetNameRaw(requestParameters: ValidateDatasetNameOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateDatasetNameResponse>>;

    /**
     * Validate the dataset name contains allowable characters and check for uniqueness
     * Validate dataset name
     */
    validateDatasetName(requestParameters: ValidateDatasetNameOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateDatasetNameResponse>;

}

/**
 * 
 */
export class DatasetApi extends runtime.BaseAPI implements DatasetApiInterface {

    /**
     * Add assets to an existing dataset
     * Add assets to an existing dataset
     */
    async addAssetsToDatasetRaw(requestParameters: AddAssetsToDatasetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetResponse>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling addAssetsToDataset.');
        }

        if (requestParameters.addAssetsToDatasetRequest === null || requestParameters.addAssetsToDatasetRequest === undefined) {
            throw new runtime.RequiredError('addAssetsToDatasetRequest','Required parameter requestParameters.addAssetsToDatasetRequest was null or undefined when calling addAssetsToDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets/{dataset_id}/assets`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddAssetsToDatasetRequestToJSON(requestParameters.addAssetsToDatasetRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatasetResponseFromJSON(jsonValue));
    }

    /**
     * Add assets to an existing dataset
     * Add assets to an existing dataset
     */
    async addAssetsToDataset(requestParameters: AddAssetsToDatasetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetResponse> {
        const response = await this.addAssetsToDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add metadata to dataset
     * Add metadata to dataset
     */
    async addMetadataRaw(requestParameters: AddMetadataOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling addMetadata.');
        }

        if (requestParameters.addMetadataRequest === null || requestParameters.addMetadataRequest === undefined) {
            throw new runtime.RequiredError('addMetadataRequest','Required parameter requestParameters.addMetadataRequest was null or undefined when calling addMetadata.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets/{dataset_id}/metadata`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddMetadataRequestToJSON(requestParameters.addMetadataRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Add metadata to dataset
     * Add metadata to dataset
     */
    async addMetadata(requestParameters: AddMetadataOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.addMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new dataset. The dataset will not be available to query until the status is \'ready\'.
     * Create a new dataset
     */
    async createDatasetRaw(requestParameters: CreateDatasetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetResponse>> {
        if (requestParameters.createDatasetRequest === null || requestParameters.createDatasetRequest === undefined) {
            throw new runtime.RequiredError('createDatasetRequest','Required parameter requestParameters.createDatasetRequest was null or undefined when calling createDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDatasetRequestToJSON(requestParameters.createDatasetRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatasetResponseFromJSON(jsonValue));
    }

    /**
     * Create a new dataset. The dataset will not be available to query until the status is \'ready\'.
     * Create a new dataset
     */
    async createDataset(requestParameters: CreateDatasetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetResponse> {
        const response = await this.createDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete dataset by id
     * Delete dataset by id
     */
    async deleteDatasetRaw(requestParameters: DeleteDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling deleteDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets/{dataset_id}`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete dataset by id
     * Delete dataset by id
     */
    async deleteDataset(requestParameters: DeleteDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all available encoders
     * Get all available encoders
     */
    async getAvailableEncodersRaw(requestParameters: GetAvailableEncodersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EncoderListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/encoders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EncoderListResponseFromJSON(jsonValue));
    }

    /**
     * Get all available encoders
     * Get all available encoders
     */
    async getAvailableEncoders(requestParameters: GetAvailableEncodersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EncoderListResponse> {
        const response = await this.getAvailableEncodersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get dataset by id
     * Get dataset by id
     */
    async getDatasetRaw(requestParameters: GetDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullDatasetResponse>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling getDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets/{dataset_id}`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullDatasetResponseFromJSON(jsonValue));
    }

    /**
     * Get dataset by id
     * Get dataset by id
     */
    async getDataset(requestParameters: GetDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullDatasetResponse> {
        const response = await this.getDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get images by dataset id
     * Get images by dataset id
     */
    async getDatasetImagesRaw(requestParameters: GetDatasetImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedAssetsResponse>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling getDatasetImages.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets/{dataset_id}/images`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedAssetsResponseFromJSON(jsonValue));
    }

    /**
     * Get images by dataset id
     * Get images by dataset id
     */
    async getDatasetImages(requestParameters: GetDatasetImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedAssetsResponse> {
        const response = await this.getDatasetImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get videos by dataset id
     * Get videos by dataset id
     */
    async getDatasetVideosRaw(requestParameters: GetDatasetVideosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedAssetsResponse>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling getDatasetVideos.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets/{dataset_id}/videos`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedAssetsResponseFromJSON(jsonValue));
    }

    /**
     * Get videos by dataset id
     * Get videos by dataset id
     */
    async getDatasetVideos(requestParameters: GetDatasetVideosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedAssetsResponse> {
        const response = await this.getDatasetVideosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all datasets paginated, optionally filtered by status
     * Get all datasets paginated
     */
    async getDatasetsRaw(requestParameters: GetDatasetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.statusesToInclude) {
            queryParameters['statuses_to_include'] = requestParameters.statusesToInclude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatasetListResponseFromJSON(jsonValue));
    }

    /**
     * Get all datasets paginated, optionally filtered by status
     * Get all datasets paginated
     */
    async getDatasets(requestParameters: GetDatasetsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetListResponse> {
        const response = await this.getDatasetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all datasets paginated in table format
     * Get all datasets in table format
     */
    async getDatasetsAsTableRaw(requestParameters: GetDatasetsAsTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetTableResponse>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.statusesToInclude) {
            queryParameters['statuses_to_include'] = requestParameters.statusesToInclude;
        }

        if (requestParameters.regexpPattern !== undefined) {
            queryParameters['regexp_pattern'] = requestParameters.regexpPattern;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets/table`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatasetTableResponseFromJSON(jsonValue));
    }

    /**
     * Get all datasets paginated in table format
     * Get all datasets in table format
     */
    async getDatasetsAsTable(requestParameters: GetDatasetsAsTableRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetTableResponse> {
        const response = await this.getDatasetsAsTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get metadata preview
     * Get metadata preview
     */
    async getMetadataPreviewRaw(requestParameters: GetMetadataPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MetadataPreviewResponse>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling getMetadataPreview.');
        }

        if (requestParameters.metadataCsvPreviewRequest === null || requestParameters.metadataCsvPreviewRequest === undefined) {
            throw new runtime.RequiredError('metadataCsvPreviewRequest','Required parameter requestParameters.metadataCsvPreviewRequest was null or undefined when calling getMetadataPreview.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets/{dataset_id}/csv/preview`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetadataCsvPreviewRequestToJSON(requestParameters.metadataCsvPreviewRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MetadataPreviewResponseFromJSON(jsonValue));
    }

    /**
     * Get metadata preview
     * Get metadata preview
     */
    async getMetadataPreview(requestParameters: GetMetadataPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MetadataPreviewResponse> {
        const response = await this.getMetadataPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a dynamic tag table that records scores for each tag for each asset
     * Create dynamic tag table for dataset
     */
    async legacyCreateDynamicTagTableRaw(requestParameters: LegacyCreateDynamicTagTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling legacyCreateDynamicTagTable.');
        }

        if (requestParameters.createDynamicTagTableRequest === null || requestParameters.createDynamicTagTableRequest === undefined) {
            throw new runtime.RequiredError('createDynamicTagTableRequest','Required parameter requestParameters.createDynamicTagTableRequest was null or undefined when calling legacyCreateDynamicTagTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets/{dataset_id}/create-dynamic-tag-table`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDynamicTagTableRequestToJSON(requestParameters.createDynamicTagTableRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a dynamic tag table that records scores for each tag for each asset
     * Create dynamic tag table for dataset
     */
    async legacyCreateDynamicTagTable(requestParameters: LegacyCreateDynamicTagTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.legacyCreateDynamicTagTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update dataset by id
     * Update dataset by id
     */
    async updateDatasetRaw(requestParameters: UpdateDatasetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetResponse>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling updateDataset.');
        }

        if (requestParameters.updateDatasetRequest === null || requestParameters.updateDatasetRequest === undefined) {
            throw new runtime.RequiredError('updateDatasetRequest','Required parameter requestParameters.updateDatasetRequest was null or undefined when calling updateDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets/{dataset_id}`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDatasetRequestToJSON(requestParameters.updateDatasetRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatasetResponseFromJSON(jsonValue));
    }

    /**
     * Update dataset by id
     * Update dataset by id
     */
    async updateDataset(requestParameters: UpdateDatasetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetResponse> {
        const response = await this.updateDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload assets to add to dataset
     * Upload assets to add to dataset
     */
    async uploadAssetToAddRaw(requestParameters: UploadAssetToAddRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling uploadAssetToAdd.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadAssetToAdd.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/ui/datasets/{dataset_id}/add_assets/upload`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Upload assets to add to dataset
     * Upload assets to add to dataset
     */
    async uploadAssetToAdd(requestParameters: UploadAssetToAddRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uploadAssetToAddRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a CSV containing full S3 paths or public URLs to be used to create or update a dataset
     * Upload a CSV with data paths
     */
    async uploadDatasetDataSourceCsvRaw(requestParameters: UploadDatasetDataSourceCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadResponse>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadDatasetDataSourceCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.dzuuid !== undefined) {
            formParams.append('dzuuid', requestParameters.dzuuid as any);
        }

        if (requestParameters.dzchunkindex !== undefined) {
            formParams.append('dzchunkindex', requestParameters.dzchunkindex as any);
        }

        if (requestParameters.dzchunkbyteoffset !== undefined) {
            formParams.append('dzchunkbyteoffset', requestParameters.dzchunkbyteoffset as any);
        }

        if (requestParameters.dztotalchunkcount !== undefined) {
            formParams.append('dztotalchunkcount', requestParameters.dztotalchunkcount as any);
        }

        if (requestParameters.dztotalfilesize !== undefined) {
            formParams.append('dztotalfilesize', requestParameters.dztotalfilesize as any);
        }

        const response = await this.request({
            path: `/api/ui/datasets/upload/csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadResponseFromJSON(jsonValue));
    }

    /**
     * Upload a CSV containing full S3 paths or public URLs to be used to create or update a dataset
     * Upload a CSV with data paths
     */
    async uploadDatasetDataSourceCsv(requestParameters: UploadDatasetDataSourceCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadResponse> {
        const response = await this.uploadDatasetDataSourceCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload dataset metadata
     * Upload dataset metadata
     */
    async uploadMetadataCsvRaw(requestParameters: UploadMetadataCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MetadataUploadResponse>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling uploadMetadataCsv.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadMetadataCsv.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.dzuuid !== undefined) {
            formParams.append('dzuuid', requestParameters.dzuuid as any);
        }

        if (requestParameters.dzchunkindex !== undefined) {
            formParams.append('dzchunkindex', requestParameters.dzchunkindex as any);
        }

        if (requestParameters.dzchunkbyteoffset !== undefined) {
            formParams.append('dzchunkbyteoffset', requestParameters.dzchunkbyteoffset as any);
        }

        if (requestParameters.dztotalchunkcount !== undefined) {
            formParams.append('dztotalchunkcount', requestParameters.dztotalchunkcount as any);
        }

        if (requestParameters.dztotalfilesize !== undefined) {
            formParams.append('dztotalfilesize', requestParameters.dztotalfilesize as any);
        }

        const response = await this.request({
            path: `/api/ui/datasets/{dataset_id}/upload/csv`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MetadataUploadResponseFromJSON(jsonValue));
    }

    /**
     * Upload dataset metadata
     * Upload dataset metadata
     */
    async uploadMetadataCsv(requestParameters: UploadMetadataCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MetadataUploadResponse> {
        const response = await this.uploadMetadataCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate the dataset name contains allowable characters and check for uniqueness
     * Validate dataset name
     */
    async validateDatasetNameRaw(requestParameters: ValidateDatasetNameOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateDatasetNameResponse>> {
        if (requestParameters.validateDatasetNameRequest === null || requestParameters.validateDatasetNameRequest === undefined) {
            throw new runtime.RequiredError('validateDatasetNameRequest','Required parameter requestParameters.validateDatasetNameRequest was null or undefined when calling validateDatasetName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets/validate-name`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateDatasetNameRequestToJSON(requestParameters.validateDatasetNameRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateDatasetNameResponseFromJSON(jsonValue));
    }

    /**
     * Validate the dataset name contains allowable characters and check for uniqueness
     * Validate dataset name
     */
    async validateDatasetName(requestParameters: ValidateDatasetNameOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateDatasetNameResponse> {
        const response = await this.validateDatasetNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
