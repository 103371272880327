import { SearchMode } from 'components/DatasetSearch/AdvancedSearch/types';
import { DropdownOption } from 'components/Dropdown';

import EditableTextInlineView from 'components/EditableText/EditableTextInlineView';
import ImageGrid from 'components/ImageGrid';
import Main from 'components/Main';
import AssetDetailSidebar from 'components/Sidebar/AssetDetailSidebar';
import { useSidebarAssetContext } from 'context/SidebarAssetContext';
import { useUserContext } from 'context/UserContext';
import { FeatureFlag } from 'feature_flags/FeatureFlagConfig';
import { evaluateFlag } from 'feature_flags/FeatureFlags';
import useSidebarAssetPreview from 'hooks/useSidebarAssetPreview';
import AdvancedSearchResults from 'pages/datasets/detail/components/AdvancedSearchResults';
import DatasetView from 'pages/datasets/detail/components/DatasetView';
import MetadataUpload from 'pages/datasets/detail/components/MetadataUpload/index';
import { useGetDatasetUpdates } from 'queries/dataset-update';
import {
  getDatasetQueryKey,
  useDeleteDatasetMutation,
  useGetDatasetById,
  useUpdateDatasetMutation,
} from 'queries/datasets';
import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  Route,
  Routes,
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { Action } from 'types/action';
import AddAssets from './components/AddAssets';

export enum TabsValues {
  Examples = 'Examples',
}

const VISIBLE_METADATA_FIELDS = [
  'coactive_image_id',
  'format',
  'created_dt',
  'coactive_shot_id',
  'coactive_video_id',
  'keyframe_time_ms',
  'keyframe_index',
  'coactive_audio_segment_id',
];

const DatasetDetail = function DatasetDetail() {
  const { pathname } = useLocation();
  const { datasetId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { auth0User, isTrialUser } = useUserContext();
  const { data: dataset } = useGetDatasetById(datasetId);
  const {
    data: updates,
    isFetched: isDatasetUpdatesFetched,
    isLoading: isDatasetUpdatesLoading,
  } = useGetDatasetUpdates(datasetId, {
    enabled: dataset?.status === 'Incomplete',
  });
  const { setSelectedAsset: setSidebarAsset } = useSidebarAssetContext();

  const isDynamicTaggingEnabledV1 = evaluateFlag(
    FeatureFlag.ENABLE_DYNAMIC_TAGGING,
  );
  const isDynamicTaggingEnabledV2 = evaluateFlag(
    FeatureFlag.ENABLE_DYNAMIC_TAGGING_V2,
  );

  useEffect(() => {
    const invitedColleagues = updates?.map((update) => update.invitedUserEmail);
    const currentUserEmail = auth0User?.email;

    // If the current user was invited to configure this dataset, go immediately to that dataset update
    const helpRequested = updates?.find(
      (update) => update.invitedUserEmail === currentUserEmail,
    );
    if (helpRequested) {
      navigate(`/datasets/${datasetId}/add-assets/${helpRequested!.id}`, {
        replace: true,
      });
      return;
    }

    // If the current dataset is incomplete and a user hasn't been invited to help and Coactive's help wasn't requested,
    // reenter the add assets flow.
    const hasRequestedCoactiveHelp = updates?.some(
      (update) => update.unsupportedDataSource,
    );
    if (
      auth0User &&
      dataset?.status === 'Incomplete' &&
      isDatasetUpdatesFetched &&
      !hasRequestedCoactiveHelp &&
      !invitedColleagues?.length
    ) {
      if (updates?.length) {
        navigate(`/datasets/${datasetId}/add-assets/${updates[0].id}`, {
          replace: true,
        });
      } else {
        navigate(`/datasets/${datasetId}/add-assets`, { replace: true });
      }
    }
  }, [auth0User, updates, dataset?.status, isDatasetUpdatesFetched]);

  useEffect(() => {
    setSidebarAsset(undefined);
  }, []);

  const { mutate: deleteDataset } = useDeleteDatasetMutation();
  const { openSidebarAssetPreview, sidebarPreviewAsset } =
    useSidebarAssetPreview();
  const [searchMode, setSearchMode] = useState<SearchMode>('visual');
  const [textSearchActive, setTextSearchActive] = useState<boolean>(false);
  const breadcrumbs = dataset?.name
    ? [
        {
          label: 'Datasets',
          to: '/datasets',
        },
        {
          label: dataset.name,
          to: `/datasets/${datasetId}`,
        },
      ]
    : undefined;

  const loadingGrid = useMemo(
    () => <ImageGrid images={new Array(50).fill(undefined)} />,
    [],
  );

  const pageOptions: Action[] | undefined = useMemo(() => {
    if (!dataset) {
      return undefined;
    }
    const opts: Action[] = [
      {
        key: 'add-assets',
        label: 'Add assets',
        action: () => {
          navigate('assets');
        },
      },
      {
        key: 'add-metadata',
        label: 'Add metadata',
        action: () => {
          navigate('metadata');
        },
      },
    ];

    if (isDynamicTaggingEnabledV2) {
      opts.push({
        key: 'add-dynamic-tags-v2',
        label: 'Add dynamic tags',
        action: () => {
          navigate({
            pathname: '/dynamic-tag-categories/add',
            search: createSearchParams({
              datasetId: datasetId || '',
            }).toString(),
          });
        },
      });
    } else if (isDynamicTaggingEnabledV1) {
      opts.push({
        key: 'add-dynamic-tags',
        label: 'Add dynamic tags',
        action: () => {
          navigate('add-dynamic-tags');
        },
      });
    }

    if (dataset?.isDeletable) {
      opts.push({
        key: 'delete',
        danger: true,
        label: 'Delete',
        action: async () => ({
          key: 'delete',
          label: 'Delete Dataset',
          danger: true,
          action: () => {
            deleteDataset(
              { datasetId: dataset.datasetId },
              {
                onSuccess: () => {
                  navigate('/datasets');
                },
                onError: () => {
                  toast.error(
                    `Unable to delete dataset ${dataset.name}. Try Again.`,
                  );
                },
              },
            );
          },
        }),
      });
    }
    return opts;
  }, [dataset]);

  const { mutate: updateDataset } = useUpdateDatasetMutation();

  // TODO: attempt code refactor to abstract this logic out without breaking React hook rules
  const updateDatasetName = async (name: string): Promise<void> =>
    new Promise((resolve, reject) => {
      if (!datasetId) {
        reject();
      }
      updateDataset(
        // @ts-ignore: datasetId should be defined at this point in execution
        { datasetId, updateDatasetRequest: { name } },
        {
          onSuccess: async () => {
            queryClient.setQueryData(getDatasetQueryKey(datasetId), {
              ...dataset,
              name,
            });
            await queryClient.invalidateQueries(getDatasetQueryKey(datasetId), {
              refetchActive: true,
              refetchInactive: true,
            });
            toast.success('Dataset name has been updated successfully.');
            resolve();
          },
          onError: () => {
            toast.error('Dataset name could not be updated. Please try again.');
          },
        },
      );
    });

  const searchActive = textSearchActive || pathname.includes('search');

  const searchModeOptions: DropdownOption<SearchMode>[] = [
    {
      label: 'Visual search',
      value: 'visual',
    },
    {
      label: 'Audio search',
      value: 'audio',
      disabled: !dataset?.isAudioSearchable,
    },
  ];

  const enabledSearchOptions = searchModeOptions.filter(
    (option) => !option.disabled,
  );

  if (!isTrialUser) {
    enabledSearchOptions.push({
      label: 'Concept & Metadata Search',
      value: 'advanced-visual',
    });
  }

  const title = (
    <EditableTextInlineView
      id="dataset-name"
      name="dataset-name"
      updateValue={(name: string) => updateDatasetName(name)}
      value={dataset?.name}
      dataTestId="dataset-name-title"
      wrapperClassName="mr-4"
      inputClassName="!text-h1"
      textDisplayClassName="!text-h1 max-w-full truncate"
      negativeTopMargin={false}
    />
  );

  // TODO: change option button menu design
  return (
    <Main
      title={title}
      loading={!dataset?.name || isDatasetUpdatesLoading}
      breadcrumbs={breadcrumbs}
      options={pageOptions}
      rightSidebar={
        sidebarPreviewAsset && (
          <AssetDetailSidebar
            asset={
              (sidebarPreviewAsset.asset as any) ??
              sidebarPreviewAsset.data?.asset
            }
            datasetId={datasetId ?? null}
            defaultMetadataTitle={
              sidebarPreviewAsset.data ? 'Other Metadata' : 'Metadata'
            }
            fromDatasetView
            metadata={sidebarPreviewAsset?.data?.data}
            metadataKeyOrder={['row']}
            metadataTitle="Search data"
            showDefaultMetadata={!sidebarPreviewAsset.data}
            showKeyframesAsVideos={!searchActive}
            setSelectedAsset={openSidebarAssetPreview}
            topScoringConceptsCount={searchActive ? 5 : undefined}
            visibleMetadataFields={VISIBLE_METADATA_FIELDS}
          />
        )
      }
    >
      <div className="max-w-8xl mx-auto">
        <div>
          <Routes>
            <Route
              index
              element={
                <DatasetView
                  datasetId={datasetId}
                  incompleteDatasetUpdates={updates}
                  dataset={dataset}
                  searchActive={searchActive}
                  setSelectedAsset={openSidebarAssetPreview}
                  searchMode={searchMode}
                  setSearchMode={setSearchMode}
                  searchModeOptions={enabledSearchOptions}
                  setTextSearchActive={setTextSearchActive}
                  loadingGrid={loadingGrid}
                />
              }
            />
            <Route
              path="search/:queryId/*"
              element={
                <AdvancedSearchResults
                  datasetId={datasetId}
                  dataset={dataset}
                  searchActive={searchActive}
                  setSelectedAsset={openSidebarAssetPreview}
                  setSearchMode={setSearchMode}
                  searchModeOptions={enabledSearchOptions}
                  setTextSearchActive={setTextSearchActive}
                  loadingGrid={loadingGrid}
                />
              }
            />
            <Route
              path="metadata"
              element={
                datasetId &&
                dataset?.name && (
                  <MetadataUpload
                    datasetName={dataset?.name}
                    datasetId={datasetId}
                  />
                )
              }
            />
            <Route
              path="assets"
              element={
                datasetId &&
                dataset?.name && <AddAssets datasetId={datasetId} />
              }
            />
          </Routes>
        </div>
      </div>
    </Main>
  );
};

export default DatasetDetail;
