/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A request to create a dynamic tag table for a dataset
 * @export
 * @interface CreateDynamicTagTableRequest
 */
export interface CreateDynamicTagTableRequest {
    /**
     * Suffix for the name of the new table
     * @type {string}
     * @memberof CreateDynamicTagTableRequest
     */
    tableNameSuffix: string;
    /**
     * List of dynamic tags
     * @type {Array<string>}
     * @memberof CreateDynamicTagTableRequest
     */
    dynamicTags: Array<string>;
}

export function CreateDynamicTagTableRequestFromJSON(json: any): CreateDynamicTagTableRequest {
    return CreateDynamicTagTableRequestFromJSONTyped(json, false);
}

export function CreateDynamicTagTableRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDynamicTagTableRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tableNameSuffix': json['tableNameSuffix'],
        'dynamicTags': json['dynamicTags'],
    };
}

export function CreateDynamicTagTableRequestToJSON(value?: CreateDynamicTagTableRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tableNameSuffix': value.tableNameSuffix,
        'dynamicTags': value.dynamicTags,
    };
}

