import { Transition } from '@headlessui/react';
import React, { PropsWithChildren } from 'react';

interface FadeTransitionProps {
  show: boolean;
  appear?: boolean;
  afterLeave?: () => void;
  beforeEnter?: () => void;
  enterDelayMs?: number;
  exit?: boolean;
  unmount?: boolean;
  className?: string;
  enterDuration?: string;
  exitDuration?: string;
}

const FadeTransition: React.FunctionComponent<
  PropsWithChildren<FadeTransitionProps>
> = function FadeTransition({
  afterLeave,
  appear,
  beforeEnter,
  show,
  enterDelayMs,
  children,
  exit,
  unmount,
  className,
  enterDuration,
  exitDuration,
}) {
  return (
    <Transition
      appear={appear}
      show={show}
      enter={`transition-opacity ${enterDuration} delay-${enterDelayMs}`}
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave={exit ? `transition-opacity ${exitDuration}` : undefined}
      leaveFrom={exit ? 'opacity-100' : undefined}
      leaveTo={exit ? 'opacity-0' : undefined}
      afterLeave={afterLeave}
      beforeEnter={beforeEnter}
      unmount={unmount}
      className={className}
    >
      {children}
    </Transition>
  );
};

FadeTransition.defaultProps = {
  appear: false,
  enterDelayMs: 0,
  afterLeave: undefined,
  beforeEnter: undefined,
  exit: true,
  unmount: false,
  className: undefined,
  enterDuration: 'duration-300',
  exitDuration: 'duration-300',
};

export default FadeTransition;
