import { ImageApi } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useQuery from 'hooks/useQuery';

export const useGetImageMetadata = (
  coactiveImageId: string | undefined,
  enabled: boolean = true,
) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    ['getImageMetadataById', coactiveImageId],
    async ({ signal }) => {
      if (!coactiveImageId) {
        return Promise.resolve(undefined);
      }
      const api = await initializeAPI<ImageApi>(ImageApi);
      return api.getMetadataForImage({ coactiveImageId }, { signal });
    },
    { cacheTime: 0, enabled },
  );
};
