import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button from 'components/Button';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid';
import Combobox, { SelectedOption } from 'components/Combobox';
import debounce from 'lodash.debounce';
import { useGetConceptsQuery } from 'queries/concepts';
import { useGetLabelsForVersionInfiniteQuery } from 'pages/concepts/queries';
import Image from 'components/Image/index';
import ClipLoader from 'react-spinners/ClipLoader';
import colors from 'tailwindcss/colors';
import { useOutsideClick } from 'hooks/useOutsideClick';

interface ConceptComboboxProps {
  datasetId: string;
  selectedConcepts: SelectedOption[];
  setSelectedConcepts: (selectedConcepts: SelectedOption[]) => void;
  isDisabled?: boolean;
}
type HoveredOption = {
  label: string;
  value: string;
  versionId?: string;
  ref: HTMLLIElement;
};

const ConceptCombobox: React.FC<ConceptComboboxProps> = ({
  isDisabled,
  datasetId,
  selectedConcepts,
  setSelectedConcepts,
}) => {
  const comboboxContainerRef = useRef<HTMLDivElement>(null);
  const comboboxRef = useRef<HTMLDivElement>(null);
  const hoverLeaveTimeoutRef = useRef<any | null>(null);
  const [isComboboxOpen, setIsComboboxOpen] = useState<boolean>(false);
  const [textQuery, setTextQuery] = useState<string>('');
  const [hoveredOption, setHoveredOption] = useState<HoveredOption | null>(
    null,
  );
  const { data: concepts, refetch } = useGetConceptsQuery(
    datasetId,
    1,
    200,
    { enabled: true },
    textQuery,
  );
  const {
    data: labelData,
    isLoading: areConceptPreviewsLoading,
    refetch: refetchLabelData,
  } = useGetLabelsForVersionInfiniteQuery(
    hoveredOption?.versionId || '',
    ['1'],
    'getConceptLabelsFromSearchCombobox',
    3,
    hoveredOption !== null,
  );

  const conceptPreviewImages = useMemo(
    () =>
      labelData?.pages[0].data?.map(
        (concept) => concept.previewImages?.small,
      ) || [],
    [labelData],
  );

  useEffect(() => {
    if (hoveredOption !== null) {
      refetchLabelData();
    }
  }, [hoveredOption]);

  const conceptsComboboxOptions = useMemo(() => {
    // temporary fix while te endpoint supports alphabetical sorting
    const orderedConcepts = concepts?.data?.sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    return (
      orderedConcepts?.map((concept) => {
        const ref = React.createRef<HTMLLIElement>();
        return {
          label: concept?.name,
          value: concept?.conceptId,
          versionId: concept?.latestVersion?.versionId,
          disabled: !concept?.hasNegativeLabel && !concept.hasNegativeLabel,
          ref,
        };
      }) || []
    );
  }, [concepts?.data]);

  const handleDebouncedInputChange = useCallback(
    debounce((value: string) => {
      setTextQuery(value);
    }, 100),
    [],
  );

  const calculateOptionExtensionTopPosition = () => {
    if (hoveredOption && hoveredOption.ref && comboboxContainerRef.current) {
      const containerBoundingRect =
        comboboxContainerRef.current.getBoundingClientRect();
      const boundingRect = hoveredOption.ref.getBoundingClientRect();
      return boundingRect.top - containerBoundingRect.top - 80;
    }
    return 0;
  };

  const handleHoverPreviewBox = () => {
    if (hoverLeaveTimeoutRef.current) {
      clearTimeout(hoverLeaveTimeoutRef.current);
      hoverLeaveTimeoutRef.current = null;
    }
  };

  const handleHoverOption = useCallback(
    (option, idx) => {
      if (hoverLeaveTimeoutRef.current) {
        clearTimeout(hoverLeaveTimeoutRef.current);
        hoverLeaveTimeoutRef.current = null;
      }
      const ref = conceptsComboboxOptions[idx]?.ref;
      if (ref && ref.current) {
        setHoveredOption({
          ...option,
          ref: ref.current,
        });
      }
    },
    [conceptsComboboxOptions],
  );

  const handleHoverOptionLeave = useCallback(() => {
    hoverLeaveTimeoutRef.current = setTimeout(() => {
      setHoveredOption(null);
    }, 500);
  }, []);

  useEffect(
    () => () => {
      if (hoverLeaveTimeoutRef.current) {
        clearTimeout(hoverLeaveTimeoutRef.current);
      }
      // Needed to re retrieve updated label data after labeling and update disabled status
      refetch();
    },
    [],
  );

  const handleRemoveConcept = (option) => {
    const filteredSelectedConcepts = [...selectedConcepts].filter(
      (concept) => concept?.value !== option.value,
    );

    setSelectedConcepts(filteredSelectedConcepts);
  };

  const handleSelectConcepts = (option) => {
    if (option?.disabled) return;
    if (!selectedConcepts.find((concept) => concept?.value === option.value)) {
      setSelectedConcepts([...selectedConcepts, option]);
    } else {
      handleRemoveConcept(option);
    }
  };

  const handleOutsideClick = () => {
    setHoveredOption(null);
    setTextQuery('');
    setIsComboboxOpen(false);
  };

  useOutsideClick(comboboxRef, handleOutsideClick);

  const renderConceptLabelsPreview = () => {
    if (areConceptPreviewsLoading) {
      return (
        <div className="text-white h-40 w-[25rem] flex flex-col items-center justify-center">
          <ClipLoader
            cssOverride={{ textAlign: 'center' }}
            color={colors.white}
            loading
            size={40}
            speedMultiplier={0.75}
          />
        </div>
      );
    }
    if (conceptPreviewImages.length === 3) {
      return conceptPreviewImages.map((image) => (
        <Image
          src={image?.url}
          key={image?.url}
          alt={image?.url}
          className="h-40 w-44 rounded-md"
        />
      ));
    }

    return (
      <div className="text-white h-24 w-[25rem] flex flex-col items-center justify-center">
        <p>This concept setup is incomplete</p>
        <Button
          className="mt-1"
          type="button"
          buttonStyle="text"
          onClick={() =>
            window.open(`/concepts/${hoveredOption?.value}/versions/latest`)
          }
          textColor="text-emerald-300"
          size="small"
        >
          Add labels
        </Button>
      </div>
    );
  };

  return (
    <div className="flex items-center gap-2 flex-wrap" ref={comboboxRef}>
      <div className="relative">
        <Button
          buttonStyle="secondary"
          disabled={isDisabled}
          onClick={() => setIsComboboxOpen((prevState) => !prevState)}
          rightIcon={MagnifyingGlassIcon}
          size="small"
          type="button"
        >
          Concept
        </Button>
        <div
          ref={comboboxContainerRef}
          className="absolute top-6 left-0 w-48 z-20"
        >
          {isComboboxOpen && (
            <Combobox
              actionButton={
                <Button
                  onClick={() =>
                    window.open(
                      `/concepts/create?datasetId=${datasetId}&name=${textQuery}`,
                    )
                  }
                  type="button"
                  buttonStyle="link"
                  size="small"
                >
                  Create a concept
                </Button>
              }
              hasInput
              inputDisplayValue={textQuery}
              onInputChange={handleDebouncedInputChange}
              onSelect={handleSelectConcepts}
              onHoverOptionEnter={handleHoverOption}
              onHoverOptionLeave={handleHoverOptionLeave}
              options={conceptsComboboxOptions || []}
              optionExtension={
                hoveredOption !== null ? (
                  <div
                    onMouseEnter={() => handleHoverPreviewBox()}
                    onMouseLeave={handleHoverOptionLeave}
                    style={{
                      top: `${calculateOptionExtensionTopPosition()}px`,
                    }}
                    className="px-2 py-2.5 absolute left-[14.5rem] bg-slate-700 rounded-lg flex flex-row items-center justify-center gap-2"
                  >
                    {renderConceptLabelsPreview()}
                  </div>
                ) : undefined
              }
              selectedOptions={selectedConcepts}
            />
          )}
        </div>
      </div>
      {selectedConcepts.length > 0 &&
        selectedConcepts.map((selectedConcept) => (
          <Button
            key={selectedConcept.value}
            onClick={() => handleRemoveConcept(selectedConcept)}
            type="button"
            buttonStyle="soft"
            rightIcon={XMarkIcon}
            size="small"
          >
            {selectedConcept.label}
          </Button>
        ))}
    </div>
  );
};

ConceptCombobox.defaultProps = {
  isDisabled: false,
};

export default ConceptCombobox;
