import { AssetResponse, PagedAssetsResponse } from 'api/generated';
import ImageGrid from 'components/ImageGrid';
import React, { useMemo, useState } from 'react';
import { InfiniteData } from 'react-query';

interface ToggleImageGridProps {
  images: Array<AssetResponse> | InfiniteData<PagedAssetsResponse> | undefined;
  onAssetClick: (image: AssetResponse) => void;
  disabledTiles?: AssetResponse[];
  disabledMessage?: string;
  dataTestId?: string;
}

const ToggleImageGrid: React.FunctionComponent<ToggleImageGridProps> = ({
  images: imageInput,
  onAssetClick,
  disabledMessage,
  disabledTiles,
  dataTestId,
}) => {
  const [selectedImages, setSelectedImages] = useState<AssetResponse[]>([]);

  const imagePages = Array.isArray(imageInput)
    ? {
        pages: [
          {
            data: imageInput as Array<AssetResponse>,
            meta: { page: { total: imageInput?.length ?? 0 } },
          } as PagedAssetsResponse,
        ],
      }
    : imageInput;

  const images = useMemo(
    () =>
      imagePages?.pages.reduce(
        (acc: AssetResponse[], item) => [...acc, ...item.data],
        [],
      ) || [],
    [imagePages],
  );

  const toggleImage = (asset: AssetResponse) => {
    if (selectedImages.includes(asset)) {
      setSelectedImages(
        selectedImages.filter(
          (selectedAsset) =>
            selectedAsset.coactiveImageId !== asset.coactiveImageId,
        ),
      );
    } else {
      setSelectedImages([...selectedImages, asset]);
    }
    onAssetClick(asset);
  };

  return (
    <ImageGrid
      selectStyle="default"
      images={images}
      type="uniform"
      onClick={toggleImage}
      selected={selectedImages}
      loadMoreButton={false}
      disabledTiles={disabledTiles}
      disabledMessage={disabledMessage}
      dataTestId={dataTestId}
    />
  );
};

ToggleImageGrid.defaultProps = {
  disabledMessage: undefined,
  disabledTiles: undefined,
  dataTestId: undefined,
};

export default ToggleImageGrid;
