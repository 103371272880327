import React from 'react';
import { useFetchClassificationScores } from 'queries/classification';
import { ClassificationScore } from 'api/generated';
import ConceptScoreRow from './ConceptScoreRow';

interface ConceptClassificationScoresProps {
  imageId: string;
  currentConceptId?: string;
}

const ConceptClassificationScores: React.FC<
  ConceptClassificationScoresProps
> = ({ currentConceptId, imageId }) => {
  const { data } = useFetchClassificationScores(imageId);

  return (
    <div>
      <div className="flex justify-between text-gray-500 mt-4 text-sm">
        <h3>Concepts</h3>
        <p>Score</p>
      </div>
      <ul className="grow mt-4">
        {data &&
          data.scores?.map((concept: ClassificationScore) => (
            <ConceptScoreRow
              currentConceptId={currentConceptId}
              concept={concept}
              key={concept.conceptId}
            />
          ))}
      </ul>
    </div>
  );
};

ConceptClassificationScores.defaultProps = {
  currentConceptId: undefined,
};

export default ConceptClassificationScores;
