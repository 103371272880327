import { AssetResponse, QueryTableRowUI } from 'api/generated';
import SidebarContext from 'context/SidebarContext';
import { useContext, useEffect, useMemo, useState } from 'react';

export interface SidebarAsset {
  asset?: AssetResponse;
  data?: QueryTableRowUI;
  embeddingId?: string;
}

interface SidebarAssetPreviewHook {
  openSidebarAssetPreview: (selected: SidebarAsset) => void;
  sidebarPreviewAsset: SidebarAsset | undefined;
  closeSidebar: () => void;
}

export const useSidebarAssetPreview = (): SidebarAssetPreviewHook => {
  const { rightSidebarOpen, setRightSidebarOpen } = useContext(SidebarContext);
  const [selectedAsset, setSelectedAsset] = useState<SidebarAsset>();

  useEffect(() => {
    let timeout;
    if (!rightSidebarOpen) {
      // Wait until close animation finishes to clear the image
      timeout = setTimeout(() => setSelectedAsset(undefined), 500);
    }
    return () => clearTimeout(timeout);
  }, [rightSidebarOpen]);

  useEffect(() => {
    setRightSidebarOpen(Boolean(selectedAsset));
  }, [selectedAsset]);

  const value = useMemo(
    () => ({
      openSidebarAssetPreview: setSelectedAsset,
      sidebarPreviewAsset: selectedAsset,
      closeSidebar: () => setSelectedAsset(undefined),
    }),
    [selectedAsset],
  );
  return value;
};

export default useSidebarAssetPreview;
