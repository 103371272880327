/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Model for text prompts
 * @export
 * @interface TextPrompt
 */
export interface TextPrompt {
    /**
     * The unique identifier of the dynamic tag this text prompt belongs to
     * @type {string}
     * @memberof TextPrompt
     */
    dynamicTagId: string;
    /**
     * The text prompt
     * @type {string}
     * @memberof TextPrompt
     */
    text: string;
}

export function TextPromptFromJSON(json: any): TextPrompt {
    return TextPromptFromJSONTyped(json, false);
}

export function TextPromptFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextPrompt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dynamicTagId': json['dynamicTagId'],
        'text': json['text'],
    };
}

export function TextPromptToJSON(value?: TextPrompt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dynamicTagId': value.dynamicTagId,
        'text': value.text,
    };
}

