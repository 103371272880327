import { ConceptApi } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';

export const useGetConcepts = (
  datasetsToInclude: string[] | undefined,
  offset?: number,
  limit?: number,
  options?: { enabled: boolean } | undefined,
) => {
  const { initializeAPI } = useAPIContext();
  const query = useQuery(
    ['getConceptsAsTable', datasetsToInclude, offset, limit],
    async ({ signal }) => {
      const api = await initializeAPI<ConceptApi>(ConceptApi);
      return api.getConceptsAsTable(
        { datasetsToInclude, offset, limit },
        { signal },
      );
    },
    { cacheTime: 0, ...options },
  );
  return query;
};

export const useDeleteAllConceptsMutation = () => {
  const { initializeAPI } = useAPIContext();

  return useMutation(['deleteAllConceptsApiConceptsDelete'], async () => {
    const api = await initializeAPI<ConceptApi>(ConceptApi);
    return api.deleteAllConcepts();
  });
};
