/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface QueryConceptDataUI
 */
export interface QueryConceptDataUI {
    /**
     * The name of the concept
     * @type {string}
     * @memberof QueryConceptDataUI
     */
    name: string;
    /**
     * The id of the concept
     * @type {string}
     * @memberof QueryConceptDataUI
     */
    id: string;
    /**
     * The query results column names related to the concept
     * @type {Array<string>}
     * @memberof QueryConceptDataUI
     */
    columnNames: Array<string>;
    /**
     * Whether the row represents a positive classification of the concept
     * @type {boolean}
     * @memberof QueryConceptDataUI
     */
    decision: boolean;
    /**
     * A probability score for the classification of the concept
     * @type {number}
     * @memberof QueryConceptDataUI
     */
    probabilityScore: number;
    /**
     * The threshold for classification of the concept
     * @type {number}
     * @memberof QueryConceptDataUI
     */
    threshold: number | null;
}

export function QueryConceptDataUIFromJSON(json: any): QueryConceptDataUI {
    return QueryConceptDataUIFromJSONTyped(json, false);
}

export function QueryConceptDataUIFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryConceptDataUI {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'id': json['id'],
        'columnNames': json['columnNames'],
        'decision': json['decision'],
        'probabilityScore': json['probabilityScore'],
        'threshold': json['threshold'],
    };
}

export function QueryConceptDataUIToJSON(value?: QueryConceptDataUI | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
        'columnNames': value.columnNames,
        'decision': value.decision,
        'probabilityScore': value.probabilityScore,
        'threshold': value.threshold,
    };
}

