/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ColumnStyle = {
    Default: 'default',
    Primary: 'primary'
} as const;
export type ColumnStyle = typeof ColumnStyle[keyof typeof ColumnStyle];


export function ColumnStyleFromJSON(json: any): ColumnStyle {
    return ColumnStyleFromJSONTyped(json, false);
}

export function ColumnStyleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnStyle {
    return json as ColumnStyle;
}

export function ColumnStyleToJSON(value?: ColumnStyle | null): any {
    return value as any;
}

