/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClassificationRequest,
  ClassificationResponse,
  HTTPValidationError,
} from '../models';
import {
    ClassificationRequestFromJSON,
    ClassificationRequestToJSON,
    ClassificationResponseFromJSON,
    ClassificationResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface ClassifyImageRequest {
    classificationRequest: ClassificationRequest;
    threshold?: number | null;
}

/**
 * ClassificationApi - interface
 * 
 * @export
 * @interface ClassificationApiInterface
 */
export interface ClassificationApiInterface {
    /**
     * Upload assets to classify based on embedding id using a public path to the image
     * @summary Classify image
     * @param {ClassificationRequest} classificationRequest 
     * @param {number} [threshold] Threshold above which classification is positive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificationApiInterface
     */
    classifyImageRaw(requestParameters: ClassifyImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClassificationResponse>>;

    /**
     * Upload assets to classify based on embedding id using a public path to the image
     * Classify image
     */
    classifyImage(requestParameters: ClassifyImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClassificationResponse>;

}

/**
 * 
 */
export class ClassificationApi extends runtime.BaseAPI implements ClassificationApiInterface {

    /**
     * Upload assets to classify based on embedding id using a public path to the image
     * Classify image
     */
    async classifyImageRaw(requestParameters: ClassifyImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClassificationResponse>> {
        if (requestParameters.classificationRequest === null || requestParameters.classificationRequest === undefined) {
            throw new runtime.RequiredError('classificationRequest','Required parameter requestParameters.classificationRequest was null or undefined when calling classifyImage.');
        }

        const queryParameters: any = {};

        if (requestParameters.threshold !== undefined) {
            queryParameters['threshold'] = requestParameters.threshold;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/classify/image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassificationRequestToJSON(requestParameters.classificationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassificationResponseFromJSON(jsonValue));
    }

    /**
     * Upload assets to classify based on embedding id using a public path to the image
     * Classify image
     */
    async classifyImage(requestParameters: ClassifyImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClassificationResponse> {
        const response = await this.classifyImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
