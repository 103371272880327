import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

interface TooltipProps {
  id: string;
  afterHide?: () => void;
  delayHide?: number;
  openOnClick?: boolean;
  isOpen?: boolean;
}

const Tooltip: FunctionComponent<PropsWithChildren<TooltipProps>> = ({
  id,
  afterHide,
  delayHide,
  openOnClick,
  isOpen,
  children,
}) => (
  <ReactTooltip
    id={id}
    variant="dark"
    className="!bg-tooltip !opacity-100 !rounded-md text-center z-40"
    place="top"
    openOnClick={openOnClick}
    afterHide={afterHide}
    delayHide={delayHide}
    isOpen={isOpen}
  >
    {children}
  </ReactTooltip>
);

Tooltip.defaultProps = {
  afterHide: undefined,
  delayHide: undefined,
  openOnClick: undefined,
  isOpen: undefined,
};

export default Tooltip;
