import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import React, { PropsWithChildren } from 'react';
import {
  Disclosure as HeadlessDisclosure,
  Transition,
} from '@headlessui/react';

import IconButton from './IconButton';

interface DisclosureProps {
  title: string;
}

const Disclosure: React.FC<PropsWithChildren<DisclosureProps>> = ({
  children,
  title,
}) => (
  <HeadlessDisclosure
    as="div"
    className="bg-white rounded-lg border border-gray-200 px-6 py-4 shadow-sm"
  >
    {({ open }) => (
      <>
        <HeadlessDisclosure.Button className="group flex w-full items-center justify-between">
          <p className="text-sm">{title}</p>
          <IconButton
            rounded="full"
            Icon={open ? ChevronUpIcon : ChevronDownIcon}
            onClick={() => null}
          />
        </HeadlessDisclosure.Button>
        <div className="w-full">
          <Transition
            show={open}
            enter="transition-all duration-300 ease-in-out"
            enterFrom="max-h-0 opacity-0"
            enterTo="max-h-screen opacity-100"
            leave="transition-all duration-300 ease-in-out"
            leaveFrom="max-h-screen opacity-100"
            leaveTo="max-h-0 opacity-0"
          >
            <HeadlessDisclosure.Panel className="py-2 transition-all duration-300 ease-in-out">
              {children}
            </HeadlessDisclosure.Panel>
          </Transition>
        </div>
      </>
    )}
  </HeadlessDisclosure>
);

export default Disclosure;
