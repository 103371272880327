import classNames from 'classnames';
import FadeTransition from 'components/FadeTransition';
import React, { useEffect, useRef } from 'react';

interface TabsProps<T extends string> {
  tabs: {
    name: T;
    icon?: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>;
    color?: 'blue' | 'red';
    dataTestId?: string;
  }[];
  active: T;
  onClick: (name: T) => void;
  transition?: boolean;
  space?: string; // Tailwind "space-x-..."" class
  tabPadding?: string; // Tailwind "p-..." class
  padding?: string; // Tailwind "p-..." class
  disabled?: boolean;
  fontSize?: string;
}

const Tabs = function Tabs<T extends string>({
  active,
  tabs,
  onClick,
  transition,
  space,
  tabPadding,
  padding,
  disabled,
  fontSize,
}: TabsProps<T>) {
  const isFirstMount = useRef(true);

  useEffect(() => {
    isFirstMount.current = false;
  });

  return (
    <div>
      <div className="hidden sm:block">
        <div
          className={classNames(
            'border-b border-gray-200',
            padding,
            disabled && 'opacity-50',
          )}
        >
          <nav className={classNames('-mb-px flex', space)} aria-label="Tabs">
            {tabs.map((tab) => (
              <FadeTransition
                appear={!isFirstMount.current && transition}
                show
                key={tab.name}
              >
                <button
                  type="button"
                  onClick={() => onClick(tab.name)}
                  className={classNames(
                    active === tab.name
                      ? [
                          tab.color !== 'red'
                            ? 'border-gray-800 text-gray-800'
                            : undefined,
                          tab.color === 'red'
                            ? 'border-red-500 text-red-600'
                            : undefined,
                        ].filter((s) => Boolean(s))
                      : 'border-transparent text-gray-500',
                    'group inline-flex items-center border-b-2 font-medium justify-center',
                    tabPadding,
                    fontSize || 'text-sm',
                    disabled && 'cursor-default',
                    !disabled && 'hover:text-gray-700',
                  )}
                  aria-current={active === tab.name ? 'page' : undefined}
                  disabled={disabled}
                  data-cy={tab.dataTestId}
                >
                  {tab.icon ? (
                    <tab.icon
                      className={classNames(
                        active === tab.name
                          ? [
                              tab.color !== 'red' ? 'text-gray-800' : undefined,
                              tab.color === 'red' ? 'text-red-500 ' : undefined,
                            ].filter((s) => Boolean(s))
                          : 'text-gray-400 group-hover:text-gray-500',
                        '-ml-0.5 mr-2 h-5 w-5',
                      )}
                      aria-hidden="true"
                    />
                  ) : undefined}
                  <span>{tab.name}</span>
                </button>
              </FadeTransition>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  transition: false,
  space: 'space-x-8',
  tabPadding: 'py-4 px-1',
  padding: 'p-0',
  disabled: false,
  fontSize: undefined,
};

export default Tabs;
