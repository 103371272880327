import {
  QueryResponseUI,
  QueryResultResponseUI,
  QueryStatus,
  QueryTableRowUI,
} from 'api/generated';
import { useGetDatasetAdvancedSearchHistoryInfiniteQueryKey } from 'components/DatasetSearch/AdvancedSearch/queries';
import FadeTransition from 'components/FadeTransition';
import { Duration } from 'luxon';
import QueryPending from 'pages/queries/ExecutedQueryView/QueryPending';
import QueryResults from 'pages/queries/ExecutedQueryView/QueryResults';
import QueryStopped from 'pages/queries/ExecutedQueryView/QueryStopped';
import { Tab } from 'pages/queries/ExecutedQueryView/types';
import { useGetQueryWithoutResultsQueryKey } from 'pages/queries/queries';
import React, { useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';

type SidebarData = QueryTableRowUI & { datasetId: string; embeddingId: string };

interface QueryResultProps {
  queryId: string;
  query: QueryResultResponseUI | undefined;
  isLoading: boolean;
  refetch: () => void;
  setSidebarData: (data: SidebarData) => void;
  runQuery: (query: string, embeddingId: string) => void;
  embeddingId: string | null | undefined;
  basePath: string;
  updateIntervalMs: number;
  activeTab?: Tab;
  setHasImages?: (hasImages: boolean) => void;
  queuedMessage?: string;
  stoppingMessage?: string;
  runningMessage?: (timeElapsed: Duration | undefined) => string;
  cancelSearchMessage?: string;
  stopSearchMessage?: string;
  showConceptDetail?: boolean;
}

const QueryResult: React.FC<QueryResultProps> = function QueryResult({
  queryId,
  query,
  isLoading,
  refetch,
  setSidebarData,
  runQuery,
  embeddingId,
  activeTab,
  basePath,
  updateIntervalMs,
  setHasImages,
  queuedMessage,
  stoppingMessage,
  runningMessage,
  cancelSearchMessage,
  stopSearchMessage,
  showConceptDetail,
}: QueryResultProps) {
  const queryClient = useQueryClient();

  useEffect(() => {
    let interval;
    if (query?.isActive) {
      interval = setInterval(() => refetch(), updateIntervalMs);
    }
    return () => clearInterval(interval);
  }, [query?.isActive]);

  useEffect(
    () => () => {
      const queryKey = useGetQueryWithoutResultsQueryKey(queryId);
      // Cancel pending requests.
      queryClient.cancelQueries(queryKey);

      // Remove query data from the cache if it's still active so it's immediately
      // refetched.
      const queryData = queryClient.getQueryData<QueryResponseUI>(queryKey);
      if (queryId && queryData?.isActive) {
        queryClient.removeQueries(queryKey);
      }
    },
    [queryId],
  );

  useEffect(() => {
    // Remove the queries from the cache to the advanced search history list updates
    if (query?.dataset.id) {
      const queryKey = useGetDatasetAdvancedSearchHistoryInfiniteQueryKey(
        query?.dataset.id,
      );
      queryClient.invalidateQueries(queryKey, {
        refetchActive: true,
        refetchInactive: true,
      });
    }
  }, [query?.status]);

  const showPendingView = Boolean(query?.isActive);
  const showStoppedView = Boolean(query?.status === QueryStatus.Stopped);

  const setSidebarDataFn = useCallback(
    (data: QueryTableRowUI) => {
      setSidebarData({
        ...data,
        datasetId: query?.dataset.id!,
        embeddingId: embeddingId!,
      });
    },
    [setSidebarData, query?.dataset.id, embeddingId],
  );

  return (
    <>
      {/* Query is pending */}
      <FadeTransition appear show={showPendingView} exit={false} unmount>
        {query && showPendingView && (
          <QueryPending
            query={query}
            queuedMessage={queuedMessage}
            stoppingMessage={stoppingMessage}
            runningMessage={runningMessage}
            cancelSearchMessage={cancelSearchMessage}
            stopSearchMessage={stopSearchMessage}
          />
        )}
      </FadeTransition>
      {/* Query stopped */}
      <FadeTransition appear show={showStoppedView} exit={false} unmount>
        {showStoppedView && (
          <QueryStopped
            query={query?.query}
            embeddingId={query?.embedding.id || ''}
            runQuery={runQuery}
          />
        )}
      </FadeTransition>
      {/* Query is done executing */}
      {!isLoading &&
        !query?.isActive &&
        query?.status !== QueryStatus.Stopped && (
          <QueryResults
            setSidebarData={setSidebarDataFn}
            query={query}
            setHasImages={setHasImages}
            activeTab={activeTab}
            basePath={basePath}
            showConceptDetail={showConceptDetail}
          />
        )}
    </>
  );
};

QueryResult.defaultProps = {
  activeTab: undefined,
  setHasImages: undefined,
  queuedMessage: 'Your query is queued.',
  stoppingMessage: 'Your query is stopping.',
  runningMessage: (timeElapsed: Duration | undefined) =>
    `Your query is ${
      timeElapsed && timeElapsed.seconds > 60 ? 'still ' : ''
    }executing.`,
  cancelSearchMessage: 'Cancel Query Execution',
  stopSearchMessage: 'Stop Query Execution',
  showConceptDetail: false,
};

export default QueryResult;
