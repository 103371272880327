import classNames from 'classnames';
import Button from 'components/Button';
import React, { MouseEventHandler, PropsWithChildren } from 'react';

interface ButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  selected: boolean;
  icon?: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>;
  className?: string;
  dataTestId?: string;
}

const FilterPill = React.forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(({ onClick, selected, children, icon, className, dataTestId }, ref) => (
  <Button
    ref={ref}
    type="button"
    onClick={onClick}
    icon={icon}
    color={selected ? 'bg-emerald-500' : 'bg-gray-200'}
    hoverColor={selected ? 'hover:bg-emerald-600' : 'transparent'}
    border="border border-transparent rounded-lg"
    textColor={selected ? 'text-white' : 'text-gray-500'}
    className={classNames(className)}
    size="small"
  >
    <span data-cy={dataTestId}>{children}</span>
  </Button>
));

FilterPill.defaultProps = {
  icon: undefined,
  className: undefined,
  dataTestId: undefined,
};

export default FilterPill;
