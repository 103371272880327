import { ResponseError } from 'api/generated';
import {
  QueryFunction,
  QueryKey,
  useQuery as useRQQuery,
  UseQueryOptions,
} from 'react-query';

function useQuery<
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, ResponseError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useRQQuery<TQueryFnData, ResponseError, TData, TQueryKey>(
    queryKey,
    queryFn,
    options,
  );
}

export default useQuery;
