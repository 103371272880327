import Page from 'pages/datasets/components/Page';
import SupportColumn from 'pages/datasets/components/SupportColumn';
import SetupView from 'pages/datasets/create/views/SetupView';
import React, { useMemo } from 'react';

/**
 * The top-level component for the create dataset flow.
 */
const CreateDataset = function CreateDataset() {
  const rightColumn = useMemo(() => <SupportColumn />, []);

  return (
    <Page title="Create dataset" rightColumn={rightColumn}>
      <SetupView />
    </Page>
  );
};

export default CreateDataset;
