import React, { useState, KeyboardEvent, ChangeEvent, useRef } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useOutsideClick } from 'hooks/useOutsideClick';
import classNames from 'classnames';
import IconButton from './IconButton';
import Button from './Button';
import TextInput from './TextInput';

interface EditableBadgeProps {
  label: string;
  onClick: () => void;
  onSubmitBadgeEdit?: (label: string) => void;
  classNames?: string;
  inputValidation?: (label: string) => boolean;
}

const EditableBadge: React.FC<EditableBadgeProps> = ({
  classNames: classNamesProp,
  inputValidation,
  label,
  onClick,
  onSubmitBadgeEdit,
}: EditableBadgeProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(label);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEditLabel = (e: KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter' || e.key === ',') && onSubmitBadgeEdit) {
      onSubmitBadgeEdit(inputValue);

      if (!inputValidation || inputValidation(label)) {
        setIsEditing(false);
        setInputValue(label);
      } else {
        // Prevents unwanted focus on outside inputs
        e.stopPropagation();
      }
    }

    if (e.key === ' ') {
      // Prevents unwanted focus on outside inputs
      e.stopPropagation();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleOutsideClick = () => {
    setInputValue(label);
    setIsEditing(false);
  };

  useOutsideClick(inputRef, handleOutsideClick);

  return (
    <div
      tabIndex={0}
      role="button"
      className={classNames(
        'focus:bg-emerald-200 focus:outline-none pl-3 pr-2 py-1.5 font-medium shadow-sm border border-transparent rounded-md bg-emerald-100 hover:bg-emerald-200 text-gray-600 flex flex-row items-center',
        classNamesProp,
      )}
    >
      {!isEditing ? (
        <Button
          textColor="text-gray-600"
          hoverColor="hover:no-underline hover:bg-emerald-300"
          padding="px-1 py-0 rounded-sm"
          buttonStyle="text"
          onClick={() => setIsEditing(true)}
        >
          {label}
        </Button>
      ) : (
        <TextInput
          ref={inputRef}
          onChange={(e) => handleChangeInput(e)}
          containerClassName="!mt-0 w-24"
          inputClassName="px-1 py-0"
          id={`edit-label-${label}`}
          name="edit-text-prompt-label"
          value={inputValue}
          onKeyDown={handleEditLabel}
        />
      )}
      <IconButton
        className="!h-5 !w-5 p-0.5"
        hoverColor="hover:bg-emerald-300 border-transparent"
        rounded="full"
        Icon={XMarkIcon}
        onClick={onClick}
      />
    </div>
  );
};

EditableBadge.defaultProps = {
  classNames: undefined,
  onSubmitBadgeEdit: undefined,
  inputValidation: undefined,
};
export default EditableBadge;
