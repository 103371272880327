import { ConceptApi } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useQuery from 'hooks/useQuery';
import toNumber from 'utils/ToNumber';

export const getConceptsQueryKey = (
  datasetId?: string | null,
  page?: number | null,
  textQuery?: string | null,
) =>
  datasetId ? ['getConcepts', datasetId, page, textQuery] : ['getConcepts'];

export const useGetConceptsQuery = (
  datasetId: string | null,
  page?: number,
  pageSize?: number,
  options?: { enabled: boolean },
  textQuery?: string | null,
) => {
  const { initializeAPI } = useAPIContext();
  const query = useQuery(
    getConceptsQueryKey(datasetId, page, textQuery),
    async ({ queryKey, signal }) => {
      const [, _datasetId, _page] = queryKey;
      const api = await initializeAPI<ConceptApi>(ConceptApi);
      return api.getConcepts(
        {
          datasetIds: [_datasetId as string]!,
          offset: pageSize
            ? pageSize * toNumber(((_page as number) ?? 1) - 1)
            : 0,
          limit: pageSize,
          regexpPattern: textQuery,
        },
        { signal },
      );
    },
    options,
  );
  return {
    ...query,
    isLoading: query.isLoading,
  };
};
