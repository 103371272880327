import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { AssetResponse } from 'api/generated';
import classNames from 'classnames';
import Button from 'components/Button';
import Main from 'components/Main';
import MenuButton from 'components/MenuButton';
import { NavigateToDatasetButton } from 'components/NavigateToDatasetButton';
import PageHeader from 'components/PageHeader';
import AssetDetailSidebar from 'components/Sidebar/AssetDetailSidebar';
import { useSidebarAssetContext } from 'context/SidebarAssetContext';
import SidebarContext from 'context/SidebarContext';
import { FeatureFlag } from 'feature_flags/FeatureFlagConfig';
import { evaluateFlag } from 'feature_flags/FeatureFlags';
import useSidebarAssetPreview from 'hooks/useSidebarAssetPreview';
import {
  getDynamicTagQueryKey,
  useDeleteDynamicTagMutation,
  useGetDynamicTagById,
  useUpdateDynamicTagMutation,
} from 'queries/dynamic-tags';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import colors from 'tailwindcss/colors';
import { EmptyErrorState } from 'components/ErrorEmptyState';
import EmptyState from 'components/EmptyState';
import DeleteDynamicTagModal from '../components/DeleteDynamicTagModal';
import DynamicTagsTopContent from '../components/DynamicTagsTopContent';
import TextPrompts from '../components/TextPrompts';
import VisualPrompts from '../components/VisualPrompts';

const DynamicTagDetail = function DynamicTagDetail() {
  const isDeleteDynamicTagsEnabled = evaluateFlag(
    FeatureFlag.DELETE_DYNAMIC_TAGS_WIP,
  );
  const navigate = useNavigate();
  const { categoryId, dynamicTagId } = useParams();
  const {
    data: dynamicTag,
    isLoading,
    isFetched,
    isError,
  } = useGetDynamicTagById(categoryId, dynamicTagId);
  const [visualPrompts, setVisualPrompts] = useState<any>(
    dynamicTag?.visualPrompts || [],
  );

  const parsedTextPrompts =
    dynamicTag && dynamicTag?.textPrompts
      ? dynamicTag?.textPrompts.map((prompt) => prompt.text)
      : [];
  const [textPrompts, setTextPrompts] = useState<string[]>(parsedTextPrompts);
  const [finalizedTextPrompts, setFinalizedTextPrompts] =
    useState<string[]>(textPrompts);
  const [finalizedVisualPrompts, setFinalizedVisualPrompts] =
    useState<any[]>(visualPrompts);
  const [isEditingPrompts, setIsEditingPrompts] = useState<boolean>(false);
  const { openSidebarAssetPreview, sidebarPreviewAsset } =
    useSidebarAssetPreview();
  const { setSelectedAsset: setSidebarAsset } = useSidebarAssetContext();
  const { rightSidebarOpen } = useContext(SidebarContext);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { mutate: updateDynamicTag } = useUpdateDynamicTagMutation();
  const queryClient = useQueryClient();
  const { mutate: deleteDynamicTag } = useDeleteDynamicTagMutation();

  const handleCancelEdit = () => {
    setTextPrompts(finalizedTextPrompts);
    setVisualPrompts(finalizedVisualPrompts);
    setIsEditingPrompts(false);
  };

  useEffect(() => {
    const parsedPrompts =
      dynamicTag && dynamicTag?.textPrompts
        ? dynamicTag?.textPrompts.map((prompt) => prompt.text)
        : [];
    setTextPrompts(parsedPrompts);
    setFinalizedTextPrompts(parsedPrompts);
    setFinalizedVisualPrompts(dynamicTag?.visualPrompts || []);
    setVisualPrompts(dynamicTag?.visualPrompts || []);
  }, [isLoading]);

  const breadcrumbs = [
    {
      label: 'Dynamic tag categories',
      to: '/dynamic-tag-categories',
    },
    {
      label: `${dynamicTag?.categoryName || 'Category'}: Dynamic tags`,
      to: `/dynamic-tag-categories/${categoryId}`,
    },
    {
      label: dynamicTag?.name || 'Dynamic tag name',
      to: '',
    },
  ];

  const updateDynamicTagFn = async (): Promise<void> =>
    new Promise((resolve, reject) => {
      if (!dynamicTagId) {
        reject();
      }

      const formattedVisualPrompts = visualPrompts.map((visualPrompt) => ({
        coactiveImageId: visualPrompt.coactiveImageId,
        path: visualPrompt.path,
      }));

      const formattedVisualPromptsWithIds = formattedVisualPrompts.map(
        (visualPrompt) => {
          if (!visualPrompt.dynamicTagId) {
            return {
              ...visualPrompt,
              dynamicTagId,
            };
          }
          return visualPrompt;
        },
      );

      updateDynamicTag(
        {
          dynamicTagId: dynamicTagId || '',
          categoryId: categoryId || '',
          updateDynamicTagPromptsRequest: {
            visualPrompts: formattedVisualPrompts,
            textPrompts,
          },
        },
        {
          onSuccess: async () => {
            setFinalizedTextPrompts(textPrompts);
            setFinalizedVisualPrompts(formattedVisualPromptsWithIds);
            queryClient.setQueryData(getDynamicTagQueryKey(dynamicTagId), {
              ...dynamicTag,
              visualPrompts: formattedVisualPromptsWithIds,
              textPrompts,
            });
            await queryClient.invalidateQueries(
              getDynamicTagQueryKey(dynamicTagId),
              {
                refetchActive: true,
                refetchInactive: true,
              },
            );
            toast.success('Dynamic tag has been updated successfully.');
            setIsEditingPrompts(false);
            resolve();
          },
          onError: () => {
            setFinalizedTextPrompts(finalizedTextPrompts);
            setFinalizedVisualPrompts(finalizedVisualPrompts);
            toast.error('Dynamic tag could not be updated. Please try again.');
          },
        },
      );
    });

  const handleSubmitChanges = () => {
    const hasTextPromptChanges = !(
      textPrompts.length === finalizedTextPrompts.length &&
      textPrompts.every((item) => finalizedTextPrompts.includes(item))
    );

    const hasVisualPromptChanges = !(
      visualPrompts.length === finalizedVisualPrompts.length &&
      visualPrompts.every((item) => finalizedVisualPrompts.includes(item))
    );

    if (hasTextPromptChanges || hasVisualPromptChanges) {
      updateDynamicTagFn();
    }
  };

  const areEditedPromptsEmpty = !textPrompts.length && !visualPrompts.length;

  const title = (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h1 data-cy="dynamic-tag-detail-page-title">
          {dynamicTag?.name || 'Dynamic tag'}
        </h1>
        {isDeleteDynamicTagsEnabled && (
          <MenuButton
            position="right"
            label="More"
            items={[
              [
                {
                  dataTestId: 'delete-dynamic-tag-btn',
                  id: 'delete-dynamic-tag',
                  name: (
                    <span className="text-red-500">Delete dynamic tag</span>
                  ),
                  onClick: () => setIsDeleteModalOpen(true),
                },
              ],
            ]}
            dataTestId="dynamic-tag-detail-page-more-btn"
          />
        )}
      </div>
      <div className="flex justify-between items-center h-8">
        <NavigateToDatasetButton
          path={`/datasets/${dynamicTag?.datasetId || ''}`}
          datasetName={dynamicTag?.datasetName || 'Dataset'}
        />
        {isEditingPrompts && (
          <div className="space-x-2">
            <Button buttonStyle="secondary" onClick={handleCancelEdit}>
              Cancel
            </Button>
            <Button
              disabled={areEditedPromptsEmpty}
              buttonStyle="primary"
              onClick={handleSubmitChanges}
            >
              Save prompt changes
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  const handleSelectAsset = (asset: AssetResponse) => {
    setSidebarAsset(asset);
    openSidebarAssetPreview({ asset });
  };

  const arePromptsEmpty = !textPrompts.length && !visualPrompts.length;

  const handleDeleteDynamicTag = async () => {
    setIsDeleteModalOpen(false);

    try {
      await new Promise<void>((resolve, reject) => {
        deleteDynamicTag(
          { categoryId: categoryId!, dynamicTagId: dynamicTagId! },
          {
            onSuccess: () => {
              navigate(`/dynamic-tag-categories/${categoryId}`);
              toast.success('Done! Your dynamic tag is being deleted.');
              resolve();
            },
            onError: () => {
              toast.error(
                'Something went wrong while deleting your dynamic tag. Please try again.',
              );
              reject();
            },
          },
        );
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const topContent = useMemo(
    () => (
      <DynamicTagsTopContent
        isAudioSearchable={dynamicTag?.areDatasetsAudioSearchable ?? false}
        datasetId={dynamicTag?.datasetId || ''}
        onAssetClick={handleSelectAsset}
        textPrompts={textPrompts}
        visualPrompts={visualPrompts}
      />
    ),
    [isFetched, isLoading, dynamicTag, visualPrompts, textPrompts],
  );

  let innerComponent;
  if (isError) {
    innerComponent = <EmptyErrorState itemWithError="dynamic tag" />;
  } else if (isLoading) {
    innerComponent = (
      <div className="w-full flex items-center justify-center h-[50vh]">
        <ClipLoader
          cssOverride={{ textAlign: 'center' }}
          color={colors.green['500']}
          loading
          size={40}
          speedMultiplier={0.75}
        />
      </div>
    );
  } else {
    innerComponent = (
      <>
        <PageHeader breadcrumbs={breadcrumbs} title={title} />
        <div
          className={classNames(
            'pt-6 my-6  flex gap-10 border-t border-gray-200 ',
            rightSidebarOpen ? 'flex-col' : 'flex-row',
          )}
        >
          <div
            className={classNames(
              'h-40',
              rightSidebarOpen ? 'w-full' : 'w-1/2',
            )}
          >
            <TextPrompts
              setIsEditingPrompts={setIsEditingPrompts}
              prompts={textPrompts}
              setPrompts={setTextPrompts}
            />
          </div>
          <div
            className={classNames(
              'h-40',
              rightSidebarOpen ? 'w-full' : 'w-1/2',
            )}
          >
            <VisualPrompts
              datasetId={dynamicTag?.datasetId!}
              setIsEditingPrompts={setIsEditingPrompts}
              visualPrompts={visualPrompts}
              setVisualPrompts={setVisualPrompts}
            />
          </div>
        </div>
        <div className="w-full mt-16 border-t border-gray-200">
          {arePromptsEmpty ? (
            <EmptyState
              iconClassName="h-6 w-6 text-yellow-400"
              Icon={ExclamationTriangleIcon}
              message=" Save text and/or visual prompts to see top content for this dynamic tag."
            />
          ) : (
            topContent
          )}
        </div>
      </>
    );
  }
  return (
    <Main
      rightSidebar={
        sidebarPreviewAsset && (
          <AssetDetailSidebar
            asset={
              (sidebarPreviewAsset.asset as any) ??
              sidebarPreviewAsset.data?.asset
            }
            datasetId={dynamicTag?.datasetId || null}
            defaultMetadataTitle={
              sidebarPreviewAsset.data ? 'Other metadata' : 'Metadata'
            }
            fromDatasetView
            metadata={sidebarPreviewAsset?.data?.data}
            metadataKeyOrder={['row']}
            metadataTitle="Search data"
            showDefaultMetadata={!sidebarPreviewAsset.data}
            setSelectedAsset={openSidebarAssetPreview}
          />
        )
      }
    >
      <DeleteDynamicTagModal
        dataTestId="dynamic-tag-detail-page-delete-modal"
        dynamicTagToDelete={dynamicTag!}
        shouldDisplay={isDeleteModalOpen}
        onClose={setIsDeleteModalOpen}
        onConfirm={handleDeleteDynamicTag}
      />
      <div className="max-w-8xl mx-auto">{innerComponent}</div>
    </Main>
  );
};

export default DynamicTagDetail;
