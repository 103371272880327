/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface ValidateDatasetNameRequest
 */
export interface ValidateDatasetNameRequest {
    /**
     * A dataset name to validate
     * @type {string}
     * @memberof ValidateDatasetNameRequest
     */
    name: string;
}

export function ValidateDatasetNameRequestFromJSON(json: any): ValidateDatasetNameRequest {
    return ValidateDatasetNameRequestFromJSONTyped(json, false);
}

export function ValidateDatasetNameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateDatasetNameRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function ValidateDatasetNameRequestToJSON(value?: ValidateDatasetNameRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}

