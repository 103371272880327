import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import {
  getUrlParams,
  isInvitationRedirectUrl,
  isInvitationUrl,
} from 'utils/UrlUtils';

// eslint-disable-next-line consistent-return
const useLoginWithRedirect = () => {
  const { loginWithRedirect: auth0LoginWithRedirect } = useAuth0();
  const loginWithRedirect = useCallback(() => {
    const returnToLocation = `${window.location.pathname}${window.location.search}`;
    if (isInvitationUrl(returnToLocation)) {
      // if invitation param exists in returnToLocation url (without redirect param), we want to do nothing.
      if (isInvitationRedirectUrl(returnToLocation)) {
        const returnToLocationParams = getUrlParams(returnToLocation);
        if (returnToLocationParams && 'redirect' in returnToLocationParams) {
          const redirectToPath = returnToLocationParams.redirect;
          sessionStorage.setItem('redirectPath', redirectToPath as string);
        }
      }
    } else {
      // if invitation param does not exist, we can use loginWithRedirect
      // to redirect anonymous user to authorize, then back to returnToLocation url
      auth0LoginWithRedirect({
        appState: {
          returnTo: returnToLocation,
        },
      });
    }
  }, [auth0LoginWithRedirect, window.location.href]);
  return loginWithRedirect;
};

export default useLoginWithRedirect;
