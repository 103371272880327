import { DatasetStatusEnum } from 'api/generated';
import classNames from 'classnames';
import React from 'react';

const getStatusClassName = (status: string) => {
  switch (status) {
    case DatasetStatusEnum.Waiting || DatasetStatusEnum.Deleting:
      return 'text-yellow-700 bg-yellow-50 border-yellow-300';
    case DatasetStatusEnum.Initializing:
      return 'text-blue-600 bg-blue-50 border-blue-200';
    case DatasetStatusEnum.Ready:
      return 'text-green-600 bg-green-50  border-green-300';
    case DatasetStatusEnum.Error:
      return 'text-red-600 bg-red-50 border-red-200';
    default:
      return 'text-gray-600 bg-gray-100 border-gray-200';
  }
};
const Status: React.FC<{
  status: string;
}> = function Status({ status }) {
  const hasEllipsis =
    status === DatasetStatusEnum.Initializing ||
    status === DatasetStatusEnum.Waiting ||
    status === DatasetStatusEnum.Deleting;

  return (
    <span
      className={classNames(
        'px-2 py-1 rounded-md border',
        getStatusClassName(status),
      )}
    >
      {status}
      {hasEllipsis ? '...' : ''}
    </span>
  );
};

export default Status;
