/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppSharedV0SchemaPagedMetaResponse,
    AppSharedV0SchemaPagedMetaResponseFromJSON,
    AppSharedV0SchemaPagedMetaResponseFromJSONTyped,
    AppSharedV0SchemaPagedMetaResponseToJSON,
} from './AppSharedV0SchemaPagedMetaResponse';
import {
    EmbeddingResponse,
    EmbeddingResponseFromJSON,
    EmbeddingResponseFromJSONTyped,
    EmbeddingResponseToJSON,
} from './EmbeddingResponse';

/**
 * A list of dataset embedding spaces
 * @export
 * @interface EmbeddingListResponse
 */
export interface EmbeddingListResponse {
    /**
     * 
     * @type {AppSharedV0SchemaPagedMetaResponse}
     * @memberof EmbeddingListResponse
     */
    meta: AppSharedV0SchemaPagedMetaResponse;
    /**
     * The paginated data
     * @type {Array<EmbeddingResponse>}
     * @memberof EmbeddingListResponse
     */
    data: Array<EmbeddingResponse>;
}

export function EmbeddingListResponseFromJSON(json: any): EmbeddingListResponse {
    return EmbeddingListResponseFromJSONTyped(json, false);
}

export function EmbeddingListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmbeddingListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': AppSharedV0SchemaPagedMetaResponseFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(EmbeddingResponseFromJSON)),
    };
}

export function EmbeddingListResponseToJSON(value?: EmbeddingListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': AppSharedV0SchemaPagedMetaResponseToJSON(value.meta),
        'data': ((value.data as Array<any>).map(EmbeddingResponseToJSON)),
    };
}

