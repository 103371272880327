/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Organization,
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';
import {
    Permissions,
    PermissionsFromJSON,
    PermissionsFromJSONTyped,
    PermissionsToJSON,
} from './Permissions';

/**
 * Base model for all schema
 * @export
 * @interface MeResponse
 */
export interface MeResponse {
    /**
     * List of permissions
     * @type {Array<Permissions>}
     * @memberof MeResponse
     */
    permissions: Array<Permissions>;
    /**
     * 
     * @type {Organization}
     * @memberof MeResponse
     */
    organization: Organization;
    /**
     * Whether user has requested a trial extension or not
     * @type {boolean}
     * @memberof MeResponse
     */
    requestedTrialExtension: boolean | null;
    /**
     * User's logout redirect url
     * @type {string}
     * @memberof MeResponse
     */
    logoutRedirectUrl?: string | null;
}

export function MeResponseFromJSON(json: any): MeResponse {
    return MeResponseFromJSONTyped(json, false);
}

export function MeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permissions': ((json['permissions'] as Array<any>).map(PermissionsFromJSON)),
        'organization': OrganizationFromJSON(json['organization']),
        'requestedTrialExtension': json['requestedTrialExtension'],
        'logoutRedirectUrl': !exists(json, 'logoutRedirectUrl') ? undefined : json['logoutRedirectUrl'],
    };
}

export function MeResponseToJSON(value?: MeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permissions': ((value.permissions as Array<any>).map(PermissionsToJSON)),
        'organization': OrganizationToJSON(value.organization),
        'requestedTrialExtension': value.requestedTrialExtension,
        'logoutRedirectUrl': value.logoutRedirectUrl,
    };
}

