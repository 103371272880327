import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

interface PageButtonProps {
  active: boolean;
  onClick?: () => void;
}

const PageButton: React.FC<PropsWithChildren<PageButtonProps>> =
  function PageButton({ active, children, onClick }) {
    return (
      <button
        type="button"
        aria-current={active ? 'page' : undefined}
        className={classNames(
          'relative inline-flex items-center px-4 py-2 border text-sm font-medium',
          {
            'z-10 bg-emerald-50 border-emerald-500 text-emerald-600': active,
            'bg-white border-gray-300 text-gray-500 hover:bg-gray-50': !active,
          },
        )}
        onClick={onClick}
      >
        {children}
      </button>
    );
  };

PageButton.defaultProps = {
  onClick: undefined,
};

export default PageButton;
