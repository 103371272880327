import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import CoactiveLogo from 'assets/logos/coactive.svg';
import classNames from 'classnames';
import ErrorText from 'components/ErrorText';
import React, { Fragment, useRef } from 'react';

interface Props {
  logo?: boolean;
  title: React.ReactElement | string;
  children: React.ReactElement;
  open: boolean;
  setOpen: (open: boolean) => void;
  actions?: React.ReactElement;
  error?: string;
}
const Dialog: React.FC<Props> = ({
  logo,
  title,
  children,
  open,
  setOpen,
  actions,
  error,
}) => {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={open} as="div" className="absolute">
      <HeadlessDialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <HeadlessDialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-8">
                <div>
                  <div className="">
                    {logo && (
                      <img
                        src={CoactiveLogo}
                        alt="Home"
                        className="h-6 w-full mb-8"
                      />
                    )}
                    <HeadlessDialog.Title
                      as="h3"
                      className={classNames(
                        'text-xl font-medium leading-6 text-gray-900',
                        { 'text-center': logo },
                      )}
                    >
                      {title}
                    </HeadlessDialog.Title>
                    <div className="mt-2">{children}</div>
                  </div>
                </div>
                {actions ? (
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 grid-reverse">
                    {actions}
                  </div>
                ) : undefined}
                {Boolean(error) && <ErrorText>{error}</ErrorText>}
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  );
};

Dialog.defaultProps = {
  actions: undefined,
  error: undefined,
  logo: false,
};

export default Dialog;
