import PageHeader from 'components/PageHeader';
import Tabs from 'components/Tabs';
import CreateUserButton from 'pages/settings/users/CreateUserButton';
import OrgInvitationsTable from 'pages/settings/users/OrgInvitationsTable';
import OrgMemberTable from 'pages/settings/users/OrgMembersTable';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetOrgInvitations } from './queries';

type UserTab = 'Members' | 'Invitations';

const TAB_QUERY_KEY = 'tab';

function getTabValueFromString(tab: string | null): UserTab | undefined {
  if (tab === 'Members' || tab === 'Invitations') {
    return tab;
  }
  return undefined;
}

const Users = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { data: invitations, refetch: refetchInvitations } =
    useGetOrgInvitations();
  const [activeTab, setActiveTab] = useState<UserTab>(
    getTabValueFromString(searchParams.get(TAB_QUERY_KEY)) ?? 'Members',
  );

  const selectTab = (tab: UserTab) => {
    navigate({
      pathname: location.pathname,
      search: `?${TAB_QUERY_KEY}=${tab}`,
    });
  };

  useEffect(() => {
    const tab = getTabValueFromString(searchParams.get(TAB_QUERY_KEY));
    if (tab) {
      setActiveTab(tab);
    }
  }, [searchParams]);

  return (
    <div className="max-w-8xl mx-auto">
      <PageHeader
        title={<h1>Users</h1>}
        backButtonTo=""
        actions={<CreateUserButton refetchInvitations={refetchInvitations} />}
      />
      <div className="pt-4">
        <Tabs
          tabs={[{ name: 'Members' }, { name: 'Invitations' }]}
          active={activeTab}
          onClick={selectTab}
        />
        <div className="pt-4">
          {activeTab === 'Members' ? (
            <OrgMemberTable />
          ) : (
            <OrgInvitationsTable
              invitations={invitations}
              refetchInvitations={refetchInvitations}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
