import DeleteModal from 'components/DeleteModal';
import React from 'react';
import {
  truncateElements,
  truncateElementsFromString,
} from 'utils/TruncateElements';
import { BasicDescription, FullCategoryResponseUI } from 'api/generated';
import { CategoryToDelete } from '../types';

interface DeleteCategoryModalProps {
  shouldDisplay: boolean;
  onClose: (isOpen: boolean) => void;
  onConfirm: () => void;
  categoryToDelete: CategoryToDelete | FullCategoryResponseUI;
  dataTestId?: string;
}

const DYNAMIC_TAGS_NAME_COUNT = 4;

const DeleteCategoryModal: React.FC<DeleteCategoryModalProps> = ({
  shouldDisplay,
  categoryToDelete,
  onClose,
  onConfirm,
  dataTestId,
}) => {
  if (!shouldDisplay) return null;

  const dynamicTagsSummary =
    typeof categoryToDelete.dynamicTags === 'string'
      ? truncateElementsFromString(
          categoryToDelete.dynamicTags,
          DYNAMIC_TAGS_NAME_COUNT,
        )
      : truncateElements(
          categoryToDelete.dynamicTags as BasicDescription[],
          DYNAMIC_TAGS_NAME_COUNT,
        );

  const isCategoryEmpty = dynamicTagsSummary.trim().length === 0;

  return (
    <DeleteModal
      dataTestId={dataTestId}
      title={`Are you sure you want to delete ${categoryToDelete.name}?`}
      isOpen={shouldDisplay}
      onClose={onClose}
      onConfirm={onConfirm}
      body={
        <div className="mt-2 space-y-2 font-medium">
          <p className="text-gray-800 text-base">
            Deleting {categoryToDelete.name} in{' '}
            <span className="font-bold text-gray-400">
              {categoryToDelete.datasets[0].name}
            </span>{' '}
            will remove the category{' '}
            {!isCategoryEmpty && 'and all its dynamic tags:'}
          </p>
          {!isCategoryEmpty && (
            <p className="text-gray-600 text-sm">{dynamicTagsSummary}</p>
          )}
        </div>
      }
    />
  );
};

DeleteCategoryModal.defaultProps = {
  dataTestId: undefined,
};

export default DeleteCategoryModal;
