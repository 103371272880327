/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConceptPredictionResponse,
  ConceptsTableResponse,
  CreateConceptRequest,
  CreateConceptsFromCsvRequest,
  ErrorResponse,
  FullConceptListResponseUI,
  FullConceptResponseUI,
  HTTPValidationError,
  UpdateConceptRequest,
  UploadResponse,
  ValidateConceptNameRequest,
  ValidateConceptNameResponse,
} from '../models';
import {
    ConceptPredictionResponseFromJSON,
    ConceptPredictionResponseToJSON,
    ConceptsTableResponseFromJSON,
    ConceptsTableResponseToJSON,
    CreateConceptRequestFromJSON,
    CreateConceptRequestToJSON,
    CreateConceptsFromCsvRequestFromJSON,
    CreateConceptsFromCsvRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FullConceptListResponseUIFromJSON,
    FullConceptListResponseUIToJSON,
    FullConceptResponseUIFromJSON,
    FullConceptResponseUIToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    UpdateConceptRequestFromJSON,
    UpdateConceptRequestToJSON,
    UploadResponseFromJSON,
    UploadResponseToJSON,
    ValidateConceptNameRequestFromJSON,
    ValidateConceptNameRequestToJSON,
    ValidateConceptNameResponseFromJSON,
    ValidateConceptNameResponseToJSON,
} from '../models';

export interface CreateConceptOperationRequest {
    createConceptRequest: CreateConceptRequest;
}

export interface CreateConceptByNamespaceIdFromCsvRequest {
    createConceptsFromCsvRequest: CreateConceptsFromCsvRequest;
}

export interface DeleteConceptRequest {
    conceptId: string;
}

export interface GetConceptRequest {
    conceptId: string;
}

export interface GetConceptPredictionEstimationRequest {
    conceptId: string;
    threshold?: number | null;
    confidenceLevel?: number | null;
    estimationFidelity?: number | null;
}

export interface GetConceptsRequest {
    datasetIds?: Array<string> | null;
    embeddingIds?: Array<string> | null;
    regexpPattern?: string | null;
    offset?: number | null;
    limit?: number | null;
}

export interface GetConceptsAsTableRequest {
    datasetsToInclude?: Array<string> | null;
    offset?: number | null;
    limit?: number | null;
}

export interface GetConceptsByEmbeddingRequest {
    embeddingId: string;
    offset?: number | null;
    limit?: number | null;
}

export interface UpdateConceptByIdRequest {
    conceptId: string;
    updateConceptRequest: UpdateConceptRequest;
}

export interface UploadConceptsCsvRequest {
    file: Blob;
    dzuuid?: string | null;
    dzchunkindex?: number | null;
    dzchunkbyteoffset?: number | null;
    dztotalchunkcount?: number | null;
    dztotalfilesize?: number | null;
}

export interface ValidateConceptNameOperationRequest {
    validateConceptNameRequest: ValidateConceptNameRequest;
}

/**
 * ConceptApi - interface
 * 
 * @export
 * @interface ConceptApiInterface
 */
export interface ConceptApiInterface {
    /**
     * Create a new concept
     * @summary Create a new concept
     * @param {CreateConceptRequest} createConceptRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConceptApiInterface
     */
    createConceptRaw(requestParameters: CreateConceptOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullConceptResponseUI>>;

    /**
     * Create a new concept
     * Create a new concept
     */
    createConcept(requestParameters: CreateConceptOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullConceptResponseUI>;

    /**
     * Bulk create concepts CSV
     * @summary Bulk create concepts CSV
     * @param {CreateConceptsFromCsvRequest} createConceptsFromCsvRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConceptApiInterface
     */
    createConceptByNamespaceIdFromCsvRaw(requestParameters: CreateConceptByNamespaceIdFromCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Bulk create concepts CSV
     * Bulk create concepts CSV
     */
    createConceptByNamespaceIdFromCsv(requestParameters: CreateConceptByNamespaceIdFromCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Delete all concepts
     * @summary Delete all concepts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConceptApiInterface
     */
    deleteAllConceptsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Delete all concepts
     * Delete all concepts
     */
    deleteAllConcepts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Delete concept by id
     * @summary Delete concept by id
     * @param {string} conceptId The unique identifier for the concept
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConceptApiInterface
     */
    deleteConceptRaw(requestParameters: DeleteConceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Delete concept by id
     * Delete concept by id
     */
    deleteConcept(requestParameters: DeleteConceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Get concept by id
     * @summary Get concept by id
     * @param {string} conceptId The unique identifier for the concept
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConceptApiInterface
     */
    getConceptRaw(requestParameters: GetConceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullConceptResponseUI>>;

    /**
     * Get concept by id
     * Get concept by id
     */
    getConcept(requestParameters: GetConceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullConceptResponseUI>;

    /**
     * Get concept\'s prediction estimation
     * @summary Get concept\'s prediction estimation
     * @param {string} conceptId The unique identifier for the concept
     * @param {number} [threshold] Threshold above which classification is positive
     * @param {number} [confidenceLevel] A higher value results in wider confidence intervals, with more confidence of correctness. A lower value results in narrower confidence intervals, with less confidence of correctness
     * @param {number} [estimationFidelity] A higher value makes estimation slower, but more accurate. A lower value makes estimation faster, but less accurate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConceptApiInterface
     */
    getConceptPredictionEstimationRaw(requestParameters: GetConceptPredictionEstimationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptPredictionResponse>>;

    /**
     * Get concept\'s prediction estimation
     * Get concept\'s prediction estimation
     */
    getConceptPredictionEstimation(requestParameters: GetConceptPredictionEstimationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptPredictionResponse>;

    /**
     * Get concepts
     * @summary Get concepts
     * @param {Array<string>} [datasetIds] The unique identifier for dataset to include
     * @param {Array<string>} [embeddingIds] The unique identifier for embeddings to include
     * @param {string} [regexpPattern] Regexp pattern to further filter concepts by name
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ConceptApiInterface
     */
    getConceptsRaw(requestParameters: GetConceptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullConceptListResponseUI>>;

    /**
     * Get concepts
     * Get concepts
     */
    getConcepts(requestParameters: GetConceptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullConceptListResponseUI>;

    /**
     * Get paginated concepts, optionally filter by datasets
     * @summary Get paginated concepts
     * @param {Array<string>} [datasetsToInclude] Datasets to include in the list response
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConceptApiInterface
     */
    getConceptsAsTableRaw(requestParameters: GetConceptsAsTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptsTableResponse>>;

    /**
     * Get paginated concepts, optionally filter by datasets
     * Get paginated concepts
     */
    getConceptsAsTable(requestParameters: GetConceptsAsTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptsTableResponse>;

    /**
     * Get concepts by embedding id
     * @summary Get concepts by embedding id
     * @param {string} embeddingId The unique identifier for the embedding
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ConceptApiInterface
     */
    getConceptsByEmbeddingRaw(requestParameters: GetConceptsByEmbeddingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullConceptListResponseUI>>;

    /**
     * Get concepts by embedding id
     * Get concepts by embedding id
     */
    getConceptsByEmbedding(requestParameters: GetConceptsByEmbeddingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullConceptListResponseUI>;

    /**
     * Update concept by id
     * @summary Update concept by id
     * @param {string} conceptId The unique identifier for the concept
     * @param {UpdateConceptRequest} updateConceptRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConceptApiInterface
     */
    updateConceptByIdRaw(requestParameters: UpdateConceptByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullConceptResponseUI>>;

    /**
     * Update concept by id
     * Update concept by id
     */
    updateConceptById(requestParameters: UpdateConceptByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullConceptResponseUI>;

    /**
     * Bulk create concepts CSV
     * @summary Bulk create concepts CSV
     * @param {Blob} file CSV file of concepts to create in bulk
     * @param {string} [dzuuid] Chunked upload id
     * @param {number} [dzchunkindex] Chunked upload index of chunk
     * @param {number} [dzchunkbyteoffset] Chunked upload bytes offset of chunk
     * @param {number} [dztotalchunkcount] Chunked upload total number of chunks
     * @param {number} [dztotalfilesize] Chunked upload total file size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConceptApiInterface
     */
    uploadConceptsCsvRaw(requestParameters: UploadConceptsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadResponse>>;

    /**
     * Bulk create concepts CSV
     * Bulk create concepts CSV
     */
    uploadConceptsCsv(requestParameters: UploadConceptsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadResponse>;

    /**
     * Validate concept name contains allowable characters and check for uniqueness
     * @summary Validate concept name
     * @param {ValidateConceptNameRequest} validateConceptNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConceptApiInterface
     */
    validateConceptNameRaw(requestParameters: ValidateConceptNameOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateConceptNameResponse>>;

    /**
     * Validate concept name contains allowable characters and check for uniqueness
     * Validate concept name
     */
    validateConceptName(requestParameters: ValidateConceptNameOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateConceptNameResponse>;

}

/**
 * 
 */
export class ConceptApi extends runtime.BaseAPI implements ConceptApiInterface {

    /**
     * Create a new concept
     * Create a new concept
     */
    async createConceptRaw(requestParameters: CreateConceptOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullConceptResponseUI>> {
        if (requestParameters.createConceptRequest === null || requestParameters.createConceptRequest === undefined) {
            throw new runtime.RequiredError('createConceptRequest','Required parameter requestParameters.createConceptRequest was null or undefined when calling createConcept.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/concepts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateConceptRequestToJSON(requestParameters.createConceptRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullConceptResponseUIFromJSON(jsonValue));
    }

    /**
     * Create a new concept
     * Create a new concept
     */
    async createConcept(requestParameters: CreateConceptOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullConceptResponseUI> {
        const response = await this.createConceptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Bulk create concepts CSV
     * Bulk create concepts CSV
     */
    async createConceptByNamespaceIdFromCsvRaw(requestParameters: CreateConceptByNamespaceIdFromCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.createConceptsFromCsvRequest === null || requestParameters.createConceptsFromCsvRequest === undefined) {
            throw new runtime.RequiredError('createConceptsFromCsvRequest','Required parameter requestParameters.createConceptsFromCsvRequest was null or undefined when calling createConceptByNamespaceIdFromCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/concepts/csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateConceptsFromCsvRequestToJSON(requestParameters.createConceptsFromCsvRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Bulk create concepts CSV
     * Bulk create concepts CSV
     */
    async createConceptByNamespaceIdFromCsv(requestParameters: CreateConceptByNamespaceIdFromCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createConceptByNamespaceIdFromCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete all concepts
     * Delete all concepts
     */
    async deleteAllConceptsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/concepts`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete all concepts
     * Delete all concepts
     */
    async deleteAllConcepts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteAllConceptsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Delete concept by id
     * Delete concept by id
     */
    async deleteConceptRaw(requestParameters: DeleteConceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.conceptId === null || requestParameters.conceptId === undefined) {
            throw new runtime.RequiredError('conceptId','Required parameter requestParameters.conceptId was null or undefined when calling deleteConcept.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/concepts/{concept_id}`.replace(`{${"concept_id"}}`, encodeURIComponent(String(requestParameters.conceptId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete concept by id
     * Delete concept by id
     */
    async deleteConcept(requestParameters: DeleteConceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteConceptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get concept by id
     * Get concept by id
     */
    async getConceptRaw(requestParameters: GetConceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullConceptResponseUI>> {
        if (requestParameters.conceptId === null || requestParameters.conceptId === undefined) {
            throw new runtime.RequiredError('conceptId','Required parameter requestParameters.conceptId was null or undefined when calling getConcept.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/concepts/{concept_id}`.replace(`{${"concept_id"}}`, encodeURIComponent(String(requestParameters.conceptId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullConceptResponseUIFromJSON(jsonValue));
    }

    /**
     * Get concept by id
     * Get concept by id
     */
    async getConcept(requestParameters: GetConceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullConceptResponseUI> {
        const response = await this.getConceptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get concept\'s prediction estimation
     * Get concept\'s prediction estimation
     */
    async getConceptPredictionEstimationRaw(requestParameters: GetConceptPredictionEstimationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptPredictionResponse>> {
        if (requestParameters.conceptId === null || requestParameters.conceptId === undefined) {
            throw new runtime.RequiredError('conceptId','Required parameter requestParameters.conceptId was null or undefined when calling getConceptPredictionEstimation.');
        }

        const queryParameters: any = {};

        if (requestParameters.threshold !== undefined) {
            queryParameters['threshold'] = requestParameters.threshold;
        }

        if (requestParameters.confidenceLevel !== undefined) {
            queryParameters['confidence_level'] = requestParameters.confidenceLevel;
        }

        if (requestParameters.estimationFidelity !== undefined) {
            queryParameters['estimation_fidelity'] = requestParameters.estimationFidelity;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/concepts/{concept_id}/prediction_estimation`.replace(`{${"concept_id"}}`, encodeURIComponent(String(requestParameters.conceptId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptPredictionResponseFromJSON(jsonValue));
    }

    /**
     * Get concept\'s prediction estimation
     * Get concept\'s prediction estimation
     */
    async getConceptPredictionEstimation(requestParameters: GetConceptPredictionEstimationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptPredictionResponse> {
        const response = await this.getConceptPredictionEstimationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get concepts
     * Get concepts
     */
    async getConceptsRaw(requestParameters: GetConceptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullConceptListResponseUI>> {
        const queryParameters: any = {};

        if (requestParameters.datasetIds) {
            queryParameters['dataset_ids'] = requestParameters.datasetIds;
        }

        if (requestParameters.embeddingIds) {
            queryParameters['embedding_ids'] = requestParameters.embeddingIds;
        }

        if (requestParameters.regexpPattern !== undefined) {
            queryParameters['regexp_pattern'] = requestParameters.regexpPattern;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/concepts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullConceptListResponseUIFromJSON(jsonValue));
    }

    /**
     * Get concepts
     * Get concepts
     */
    async getConcepts(requestParameters: GetConceptsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullConceptListResponseUI> {
        const response = await this.getConceptsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get paginated concepts, optionally filter by datasets
     * Get paginated concepts
     */
    async getConceptsAsTableRaw(requestParameters: GetConceptsAsTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConceptsTableResponse>> {
        const queryParameters: any = {};

        if (requestParameters.datasetsToInclude) {
            queryParameters['datasets_to_include'] = requestParameters.datasetsToInclude;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/concepts/table`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConceptsTableResponseFromJSON(jsonValue));
    }

    /**
     * Get paginated concepts, optionally filter by datasets
     * Get paginated concepts
     */
    async getConceptsAsTable(requestParameters: GetConceptsAsTableRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConceptsTableResponse> {
        const response = await this.getConceptsAsTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get concepts by embedding id
     * Get concepts by embedding id
     */
    async getConceptsByEmbeddingRaw(requestParameters: GetConceptsByEmbeddingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullConceptListResponseUI>> {
        if (requestParameters.embeddingId === null || requestParameters.embeddingId === undefined) {
            throw new runtime.RequiredError('embeddingId','Required parameter requestParameters.embeddingId was null or undefined when calling getConceptsByEmbedding.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/embeddings/{embedding_id}/concepts`.replace(`{${"embedding_id"}}`, encodeURIComponent(String(requestParameters.embeddingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullConceptListResponseUIFromJSON(jsonValue));
    }

    /**
     * Get concepts by embedding id
     * Get concepts by embedding id
     */
    async getConceptsByEmbedding(requestParameters: GetConceptsByEmbeddingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullConceptListResponseUI> {
        const response = await this.getConceptsByEmbeddingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update concept by id
     * Update concept by id
     */
    async updateConceptByIdRaw(requestParameters: UpdateConceptByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullConceptResponseUI>> {
        if (requestParameters.conceptId === null || requestParameters.conceptId === undefined) {
            throw new runtime.RequiredError('conceptId','Required parameter requestParameters.conceptId was null or undefined when calling updateConceptById.');
        }

        if (requestParameters.updateConceptRequest === null || requestParameters.updateConceptRequest === undefined) {
            throw new runtime.RequiredError('updateConceptRequest','Required parameter requestParameters.updateConceptRequest was null or undefined when calling updateConceptById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/concepts/{concept_id}`.replace(`{${"concept_id"}}`, encodeURIComponent(String(requestParameters.conceptId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateConceptRequestToJSON(requestParameters.updateConceptRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullConceptResponseUIFromJSON(jsonValue));
    }

    /**
     * Update concept by id
     * Update concept by id
     */
    async updateConceptById(requestParameters: UpdateConceptByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullConceptResponseUI> {
        const response = await this.updateConceptByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Bulk create concepts CSV
     * Bulk create concepts CSV
     */
    async uploadConceptsCsvRaw(requestParameters: UploadConceptsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadResponse>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadConceptsCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.dzuuid !== undefined) {
            formParams.append('dzuuid', requestParameters.dzuuid as any);
        }

        if (requestParameters.dzchunkindex !== undefined) {
            formParams.append('dzchunkindex', requestParameters.dzchunkindex as any);
        }

        if (requestParameters.dzchunkbyteoffset !== undefined) {
            formParams.append('dzchunkbyteoffset', requestParameters.dzchunkbyteoffset as any);
        }

        if (requestParameters.dztotalchunkcount !== undefined) {
            formParams.append('dztotalchunkcount', requestParameters.dztotalchunkcount as any);
        }

        if (requestParameters.dztotalfilesize !== undefined) {
            formParams.append('dztotalfilesize', requestParameters.dztotalfilesize as any);
        }

        const response = await this.request({
            path: `/api/ui/concepts/upload/csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadResponseFromJSON(jsonValue));
    }

    /**
     * Bulk create concepts CSV
     * Bulk create concepts CSV
     */
    async uploadConceptsCsv(requestParameters: UploadConceptsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadResponse> {
        const response = await this.uploadConceptsCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate concept name contains allowable characters and check for uniqueness
     * Validate concept name
     */
    async validateConceptNameRaw(requestParameters: ValidateConceptNameOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateConceptNameResponse>> {
        if (requestParameters.validateConceptNameRequest === null || requestParameters.validateConceptNameRequest === undefined) {
            throw new runtime.RequiredError('validateConceptNameRequest','Required parameter requestParameters.validateConceptNameRequest was null or undefined when calling validateConceptName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/concepts/validate-name`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateConceptNameRequestToJSON(requestParameters.validateConceptNameRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateConceptNameResponseFromJSON(jsonValue));
    }

    /**
     * Validate concept name contains allowable characters and check for uniqueness
     * Validate concept name
     */
    async validateConceptName(requestParameters: ValidateConceptNameOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateConceptNameResponse> {
        const response = await this.validateConceptNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
