import { ArrowsPointingOutIcon, XMarkIcon } from '@heroicons/react/24/solid';
import {
  AssetResponse,
  AssetType,
  ImageSizeEnum,
  LabelEnum,
} from 'api/generated';
import classNames from 'classnames';
import ClipboardIconButton from 'components/ClipboardIconButton';
import FullScreenAssetModal from 'components/FullScreenAssetModal';
import IconButton from 'components/IconButton';
import ProtectedImage from 'components/Image/ProtectedImage';
import MetadataSection from 'components/Metadata/MetadataSection';
import { useGetImageMetadata } from 'components/Sidebar/AssetDetailSidebar/queries';
import ProtectedVideo from 'components/video/ProtectedVideo';
import RefineSidebarContext from 'context/RefineSidebarContext';
import SidebarContext from 'context/SidebarContext';
import UpdateLabelsButtonControls from 'pages/concepts/detail/tabs/Examples/UpdateLabelsButtonControls';
import ConceptClassificationScores from 'pages/queries/ConceptClassificationScores/ConceptClassificationScores';
import { useUpdateConceptVersionLabelsMutation } from 'queries/labels';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import colors from 'tailwindcss/colors';
import SidebarWrapper from '../SidebarWrapper';

interface RefineSidebarProps {
  conceptId: string;
  conceptName: string;
  conceptVersionId: string;
  onClose?: () => void;
  hasLoadingIndicator?: boolean;
  setShouldRefetchPredictions?: (shouldRefetchPredictions: boolean) => void;
}

const RefineSidebar: React.FunctionComponent<RefineSidebarProps> =
  function RefineSidebar({
    conceptId,
    conceptName,
    conceptVersionId,
    onClose,
    hasLoadingIndicator,
    setShouldRefetchPredictions,
  }) {
    const { rightSidebarOpen, setRightSidebarOpen } =
      useContext(SidebarContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { image, disabled, onLabelFun, view, setImage } =
      useContext(RefineSidebarContext);
    const imageRef = useRef(image);
    imageRef.current = image;
    const { data: imageMetadata } = useGetImageMetadata(image?.coactiveImageId);
    const { mutate: updateLabels, error: labelUpdateError } =
      useUpdateConceptVersionLabelsMutation();

    useEffect(() => {
      if (labelUpdateError) {
        toast.error('Unable to update label. Try again.');
      }
    }, [labelUpdateError]);

    const labelImage = useCallback(
      async (img: AssetResponse | undefined, label: LabelEnum) => {
        if (!img) return;
        const { coactiveImageId } = img;
        updateLabels(
          {
            conceptVersionId,
            updateConceptVersionLabelsRequest: {
              labels: [{ coactiveImageId, label }],
            },
          },
          {
            onSuccess: () => {
              onLabelFun?.(img.coactiveImageId, label);
              setShouldRefetchPredictions?.(true);
              // Be lazy and don't make another call to get the true value of the label
              if (imageRef.current?.coactiveImageId === img.coactiveImageId) {
                setImage({ ...img, label });
              }
            },
          },
        );
      },
      [conceptVersionId, onLabelFun],
    );

    const removeLabel = async () => {
      if (!image || !image.labelId) return;
      updateLabels(
        {
          conceptVersionId,
          updateConceptVersionLabelsRequest: {
            labels: [
              { coactiveImageId: image.coactiveImageId, label: undefined },
            ],
          },
        },
        {
          onSuccess: () => {
            onLabelFun?.(image.coactiveImageId, undefined);
            setRightSidebarOpen(false);
            setShouldRefetchPredictions?.(true);
            toast.success(<span>Successfully removed example</span>);
          },
          onError: () => {
            toast.error(<span>Example could not be removed. Try again.</span>);
          },
        },
      );
    };

    const isVideo = image?.assetType === AssetType.Keyframe && image.video?.url;
    const isImage = image?.assetType === AssetType.Image;

    const handlePreviewClick = () => {
      if (isImage) {
        setIsModalOpen(true);
      }
    };

    return (
      <>
        <FullScreenAssetModal
          asset={image!}
          open={isModalOpen}
          setOpen={setIsModalOpen}
        />
        <SidebarWrapper
          footer={
            <ClipboardIconButton
              color="text-gray-900"
              hoverColor="hover:gray-900"
              iconType="share"
              id="share"
              padding="p-0"
              size="large"
              text="test"
              tooltipText="Copy link to share"
            />
          }
          header={
            <IconButton
              alt="close-sidebar"
              Icon={XMarkIcon}
              onClick={() => (onClose ? onClose() : setRightSidebarOpen(false))}
              size="medium"
              tooltip="Close"
            />
          }
          rightSidebarOpen={rightSidebarOpen}
        >
          <>
            <div className="flex-shrink-0 px-4 ">
              {view === 'new-label' && rightSidebarOpen ? (
                <div className="flex justify-center items-center flex-col">
                  <h2 className="text-gray-800 text-xl font-semibold">
                    Does this image match {conceptName}?
                  </h2>
                  <p className="text-gray-600 text-base">
                    Coactive recommends images that will best train this
                    concept.
                  </p>
                </div>
              ) : (
                <div className="pt-2" />
              )}
            </div>
            <div className="mt-5 flex-1 overflow-y-auto pb-4">
              <div
                className={classNames(
                  'flex h-[25rem] flex-col items-center justify-center max-h-[40vh] bg-slate-100 relative',
                  {
                    'opacity-50': disabled,
                    'items-stretch cursor-default': isVideo,
                    'cursor-pointer': isImage,
                  },
                )}
                onClick={handlePreviewClick}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') handlePreviewClick();
                }}
                role="button"
                tabIndex={0}
              >
                <div className="absolute top-6 right-6 z-30">
                  <IconButton
                    alt="expand"
                    background="bg-slate-50 bg-opacity-75 hover:bg-slate-200"
                    onClick={() => setIsModalOpen(true)}
                    hoverColor="hover:bg-slate-200"
                    Icon={ArrowsPointingOutIcon}
                    rounded="full"
                    tooltip="Expand"
                  />
                </div>
                {hasLoadingIndicator ? (
                  <ClipLoader
                    color={colors.emerald['500']}
                    loading
                    size={40}
                    speedMultiplier={0.75}
                  />
                ) : (
                  <>
                    {isImage && (
                      <ProtectedImage
                        key={image.coactiveImageId}
                        url={image.previewImages?.regular?.url}
                        sensitive={image.sensitive}
                        coactiveImageId={image.coactiveImageId}
                        className="h-full w-auto max-h-[60vh] max-w-full !static"
                        alt="subject to label"
                        size={ImageSizeEnum.Regular}
                        placeholder="black"
                        objectFit="object-contain"
                      />
                    )}
                    {isVideo && (
                      <ProtectedVideo
                        key={image.coactiveImageId}
                        posterImageUrl={image.previewImages.regular.url}
                        url={image.video?.url || ''}
                        startTime={image.shot?.startTimeMs}
                        endTime={image.shot?.endTimeMs}
                        className="max-h-full"
                      />
                    )}
                  </>
                )}
              </div>
              <div className="py-4 flex flex-row justify-center gap-4 items-center bg-slate-100">
                {view === 'new-label' && (
                  <UpdateLabelsButtonControls
                    continueLabeling
                    disabled={disabled}
                    image={image!}
                    labelImage={labelImage}
                  />
                )}
                {view === 'update-label' && (
                  <UpdateLabelsButtonControls
                    image={image!}
                    labelImage={labelImage}
                    removeLabel={removeLabel}
                  />
                )}
              </div>
              <div className="pt-2 px-8 pb-0 text-sm">
                {imageMetadata && (
                  <MetadataSection
                    metadata={{ path: imageMetadata.path }}
                    singleField
                  />
                )}
                <ConceptClassificationScores
                  currentConceptId={conceptId}
                  imageId={image?.coactiveImageId || ''}
                />
                {view === 'update-label' && imageMetadata && (
                  <MetadataSection
                    title="Metadata"
                    metadata={{
                      coactive_image_id: imageMetadata.coactiveImageId,
                      ...imageMetadata.metadata,
                    }}
                    singleField
                  />
                )}
              </div>
            </div>
          </>
        </SidebarWrapper>
      </>
    );
  };

RefineSidebar.defaultProps = {
  hasLoadingIndicator: false,
  setShouldRefetchPredictions: undefined,
  onClose: undefined,
};

export default RefineSidebar;
