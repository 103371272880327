import ErrorText from 'components/ErrorText';
import ToolbarButton from 'pages/datasets/components/ToolbarButton';
import React from 'react';
import { MultiStepFlowAction } from 'types/action';

interface FooterToolbarProps {
  error?: string;
  loading?: boolean;
  next?: MultiStepFlowAction;
  previous?: MultiStepFlowAction;
}

const FooterToolbar = function FooterToolbar({
  error,
  loading,
  next,
  previous,
}: FooterToolbarProps) {
  return (
    <div className="border-t border-gray-200 mt-8 py-4">
      <div className="flex">
        {previous && (
          <ToolbarButton
            dataTestId={previous.dataTestId}
            label={previous.label}
            action={previous.action}
            loadingMessage={previous.loadingMessage}
            rightIcon={previous.rightIcon}
            leftIcon={previous.leftIcon}
            disabled={previous.disabled}
          />
        )}
        <div className="grow" />
        {next && (
          <ToolbarButton
            dataTestId={next.dataTestId}
            label={next.label}
            action={next.action}
            loadingMessage={next.loadingMessage}
            rightIcon={next.rightIcon}
            leftIcon={next.leftIcon}
            disabled={next.disabled}
            loading={loading}
          />
        )}
      </div>
      <div className="text-right">
        {error && <ErrorText>{error}</ErrorText>}
      </div>
    </div>
  );
};

FooterToolbar.defaultProps = {
  error: undefined,
  loading: undefined,
  next: undefined,
  previous: undefined,
};

export default FooterToolbar;
