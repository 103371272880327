import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button';
import React from 'react';

interface ErrorStateMessageProps {
  message: string;
  action: {
    icon: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>;
    label: string;
    execute: () => void;
  };
}

const ErrorStateMessage: React.FC<ErrorStateMessageProps> =
  function ErrorStateMessage({ message, action }) {
    const Icon = action.icon;
    return (
      <div className="text-gray-500 text-center my-8">
        <ExclamationCircleIcon className="h-20 mx-auto mb-6 text-gray-300" />
        <div className="text-lg mb-2 font-bold">
          Oops! Something went wrong.
        </div>
        <div className="text-sm font-medium mb-6">{message}</div>
        <Button
          buttonStyle="link"
          onClick={action.execute}
          className="text-sm font-medium"
        >
          <Icon className="h-5 w-5 mr-2" />
          {action.label}
        </Button>
      </div>
    );
  };

export default ErrorStateMessage;
