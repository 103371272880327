import React from 'react';
import classNames from 'classnames';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';

const Page403: React.FunctionComponent = function Page() {
  const navigate = useNavigate();

  return (
    <div className="min-h-full bg-white">
      <div className="bg-[length:100%_] lg:bg-[length:_60%] bg-[url('../assets/images/404image.jpeg')] h-[calc(100vh-5rem)] bg-no-repeat lg:bg-right bg-right-bottom">
        <main className="flex transition-all w-full pb-8 mx-auto px-2 sm:px-4 lg:px-4 mx-auto transition-all duration-300 h-full w-full max-w-8xl">
          <div
            className={classNames(
              'h-full flex flex-col pt-16 items-center text-center',
              'lg:justify-center lg:pt-0 lg:w-1/2 lg:items-start lg:text-left',
            )}
          >
            <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
              <span className="bg-white">
                You&apos;ve reached a page that you have insufficient
                permissions to visit
              </span>
            </h1>
            <p className="text-base py-8 sm:w-full">
              <span className="bg-white">
                If you think there has been a mistake, contact your account
                admin or contact Coactive at{' '}
                <a href="mailto:support@coactive.ai">support@coactive.ai</a>
              </span>
            </p>
            <Button onClick={() => navigate('/')}>Take Me Home</Button>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Page403;
