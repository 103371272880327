import ClipboardIconButton from 'components/ClipboardIconButton';
import { DateTime } from 'luxon';
import React from 'react';
import { isObjectEmpty } from 'utils/ObjectUtils';
import MetadataValue from './MetadataValue';

export enum MetadataFieldNames {
  asset_type = 'Asset type',
  coactive_image_id = 'Coactive image ID',
  created_dt = 'Created at',
  path = 'Source URL',
  updated_dt = 'Updated at',
  format = 'Format',
  coactive_video_id = 'Coactive video ID',
  coactive_shot_id = 'Coactive shot ID',
  keyframe_index = 'Keyframe index',
  keyframe_time_ms = 'Keyframe time (ms)',
  coactive_audio_segment_id = 'Coactive audio segment ID',
}

const MetadataSection: React.FunctionComponent<{
  keyOrder?: string[];
  metadata?: { [key: string]: any } | undefined;
  noClipboardIcon?: string[];
  singleField?: boolean;
  title?: string;
  visibleFields?: string[];
}> = function MetadataSection({
  keyOrder,
  metadata,
  noClipboardIcon,
  singleField,
  title,
  visibleFields,
}) {
  let metadataEntries = metadata && Object.entries(metadata);

  // Show metadata in correct order if 'keyOrder' prop is specified
  if (metadata && keyOrder) {
    metadataEntries = metadataEntries?.filter(([k]) => !keyOrder?.includes(k));
    [...(keyOrder ?? [])]?.reverse().forEach((key) => {
      if (metadata?.[key] !== undefined) {
        metadataEntries?.push([key, metadata[key]]);
      }
    });
    metadataEntries?.reverse();
  }

  const isVisibleField = (key: string, fields?: string[]) =>
    (fields && fields.includes(key)) || !fields;

  return metadata && !isObjectEmpty(metadata) ? (
    <div className="pt-4 max-w-full overflow-hidden break-words">
      {title && (
        <div className="text-gray-700 text-lg font-medium mb-4">{title}</div>
      )}
      <div className="pt-1 flex text-sm flex-wrap justify-between">
        {metadataEntries?.map(([key, value]: [key: string, value: any]) => {
          const parsedStr =
            typeof value === 'string' ? value : JSON.stringify(value);
          const parsedDate = /^\d+$/.test(parsedStr)
            ? undefined
            : DateTime.fromISO(parsedStr);
          const parsedValue = parsedDate?.isValid
            ? parsedDate.toLocaleString(DateTime.DATETIME_FULL)
            : parsedStr;
          const copyText = parsedDate?.isValid ? parsedStr : parsedValue;
          if (isVisibleField(key, visibleFields)) {
            return (
              <div
                className={`py-1 flex flex-col min-w-0 max-w-full mb-8 ${
                  singleField ? 'w-full' : 'w-1/2'
                }`}
                key={key}
              >
                <div className="mb-2">
                  <label
                    className="inline text-gray-500 font-medium"
                    htmlFor={`metadata-${key}`}
                  >
                    {MetadataFieldNames[key] || key}
                    {value != null && !noClipboardIcon?.includes(key) && (
                      <div className="inline-block align-bottom ml-1.5">
                        <ClipboardIconButton
                          color="text-slate-400"
                          iconType="duplicate"
                          id={key}
                          margin="ml-1"
                          padding="p-0"
                          size="small"
                          text={copyText}
                          tooltipText="Copy"
                        />
                      </div>
                    )}
                  </label>
                </div>
                <MetadataValue
                  value={value}
                  parsedValue={parsedValue}
                  key={key}
                  copyText={copyText}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  ) : (
    <div />
  );
};

MetadataSection.defaultProps = {
  keyOrder: undefined,
  metadata: undefined,
  noClipboardIcon: undefined,
  singleField: false,
  title: undefined,
  visibleFields: undefined,
};

export default MetadataSection;
