import React, { JSX, useContext, useMemo } from 'react';
import ImageGrid from 'components/ImageGrid';
import SidebarContext from 'context/SidebarContext';
import RefineSidebarContext from 'context/RefineSidebarContext';
import { AssetResponse } from 'api/generated';
import { LabeledAssetResponse } from 'types/image';

interface ExamplesGridProps {
  transitionKey?: string;
  images: AssetResponse[];
  onClick?: (image: AssetResponse) => void;
  fetchNextExamples: () => void;
  hasMore: boolean;
  tileFooter?: (img: AssetResponse | LabeledAssetResponse) => JSX.Element;
  dataTestId?: string;
}

const ExamplesGrid: React.FC<ExamplesGridProps> = function ExamplesGrid({
  images,
  onClick,
  fetchNextExamples,
  hasMore,
  tileFooter,
  dataTestId,
}) {
  const { rightSidebarOpen } = useContext(SidebarContext);
  const { image } = useContext(RefineSidebarContext);

  const grid = useMemo(
    () => (
      <ImageGrid
        images={images}
        type="uniform"
        onClick={onClick}
        fetchNextImages={fetchNextExamples}
        hasMore={hasMore}
        selected={image && rightSidebarOpen ? [image] : undefined}
        tileFooter={tileFooter}
        dataTestId={dataTestId}
      />
    ),
    [images, image, rightSidebarOpen, fetchNextExamples, hasMore, onClick],
  );
  return grid;
};

ExamplesGrid.defaultProps = {
  transitionKey: undefined,
  onClick: undefined,
  dataTestId: undefined,
};

export default ExamplesGrid;
