import { Combobox as HeadlessCombobox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import { ClipLoader } from 'react-spinners';
import colors from 'tailwindcss/colors';

export type ComboboxOption = {
  disabled?: boolean;
  label: string;
  ref?: React.RefObject<HTMLLIElement>;
  value: string;
  versionId?: string;
};

export type SelectedOption = {
  label: string;
  value: string;
};

interface ComboboxProps {
  actionButton?: ReactElement<any, any>;
  hasInput?: boolean;
  inputDisplayValue?: string;
  isInputValueLoading?: boolean;
  onInputChange?: (value: string) => void;
  onSelect: (selectedConcepts: SelectedOption[]) => void;
  onHoverOptionEnter?: (option?: ComboboxOption, idx?: number) => void;
  onHoverOptionLeave?: (option?: ComboboxOption, idx?: number) => void;
  options: ComboboxOption[];
  optionExtension?: ReactNode;
  selectedOptions?: SelectedOption[];
}

const Combobox: React.FC<ComboboxProps> = ({
  actionButton,
  hasInput,
  inputDisplayValue,
  isInputValueLoading,
  onHoverOptionEnter,
  onHoverOptionLeave,
  onInputChange,
  onSelect,
  options,
  optionExtension,
  selectedOptions,
}) => (
  <HeadlessCombobox
    as="div"
    onChange={(val) => onSelect(val)}
    value={selectedOptions}
    className="w-56"
  >
    <div className="relative mt-2" id="combobox-innerContainer">
      {hasInput && (
        <>
          <HeadlessCombobox.Input
            className="w-full rounded-md border-0 bg-slate-50 py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
            onChange={(e) => onInputChange?.(e.target.value)}
            displayValue={() => inputDisplayValue || ''}
            placeholder="Search concept"
          />
          {isInputValueLoading && (
            <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ClipLoader
                color={colors.green['500']}
                loading
                size={22}
                speedMultiplier={0.75}
              />
            </div>
          )}
        </>
      )}
      {options && options.length > 0 ? (
        <>
          <HeadlessCombobox.Options
            className="border border-solid border-grey-500 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            static
          >
            {options.map((option, idx) => {
              const isActive = selectedOptions?.find(
                (value) => value.value === option.value,
              );
              return (
                <HeadlessCombobox.Option
                  key={option.value}
                  value={option}
                  className={classNames(
                    ' select-none py-2 px-3 flex justify-between items-center',
                    option.disabled
                      ? 'text-slate-400 hover:bg-white'
                      : 'cursor-pointer',
                    isActive
                      ? 'bg-slate-100 hover:bg-slate-200'
                      : 'hover:bg-slate-100',
                  )}
                  onMouseEnter={() => onHoverOptionEnter?.(option, idx)}
                  onMouseLeave={() => onHoverOptionLeave?.(option, idx)}
                  ref={option.ref}
                >
                  <span className={classNames('block truncate')}>
                    {option.label}
                  </span>
                  {isActive && <CheckIcon className="text-gray-400 h-4 w-4" />}
                </HeadlessCombobox.Option>
              );
            })}
          </HeadlessCombobox.Options>
          {optionExtension}
        </>
      ) : (
        <div className="flex items-center py-8 flex-col justify-center gap-y-1 border border-solid border-grey-500 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <p>No results</p>
          {actionButton}
        </div>
      )}
    </div>
  </HeadlessCombobox>
);

Combobox.defaultProps = {
  actionButton: undefined,
  inputDisplayValue: '',
  hasInput: false,
  isInputValueLoading: false,
  onHoverOptionEnter: undefined,
  onHoverOptionLeave: undefined,
  onInputChange: undefined,
  optionExtension: undefined,
  selectedOptions: undefined,
};

export default Combobox;
