import React from 'react';
import { QueryResponseUI, QueryStatus } from 'api/generated';
import { useStopQuery } from 'pages/queries/queries';
import useDuration from 'hooks/useDuration';
import { Duration } from 'luxon';

interface QueryPendingProps {
  query: QueryResponseUI;
  queuedMessage?: string;
  stoppingMessage?: string;
  runningMessage?: (timeElapsed: Duration | undefined) => string;
  cancelSearchMessage?: string;
  stopSearchMessage?: string;
}

const QueryPending = function QueryPending({
  query,
  queuedMessage,
  stoppingMessage,
  runningMessage,
  cancelSearchMessage,
  stopSearchMessage,
}: QueryPendingProps) {
  const timeElapsed = useDuration(query?.startDt, query?.endDt);
  const stopMutation = useStopQuery();

  return (
    <div className="py-12 text-center">
      <div className="text-sm font-semibold">
        {query.status === QueryStatus.Running && runningMessage?.(timeElapsed)}
        {query.status === QueryStatus.Queued && queuedMessage}
        {query.status === QueryStatus.Stopping && stoppingMessage}
      </div>
      {timeElapsed && query.isActive && (
        <div className="mt-4 text-gray-600 text-sm">
          Time elapsed:{' '}
          <div className="inline-block min-w-[8.75rem]">
            {timeElapsed.minutes} minutes, {timeElapsed.seconds} seconds
          </div>
        </div>
      )}
      {query.isActive && query.status !== QueryStatus.Stopping && (
        <button
          type="button"
          className="text-sm mt-4 font-semibold text-red-600 hover:text-red-700 hover:text-red-700 hover:underline"
          onClick={() => stopMutation.mutate({ queryId: query.queryId })}
        >
          {query.status === QueryStatus.Running
            ? stopSearchMessage
            : cancelSearchMessage}
        </button>
      )}
    </div>
  );
};

QueryPending.defaultProps = {
  queuedMessage: 'Your query is queued.',
  stoppingMessage: 'Your query is stopping.',
  runningMessage: (timeElapsed: Duration | undefined) =>
    `Your query is ${
      timeElapsed && timeElapsed.seconds > 60 ? 'still ' : ''
    }executing.`,
  cancelSearchMessage: 'Cancel Query Execution',
  stopSearchMessage: 'Stop Query Execution',
};

export default QueryPending;
