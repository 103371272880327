/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The status of a dataset
 * 
 * incomplete - The user has not yet identified any data to add to the dataset
 * waiting - The dataset was recently created with at least one data source and has not yet
 *     started to initialize
 * initializing - The dataset is either being created or updated. The dataset will not be available
 *     to query until the status is 'ready'.
 * ready - The dataset has finished initializing or updating and is ready for use
 * error - An error occurred while creating or updating the dataset
 * deleting - The dataset is currently being deleted
 * deleted - The dataset was successfully deleted
 * @export
 */
export const DatasetStatusEnum = {
    Incomplete: 'Incomplete',
    Waiting: 'Waiting',
    Initializing: 'Initializing',
    Ready: 'Ready',
    Error: 'Error',
    Deleting: 'Deleting',
    Deleted: 'Deleted'
} as const;
export type DatasetStatusEnum = typeof DatasetStatusEnum[keyof typeof DatasetStatusEnum];


export function DatasetStatusEnumFromJSON(json: any): DatasetStatusEnum {
    return DatasetStatusEnumFromJSONTyped(json, false);
}

export function DatasetStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasetStatusEnum {
    return json as DatasetStatusEnum;
}

export function DatasetStatusEnumToJSON(value?: DatasetStatusEnum | null): any {
    return value as any;
}

