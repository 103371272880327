import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import CollapsibleSection from 'pages/datasets/components/CollapsibleSection';
import React, { useState } from 'react';
import FileUpload from 'components/FileUpload';
import AccessInstructionsList from 'pages/datasets/add-assets/views/AccessView/AccessInstructionsList';
import Checkbox from 'components/Checkbox';

interface ConfigureGsAccessProps {
  createDataset: () => void;
  createDatasetDisabled: boolean;
  publicAccessConfigured: boolean;
  setCredentialsId: (credentials: string) => void;
  setPublicAccessConfigured: (publicAccessConfigured: boolean) => void;
}

/**
 * The steps to configure access to an Google Storage data source.
 */
const ConfigureGsAccess = function ConfigureGsAccess({
  createDataset,
  createDatasetDisabled,
  publicAccessConfigured,
  setCredentialsId,
  setPublicAccessConfigured,
}: ConfigureGsAccessProps) {
  const [activeStepIdx, setActiveStepIdx] = useState(0);
  const [uploadErrorText, setUploadErrorText] = useState<string>();

  return (
    <div className="my-4 space-y-8">
      <CollapsibleSection
        stepNumber={1}
        title="Create a Service account"
        primaryAction={{ action: () => setActiveStepIdx((idx) => idx + 1) }}
        defaultOpen={activeStepIdx === 0}
        key={`0-${activeStepIdx === 0}`}
      >
        <AccessInstructionsList>
          <li>
            <a
              href="https://console.cloud.google.com/projectselector/iam-admin/serviceaccounts/create?walkthrough_id=iam--create-service-account&_ga=2.178097057.546815626.1697054381-1554467814.1696461771#step_index=1"
              className="underline text-green-600 font-semibold"
            >
              Create a Service account
            </a>{' '}
            in Google Cloud with the following details:
            <p>
              <span className="font-semibold">Service account name:</span>{' '}
              CoactiveReadAccess
            </p>
            <p>
              <span className="font-semibold">Service account ID:</span>{' '}
              coactivereadaccess
            </p>
          </li>
          <li>
            Select <span className="font-semibold">Done</span>, then copy the
            email address of your new account. Keep this tab open as you will
            need to return to this tab for Step 3.
          </li>
        </AccessInstructionsList>
      </CollapsibleSection>
      <CollapsibleSection
        stepNumber={2}
        title="Configure access"
        primaryAction={{ action: () => setActiveStepIdx((idx) => idx + 1) }}
        secondaryAction={{ action: () => setActiveStepIdx((idx) => idx - 1) }}
        defaultOpen={activeStepIdx === 1}
        key={`1-${activeStepIdx === 1}`}
      >
        <AccessInstructionsList>
          <li>
            Go to{' '}
            <a
              href="https://console.cloud.google.com/storage/browser"
              className="underline text-green-600 font-semibold"
            >
              Google Cloud Storage
            </a>{' '}
            and open the bucket you wish to import.
          </li>
          <li>
            Go to <span className="font-semibold">Permissions</span> &gt;{' '}
            <span className="font-semibold">Grant Access</span> and paste your
            account email in the{' '}
            <span className="font-semibold">New principals</span> field. Set the{' '}
            <span className="font-semibold">Role</span> to{' '}
            <span className="font-semibold">Storage Object Viewer</span>. Select{' '}
            <span className="font-semibold">Save</span>.
          </li>
        </AccessInstructionsList>
      </CollapsibleSection>
      <CollapsibleSection
        stepNumber={3}
        title="Upload the created user access key"
        description="Coactive will only use this key to ingest your data. We will never read from your bucket without explicit permission."
        primaryAction={{
          action: createDataset,
          label: 'Create dataset',
          rightIcon: CheckIcon,
          disabled: createDatasetDisabled,
        }}
        secondaryAction={{ action: () => setActiveStepIdx((idx) => idx - 1) }}
        defaultOpen={activeStepIdx === 2}
        key={`2-${activeStepIdx === 2}`}
      >
        <AccessInstructionsList>
          <li>
            Return to the{' '}
            <a
              href="https://console.cloud.google.com/iam-admin/serviceaccounts"
              className="underline text-green-600 font-semibold"
            >
              Services Accounts
            </a>{' '}
            tab from Step 1 and open your new Service account.
          </li>
          <li>
            Go to <span className="font-semibold">Keys</span> &gt;{' '}
            <span className="font-semibold">Add key</span> &gt;{' '}
            <span className="font-semibold">JSON</span> &gt;{' '}
            <span className="font-semibold">Create</span>. A CSV of your key
            will automatically download. Upload the key file below.
            <div className="mt-4">
              <FileUpload
                uploadUrl={`${process.env.REACT_APP_API_HOST}/api/ui/credentials/gcp`}
                onUploadSuccess={(upload) => {
                  setCredentialsId(upload[0].response.credentialsId);
                }}
                onUploadError={(upload) => {
                  setUploadErrorText(upload[0].response.detail);
                }}
                acceptedTypes={['text/json', 'application/json']}
                error={uploadErrorText}
                icon={PlusCircleIcon}
              >
                <p className="max-w-80 pointer-events-none text-md font-medium">
                  <span className="text-green-600">Upload JSON file</span> or
                  drag and drop
                </p>
              </FileUpload>
              <Checkbox
                id="public-access"
                name="public access configured"
                checked={publicAccessConfigured}
                onChange={(e) => setPublicAccessConfigured(e.target.checked)}
                label="I configured public access on this bucket (not recommended)"
              />
            </div>
          </li>
        </AccessInstructionsList>
      </CollapsibleSection>
    </div>
  );
};

export default ConfigureGsAccess;
