import { ErrorResponse } from 'api/generated';
import classNames from 'classnames';
import Dropzone from 'components/Dropzone';
import ErrorText from 'components/ErrorText';
import UploadProgress from 'components/UploadProgress';
import { DropzoneFile } from 'dropzone';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FileUploadStatus, getFileKey } from 'utils/FileUtils';

export interface CompleteUpload<T> {
  file: DropzoneFile; // The Dropzone file upload metadata
  response: T; // The response body of the upload endpoint
}

interface FileUploadProps<T> {
  uploadUrl: string;
  // Called once all uploads have completed successfully.
  onUploadSuccess: (responses: CompleteUpload<T>[]) => void;
  // Called if one or more uploads are unsuccessful with only the unsuccessful responses.
  onUploadError?: (errorResponses: CompleteUpload<ErrorResponse>[]) => void;
  onFileUploadStarted?: () => void;
  acceptedTypes: string[];
  illustrations?: { primary: string; secondary?: string }[];
  icon?: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>;
  error?: string;
  multiple?: boolean;
  maxFiles?: number;
}

const FileUpload: React.FunctionComponent<
  PropsWithChildren<FileUploadProps<any>>
> = function FileUpload<T>({
  uploadUrl,
  onUploadSuccess,
  onUploadError,
  onFileUploadStarted,
  acceptedTypes,
  illustrations,
  icon,
  error,
  children,
  multiple,
  maxFiles,
}: PropsWithChildren<FileUploadProps<T>>) {
  const [files, setFiles] = useState<File[]>([]);
  const [fileUploadStatus, setFileUploadStatus] = useState<FileUploadStatus>(
    {},
  );
  const [uploadResponses, setUploadResponses] = useState<CompleteUpload<T>[]>(
    [],
  );

  const animationsFinishedRef = useRef<string[]>([]);

  const checkIfUploadComplete = () => {
    const allAnimationsFinished = files.every((file) => {
      const key = getFileKey(file);
      return (
        animationsFinishedRef.current.includes(key) ||
        fileUploadStatus[key] === 'error'
      );
    });
    if (allAnimationsFinished && uploadResponses?.length) {
      if (uploadResponses?.every((r) => r.file.status === 'success')) {
        onUploadSuccess?.(uploadResponses);
      } else {
        const errorResponses = uploadResponses?.filter(
          (r) =>
            r.file.status === 'error' && (r.response as ErrorResponse).detail,
        ) as CompleteUpload<ErrorResponse>[];
        onUploadError?.(errorResponses);
      }
    }
  };

  const onFileSuccessResponse = useCallback(
    (file: DropzoneFile, response: any) => {
      setUploadResponses((rs) => [...rs, { file, response }]);
    },
    [],
  );

  const onFileErrorResponse = useCallback(
    (file: DropzoneFile, response: any) => {
      setUploadResponses((rs) => [...rs, { file, response }]);
    },
    [],
  );

  const onUploadProgressComplete = (key) => {
    animationsFinishedRef.current = [...animationsFinishedRef.current, key];
    checkIfUploadComplete();
  };
  useEffect(() => {
    checkIfUploadComplete();
  }, [files, uploadResponses, fileUploadStatus]);

  const firstIllustration = illustrations?.length
    ? illustrations[0]
    : undefined;
  const secondIllustration =
    illustrations && illustrations.length > 0 ? illustrations[1] : undefined;
  const numUploadedFiles = useMemo(
    () =>
      (files || [])?.filter(
        (file) => fileUploadStatus[getFileKey(file)] === 'success',
      ).length,
    [files, fileUploadStatus],
  );

  const Icon = icon;

  return (
    <div className="rounded-md flex flex-col h-full">
      <Dropzone
        multiple={multiple}
        id="csv-example-upload"
        files={files}
        setFiles={setFiles}
        fileUploadStatus={fileUploadStatus}
        setFileUploadStatus={setFileUploadStatus}
        className="flex-col grow w-full justify-center align-center bg-white pointer-cursor group"
        acceptedTypes={acceptedTypes}
        uploadUrl={uploadUrl}
        onFileSuccessResponse={onFileSuccessResponse}
        onFileErrorResponse={onFileErrorResponse}
        onFileUploadStarted={onFileUploadStarted}
        maxFiles={maxFiles}
      >
        {illustrations && (
          <div className="flex space-around text-center pointer-events-none mx-auto items-center p-8">
            {firstIllustration && (
              <div className="relative w-40">
                {firstIllustration?.secondary && (
                  <img
                    src={firstIllustration?.secondary}
                    alt=""
                    aria-hidden="true"
                    className="w-40 top-0 group-hover:-rotate-6 group-hover:scale-105 origin-bottom transition-all"
                  />
                )}
                <img
                  src={firstIllustration?.primary}
                  alt=""
                  aria-hidden="true"
                  className={classNames(
                    'w-40 top-0 group-hover:scale-105 origin-bottom transition-all',
                    firstIllustration?.secondary &&
                      'group-hover:rotate-6 absolute',
                  )}
                />
              </div>
            )}
            {secondIllustration && (
              <>
                <div className="text-gray-300 group-hover:scale-105 transition-all text-md font-bold h-full px-12">
                  OR
                </div>
                <div className="relative w-40">
                  {secondIllustration.secondary && (
                    <img
                      src={secondIllustration.secondary}
                      alt=""
                      aria-hidden="true"
                      className="w-40 top-0 group-hover:rotate-6 group-hover:scale-105 origin-bottom transition-all"
                    />
                  )}
                  <img
                    src={secondIllustration.primary}
                    alt=""
                    aria-hidden="true"
                    className={classNames(
                      'w-40 absolute top-0 group-hover:scale-105 origin-bottom transition-all',
                      secondIllustration.secondary && 'group-hover:-rotate-6',
                    )}
                  />
                </div>
              </>
            )}
          </div>
        )}
        {Icon ? (
          <div className="flex justify-center pb-4 pointer-events-none">
            <Icon className="h-6 w-6" />
          </div>
        ) : undefined}
        {children}
      </Dropzone>
      {Boolean(files?.length) && (
        <div>
          <p
            className={classNames('font-semibold mb-4 text-sm uppercase mt-8')}
          >
            Uploaded Files {numUploadedFiles}/{files.length}
          </p>
          {files?.map((file: File) => {
            const key = getFileKey(file);
            const status = fileUploadStatus[key];
            const progress = typeof status === 'number' ? status : 0;
            return (
              <div className="mt-2" key={key}>
                <UploadProgress
                  file={file}
                  progress={progress}
                  error={fileUploadStatus[getFileKey(file)] === 'error'}
                  success={fileUploadStatus[getFileKey(file)] === 'success'}
                  onCancel={() => undefined}
                  onPause={() => undefined}
                  started // {uploadsInitiated}
                  onComplete={() => onUploadProgressComplete(key)}
                />
              </div>
            );
          })}
        </div>
      )}
      <ErrorText errorStyle="form">{error}</ErrorText>
    </div>
  );
};

FileUpload.defaultProps = {
  illustrations: undefined,
  icon: undefined,
  onUploadError: undefined,
  onFileUploadStarted: undefined,
  error: undefined,
  multiple: false,
  maxFiles: 1,
};

export default FileUpload;
