import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import React from 'react';
import EmptyState from 'components/EmptyState';

export const EmptyErrorState = function EmptyErrorState({
  itemWithError,
}: {
  itemWithError: string;
}) {
  return (
    <EmptyState
      Icon={ExclamationCircleIcon}
      iconClassName="h-6 w-6 text-red-400"
      primaryText={`Unable to show ${itemWithError}`}
      message="Please refresh the page and try again."
    />
  );
};
