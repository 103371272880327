import {
  AssetType,
  DatasetApi,
  ExecuteNoCodeQueryRequest,
  GetImagesWithMultimodalSearchRequest,
  MetadataPreviewResponse,
  QueryApi,
  SimilaritySearchApi,
} from 'api/generated';
import { SearchType } from 'api/generated/models/SearchType';
import { useAPIContext } from 'context/APIContext';
import useInfiniteQuery from 'hooks/useInfiniteQuery';
import useMutation from 'hooks/useMutation';
import {
  Creator,
  useGetQueryHistoryInfiniteQueryInfiniteQueryKey,
} from 'pages/queries/queries';
import { useQueryClient } from 'react-query';
import toNumber from 'utils/ToNumber';

export const getMetadataPreviewInfiniteQueryKey = (
  uploadPath: string | undefined,
) => ['getDatasetMetadataPreview', uploadPath];

const EXAMPLES_PAGE_SIZE = 50;
const SEARCH_RESULTS_LENGTH = 100;

export const useGetMetadataPreviewInfiniteQuery = (
  datasetId: string,
  uploadPath: string,
  initialData?: MetadataPreviewResponse,
) => {
  const { initializeAPI } = useAPIContext();
  const query = useInfiniteQuery(
    getMetadataPreviewInfiniteQueryKey(uploadPath),
    async ({ signal, pageParam }) => {
      const api = await initializeAPI<DatasetApi>(DatasetApi);

      const requestParams = {
        datasetId,
        offset: EXAMPLES_PAGE_SIZE * toNumber(pageParam || 0),
        limit: EXAMPLES_PAGE_SIZE,
        metadataCsvPreviewRequest: { csvPath: uploadPath },
      };
      return api.getMetadataPreview(requestParams, { signal });
    },
    {
      initialData: { pages: [initialData], pageParams: [1] },
      enabled: false,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage?.meta?.page?.currentPage,
    },
  );
  return query;
};

export const getSearchImagesQueryKey = (datasetId: string | undefined) => [
  'getImagesWithMultimodalSearch',
  datasetId,
];

export const useSearchImagesQuery = (
  query: string | null,
  datasetId: string,
  searchType: SearchType,
  assetType: AssetType | undefined,
  conceptIds?: string[],
  metadataFilters?: string,
  enabled?: boolean,
  uniqueKey?: string,
) => {
  const { initializeAPI } = useAPIContext();
  const infiniteQuery = useInfiniteQuery(
    getSearchImagesQueryKey(`${datasetId}${uniqueKey || ''}`),
    async ({ signal }) => {
      const api = await initializeAPI<SimilaritySearchApi>(SimilaritySearchApi);

      const requestParams = {
        query,
        datasetId,
        limit: SEARCH_RESULTS_LENGTH,
        searchType,
        conceptIds,
        metadataFilters,
        assetType:
          searchType === 'audio' ? undefined : assetType?.toLowerCase(),
      } as GetImagesWithMultimodalSearchRequest;
      return api.getImagesWithMultimodalSearch(requestParams, { signal });
    },
    {
      enabled: enabled || false,
      cacheTime: 0,
    },
  );
  return infiniteQuery;
};

export const useRunNoCodeQuery = () => {
  const queryClient = useQueryClient();
  const { initializeAPI } = useAPIContext();
  return useMutation(
    ['executeNoCodeQuery'],
    async (variables: ExecuteNoCodeQueryRequest) => {
      const api = await initializeAPI<QueryApi>(QueryApi);
      return api.executeNoCodeQuery(variables);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          useGetQueryHistoryInfiniteQueryInfiniteQueryKey(Creator.Me),
        );
      },
      onError: () => {
        /* TODO COA-37 */
      },
    },
  );
};
