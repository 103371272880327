import classNames from 'classnames';
import React from 'react';

import Tooltip from 'components/Tooltip';
import { v4 as uuidv4 } from 'uuid';

interface IconButtonProps {
  alt?: string;
  background?: string;
  disabled?: boolean;
  fill?: string;
  hoverColor?: string;
  Icon: any;
  onClick: () => void;
  rounded?: 'full';
  size?: 'small' | 'medium';
  tooltip?: string;
  className?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  onClick,
  alt,
  background,
  className,
  disabled,
  fill,
  hoverColor,
  Icon,
  rounded,
  size,
  tooltip,
}) => {
  const tooltipId = uuidv4();
  return (
    <>
      <button
        disabled={disabled}
        type="button"
        className={classNames(
          'ml-1 flex items-center justify-center  h-10 w-10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white',
          rounded === 'full' ? 'rounded-full' : 'rounded-md',
          disabled
            ? 'disabled:pointer-events-none'
            : hoverColor || 'hover:bg-slate-50',
          // TODO - Rework how className props work to allow more custom size
          // and update the implementation of this component across the platform to reflect changes if needed
          size === 'small' ? 'h-8 w-8' : 'h-6 w-6',
          disabled ? 'cursor-default' : 'cursor-pointer',
          disabled && !background ? 'bg-slate-50' : background || '',
          className,
        )}
        onClick={onClick}
        data-tooltip-id={`iconButton-${tooltipId}` || ''}
        data-tooltip-content={tooltip || ''}
        data-tooltip-place="top"
      >
        <span className="sr-only">{alt}</span>
        <Icon
          className={classNames(
            disabled && !fill ? 'text-gray-300' : fill || 'text-gray-900',
            size === 'small' ? 'h-5 w-5' : 'h-6 w-6',
          )}
          aria-hidden="true"
        />
      </button>
      {tooltip && (
        <Tooltip id={`iconButton-${tooltipId}`}>
          <span>{tooltip}</span>
        </Tooltip>
      )}
    </>
  );
};

IconButton.defaultProps = {
  alt: 'icon-button',
  background: '',
  className: undefined,
  disabled: false,
  fill: 'fill-gray-900',
  hoverColor: undefined,
  rounded: undefined,
  size: 'small',
  tooltip: undefined,
};

export default IconButton;
