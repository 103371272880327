import {
  LabelApi,
  UpdateConceptVersionLabelsOperationRequest,
} from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import { useRef } from 'react';

export const getCandidatesQueryKey = (conceptVersionId: string) => [
  'getLabelCandidatesForConceptVersionId',
  conceptVersionId,
];

export const useGetCandidatesQuery = (
  conceptVersionId: string,
  limit?: number,
) => {
  const { initializeAPI } = useAPIContext();
  const sig = useRef<AbortSignal | undefined>();
  return {
    signal: sig,
    ...useQuery(
      getCandidatesQueryKey(conceptVersionId),
      async ({ queryKey: [, _conceptVersionId], signal }) => {
        const api = await initializeAPI<LabelApi>(LabelApi);
        sig.current = signal;
        return api.getLabelCandidatesForConceptVersionId(
          {
            conceptVersionId: _conceptVersionId,
            limit,
          },
          { signal },
        );
      },
      {
        enabled: false,
        cacheTime: 0,
      },
    ),
  };
};

export const useUpdateLabelMutation = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    ['updateConceptVersionLabels'],
    async (variables: UpdateConceptVersionLabelsOperationRequest) => {
      const api = await initializeAPI<LabelApi>(LabelApi);
      return api.updateConceptVersionLabels(variables);
    },
  );
};
