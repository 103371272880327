/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppSharedV0SchemaPagedMetaResponse,
    AppSharedV0SchemaPagedMetaResponseFromJSON,
    AppSharedV0SchemaPagedMetaResponseFromJSONTyped,
    AppSharedV0SchemaPagedMetaResponseToJSON,
} from './AppSharedV0SchemaPagedMetaResponse';
import {
    FullConceptVersionLabelResponse,
    FullConceptVersionLabelResponseFromJSON,
    FullConceptVersionLabelResponseFromJSONTyped,
    FullConceptVersionLabelResponseToJSON,
} from './FullConceptVersionLabelResponse';

/**
 * A paginated list of labels
 * @export
 * @interface PagedConceptVersionLabelsResponse
 */
export interface PagedConceptVersionLabelsResponse {
    /**
     * 
     * @type {AppSharedV0SchemaPagedMetaResponse}
     * @memberof PagedConceptVersionLabelsResponse
     */
    meta: AppSharedV0SchemaPagedMetaResponse;
    /**
     * The paginated data
     * @type {Array<FullConceptVersionLabelResponse>}
     * @memberof PagedConceptVersionLabelsResponse
     */
    data: Array<FullConceptVersionLabelResponse>;
}

export function PagedConceptVersionLabelsResponseFromJSON(json: any): PagedConceptVersionLabelsResponse {
    return PagedConceptVersionLabelsResponseFromJSONTyped(json, false);
}

export function PagedConceptVersionLabelsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedConceptVersionLabelsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': AppSharedV0SchemaPagedMetaResponseFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(FullConceptVersionLabelResponseFromJSON)),
    };
}

export function PagedConceptVersionLabelsResponseToJSON(value?: PagedConceptVersionLabelsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': AppSharedV0SchemaPagedMetaResponseToJSON(value.meta),
        'data': ((value.data as Array<any>).map(FullConceptVersionLabelResponseToJSON)),
    };
}

