import {
  ConceptApi,
  CreateConceptByNamespaceIdFromCsvRequest,
  NamespaceApi,
} from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';

export const getNamespacesQueryKey = (embeddingId: string) => [
  'getNamespacesByEmbeddingId',
  embeddingId,
];
export const useGetNamespacesQuery = (embeddingId: string) => {
  const { initializeAPI } = useAPIContext();
  const query = useQuery(
    getNamespacesQueryKey(embeddingId),
    async ({ queryKey, signal }) => {
      const [, _embeddingId] = queryKey;
      const api = await initializeAPI<NamespaceApi>(NamespaceApi);
      return api.getNamespacesByEmbeddingId(
        { embeddingId: _embeddingId },
        { signal },
      );
    },
  );
  return query;
};

export const useCreateConceptsFromCsvMutation = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    ['createConceptByNamespaceIdFromCsv'],
    async (variables: CreateConceptByNamespaceIdFromCsvRequest) => {
      const api = await initializeAPI<ConceptApi>(ConceptApi);
      return api.createConceptByNamespaceIdFromCsv(variables);
    },
  );
};
