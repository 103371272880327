import {
  ArrowDownTrayIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/24/solid';
import { AssetType, ImageSizeEnum } from 'api/generated';
import classNames from 'classnames';
import Button from 'components/Button';
import ClipboardIconButton from 'components/ClipboardIconButton';
import FullScreenAssetModal from 'components/FullScreenAssetModal';
import FullScreenLoadingPage from 'components/FullScreenLoadingPage';
import IconButton from 'components/IconButton';
import ProtectedImage from 'components/Image/ProtectedImage';
import MetadataSection from 'components/Metadata/MetadataSection';
import { useGetImageMetadata } from 'components/Sidebar/AssetDetailSidebar/queries';
import ProtectedVideo from 'components/video/ProtectedVideo';
import { useUserContext } from 'context/UserContext';
import EditConceptVersionLabels from 'pages/queries/EditConceptVersionLabels/EditConceptVersionLabels';
import { useGetDatasetById } from 'queries/datasets';
import { useGetImageDownloadUrl } from 'queries/download-url';
import { useGetUserById } from 'queries/users';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { downloadAssetWithUrl } from 'utils/DownloadAsset';
import SimilarImagesGrid from '../SimilarImagesGrid';

const AssetDetail: React.FunctionComponent = function Page() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { datasetId, imageId, videoId } = useParams();
  const { data, isLoading } = useGetImageMetadata(imageId || videoId);
  const { data: datasetData } = useGetDatasetById(datasetId);
  const { isTrialUser } = useUserContext();
  const currentPath = window.location.href;
  const { data: createdBy } = useGetUserById(datasetData?.createdUserId);
  const shouldFetchDownloadUrl = data?.coactiveImageId != null;
  const { data: downloadUrl } = useGetImageDownloadUrl(
    data?.coactiveImageId!,
    shouldFetchDownloadUrl,
  );

  const defaultMetadata = useMemo(() => {
    if (!data) {
      return undefined;
    }
    const ret: { [key: string]: string | undefined } = { ...data?.metadata };
    if (data?.coactiveImageId) {
      ret.coactive_image_id = data.coactiveImageId;
    }
    if (data?.createdDt) {
      ret.created_dt = data.createdDt;
    }
    if (data?.updatedDt) {
      ret.updated_dt = data.updatedDt;
    }
    return ret;
  }, [data]);

  const pathMetadata = {
    path: data?.path,
  };

  const isImage = data?.assetType === AssetType.Image;
  const isVideo = data?.assetType === AssetType.Keyframe;

  const handlePreviewClick = () => {
    if (!isImage) return;
    setIsModalOpen(true);
  };

  return (
    <>
      {data && (
        <FullScreenAssetModal
          asset={data}
          open={isModalOpen}
          setOpen={setIsModalOpen}
        />
      )}
      {isLoading ? (
        <FullScreenLoadingPage color="white" />
      ) : (
        <div className="flex relative h-[calc(100vh-4rem)] bg-white">
          <div
            className={classNames(
              'w-1/2 bg-slate-100 flex items-center relative',
              isImage ? 'cursor-pointer' : 'cursor-default',
            )}
            onClick={handlePreviewClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') handlePreviewClick();
            }}
            role="button"
            tabIndex={0}
          >
            <div className="absolute top-6 right-6">
              <IconButton
                alt="expand"
                background="bg-slate-50 bg-opacity-75"
                onClick={() => setIsModalOpen(true)}
                hoverColor="hover:bg-slate-200"
                Icon={ArrowsPointingOutIcon}
                rounded="full"
                tooltip="Expand"
              />
            </div>
            {isImage && data.previewImages?.regular?.url && (
              <ProtectedImage
                url={data.previewImages?.regular?.url}
                sensitive={data.sensitive}
                className="h-full w-full !static min-h-64"
                size={ImageSizeEnum.Regular}
                coactiveImageId={data.coactiveImageId}
                placeholder="black"
                objectFit="object-contain"
                preventHeightJump
              />
            )}
            {isVideo && data.previewImages && data.video?.url && (
              <ProtectedVideo
                posterImageUrl={data.previewImages.regular.url}
                url={data.video.url}
                className="max-h-full"
              />
            )}
          </div>
          <div className="w-1/2 pl-8 pr-0  pb-16 relative">
            <div className="h-full overflow-y-auto pr-8 max-h-full">
              <div className="mt-8">
                <p className=" text-gray-500 font-medium mb-2 text-sm">
                  Dataset
                </p>
                <Button
                  onClick={() => navigate(`/datasets/${datasetId}`)}
                  buttonStyle="badge"
                  padding="py-0.5 px-3"
                  textSize="text-lg"
                >
                  {datasetData?.name}
                </Button>
              </div>
              <MetadataSection metadata={pathMetadata} singleField />
              {isImage &&
                datasetId &&
                data?.coactiveImageId &&
                !isTrialUser && (
                  <EditConceptVersionLabels
                    datasetId={datasetId}
                    coactiveImageId={data.coactiveImageId}
                  />
                )}
              <MetadataSection title="Metadata" metadata={defaultMetadata} />
              <div className="text-sm mb-8">
                <p className="text-gray-500 font-medium mb-2">Created by</p>
                <p>{createdBy?.displayName}</p>
              </div>
              {isImage && datasetId && (
                <SimilarImagesGrid
                  datasetId={datasetId}
                  imageId={imageId}
                  onClick={(asset) =>
                    navigate(
                      `/datasets/${datasetId}/images/${asset.coactiveImageId}`,
                    )
                  }
                />
              )}
            </div>
            <div className="border-t border-slate-200 absolute bottom-0 left-0 w-full h-16 flex gap-x-2 items-center px-8">
              <IconButton
                onClick={() =>
                  downloadAssetWithUrl(
                    downloadUrl?.downloadUrl as string,
                    `${data?.coactiveImageId}${data?.format}`,
                  )
                }
                Icon={ArrowDownTrayIcon}
                tooltip="Download"
              />
              <ClipboardIconButton
                color="text-gray-900"
                hoverColor="hover:gray-900"
                iconType="share"
                id="share"
                margin="ml-1"
                padding="p-0"
                size="small"
                text={currentPath}
                tooltipText="Share"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AssetDetail;
