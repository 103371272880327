/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateOrgInvitationRequest,
  HTTPValidationError,
  OrgInvitationsTableResponse,
  SDKCredentialResponse,
  UpdateUserRoleRequest,
  UserTableResponse,
} from '../models';
import {
    CreateOrgInvitationRequestFromJSON,
    CreateOrgInvitationRequestToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    OrgInvitationsTableResponseFromJSON,
    OrgInvitationsTableResponseToJSON,
    SDKCredentialResponseFromJSON,
    SDKCredentialResponseToJSON,
    UpdateUserRoleRequestFromJSON,
    UpdateUserRoleRequestToJSON,
    UserTableResponseFromJSON,
    UserTableResponseToJSON,
} from '../models';

export interface CreateOrgInvitationOperationRequest {
    createOrgInvitationRequest: CreateOrgInvitationRequest;
}

export interface DeleteOrgInvitationRequest {
    invitationId: string | null;
}

export interface DeleteOrgMemberRequest {
    userId: string;
}

export interface DemoteOrgMemberRequest {
    userId: string;
}

export interface GetOrgInvitationsRequest {
    offset?: number | null;
    limit?: number | null;
}

export interface GetOrgMembersRequest {
    offset?: number | null;
    limit?: number | null;
}

export interface UpgradeOrgMemberRequest {
    updateUserRoleRequest: UpdateUserRoleRequest;
}

/**
 * AuthApi - interface
 * 
 * @export
 * @interface AuthApiInterface
 */
export interface AuthApiInterface {
    /**
     * Creates an invitation to join the current organization
     * @summary Creates an invitation to join the current organization
     * @param {CreateOrgInvitationRequest} createOrgInvitationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    createOrgInvitationRaw(requestParameters: CreateOrgInvitationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Creates an invitation to join the current organization
     * Creates an invitation to join the current organization
     */
    createOrgInvitation(requestParameters: CreateOrgInvitationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Gets or creates SDK credentials
     * @summary Gets or creates SDK credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    createSdkCredentialsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SDKCredentialResponse>>;

    /**
     * Gets or creates SDK credentials
     * Gets or creates SDK credentials
     */
    createSdkCredentials(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SDKCredentialResponse>;

    /**
     * Delete a pending org invitation
     * @summary Delete a pending org invitation
     * @param {string} invitationId The invitation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    deleteOrgInvitationRaw(requestParameters: DeleteOrgInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Delete a pending org invitation
     * Delete a pending org invitation
     */
    deleteOrgInvitation(requestParameters: DeleteOrgInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Delete a member from an organization
     * @summary Delete a member from an organization
     * @param {string} userId User id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    deleteOrgMemberRaw(requestParameters: DeleteOrgMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Delete a member from an organization
     * Delete a member from an organization
     */
    deleteOrgMember(requestParameters: DeleteOrgMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Remove admin capabilities from a user in an organization
     * @summary Remove admin capabilities from a user in an organization
     * @param {string} userId User id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    demoteOrgMemberRaw(requestParameters: DemoteOrgMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Remove admin capabilities from a user in an organization
     * Remove admin capabilities from a user in an organization
     */
    demoteOrgMember(requestParameters: DemoteOrgMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Get all pending org invitations
     * @summary Get all pending org invitations
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    getOrgInvitationsRaw(requestParameters: GetOrgInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgInvitationsTableResponse>>;

    /**
     * Get all pending org invitations
     * Get all pending org invitations
     */
    getOrgInvitations(requestParameters: GetOrgInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgInvitationsTableResponse>;

    /**
     * Get members for the current org
     * @summary Get all org members
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    getOrgMembersRaw(requestParameters: GetOrgMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserTableResponse>>;

    /**
     * Get members for the current org
     * Get all org members
     */
    getOrgMembers(requestParameters: GetOrgMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserTableResponse>;

    /**
     * Get already created SDK credentials
     * @summary Get already created SDK credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    getSdkCredentialsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SDKCredentialResponse>>;

    /**
     * Get already created SDK credentials
     * Get already created SDK credentials
     */
    getSdkCredentials(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SDKCredentialResponse>;

    /**
     * Log out of the Coactive app
     * @summary Log out of the Coactive app
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    logoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Log out of the Coactive app
     * Log out of the Coactive app
     */
    logout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Give a user in an organization the permission to create/upgrade another user
     * @summary Give a user in an organization admin capabilities
     * @param {UpdateUserRoleRequest} updateUserRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    upgradeOrgMemberRaw(requestParameters: UpgradeOrgMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Give a user in an organization the permission to create/upgrade another user
     * Give a user in an organization admin capabilities
     */
    upgradeOrgMember(requestParameters: UpgradeOrgMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI implements AuthApiInterface {

    /**
     * Creates an invitation to join the current organization
     * Creates an invitation to join the current organization
     */
    async createOrgInvitationRaw(requestParameters: CreateOrgInvitationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.createOrgInvitationRequest === null || requestParameters.createOrgInvitationRequest === undefined) {
            throw new runtime.RequiredError('createOrgInvitationRequest','Required parameter requestParameters.createOrgInvitationRequest was null or undefined when calling createOrgInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/auth/org/invitations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrgInvitationRequestToJSON(requestParameters.createOrgInvitationRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates an invitation to join the current organization
     * Creates an invitation to join the current organization
     */
    async createOrgInvitation(requestParameters: CreateOrgInvitationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createOrgInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets or creates SDK credentials
     * Gets or creates SDK credentials
     */
    async createSdkCredentialsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SDKCredentialResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/auth/sdk/credentials`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SDKCredentialResponseFromJSON(jsonValue));
    }

    /**
     * Gets or creates SDK credentials
     * Gets or creates SDK credentials
     */
    async createSdkCredentials(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SDKCredentialResponse> {
        const response = await this.createSdkCredentialsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Delete a pending org invitation
     * Delete a pending org invitation
     */
    async deleteOrgInvitationRaw(requestParameters: DeleteOrgInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling deleteOrgInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/auth/org/invitations/{invitation_id}`.replace(`{${"invitation_id"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete a pending org invitation
     * Delete a pending org invitation
     */
    async deleteOrgInvitation(requestParameters: DeleteOrgInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteOrgInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a member from an organization
     * Delete a member from an organization
     */
    async deleteOrgMemberRaw(requestParameters: DeleteOrgMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteOrgMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/auth/org/members/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete a member from an organization
     * Delete a member from an organization
     */
    async deleteOrgMember(requestParameters: DeleteOrgMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteOrgMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove admin capabilities from a user in an organization
     * Remove admin capabilities from a user in an organization
     */
    async demoteOrgMemberRaw(requestParameters: DemoteOrgMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling demoteOrgMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/auth/org/users/admins/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Remove admin capabilities from a user in an organization
     * Remove admin capabilities from a user in an organization
     */
    async demoteOrgMember(requestParameters: DemoteOrgMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.demoteOrgMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all pending org invitations
     * Get all pending org invitations
     */
    async getOrgInvitationsRaw(requestParameters: GetOrgInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgInvitationsTableResponse>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/auth/org/invitations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgInvitationsTableResponseFromJSON(jsonValue));
    }

    /**
     * Get all pending org invitations
     * Get all pending org invitations
     */
    async getOrgInvitations(requestParameters: GetOrgInvitationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgInvitationsTableResponse> {
        const response = await this.getOrgInvitationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get members for the current org
     * Get all org members
     */
    async getOrgMembersRaw(requestParameters: GetOrgMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserTableResponse>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/auth/org/members`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserTableResponseFromJSON(jsonValue));
    }

    /**
     * Get members for the current org
     * Get all org members
     */
    async getOrgMembers(requestParameters: GetOrgMembersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserTableResponse> {
        const response = await this.getOrgMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get already created SDK credentials
     * Get already created SDK credentials
     */
    async getSdkCredentialsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SDKCredentialResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/auth/sdk/credentials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SDKCredentialResponseFromJSON(jsonValue));
    }

    /**
     * Get already created SDK credentials
     * Get already created SDK credentials
     */
    async getSdkCredentials(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SDKCredentialResponse> {
        const response = await this.getSdkCredentialsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Log out of the Coactive app
     * Log out of the Coactive app
     */
    async logoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ui/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Log out of the Coactive app
     * Log out of the Coactive app
     */
    async logout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.logoutRaw(initOverrides);
        return await response.value();
    }

    /**
     * Give a user in an organization the permission to create/upgrade another user
     * Give a user in an organization admin capabilities
     */
    async upgradeOrgMemberRaw(requestParameters: UpgradeOrgMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.updateUserRoleRequest === null || requestParameters.updateUserRoleRequest === undefined) {
            throw new runtime.RequiredError('updateUserRoleRequest','Required parameter requestParameters.updateUserRoleRequest was null or undefined when calling upgradeOrgMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/auth/org/users/admins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRoleRequestToJSON(requestParameters.updateUserRoleRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Give a user in an organization the permission to create/upgrade another user
     * Give a user in an organization admin capabilities
     */
    async upgradeOrgMember(requestParameters: UpgradeOrgMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.upgradeOrgMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
