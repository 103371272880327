import Button from 'components/Button';
import Dialog from 'components/Dialog';
import TextInput from 'components/TextInput';
import React, { ChangeEvent, Fragment, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { useCreateOrgInvitationMutation } from './queries';

const CreateUserButton: React.FC<{ refetchInvitations: () => void }> = ({
  refetchInvitations,
}) => {
  const getDefaultUserData = useCallback(
    () => ({
      email: '',
      givenName: '',
      familyName: '',
      name: '',
      connection: 'Username-Password-Authentication',
      password: uuidv4(),
    }),
    [],
  );
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newUserData, setNewUserData] = useState(getDefaultUserData());
  const { mutate: inviteUser, isLoading } = useCreateOrgInvitationMutation();
  const formDisabled = isLoading;

  const onChange = useCallback(
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setNewUserData({ ...newUserData, [field]: e.target.value });
    },
    [newUserData, setNewUserData],
  );

  const validateEmail = useCallback((value) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const valid = regex.test(value);
    return Promise.resolve({
      valid: {
        value: valid,
        message: valid ? undefined : 'The provided email is not a valid email',
      },
      unique: { value: true },
    });
  }, []);

  const submit = useCallback(async () => {
    inviteUser(
      {
        createOrgInvitationRequest: {
          givenName: newUserData.givenName,
          familyName: newUserData.familyName,
          email: newUserData.email,
        },
      },
      {
        onSuccess: () => {
          setNewUserData(getDefaultUserData());
          refetchInvitations();
          setOpen(false);
          toast.success(`Invitation sent to ${newUserData.email}`);
        },
        onError: async (error: any) => {
          setErrorMessage((await error.response.json()).detail);
        },
      },
    );
  }, [inviteUser, newUserData, setNewUserData]);

  return (
    <Fragment>
      <Button onClick={() => setOpen(true)}>Create User</Button>
      <Dialog
        title="Create new user"
        open={open}
        setOpen={setOpen}
        actions={
          <Button
            disabled={
              !newUserData.email ||
              !newUserData.familyName ||
              !newUserData.givenName ||
              formDisabled
            }
            onClick={submit}
            loading={isLoading}
          >
            Create
          </Button>
        }
        error={errorMessage}
      >
        <Fragment>
          <div className="grid grid-cols-2 gap-4 mb-2">
            <TextInput
              id="firstName"
              name="firstName"
              label="First name"
              placeholder="First name"
              value={newUserData.givenName}
              disabled={formDisabled}
              onChange={onChange('givenName')}
            />
            <TextInput
              id="lastName"
              name="lastName"
              label="Last name"
              placeholder="Last name"
              value={newUserData.familyName}
              disabled={formDisabled}
              onChange={onChange('familyName')}
            />
          </div>
          <TextInput
            id="email"
            name="email"
            label="Email"
            placeholder="Email"
            value={newUserData.email}
            disabled={formDisabled}
            onChange={onChange('email')}
            validation={validateEmail}
          />
        </Fragment>
      </Dialog>
    </Fragment>
  );
};

export default CreateUserButton;
