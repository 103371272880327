/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DataSourceTypeEnum,
    DataSourceTypeEnumFromJSON,
    DataSourceTypeEnumFromJSONTyped,
    DataSourceTypeEnumToJSON,
} from './DataSourceTypeEnum';

/**
 * The base model for any single resource HTTP response
 * @export
 * @interface DatasetUpdateResponse
 */
export interface DatasetUpdateResponse {
    /**
     * The user that created the resource
     * @type {string}
     * @memberof DatasetUpdateResponse
     */
    createdUserId: string;
    /**
     * The created datetime of the resource
     * @type {Date}
     * @memberof DatasetUpdateResponse
     */
    createdDt: string;
    /**
     * The user that last updated the resource
     * @type {string}
     * @memberof DatasetUpdateResponse
     */
    updatedUserId: string;
    /**
     * The datetime the resource was last updated
     * @type {Date}
     * @memberof DatasetUpdateResponse
     */
    updatedDt: string;
    /**
     * 
     * @type {DataSourceTypeEnum}
     * @memberof DatasetUpdateResponse
     */
    dataSourceType?: DataSourceTypeEnum;
    /**
     * An unsupported data source identified by the user
     * @type {string}
     * @memberof DatasetUpdateResponse
     */
    unsupportedDataSource?: string | null;
    /**
     * Data source prefix to configure access
     * @type {string}
     * @memberof DatasetUpdateResponse
     */
    dataPath?: string | null;
    /**
     * User email to invite to help configure the dataset update
     * @type {string}
     * @memberof DatasetUpdateResponse
     */
    invitedUserEmail?: string | null;
    /**
     * The storage credentials id
     * @type {string}
     * @memberof DatasetUpdateResponse
     */
    credentialsId?: string | null;
    /**
     * Dataset id
     * @type {string}
     * @memberof DatasetUpdateResponse
     */
    datasetId: string;
    /**
     * Dataset id
     * @type {string}
     * @memberof DatasetUpdateResponse
     */
    id: string;
    /**
     * The user who completed the configuration of the update
     * @type {string}
     * @memberof DatasetUpdateResponse
     */
    configuredUserId?: string | null;
    /**
     * The time at which the update was successfully configured
     * @type {Date}
     * @memberof DatasetUpdateResponse
     */
    configuredDt?: string | null;
    /**
     * The invitation URL sent to help configure the dataset update
     * @type {string}
     * @memberof DatasetUpdateResponse
     */
    invitationUrl?: string | null;
}

export function DatasetUpdateResponseFromJSON(json: any): DatasetUpdateResponse {
    return DatasetUpdateResponseFromJSONTyped(json, false);
}

export function DatasetUpdateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasetUpdateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdUserId': json['createdUserId'],
        'createdDt': json['createdDt'],
        'updatedUserId': json['updatedUserId'],
        'updatedDt': json['updatedDt'],
        'dataSourceType': !exists(json, 'dataSourceType') ? undefined : DataSourceTypeEnumFromJSON(json['dataSourceType']),
        'unsupportedDataSource': !exists(json, 'unsupportedDataSource') ? undefined : json['unsupportedDataSource'],
        'dataPath': !exists(json, 'dataPath') ? undefined : json['dataPath'],
        'invitedUserEmail': !exists(json, 'invitedUserEmail') ? undefined : json['invitedUserEmail'],
        'credentialsId': !exists(json, 'credentialsId') ? undefined : json['credentialsId'],
        'datasetId': json['datasetId'],
        'id': json['id'],
        'configuredUserId': !exists(json, 'configuredUserId') ? undefined : json['configuredUserId'],
        'configuredDt': !exists(json, 'configuredDt') ? undefined : json['configuredDt'] === null ? null : json['configuredDt'],
        'invitationUrl': !exists(json, 'invitationUrl') ? undefined : json['invitationUrl'],
    };
}

export function DatasetUpdateResponseToJSON(value?: DatasetUpdateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdUserId': value.createdUserId,
        'createdDt': value.createdDt,
        'updatedUserId': value.updatedUserId,
        'updatedDt': value.updatedDt,
        'dataSourceType': DataSourceTypeEnumToJSON(value.dataSourceType),
        'unsupportedDataSource': value.unsupportedDataSource,
        'dataPath': value.dataPath,
        'invitedUserEmail': value.invitedUserEmail,
        'credentialsId': value.credentialsId,
        'datasetId': value.datasetId,
        'id': value.id,
        'configuredUserId': value.configuredUserId,
        'configuredDt': value.configuredDt === undefined ? undefined : value.configuredDt === null ? null : value.configuredDt,
        'invitationUrl': value.invitationUrl,
    };
}

