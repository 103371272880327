/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextPrompt,
    TextPromptFromJSON,
    TextPromptFromJSONTyped,
    TextPromptToJSON,
} from './TextPrompt';
import {
    VisualPrompt,
    VisualPromptFromJSON,
    VisualPromptFromJSONTyped,
    VisualPromptToJSON,
} from './VisualPrompt';

/**
 * Update dynamic tag prompts response object
 * @export
 * @interface UpdateDynamicTagPromptsResponse
 */
export interface UpdateDynamicTagPromptsResponse {
    /**
     * The dynamic tag id
     * @type {string}
     * @memberof UpdateDynamicTagPromptsResponse
     */
    dynamicTagId: string;
    /**
     * A list of text prompts
     * @type {Array<TextPrompt>}
     * @memberof UpdateDynamicTagPromptsResponse
     */
    textPrompts: Array<TextPrompt>;
    /**
     * A list of visual prompts
     * @type {Array<VisualPrompt>}
     * @memberof UpdateDynamicTagPromptsResponse
     */
    visualPrompts: Array<VisualPrompt>;
}

export function UpdateDynamicTagPromptsResponseFromJSON(json: any): UpdateDynamicTagPromptsResponse {
    return UpdateDynamicTagPromptsResponseFromJSONTyped(json, false);
}

export function UpdateDynamicTagPromptsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDynamicTagPromptsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dynamicTagId': json['dynamicTagId'],
        'textPrompts': ((json['textPrompts'] as Array<any>).map(TextPromptFromJSON)),
        'visualPrompts': ((json['visualPrompts'] as Array<any>).map(VisualPromptFromJSON)),
    };
}

export function UpdateDynamicTagPromptsResponseToJSON(value?: UpdateDynamicTagPromptsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dynamicTagId': value.dynamicTagId,
        'textPrompts': ((value.textPrompts as Array<any>).map(TextPromptToJSON)),
        'visualPrompts': ((value.visualPrompts as Array<any>).map(VisualPromptToJSON)),
    };
}

