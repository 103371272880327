export function findReducedEmbedding<T extends { name: string }>(
  embeddings: T[],
): T | undefined {
  return embeddings.find((e) => e.name.includes('-reduced'));
}

export function findDefaultEmbedding<T extends { name: string }>(
  embeddings: T[],
): T | undefined {
  return embeddings.find((e) => e.name === 'default');
}
