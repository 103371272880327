/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface AddMetadataRequest
 */
export interface AddMetadataRequest {
    /**
     * Path to the csv file
     * @type {string}
     * @memberof AddMetadataRequest
     */
    csvPath: string;
}

export function AddMetadataRequestFromJSON(json: any): AddMetadataRequest {
    return AddMetadataRequestFromJSONTyped(json, false);
}

export function AddMetadataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddMetadataRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'csvPath': json['csvPath'],
    };
}

export function AddMetadataRequestToJSON(value?: AddMetadataRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'csvPath': value.csvPath,
    };
}

