import { Mutation, Query, QueryKey } from 'react-query';
import { toast } from 'react-toastify';
import useLoginWithRedirect from './useLoginWithRedirect';

function useCheckNetworkStatus() {
  const loginWithRedirect = useLoginWithRedirect();
  const handle4xx = (error: unknown, key: QueryKey | number) => {
    if (
      error &&
      typeof error === 'object' &&
      'response' in error &&
      'status' in (error as any).response
    ) {
      const { status } = (error as any).response as Response;
      if (status === 401) {
        loginWithRedirect();
      }
      if (status === 403) {
        toast.error(
          'You do not have the authorization to perform this action.',
          {
            toastId: [key].concat(status).toString(),
          },
        );
      } else if (status === 419) {
        toast.info('Your session has expired. Please log in again.', {
          toastId: [key].concat(status).toString(),
        });
        loginWithRedirect();
      }
    }
  };

  const handleQuery4xx = (error: unknown, query: Query) => {
    handle4xx(error, query.queryKey);
  };

  const handleMutation4xx = (
    error: unknown,
    variables: unknown,
    context: unknown,
    mutation: Mutation<unknown, unknown, unknown, unknown>,
  ) => {
    handle4xx(error, mutation.mutationId);
  };
  return { handleQuery4xx, handleMutation4xx };
}

export default useCheckNetworkStatus;
