import React, { useEffect, useMemo } from 'react';
import { DatasetStatusEnum } from 'api/generated';
import Dropdown from 'components/Dropdown';
import { useGetDatasetsQuery } from 'pages/concepts/queries';

interface Props {
  selectedId: string | null;
  setSelectedId: (selectedId: string) => void;
  disabled?: boolean;
  hideLabel?: boolean;
  statusesToInclude?: DatasetStatusEnum[];
  customLabel?: string;
  dataTestId?: string;
}

const DatasetDropdown = function DatasetDropdown({
  disabled,
  hideLabel,
  selectedId,
  setSelectedId,
  statusesToInclude,
  customLabel,
  dataTestId,
}: Props) {
  const { data, isLoading } = useGetDatasetsQuery(statusesToInclude);
  const datasets = useMemo(() => data?.data || [], [data]);
  useEffect(() => {
    if (!selectedId && datasets.length === 1) {
      setSelectedId(datasets[0]?.datasetId ?? '');
    }
  }, [datasets]);
  const label = hideLabel ? undefined : customLabel || 'Dataset';
  return (
    <Dropdown
      className="min-w-[200px]"
      disabled={disabled || datasets.length === 0}
      label={label}
      onChange={setSelectedId}
      options={datasets.map((dataset) => ({
        label: dataset?.name ?? '',
        value: dataset?.datasetId ?? '',
      }))}
      optionsLoading={isLoading}
      selected={selectedId || ''}
      dataTestId={dataTestId}
    />
  );
};

DatasetDropdown.defaultProps = {
  statusesToInclude: undefined,
  hideLabel: false,
  disabled: false,
  customLabel: undefined,
  dataTestId: undefined,
};

export default DatasetDropdown;
