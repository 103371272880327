import Button from 'components/Button';
import FadeTransition from 'components/FadeTransition';
import LoadingTable from 'components/LoadingTable';
import Main from 'components/Main';
import PageHeader from 'components/PageHeader';
import usePagination from 'hooks/usePagination';
import TableView from 'pages/datasets/list/components/TableView';
import { useGetDatasetsAsTableQuery } from 'queries/datasets';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaginationState } from 'types/pagination';

const Datasets = function Datasets() {
  const pagination = usePagination();
  const navigate = useNavigate();
  const {
    data: datasets,
    isLoading,
    isFetched,
    refetch,
  } = useGetDatasetsAsTableQuery({
    limit: pagination.size,
    offset: (pagination.page - 1) * pagination.size,
  });
  const paginationState = useMemo<PaginationState>(
    () => ({ page: pagination.page, size: pagination.size }),
    [pagination.page, pagination.size],
  );
  const loadingTable = useMemo(() => <LoadingTable />, []);
  const loading = isLoading && !isFetched;

  return (
    <Main>
      <div className="max-w-8xl mx-auto">
        <PageHeader
          title={<h1>Datasets</h1>}
          backButtonTo=""
          actions={
            <Button
              dataTestId="create-dataset"
              onClick={() => navigate('/datasets/create')}
            >
              Create a Dataset
            </Button>
          }
        />
        <div className="pt-8">
          <FadeTransition show={Boolean(!loading && datasets)} appear>
            {datasets && (
              <TableView
                datasets={datasets?.data}
                columns={datasets?.columns}
                total={datasets?.meta?.page?.total || 0}
                refetchDatasets={refetch}
                loading={isLoading}
                setPageSize={pagination.setSize}
                loadPage={pagination.setPage}
                pagination={paginationState}
              />
            )}
          </FadeTransition>
          <FadeTransition show={loading} appear enterDelayMs={500} exit={false}>
            {loading && loadingTable}
          </FadeTransition>
        </div>
      </div>
    </Main>
  );
};

export default Datasets;
