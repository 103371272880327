/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const SQLOperand = {
    And: 'AND',
    Or: 'OR'
} as const;
export type SQLOperand = typeof SQLOperand[keyof typeof SQLOperand];


export function SQLOperandFromJSON(json: any): SQLOperand {
    return SQLOperandFromJSONTyped(json, false);
}

export function SQLOperandFromJSONTyped(json: any, ignoreDiscriminator: boolean): SQLOperand {
    return json as SQLOperand;
}

export function SQLOperandToJSON(value?: SQLOperand | null): any {
    return value as any;
}

