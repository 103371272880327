/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CoactiveTablesEnum,
    CoactiveTablesEnumFromJSON,
    CoactiveTablesEnumFromJSONTyped,
    CoactiveTablesEnumToJSON,
} from './CoactiveTablesEnum';
import {
    NoCodeQueryClause,
    NoCodeQueryClauseFromJSON,
    NoCodeQueryClauseFromJSONTyped,
    NoCodeQueryClauseToJSON,
} from './NoCodeQueryClause';
import {
    NoCodeQuerySortItem,
    NoCodeQuerySortItemFromJSON,
    NoCodeQuerySortItemFromJSONTyped,
    NoCodeQuerySortItemToJSON,
} from './NoCodeQuerySortItem';

/**
 * 
 * @export
 * @interface QueryParameters
 */
export interface QueryParameters {
    /**
     * 
     * @type {CoactiveTablesEnum}
     * @memberof QueryParameters
     */
    table?: CoactiveTablesEnum;
    /**
     * Optional start date to filter on
     * @type {Date}
     * @memberof QueryParameters
     */
    startDt?: string | null;
    /**
     * Optional end date to filter on
     * @type {Date}
     * @memberof QueryParameters
     */
    endDt?: string | null;
    /**
     * 
     * @type {NoCodeQuerySortItem}
     * @memberof QueryParameters
     */
    sort?: NoCodeQuerySortItem;
    /**
     * 
     * @type {NoCodeQueryClause}
     * @memberof QueryParameters
     */
    clause: NoCodeQueryClause;
}

export function QueryParametersFromJSON(json: any): QueryParameters {
    return QueryParametersFromJSONTyped(json, false);
}

export function QueryParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'table': !exists(json, 'table') ? undefined : CoactiveTablesEnumFromJSON(json['table']),
        'startDt': !exists(json, 'startDt') ? undefined : json['startDt'] === null ? null : json['startDt'],
        'endDt': !exists(json, 'endDt') ? undefined : json['endDt'] === null ? null : json['endDt'],
        'sort': !exists(json, 'sort') ? undefined : NoCodeQuerySortItemFromJSON(json['sort']),
        'clause': NoCodeQueryClauseFromJSON(json['clause']),
    };
}

export function QueryParametersToJSON(value?: QueryParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'table': CoactiveTablesEnumToJSON(value.table),
        'startDt': value.startDt === undefined ? undefined : value.startDt === null ? null : value.startDt,
        'endDt': value.endDt === undefined ? undefined : value.endDt === null ? null : value.endDt,
        'sort': NoCodeQuerySortItemToJSON(value.sort),
        'clause': NoCodeQueryClauseToJSON(value.clause),
    };
}

