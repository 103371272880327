/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MetadataType,
    MetadataTypeFromJSON,
    MetadataTypeFromJSONTyped,
    MetadataTypeToJSON,
} from './MetadataType';

/**
 * 
 * @export
 * @interface MetadataKey
 */
export interface MetadataKey {
    /**
     * Metadata key present on the dataset
     * @type {string}
     * @memberof MetadataKey
     */
    key: string;
    /**
     * 
     * @type {MetadataType}
     * @memberof MetadataKey
     */
    type: MetadataType;
}

export function MetadataKeyFromJSON(json: any): MetadataKey {
    return MetadataKeyFromJSONTyped(json, false);
}

export function MetadataKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetadataKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'type': MetadataTypeFromJSON(json['type']),
    };
}

export function MetadataKeyToJSON(value?: MetadataKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'type': MetadataTypeToJSON(value.type),
    };
}

