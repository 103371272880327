import {
  FullConceptResponseUI,
  FullConceptVersionLabelResponse,
  LabelEnum,
} from 'api/generated';
import classNames from 'classnames';
import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import { useGetLabelsForVersionInfiniteQueryKey } from 'pages/concepts/queries';
import { useUpdateConceptVersionLabelsMutation } from 'queries/labels';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface EditConceptVersionLabelRowProps {
  concept: FullConceptResponseUI;
  label: FullConceptVersionLabelResponse | undefined;
  coactiveImageId: string;
  labelsAreLoading: boolean;
  refetchLabels: () => void;
  score: any | undefined;
  refetchPredictions?: (shouldRefetchPredictions: boolean) => void;
}

const EditConceptVersionLabelRow: React.FC<EditConceptVersionLabelRowProps> = ({
  concept,
  label,
  coactiveImageId,
  labelsAreLoading,
  refetchLabels,
  refetchPredictions,
  score,
}) => {
  const { mutate: updateLabels, isLoading } =
    useUpdateConceptVersionLabelsMutation();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [pendingLabel, setPendingLabel] = useState<LabelEnum>();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const loadingTimeout = useRef<any>();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => () => clearTimeout(loadingTimeout.current), []);

  const labelHandler = useCallback(
    (conceptVersionId: string, _label: LabelEnum | undefined) => () => {
      if (label?.label === _label) {
        return;
      }

      clearTimeout(loadingTimeout.current);
      loadingTimeout.current = setTimeout(() => setShowLoader(true), 100);
      setShowLoader(false);
      setPendingLabel(_label);
      queryClient.invalidateQueries(
        useGetLabelsForVersionInfiniteQueryKey(conceptVersionId),
      );
      updateLabels(
        {
          conceptVersionId,
          updateConceptVersionLabelsRequest: {
            labels: [{ coactiveImageId, label: _label }],
          },
        },
        {
          onSettled: () => {
            refetchPredictions?.(true);
            refetchLabels();
          },
          onSuccess: () => {
            setErrorMessage(undefined);
          },
          onError: async (error: any) => {
            setErrorMessage((await error.response.json()).detail);
          },
        },
      );
    },
    [label],
  );

  const isPositive = label?.label === LabelEnum._1;
  const isNegative = label?.label === LabelEnum._0;

  return (
    <li
      key={concept.conceptId}
      className=" border-b border-gray-100 last:border-b-0"
    >
      <div className="flex py-2 items-center justify-between">
        <div className="flex">
          <div className="flex items-center rounded text-gray-800 border-slate-200 py-0.5 grow shrink-1 overflow-hidden text-ellipsis">
            <Button
              onClick={() =>
                navigate(`/concepts/${concept.conceptId}/versions/latest`)
              }
              buttonStyle="badge"
              padding="py-0.5 px-3"
            >
              {concept.name}
            </Button>
          </div>
          <Button
            className={classNames('ml-4', {
              'opacity-50': labelsAreLoading || isLoading,
            })}
            disabled={isLoading || labelsAreLoading}
            onClick={labelHandler(
              concept.latestVersion.versionId,
              isPositive ? undefined : LabelEnum._1,
            )}
            color={classNames({ 'bg-emerald-50': isPositive })}
            textColor={classNames({ 'text-emerald-700': isPositive })}
            buttonStyle="secondary"
            padding="py-0.5 px-3"
            loading={pendingLabel === LabelEnum._1 && isLoading && showLoader}
          >
            Yes
          </Button>
          <Button
            className={classNames('ml-2', {
              'opacity-50': labelsAreLoading || isLoading,
            })}
            disabled={isLoading || labelsAreLoading}
            onClick={labelHandler(
              concept.latestVersion.versionId,
              isNegative ? undefined : LabelEnum._0,
            )}
            color={classNames({ 'bg-emerald-50': isNegative })}
            textColor={classNames({ 'text-emerald-700': isNegative })}
            buttonStyle="secondary"
            padding="py-0.5 px-3"
            loading={pendingLabel === LabelEnum._0 && isLoading && showLoader}
          >
            No
          </Button>
          {label?.label && (
            <Button
              className="ml-2"
              onClick={labelHandler(concept.latestVersion.versionId, undefined)}
              textColor="slate-600"
              buttonStyle="link"
              padding="py-0.5 px-3"
              loading={pendingLabel === LabelEnum._0 && isLoading && showLoader}
            >
              Clear
            </Button>
          )}
        </div>
        <p className="text-end text-sm">{score}</p>
      </div>
      <div className="text-right">
        <ErrorText margin="mb-2">{errorMessage}</ErrorText>
      </div>
    </li>
  );
};

EditConceptVersionLabelRow.defaultProps = {
  refetchPredictions: undefined,
};

export default EditConceptVersionLabelRow;
