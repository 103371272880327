import { QueryConceptDataUI } from 'api/generated';
import ConceptClassification from 'components/ConceptClassification';
import React, { useMemo } from 'react';

const ConceptClassificationsList: React.FunctionComponent<{
  classifications: QueryConceptDataUI[];
  conceptClassificationPadding?: string;
}> = function ConceptClassificationsList({
  classifications,
  conceptClassificationPadding,
}) {
  const sortedClassifications = useMemo(
    () =>
      [...classifications].sort(
        (c1, c2) => c2.probabilityScore - c1.probabilityScore,
      ),
    [classifications],
  );

  return (
    <div>
      {sortedClassifications?.map((classification) => (
        <ConceptClassification
          key={classification.id}
          classification={classification}
          padding={conceptClassificationPadding}
        />
      ))}
    </div>
  );
};

ConceptClassificationsList.defaultProps = {
  conceptClassificationPadding: 'pb-2',
};

export default ConceptClassificationsList;
