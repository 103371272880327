/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CoactiveTablesEnum,
    CoactiveTablesEnumFromJSON,
    CoactiveTablesEnumFromJSONTyped,
    CoactiveTablesEnumToJSON,
} from './CoactiveTablesEnum';
import {
    NoCodeQueryClause,
    NoCodeQueryClauseFromJSON,
    NoCodeQueryClauseFromJSONTyped,
    NoCodeQueryClauseToJSON,
} from './NoCodeQueryClause';
import {
    NoCodeQuerySortItem,
    NoCodeQuerySortItemFromJSON,
    NoCodeQuerySortItemFromJSONTyped,
    NoCodeQuerySortItemToJSON,
} from './NoCodeQuerySortItem';
import {
    SearchType,
    SearchTypeFromJSON,
    SearchTypeFromJSONTyped,
    SearchTypeToJSON,
} from './SearchType';

/**
 * 
 * @export
 * @interface DatasetSearchRequest
 */
export interface DatasetSearchRequest {
    /**
     * 
     * @type {CoactiveTablesEnum}
     * @memberof DatasetSearchRequest
     */
    table?: CoactiveTablesEnum;
    /**
     * Optional start date to filter on
     * @type {Date}
     * @memberof DatasetSearchRequest
     */
    startDt?: string | null;
    /**
     * Optional end date to filter on
     * @type {Date}
     * @memberof DatasetSearchRequest
     */
    endDt?: string | null;
    /**
     * 
     * @type {NoCodeQuerySortItem}
     * @memberof DatasetSearchRequest
     */
    sort?: NoCodeQuerySortItem;
    /**
     * 
     * @type {NoCodeQueryClause}
     * @memberof DatasetSearchRequest
     */
    clause?: NoCodeQueryClause;
    /**
     * The id of the dataset to query
     * @type {string}
     * @memberof DatasetSearchRequest
     */
    datasetId: string;
    /**
     * The natural language query to search over the dataset
     * @type {string}
     * @memberof DatasetSearchRequest
     */
    naturalLanguageQuery: string;
    /**
     * 
     * @type {SearchType}
     * @memberof DatasetSearchRequest
     */
    searchType?: SearchType;
}

export function DatasetSearchRequestFromJSON(json: any): DatasetSearchRequest {
    return DatasetSearchRequestFromJSONTyped(json, false);
}

export function DatasetSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasetSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'table': !exists(json, 'table') ? undefined : CoactiveTablesEnumFromJSON(json['table']),
        'startDt': !exists(json, 'startDt') ? undefined : json['startDt'] === null ? null : json['startDt'],
        'endDt': !exists(json, 'endDt') ? undefined : json['endDt'] === null ? null : json['endDt'],
        'sort': !exists(json, 'sort') ? undefined : NoCodeQuerySortItemFromJSON(json['sort']),
        'clause': !exists(json, 'clause') ? undefined : NoCodeQueryClauseFromJSON(json['clause']),
        'datasetId': json['datasetId'],
        'naturalLanguageQuery': json['naturalLanguageQuery'],
        'searchType': !exists(json, 'searchType') ? undefined : SearchTypeFromJSON(json['searchType']),
    };
}

export function DatasetSearchRequestToJSON(value?: DatasetSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'table': CoactiveTablesEnumToJSON(value.table),
        'startDt': value.startDt === undefined ? undefined : value.startDt === null ? null : value.startDt,
        'endDt': value.endDt === undefined ? undefined : value.endDt === null ? null : value.endDt,
        'sort': NoCodeQuerySortItemToJSON(value.sort),
        'clause': NoCodeQueryClauseToJSON(value.clause),
        'datasetId': value.datasetId,
        'naturalLanguageQuery': value.naturalLanguageQuery,
        'searchType': SearchTypeToJSON(value.searchType),
    };
}

