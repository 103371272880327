import { ArrowRightIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import Dropdown, { DropdownOption } from 'components/Dropdown';
import React, { PropsWithChildren } from 'react';
import IconButton from './IconButton';

interface SearchContainerProps<T extends string> {
  searchMode?: T;
  setSearchMode?: (searchMode: T) => void;
  searchModeOptions?: DropdownOption<T>[];
  executeSearch: () => void;
  buttonDisabled: boolean;
  dropdowns?: React.ReactNode;
  footer?: React.ReactNode;
  areaDisabled?: boolean;
}

const SearchContainer = function SearchContainer<T extends string>({
  areaDisabled,
  children,
  searchMode,
  setSearchMode,
  searchModeOptions,
  executeSearch,
  buttonDisabled,
  dropdowns,
  footer,
}: PropsWithChildren<SearchContainerProps<T>>) {
  const searchModeCount = searchModeOptions?.length ?? 0;

  return (
    <div className="flex mx-auto max-w-full flex-col items-center w-full">
      <div
        className={classNames(
          'rounded-md w-full border border-solid ',
          areaDisabled
            ? 'bg-slate-100 border-slate-100'
            : 'bg-white border-grey-500',
        )}
      >
        {children}
        <div
          className={classNames(
            'flex py-2.5 px-3 items-center',
            footer ? 'justify-between' : 'justify-end',
          )}
        >
          {footer}
          {!areaDisabled && (
            <IconButton
              background="bg-slate-100 bg-opacity-75"
              hoverColor="hover:bg-slate-200"
              Icon={ArrowRightIcon}
              rounded="full"
              onClick={executeSearch}
              fill={classNames({
                'text-black text-opacity-50': areaDisabled,
              })}
              disabled={buttonDisabled}
              size="small"
            />
          )}
        </div>
      </div>
      <div className="flex w-full justify-between mt-3 items-center ">
        <div className="flex justify-start gap-2 items-center flex-wrap">
          {searchModeOptions && searchModeCount > 1 && (
            <Dropdown
              className="shrink-0 border border-solid border-grey-500 rounded-md text-gray-700"
              options={searchModeOptions}
              selected={searchMode}
              onChange={(v: T) => setSearchMode?.(v)}
              buttonStyle="rounded-md h-full border-0"
              buttonShadow=""
              size="small"
            />
          )}
          {dropdowns}
        </div>
      </div>
    </div>
  );
};

SearchContainer.defaultProps = {
  areaDisabled: false,
  dropdowns: undefined,
  searchMode: undefined,
  setSearchMode: undefined,
  searchModeOptions: undefined,
  footer: undefined,
};

export default SearchContainer;
