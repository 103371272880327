import { useAuth0 } from '@auth0/auth0-react';
import { AuthApi, MeResponse } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useMutation from 'hooks/useMutation';
import { useMemo } from 'react';

export const useLogoutMutation = () => {
  const { initializeAPI } = useAPIContext();

  return useMutation(['logout'], async () => {
    const api = await initializeAPI<AuthApi>(AuthApi, {}, false);
    return api.logout();
  });
};

const useLogout = (me: MeResponse | undefined) => {
  const { logout: auth0Logout } = useAuth0();
  const { mutate: webappLogout } = useLogoutMutation();

  return useMemo(
    () => async () => {
      await webappLogout();
      const logoutRedirectUrl = me?.logoutRedirectUrl
        ? me.logoutRedirectUrl
        : `${window.location.protocol}//${window.location.host}/login`;
      auth0Logout({
        logoutParams: {
          returnTo: logoutRedirectUrl,
          client_id: process.env.REACT_APP_AUTH0_UI_CLIENT_ID,
          federated: true,
        },
      });
    },
    [auth0Logout, webappLogout],
  );
};

export default useLogout;
