import React from 'react';

interface QueryStoppedProps {
  query: string | undefined;
  embeddingId: string;
  runQuery: (query: string, embeddingId: string) => void;
}

const QueryStopped: React.FC<QueryStoppedProps> = function QueryStopped({
  query,
  embeddingId,
  runQuery,
}) {
  return (
    <div className="py-12 text-center">
      <div className="text-sm font-semibold">
        This query was stopped while executing.
      </div>
      {query ? (
        <button
          type="button"
          className="text-sm mt-4 font-semibold text-green-600 hover:text-green-700 hover:text-green-700 hover:underline"
          onClick={() => runQuery(query, embeddingId)}
        >
          Run Again
        </button>
      ) : undefined}
    </div>
  );
};

export default QueryStopped;
