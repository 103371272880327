import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { QueryTableRowUI } from 'api/generated';
import Button from 'components/Button';
import FadeTransition from 'components/FadeTransition';
import Main from 'components/Main';
import AssetDetailSidebar from 'components/Sidebar/AssetDetailSidebar';
import SidebarContext from 'context/SidebarContext';
import ExecutedQueryView from 'pages/queries/ExecutedQueryView';
import Workspace from 'pages/queries/Workspace';
import {
  Creator,
  useGetQueryHistoryInfiniteQueryInfiniteQueryKey,
  useRunQuery,
} from 'pages/queries/queries';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import HistorySidebar from './HistorySidebar';

const QueryView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { setLeftSidebarOpen, setRightSidebarOpen } =
    useContext(SidebarContext);
  const [sidebarData, setSidebarData] = useState<
    QueryTableRowUI & { datasetId: string; embeddingId: string }
  >();
  const [selectedQueryId, setSelectedQueryId] = useState<string>();
  const [selectedCreatorTab, setSelectedCreatorTab] = useState<Creator>();
  const queryClient = useQueryClient();
  const runQueryMutation = useRunQuery();

  useEffect(() => {
    if (params && params['*']) {
      const parts = params['*'].split('/');
      if (parts.length) {
        setSelectedCreatorTab(
          parts[0] === Creator.All.toString() ? Creator.All : Creator.Me,
        );
        setLeftSidebarOpen(true);
      } else {
        setSelectedCreatorTab(undefined);
        setLeftSidebarOpen(false);
      }
      if (parts.length > 1) {
        setSelectedQueryId(parts[1]);
      } else {
        setSelectedQueryId(undefined);
      }
    } else {
      setLeftSidebarOpen(false);
      setSelectedCreatorTab(undefined);
      setSelectedQueryId(undefined);
    }
  }, [params]);

  const runQuery = useCallback(
    async (query: string, embeddingId: string) =>
      // TODO: post query
      runQueryMutation.mutate(
        { queryRequestUI: { query, embeddingId } },
        {
          onSuccess: (result) => {
            queryClient.invalidateQueries(
              useGetQueryHistoryInfiniteQueryInfiniteQueryKey(Creator.Me),
            );
            queryClient.invalidateQueries(
              useGetQueryHistoryInfiniteQueryInfiniteQueryKey(Creator.All),
            );
            navigate(`/queries/${Creator.All}/${result.queryId}/results`);
          },
        },
      ),
    [selectedCreatorTab],
  );

  useEffect(() => {
    setRightSidebarOpen(Boolean(sidebarData));
  }, [sidebarData]);

  const workspace = useMemo(
    () => (
      <Workspace
        runQuery={runQuery}
        codeMirrorDefaultHeight="calc(100vh - 12rem)"
      />
    ),
    [runQuery],
  );

  const backToWorkspace = useCallback(() => {
    setSelectedQueryId(undefined);
    setSidebarData(undefined);
    navigate('/queries/all');
  }, []);

  const onSelectQuery = useCallback(
    (queryId: string | undefined, error?: boolean) => {
      navigate(
        queryId
          ? `/queries/${selectedCreatorTab}/${queryId}/${
              error ? 'error' : 'results'
            }`
          : `/queries/${selectedCreatorTab}`,
      );
      setSidebarData(undefined);
    },
    [selectedCreatorTab],
  );

  return (
    <Main
      leftSidebar={
        <HistorySidebar
          selectQuery={onSelectQuery}
          selectedQueryId={selectedQueryId}
          selectedTab={selectedCreatorTab}
        />
      }
      rightSidebar={
        sidebarData && (
          <AssetDetailSidebar
            compact
            asset={sidebarData.asset!}
            datasetId={sidebarData.datasetId}
            defaultMetadataTitle="Other Metadata"
            metadata={sidebarData.data}
            metadataKeyOrder={['row']}
            metadataTitle="Query Data"
          />
        )
      }
    >
      <div className="max-w-8xl mx-auto">
        <FadeTransition exit={false} show={!selectedQueryId}>
          {Boolean(!selectedQueryId) && workspace}
        </FadeTransition>
        <FadeTransition exit={false} show={Boolean(selectedQueryId)}>
          {selectedQueryId && (
            <>
              <div className="flex justify-start gap-2 mb-4">
                <Button
                  className="border-0"
                  onClick={backToWorkspace}
                  padding="pl-0 pr-4 py-2"
                  shadow="shadow-none"
                  color="bg-transparent"
                  hoverColor="bg-transparent"
                  textColor="text-gray-700"
                >
                  <ChevronLeftIcon className="h-4 w-4" />
                  <span className="pl-2 text-sm">Back to Workspace</span>
                </Button>
              </div>
              <ExecutedQueryView
                queryId={selectedQueryId}
                setSidebarData={setSidebarData}
                runQuery={runQuery}
                backToWorkspace={backToWorkspace}
                creator={selectedCreatorTab ?? Creator.Me}
              />
            </>
          )}
        </FadeTransition>
      </div>
    </Main>
  );
};
export default QueryView;
