import classNames from 'classnames';
import ClipboardActionButton from 'components/ClipboardActionButton';
import React from 'react';

interface CodeSnippetProps {
  id: string;
  code: string;
  maxCodeHeight?: string;
}

/**
 * A component to display code and make it easy to copy to the clipboard.
 */
const CodeSnippet = function CodeSnippet({
  id,
  code,
  maxCodeHeight,
}: CodeSnippetProps) {
  return (
    <div className="rounded-md">
      <div className="rounded-t-md border-b-[1px] border-slate-200 p-2 bg-slate-100 text-right">
        <ClipboardActionButton
          id={`copy-code-${id}`}
          text={code}
          buttonStyle="link"
          textColor="text-gray-600"
          textHoverColor="text-gray-700"
        >
          Copy code
        </ClipboardActionButton>
      </div>
      <div
        className={classNames(
          'text-sm bg-slate-50 p-4 overflow-scroll',
          maxCodeHeight,
        )}
      >
        <pre>
          <code>{code}</code>
        </pre>
      </div>
    </div>
  );
};

CodeSnippet.defaultProps = {
  maxCodeHeight: undefined,
};

export default CodeSnippet;
