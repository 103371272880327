import { QueryResultResponseUI, QueryTableRowUI } from 'api/generated';
import FadeTransition from 'components/FadeTransition';
import LoadingTable from 'components/LoadingTable';
import Table from 'components/Table';
import React, { Fragment, useCallback, useMemo } from 'react';
import { PaginationState } from 'types/pagination';
import { TablePageSize } from 'types/table';
import { getFileExtensionFromUrl } from 'utils/UrlUtils';

interface QueryTableResultsProps {
  show: boolean;
  queryResult: QueryResultResponseUI | undefined;
  pagination: PaginationState;
  setPageSize: (size: TablePageSize) => void;
  loadPage: (page: number) => void;
  setSidebarData: (data: QueryTableRowUI) => void;
  downloadCsvUrl?: string | (() => Promise<string>);
  downloadCsvUrlAuthenticated?: boolean;
  hiddenColumns: Set<string>;
  setHiddenColumns: (hiddenColumns: Set<string>) => void;
}

const QueryTableResults: React.FC<QueryTableResultsProps> =
  function ExecutedQueryView({
    show,
    queryResult,
    pagination,
    setPageSize,
    loadPage,
    downloadCsvUrl,
    downloadCsvUrlAuthenticated,
    setSidebarData,
    hiddenColumns,
    setHiddenColumns,
  }: QueryTableResultsProps) {
    const loadingTable = useMemo(() => <LoadingTable />, []);
    const showLoadingTable = Boolean(show && !queryResult);
    const downloadCsvFilename = useCallback(
      (url: string) =>
        queryResult
          ? `${queryResult?.queryId}.${getFileExtensionFromUrl(url)}`
          : '',
      [queryResult?.queryId, downloadCsvUrl],
    );

    return (
      <Fragment>
        <FadeTransition
          appear
          show={showLoadingTable}
          enterDelayMs={500}
          exit={false}
        >
          {showLoadingTable && <div className="mt-4">{loadingTable}</div>}
        </FadeTransition>
        <FadeTransition appear show={Boolean(show && queryResult)} exit={false}>
          {Boolean(
            queryResult?.results?.columns && queryResult.results?.data,
          ) && (
            <Table
              downloadCsvUrl={downloadCsvUrl}
              downloadCsvFilename={downloadCsvFilename}
              downloadCsvUrlAuthenticated={downloadCsvUrlAuthenticated}
              idKey="row"
              columns={queryResult?.results?.columns!}
              data={queryResult?.results?.data!}
              total={queryResult?.results?.meta?.page?.total!}
              activePage={pagination.page}
              pageSize={pagination.size}
              showRowCount
              setPageSize={setPageSize}
              loadPage={loadPage}
              onClickImagePreview={setSidebarData}
              hiddenColumns={hiddenColumns}
              setHiddenColumns={setHiddenColumns}
            />
          )}
        </FadeTransition>
      </Fragment>
    );
  };

QueryTableResults.defaultProps = {
  downloadCsvUrl: undefined,
  downloadCsvUrlAuthenticated: true,
};

export default QueryTableResults;
