/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Auth0OrgInvitationCreator,
    Auth0OrgInvitationCreatorFromJSON,
    Auth0OrgInvitationCreatorFromJSONTyped,
    Auth0OrgInvitationCreatorToJSON,
} from './Auth0OrgInvitationCreator';
import {
    Auth0OrgInvitationRecipient,
    Auth0OrgInvitationRecipientFromJSON,
    Auth0OrgInvitationRecipientFromJSONTyped,
    Auth0OrgInvitationRecipientToJSON,
} from './Auth0OrgInvitationRecipient';

/**
 * Base model for all schema
 * @export
 * @interface OrgInvitation
 */
export interface OrgInvitation {
    /**
     * Invitation id
     * @type {string}
     * @memberof OrgInvitation
     */
    id: string;
    /**
     * 
     * @type {Auth0OrgInvitationCreator}
     * @memberof OrgInvitation
     */
    inviter: Auth0OrgInvitationCreator;
    /**
     * 
     * @type {Auth0OrgInvitationRecipient}
     * @memberof OrgInvitation
     */
    invitee: Auth0OrgInvitationRecipient;
    /**
     * The invitation url sent to the invitee
     * @type {string}
     * @memberof OrgInvitation
     */
    invitationUrl: string;
    /**
     * The date the invitation was created
     * @type {string}
     * @memberof OrgInvitation
     */
    createdAt: string;
    /**
     * The date the invitation will expire
     * @type {string}
     * @memberof OrgInvitation
     */
    expiresAt: string;
}

export function OrgInvitationFromJSON(json: any): OrgInvitation {
    return OrgInvitationFromJSONTyped(json, false);
}

export function OrgInvitationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgInvitation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'inviter': Auth0OrgInvitationCreatorFromJSON(json['inviter']),
        'invitee': Auth0OrgInvitationRecipientFromJSON(json['invitee']),
        'invitationUrl': json['invitationUrl'],
        'createdAt': json['createdAt'],
        'expiresAt': json['expiresAt'],
    };
}

export function OrgInvitationToJSON(value?: OrgInvitation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'inviter': Auth0OrgInvitationCreatorToJSON(value.inviter),
        'invitee': Auth0OrgInvitationRecipientToJSON(value.invitee),
        'invitationUrl': value.invitationUrl,
        'createdAt': value.createdAt,
        'expiresAt': value.expiresAt,
    };
}

