/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldValidation,
    FieldValidationFromJSON,
    FieldValidationFromJSONTyped,
    FieldValidationToJSON,
} from './FieldValidation';

/**
 * Base model for all schema
 * @export
 * @interface ValidateConceptNameResponse
 */
export interface ValidateConceptNameResponse {
    /**
     * 
     * @type {FieldValidation}
     * @memberof ValidateConceptNameResponse
     */
    valid: FieldValidation;
    /**
     * 
     * @type {FieldValidation}
     * @memberof ValidateConceptNameResponse
     */
    unique: FieldValidation;
}

export function ValidateConceptNameResponseFromJSON(json: any): ValidateConceptNameResponse {
    return ValidateConceptNameResponseFromJSONTyped(json, false);
}

export function ValidateConceptNameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateConceptNameResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valid': FieldValidationFromJSON(json['valid']),
        'unique': FieldValidationFromJSON(json['unique']),
    };
}

export function ValidateConceptNameResponseToJSON(value?: ValidateConceptNameResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valid': FieldValidationToJSON(value.valid),
        'unique': FieldValidationToJSON(value.unique),
    };
}

