import classNames from 'classnames';
import React, { CSSProperties } from 'react';

interface LoadingBlockProps {
  className: string;
  style?: CSSProperties | undefined;
}

export const LOADING_BLOCK_STYLE = 'animate-pulse bg-slate-300';

const LoadingBlock = function LoadingBlock({
  className,
  style,
}: LoadingBlockProps) {
  return (
    <div className={classNames(LOADING_BLOCK_STYLE, className)} style={style} />
  );
};

LoadingBlock.defaultProps = {
  style: undefined,
};

export default LoadingBlock;
