/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * All possible query status values
 * @export
 */
export const QueryStatus = {
    Queued: 'Queued',
    Running: 'Running',
    Stopping: 'Stopping',
    Stopped: 'Stopped',
    Complete: 'Complete',
    Error: 'Error'
} as const;
export type QueryStatus = typeof QueryStatus[keyof typeof QueryStatus];


export function QueryStatusFromJSON(json: any): QueryStatus {
    return QueryStatusFromJSONTyped(json, false);
}

export function QueryStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryStatus {
    return json as QueryStatus;
}

export function QueryStatusToJSON(value?: QueryStatus | null): any {
    return value as any;
}

