/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The data source of the dataset
 * 
 * s3 - AWS S3 bucket
 * local - A local path (unavailable via the SDK)
 * @export
 */
export const StorageTypeEnum = {
    Local: 'local',
    S3: 's3',
    Gs: 'gs'
} as const;
export type StorageTypeEnum = typeof StorageTypeEnum[keyof typeof StorageTypeEnum];


export function StorageTypeEnumFromJSON(json: any): StorageTypeEnum {
    return StorageTypeEnumFromJSONTyped(json, false);
}

export function StorageTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StorageTypeEnum {
    return json as StorageTypeEnum;
}

export function StorageTypeEnumToJSON(value?: StorageTypeEnum | null): any {
    return value as any;
}

