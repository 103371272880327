/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * Organization id
     * @type {string}
     * @memberof Organization
     */
    orgId: string;
    /**
     * Display name of the organization
     * @type {string}
     * @memberof Organization
     */
    displayName: string;
    /**
     * Url to a logo of the organization
     * @type {string}
     * @memberof Organization
     */
    logoUrl?: string;
    /**
     * Expiration date of org
     * @type {Date}
     * @memberof Organization
     */
    expirationDate?: string | null;
}

export function OrganizationFromJSON(json: any): Organization {
    return OrganizationFromJSONTyped(json, false);
}

export function OrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Organization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orgId': json['orgId'],
        'displayName': json['displayName'],
        'logoUrl': !exists(json, 'logoUrl') ? undefined : json['logoUrl'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : json['expirationDate'] === null ? null : json['expirationDate'],
    };
}

export function OrganizationToJSON(value?: Organization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orgId': value.orgId,
        'displayName': value.displayName,
        'logoUrl': value.logoUrl,
        'expirationDate': value.expirationDate === undefined ? undefined : value.expirationDate === null ? null : value.expirationDate,
    };
}

