/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Metadata for retrieving paginated data
 * @export
 * @interface AppSharedV1SchemaPagedLinksResponse
 */
export interface AppSharedV1SchemaPagedLinksResponse {
    /**
     * Link to previous page
     * @type {string}
     * @memberof AppSharedV1SchemaPagedLinksResponse
     */
    prev?: string | null;
    /**
     * Link to next page
     * @type {string}
     * @memberof AppSharedV1SchemaPagedLinksResponse
     */
    next?: string | null;
    /**
     * Link to first page
     * @type {string}
     * @memberof AppSharedV1SchemaPagedLinksResponse
     */
    first?: string | null;
    /**
     * Link to last page
     * @type {string}
     * @memberof AppSharedV1SchemaPagedLinksResponse
     */
    last?: string | null;
}

export function AppSharedV1SchemaPagedLinksResponseFromJSON(json: any): AppSharedV1SchemaPagedLinksResponse {
    return AppSharedV1SchemaPagedLinksResponseFromJSONTyped(json, false);
}

export function AppSharedV1SchemaPagedLinksResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppSharedV1SchemaPagedLinksResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prev': !exists(json, 'prev') ? undefined : json['prev'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'first': !exists(json, 'first') ? undefined : json['first'],
        'last': !exists(json, 'last') ? undefined : json['last'],
    };
}

export function AppSharedV1SchemaPagedLinksResponseToJSON(value?: AppSharedV1SchemaPagedLinksResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prev': value.prev,
        'next': value.next,
        'first': value.first,
        'last': value.last,
    };
}

