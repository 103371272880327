/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Update dynamic tag prompts request object
 * @export
 * @interface UpdateDynamicTagPromptsRequest
 */
export interface UpdateDynamicTagPromptsRequest {
    /**
     * data from frontend used to update text_prompts
     * @type {Array<string>}
     * @memberof UpdateDynamicTagPromptsRequest
     */
    textPrompts?: Array<string> | null;
    /**
     * data from frontend used to update visual_prompts
     * @type {Array<{ [key: string]: string; }>}
     * @memberof UpdateDynamicTagPromptsRequest
     */
    visualPrompts?: Array<{ [key: string]: string; }> | null;
}

export function UpdateDynamicTagPromptsRequestFromJSON(json: any): UpdateDynamicTagPromptsRequest {
    return UpdateDynamicTagPromptsRequestFromJSONTyped(json, false);
}

export function UpdateDynamicTagPromptsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDynamicTagPromptsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'textPrompts': !exists(json, 'textPrompts') ? undefined : json['textPrompts'],
        'visualPrompts': !exists(json, 'visualPrompts') ? undefined : json['visualPrompts'],
    };
}

export function UpdateDynamicTagPromptsRequestToJSON(value?: UpdateDynamicTagPromptsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'textPrompts': value.textPrompts,
        'visualPrompts': value.visualPrompts,
    };
}

