/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * An object used to create a category in Coactive's system
 * @export
 * @interface CreateCategoryRequest
 */
export interface CreateCategoryRequest {
    /**
     * The category name
     * @type {string}
     * @memberof CreateCategoryRequest
     */
    name: string;
    /**
     * The dataset id to create the category
     * @type {string}
     * @memberof CreateCategoryRequest
     */
    datasetId: string;
    /**
     * The dynamic tags within the category to create
     * @type {{ [key: string]: Array<string>; }}
     * @memberof CreateCategoryRequest
     */
    dynamicTags?: { [key: string]: Array<string>; } | null;
}

export function CreateCategoryRequestFromJSON(json: any): CreateCategoryRequest {
    return CreateCategoryRequestFromJSONTyped(json, false);
}

export function CreateCategoryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCategoryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'datasetId': json['datasetId'],
        'dynamicTags': !exists(json, 'dynamicTags') ? undefined : json['dynamicTags'],
    };
}

export function CreateCategoryRequestToJSON(value?: CreateCategoryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'datasetId': value.datasetId,
        'dynamicTags': value.dynamicTags,
    };
}

