/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface ResendDatasetUpdateInvitationsRequest
 */
export interface ResendDatasetUpdateInvitationsRequest {
    /**
     * Dataset update ids of invitations to resend
     * @type {Array<string>}
     * @memberof ResendDatasetUpdateInvitationsRequest
     */
    datasetUpdateIds: Array<string>;
}

export function ResendDatasetUpdateInvitationsRequestFromJSON(json: any): ResendDatasetUpdateInvitationsRequest {
    return ResendDatasetUpdateInvitationsRequestFromJSONTyped(json, false);
}

export function ResendDatasetUpdateInvitationsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResendDatasetUpdateInvitationsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datasetUpdateIds': json['datasetUpdateIds'],
    };
}

export function ResendDatasetUpdateInvitationsRequestToJSON(value?: ResendDatasetUpdateInvitationsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datasetUpdateIds': value.datasetUpdateIds,
    };
}

