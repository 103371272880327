import { useWindowSize } from 'hooks/useWindowSize';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import shave from 'shave';

interface MetadataValueProps {
  value: any;
  parsedValue: any;
  copyText: string;
  key: string;
}

const MetadataValue: React.FunctionComponent<MetadataValueProps> =
  function MetadataValue({ value, parsedValue, copyText, key }) {
    const valueRef = useRef<HTMLSpanElement>();
    const windowSize = useWindowSize();
    const [width, setWidth] = useState(0);
    const [showLink, setShowLink] = useState(false);
    const [showFullText, setShowFullText] = useState(false);

    const maxHeight = useMemo(() => {
      if (!valueRef.current) {
        return 50;
      }
      const style = getComputedStyle(valueRef.current);
      const fontSize = parseInt(style.getPropertyValue('font-size'), 20);
      return 2 * fontSize; // # pixels = # rem * root font size
    }, [windowSize]);

    const resizeObserver = React.useRef<ResizeObserver>(
      new ResizeObserver((entries: ResizeObserverEntry[]) => {
        setWidth(entries[0].contentRect.width);
      }),
    );

    const resizedContainerRef = React.useCallback(
      (container: HTMLSpanElement) => {
        valueRef.current = container;
        if (container !== null) {
          resizeObserver.current.observe(container);
        } else if (resizeObserver.current) {
          resizeObserver.current.disconnect();
        }
      },
      [resizeObserver.current],
    );

    useEffect(() => {
      if (valueRef.current) {
        shave(valueRef.current, maxHeight, {
          classname: 'is-shaved',
          spaces: copyText?.includes(' '),
        });
        const elements = valueRef.current.getElementsByClassName('is-shaved');
        // if there are elements with the 'is-shaved' class, then the text has been truncated
        const shouldShowLink = Boolean(elements.length);
        setShowLink(shouldShowLink);
        if (shouldShowLink) {
          setShowFullText(false);
        }
      }
    }, [maxHeight, valueRef, width]);

    useEffect(() => {
      if (showFullText && valueRef.current) {
        valueRef.current.textContent = parsedValue;
      }
    }, [showFullText]);

    const handleClickLink = () => {
      setShowFullText(true);
      setShowLink(false);
    };

    const valueComponent =
      value !== null ? (
        <span
          ref={resizedContainerRef}
          id={`metadata-${key}`}
          title={copyText}
          className="metadata-value"
        >
          {parsedValue}
        </span>
      ) : (
        <span
          ref={resizedContainerRef}
          id={`metadata-${key}`}
          className="text-gray-400 metadata-value"
        >
          None
        </span>
      );

    return (
      <>
        {valueComponent}
        {showLink && (
          <button
            type="button"
            onClick={handleClickLink}
            className="text-gray-400 font-medium text-center"
          >
            Show more
          </button>
        )}
      </>
    );
  };

export default MetadataValue;
