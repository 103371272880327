/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColumnDataType,
    ColumnDataTypeFromJSON,
    ColumnDataTypeFromJSONTyped,
    ColumnDataTypeToJSON,
} from './ColumnDataType';
import {
    ColumnDisplayType,
    ColumnDisplayTypeFromJSON,
    ColumnDisplayTypeFromJSONTyped,
    ColumnDisplayTypeToJSON,
} from './ColumnDisplayType';
import {
    ColumnStyle,
    ColumnStyleFromJSON,
    ColumnStyleFromJSONTyped,
    ColumnStyleToJSON,
} from './ColumnStyle';

/**
 * A representation of a table column
 * @export
 * @interface Column
 */
export interface Column {
    /**
     * The key for the row value in the row data list object
     * @type {string}
     * @memberof Column
     */
    key: string;
    /**
     * The path of keys to traverse for the row value in the row data list object. Will take precedence over 'key' if defined.
     * @type {Array<string>}
     * @memberof Column
     */
    keyPath?: Array<string> | null;
    /**
     * The display value for the column name
     * @type {string}
     * @memberof Column
     */
    label?: string | null;
    /**
     * 
     * @type {ColumnDisplayType}
     * @memberof Column
     */
    displayType?: ColumnDisplayType;
    /**
     * 
     * @type {ColumnStyle}
     * @memberof Column
     */
    style?: ColumnStyle;
    /**
     * Link template, ex. /path/to/{some_var}
     * @type {string}
     * @memberof Column
     */
    to?: string | null;
    /**
     * Optionally hide the column label in the table header
     * @type {boolean}
     * @memberof Column
     */
    hideLabel?: boolean | null;
    /**
     * 
     * @type {ColumnDataType}
     * @memberof Column
     */
    dataType?: ColumnDataType;
    /**
     * The default string to display when value is None
     * @type {string}
     * @memberof Column
     */
    emptyText?: string | null;
    /**
     * The unit to display in a chart
     * @type {string}
     * @memberof Column
     */
    unit?: string | null;
}

export function ColumnFromJSON(json: any): Column {
    return ColumnFromJSONTyped(json, false);
}

export function ColumnFromJSONTyped(json: any, ignoreDiscriminator: boolean): Column {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'keyPath': !exists(json, 'keyPath') ? undefined : json['keyPath'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'displayType': !exists(json, 'displayType') ? undefined : ColumnDisplayTypeFromJSON(json['displayType']),
        'style': !exists(json, 'style') ? undefined : ColumnStyleFromJSON(json['style']),
        'to': !exists(json, 'to') ? undefined : json['to'],
        'hideLabel': !exists(json, 'hideLabel') ? undefined : json['hideLabel'],
        'dataType': !exists(json, 'dataType') ? undefined : ColumnDataTypeFromJSON(json['dataType']),
        'emptyText': !exists(json, 'emptyText') ? undefined : json['emptyText'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
    };
}

export function ColumnToJSON(value?: Column | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'keyPath': value.keyPath,
        'label': value.label,
        'displayType': ColumnDisplayTypeToJSON(value.displayType),
        'style': ColumnStyleToJSON(value.style),
        'to': value.to,
        'hideLabel': value.hideLabel,
        'dataType': ColumnDataTypeToJSON(value.dataType),
        'emptyText': value.emptyText,
        'unit': value.unit,
    };
}

