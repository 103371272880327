import { Configuration, ConfigurationParameters } from 'api/generated';

function getApiConf(conf: ConfigurationParameters = {}) {
  return new Configuration({
    basePath: process.env.REACT_APP_API_HOST,
    ...conf,
  });
}

export default getApiConf;
