import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import { ColumnCount } from 'types/grids';
import { createColumnCountClass } from 'utils/GridUtils';
import LoadingBlock from './LoadingBlock';

interface LoadingGridProps {
  columnCount?: ColumnCount;
  style?: CSSProperties | undefined;
  tileCount?: number;
}

const LoadingGrid = function LoadingGrid({
  columnCount,
  style,
  tileCount,
}: LoadingGridProps) {
  const tiles = [...Array(tileCount || 9).keys()];
  return (
    <div
      className={classNames(
        'grid gap-3',
        columnCount
          ? createColumnCountClass(columnCount)
          : 'grid-cols-2 xs:grid-cols:2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7',
      )}
      style={style}
    >
      {tiles.map((tile) => (
        <LoadingBlock
          key={`tile-${tile}`}
          className="h-full w-full rounded-md aspect-square"
        />
      ))}
    </div>
  );
};

LoadingGrid.defaultProps = {
  columnCount: undefined,
  style: undefined,
  tileCount: 9,
};

export default LoadingGrid;
