import { AssetResponse, DatasetStatusEnum } from 'api/generated';
import Button from 'components/Button';
import DatasetDropdown from 'components/DatasetDropdown';
import FadeTransition from 'components/FadeTransition';
import ImageGrid from 'components/ImageGrid';
import Main from 'components/Main';
import PageHeader from 'components/PageHeader';
import SectionHeader from 'components/SectionHeader';
import AssetDetailSidebar from 'components/Sidebar/AssetDetailSidebar';
import TextInput from 'components/TextInput';
import SidebarContext from 'context/SidebarContext';
import EmbeddingDropdown from 'pages/concepts/create/components/EmbeddingDropdown';
import { useGetSimilarImagesGroupedInfiniteQuery } from 'pages/similarity-search/queries';
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const DEFAULT_LIMIT = 100;

const SimilaritySearch = function SimilaritySearch() {
  const [file, setFile] = useState<File>();
  const [selectedAsset, setSelectedAsset] = useState<AssetResponse>();
  const [datasetId, setDatasetId] = useState<string | null>(null);
  const [embeddingId, setEmbeddingId] = useState<string | null>(null);
  const [metadataKey, setMetadataKey] = useState<string>();
  const [limit, setLimit] = useState<number>(DEFAULT_LIMIT);

  const {
    data,
    refetch: getSimilarImages,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetSimilarImagesGroupedInfiniteQuery(
    limit,
    embeddingId!,
    file!,
    metadataKey,
  );

  const { setRightSidebarOpen } = useContext(SidebarContext);

  useEffect(() => {
    setRightSidebarOpen(Boolean(selectedAsset));
  }, [selectedAsset]);

  const images = useMemo(
    () => data?.pages.slice(-1)[0].data.flatMap((d) => d.items),
    [data],
  );

  const results = useMemo(
    () => (
      <div className="pt-4">
        <div className="pt-2">
          <ImageGrid
            images={images}
            onClick={setSelectedAsset}
            selected={selectedAsset ? [selectedAsset] : undefined}
            hasMore
            fetchNextImages={fetchNextPage}
            isLoadingMore={isFetchingNextPage}
            loadMoreButton
          />
        </div>
      </div>
    ),
    [
      images,
      isFetchingNextPage,
      selectedAsset,
      fetchNextPage,
      setSelectedAsset,
    ],
  );

  return (
    <Main
      rightSidebar={
        selectedAsset && (
          <AssetDetailSidebar asset={selectedAsset} datasetId={datasetId} />
        )
      }
    >
      <div className="max-w-8xl mx-auto">
        <PageHeader title={<h1>Similarity Search</h1>} backButtonTo="" />
        <div className="pb-24">
          <div className="mt-6 px-6 py-4 bg-white rounded-md 2xl:max-w-8xl mx-auto">
            <div className="py-2 max-w-sm">
              <DatasetDropdown
                selectedId={datasetId}
                setSelectedId={(selectedId) => {
                  setEmbeddingId(null);
                  setDatasetId(selectedId);
                }}
                statusesToInclude={[DatasetStatusEnum.Ready]}
              />
            </div>
            <div className="py-2 max-w-sm hidden">
              <EmbeddingDropdown
                datasetId={datasetId}
                selectedId={embeddingId}
                setSelectedId={(selectedId) => {
                  setEmbeddingId(selectedId);
                }}
              />
            </div>
            <div className="py-2 max-w-sm hidden">
              <TextInput
                id="group_by_metadata_key"
                name="group_by_metadata_key"
                label="Group By Metadata Key"
                defaultValue=""
                placeholder="Metadata Key"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setMetadataKey(e.target.value)
                }
              />
            </div>
            <div className="py-2 max-w-sm hidden">
              <TextInput
                id="limit"
                name="limit"
                label="Limit"
                value={`${limit}`}
                type="number"
                placeholder="Grouped Results Limit"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setLimit(parseInt(e.target.value, 10))
                }
              />
            </div>
            <div className="py-2 max-w-sm">
              <label
                htmlFor="image-to-search"
                className="block text-sm font-medium text-gray-700"
              >
                Image to Search
              </label>
              <input
                className="py-2 text-sm"
                id="image-to-search"
                type="file"
                name="file"
                onChange={(event) => setFile(event.target.files?.[0])}
              />
            </div>
            <div className="py-4 max-w-sm">
              <Button
                type="submit"
                disabled={!(embeddingId && file)}
                onClick={() => getSimilarImages()}
                loading={isLoading}
                className="w-36"
              >
                Search
              </Button>
            </div>
          </div>
          <div className="mt-8">
            <FadeTransition show={Boolean(data && !isLoading)} appear>
              <div className="px-6 pt-4 pb-8 bg-white rounded-md">
                <SectionHeader title="Results" />
                {results}
              </div>
            </FadeTransition>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default SimilaritySearch;
