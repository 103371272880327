import {
  AssetResponse,
  FullConceptResponseUI,
  FullVersionResponse,
  LabelEnum,
} from 'api/generated';
import classNames from 'classnames';
import EditableTextView from 'components/EditableText/EditableTextView';
import Main from 'components/Main';
import Tabs from 'components/Tabs';
import RefineSidebarContext from 'context/RefineSidebarContext';
import { useSidebarAssetContext } from 'context/SidebarAssetContext';
import SidebarContext from 'context/SidebarContext';
import useSidebarAssetPreview from 'hooks/useSidebarAssetPreview';
import AddExamples from 'pages/concepts/detail/tabs/Examples/AddExamples';
import {
  getConceptQueryKey,
  updateConceptMutation,
  useGetLabelsForVersionInfiniteQuery,
  useGetLabelsForVersionInfiniteQueryKey,
} from 'pages/concepts/queries';
import {
  ConceptActionState,
  LabelCandidateStatus,
  TabsValues,
} from 'pages/concepts/types';
import { useGetConceptPrediction } from 'queries/concept-prediction';
import { useGetDatasetById } from 'queries/datasets';
import { useUpdateConceptVersionLabelsMutation } from 'queries/labels';
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { evaluateFlag } from 'feature_flags/FeatureFlags';
import { FeatureFlag } from 'feature_flags/FeatureFlagConfig';
import useGetConceptSidebarComponent from '../hooks/useGetConceptSidebarComponent';
import ConceptDetailsTitle from './Title';
import PredictionEstimates from './tabs/ConceptPredictionChart/PredictionEstimates';
import { convertEstimatesToChartData } from './tabs/ConceptPredictionChart/utils';
import Examples from './tabs/Examples';
import ContinueLabelingSingleCandidate from './tabs/Examples/ContinueLabeling/ContinueLabelingSingleCandidate';
import TopContent from './tabs/TopContent/TopContent';

interface ContentProps {
  conceptId: string;
  conceptVersionId: string | 'latest';
  version: FullVersionResponse;
  concept: FullConceptResponseUI;
  refreshVersion: () => void;
  loadingGrid: ReactElement<any, any>;
}

const TAB_VALUES = [
  {
    name: TabsValues.TopContent,
    dataTestId: 'concept-detail-page-top-content-tab',
  },
  {
    name: TabsValues.Labels,
    dataTestId: 'concept-detail-page-labels-tab',
  },
];

const ConceptContent: React.FC<ContentProps> = function ConceptContent({
  conceptVersionId,
  conceptId,
  version,
  concept,
  refreshVersion,
  loadingGrid,
}) {
  const isConceptPredictionGraphEnabled = evaluateFlag(
    FeatureFlag.ENABLE_CONCEPT_PREDICTION_GRAPH,
  );

  const isFirstMount = useRef<boolean>(true);
  const { rightSidebarOpen, setRightSidebarOpen } = useContext(SidebarContext);
  const { setOnLabelFun } = useContext(RefineSidebarContext);
  const { openSidebarAssetPreview, sidebarPreviewAsset } =
    useSidebarAssetPreview();
  const { setSelectedAsset: setSidebarAsset, selectedAsset: sidebarAsset } =
    useSidebarAssetContext();
  const [isConceptUnlabeled, setIsConceptUnlabeled] = useState<boolean>(
    !concept.hasNegativeLabel && !concept.hasPositiveLabel,
  );
  const { data: dataset } = useGetDatasetById(version.dataset.id);
  const [activeTab, setActiveTab] = useState<TabsValues>(TabsValues.TopContent);
  const [actionState, setActionState] = useState<ConceptActionState>();
  const [displayChartLoader, setDisplayChartLoader] = useState<boolean>(false);
  const [hasFetchingLoadingIndicator, setHasFetchingLoadingIndicator] =
    useState<boolean>(false);
  const [labelCandidatesStatus, setLabelCandidatesStatus] =
    useState<LabelCandidateStatus>('none');
  const [labelFilters, setLabelFilters] = useState<LabelEnum[]>([LabelEnum._1]);
  const [shouldRefetchLabels, setShouldRefetchLabels] =
    useState<boolean>(false);
  const [shouldRefetchPredictions, setShouldRefetchPredictions] =
    useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    data: labelData,
    isLoading: areLabelsLoading,
    isRefetching: areLabelsRefetching,
    fetchNextPage: fetchNextLabelsPage,
    refetch: refetchLabels,
    isError: isErrorFetchingLabels,
  } = useGetLabelsForVersionInfiniteQuery(
    conceptVersionId,
    labelFilters,
    'getConceptVersionLabelsFromConceptDetailPage',
  );
  const {
    data: predictions,
    refetch: refetchPredictions,
    isRefetching: isRefetchingPredictions,
  } = useGetConceptPrediction(
    conceptId,
    undefined,
    undefined,
    undefined,
    isConceptPredictionGraphEnabled && Boolean(!isConceptUnlabeled),
  );
  const { mutate: updateLabels, isLoading } =
    useUpdateConceptVersionLabelsMutation();
  const { mutate: updateConcept } = updateConceptMutation(conceptId);

  const refreshLabels = () => {
    // Refetch the version to update the minimum-labels-to-query warning
    queryClient.invalidateQueries(
      useGetLabelsForVersionInfiniteQueryKey(conceptVersionId),
    );
    return Promise.all([refreshVersion(), refetchLabels()]);
  };

  const assetCount = dataset ? dataset.imageCount! + dataset.videoCount! : 0;

  const finishLabelingCandidates = () => {
    setActionState(ConceptActionState.View);
    if (isConceptPredictionGraphEnabled) {
      refetchPredictions();
    }
    setRightSidebarOpen(false);
  };

  const contentSidebarComponent = useGetConceptSidebarComponent({
    activeTab,
    conceptId,
    conceptVersionId,
    concept,
    version,
    actionState,
    hasFetchingLoadingIndicator,
    setShouldRefetchPredictions,
    finishLabelingCandidates,
    sidebarAsset,
    sidebarPreviewAsset,
  });

  useEffect(() => {
    const totalLabels = labelData?.pages.length
      ? labelData.pages[labelData.pages.length - 1].meta?.page?.total
      : 0;

    if (!areLabelsLoading && totalLabels === 0 && !isErrorFetchingLabels) {
      setActionState(ConceptActionState.Add);
      isFirstMount.current = false;
    } else if (
      isFirstMount.current &&
      ((!areLabelsLoading && totalLabels && totalLabels > 0) ||
        isErrorFetchingLabels)
    ) {
      setActionState(ConceptActionState.View);
      isFirstMount.current = false;
    }
  }, [areLabelsLoading, labelData, isErrorFetchingLabels]);

  const breadcrumbs = [
    {
      label: 'Concepts',
      to: '/concepts',
    },
    {
      label: version ? `${version?.concept?.name}` : 'Untitled',
      // TODO: reference 'latest' instead of version id if applicable
      to: `/concepts/${conceptId}/versions/${conceptVersionId}`,
    },
  ];

  const addSimilarImages = (positive: string[], negative: string[]) => {
    const onSuccess = async () => {
      // Refetch labels so that the UX doesn't jump when we go back to the labels view
      await refreshLabels();
      // Invalidate the version query to update the isQueryable value
      setActionState(ConceptActionState.View);
      if (isConceptUnlabeled) {
        setIsConceptUnlabeled(false);
      }
    };
    updateLabels(
      {
        conceptVersionId,
        updateConceptVersionLabelsRequest: {
          labels: [
            ...positive.map((id) => ({
              coactiveImageId: id,
              label: LabelEnum._1,
            })),
            ...negative.map((id) => ({
              coactiveImageId: id,
              label: LabelEnum._0,
            })),
          ],
        },
      },
      {
        onSuccess,
        onError: async () => {
          // TODO: Better error handling here.
          // For now, assume that an error response means that some of the images
          // have already been added to the concept and treat it like a success.
          await onSuccess();
        },
      },
    );
  };

  useEffect(() => {
    if (shouldRefetchLabels) {
      refetchLabels();

      setShouldRefetchLabels(false);
    }
  }, [shouldRefetchLabels]);

  useEffect(() => {
    if (
      isConceptPredictionGraphEnabled &&
      !isRefetchingPredictions &&
      shouldRefetchPredictions
    ) {
      refetchPredictions();
      setShouldRefetchPredictions(false);
    }
  }, [isConceptPredictionGraphEnabled, labelData, shouldRefetchPredictions]);

  const updateDescription = async (description: string): Promise<void> =>
    new Promise((resolve, reject) => {
      updateConcept(
        { conceptId, updateConceptRequest: { description } },
        {
          onSuccess: async () => {
            queryClient.setQueryData(getConceptQueryKey(concept.conceptId), {
              ...concept,
              description,
            });
            await queryClient.invalidateQueries(
              getConceptQueryKey(concept.conceptId),
              { refetchActive: true, refetchInactive: true },
            );
            resolve();
          },
          onError: () => {
            reject();
          },
        },
      );
    });

  useEffect(() => {
    if (actionState !== ConceptActionState.Label) {
      setOnLabelFun(() => refreshLabels as any);
    }
  }, [actionState]);

  const exitAddExamples = useCallback(
    (modifiedLabels?: boolean) => {
      if (modifiedLabels) {
        refreshLabels();
      }
      setRightSidebarOpen(false);
      setActionState(ConceptActionState.View);
    },
    [refreshLabels],
  );

  const handleSelectAsset = (asset: AssetResponse) => {
    setSidebarAsset(asset);
    openSidebarAssetPreview({ asset });
  };

  const handleSelectTab = (tab: TabsValues) => {
    if (tab === activeTab) return;
    if (rightSidebarOpen) {
      setRightSidebarOpen(false);
    }
    if (tab === TabsValues.Labels) {
      setActionState(ConceptActionState.View);
    }
    setLabelCandidatesStatus('none');
    setActiveTab(tab);
  };

  // Needed to temporarily hide the chart while sidebar displays as recharts recalculates on width change causing choppy animations
  useEffect(() => {
    setDisplayChartLoader(true);
    const timer = setTimeout(() => {
      setDisplayChartLoader(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [rightSidebarOpen]);

  const shouldDisplayChart =
    isConceptPredictionGraphEnabled && !isConceptUnlabeled;

  const getLabelTabContent = () => {
    switch (actionState) {
      case ConceptActionState.Add:
        return (
          <AddExamples
            conceptId={conceptId}
            conceptVersionId={conceptVersionId}
            conceptVersionPositiveLabelsCount={version.positiveLabelsCount}
            datasetId={concept.dataset.id}
            addLabels={addSimilarImages}
            isAddingLabels={isLoading}
            exit={exitAddExamples}
            setShouldRefetchLabels={setShouldRefetchLabels}
          />
        );
      case ConceptActionState.Label:
        return (
          <>
            <ContinueLabelingSingleCandidate
              conceptVersionId={conceptVersionId}
              refreshLabels={refreshLabels}
              setLabelCandidatesStatus={setLabelCandidatesStatus}
              setHasCandidatesLoadingIndicator={setHasFetchingLoadingIndicator}
              setActionState={setActionState}
            />
            <Examples
              actionState={actionState!}
              version={version}
              data={labelData}
              isError={isErrorFetchingLabels}
              isLoading={areLabelsLoading}
              isRefetching={areLabelsRefetching}
              fetchNextPage={fetchNextLabelsPage}
              labelFilters={labelFilters}
              setLabelFilters={setLabelFilters}
              loadingGrid={loadingGrid}
              labelCandidatesStatus={labelCandidatesStatus}
              setLabelCandidatesStatus={setLabelCandidatesStatus}
              hasHeadLoadingIndicator={hasFetchingLoadingIndicator}
            />
          </>
        );
      case ConceptActionState.View:
        return (
          <Examples
            actionState={actionState!}
            version={version}
            data={labelData}
            isError={isErrorFetchingLabels}
            isLoading={areLabelsLoading}
            isRefetching={areLabelsRefetching}
            fetchNextPage={fetchNextLabelsPage}
            labelFilters={labelFilters}
            setLabelFilters={setLabelFilters}
            loadingGrid={loadingGrid}
            labelCandidatesStatus={labelCandidatesStatus}
            setLabelCandidatesStatus={setLabelCandidatesStatus}
            hasHeadLoadingIndicator={hasFetchingLoadingIndicator}
            dataTestId="concept-detail-page-label-examples"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Main
      title={
        <ConceptDetailsTitle
          conceptVersionId={conceptVersionId}
          isConceptUnlabeled={isConceptUnlabeled}
          version={version}
          actionState={actionState!}
          conceptId={conceptId}
          setActionState={setActionState}
          labelCandidatesStatus={labelCandidatesStatus}
          setLabelCandidatesStatus={setLabelCandidatesStatus}
          setHasFetchingLoadingIndicator={setHasFetchingLoadingIndicator}
          disabled={!labelData}
          setActiveTab={setActiveTab}
        />
      }
      backButtonTo="/concepts"
      breadcrumbs={breadcrumbs}
      mainClassName="overflow-x-visible overflow-y-visible"
      rightSidebar={contentSidebarComponent()}
    >
      <div className="max-w-8xl mx-auto">
        <div
          className={classNames('mt-2', {
            'border-b pb-5': isConceptUnlabeled,
          })}
        >
          <EditableTextView
            id="concept-description"
            name="concept description"
            updateValue={updateDescription}
            value={concept?.description}
            placeholder="This concept does not have a description"
            dataTestId="concept-detail-page-description"
          />
        </div>
        <div className="flex relative">
          <div
            className={classNames(
              'h-full',
              rightSidebarOpen ? 'w-full' : 'w-1/2',
            )}
          >
            {shouldDisplayChart && (
              <PredictionEstimates
                assetCount={assetCount}
                chartData={
                  predictions?.estimates && !isRefetchingPredictions
                    ? convertEstimatesToChartData(
                        [...predictions.estimates].reverse(),
                      )
                    : undefined
                }
                displayChartLoader={displayChartLoader}
                isRefetchingPredictions={isRefetchingPredictions}
              />
            )}
          </div>
        </div>
        {isConceptUnlabeled && actionState === ConceptActionState.Add ? (
          <AddExamples
            conceptId={conceptId}
            conceptVersionId={conceptVersionId}
            conceptVersionPositiveLabelsCount={version.positiveLabelsCount}
            datasetId={concept.dataset.id}
            addLabels={addSimilarImages}
            isAddingLabels={isLoading}
            exit={exitAddExamples}
          />
        ) : (
          <>
            <Tabs
              tabs={TAB_VALUES}
              active={activeTab}
              onClick={(tab) => handleSelectTab(tab)}
            />
            {activeTab === TabsValues.Labels ? (
              getLabelTabContent()
            ) : (
              <TopContent
                conceptId={conceptId}
                datasetId={version.dataset.id}
                onAssetClick={handleSelectAsset}
                enabled={
                  activeTab === TabsValues.TopContent && !isConceptUnlabeled
                }
              />
            )}
          </>
        )}
      </div>
    </Main>
  );
};

export default ConceptContent;
