import { EnvelopeIcon } from '@heroicons/react/24/outline';
import CopyActionButton from 'components/ClipboardActionButton';
import AddAssetsViewContainer from 'pages/datasets/components/AddAssetsViewContainer';
import React from 'react';

interface TeammateInvitedProps {
  invitationUrl: string | null | undefined;
  invitedUserEmail: string;
}

/**
 * A component to show a confirmation screen that a teammate has been invited to finish the rest of the dataset creation flow.
 */
const TeammateInvited = function TeammateInvited({
  invitationUrl,
  invitedUserEmail,
}: TeammateInvitedProps) {
  return (
    <AddAssetsViewContainer
      title={<span>Invite sent to {invitedUserEmail}</span>}
      icon={EnvelopeIcon}
      isCreate
    >
      <p className="text-sm pt-4 pb-8">
        We&apos;ve emailed an invitation link to {invitedUserEmail} so they can
        join and finish right where you left off. The link expires in two days.
        We&apos;ll notify you when the dataset has been created.
      </p>
      {invitationUrl && (
        <CopyActionButton
          id="copy-invitation"
          text={invitationUrl}
          buttonStyle="secondary"
        >
          Copy invitation link
        </CopyActionButton>
      )}
    </AddAssetsViewContainer>
  );
};

export default TeammateInvited;
