import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { DatasetStatusEnum } from 'api/generated';
import Button from 'components/Button';
import DatasetDropdown from 'components/DatasetDropdown';
import TextArea from 'components/TextArea';
import TextInput from 'components/TextInput';
import useQueryParams from 'hooks/useQueryParams';
import Page from 'pages/datasets/components/Page';
import {
  useCreateCategoryMutation,
  useValidateCategoryNameMutation,
} from 'queries/dynamic-tags';
import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  inputValidationCallbackFactory,
  isValidationResponseInvalid,
} from 'utils/InputValidationUtils';

const TEXT_AREA_INPUT_PLACEHOLDER =
  'Enter each dynamic tag on a new line with text prompts after a colon. For example:\ndynamic tag: text prompt, another text prompt\ndynamic tag\ndynamic tag: text prompt';

const CATEGORY_NAME_PLACEHOLDER =
  'Choose a name for this category of dynamic tags';
/**
 * The top-level component for the create dynamic tag category flow.
 */

const CreateDynamicTagCategory = function CreateDynamicTagCategory() {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const initialDatasetId = queryParams.get('datasetId');
  const [datasetId, setDatasetId] = useState<string | null>(
    initialDatasetId || null,
  );
  const [categoryName, setCategoryName] = useState<string>();
  const [dynamicTagsRawText, setDynamicTagsRawText] = useState<string>();
  const [dynamicTagsError, setDynamicTagsError] = useState<string | null>(null);

  const { mutate: createDynamicTagCategory } = useCreateCategoryMutation();
  const {
    mutate: validateCategoryName,
    isLoading: isCategoryNameValidating,
    data,
  } = useValidateCategoryNameMutation();

  const isCategoryNameInvalid = isValidationResponseInvalid(data);
  const inputValidation = inputValidationCallbackFactory<string>(
    categoryName,
    validateCategoryName,
    (value: string) => ({ validateNameRequest: { name: value } }),
    [],
  );

  const createDynamicTagCategoryFn = async (dynamicTagGroups: {
    [name: string]: string[];
  }): Promise<void> =>
    new Promise((resolve, reject) => {
      if (!datasetId || !categoryName) {
        reject();
      }
      createDynamicTagCategory(
        {
          createCategoryRequest: {
            name: categoryName!,
            datasetId: datasetId!,
            dynamicTags: dynamicTagGroups,
          },
        },
        {
          onSuccess: (response: any) => {
            navigate(`/dynamic-tag-categories/${response.categoryId}`);
            toast.success('Success! Your category has been added!');
          },
          onError: async (error: any) => {
            const errorMessage = await error.response?.json();
            setDynamicTagsError(
              errorMessage?.detail ||
                'Category could not be created. Please try again.',
            );
          },
        },
      );
    });

  // TODO: figure out why error state update is lagging
  let hasError = false;
  const onCreateCategory = () => {
    const dynamicTagGroups = {};

    const addDynamicTagGroup = (rawTagInfo: string[]): void => {
      const [dynamicTagName, ...rest]: string[] = rawTagInfo;

      if (dynamicTagName in dynamicTagGroups) {
        setDynamicTagsError(`Duplicate dynamic tag names: ${dynamicTagName}`);
        hasError = true;
        return;
      }
      if (rest.length > 1) {
        setDynamicTagsError(
          `Multiple colons on dynamic tag: ${dynamicTagName}`,
        );
        hasError = true;
        return;
      }

      // if no text prompts are given, set it to the name of the dynamic tag
      const textPrompts =
        rest.length && rest[0]
          ? rest[0]
              .split(',')
              .map((e) => e.trim())
              .filter((e) => e)
          : [dynamicTagName];

      const parsedTextPrompts = new Set();
      const duplicatedTextPrompts: string[] = [];
      textPrompts.forEach((element) => {
        if (parsedTextPrompts.has(element)) {
          duplicatedTextPrompts.push(element);
        } else {
          parsedTextPrompts.add(element);
        }
      });

      if (duplicatedTextPrompts.length) {
        setDynamicTagsError(
          `Duplicate text prompts: ${duplicatedTextPrompts.join(', ')}`,
        );
        hasError = true;
        return;
      }

      if (dynamicTagName) {
        dynamicTagGroups[dynamicTagName] = textPrompts;
      }
      setDynamicTagsError(null);
      hasError = false;
    };

    const dynamicTagLines =
      dynamicTagsRawText?.split('\n').map((text) => text.trim()) || [];

    dynamicTagLines.forEach((line) => {
      const tagInfo = line.split(':').map((e) => e.trim());
      switch (tagInfo.length) {
        case 0:
          return; // skip this line because it is empty
        case 1:
        case 2:
          addDynamicTagGroup(tagInfo);
          break;
        default:
          setDynamicTagsError(`Multiple colons on dynamic tag: ${tagInfo[0]}`);
          hasError = true;
          break;
      }
    });

    if (hasError || !categoryName || !datasetId) {
      // TODO: better error handling
      return;
    }

    createDynamicTagCategoryFn(dynamicTagGroups);
  };

  return (
    <Page title="Add dynamic tag category">
      <div className="ml-4 mr-4">
        <div className="mt-12 mb-8">
          <DatasetDropdown
            dataTestId="create-category-dataset-dropdown"
            customLabel="Select dataset"
            selectedId={datasetId}
            setSelectedId={setDatasetId}
            statusesToInclude={[DatasetStatusEnum.Ready]}
            disabled={Boolean(initialDatasetId)}
          />
        </div>
        <div className="mb-8">
          <TextInput
            dataTestId="create-category-name-input"
            id="category-name"
            name="category-name"
            label="Category name"
            placeholder={CATEGORY_NAME_PLACEHOLDER}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCategoryName(e.target.value.trim())
            }
            validation={inputValidation}
          />
        </div>
        <div className="mb-12">
          <TextArea
            id="dynamic-tags-raw"
            name="dynamic-tags-raw"
            label="Dynamic tags"
            className="h-72"
            dataTestId="create-category-dynamic-tags-textarea"
            placeholder={TEXT_AREA_INPUT_PLACEHOLDER}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setDynamicTagsRawText(e.target.value)
            }
            error={dynamicTagsError || undefined}
          />
        </div>
        <div className="border-t w-full text-right pt-4 pb-4">
          <Button
            dataTestId="create-category-create-button"
            onClick={onCreateCategory}
            rightIcon={ArrowRightIcon}
            disabled={
              !(datasetId && categoryName) ||
              isCategoryNameValidating ||
              isCategoryNameInvalid
            }
          >
            Add category
          </Button>
        </div>
      </div>
    </Page>
  );
};

export default CreateDynamicTagCategory;
