import { Route, Routes } from 'react-router-dom';
import React from 'react';
import Page404 from 'pages/Page404';
import CreateConcepts from './create/CreateConcepts';
import CreateConcept from './create/CreateConcept';
import Concept from './detail';
import ConceptsList from './list';

const ConceptRouter = function ConceptRouter() {
  return (
    <Routes>
      <Route path="" element={<ConceptsList />} />
      <Route path="/create" element={<CreateConcept />} />
      <Route path="/:conceptId/create" element={<CreateConcept />} />
      <Route path="/upload" element={<CreateConcepts />} />
      <Route
        path="/:conceptId/versions/:conceptVersionId"
        element={<Concept />}
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default ConceptRouter;
