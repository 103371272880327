export const abbreviatedNumberFormatter = (number: number) => {
  if (number >= 1000000000) {
    const formatted = number / 1000000000;
    return formatted % 1 === 0
      ? `${formatted.toFixed(0)}B`
      : `${formatted.toFixed(1)}B`;
  }
  if (number >= 1000000) {
    const formatted = number / 1000000;
    return formatted % 1 === 0
      ? `${formatted.toFixed(0)}M`
      : `${formatted.toFixed(1)}M`;
  }
  if (number >= 1000) {
    const formatted = number / 1000;
    return formatted % 1 === 0
      ? `${formatted.toFixed(0)}K`
      : `${formatted.toFixed(1)}K`;
  }
  return number.toString();
};
