/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetResponse,
    AssetResponseFromJSON,
    AssetResponseFromJSONTyped,
    AssetResponseToJSON,
} from './AssetResponse';

/**
 * One similarity search item, with optional group by value and a list of items all matching
 * the group by value
 * @export
 * @interface SimilaritySearchItem
 */
export interface SimilaritySearchItem {
    /**
     * The value of key that's grouped on
     * @type {string}
     * @memberof SimilaritySearchItem
     */
    groupByValue?: string | null;
    /**
     * List of assets
     * @type {Array<AssetResponse>}
     * @memberof SimilaritySearchItem
     */
    items: Array<AssetResponse>;
}

export function SimilaritySearchItemFromJSON(json: any): SimilaritySearchItem {
    return SimilaritySearchItemFromJSONTyped(json, false);
}

export function SimilaritySearchItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilaritySearchItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupByValue': !exists(json, 'groupByValue') ? undefined : json['groupByValue'],
        'items': ((json['items'] as Array<any>).map(AssetResponseFromJSON)),
    };
}

export function SimilaritySearchItemToJSON(value?: SimilaritySearchItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupByValue': value.groupByValue,
        'items': ((value.items as Array<any>).map(AssetResponseToJSON)),
    };
}

