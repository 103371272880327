import {
  AuthApi,
  CreateOrgInvitationOperationRequest,
  DeleteOrgInvitationRequest,
  DeleteOrgMemberRequest,
  DemoteOrgMemberRequest,
  UpgradeOrgMemberRequest,
  GetOrgMembersRequest,
} from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';

export const useGetOrgMembers = (input: GetOrgMembersRequest) => {
  const { initializeAPI } = useAPIContext();
  const query = useQuery(
    ['getOrgMembers', JSON.stringify(input)],
    async ({ signal }) => {
      const api = await initializeAPI<AuthApi>(AuthApi);
      return api.getOrgMembers(input, { signal });
    },
    {
      enabled: true,
    },
  );
  return query;
};

export const useCreateOrgInvitationMutation = () => {
  const { initializeAPI } = useAPIContext();

  return useMutation(
    ['createOrgInvitation'],
    async (variables: CreateOrgInvitationOperationRequest) => {
      const api = await initializeAPI<AuthApi>(AuthApi);
      return api.createOrgInvitation(variables);
    },
  );
};

export const useGetOrgInvitations = () => {
  const { initializeAPI } = useAPIContext();
  const query = useQuery(
    ['getOrgInvitations'],
    async ({ signal }) => {
      const api = await initializeAPI<AuthApi>(AuthApi);
      return api.getOrgInvitations(
        {
          offset: 0,
          limit: 50,
        },
        { signal },
      );
    },
    {
      enabled: true,
    },
  );
  return query;
};

export const useDeleteOrgInvitationMutation = () => {
  const { initializeAPI } = useAPIContext();

  return useMutation(
    ['deleteOrgInvitation'],
    async (variables: DeleteOrgInvitationRequest) => {
      const api = await initializeAPI<AuthApi>(AuthApi);
      return api.deleteOrgInvitation(variables);
    },
  );
};

export const useDeleteOrgMemberMutation = () => {
  const { initializeAPI } = useAPIContext();

  return useMutation(
    ['deleteOrgMember'],
    async (variables: DeleteOrgMemberRequest) => {
      const api = await initializeAPI<AuthApi>(AuthApi);
      return api.deleteOrgMember(variables);
    },
  );
};

export const useMakeAdminMutation = () => {
  const { initializeAPI } = useAPIContext();

  return useMutation(
    ['upgradeOrgMember'],
    async (variables: UpgradeOrgMemberRequest) => {
      const api = await initializeAPI<AuthApi>(AuthApi);
      return api.upgradeOrgMember(variables);
    },
  );
};

export const useRemoveAdminMutation = () => {
  const { initializeAPI } = useAPIContext();

  return useMutation(
    ['deleteOrgMember'],
    async (variables: DemoteOrgMemberRequest) => {
      const api = await initializeAPI<AuthApi>(AuthApi);
      return api.demoteOrgMember(variables);
    },
  );
};
