import { useAddAssetsNavigationContext } from 'pages/datasets/add-assets/context/AddAssetsNavigationContext';
import DatasetCreated from 'pages/datasets/add-assets/views/ConfirmationView/DatasetCreated';
import TeammateInvited from 'pages/datasets/add-assets/views/ConfirmationView/TeammateInvited';
import { useGetDatasetUpdate } from 'queries/dataset-update';
import { useGetDatasetById } from 'queries/datasets';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

/**
 * The view presented to the user once they've reached a terminal state in the add assets/create dataset flow:
 * 1. A user was invited
 * 2. A request was sent to Coactive to help onboard data from an unsupported source
 * 3. The dataset was properly configured
 */
const ConfirmationView = function ConfirmationView() {
  const { datasetId, datasetUpdateId } = useParams();
  const { data: datasetUpdate } = useGetDatasetUpdate(datasetUpdateId);
  const { data: dataset } = useGetDatasetById(datasetId);
  const { setNext, flowEndReason } = useAddAssetsNavigationContext();

  if (!dataset || !datasetUpdateId) {
    return null;
  }

  useEffect(() => {
    setNext(undefined);
  }, []);

  return flowEndReason === 'teammate invited' &&
    datasetUpdate?.invitedUserEmail ? (
    <TeammateInvited
      invitedUserEmail={datasetUpdate?.invitedUserEmail}
      invitationUrl={datasetUpdate.invitationUrl}
    />
  ) : (
    <DatasetCreated
      datasetId={dataset.datasetId}
      datasetName={dataset.name}
      datasetStatus={dataset.status}
    />
  );
};

export default ConfirmationView;
