import {
  LabelApi,
  UpdateConceptVersionLabelsOperationRequest,
} from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';

export const useUpdateConceptVersionLabelsMutation = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    ['updateConceptVersionLabels'],
    async (variables: UpdateConceptVersionLabelsOperationRequest) => {
      const api = await initializeAPI<LabelApi>(LabelApi);
      return api.updateConceptVersionLabels(variables);
    },
  );
};

export const useGetLabelsForImageQuery = (
  coactiveImageId: string,
  conceptVersionIds?: string[],
  options?: { enabled: boolean },
) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    ['getConceptVersionLabelsForImage', coactiveImageId],
    async () => {
      const api = await initializeAPI<LabelApi>(LabelApi);
      return api.getConceptVersionLabelsForImage({ coactiveImageId });
    },
    {
      enabled: options
        ? options.enabled
        : Boolean(coactiveImageId && conceptVersionIds),
    },
  );
};
