/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface QueryResultsDownloadUrlResponse
 */
export interface QueryResultsDownloadUrlResponse {
    /**
     * Query id
     * @type {string}
     * @memberof QueryResultsDownloadUrlResponse
     */
    queryId: string;
    /**
     * Results download url
     * @type {string}
     * @memberof QueryResultsDownloadUrlResponse
     */
    downloadUrl: string;
}

export function QueryResultsDownloadUrlResponseFromJSON(json: any): QueryResultsDownloadUrlResponse {
    return QueryResultsDownloadUrlResponseFromJSONTyped(json, false);
}

export function QueryResultsDownloadUrlResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryResultsDownloadUrlResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'queryId': json['queryId'],
        'downloadUrl': json['downloadUrl'],
    };
}

export function QueryResultsDownloadUrlResponseToJSON(value?: QueryResultsDownloadUrlResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'queryId': value.queryId,
        'downloadUrl': value.downloadUrl,
    };
}

