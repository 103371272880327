const ESTIMATE_NAMES = [
  'Certain positive',
  'Uncertain positive',
  'Uncertain negative',
  'Certain negative',
];

const SEPARATOR_BAR = {
  name: '',
  value: 1,
  label: '',
};

// Estimate values need to be converted so log scale doesn't break
// 0 to 1 for min value and 1 to 1.3 so the bars look different on lower scales
const adjustEstimateValue = (val: number) => {
  if (val === 0) {
    return 1;
  }
  if (val === 1) {
    return 1.3;
  }
  return val;
};

export const convertEstimatesToChartData = (estimateValues: number[]) => {
  const chartData = estimateValues.map((estimate: number, idx: number) => ({
    name: ESTIMATE_NAMES[idx],
    value: adjustEstimateValue(estimate),
    label: String(estimate),
  }));

  chartData.splice(2, 0, SEPARATOR_BAR);

  return chartData;
};

export const convertAssetCountToDomainEnd = (assetCount: number) =>
  10 ** Math.ceil(Math.log10(assetCount));
