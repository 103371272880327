import SupportColumn from 'pages/datasets/components/SupportColumn';
import ConfirmationView from 'pages/datasets/add-assets/views/ConfirmationView';
import DataSourceView from 'pages/datasets/add-assets/views/DataSourceView';
import AccessView from 'pages/datasets/add-assets/views/AccessView';
import React from 'react';
import Page from 'pages/datasets/components/Page';
import SetupView from 'pages/datasets/create/views/SetupView';
import { useAddAssetsNavigationContext } from 'pages/datasets/add-assets/context/AddAssetsNavigationContext';
import { useGetDatasetUpdate } from 'queries/dataset-update';
import { useGetDatasetById } from 'queries/datasets';
import { useParams } from 'react-router-dom';

const ActiveView = () => {
  const { activeStep } = useAddAssetsNavigationContext();
  const { datasetId, datasetUpdateId } = useParams();
  const { isFetching: datasetUpdateIsFetching } =
    useGetDatasetUpdate(datasetUpdateId);
  const { isFetching: datasetIsFetching } = useGetDatasetById(datasetId);

  if ((datasetUpdateId && datasetUpdateIsFetching) || datasetIsFetching) {
    return null;
  }

  // Name can only be reached via this component by a user clicking the "Previous" button from 'Data Source'
  if (activeStep === 'Name') {
    return <SetupView />;
  }
  if (activeStep === 'Data Source') {
    return <DataSourceView />;
  }
  if (activeStep === 'Access') {
    return <AccessView />;
  }
  if (activeStep === 'Confirmation') {
    return <ConfirmationView />;
  }
  return null;
};

const AddAssets = function AddAssets() {
  return (
    <Page title="Create dataset" rightColumn={<SupportColumn />}>
      <ActiveView />
    </Page>
  );
};

export default AddAssets;
