import { RefObject, useEffect, useMemo, useRef } from 'react';

function usePreventMediaHeightJump<T extends HTMLElement>(
  enabled: boolean = true,
): {
  containerRef: RefObject<HTMLDivElement>;
  mediaRef: RefObject<T>;
  onMediaLoad: (() => void) | undefined;
} {
  const containerRef = useRef<HTMLDivElement>(null);
  const mediaRef = useRef<T>(null);
  const prevMediaHeight = useRef<number>();

  useEffect(() => {
    if (enabled && prevMediaHeight.current) {
      containerRef.current?.setAttribute(
        'style',
        `min-height: ${prevMediaHeight.current}px`,
      );
    }
  }, [enabled]);

  const onMediaLoad = useMemo(() => {
    if (!enabled) {
      return undefined;
    }
    return () => {
      if (enabled && mediaRef.current) {
        if (containerRef.current && mediaRef.current) {
          const imgHeight = mediaRef.current.offsetHeight;
          prevMediaHeight.current = imgHeight;
          containerRef.current.setAttribute(
            'style',
            `min-height: ${imgHeight}px`,
          );
        }
      }
    };
  }, [enabled]);

  return { containerRef, mediaRef, onMediaLoad };
}

export default usePreventMediaHeightJump;
