import React from 'react';

export function findById<T extends { value: string }>(
  lst: T[],
  id: string | undefined,
): T | undefined {
  return id ? lst?.find((itm: T) => itm.value === id) : undefined;
}

export function findByIds<T extends { value: string }>(
  lst: T[],
  ids: string[],
): T[] {
  const found = ids
    ? lst?.filter((itm: T) => ids.includes(itm.value))
    : undefined;
  if (found && found.length > 1) {
    // Put return value in the same order as `ids` arg
    return ids
      ?.map((id) => found.find((x) => x.value === id))
      .map((x) => x as T);
  }
  return found ?? [];
}

export function findByIdInGroup<
  GT extends string,
  O extends { value: string },
  G extends { value: GT; options: O[] },
>(lst: G[], id: string | undefined): [GT | undefined, O | undefined] {
  if (!id) {
    return [undefined, undefined];
  }
  let foundItem;
  lst.forEach((group) => {
    const item = group.options?.find((itm2) => itm2.value === id);
    if (item) {
      foundItem = [group.value, item];
    }
  });
  return foundItem ?? [undefined, undefined];
}

export function removeNulls<T>(lst: (T | undefined | null)[] | undefined): T[] {
  return lst?.filter(
    (it: T | null | undefined) => it !== null && it !== undefined,
  ) as T[];
}

export function sortCaseInsensitive<T>(
  arr: T[],
  getCompareStr: (item: T) => string = (t: T) => t as unknown as string,
): T[] {
  return arr.sort((val1, val2) =>
    getCompareStr(val1).toUpperCase() < getCompareStr(val2).toUpperCase()
      ? -1
      : 1,
  );
}

export function toEnglishList(
  list: string[] | undefined,
  itemStyle?: string,
): React.ReactElement | null {
  if (!list?.length) {
    return null;
  }
  if (list.length === 1) {
    return <span className={itemStyle}>{list[0]}</span>;
  }
  const head = [...list];
  const tail = list.pop();
  return (
    <span>
      {head
        .map((elem) => (
          <span className={itemStyle} key={elem}>
            {elem}
          </span>
        ))
        .join(', ')}{' '}
      and {tail}
    </span>
  );
}
