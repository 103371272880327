/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Accepted cloud storage types
 * 
 * s3 - AWS S3 bucket prefix
 * gs - Google Storage bucket prefix
 * @export
 */
export const DataSourceTypeEnum = {
    S3: 's3',
    Gs: 'gs',
    Csv: 'csv',
    Zip: 'zip'
} as const;
export type DataSourceTypeEnum = typeof DataSourceTypeEnum[keyof typeof DataSourceTypeEnum];


export function DataSourceTypeEnumFromJSON(json: any): DataSourceTypeEnum {
    return DataSourceTypeEnumFromJSONTyped(json, false);
}

export function DataSourceTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSourceTypeEnum {
    return json as DataSourceTypeEnum;
}

export function DataSourceTypeEnumToJSON(value?: DataSourceTypeEnum | null): any {
    return value as any;
}

