import React from 'react';
// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax
import AnimatedLogo from '-!svg-react-loader!assets/animations/coactive-loading.svg';

interface LogoLoaderProps {
  className?: string;
}

const LogoLoader: React.FC<LogoLoaderProps> = function LogoLoader({
  className,
}) {
  const Logo = AnimatedLogo as any;
  return <Logo className={className} />;
};

LogoLoader.defaultProps = {
  className: undefined,
};

export default LogoLoader;
