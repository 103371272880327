import React, { useMemo, useState } from 'react';
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import AuthLoadingWrapper from 'components/AuthLoadingWrapper';
import Page from 'components/Page';
import RequireAuth from 'components/RequireAuth';
import { APIContextWrapper } from 'context/APIContext';
import QueryClientProviderWrapper from 'context/QueryClientContext';
import { SensitiveImageContextWrapper } from 'context/SensitiveImageContext';
import { UserContextWrapper, useUserContext } from 'context/UserContext';
import Page404 from 'pages/Page404';
import ConceptRouter from 'pages/concepts';
import DatasetRouter from 'pages/datasets';
import Query from 'pages/queries';
import SettingsRouter from 'pages/settings';
import SimilaritySearch from 'pages/similarity-search';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import './App.scss';
import EndOfTrialModal from 'components/EndOfTrialModal';
import DynamicTagsRouter from 'pages/dynamic-tags';
import {
  buildUserContext,
  DEFAULT_USER_CONTEXT,
  evaluateFlag,
} from 'feature_flags/FeatureFlags';
import { FeatureFlag } from 'feature_flags/FeatureFlagConfig';

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_UI_DOMAIN ?? ''} // Domain of the login page
      clientId={process.env.REACT_APP_AUTH0_UI_CLIENT_ID ?? ''} // Client ID of the Coactive SPA application
      authorizationParams={{
        redirect_uri: window.location.origin,
        // The audience of the API to call (in this case, RBAC)
        audience: process.env.REACT_APP_AUTH0_UI_AUDIENCE ?? '',
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

const SettingsComponent: React.FC = () => (
  <RequireAuth>
    <SettingsRouter />
  </RequireAuth>
);

const DatasetsComponent: React.FC = () => (
  <RequireAuth>
    <DatasetRouter />
  </RequireAuth>
);

const ConceptsComponent: React.FC = () => (
  <RequireAuth>
    <ConceptRouter />
  </RequireAuth>
);

const DynamicTagsComponent: React.FC = () => (
  <RequireAuth>
    <DynamicTagsRouter />
  </RequireAuth>
);

const QueryComponent: React.FC = () => (
  <RequireAuth>
    <Query />
  </RequireAuth>
);

const SimsearchComponent: React.FC = () => (
  <RequireAuth>
    <SimilaritySearch />
  </RequireAuth>
);

const AppContent = function AppContent() {
  const { isTrialUser, auth0User, me } = useUserContext();

  // initialize user context for LaunchDarkly feature flag evaluations
  const [userContext, setUserContext] = useState<Object | null>(null);
  useMemo(() => {
    setUserContext(
      buildUserContext(auth0User?.sub, {
        orgId: me?.organization.orgId || '',
      }),
    );
  }, [me?.organization.orgId]);

  const isDynamicTaggingEnabled = evaluateFlag(
    FeatureFlag.ENABLE_DYNAMIC_TAGGING_V2,
    userContext || DEFAULT_USER_CONTEXT,
  );

  return (
    <div>
      <EndOfTrialModal />
      <Page>
        <Routes>
          <Route
            path="/datasets/*"
            element={<ProtectedRoute component={DatasetsComponent} />}
          />
          <Route
            path="/settings/*"
            element={<ProtectedRoute component={SettingsComponent} />}
          />
          {!isTrialUser && (
            <>
              <Route
                path="/concepts/*"
                element={<ProtectedRoute component={ConceptsComponent} />}
              />
              {isDynamicTaggingEnabled && (
                <Route
                  path="/dynamic-tag-categories/*"
                  element={<ProtectedRoute component={DynamicTagsComponent} />}
                />
              )}
              <Route
                path="/queries/*"
                element={<ProtectedRoute component={QueryComponent} />}
              />
              <Route
                path="/simsearch"
                element={<ProtectedRoute component={SimsearchComponent} />}
              />
            </>
          )}
          <Route path="/" element={<Navigate to="/datasets" replace />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Page>
    </div>
  );
};

const App = function App() {
  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback>
        <QueryClientProviderWrapper>
          <ReactQueryDevtools initialIsOpen={false} />

          <APIContextWrapper>
            <UserContextWrapper>
              <AuthLoadingWrapper>
                <SensitiveImageContextWrapper>
                  <AppContent />
                </SensitiveImageContextWrapper>
              </AuthLoadingWrapper>
            </UserContextWrapper>
          </APIContextWrapper>
        </QueryClientProviderWrapper>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
};

export default App;
