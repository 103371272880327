import { XMarkIcon } from '@heroicons/react/24/solid';
import {
  DeleteQueryByIdRequest,
  QueryParameters,
  QueryStatus,
} from 'api/generated';
import classNames from 'classnames';
import Button from 'components/Button';
import FilterPills from 'components/DatasetSearch/AdvancedSearch/FilterPills';
import { convertParametersToFilterConditions } from 'components/DatasetSearch/utils';
import RelativeTimeEyebrow from 'components/RelativeTimeEyebrow';
import React from 'react';

const SearchHistoryRow: React.FC<{
  queryId: string;
  queryActive: boolean | undefined;
  createdDt: string;
  onClick: () => void;
  status: QueryStatus;
  parameters: QueryParameters | undefined;
  deleteSearchQuery: (request: DeleteQueryByIdRequest) => void;
  activeRow?: string;
}> = ({
  queryId,
  queryActive,
  createdDt,
  onClick,
  status,
  parameters,
  deleteSearchQuery,
  activeRow,
}) => (
  <div
    tabIndex={0}
    role="button"
    onClick={onClick}
    onKeyDown={(key) => {
      if (key.code === 'Enter' || key.code === 'Space') {
        onClick();
      }
    }}
    className={classNames('p-2 hover:bg-gray-100 w-full text-left group flex', {
      'bg-blue-50': activeRow === queryId,
    })}
  >
    <div className="grow">
      <div className="text-xs mb-2">
        <RelativeTimeEyebrow timestamp={createdDt} /> &bull;{' '}
        <span
          className={classNames({
            'text-red-600': status === QueryStatus.Error,
            'text-green-600': status === QueryStatus.Complete,
            'text-blue-500':
              status !== QueryStatus.Error && status !== QueryStatus.Complete,
            'animate-pulse': status === QueryStatus.Running,
          })}
        >
          {status}
        </span>
      </div>
      <div className="flex flex-wrap items-center">
        <FilterPills
          filterConditions={convertParametersToFilterConditions(parameters)}
          operand={parameters?.clause?.operand ?? ''}
          sortItem={parameters?.sort ? { ...parameters?.sort } : undefined}
          placeholder="Unknown"
        />
      </div>
    </div>
    {!queryActive && (
      <div className="p-2 shrink-0 grow-0 hidden group-hover:flex items-center justify-center">
        <Button
          icon={XMarkIcon}
          onClick={(e) => {
            e.stopPropagation();
            deleteSearchQuery({ queryId });
          }}
          onMouseDown={(e) => {
            // Include this so that the search bar isn't focused on click
            e.stopPropagation();
            e.preventDefault();
          }}
          buttonStyle="link"
          padding="p-0"
          textColor="text-gray-400"
          textHoverColor="hover:text-gray-500"
        />
      </div>
    )}
  </div>
);

SearchHistoryRow.defaultProps = {
  activeRow: undefined,
};

export default SearchHistoryRow;
