/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A response for a user's trial extension request
 * @export
 * @interface TrialExtensionResponse
 */
export interface TrialExtensionResponse {
    /**
     * True if trial extension flow has been successful
     * @type {boolean}
     * @memberof TrialExtensionResponse
     */
    success: boolean;
    /**
     * Updated user data
     * @type {object}
     * @memberof TrialExtensionResponse
     */
    userData?: object | null;
}

export function TrialExtensionResponseFromJSON(json: any): TrialExtensionResponse {
    return TrialExtensionResponseFromJSONTyped(json, false);
}

export function TrialExtensionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrialExtensionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'userData': !exists(json, 'userData') ? undefined : json['userData'],
    };
}

export function TrialExtensionResponseToJSON(value?: TrialExtensionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'userData': value.userData,
    };
}

