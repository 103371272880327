export enum ConceptActionState {
  Label = 'label',
  View = 'view',
  Add = 'add',
  AddSimilar = 'add-similar',
}

export enum TabsValues {
  Labels = 'Labels',
  TopContent = 'Top content',
}

export type LabelCandidateStatus = 'none' | 'error' | 'empty';
