/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const Permissions = {
    Readusers: 'read:users',
    Createusers: 'create:users',
    Updateusers: 'update:users',
    Deleteusers: 'delete:users',
    Createcredentials: 'create:credentials',
    Readcredentials: 'read:credentials',
    Featuresinternal: 'features:internal',
    Deletedatasets: 'delete:datasets'
} as const;
export type Permissions = typeof Permissions[keyof typeof Permissions];


export function PermissionsFromJSON(json: any): Permissions {
    return PermissionsFromJSONTyped(json, false);
}

export function PermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permissions {
    return json as Permissions;
}

export function PermissionsToJSON(value?: Permissions | null): any {
    return value as any;
}

