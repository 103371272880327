import {
  DataSourceTypeEnum,
  DatasetUpdatesApi,
  ResendDatasetUpdateInvitationsOperationRequest,
} from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import { QueryClient, useIsFetching, useIsMutating } from 'react-query';

export const createDatasetUpdateProgressQueryKey = ['createDatasetUpdate'];

export const useCreateDatasetUpdateProgress = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    createDatasetUpdateProgressQueryKey,
    async (variables: {
      datasetId: string;
      dataPath?: string | null;
      dataSourceType?: DataSourceTypeEnum;
      credentialsId?: string;
      invitedUserEmail?: string;
      unsupportedDataSource?: string;
    }) => {
      const api = await initializeAPI<DatasetUpdatesApi>(DatasetUpdatesApi);
      return api.createDatasetUpdate({
        createDatasetUpdateRequest: {
          datasetId: variables.datasetId,
          dataPath: variables.dataPath,
          dataSourceType: variables.dataSourceType,
          credentialsId: variables.credentialsId,
          invitedUserEmail: variables.invitedUserEmail,
          unsupportedDataSource: variables.unsupportedDataSource,
        },
      });
    },
  );
};

export const updateDatasetUpdateProgressQueryKey = ['updateDatasetUpdate'];

export const useUpdateDatasetUpdateProgress = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    updateDatasetUpdateProgressQueryKey,
    async (variables: {
      datasetUpdateId: string;
      dataPath?: string | null;
      dataSourceType?: DataSourceTypeEnum;
      credentialsId?: string;
      invitedUserEmail?: string;
      unsupportedDataSource?: string;
    }) => {
      const api = await initializeAPI<DatasetUpdatesApi>(DatasetUpdatesApi);
      return api.updateDatasetUpdate({
        datasetUpdateId: variables.datasetUpdateId,
        updateDatasetUpdateRequest: {
          dataPath: variables.dataPath,
          dataSourceType: variables.dataSourceType,
          credentialsId: variables.credentialsId,
          invitedUserEmail: variables.invitedUserEmail,
          unsupportedDataSource: variables.unsupportedDataSource,
        },
      });
    },
  );
};

export const getDatasetUpdateQueryKey = (id: string | undefined) => [
  'getDatasetUpdate',
  id,
];

export const useGetDatasetUpdate = (datasetUpdateId: string | undefined) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    getDatasetUpdateQueryKey(datasetUpdateId),
    async ({ signal }) => {
      const api = await initializeAPI<DatasetUpdatesApi>(DatasetUpdatesApi);
      return api.getDatasetUpdate(
        { datasetUpdateId: datasetUpdateId || '' },
        { signal },
      );
    },
    { cacheTime: 0, enabled: Boolean(datasetUpdateId) },
  );
};

export const getDatasetUpdatesQueryKey = (datasetId: string | undefined) => [
  'getDatasetUpdates',
  datasetId,
];

export const useGetDatasetUpdates = (
  datasetId: string | undefined,
  options: { enabled: boolean },
) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    getDatasetUpdatesQueryKey(datasetId),
    async ({ signal }) => {
      const api = await initializeAPI<DatasetUpdatesApi>(DatasetUpdatesApi);
      return api.getDatasetUpdates({ datasetId: datasetId || '' }, { signal });
    },
    { cacheTime: 0, enabled: Boolean(datasetId) && options.enabled !== false },
  );
};

export const useResendDatasetUpdateInvitationLinks = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    ['resendDatasetUpdateInvitationLinks'],
    async (variables: ResendDatasetUpdateInvitationsOperationRequest) => {
      const api = await initializeAPI<DatasetUpdatesApi>(DatasetUpdatesApi);
      return api.resendDatasetUpdateInvitations(variables);
    },
  );
};

export const invalidateDatasetUpdateQueries = (
  queryClient: QueryClient,
  datasetId?: string,
  id?: string,
) =>
  Promise.all([
    queryClient.invalidateQueries(getDatasetUpdatesQueryKey(datasetId)),
    ...(id
      ? [queryClient.invalidateQueries(getDatasetUpdateQueryKey(id))]
      : []),
  ]);

export function useIsDatasetUpdateMutatingOrFetching(id?: string): boolean {
  const creating = useIsMutating(createDatasetUpdateProgressQueryKey);
  const updating = useIsMutating(updateDatasetUpdateProgressQueryKey);
  const fetching = useIsFetching(getDatasetUpdateQueryKey(id));
  return creating > 0 || updating > 0 || fetching > 0;
}
