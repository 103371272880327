import {
  AssetResponse,
  QueryConceptDataUI,
  QueryResultResponseUI,
  QueryTableRowUI,
} from 'api/generated';
import ConceptClassificationsList from 'components/ConceptClassificationsList';
import EmptyStateMessage from 'components/EmptyStateMessage';
import FadeTransition from 'components/FadeTransition';
import ImageGrid from 'components/ImageGrid';
import Pagination from 'components/Pagination/index';
import React, { useMemo } from 'react';
import { PaginationState } from 'types/pagination';
import { TablePageSize } from 'types/table';
import { removeImageProperties } from 'utils/QueryUtils';

const ConceptDetail: React.FC<{
  data: QueryConceptDataUI[];
}> = function ConceptDetail({ data }) {
  const concepts = [...data];
  concepts.sort((c1, c2) => c2.probabilityScore - c1.probabilityScore);
  const display = concepts.slice(0, Math.min(concepts.length + 1, 3));
  return (
    <div className="text-sm">
      <ConceptClassificationsList classifications={display} />
    </div>
  );
};

interface QueryImageResultsProps {
  show: boolean;
  queryResult: QueryResultResponseUI | undefined;
  pagination: PaginationState;
  setPageSize: (size: TablePageSize) => void;
  loadPage: (page: number) => void;
  setSidebarData: (data: QueryTableRowUI) => void;
  showConceptDetail?: boolean;
}

const QueryImageResults: React.FC<QueryImageResultsProps> =
  function QueryImageResults({
    show,
    queryResult,
    pagination,
    setPageSize,
    loadPage,
    setSidebarData,
    showConceptDetail,
  }: QueryImageResultsProps) {
    const images = useMemo(
      () => queryResult?.results?.data?.map((row: any) => row.asset),
      [queryResult],
    );
    const conceptData = useMemo(() => {
      const map = {};
      queryResult?.results?.data?.forEach((row) => {
        const cid = row.asset?.coactiveImageId;
        if (cid) {
          map[cid] = row.concepts;
        }
      });
      return map;
    }, [queryResult]);

    const getQueryRow = (image: AssetResponse): QueryTableRowUI | undefined => {
      const queryRow = queryResult?.results?.data?.find(
        (row) => row && row.asset?.coactiveImageId === image.coactiveImageId,
      );
      return removeImageProperties(queryRow);
    };

    return (
      <FadeTransition appear show={show} exit={false}>
        {Boolean(images) && (
          <div className="mt-2">
            <ImageGrid
              images={images}
              data={conceptData}
              detailComponent={showConceptDetail ? ConceptDetail : undefined}
              onClick={(image) => {
                const row = getQueryRow(image);
                if (row) {
                  setSidebarData(row);
                }
              }}
              type="uniform"
              popoverClassName="w-[30rem] origin-bottom-right absolute mt-2 top-[calc(-100%-1rem)] right-[100%] h-60 overflow-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-30 mt-2 left-0"
            />
            {queryResult?.results?.meta?.page?.total &&
            queryResult?.results?.meta?.page?.total > 20 ? (
              <div className="py-3">
                <Pagination
                  total={queryResult?.results?.meta?.page?.total!}
                  activePage={pagination.page}
                  pageSize={pagination.size}
                  setPageSize={setPageSize}
                  goToPage={loadPage}
                />
              </div>
            ) : undefined}
          </div>
        )}
        {!images?.length && (
          <div className="text-center py-12 px-2 text-sm">
            <EmptyStateMessage>
              <p>No results found</p>
            </EmptyStateMessage>
          </div>
        )}
      </FadeTransition>
    );
  };

QueryImageResults.defaultProps = {
  showConceptDetail: false,
};

export default QueryImageResults;
