import { QueryResponseUI, QueryStatus } from 'api/generated';
import EmptyStateMessage from 'components/EmptyStateMessage';
import FadeTransition from 'components/FadeTransition';
import QueryHistoryRow from 'pages/queries/HistorySidebar/QueryHistoryRow';
import {
  Creator,
  useGetQueryHistoryInfiniteQuery,
  useStopQuery,
  useDeleteQuery,
} from 'pages/queries/queries';
import { QUERY_UPDATE_INTERVAL } from 'pages/queries/utils';
import React, { useCallback, useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

interface HistorySidebarQueriesProps {
  tab: Creator;
  selectedQueryId: string | undefined;
  selectQuery: (queryId: string | undefined, error?: boolean) => void;
}

const HistorySidebarQueries: React.FunctionComponent<HistorySidebarQueriesProps> =
  function HistorySidebarQueries({ tab, selectedQueryId, selectQuery }) {
    const { data, fetchNextPage, refetch, isLoading } =
      useGetQueryHistoryInfiniteQuery(tab);
    const stopMutation = useStopQuery();
    const deleteQueryMutation = useDeleteQuery(() => selectQuery(undefined));

    const [queries, totalQueries] = useMemo(
      () => [
        data?.pages.reduce(
          (acc: QueryResponseUI[], item) => [...acc, ...item.data],
          [],
        ) || [],
        data?.pages.length
          ? data.pages[data.pages.length - 1].meta?.page?.total || 0
          : 0,
      ],
      [data],
    );

    const hasMore = useMemo(
      () => queries.length < totalQueries,
      [queries, totalQueries],
    );

    const hasActiveQueries = useMemo(
      () =>
        !!queries?.find((item) =>
          (
            [
              QueryStatus.Queued,
              QueryStatus.Running,
              QueryStatus.Stopping,
            ] as QueryStatus[]
          ).includes(item.status),
        ),
      [queries],
    );

    useEffect(() => {
      let interval;
      if (hasActiveQueries) {
        interval = setInterval(() => refetch(), QUERY_UPDATE_INTERVAL);
      }
      return () => clearInterval(interval);
    }, [hasActiveQueries, refetch]);

    const onSelected = useCallback((queryId: string, error: boolean) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      selectQuery(queryId, error);
    }, []);

    return (
      <>
        <FadeTransition
          appear
          show={Boolean(totalQueries)}
          className="history-sidebar-content h-full"
        >
          <div className="h-[90%] overflow-auto">
            <InfiniteScroll
              dataLength={totalQueries || 0}
              next={fetchNextPage}
              loader={<span />} // TODO - created loader for infinite scroll grid
              hasMore={hasMore}
              height="100%"
            >
              <ul className="flex-1">
                {queries?.map((query) => (
                  <QueryHistoryRow
                    key={query.queryId}
                    query={query}
                    onSelected={onSelected}
                    active={query.queryId === selectedQueryId}
                    stopMutation={stopMutation}
                    deleteQueryMutation={deleteQueryMutation}
                    creator={tab}
                  />
                ))}
              </ul>
            </InfiniteScroll>
          </div>
        </FadeTransition>
        {!totalQueries && !isLoading && (
          <div className="px-4 py-6 text-center">
            <EmptyStateMessage>No Queries to Show</EmptyStateMessage>
          </div>
        )}
      </>
    );
  };

export default HistorySidebarQueries;
