/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface UploadResponse
 */
export interface UploadResponse {
    /**
     * Name of the uploaded file
     * @type {string}
     * @memberof UploadResponse
     */
    filename: string;
    /**
     * Path to the csv file
     * @type {string}
     * @memberof UploadResponse
     * @deprecated
     */
    csvPath?: string | null;
}

export function UploadResponseFromJSON(json: any): UploadResponse {
    return UploadResponseFromJSONTyped(json, false);
}

export function UploadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': json['filename'],
        'csvPath': !exists(json, 'csvPath') ? undefined : json['csvPath'],
    };
}

export function UploadResponseToJSON(value?: UploadResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
        'csvPath': value.csvPath,
    };
}

