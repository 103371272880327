import { QueryResponseUI, QueryStatus } from 'api/generated';

export function getPendingQueryIds(
  history: (QueryResponseUI | QueryResponseUI)[] | undefined,
): string[] {
  return history
    ? history
        .filter(
          (hq) =>
            hq.status === QueryStatus.Running ||
            hq.status === QueryStatus.Queued ||
            hq.status === QueryStatus.Stopping,
        )
        .map((q) => q.queryId)
    : [];
}

export function replaceQueriesInHistory(
  queries: (QueryResponseUI | QueryResponseUI)[],
  h: (QueryResponseUI | QueryResponseUI)[] | undefined,
): (QueryResponseUI | QueryResponseUI)[] | undefined {
  const queriesMap = {};
  queries.forEach((q) => {
    queriesMap[q.queryId] = q;
  });
  const updatedHistory = h?.map((oldQ) => {
    const newQ = queriesMap[oldQ.queryId];
    const updatedStatus = queriesMap[oldQ.queryId]?.status;
    return newQ && oldQ.status !== updatedStatus ? newQ : oldQ;
  });
  return updatedHistory;
}

export function removeImageProperties(row: object | undefined) {
  const data: any = {
    ...row,
  };
  delete data.preview_thumb;
  delete data.preview_small;
  delete data.preview_regular;
  delete data.preview_full;
  return data;
}
