import { XMarkIcon } from '@heroicons/react/24/solid';
import { Comparator, Concept, MetadataKey } from 'api/generated';
import classNames from 'classnames';
import Button from 'components/Button';
import ConceptFilter from 'components/DatasetSearch/AdvancedSearch/FilterRow/ConceptFilter';
import MetadataFilter from 'components/DatasetSearch/AdvancedSearch/FilterRow/MetadataFilter';
import FilterSubjectDropdown from 'components/DatasetSearch/AdvancedSearch/FilterSubjectDropdown';
import {
  ConceptFilterType,
  FilterCondition,
} from 'components/DatasetSearch/AdvancedSearch/types';
import React, { useMemo } from 'react';

interface FilterRowProps {
  row: Partial<FilterCondition>;
  onChange: (fc: Partial<FilterCondition>) => void;
  availableConcepts: Concept[];
  availableMetadataKeys: MetadataKey[];
  remove: () => void;
  className?: string;
  columnsLoading: boolean;
}

const FilterRow = function FilterRow({
  row,
  onChange,
  availableConcepts,
  remove,
  availableMetadataKeys,
  className,
  columnsLoading,
}: FilterRowProps) {
  const concepts = useMemo(() => {
    const cs = [...availableConcepts];
    if (row.concepts?.length && row.concepts[0]?.conceptId) {
      const concept = row.concepts[0];
      cs.push({
        ...concept,
        name: concept.name!,
        threshold: concept.threshold!,
      });
    }
    return cs;
  }, [availableConcepts, row]);

  const metadataKeys = useMemo(() => {
    const mks = [...availableMetadataKeys];
    if (row.metadataKeys?.length && row.metadataKeys[0]?.type === 'datetime') {
      const metadataKey = row.metadataKeys[0];
      mks.push({ ...metadataKey });
    }
    return mks;
  }, [availableMetadataKeys, row]);

  return (
    <div
      className={classNames(
        className,
        'text-sm group flex w-full hover:bg-gray-100 py-2 px-4 item-center',
      )}
    >
      <div className="grow space-x-2">
        <FilterSubjectDropdown
          selected={
            (row.concepts?.length ? row.concepts[0].conceptId : undefined) ??
            row.metadataKeys?.[0]?.key
          }
          onChange={({ concept, metadataKey }) => {
            if (concept) {
              onChange({
                id: row.id,
                concepts: [
                  {
                    ...concept,
                    comparator: Comparator.GreaterThanOrEqualTo,
                    value: undefined,
                    type: ConceptFilterType.Positive,
                  },
                ],
              });
              return;
            }
            if (metadataKey) {
              onChange({
                id: row.id,
                metadataKeys:
                  metadataKey.type === 'datetime'
                    ? [
                        {
                          ...metadataKey,
                          comparator: Comparator.GreaterThan,
                          value: undefined,
                          type: metadataKey.type,
                        },
                        {
                          ...metadataKey,
                          comparator: Comparator.LessThan,
                          value: undefined,
                          type: metadataKey.type,
                        },
                      ]
                    : [
                        {
                          ...metadataKey,
                          comparator: Comparator.Equals,
                          value: '',
                          type: metadataKey.type,
                        },
                      ],
              });
            }
          }}
          concepts={concepts}
          metadataKeys={metadataKeys}
          placeholder="Select filter condition"
          loading={columnsLoading}
        />
        {Boolean(row.concepts?.length) && (
          <ConceptFilter
            data={row.concepts!}
            onChange={(cs) => onChange({ ...row, concepts: cs })}
          />
        )}
        {row.metadataKeys &&
          row.metadataKeys.length &&
          row.metadataKeys[0]?.key && (
            <MetadataFilter
              data={row.metadataKeys}
              onChange={(mks) => onChange({ ...row, metadataKeys: mks })}
            />
          )}
      </div>
      <div className="shrink-0 grow-0 hidden group-hover:flex items-center justify-center">
        <Button
          icon={XMarkIcon}
          onClick={remove}
          buttonStyle="link"
          padding="p-0"
          textColor="text-gray-500"
        />
      </div>
    </div>
  );
};

FilterRow.defaultProps = {};

export default FilterRow;

FilterRow.defaultProps = {
  className: undefined,
};
