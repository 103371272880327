/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const MetadataType = {
    String: 'string',
    Number: 'number',
    Datetime: 'datetime',
    Boolean: 'boolean'
} as const;
export type MetadataType = typeof MetadataType[keyof typeof MetadataType];


export function MetadataTypeFromJSON(json: any): MetadataType {
    return MetadataTypeFromJSONTyped(json, false);
}

export function MetadataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetadataType {
    return json as MetadataType;
}

export function MetadataTypeToJSON(value?: MetadataType | null): any {
    return value as any;
}

