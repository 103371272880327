import { ErrorResponse } from 'api/generated';
import CsvLabelByCoactiveIdExampleIllustration from 'assets/illustrations/csv-label-by-path-example.svg';
import FadeTransition from 'components/FadeTransition';
import FileUpload, { CompleteUpload } from 'components/FileUpload';
import React, { useCallback, useState } from 'react';

interface UploadLabelsCsvProps {
  conceptId: string;
  conceptVersionId: string;
  exit: (refreshLabels?: boolean) => void;
}

const UploadLabelsCsv: React.FC<UploadLabelsCsvProps> =
  function UploadLabelsCsv({ conceptId, conceptVersionId, exit }) {
    const [error, setError] = useState<string>();

    const onUploadStart = useCallback(() => {
      setError(undefined);
    }, []);

    const onUploadSuccess = useCallback(() => {
      exit(true);
    }, []);

    const onUploadError = useCallback(
      (uploads: CompleteUpload<ErrorResponse>[]) => {
        setError(uploads[0]?.response?.detail);
      },
      [],
    );

    return (
      <FadeTransition show appear exit={false}>
        {conceptId && conceptVersionId && (
          <div className="mx-auto max-w-4xl">
            <FileUpload
              acceptedTypes={['text/csv']}
              uploadUrl={`${process.env.REACT_APP_API_HOST}/api/ui/versions/${conceptVersionId}/labels/upload/csv`}
              maxFiles={1}
              onUploadSuccess={onUploadSuccess}
              onUploadError={onUploadError}
              onFileUploadStarted={onUploadStart}
              illustrations={[
                {
                  primary: CsvLabelByCoactiveIdExampleIllustration,
                },
              ]}
              error={error}
            >
              <p className="max-w-80 pointer-events-none">
                Drop a CSV with label data or{' '}
                <strong className="text-blue-500">browse</strong>.
              </p>
            </FileUpload>
          </div>
        )}
      </FadeTransition>
    );
  };

export default UploadLabelsCsv;
