import Workspace from 'pages/queries/Workspace';
import React, { useCallback, useEffect, useState } from 'react';

interface ExecutedQueryWorkspaceProps {
  runQuery: ((query: string, embeddingId: string) => void) | undefined;
  queryId: string | undefined;
  query: string | undefined;
  datasetId: string | undefined;
  embeddingId: string | null;
}

const QUERY_UNCHANGED_RUN_BTN_TEXT = 'Run Again';
const QUERY_CHANGED_RUN_BTN_TEXT = 'Run as New';

const ExecutedQueryWorkspace: React.FunctionComponent<ExecutedQueryWorkspaceProps> =
  function QueryEditor({ runQuery, queryId, query, datasetId, embeddingId }) {
    // Use a separate key so that state of child components is fully reset every time
    // the query id changes
    const [runBtnLabel, setRunBtnLabel] = useState(
      QUERY_UNCHANGED_RUN_BTN_TEXT,
    );

    const onChange = useCallback(
      (value: string, embId: string | null) => {
        if (query === value && embId === embeddingId) {
          setRunBtnLabel(QUERY_UNCHANGED_RUN_BTN_TEXT);
        } else if (embId) {
          setRunBtnLabel(QUERY_CHANGED_RUN_BTN_TEXT);
        }
      },
      [query, embeddingId],
    );

    useEffect(() => {
      setRunBtnLabel(QUERY_UNCHANGED_RUN_BTN_TEXT);
    }, [queryId]);

    return (
      <Workspace
        key={queryId}
        runQuery={runQuery}
        disabled={!query}
        defaultValue={query}
        runBtnLabel={runBtnLabel}
        onChange={query ? onChange : undefined}
        useLocalStorageDefaultValue={false}
        persistToLocalStorage={false}
        selectedDatasetId={datasetId}
        defaultEmbeddingId={embeddingId}
        codeMirrorDefaultHeight="calc(60vh - 12rem)"
      />
    );
  };

export default ExecutedQueryWorkspace;
