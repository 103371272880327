import useCheckNetworkStatus from 'hooks/useCheckNetworkStatus';
import React, { PropsWithChildren, useMemo } from 'react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';

const QueryClientProviderWrapper = ({ children }: PropsWithChildren<{}>) => {
  const { handleQuery4xx, handleMutation4xx } = useCheckNetworkStatus();
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
            retry: false,
          },
        },
        queryCache: new QueryCache({
          onError: handleQuery4xx,
        }),
        mutationCache: new MutationCache({
          onError: handleMutation4xx,
        }),
      }),
    [],
  );
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default QueryClientProviderWrapper;
