import { FullConceptResponseUI } from 'api/generated';
import classNames from 'classnames';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Pagination from 'components/Pagination/index';
import EditConceptVersionLabelRow from 'pages/queries/EditConceptVersionLabels/EditConceptVersionLabelRow';
import { useFetchClassificationScores } from 'queries/classification';
import { useGetConceptsQuery } from 'queries/concepts';
import { useGetLabelsForImageQuery } from 'queries/labels';
import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { TablePageSize } from 'types/table';
import IconButton from 'components/IconButton';
import { ClipLoader } from 'react-spinners';
import colors from 'tailwindcss/colors';

const ROWS_TO_SHOW = 3;

interface EditConceptVersionLabelsProps {
  datasetId: string;
  coactiveImageId: string;
  topScoringConceptsCount?: number;
  setShouldRefetchPredictions?: (shouldRefetchPredictions: boolean) => void;
}

const EditConceptVersionLabels: React.FC<EditConceptVersionLabelsProps> = ({
  datasetId,
  coactiveImageId,
  topScoringConceptsCount,
  setShouldRefetchPredictions,
}) => {
  // Temporary: must be a high number to get the real top scoring concepts for preview rows
  const [pageSize, setPageSize] = useState<TablePageSize>(200);
  const [page, setPage] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);
  const { data: concepts, isLoading: areConceptsLoading } = useGetConceptsQuery(
    datasetId,
    page,
    pageSize,
    {
      enabled: true,
    },
  );
  const { data: classifications } =
    useFetchClassificationScores(coactiveImageId);
  const [previewRows, setPreviewRows] = useState<FullConceptResponseUI[]>([]);
  const buttonClickHandler = () => {
    setPageSize(10);
    setPage(1);
    setOpen(true);
  };

  useEffect(() => {
    // Temporary workaround to show top 3 scored concepts while API responses get sorted out

    if (concepts?.data && classifications?.scores) {
      const conceptMap = new Map(
        concepts.data.map((concept) => [concept.conceptId, concept]),
      );

      const topScoringConcepts = classifications.scores
        .slice(0, topScoringConceptsCount || ROWS_TO_SHOW)
        .reduce<FullConceptResponseUI[]>((acc, classification) => {
          const concept = conceptMap.get(classification.conceptId);
          if (concept) {
            acc.push(concept);
          }
          return acc;
        }, []);

      if (previewRows.length === 0 && topScoringConcepts) {
        setPreviewRows(topScoringConcepts);
      }
    }
  }, [concepts?.data, classifications?.scores, previewRows.length]);

  useEffect(() => {
    if (!open) {
      setPageSize(200);
      setPage(1);
    }
  }, [open]);

  const {
    data: labels,
    isLoading: areLabelsLoading,
    refetch: refetchLabels,
  } = useGetLabelsForImageQuery(
    coactiveImageId,
    concepts?.data?.map((concept) => concept.latestVersion.versionId),
  );

  const getConceptScore = (conceptId: string) => {
    const score = classifications?.scores?.find(
      (conceptScore) => conceptScore.conceptId === conceptId,
    )?.score;

    return score?.toFixed(2);
  };

  return (
    <>
      <Dialog title="Edit concept labels" open={open} setOpen={setOpen}>
        <div
          className={classNames(
            'flex flex-col mb-2 transition ease-in-out transition-all duration-500 min-h-[400px] ',
          )}
        >
          <div className="absolute top-0 right-0 pt-5 pr-5">
            <IconButton onClick={() => setOpen(false)} Icon={XMarkIcon} />
          </div>
          {concepts?.data?.length ? (
            <>
              <div className="flex justify-between text-gray-500 mt-4 text-sm">
                <h3>Concepts</h3>
                <p>Score</p>
              </div>
              <ul className="grow mt-4">
                {concepts?.data?.map((concept: FullConceptResponseUI) => (
                  <EditConceptVersionLabelRow
                    key={concept.conceptId}
                    concept={concept}
                    label={labels?.data?.find(
                      (l) =>
                        l.conceptVersionId === concept.latestVersion.versionId,
                    )}
                    score={getConceptScore(concept.conceptId)}
                    coactiveImageId={coactiveImageId}
                    labelsAreLoading={areLabelsLoading}
                    refetchLabels={refetchLabels}
                  />
                ))}
              </ul>
              {(concepts?.meta?.page?.total ?? 0) > pageSize && (
                <div className="mt-8">
                  <Pagination
                    activePage={page || 1}
                    total={concepts?.meta?.page?.total ?? 0}
                    pageSize={pageSize}
                    goToPage={(p: number) => setPage(p)}
                    showPaginationLabel={false}
                    allowChangePageSize={false}
                  />
                </div>
              )}
            </>
          ) : undefined}
        </div>
      </Dialog>
      <div className="border-b border-gray-100">
        {areConceptsLoading ? (
          <div className="mb-8">
            <ClipLoader
              cssOverride={{ textAlign: 'center', marginLeft: '0.5rem' }}
              color={colors.green['500']}
              loading
              size={40}
              speedMultiplier={0.75}
            />
          </div>
        ) : (
          concepts &&
          concepts?.data?.length > 0 && (
            <>
              <div className="flex justify-between text-gray-500 mb-4 text-sm">
                <h3>Concepts</h3>
                <p>Score</p>
              </div>
              <ul>
                {previewRows?.map((concept: FullConceptResponseUI) => (
                  <EditConceptVersionLabelRow
                    key={concept.conceptId}
                    concept={concept}
                    label={labels?.data?.find(
                      (label) =>
                        label.conceptVersionId ===
                        concept.latestVersion.versionId,
                    )}
                    score={getConceptScore(concept.conceptId)}
                    coactiveImageId={coactiveImageId}
                    labelsAreLoading={areLabelsLoading}
                    refetchPredictions={setShouldRefetchPredictions}
                    refetchLabels={refetchLabels}
                  />
                ))}
              </ul>
              {(concepts?.meta?.page?.total ?? 0) >
                (topScoringConceptsCount || ROWS_TO_SHOW) && (
                <div className="w -full text-center my-8">
                  <div>
                    <Button buttonStyle="text" onClick={buttonClickHandler}>
                      Show more
                    </Button>
                  </div>
                </div>
              )}
            </>
          )
        )}
      </div>
    </>
  );
};

EditConceptVersionLabels.defaultProps = {
  topScoringConceptsCount: undefined,
  setShouldRefetchPredictions: undefined,
};

export default EditConceptVersionLabels;
