import { EyeSlashIcon } from '@heroicons/react/24/solid';
import { ImageSizeEnum } from 'api/generated';
import classNames from 'classnames';
import { useSensitiveImageContext } from 'context/SensitiveImageContext';
import SidebarContext from 'context/SidebarContext';
import usePreventMediaHeightJump from 'hooks/usePreventMediaHeightJump';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import colors from 'tailwindcss/colors';

const Image: React.FC<{
  src: string;
  alt: string;
  isPlaceholder?: boolean;
  rounded?: boolean;
  shadow?: string;
  className?: string;
  sensitive?: boolean | null; // Expected to not change after mount
  size?: ImageSizeEnum; // Expected to not change after mount
  coactiveImageId?: string;
  objectFit?: string;
  // Use this prop if the if the src prop is expected to change. If this flag is set to `true`,
  // the height of the previous image will be maintained while the new image loads. This way,
  // the view won't jump while the new image is loading. This should only be used if the image height
  // is not fixed (if it changes depending on the src in its container).
  preventHeightJump?: boolean;
}> = function Image({
  src,
  alt,
  isPlaceholder,
  className,
  shadow,
  rounded,
  sensitive,
  size,
  coactiveImageId: cid,
  objectFit,
  preventHeightJump,
}) {
  const { rightSidebarOpen, leftSidebarOpen } = useContext(SidebarContext);
  const { getImageRevealed, setImageRevealed } = useSensitiveImageContext();
  const [showWarning, setShowWarning] = useState(sensitive);
  const [showMessage, setShowMessage] = useState(
    (!rightSidebarOpen && !leftSidebarOpen) ||
      size === ImageSizeEnum.Full ||
      size === ImageSizeEnum.Regular,
  );
  const timeoutRef = useRef<any>();
  const { containerRef, mediaRef, onMediaLoad } =
    usePreventMediaHeightJump<HTMLImageElement>(
      Boolean(preventHeightJump && !isPlaceholder),
    );

  useEffect(() => {
    // This useEffect times the display of the warning detail message so that it
    // doesn't jump when closing the right/left sidebars.
    clearTimeout(timeoutRef.current);
    if (
      sensitive &&
      size !== ImageSizeEnum.Full &&
      size !== ImageSizeEnum.Regular
    ) {
      if (!rightSidebarOpen && !leftSidebarOpen) {
        timeoutRef.current = setTimeout(() => setShowMessage(true), 500);
      } else {
        setShowMessage(false);
      }
    }
    return () => clearTimeout(timeoutRef.current);
  }, [rightSidebarOpen || leftSidebarOpen, sensitive, size]);

  const revealSensitiveImage = useCallback(
    (reveal: boolean) => {
      if (cid) {
        setImageRevealed(cid, reveal);
      } else {
        setShowWarning(reveal);
      }
    },
    [sensitive, setImageRevealed, cid],
  );

  const revealImage = (cid && getImageRevealed(cid)) || !showWarning;

  return (
    <div className={classNames('group relative', className)}>
      <div
        ref={containerRef}
        className={classNames(
          'group relative aspect-w-1 aspect-h-1 overflow-hidden',
          rounded ? 'rounded-md' : undefined,
          className,
        )}
      >
        <img
          key="src"
          ref={mediaRef}
          onLoad={onMediaLoad}
          src={src}
          alt={alt}
          className={classNames(
            'object-center',
            objectFit,
            rounded ? 'rounded-md' : undefined,
            className,
            shadow,
            !revealImage ? 'blur-xl' : undefined,
          )}
        />
      </div>
      {/* Special button for thumbnails */}
      {sensitive && size === ImageSizeEnum.Thumb ? (
        <div
          className={classNames(
            'absolute top-0 right-0 bottom-0 left-0 text-white',
            rounded ? 'rounded-md' : undefined,
          )}
        >
          <div
            className={classNames(
              'absolute top-0 right-0 bottom-0 left-0 bg-black opacity-10',
              rounded ? 'rounded-md' : undefined,
            )}
          />
          <button
            type="button"
            className="absolute bottom-2 left-2 opacity-50 hover:opacity-100 transition-all"
            onClick={() => {
              revealSensitiveImage(!revealImage);
            }}
          >
            <EyeSlashIcon fill={colors.white} className="w-4 h-4" />
          </button>
        </div>
      ) : undefined}
      {/* Image is revealed */}
      {sensitive && revealImage && size !== ImageSizeEnum.Thumb ? (
        <button
          type="button"
          className="absolute bottom-2 left-2 opacity-50 hover:opacity-100 transition-all"
          onClick={(e) => {
            e.stopPropagation();
            revealSensitiveImage(false);
          }}
        >
          <EyeSlashIcon fill={colors.white} className="w-6 h-6" />
        </button>
      ) : undefined}
      {/* Image is not revealed */}
      {size !== ImageSizeEnum.Thumb && !revealImage ? (
        <div
          className={classNames(
            'absolute top-0 right-0 bottom-0 left-0 text-white',
            rounded ? 'rounded-md' : undefined,
          )}
        >
          <div
            className={classNames(
              'absolute top-0 right-0 bottom-0 left-0 bg-black opacity-50',
              rounded ? 'rounded-md' : undefined,
            )}
          />
          <div
            className={classNames(
              'absolute top-0 right-0 bottom-0 left-0 text-xs md:text-sm flex justify-center items-center flex-col text-center px-1 sm:px-2 transition-all duration-500',
              { '!text-xs': rightSidebarOpen && leftSidebarOpen },
            )}
          >
            <div>
              <EyeSlashIcon
                fill={colors.white}
                className="w-5 h-5 mx-auto mb-1"
              />
            </div>
            <div className="font-bold mb-2 md:mb-4">Sensitive Content</div>
            <div
              className={classNames('transition-all mb-2 md:mb-4', {
                hidden: !showMessage,
              })}
            >
              This photo contains sensitive content which some people may find
              offensive or disturbing.
            </div>
            <button
              type="button"
              className="text-blue-400 hover:text-blue-500 font-bold"
              onClick={(e) => {
                e.stopPropagation();
                revealSensitiveImage(true);
              }}
            >
              See Image
            </button>
          </div>
        </div>
      ) : undefined}
    </div>
  );
};

Image.defaultProps = {
  className: undefined,
  shadow: undefined,
  rounded: false,
  sensitive: false,
  size: undefined,
  coactiveImageId: undefined,
  objectFit: 'object-cover',
  isPlaceholder: false,
  preventHeightJump: false,
};

export default Image;
