import classNames from 'classnames';
import React from 'react';
import './index.scss';
import Check from 'assets/icons/check-white.svg';
import X from 'assets/icons/x-white.svg';
import Skip from 'assets/icons/ban-white.svg';
import { LabelEnum } from 'api/generated';

interface LabelProps {
  label: LabelEnum;
  className?: string;
}

const Label: React.FunctionComponent<LabelProps> = function Label({
  className,
  label,
}) {
  return (
    <div
      className={classNames(
        'h-8 w-8 rounded-full m-1 flex items-center justify-center',
        {
          'bg-green-500': label === LabelEnum._1,
          'bg-red-500': label === LabelEnum._0,
          'bg-gray-500': label === LabelEnum.Skip,
        },
        className,
      )}
    >
      {label === LabelEnum._1 && (
        <img src={Check} alt="Positive Example" className="w-3/5 fill-white" />
      )}
      {label === LabelEnum._0 && (
        <img src={X} alt="Negative Example" className="w-3/5 fill-white" />
      )}
      {label === LabelEnum.Skip && (
        <img src={Skip} alt="Skipped" className="w-3/5 fill-white" />
      )}
    </div>
  );
};

Label.defaultProps = {
  className: undefined,
};

export default Label;
