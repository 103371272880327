/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConceptLabelRequest,
    ConceptLabelRequestFromJSON,
    ConceptLabelRequestFromJSONTyped,
    ConceptLabelRequestToJSON,
} from './ConceptLabelRequest';

/**
 * An object used to create a concept in Coactive's system
 * @export
 * @interface CreateConceptRequest
 */
export interface CreateConceptRequest {
    /**
     * The name of the concept, which will become available as the column name in SQL
     * @type {string}
     * @memberof CreateConceptRequest
     */
    name: string;
    /**
     * An optional description for the concept
     * @type {string}
     * @memberof CreateConceptRequest
     */
    description?: string | null;
    /**
     * Labels to create/update/delete
     * @type {Array<ConceptLabelRequest>}
     * @memberof CreateConceptRequest
     */
    labels?: Array<ConceptLabelRequest> | null;
    /**
     * Threshold above which classification is positive
     * @type {number}
     * @memberof CreateConceptRequest
     */
    threshold?: number | null;
    /**
     * How strongly the model adapts to the training labels. Regularization strength. Lower value means the model fits more heavily to the training data 
     * @type {number}
     * @memberof CreateConceptRequest
     */
    regularization?: number | null;
    /**
     * The id of the embedding
     * @type {string}
     * @memberof CreateConceptRequest
     */
    embeddingId?: string | null;
    /**
     * The id of the dataset
     * @type {string}
     * @memberof CreateConceptRequest
     */
    datasetId?: string | null;
}

export function CreateConceptRequestFromJSON(json: any): CreateConceptRequest {
    return CreateConceptRequestFromJSONTyped(json, false);
}

export function CreateConceptRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateConceptRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'labels': !exists(json, 'labels') ? undefined : (json['labels'] === null ? null : (json['labels'] as Array<any>).map(ConceptLabelRequestFromJSON)),
        'threshold': !exists(json, 'threshold') ? undefined : json['threshold'],
        'regularization': !exists(json, 'regularization') ? undefined : json['regularization'],
        'embeddingId': !exists(json, 'embeddingId') ? undefined : json['embeddingId'],
        'datasetId': !exists(json, 'datasetId') ? undefined : json['datasetId'],
    };
}

export function CreateConceptRequestToJSON(value?: CreateConceptRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'labels': value.labels === undefined ? undefined : (value.labels === null ? null : (value.labels as Array<any>).map(ConceptLabelRequestToJSON)),
        'threshold': value.threshold,
        'regularization': value.regularization,
        'embeddingId': value.embeddingId,
        'datasetId': value.datasetId,
    };
}

