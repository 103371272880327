import { CheckIcon } from '@heroicons/react/24/solid';
import CollapsibleSection from 'pages/datasets/components/CollapsibleSection';
import React, { useState } from 'react';
import { getS3BucketName } from 'pages/datasets/add-assets/utils';
import CodeSnippet from 'components/CodeSnippet';
import FileUpload from 'components/FileUpload';
import CsvCredentialsIllustration from 'assets/illustrations/csv-aws-credentials.svg';
import AccessInstructionsList from 'pages/datasets/add-assets/views/AccessView/AccessInstructionsList';
import Checkbox from 'components/Checkbox';

interface ConfigureS3AccessProps {
  createDataset: () => void;
  createDatasetDisabled: boolean;
  prefix: string;
  publicAccessConfigured: boolean;
  setPublicAccessConfigured: (publicAccessConfigured: boolean) => void;
  setCredentialsId: (credentials: string) => void;
}

/**
 * The steps to configure access to an AWS S3 data source.
 */
const ConfigureS3Access = function ConfigureS3Access({
  createDataset,
  createDatasetDisabled,
  prefix,
  publicAccessConfigured,
  setCredentialsId,
  setPublicAccessConfigured,
}: ConfigureS3AccessProps) {
  const [activeStepIdx, setActiveStepIdx] = useState(0);
  const [uploadErrorText, setUploadErrorText] = useState<string>();

  const bucketName = getS3BucketName(prefix) ?? '';
  const iamPolicyJson =
    '{\n' +
    '    "Version": "2012-10-17",\n' +
    '    "Statement": [\n' +
    '        {\n' +
    '            "Sid": "CoactiveReadAccess",\n' +
    '            "Effect": "Allow",\n' +
    '            "Action": ["s3:ListBucket", "s3:GetBucketLocation", "s3:GetObject"],\n' +
    '            "Resource": [\n' +
    `                "arn:aws:s3:::${bucketName}",\n` +
    `                "arn:aws:s3:::${bucketName}/*"\n` +
    '            ]\n' +
    '        }\n' +
    '    ]\n' +
    '}';

  return (
    <div className="my-4 space-y-8">
      <CollapsibleSection
        stepNumber={1}
        title="Create a new IAM permission policy"
        primaryAction={{ action: () => setActiveStepIdx((idx) => idx + 1) }}
        defaultOpen={activeStepIdx === 0}
        key={`0-${activeStepIdx === 0}`}
      >
        <AccessInstructionsList>
          <li>
            In S3, go to your{' '}
            <a
              href="https://console.aws.amazon.com/iam/home#/policies"
              className="underline text-green-600 font-semibold"
            >
              IAM Policies
            </a>{' '}
            page and click <strong>Create policy</strong>
          </li>
          <li>
            Set policy editor to <strong>JSON</strong>. Paste the code below
            into the <strong>Policy Editor</strong> (replacing the previous
            code). Click <strong>Next</strong>
            <div className="py-3">
              <CodeSnippet
                code={iamPolicyJson}
                id="aws-iam-policy"
                maxCodeHeight="max-h-40"
              />
            </div>
          </li>
          <li>
            Set <strong>policy name</strong> to CoactiveReadAccess and create it
          </li>
        </AccessInstructionsList>
      </CollapsibleSection>
      <CollapsibleSection
        stepNumber={2}
        title="Create an IAM User"
        primaryAction={{ action: () => setActiveStepIdx((idx) => idx + 1) }}
        secondaryAction={{ action: () => setActiveStepIdx((idx) => idx - 1) }}
        defaultOpen={activeStepIdx === 1}
        key={`1-${activeStepIdx === 1}`}
      >
        <AccessInstructionsList>
          <li>
            Under <strong>Access management</strong>, go to{' '}
            <a
              href="https://console.aws.amazon.com/iam/home#/users"
              className="underline text-green-600 font-semibold"
            >
              Users
            </a>
            . Create an <strong>IAM user</strong> and name the user Coactive.
          </li>
          <li>
            Under <strong>Permission options</strong>, select{' '}
            <strong>Attach policies directly</strong>.
          </li>
          <li>
            In the <strong>Permission policies</strong> search bar, search for
            CoactiveReadAccess and select CoactiveReadAccess.
          </li>
          <li>
            Click <strong>Next</strong>, then <strong>Create user</strong>.
          </li>
        </AccessInstructionsList>
      </CollapsibleSection>
      <CollapsibleSection
        stepNumber={3}
        title="Create and upload access key"
        description="Coactive will only use this key to ingest your data. We will never read from your bucket without explicit permission."
        primaryAction={{
          action: createDataset,
          label: 'Create dataset',
          rightIcon: CheckIcon,
          disabled: createDatasetDisabled,
        }}
        secondaryAction={{ action: () => setActiveStepIdx((idx) => idx - 1) }}
        defaultOpen={activeStepIdx === 2}
        key={`2-${activeStepIdx === 2}`}
      >
        <AccessInstructionsList>
          <li>
            Under <strong>Users</strong>, select Coactive.
          </li>
          <li>
            In the upper-right of the <strong>Summary</strong> box (or under{' '}
            <strong>Security credentials</strong>), click{' '}
            <strong>Create access key</strong>.
          </li>
          <li>
            Select <strong>Third-party service</strong> &gt;{' '}
            <strong>Next</strong> &gt; <strong>Create access key</strong>.
          </li>
          <li>
            Click to <strong>Download .csv file</strong> and upload the access
            key below.
            <div className="mt-4">
              <FileUpload
                uploadUrl={`${process.env.REACT_APP_API_HOST}/api/ui/credentials/aws`}
                onUploadSuccess={(upload) => {
                  setCredentialsId(upload[0].response.credentialsId);
                }}
                onUploadError={(upload) => {
                  setUploadErrorText(upload[0].response.detail);
                }}
                acceptedTypes={['text/csv', 'application/csv']}
                illustrations={[
                  {
                    primary: CsvCredentialsIllustration,
                  },
                ]}
                error={uploadErrorText}
              >
                <p className="max-w-80 pointer-events-none text-md font-medium">
                  <span className="text-green-600">Upload CSV file</span> or
                  drag and drop
                </p>
                <p className="text-xs text-gray-500">
                  File must include access key ID and secret access key.
                </p>
              </FileUpload>
              <Checkbox
                id="public-access"
                name="public access configured"
                checked={publicAccessConfigured}
                onChange={(e) => setPublicAccessConfigured(e.target.checked)}
                label="I configured public access on this bucket (not recommended)"
              />
            </div>
          </li>
        </AccessInstructionsList>
      </CollapsibleSection>
    </div>
  );
};

export default ConfigureS3Access;
