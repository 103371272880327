import accepts from 'attr-accept';

export interface FileUploadStatus {
  [key: string]: number | 'error' | 'success';
}

// Error codes
export const FILE_INVALID_TYPE = 'file-invalid-type';
export const FILE_TOO_LARGE = 'file-too-large';
export const FILE_TOO_SMALL = 'file-too-small';
export const TOO_MANY_FILES = 'too-many-files';

export const ErrorCode = {
  FileInvalidType: FILE_INVALID_TYPE,
  FileTooLarge: FILE_TOO_LARGE,
  FileTooSmall: FILE_TOO_SMALL,
  TooManyFiles: TOO_MANY_FILES,
};

function isDefined(value) {
  return value !== undefined && value !== null;
}

// File Errors
export const getInvalidTypeRejectionErr = (accept) => {
  const acceptSanitized =
    Array.isArray(accept) && accept.length === 1 ? accept[0] : accept;
  const messageSuffix = Array.isArray(acceptSanitized)
    ? `one of ${acceptSanitized.join(', ')}`
    : acceptSanitized;
  return {
    code: FILE_INVALID_TYPE,
    message: `File type must be ${messageSuffix}`,
  };
};

export const getTooLargeRejectionErr = (maxSize) => ({
  code: FILE_TOO_LARGE,
  message: `File is larger than ${maxSize} ${maxSize === 1 ? 'byte' : 'bytes'}`,
});

export const getTooSmallRejectionErr = (minSize) => ({
  code: FILE_TOO_SMALL,
  message: `File is smaller than ${minSize} ${
    minSize === 1 ? 'byte' : 'bytes'
  }`,
});

export function getFileSize(file: File): string {
  const fileSize = file.size.toString();

  if (fileSize.length < 4) {
    return `${Math.round(+fileSize)} bytes`;
  }

  if (fileSize.length < 7) {
    return `${Math.round(+fileSize / 1024).toFixed(2)} KB`;
  }

  return `${(Math.round(+fileSize / 1024) / 1000).toFixed(2)} MB`;
}

// Firefox versions prior to 53 return a bogus MIME type for every file drag, so dragovers with
// that MIME type will always be accepted
export function fileAccepted(file, accept) {
  const isAcceptable =
    file.type === 'application/x-moz-file' || accepts(file, accept);
  return [
    isAcceptable,
    isAcceptable ? null : getInvalidTypeRejectionErr(accept),
  ];
}

export function fileMatchSize(file, minSize, maxSize) {
  if (isDefined(file.size)) {
    if (isDefined(minSize) && isDefined(maxSize)) {
      if (file.size > maxSize) return [false, getTooLargeRejectionErr(maxSize)];
      if (file.size < minSize) return [false, getTooSmallRejectionErr(minSize)];
    } else if (isDefined(minSize) && file.size < minSize)
      return [false, getTooSmallRejectionErr(minSize)];
    else if (isDefined(maxSize) && file.size > maxSize)
      return [false, getTooLargeRejectionErr(maxSize)];
  }
  return [true, null];
}

export function allFilesAccepted({
  files,
  acceptedFiles,
  minSize,
  maxSize,
  multiple,
  maxFiles,
}) {
  if (
    (!multiple && files.length > 1) ||
    (multiple && maxFiles >= 1 && files.length > maxFiles)
  ) {
    return false;
  }

  return files.every((file) => {
    const [accepted] = fileAccepted(file, acceptedFiles);
    const [sizeMatch] = fileMatchSize(file, minSize, maxSize);
    return accepted && sizeMatch;
  });
}

export function getFileKey(file: File): string {
  return `${file.name}-${file.size}`;
}

export function filterDuplicateFiles(
  files: File[],
  fileUploadStatus: FileUploadStatus,
): File[] {
  return files.filter((f) => fileUploadStatus[getFileKey(f)] === undefined);
}

export function allUploadsComplete(
  files: File[],
  fileUploadStatus: FileUploadStatus,
): boolean {
  return (
    // At least one upload is complete and all others have completed or errored.
    Boolean(files.find((file) => fileUploadStatus[getFileKey(file)] === 100)) &&
    files.every((file) => {
      const status = fileUploadStatus[getFileKey(file)];
      return status === 100 || status === 'error';
    })
  );
}
