import { PagedDataResponseAssetResponse, LabelApi } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useInfiniteQuery from 'hooks/useInfiniteQuery';
import toNumber from 'utils/ToNumber';

export const useGetCandidatesToLabelForImagePathInfiniteQueryKey = (
  conceptVersionId: string,
  imagePaths: string[],
) => ['getCandidatesToLabel', conceptVersionId, imagePaths];

export const useLoadMoreSimilarImages = (
  conceptVersionId: string,
  imagePaths: string[],
  firstPage: PagedDataResponseAssetResponse,
) => {
  const { initializeAPI } = useAPIContext();
  const query = useInfiniteQuery(
    useGetCandidatesToLabelForImagePathInfiniteQueryKey(
      conceptVersionId,
      imagePaths,
    ),
    async ({ signal, pageParam }) => {
      const api = await initializeAPI<LabelApi>(LabelApi);
      return api.getLabelCandidatesByImagePaths(
        {
          conceptVersionId: conceptVersionId.toString(),
          imagePaths,
          offset: firstPage.data.length * toNumber(pageParam || 0),
          limit: firstPage.data.length,
        },
        { signal },
      );
    },
    {
      enabled: true,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.meta?.page?.currentPage,
      initialData: { pages: [firstPage], pageParams: [1] },
    },
  );
  return query;
};
