/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Metadata about a video audio segment
 * @export
 * @interface AudioSegment
 */
export interface AudioSegment {
    /**
     * The start time in milliseconds of the beginning of the audio clip
     * @type {number}
     * @memberof AudioSegment
     */
    startTimeMs: number;
    /**
     * The end time in milliseconds of the end of the audio clip
     * @type {number}
     * @memberof AudioSegment
     */
    endTimeMs: number;
    /**
     * The text transcription of the audio clip
     * @type {string}
     * @memberof AudioSegment
     */
    speechToTextTranscription: string;
}

export function AudioSegmentFromJSON(json: any): AudioSegment {
    return AudioSegmentFromJSONTyped(json, false);
}

export function AudioSegmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AudioSegment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startTimeMs': json['startTimeMs'],
        'endTimeMs': json['endTimeMs'],
        'speechToTextTranscription': json['speechToTextTranscription'],
    };
}

export function AudioSegmentToJSON(value?: AudioSegment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startTimeMs': value.startTimeMs,
        'endTimeMs': value.endTimeMs,
        'speechToTextTranscription': value.speechToTextTranscription,
    };
}

