import React from 'react';

const Pills: React.FC<{
  value: string;
}> = function Pill({ value }) {
  const pills = typeof value === 'string' ? [value] : value;
  return (
    <div className="flex flex-wrap space-x-1">
      {pills?.map((pill) => (
        <span
          key={pill}
          className="rounded-md border border-1 border-slate-400 px-2 py-1 uppercase text-xs"
        >
          {pill}
        </span>
      ))}
    </div>
  );
};

export default Pills;
