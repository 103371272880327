import { BasicDescription } from 'api/generated';

export const truncateElements = (
  elements: BasicDescription[],
  limit: number,
) => {
  const elementNames = elements.map((element) => element.name);

  if (elementNames.length <= limit) return elementNames.join(', ');
  const visibleElements = elementNames.slice(0, limit);

  return `${visibleElements.join(', ')}, +${elements.length - limit}`;
};

export const truncateElementsFromString = (elements: string, limit: number) => {
  const splitElements = elements.split(', ');

  if (splitElements.length <= limit) return elements;
  const visibleElements = splitElements.slice(0, limit);

  return `${visibleElements.join(', ')}, +${splitElements.length - limit}`;
};
