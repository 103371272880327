import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, {
  Fragment,
  MouseEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Action } from 'types/action';

interface ActionMenuProps {
  actions: Action[];
  insideScrollableContainer?: boolean;
  button?: Element | ReactElement<any, any> | string;
  buttonStyle?: string;
  menuStyle?: string;
  dataTestAccessor?: string;
}

const ActionsMenu = function ActionsMenu({
  actions,
  insideScrollableContainer: insideScrollableContainerProp,
  button,
  menuStyle,
  buttonStyle,
  dataTestAccessor,
}: ActionMenuProps) {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const insideScrollableContainer = useRef(insideScrollableContainerProp);
  const openedSinceScroll = useRef(false);
  const overrideOpenRef = useRef(undefined);
  const [overrideOpen, setOverrideOpen] = useState<boolean>();
  const [key, setMenuKey] = useState(0);

  useEffect(() => {
    const closeOnScroll = () => {
      openedSinceScroll.current = false;
      setOverrideOpen(false);
      setMenuKey((k) => k + 1);
    };
    if (insideScrollableContainer.current) {
      window.addEventListener('scroll', closeOnScroll);
    }
    return () => window.removeEventListener('scroll', closeOnScroll);
  }, []);

  const setOverrideOpenFromInternalState = (open) => {
    if (!insideScrollableContainer.current || openedSinceScroll.current) {
      overrideOpenRef.current = open;
    }
  };

  useEffect(() => {
    if (overrideOpen) {
      const listener = (e) => {
        if (
          menuRef.current !== e.target &&
          !menuRef.current?.contains(e.target) &&
          !buttonRef.current?.contains(e.target)
        ) {
          setOverrideOpen(false);
        }
      };
      window.addEventListener('click', listener);
      return () => window.removeEventListener('click', listener);
    }
    return undefined;
  }, [overrideOpen]);

  const offset =
    insideScrollableContainer.current && buttonRef.current
      ? buttonRef.current.getBoundingClientRect().top +
        buttonRef.current.offsetHeight -
        10
      : undefined;

  return (
    <Menu as="div" className="flex relative text-left" key={key}>
      {({ open }) => (
        <>
          {setOverrideOpenFromInternalState(open)}
          {button ?? (
            <Menu.Button>
              <button
                ref={(ref) => {
                  buttonRef.current = ref;
                }}
                type="button"
                data-cy={
                  dataTestAccessor
                    ? `row-actions-${dataTestAccessor}`
                    : undefined
                }
                className={classNames(
                  '-my-2 p-2 rounded-full bg-white flex items-center text-gray-800 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600',
                  buttonStyle,
                )}
                onClick={() => {
                  openedSinceScroll.current = true;
                  setOverrideOpen(!overrideOpen);
                }}
              >
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </Menu.Button>
          )}
          <Transition
            as={Fragment}
            show={overrideOpen ?? open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              style={offset ? { top: `${offset}px` } : undefined}
              className={classNames(
                {
                  'absolute right-0 origin-top-right':
                    !insideScrollableContainer.current,
                  'fixed ml-[-12rem]': insideScrollableContainer.current,
                },
                'mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20',
                menuStyle,
              )}
              ref={(ref) => {
                menuRef.current = ref;
              }}
            >
              <div className="py-1">
                {actions.map((option) => (
                  <Menu.Item key={option.key}>
                    {({ active }) => (
                      <button
                        data-cy={
                          option.dataTestId && dataTestAccessor
                            ? `${option.dataTestId}-${dataTestAccessor}`
                            : undefined
                        }
                        type="button"
                        disabled={option.disabled}
                        className={classNames(
                          active && 'bg-gray-100',
                          active && !option.danger && 'text-gray-900',
                          !active && !option.danger && 'text-gray-700',
                          active && option.danger && 'text-red-900',
                          !active && option.danger && 'text-red-700',
                          option.danger && 'font-medium',
                          option.borderTop ? 'border-t' : '',
                          'flex justify-between px-4 py-2 text-sm w-full',
                        )}
                        onClick={(e: MouseEvent) => {
                          option.action?.(e);
                          setOverrideOpen(false);
                        }}
                      >
                        <span>{option.label}</span>
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

ActionsMenu.defaultProps = {
  insideScrollableContainer: false,
  button: undefined,
  menuStyle: undefined,
  buttonStyle: undefined,
  dataTestAccessor: undefined,
};

export default ActionsMenu;
