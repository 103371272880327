/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface GenerateTrailerRequest
 */
export interface GenerateTrailerRequest {
    /**
     * The shot ids to include
     * @type {Array<string>}
     * @memberof GenerateTrailerRequest
     */
    shotIds: Array<string>;
}

export function GenerateTrailerRequestFromJSON(json: any): GenerateTrailerRequest {
    return GenerateTrailerRequestFromJSONTyped(json, false);
}

export function GenerateTrailerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateTrailerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shotIds': json['shotIds'],
    };
}

export function GenerateTrailerRequestToJSON(value?: GenerateTrailerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shotIds': value.shotIds,
    };
}

