/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicDescription,
    BasicDescriptionFromJSON,
    BasicDescriptionFromJSONTyped,
    BasicDescriptionToJSON,
} from './BasicDescription';

/**
 * A concept object plus the names and ids of its associated dataset and embedding
 * @export
 * @interface FullConceptResponse
 */
export interface FullConceptResponse {
    /**
     * The user that created the resource
     * @type {string}
     * @memberof FullConceptResponse
     */
    createdUserId: string;
    /**
     * The created datetime of the resource
     * @type {Date}
     * @memberof FullConceptResponse
     */
    createdDt: string;
    /**
     * The user that last updated the resource
     * @type {string}
     * @memberof FullConceptResponse
     */
    updatedUserId: string;
    /**
     * The datetime the resource was last updated
     * @type {Date}
     * @memberof FullConceptResponse
     */
    updatedDt: string;
    /**
     * The name of the concept
     * @type {string}
     * @memberof FullConceptResponse
     */
    name: string;
    /**
     * An optional description for the concept
     * @type {string}
     * @memberof FullConceptResponse
     */
    description?: string | null;
    /**
     * The unique identifier for the concept
     * @type {string}
     * @memberof FullConceptResponse
     */
    conceptId: string;
    /**
     * Threshold above which classification is positive
     * @type {number}
     * @memberof FullConceptResponse
     */
    threshold: number;
    /**
     * How strongly the model adapts to the training labels. Regularization strength. Lower value means the model fits more heavily to the training data 
     * @type {number}
     * @memberof FullConceptResponse
     */
    regularization: number;
    /**
     * 
     * @type {BasicDescription}
     * @memberof FullConceptResponse
     */
    dataset: BasicDescription;
    /**
     * 
     * @type {BasicDescription}
     * @memberof FullConceptResponse
     */
    embedding: BasicDescription;
}

export function FullConceptResponseFromJSON(json: any): FullConceptResponse {
    return FullConceptResponseFromJSONTyped(json, false);
}

export function FullConceptResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullConceptResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdUserId': json['createdUserId'],
        'createdDt': json['createdDt'],
        'updatedUserId': json['updatedUserId'],
        'updatedDt': json['updatedDt'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'conceptId': json['conceptId'],
        'threshold': json['threshold'],
        'regularization': json['regularization'],
        'dataset': BasicDescriptionFromJSON(json['dataset']),
        'embedding': BasicDescriptionFromJSON(json['embedding']),
    };
}

export function FullConceptResponseToJSON(value?: FullConceptResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdUserId': value.createdUserId,
        'createdDt': value.createdDt,
        'updatedUserId': value.updatedUserId,
        'updatedDt': value.updatedDt,
        'name': value.name,
        'description': value.description,
        'conceptId': value.conceptId,
        'threshold': value.threshold,
        'regularization': value.regularization,
        'dataset': BasicDescriptionToJSON(value.dataset),
        'embedding': BasicDescriptionToJSON(value.embedding),
    };
}

