import { User as Auth0User, useAuth0 } from '@auth0/auth0-react';
import { MeResponse, Permissions, UserApi } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useLoginWithRedirect from 'hooks/useLoginWithRedirect';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface UserContextState {
  authCookieSet: boolean;
  me: MeResponse | undefined;
  hasPermissions: (requiredPermissions?: Permissions[]) => boolean;
  auth0User: Auth0User | undefined;
  expirationDate: string | undefined | null;
  isTrialUser: boolean;
  isTrialOver: boolean;
  didRequestExtension: boolean;
}

const UserContext = createContext<UserContextState>({
  authCookieSet: true,
  me: undefined,
  hasPermissions: () => false,
  auth0User: undefined,
  expirationDate: undefined,
  isTrialUser: false,
  isTrialOver: false,
  didRequestExtension: false,
});

export const useGetMeQuery = () => {
  const { isLoading: isAuth0Loading } = useAuth0();
  const { initializeAPI } = useAPIContext();
  const query = useQuery(
    ['getCurrentUser'],
    async ({ signal }) => {
      const api = await initializeAPI<UserApi>(UserApi);
      return api.getCurrentUser({
        signal,
        credentials: 'include',
      });
    },
    { enabled: !isAuth0Loading },
  );
  return query;
};

export const useRequestTrialExtensionMutation = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(['requestTrialExtension'], async () => {
    const api = await initializeAPI<UserApi>(UserApi);
    return api.sendTrialExtensionRequest();
  });
};

export const UserContextWrapper: React.FC<PropsWithChildren> =
  function UserContextWrapper({ children }) {
    const { user: auth0User } = useAuth0();
    const { isLoading, isError, data: me } = useGetMeQuery();
    const [isTrialOverState, setIsTrialOverState] = useState(false);
    const loginWithRedirect = useLoginWithRedirect();

    const recalculate = () => {
      setIsTrialOverState(
        Boolean(
          new Date(me?.organization?.expirationDate ?? 0).getTime() <
            new Date().getTime(),
        ),
      );
    };

    useEffect(() => {
      let interval;
      if (me?.organization?.expirationDate != null) {
        recalculate();
        interval = setInterval(() => recalculate(), 5000);
      }
      return () => clearInterval(interval);
    }, [me?.organization?.expirationDate]);
    useEffect(() => {
      if (isError) {
        loginWithRedirect();
      }
    }, [isError]);

    const hasPermissions = useCallback(
      (requiredPermissions?: Permissions[]) =>
        requiredPermissions?.every((val) => me?.permissions.includes(val)) ??
        true,
      [me?.permissions],
    );

    const value = useMemo(() => {
      const { heap } = window as any;
      if (auth0User && heap && heap.identify) {
        heap.identify(auth0User.sub);
        heap.addUserProperties({
          email: auth0User.email,
          name: auth0User.name,
          org_id: auth0User.org_id || '',
        });
      }

      return {
        authCookieSet: !isLoading,
        me,
        hasPermissions,
        auth0User,
        expirationDate: me?.organization?.expirationDate,
        isTrialUser: Boolean(me?.organization?.expirationDate != null),
        isTrialOver: isTrialOverState,
        didRequestExtension: me?.requestedTrialExtension ?? false,
      };
    }, [
      isLoading,
      me,
      auth0User,
      (window as any).heap?.identify,
      isTrialOverState,
    ]);

    return (
      <UserContext.Provider value={value}>{children}</UserContext.Provider>
    );
  };

export function useUserContext() {
  return useContext(UserContext);
}

export default UserContext;
