import { MetadataPreviewResponse } from 'api/generated';
import EmptyStateMessage from 'components/EmptyStateMessage';
import FadeTransition from 'components/FadeTransition';
import Table from 'components/Table';
import { useGetMetadataPreviewInfiniteQuery } from 'pages/datasets/detail/components/queries';
import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

const MetadataPreview = ({
  datasetId,
  csvPath,
  initialData,
}: {
  datasetId: string;
  csvPath: string;
  initialData: MetadataPreviewResponse;
}) => {
  const { data: metadata, fetchNextPage: fetchNextMetadataPreviewPage } =
    useGetMetadataPreviewInfiniteQuery(datasetId, csvPath, initialData);

  const [metadataColumns, metadataRows, totalMetadataRows] = useMemo(
    () =>
      metadata
        ? [
            metadata.pages[0]?.columns ?? [],
            metadata.pages.reduce(
              (acc: any[], item) => [...acc, ...(item?.data ?? [])],
              [],
            ) || [],
            metadata.pages.length
              ? metadata.pages[metadata.pages.length - 1]?.meta?.page?.total ??
                0
              : 0,
          ]
        : [undefined, undefined, 0],
    [metadata],
  );
  const hasMore = useMemo(
    () => (metadataRows ? metadataRows.length < totalMetadataRows : false),
    [metadataRows, totalMetadataRows],
  );

  return (
    <FadeTransition show={Boolean(metadataRows)} appear>
      {metadataRows && metadataColumns && (
        <InfiniteScroll
          dataLength={metadataRows?.length}
          next={fetchNextMetadataPreviewPage}
          loader={<div />}
          hasMore={hasMore}
        >
          <Table
            columns={metadataColumns}
            data={metadataRows}
            idKey="row"
            loadStrategy="scroll"
            total={totalMetadataRows}
            showRowCount
            emptyMessage={
              <div className="text-center py-12 px-2 text-sm">
                <EmptyStateMessage>
                  <p className="pb-4">No metadata to show</p>
                </EmptyStateMessage>
              </div>
            }
          />
        </InfiniteScroll>
      )}
    </FadeTransition>
  );
};

export default MetadataPreview;
