import {
  ConceptApi,
  CreateConceptOperationRequest,
  CreateVersionOperationRequest,
  DatasetStatusEnum,
  ValidateConceptNameOperationRequest,
  VersionApi,
} from 'api/generated';
import Button from 'components/Button';
import DatasetDropdown from 'components/DatasetDropdown';
import Dropdown from 'components/Dropdown';
import ErrorText from 'components/ErrorText';
import Main from 'components/Main';
import TextArea from 'components/TextArea';
import TextInput from 'components/TextInput';
import { useAPIContext } from 'context/APIContext';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import useQueryParams from 'hooks/useQueryParams';
import { getConceptsQueryKey } from 'queries/concepts';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import getApiConf from 'utils/ApiConfiguration';
import { validate as uuidValidate } from 'uuid';
import EmbeddingDropdown from './components/EmbeddingDropdown';

const CreateConcept = function CreateConcept() {
  const { initializeAPI } = useAPIContext();
  const navigate = useNavigate();
  const { conceptId } = useParams();
  const queryParams = useQueryParams();
  const queryClient = useQueryClient();

  const [datasetId, setDatasetId] = useState<string | null>(
    queryParams.get('datasetId'),
  );
  const [embeddingId, setEmbeddingId] = useState<string | null>(null);
  const [conceptName, setConceptName] = useState<string>();
  const [conceptNameValidationError, setConceptNameValidationError] =
    useState<string>();
  const [description, setDescription] = useState<string>();
  const [versionName, setVersionName] = useState<string>();
  const [submitError, setSubmitError] = useState<string | undefined>();
  if (conceptId && !uuidValidate(conceptId)) {
    navigate('/concepts');
    return <div />;
  }
  const conceptNameQuery = queryParams.get('name');
  const getConceptByIdQuery = useQuery(
    ['getConceptByIdApiConceptsConceptIdGet', conceptId],
    async ({ queryKey, signal }) => {
      const [, _conceptId] = queryKey;
      const api = await initializeAPI<ConceptApi>(ConceptApi);
      return api.getConcept(
        {
          conceptId: _conceptId || '',
        },
        { signal },
      );
    },
    { enabled: false },
  );

  useEffect(() => {
    if (uuidValidate(conceptId || '')) {
      getConceptByIdQuery.refetch();
    }
  }, [conceptId]);

  const concept = getConceptByIdQuery.data;

  const { mutate: createConcept, isLoading } = useMutation(
    ['createVersionApiVersionsPost'],
    (variables: CreateVersionOperationRequest) =>
      new VersionApi(getApiConf()).createVersion(variables),
  );

  const createConceptMutation = useMutation(
    ['createVersionApiVersionsPost'],
    async (variables: CreateConceptOperationRequest) => {
      const api = await initializeAPI<ConceptApi>(ConceptApi);
      return api.createConcept(variables);
    },
  );

  const validateConceptNameMutation = useMutation(
    ['validateConceptNameEndpointApiValidateConceptNamePost'],
    async (variables: ValidateConceptNameOperationRequest) => {
      const api = await initializeAPI<ConceptApi>(ConceptApi);
      return api.validateConceptName(variables);
    },
  );

  const submit = () => {
    if (submitError) {
      setSubmitError(undefined);
    }
    if (conceptId) {
      createConcept(
        {
          createVersionRequest: {
            conceptId,
            name: versionName!,
            description,
          },
        },
        {
          onSuccess: (response) => {
            queryClient.invalidateQueries(getConceptsQueryKey());
            navigate(`/concepts/${conceptId}/versions/latest`, {
              state: {
                conceptVersion: response,
              },
            });
          },
          onError: () => {
            setSubmitError(
              'An error occurred while creating the concept version. Try again.',
            );
          },
        },
      );
    } else {
      if (!embeddingId) {
        setSubmitError('An embedding is required to create a concept');
        return;
      }
      createConceptMutation.mutate(
        {
          createConceptRequest: {
            embeddingId,
            name: conceptName!,
            description,
          },
        },
        {
          onSuccess: (response) => {
            queryClient.invalidateQueries(getConceptsQueryKey());
            const { latestVersion, ...c } = response;
            (latestVersion as any).concept = { id: c.conceptId, name: c.name };
            navigate(`/concepts/${c.conceptId}/versions/latest`, {
              state: {
                concept: c,
                conceptVersion: latestVersion,
              },
            });
          },
          onError: () => {
            setSubmitError(
              'An error occurred while creating the concept. Try again.',
            );
          },
        },
      );
    }
  };

  const conceptOptions = useMemo(
    () =>
      concept
        ? [
            {
              label: concept.name,
              value: `${concept.conceptId}`,
            },
          ]
        : [],
    [concept],
  );

  useEffect(() => {
    if (conceptNameQuery) {
      setConceptName(conceptNameQuery);
    }
  }, []);

  // TODO: update to use inputValidationCallbackFactory
  const validateName = useCallback(
    async (value: string) => {
      if (value && embeddingId) {
        const response = await validateConceptNameMutation.mutateAsync({
          validateConceptNameRequest: {
            name: value,
            embeddingId,
          },
        });
        setConceptNameValidationError(
          response?.valid?.message ?? response?.unique?.message ?? undefined,
        );
        return response;
      }
      return undefined;
    },
    [concept, embeddingId],
  );

  useEffect(() => {
    if (conceptName) {
      validateName(conceptName);
    }
  }, [embeddingId]);

  return (
    <Main
      backButtonTo="/concepts"
      title={
        <h1>
          {conceptId
            ? `Create a New Version of "${concept?.name}"`
            : 'Create a Concept'}
        </h1>
      }
    >
      <div className="pb-24">
        <div className="py-4 bg-white rounded-md 2xl:max-w-8xl mx-auto">
          <div className="py-2 max-w-sm">
            <DatasetDropdown
              selectedId={datasetId}
              setSelectedId={(selectedId) => {
                setEmbeddingId(null);
                setDatasetId(selectedId);
              }}
              statusesToInclude={[DatasetStatusEnum.Ready]}
              dataTestId="create-concept-page-dataset-dropdown"
            />
          </div>
          <div className="py-2 max-w-sm">
            <EmbeddingDropdown
              datasetId={datasetId}
              selectedId={embeddingId}
              setSelectedId={(selectedId) => {
                setEmbeddingId(selectedId);
              }}
            />
          </div>
          {conceptId && (
            <div className="py-2 max-w-sm">
              <Dropdown
                label="Concept"
                options={conceptOptions}
                selected={`${concept?.conceptId}`}
                disabled
              />
            </div>
          )}
          {!conceptId && (
            <div className="py-2 max-w-sm">
              <TextInput
                id="name"
                name="name"
                label="Name"
                defaultValue=""
                placeholder="your_concept_name"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConceptName(e.target.value)
                }
                validation={validateName}
                error={conceptNameValidationError}
                value={conceptName}
                dataTestId="create-concept-page-name"
              />
            </div>
          )}
          {conceptId && (
            <div className="py-2 max-w-sm">
              <TextInput
                id="version_name"
                name="version name"
                label="Version Name"
                placeholder={conceptId ? 'new_version_name' : 'v1'}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setVersionName(e.target.value)
                }
              />
            </div>
          )}
          <div className="py-2 max-w-2xl">
            <TextArea
              id="description"
              name="description"
              label="Description (Optional)"
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setDescription(e.target.value)
              }
              dataTestId="create-concept-page-description"
            />
          </div>
          <div className="pt-5">
            <div className="flex">
              <Button
                type="submit"
                disabled={
                  !(
                    (conceptId || embeddingId) &&
                    (conceptId || datasetId) &&
                    (conceptId || conceptName) &&
                    (!conceptId || versionName) &&
                    !conceptNameValidationError
                  )
                }
                onClick={submit}
                loading={isLoading}
              >
                <span data-cy="create-concept-page-submit-button">Create</span>
              </Button>
              <Button
                type="button"
                className="ml-3"
                buttonStyle="secondary"
                onClick={() => navigate('/concepts')}
              >
                Cancel
              </Button>
            </div>
            <ErrorText errorStyle="form">{submitError}</ErrorText>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default CreateConcept;
