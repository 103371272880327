/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MetadataPreviewResponse,
    MetadataPreviewResponseFromJSON,
    MetadataPreviewResponseFromJSONTyped,
    MetadataPreviewResponseToJSON,
} from './MetadataPreviewResponse';

/**
 * Base model for all schema
 * @export
 * @interface MetadataUploadResponse
 */
export interface MetadataUploadResponse {
    /**
     * Path to the csv file
     * @type {string}
     * @memberof MetadataUploadResponse
     */
    csvPath: string;
    /**
     * 
     * @type {MetadataPreviewResponse}
     * @memberof MetadataUploadResponse
     */
    preview: MetadataPreviewResponse;
}

export function MetadataUploadResponseFromJSON(json: any): MetadataUploadResponse {
    return MetadataUploadResponseFromJSONTyped(json, false);
}

export function MetadataUploadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetadataUploadResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'csvPath': json['csvPath'],
        'preview': MetadataPreviewResponseFromJSON(json['preview']),
    };
}

export function MetadataUploadResponseToJSON(value?: MetadataUploadResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'csvPath': value.csvPath,
        'preview': MetadataPreviewResponseToJSON(value.preview),
    };
}

