/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Comparator,
    ComparatorFromJSON,
    ComparatorFromJSONTyped,
    ComparatorToJSON,
} from './Comparator';

/**
 * 
 * @export
 * @interface ConceptParameter
 */
export interface ConceptParameter {
    /**
     * 
     * @type {Comparator}
     * @memberof ConceptParameter
     */
    comparator?: Comparator;
    /**
     * The unique identifier for the concept
     * @type {string}
     * @memberof ConceptParameter
     */
    conceptId: string;
    /**
     * The probability score to compare
     * @type {number}
     * @memberof ConceptParameter
     */
    value?: number | null;
    /**
     * The name of the concept
     * @type {string}
     * @memberof ConceptParameter
     */
    name?: string | null;
    /**
     * The positive classification threshold
     * @type {number}
     * @memberof ConceptParameter
     */
    threshold?: number | null;
}

export function ConceptParameterFromJSON(json: any): ConceptParameter {
    return ConceptParameterFromJSONTyped(json, false);
}

export function ConceptParameterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConceptParameter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comparator': !exists(json, 'comparator') ? undefined : ComparatorFromJSON(json['comparator']),
        'conceptId': json['conceptId'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'threshold': !exists(json, 'threshold') ? undefined : json['threshold'],
    };
}

export function ConceptParameterToJSON(value?: ConceptParameter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comparator': ComparatorToJSON(value.comparator),
        'conceptId': value.conceptId,
        'value': value.value,
        'name': value.name,
        'threshold': value.threshold,
    };
}

