import { ClassificationScore } from 'api/generated';
import Button from 'components/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ConceptScoreRowProps {
  concept: ClassificationScore;
  currentConceptId?: string;
}

const ConceptScoreRow: React.FC<ConceptScoreRowProps> = ({
  concept,
  currentConceptId,
}) => {
  const navigate = useNavigate();
  const isCurrentConcept = concept.conceptId === currentConceptId;

  return (
    <li
      key={concept.conceptId}
      className=" border-b border-gray-100 last:border-b-0"
    >
      <div className="flex py-2 items-center justify-between">
        <div className="flex">
          <div className="flex items-center rounded text-gray-800 border-slate-200 py-0.5 grow shrink-1 overflow-hidden text-ellipsis">
            <Button
              buttonStyle="badge"
              disabled={isCurrentConcept}
              color={isCurrentConcept ? 'bg-emerald-50' : ''}
              textColor={isCurrentConcept ? 'disabled:!text-emerald-500' : ''}
              onClick={() =>
                navigate(`/concepts/${concept.conceptId}/versions/latest`)
              }
              padding="py-0.5 px-3"
            >
              {concept.conceptName}
            </Button>
          </div>
        </div>
        <p className="text-end text-sm">{concept.score.toFixed(2)}</p>
      </div>
    </li>
  );
};

ConceptScoreRow.defaultProps = {
  currentConceptId: undefined,
};

export default ConceptScoreRow;
