import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Tooltip from './Tooltip';

interface BadgeListProps {
  elements: string[];
  maxElementsToShow: number;
}

export const BadgeList: React.FunctionComponent<BadgeListProps> =
  function BadgeList({ elements, maxElementsToShow }) {
    const elementsToDisplay =
      elements.length <= maxElementsToShow
        ? elements
        : elements.slice(0, maxElementsToShow);
    const excessCount = elements.length - maxElementsToShow;

    const windowWidth = window.innerWidth;

    const characterLimit = windowWidth > 1280 ? 10 : 5;

    const getTruncatedTextWithTooltip = (text: string) => ({
      text:
        text.length > characterLimit
          ? `${text.slice(0, characterLimit)}...`
          : text,
      hasTooltip: text.length > characterLimit,
    });

    return (
      <div className="flex">
        {elementsToDisplay.map((element) => {
          const tooltipId = uuidv4();
          const truncatedElement = getTruncatedTextWithTooltip(element);

          return (
            <div key={`tableRowBadge-${tooltipId}`}>
              <span
                data-tooltip-id={`tableRowBadge-${tooltipId}` || ''}
                data-tooltip-content={element || ''}
                data-tooltip-place="top"
                className="m-0.5 py-1 px-2 bg-emerald-50 rounded text-gray-600 font-medium text-sm"
              >
                {truncatedElement.text}
              </span>
              {truncatedElement.hasTooltip && (
                <Tooltip id={`tableRowBadge-${tooltipId}`}>
                  <span>{element}</span>
                </Tooltip>
              )}
            </div>
          );
        })}
        {excessCount > 0 && (
          <span className="flex items-center px-1 font-medium text-gray-600">
            +{excessCount}
          </span>
        )}
      </div>
    );
  };
