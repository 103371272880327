/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Possible sort orders
 * 
 * asc: ascending
 *  desc: descending
 * @export
 */
export const SortDirection = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type SortDirection = typeof SortDirection[keyof typeof SortDirection];


export function SortDirectionFromJSON(json: any): SortDirection {
    return SortDirectionFromJSONTyped(json, false);
}

export function SortDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortDirection {
    return json as SortDirection;
}

export function SortDirectionToJSON(value?: SortDirection | null): any {
    return value as any;
}

