import { ImageApi } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useQuery from 'hooks/useQuery';

export const useGetImageDownloadUrl = (
  imageId: string,
  shouldFetch: boolean = true,
) => {
  const { initializeAPI } = useAPIContext();

  return useQuery(
    ['getImageDownloadUrl', imageId],
    async () => {
      const api = await initializeAPI<ImageApi>(ImageApi);
      return api.getImageDownloadUrl({
        coactiveImageId: imageId,
      });
    },
    {
      enabled: shouldFetch,
    },
  );
};
