import { Transition } from '@headlessui/react';
import { ClockIcon, PlayIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { EditorState, EditorView } from '@uiw/react-codemirror';
import { DatasetStatusEnum } from 'api/generated';
import Button from 'components/Button';
import DatasetDropdown from 'components/DatasetDropdown';
import SidebarContext from 'context/SidebarContext';
import EmbeddingDropdown from 'pages/concepts/create/components/EmbeddingDropdown';
import CodeMirror from 'pages/queries/CodeMirror';
import { getSelectedText } from 'pages/queries/codemirror/utils';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import autoFormatSql from './codemirror/autoformat';

interface WorkspaceProps {
  runQuery?: (query: string, embeddingId: string) => void;
  onChange?: (val: string, embeddingId: string | null) => void;
  onResize?: (height: number) => void;
  defaultValue?: string;
  runBtnLabel?: string;
  runBtnSelected?: string;
  useLocalStorageDefaultValue?: boolean;
  persistToLocalStorage?: boolean;
  selectedDatasetId?: string;
  defaultEmbeddingId?: string | null;
  codeMirrorDefaultHeight?: string;
  disabled?: boolean;
}

const QUERY_WORKSPACE = 'query_workspace';

const Workspace: React.FunctionComponent<WorkspaceProps> = function Workspace({
  runQuery,
  onChange,
  onResize,
  defaultValue,
  runBtnLabel,
  runBtnSelected,
  useLocalStorageDefaultValue,
  persistToLocalStorage,
  selectedDatasetId,
  defaultEmbeddingId,
  codeMirrorDefaultHeight,
  disabled,
}) {
  const calcDefaultValue =
    defaultValue ||
    (useLocalStorageDefaultValue
      ? localStorage.getItem(QUERY_WORKSPACE)
      : '') ||
    '';
  const [queryValue, setQueryValue] = useState<string>(calcDefaultValue);
  const [queryToExecute, setQueryToExecute] = useState<string>(queryValue);
  const stateRef = useRef<EditorState | null | undefined>();
  const viewRef = useRef<EditorView | null | undefined>();
  const [hasHighlightedText, setHasHighlightedText] = useState<boolean>(false);
  const [datasetId, setDatasetId] = useState<string | null>(
    selectedDatasetId ?? null,
  );
  const [embeddingId, setEmbeddingId] = useState<string | null>(
    defaultEmbeddingId ?? null,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (persistToLocalStorage) {
      const interval = setInterval(() => {
        window.localStorage.setItem(QUERY_WORKSPACE, queryValue || '');
      }, 500);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [queryValue]);

  useEffect(() => {
    setQueryValue(calcDefaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (selectedDatasetId && selectedDatasetId !== datasetId) {
      setDatasetId(selectedDatasetId);
    }
  }, [selectedDatasetId]);

  const onTextChange = useCallback(
    (e) => {
      setQueryValue(e);
      onChange?.(e, embeddingId);
    },
    [setQueryValue, onChange, embeddingId],
  );

  const updateDatasetId = useCallback(
    (id: string) => {
      setDatasetId(id);
      onChange?.(queryValue, embeddingId);
    },
    [queryValue],
  );

  const updateEmbeddingId = useCallback(
    (id: string | null) => {
      setEmbeddingId(id);
      onChange?.(queryValue, id);
    },
    [queryValue],
  );

  const handleUpdateEditor = () => {
    if (stateRef.current) {
      const highlightedText = getSelectedText(stateRef.current).trim();
      const editorText = stateRef.current.doc.toString().trim();
      const hasHighlight = highlightedText.length > 0;

      setHasHighlightedText(hasHighlight);
      setQueryToExecute(hasHighlight ? highlightedText : editorText);
    }
  };

  const editor = useMemo(
    () => (
      <CodeMirror
        value={queryValue}
        onChange={onTextChange}
        onUpdate={handleUpdateEditor}
        onResize={onResize}
        defaultHeight={codeMirrorDefaultHeight}
        heightPreferenceKey="coactive-cm-workspace-height"
        stateRef={stateRef}
        viewRef={viewRef}
        editable={!disabled}
      />
    ),
    [
      onResize,
      onTextChange,
      hasHighlightedText,
      datasetId,
      embeddingId,
      queryValue,
      disabled,
    ],
  );

  return (
    <SidebarContext.Consumer>
      {(context) => (
        <>
          <div className="flex justify-between items-end mb-4 flex-wrap gap-y-4">
            <div className="self-start grow md:grow-0 mt-4 lg:mt-0">
              <div className="flex gap-2 flex-wrap">
                <div>
                  <DatasetDropdown
                    selectedId={datasetId}
                    setSelectedId={updateDatasetId}
                    statusesToInclude={[DatasetStatusEnum.Ready]}
                    disabled={disabled}
                  />
                </div>
                <div>
                  <EmbeddingDropdown
                    key={datasetId}
                    datasetId={datasetId}
                    selectedId={embeddingId}
                    setSelectedId={updateEmbeddingId}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap grow justify-end gap-2">
              <div>
                <Transition
                  appear={false}
                  show={!context.leftSidebarOpen}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Button
                    color="bg-gray-400"
                    hoverColor="bg-gray-500"
                    onClick={() => navigate('/queries/all')}
                  >
                    <ClockIcon className="h-5 w-5 text-white" />
                    <span className="pl-2">History</span>
                  </Button>
                </Transition>
              </div>
              <Button
                color="bg-pink-500"
                hoverColor="bg-pink-600"
                disabled={disabled}
                onClick={() => autoFormatSql(stateRef.current, viewRef.current)}
              >
                <SparklesIcon className="h-5 w-5 text-white" />
                <span className="pl-2">Format</span>
              </Button>
              <Button
                color="bg-green-500"
                hoverColor="bg-green-600"
                disabled={
                  !datasetId || !embeddingId || !queryToExecute || disabled
                }
                onClick={() => {
                  if (datasetId && embeddingId) {
                    return runQuery?.(queryToExecute, embeddingId);
                  }
                  return null;
                }}
              >
                <PlayIcon className="h-5 w-5 text-white" />
                <span className="pl-2">
                  {hasHighlightedText ? runBtnSelected : runBtnLabel}
                </span>
              </Button>
            </div>
          </div>
          {editor}
        </>
      )}
    </SidebarContext.Consumer>
  );
};

Workspace.defaultProps = {
  runQuery: undefined,
  onChange: undefined,
  onResize: undefined,
  defaultValue: undefined,
  runBtnLabel: 'Run',
  runBtnSelected: 'Run Selected',
  useLocalStorageDefaultValue: true,
  persistToLocalStorage: true,
  selectedDatasetId: undefined,
  codeMirrorDefaultHeight: undefined,
  disabled: false,
  defaultEmbeddingId: null,
};

export default Workspace;
