import { parseTemplate } from 'url-template';

/**
 * Formats a URL path given a path template and variables
 * @param pathTemplate ex. /concept/{id}
 * @param variables ex. { id: 'someId' }
 */
export function formatPath(
  pathTemplate: string,
  variables: { [key: string]: string },
) {
  return parseTemplate(pathTemplate).expand(variables);
}
