import { DatasetApi, AddMetadataOperationRequest } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import { useMutation } from 'react-query';

export const useAddMetadataMutation = (datasetId: string) => {
  const { initializeAPI } = useAPIContext();

  return useMutation(
    ['addDatasetMetadata', datasetId],
    async (variables: AddMetadataOperationRequest) => {
      const api = await initializeAPI<DatasetApi>(DatasetApi);
      return api.addMetadata(variables);
    },
  );
};
