import React from 'react';
import Page404 from 'pages/Page404';
import AddAssets from 'pages/datasets/add-assets';
import { AddAssetsActionsContextWrapper } from 'pages/datasets/add-assets/context/AddAssetsActionsContext';
import { AddAssetsNavigationContextWrapper } from 'pages/datasets/add-assets/context/AddAssetsNavigationContext';
import CreateDataset from 'pages/datasets/create';
import DatasetDetail from 'pages/datasets/detail';
import Datasets from 'pages/datasets/list';
import { Route, Routes } from 'react-router-dom';
import AssetDetail from './detail/components/AssetDetail';
import AddDynamicTags from './add-dynamic-tags/AddDynamicTags';

const DatasetRouter = function DatasetRouter() {
  return (
    <Routes>
      <Route path="" element={<Datasets />} />
      <Route
        path="/create"
        element={
          <AddAssetsNavigationContextWrapper>
            <CreateDataset />
          </AddAssetsNavigationContextWrapper>
        }
      />
      <Route
        path="/:datasetId/add-assets/:datasetUpdateId?"
        element={
          <AddAssetsNavigationContextWrapper>
            <AddAssetsActionsContextWrapper>
              <AddAssets />
            </AddAssetsActionsContextWrapper>
          </AddAssetsNavigationContextWrapper>
        }
      />
      <Route path="/:datasetId/*" element={<DatasetDetail />} />
      <Route path="/:datasetId/add-dynamic-tags" element={<AddDynamicTags />} />
      <Route path="/:datasetId/images/:imageId" element={<AssetDetail />} />
      <Route path="/:datasetId/videos/:videoId" element={<AssetDetail />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default DatasetRouter;
