import { FunnelIcon } from '@heroicons/react/24/solid';
import { DatasetResponse } from 'api/generated';
import classNames from 'classnames';
import MultiSelectButton from 'components/MultiSelectButton';
import React, { useMemo } from 'react';
import { removeNulls } from 'utils/ListUtils';

interface DatasetFilterProps {
  datasets: (DatasetResponse | null)[] | undefined;
  selected: string[] | undefined;
  setSelected: ((datasetIds: string[]) => void) | undefined;
}

const DatasetFilter: React.FunctionComponent<DatasetFilterProps> = ({
  datasets,
  selected,
  setSelected,
}: DatasetFilterProps) => {
  const options = useMemo(() => {
    const filteredDataset = removeNulls(datasets);
    return (
      filteredDataset?.map((dataset) => ({
        key: dataset.datasetId,
        value: dataset.datasetId,
        label: dataset.name,
      })) || []
    );
  }, [datasets]);
  const value = !selected?.length ? options?.map((d) => d.key) : selected;
  const funnelIcon = useMemo(
    () => (
      <FunnelIcon
        className={classNames(
          'h-4 w-4',
          options?.length === value?.length ? 'text-gray-400' : 'text-blue-500',
        )}
        aria-hidden="true"
      />
    ),
    [],
  );
  return (
    <MultiSelectButton
      value={value || []}
      onChange={(v) => {
        if (v?.length) {
          setSelected?.(v.length === options?.length ? [] : v);
        }
      }}
      options={options}
      buttonLabel={funnelIcon}
      buttonClassName="ml-2 rounded-full"
      srOnlyLabel="Filter datasets"
      popoverPlacement="bottom-start"
    />
  );
};

export default DatasetFilter;
