import { QueryResponseUI } from 'api/generated';
import SearchHistoryRow from 'components/DatasetSearch/AdvancedSearch/SearchHistory/SearchHistoryRow';
import {
  useDeleteDatasetSearchQuery,
  useGetDatasetAdvancedSearchHistoryInfiniteQuery,
} from 'components/DatasetSearch/AdvancedSearch/queries';
import EmptyStateMessage from 'components/EmptyStateMessage';
import FadeTransition from 'components/FadeTransition';
import LoadingList from 'components/LoadingList';
import { ScrollToTop } from 'components/ScrollToTop';
import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';

interface SearchHistoryProps {
  datasetId: string;
  columnInfoLoading: boolean;
  onSelect: () => void;
}

const SearchHistory = function SearchHistory({
  datasetId,
  columnInfoLoading,
  onSelect,
}: SearchHistoryProps) {
  const navigate = useNavigate();
  const params = useParams();
  const {
    data,
    fetchNextPage,
    isLoading: isHistoryLoading,
  } = useGetDatasetAdvancedSearchHistoryInfiniteQuery(datasetId);
  const { mutate: deleteSearchQuery } = useDeleteDatasetSearchQuery(datasetId);
  const [rows, totalRows] = useMemo(
    () => [
      data?.pages.reduce(
        (acc: QueryResponseUI[], item) => [...acc, ...item.data],
        [],
      ) || [],
      data?.pages.length
        ? data.pages[data.pages.length - 1].meta?.page?.total || 0
        : 0,
    ],
    [data],
  );

  const isLoading = isHistoryLoading || columnInfoLoading;

  const hasMore = useMemo(() => rows.length < totalRows, [rows, totalRows]);
  return (
    <div className="max-h-[500px] overflow-auto">
      {!isLoading && Boolean(totalRows) && (
        <>
          <InfiniteScroll
            style={{ overflow: 'visible' }}
            dataLength={rows?.length ?? 0}
            next={fetchNextPage}
            loader={<span />} // TODO - created loader for infinite scroll grid
            hasMore={hasMore || false}
          >
            <ul>
              {rows.map((row) => (
                <li key={row.queryId}>
                  <SearchHistoryRow
                    queryId={row.queryId}
                    queryActive={row.isActive}
                    createdDt={row.createdDt}
                    onClick={() => {
                      onSelect();
                      navigate(
                        `/datasets/${row.dataset.id}/search/${row.queryId}/images`,
                      );
                    }}
                    status={row.status}
                    parameters={row.parameters}
                    activeRow={params.queryId}
                    deleteSearchQuery={deleteSearchQuery}
                  />
                </li>
              ))}
            </ul>
          </InfiniteScroll>
          <ScrollToTop />
        </>
      )}
      {!isLoading && !totalRows && (
        <div className="text-center py-12 px-2 text-sm">
          <EmptyStateMessage>
            <p>No search history</p>
          </EmptyStateMessage>
        </div>
      )}
      {isLoading && (
        <FadeTransition appear show={isLoading} exit={false} enterDelayMs={500}>
          <div className="p-4">
            <LoadingList />
          </div>
        </FadeTransition>
      )}
    </div>
  );
};

export default SearchHistory;
