import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  ClipboardDocumentIcon,
  Square2StackIcon,
  ShareIcon,
} from '@heroicons/react/24/outline';
import Clipboard from 'react-clipboard.js';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import { v4 as uuidv4 } from 'uuid';

const TOOLTIP_CLOSE_ON_COPY_DELAY = 1000;

interface ClipboardIconButtonProps {
  id: string;
  text: string;
  className?: string;
  color?: string;
  hoverColor?: string;
  padding?: string;
  margin?: string;
  tooltipText?: string;
  iconType?: 'clipboard' | 'duplicate' | 'share';
  size?: 'small' | 'medium' | 'large';
}

const ClipboardIcon: FunctionComponent<ClipboardIconButtonProps> =
  function ClipboardIcon({
    id,
    text,
    className,
    color,
    hoverColor,
    padding,
    margin,
    tooltipText,
    iconType,
    size,
  }) {
    const [showCopyText, setShowCopyText] = useState({
      id: uuidv4(),
      show: false,
    });
    const fadeCopyTooltipTimeoutRef = useRef<any>();
    const containerRef = useRef<any>();

    useEffect(() => {
      if (showCopyText.show) {
        fadeCopyTooltipTimeoutRef.current = setTimeout(() => {
          setShowCopyText({ id: uuidv4(), show: false });
        }, TOOLTIP_CLOSE_ON_COPY_DELAY);
      } else {
        clearTimeout(fadeCopyTooltipTimeoutRef.current);
      }
    }, [showCopyText.show]);

    return (
      <>
        <Clipboard
          onSuccess={() => setShowCopyText((ct) => ({ ...ct, show: true }))}
          onClick={(e) => e.stopPropagation()}
          data-clipboard-text={text}
          data-tooltip-id={`copy-${id}`}
          data-tooltip-content={showCopyText.show ? 'Copied!' : tooltipText}
          data-tooltip-place="top"
          className="rounded-full transition-all rounded-[.05rem] flex items-center "
        >
          <div
            ref={(ref) => {
              containerRef.current = ref;
            }}
            className={classNames(
              'relative flex items-center justify-center outline-offset-[.22rem] hover:bg-slate-50 rounded-md',
              {
                'h-5 w-5': size === 'small',
                'h-6 w-6': size === 'medium',
                'h-10 w-10': size === 'large',
              },
              className,
              padding,
              margin,
            )}
          >
            {iconType === 'clipboard' && (
              <ClipboardDocumentIcon
                className={classNames(
                  'justify-center',
                  size === 'small' ? 'h-5 w-5' : 'h-6 w-6',
                  color,
                  hoverColor,
                )}
              />
            )}
            {iconType === 'duplicate' && (
              <Square2StackIcon
                className={classNames(
                  'justify-center relative bottom-[1px]',
                  size === 'small' ? 'h-5 w-5' : 'h-6 w-6',
                  color,
                  hoverColor,
                )}
              />
            )}
            {iconType === 'share' && (
              <ShareIcon
                className={classNames(
                  'h-5 w-5 justify-center fill-current',
                  color,
                  hoverColor,
                )}
              />
            )}
          </div>
        </Clipboard>
        <Tooltip
          key={showCopyText.id}
          id={`copy-${id}`}
          isOpen={showCopyText.show ? true : undefined}
        >
          <span>{showCopyText.show ? 'Copied!' : tooltipText}</span>
        </Tooltip>
      </>
    );
  };

ClipboardIcon.defaultProps = {
  className: undefined,
  color: 'text-gray-500',
  hoverColor: 'hover:text-gray-600',
  padding: 'p-3',
  margin: '',
  tooltipText: 'Copy to Clipboard',
  iconType: 'clipboard',
  size: 'small',
};

export default ClipboardIcon;
