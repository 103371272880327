import { UserApi } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useQuery from 'hooks/useQuery';

export const useGetUserById = (userId?: string) => {
  const { initializeAPI } = useAPIContext();

  return useQuery(['getUserById', userId], async () => {
    if (!userId) {
      return null;
    }
    const api = await initializeAPI<UserApi>(UserApi);
    return api.getUserById({
      userId,
    });
  });
};
