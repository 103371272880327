import { AssetResponse, LabelEnum } from 'api/generated';
import RefineSidebarContext from 'context/RefineSidebarContext';
import SidebarContext from 'context/SidebarContext';
import { useGetCandidatesQuery } from 'pages/concepts/detail/tabs/Examples/ContinueLabeling/queries';
import { ConceptActionState, LabelCandidateStatus } from 'pages/concepts/types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

interface ContinueLabelingSingleCandidateProps {
  conceptVersionId: string;
  refreshLabels: () => void;
  setHasCandidatesLoadingIndicator?: (
    hasCandidatesLoadingIndicator: boolean,
  ) => void;
  setLabelCandidatesStatus?: (
    labelCandidatesStatusMessage: LabelCandidateStatus,
  ) => void;
  setActionState: (actionState: ConceptActionState) => void;
}

type LabeledCandidate = AssetResponse & { label: LabelEnum | undefined };

const ContinueLabelingSingleCandidate: React.FC<ContinueLabelingSingleCandidateProps> =
  function ConceptExamples({
    conceptVersionId,
    refreshLabels,
    setHasCandidatesLoadingIndicator,
    setLabelCandidatesStatus,
    setActionState,
  }) {
    const { setRightSidebarOpen } = useContext(SidebarContext);
    const [candidates, setCandidates] = useState<LabeledCandidate[]>([]);
    const {
      setImage: setSidebarImage,
      setDisabled: setSidebarDisabled,
      setOnLabelFun,
      setView: setRightSidebarView,
    } = useContext(RefineSidebarContext);

    const [activeImage, setActiveImage] = useState<any>(undefined);

    const labelRound = useRef(0);

    const {
      refetch: refetchCandidates,
      data,
      isFetching,
      isSuccess,
      isError: showError,
    } = useGetCandidatesQuery(conceptVersionId, 1);

    // Load new candidates on mount
    useEffect(() => {
      labelRound.current += 1;
      refetchCandidates();
      // Before unmount, update the images on the parent components so the view is updated if the user
      // clicks away.
      return () => {
        setOnLabelFun(undefined);
      };
    }, []);

    // Set all variable after the candidates have been fetched
    useEffect(() => {
      if (data?.data) {
        setCandidates([...data.data.map((c) => ({ ...c, label: undefined }))]);
        setActiveImage(candidates?.[0]);
        setRightSidebarOpen(true);
        setRightSidebarView('new-label');
        setSidebarDisabled(false);
        setHasCandidatesLoadingIndicator?.(false);
      }
    }, [data?.data]);

    useEffect(() => {
      setHasCandidatesLoadingIndicator?.(isFetching);
    }, [isFetching]);

    // This function gets called after a candidate is successfully labelled
    const onLabel = useCallback(
      async (coactiveImageId: string, label: LabelEnum | undefined) => {
        if (!coactiveImageId || !label) return;
        setCandidates((cs) =>
          cs.map((c) =>
            c.coactiveImageId === coactiveImageId ? { ...c, label } : c,
          ),
        );
      },
      [],
    );

    // When a candidate is labelled, select the next image to be labelled or finish the labelling process
    // if all candidates have been labelled
    useEffect(() => {
      const nextUnlabelled = candidates.find((candidate) => !candidate.label);

      if (nextUnlabelled) {
        setActiveImage(nextUnlabelled);
        return;
      }

      setSidebarDisabled(true);
      refreshLabels();
      refetchCandidates();
    }, [candidates]);

    // Update the image in the sidebar
    useEffect(() => {
      setSidebarImage(activeImage);
    }, [activeImage]);

    // Update the label function in the refine sidebar
    useEffect(() => {
      setOnLabelFun(() => onLabel as any);
    }, [onLabel]);

    const hasFinishedLabeling = Boolean(isSuccess && data?.data.length === 0);
    const hasMoreCandidatesToLabel = Boolean(
      isSuccess && data?.data.length > 0,
    );

    const shouldUpdateGetCandidateStatus =
      !isFetching && (showError || hasFinishedLabeling);

    useEffect(() => {
      if (shouldUpdateGetCandidateStatus) {
        setLabelCandidatesStatus?.(showError ? 'error' : 'empty');
        setRightSidebarOpen(false);
        setActionState(ConceptActionState.View);
      }
    }, [shouldUpdateGetCandidateStatus]);

    useEffect(() => {
      if (hasMoreCandidatesToLabel) {
        setLabelCandidatesStatus?.('none');
      }
    }, [data]);

    return <div />;
  };

ContinueLabelingSingleCandidate.defaultProps = {
  setLabelCandidatesStatus: undefined,
  setHasCandidatesLoadingIndicator: undefined,
};

export default ContinueLabelingSingleCandidate;
