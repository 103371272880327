/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicDescription,
    BasicDescriptionFromJSON,
    BasicDescriptionFromJSONTyped,
    BasicDescriptionToJSON,
} from './BasicDescription';

/**
 * The base model for any single resource HTTP response
 * @export
 * @interface FullVersionResponse
 */
export interface FullVersionResponse {
    /**
     * The user that created the resource
     * @type {string}
     * @memberof FullVersionResponse
     */
    createdUserId: string;
    /**
     * The created datetime of the resource
     * @type {Date}
     * @memberof FullVersionResponse
     */
    createdDt: string;
    /**
     * The user that last updated the resource
     * @type {string}
     * @memberof FullVersionResponse
     */
    updatedUserId: string;
    /**
     * The datetime the resource was last updated
     * @type {Date}
     * @memberof FullVersionResponse
     */
    updatedDt: string;
    /**
     * The id of the parent concept for the version
     * @type {string}
     * @memberof FullVersionResponse
     */
    conceptId: string;
    /**
     * Name of the version
     * @type {string}
     * @memberof FullVersionResponse
     */
    name: string;
    /**
     * Optional description of the version
     * @type {string}
     * @memberof FullVersionResponse
     */
    description?: string | null;
    /**
     * The id of the version
     * @type {string}
     * @memberof FullVersionResponse
     */
    versionId: string;
    /**
     * 
     * @type {BasicDescription}
     * @memberof FullVersionResponse
     */
    concept: BasicDescription;
    /**
     * 
     * @type {BasicDescription}
     * @memberof FullVersionResponse
     */
    namespace: BasicDescription;
    /**
     * 
     * @type {BasicDescription}
     * @memberof FullVersionResponse
     */
    embedding: BasicDescription;
    /**
     * 
     * @type {BasicDescription}
     * @memberof FullVersionResponse
     */
    dataset: BasicDescription;
    /**
     * Version task status
     * @type {string}
     * @memberof FullVersionResponse
     */
    jobStatus: string;
    /**
     * Indicates if the version is in a queryable state
     * @type {boolean}
     * @memberof FullVersionResponse
     */
    isQueryable: boolean;
    /**
     * Number of positive labels for the concept
     * @type {number}
     * @memberof FullVersionResponse
     */
    positiveLabelsCount: number;
    /**
     * Number of negative labels for the concept
     * @type {number}
     * @memberof FullVersionResponse
     */
    negativeLabelsCount: number;
}

export function FullVersionResponseFromJSON(json: any): FullVersionResponse {
    return FullVersionResponseFromJSONTyped(json, false);
}

export function FullVersionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullVersionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdUserId': json['createdUserId'],
        'createdDt': json['createdDt'],
        'updatedUserId': json['updatedUserId'],
        'updatedDt': json['updatedDt'],
        'conceptId': json['conceptId'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'versionId': json['versionId'],
        'concept': BasicDescriptionFromJSON(json['concept']),
        'namespace': BasicDescriptionFromJSON(json['namespace']),
        'embedding': BasicDescriptionFromJSON(json['embedding']),
        'dataset': BasicDescriptionFromJSON(json['dataset']),
        'jobStatus': json['jobStatus'],
        'isQueryable': json['isQueryable'],
        'positiveLabelsCount': json['positiveLabelsCount'],
        'negativeLabelsCount': json['negativeLabelsCount'],
    };
}

export function FullVersionResponseToJSON(value?: FullVersionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdUserId': value.createdUserId,
        'createdDt': value.createdDt,
        'updatedUserId': value.updatedUserId,
        'updatedDt': value.updatedDt,
        'conceptId': value.conceptId,
        'name': value.name,
        'description': value.description,
        'versionId': value.versionId,
        'concept': BasicDescriptionToJSON(value.concept),
        'namespace': BasicDescriptionToJSON(value.namespace),
        'embedding': BasicDescriptionToJSON(value.embedding),
        'dataset': BasicDescriptionToJSON(value.dataset),
        'jobStatus': value.jobStatus,
        'isQueryable': value.isQueryable,
        'positiveLabelsCount': value.positiveLabelsCount,
        'negativeLabelsCount': value.negativeLabelsCount,
    };
}

