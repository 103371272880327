import React, {
  useContext,
  createContext,
  useMemo,
  useState,
  PropsWithChildren,
} from 'react';
import { LabelEnum } from 'api/generated';
import { LabeledAssetResponse } from 'types/image';

export type RefineSidebarView = 'new-label' | 'update-label';

interface RefineSidebarContextState {
  disabled: boolean;
  setDisabled: (disabled: boolean) => void;
  view: RefineSidebarView;
  setView: (view: RefineSidebarView) => void;
  image: LabeledAssetResponse | undefined;
  setImage: (image: LabeledAssetResponse) => void;
  onLabelFun: (
    coactiveImageId: string,
    label: LabelEnum | undefined,
  ) => Promise<any>;
  setOnLabelFun: (
    _:
      | ((coactiveImageId: string, label: LabelEnum) => Promise<any>)
      | undefined,
  ) => void;
}

const RefineSidebarContext = createContext({
  disabled: false,
  setDisabled: () => undefined,
  view: 'new-label',
  setView: () => undefined,
  image: undefined,
  setImage: () => undefined,
  onLabelFun: async () => undefined,
  setOnLabelFun: () => undefined,
  removeExample: () => undefined,
} as RefineSidebarContextState);

export const RefineSidebarContextWrapper: React.FC<PropsWithChildren> =
  function RefineSidebarContextWrapper({ children }) {
    const [sidebarDisabled, setSidebarDisabled] = useState<boolean>(false);
    const [sidebarImage, setSidebarImage] = useState<LabeledAssetResponse>();
    const [sidebarView, setSidebarView] =
      useState<RefineSidebarView>('new-label');
    const [onLabelFun, setOnLabelFun] = useState<
      undefined | ((coactiveImageId: string, label: LabelEnum) => Promise<any>)
    >(undefined);
    const value = useMemo(
      () => ({
        image: sidebarImage,
        setImage: setSidebarImage,
        view: sidebarView,
        setView: setSidebarView,
        disabled: sidebarDisabled,
        setDisabled: setSidebarDisabled,
        onLabelFun,
        setOnLabelFun: (fun) => setOnLabelFun(fun),
      }),
      [sidebarImage, sidebarDisabled, onLabelFun],
    );
    return (
      <RefineSidebarContext.Provider value={value as any}>
        {children}
      </RefineSidebarContext.Provider>
    );
  };

export function useRefineSidebarContext() {
  return useContext(RefineSidebarContext);
}

export default RefineSidebarContext;
