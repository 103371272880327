import { useAuth0 } from '@auth0/auth0-react';
import { ImageSizeEnum } from 'api/generated';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import coactivePlaceholder from 'assets/logos/image-placeholder.svg';
import blackPlaceholder from 'assets/icons/black-square.svg';
import Image from 'components/Image/index';

type ImagePlaceholderType = 'coactive' | 'black';

const ImagePlaceholder: React.FC<{
  className: string | undefined;
  rounded: boolean | undefined;
  shadow: string | undefined;
}> = function ImagePlaceholder({ className, rounded, shadow }) {
  return (
    <div
      className={classNames(
        'bg-gray-300 animate-pulse',
        rounded ? 'rounded-md' : undefined,
        shadow,
        className,
      )}
    />
  );
};

const ProtectedImage: React.FC<{
  url: string;
  alt?: string;
  rounded?: boolean;
  shadow?: string;
  className?: string;
  sensitive?: boolean | null;
  size?: ImageSizeEnum;
  coactiveImageId?: string;
  placeholder?: ImagePlaceholderType;
  objectFit?: string;
  // Use this prop if the if the src prop is expected to change. If this flag is set to `true`,
  // the height of the previous image will be maintained while the new image loads. This way,
  // the view won't jump while the new image is loading. This should only be used if the image height
  // is not fixed (if it changes depending on the src in its container).
  preventHeightJump?: boolean;
}> = function ProtectedImage({
  url,
  sensitive,
  alt,
  rounded,
  shadow,
  className,
  size,
  coactiveImageId,
  placeholder,
  objectFit,
  preventHeightJump,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [imageUrl, setImageUrl] = useState<{
    url: string | undefined;
    isPlaceholder: boolean;
  }>({
    url: undefined,
    isPlaceholder: false,
  });

  useEffect(() => {
    if (placeholder === 'black') {
      setImageUrl({ url: blackPlaceholder, isPlaceholder: true });
    } else {
      setImageUrl({ url: coactivePlaceholder, isPlaceholder: true });
    }
    let cancel = false;
    const fetcher = async () => {
      const token = await getAccessTokenSilently();
      const res = await fetch(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.ok) {
        const blob = await res.blob();
        const objectUrl = URL.createObjectURL(blob);
        if (!cancel) {
          setImageUrl({ url: objectUrl, isPlaceholder: false });
        }
      }
    };
    fetcher();

    return () => {
      cancel = true;
    };
  }, [url]);

  if (!imageUrl.url) {
    return (
      <ImagePlaceholder
        className={className}
        rounded={rounded}
        shadow={shadow}
      />
    );
  }

  return (
    <Image
      src={imageUrl.url}
      isPlaceholder={imageUrl.isPlaceholder}
      alt={alt ?? url}
      sensitive={sensitive}
      rounded={rounded}
      shadow={shadow}
      className={className}
      size={size}
      coactiveImageId={coactiveImageId}
      objectFit={objectFit}
      preventHeightJump={preventHeightJump}
    />
  );
};

ProtectedImage.defaultProps = {
  alt: undefined,
  rounded: false,
  shadow: undefined,
  className: undefined,
  sensitive: false,
  size: undefined,
  coactiveImageId: undefined,
  placeholder: 'coactive',
  objectFit: 'object-cover',
  preventHeightJump: false,
};

export default ProtectedImage;
