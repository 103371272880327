import { DatasetApi } from 'api/generated';
import { VideoApi } from 'api/generated/apis/VideoApi';
import { useAPIContext } from 'context/APIContext';
import useInfiniteQuery from 'hooks/useInfiniteQuery';
import useQuery from 'hooks/useQuery';
import toNumber from 'utils/ToNumber';

const PAGE_SIZE = 50;

export const useGetImagesForDatasetApiInfiniteQueryKey = (
  conceptVersionId: string,
) => ['getDatasetImages', conceptVersionId];

export const useGetVideosForDatasetApiInfiniteQueryKey = (
  conceptVersionId: string,
) => ['getDatasetVideos', conceptVersionId];

export const useGetImagesForDatasetApiInfiniteQuery = (datasetId: string) => {
  const { initializeAPI } = useAPIContext();
  const query = useInfiniteQuery(
    useGetImagesForDatasetApiInfiniteQueryKey(datasetId),
    async ({ queryKey, signal, pageParam }) => {
      const [, _datasetId] = queryKey;
      if (typeof _datasetId === 'undefined') {
        throw new Error('datasetId not set');
      }
      const api = await initializeAPI<DatasetApi>(DatasetApi);
      return api.getDatasetImages(
        {
          datasetId: _datasetId.toString(),
          offset: PAGE_SIZE * toNumber(pageParam || 0),
          limit: PAGE_SIZE,
        },
        { signal },
      );
    },
    {
      enabled: true,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.meta?.page?.currentPage,
    },
  );
  return query;
};

export const useGetVideosForDatasetApiInfiniteQuery = (datasetId: string) => {
  const { initializeAPI } = useAPIContext();
  const query = useInfiniteQuery(
    useGetVideosForDatasetApiInfiniteQueryKey(datasetId),
    async ({ queryKey, signal, pageParam }) => {
      const [, _datasetId] = queryKey;
      if (typeof _datasetId === 'undefined') {
        throw new Error('datasetId not set');
      }
      const api = await initializeAPI<DatasetApi>(DatasetApi);
      return api.getDatasetVideos(
        {
          datasetId: _datasetId.toString(),
          offset: PAGE_SIZE * toNumber(pageParam || 0),
          limit: PAGE_SIZE,
        },
        { signal },
      );
    },
    {
      enabled: true,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.meta?.page?.currentPage,
    },
  );
  return query;
};

export const getMetadataForVideo = (videoId: string) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    'getMetadataForVideo',
    async ({ signal }) => {
      const api = await initializeAPI<VideoApi>(VideoApi);
      return api.getMetadataForVideo({ videoId }, { signal });
    },
    { cacheTime: 0 },
  );
};
