import { AssetResponse } from 'api/generated';
import InteractiveLabelGrid from 'components/InteractiveLabelGrid';
import { useLoadMoreSimilarImages } from 'pages/concepts/detail/tabs/Examples/AddExamples/FindSimilarImages/SimilarImages/queries';
import React from 'react';

const NUM_SIMILAR_IMAGES = 100;

interface SimilarImagesProps {
  conceptVersionId: string;
  imagePaths: string[];
  images: AssetResponse[];
  addLabels: (positive: string[], negative: string[]) => void;
  isAddingLabels: boolean;
  cancel: () => void;
  error?: string;
  numRequiredPositiveLabels?: number;
}

const SimilarImages: React.FunctionComponent<SimilarImagesProps> =
  function SimilarImages({
    conceptVersionId,
    imagePaths,
    images,
    addLabels,
    isAddingLabels,
    cancel,
    error,
    numRequiredPositiveLabels: numRequiredPositiveLabelsProp,
  }) {
    const numRequiredPositiveLabels = numRequiredPositiveLabelsProp ?? 0;
    const { data, fetchNextPage, isFetchingNextPage } =
      useLoadMoreSimilarImages(conceptVersionId, imagePaths, {
        data: images,
        meta: {
          page: {
            currentPage: 1,
            limit: images.length,
            total: NUM_SIMILAR_IMAGES,
            lastPage: Number(NUM_SIMILAR_IMAGES / (images.length || 1)),
            offset: 0,
          },
          links: {},
          sort: [],
        },
      });

    return (
      <InteractiveLabelGrid
        images={data}
        addLabels={addLabels}
        isAddingLabels={isAddingLabels}
        cancel={cancel}
        error={error}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        numRequiredPositiveLabels={numRequiredPositiveLabels}
      />
    );
  };

SimilarImages.defaultProps = {
  error: undefined,
  numRequiredPositiveLabels: 0,
};

export default SimilarImages;
