/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPValidationError,
  MeResponse,
  TrialExtensionResponse,
  UserResponse,
} from '../models';
import {
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    MeResponseFromJSON,
    MeResponseToJSON,
    TrialExtensionResponseFromJSON,
    TrialExtensionResponseToJSON,
    UserResponseFromJSON,
    UserResponseToJSON,
} from '../models';

export interface GetUserByIdRequest {
    userId: string;
}

/**
 * UserApi - interface
 * 
 * @export
 * @interface UserApiInterface
 */
export interface UserApiInterface {
    /**
     * Get details about the current user
     * @summary Get details about the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getCurrentUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeResponse>>;

    /**
     * Get details about the current user
     * Get details about the current user
     */
    getCurrentUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeResponse>;

    /**
     * Get details about user based on id
     * @summary Get details about user based on id
     * @param {string} userId Id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserByIdRaw(requestParameters: GetUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserResponse>>;

    /**
     * Get details about user based on id
     * Get details about user based on id
     */
    getUserById(requestParameters: GetUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserResponse>;

    /**
     * Updates current auth0 user\'s app metadata \'requested_trial_extension\' field to        true and sends sendgrid automated email
     * @summary Updates current auth0 user\'s app metadata \'requested_trial_extension        field to true and sends sendgrid automated email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    sendTrialExtensionRequestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrialExtensionResponse>>;

    /**
     * Updates current auth0 user\'s app metadata \'requested_trial_extension\' field to        true and sends sendgrid automated email
     * Updates current auth0 user\'s app metadata \'requested_trial_extension        field to true and sends sendgrid automated email
     */
    sendTrialExtensionRequest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrialExtensionResponse>;

}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI implements UserApiInterface {

    /**
     * Get details about the current user
     * Get details about the current user
     */
    async getCurrentUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/user/me`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeResponseFromJSON(jsonValue));
    }

    /**
     * Get details about the current user
     * Get details about the current user
     */
    async getCurrentUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeResponse> {
        const response = await this.getCurrentUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get details about user based on id
     * Get details about user based on id
     */
    async getUserByIdRaw(requestParameters: GetUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/user/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
    }

    /**
     * Get details about user based on id
     * Get details about user based on id
     */
    async getUserById(requestParameters: GetUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserResponse> {
        const response = await this.getUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates current auth0 user\'s app metadata \'requested_trial_extension\' field to        true and sends sendgrid automated email
     * Updates current auth0 user\'s app metadata \'requested_trial_extension        field to true and sends sendgrid automated email
     */
    async sendTrialExtensionRequestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrialExtensionResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/user/request-trial-extension`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrialExtensionResponseFromJSON(jsonValue));
    }

    /**
     * Updates current auth0 user\'s app metadata \'requested_trial_extension\' field to        true and sends sendgrid automated email
     * Updates current auth0 user\'s app metadata \'requested_trial_extension        field to true and sends sendgrid automated email
     */
    async sendTrialExtensionRequest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrialExtensionResponse> {
        const response = await this.sendTrialExtensionRequestRaw(initOverrides);
        return await response.value();
    }

}
