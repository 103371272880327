import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

const ErrorText: React.FC<
  PropsWithChildren<{
    id?: string;
    errorStyle?: 'form' | 'submit';
    margin?: string;
  }>
> = function ErrorText({ children, id, errorStyle, margin }) {
  return (
    <p
      className={classNames(
        Boolean(children) && errorStyle === 'submit' && !margin && 'mt-4',
        margin,
        'text-sm text-red-600',
      )}
      aria-live="assertive"
      id={id}
    >
      {children}
    </p>
  );
};

ErrorText.defaultProps = {
  id: undefined,
  errorStyle: 'form',
  margin: 'mt-2',
};

export default ErrorText;
