import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import ActionsMenu from 'components/ActionsMenu';
import FadeTransition from 'components/FadeTransition';
import LoadingBlock from 'components/LoadingBlock';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Action } from 'types/action';

export interface PageHeaderProps {
  title?: ReactElement<any, any> | string;
  backButtonTo?: string;
  breadcrumbs?: {
    label: string;
    to: string;
  }[];
  actions?: ReactElement<any, any>;
  options?: Action[];
  loading?: boolean;
}

const PageHeader: React.FunctionComponent<PageHeaderProps> =
  function PageHeader({
    actions,
    backButtonTo,
    breadcrumbs,
    title,
    options,
    loading,
  }: PageHeaderProps) {
    const breadcrumbsPadding = 'py-4';
    return (
      <div>
        <div>
          {backButtonTo && !breadcrumbs && (
            <nav className="hidden sm:block" aria-label="Back">
              <Link
                to={backButtonTo}
                className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 pt-4"
              >
                <ChevronLeftIcon
                  className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Back
              </Link>
            </nav>
          )}
          <FadeTransition appear show={!loading}>
            {breadcrumbs?.length && (
              <nav className="hidden sm:flex" aria-label="Breadcrumb">
                <ol
                  className={classNames(
                    'flex items-center',
                    breadcrumbsPadding,
                  )}
                >
                  {breadcrumbs?.map((breadcrumb, idx) => (
                    <li key={breadcrumb.label} className="m-0">
                      <div className="flex items-center">
                        {idx !== 0 && (
                          <ChevronRightIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-400 mx-2"
                            aria-hidden="true"
                          />
                        )}
                        {breadcrumb.to ? (
                          <Link
                            to={breadcrumb.to}
                            className="text-sm font-medium text-gray-500 hover:text-gray-700"
                          >
                            {breadcrumb.label}
                          </Link>
                        ) : (
                          <span className="text-sm font-medium text-gray-500">
                            {breadcrumb.label}
                          </span>
                        )}
                      </div>
                    </li>
                  ))}
                </ol>
              </nav>
            )}
          </FadeTransition>
          <FadeTransition
            appear
            show={!!loading}
            exit={false}
            enterDelayMs={500}
          >
            {!!loading && (
              <div className={breadcrumbsPadding}>
                <LoadingBlock className="h-4 w-64 max-w-full" />
              </div>
            )}
          </FadeTransition>
        </div>
        <div
          className={classNames(
            'flex items-center justify-between',
            !backButtonTo && !breadcrumbs?.length ? 'mt-8' : 'mt-0',
          )}
        >
          <div className="flex-1 min-w-0">
            <div className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl overflow-visible">
              <FadeTransition appear show={!loading}>
                {title}
              </FadeTransition>
              <FadeTransition
                appear
                show={!!loading}
                exit={false}
                enterDelayMs={500}
              >
                {!!loading && <LoadingBlock className="h-6 w-96 max-w-full" />}
              </FadeTransition>
            </div>
          </div>
          {actions && (
            <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
              {actions}
            </div>
          )}
          {options && <ActionsMenu actions={options} buttonStyle="shadow" />}
        </div>
      </div>
    );
  };

PageHeader.defaultProps = {
  breadcrumbs: undefined,
  actions: undefined,
  options: undefined,
  backButtonTo: undefined,
  title: undefined,
  loading: false,
};

export default PageHeader;
