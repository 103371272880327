/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Concept
 */
export interface Concept {
    /**
     * Concept id
     * @type {string}
     * @memberof Concept
     */
    conceptId: string;
    /**
     * Concept name
     * @type {string}
     * @memberof Concept
     */
    name: string;
    /**
     * Positive classification threshold
     * @type {number}
     * @memberof Concept
     */
    threshold: number;
}

export function ConceptFromJSON(json: any): Concept {
    return ConceptFromJSONTyped(json, false);
}

export function ConceptFromJSONTyped(json: any, ignoreDiscriminator: boolean): Concept {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conceptId': json['conceptId'],
        'name': json['name'],
        'threshold': json['threshold'],
    };
}

export function ConceptToJSON(value?: Concept | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conceptId': value.conceptId,
        'name': value.name,
        'threshold': value.threshold,
    };
}

