/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Comparator,
    ComparatorFromJSON,
    ComparatorFromJSONTyped,
    ComparatorToJSON,
} from './Comparator';
import {
    MetadataType,
    MetadataTypeFromJSON,
    MetadataTypeFromJSONTyped,
    MetadataTypeToJSON,
} from './MetadataType';

/**
 * 
 * @export
 * @interface MetadataParameter
 */
export interface MetadataParameter {
    /**
     * 
     * @type {Comparator}
     * @memberof MetadataParameter
     */
    comparator?: Comparator;
    /**
     * The metadata key to compare
     * @type {string}
     * @memberof MetadataParameter
     */
    key: string;
    /**
     * 
     * @type {MetadataType}
     * @memberof MetadataParameter
     */
    type: MetadataType;
    /**
     * The value to compare
     * @type {string}
     * @memberof MetadataParameter
     */
    value?: string | null;
}

export function MetadataParameterFromJSON(json: any): MetadataParameter {
    return MetadataParameterFromJSONTyped(json, false);
}

export function MetadataParameterFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetadataParameter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comparator': !exists(json, 'comparator') ? undefined : ComparatorFromJSON(json['comparator']),
        'key': json['key'],
        'type': MetadataTypeFromJSON(json['type']),
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function MetadataParameterToJSON(value?: MetadataParameter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comparator': ComparatorToJSON(value.comparator),
        'key': value.key,
        'type': MetadataTypeToJSON(value.type),
        'value': value.value,
    };
}

