import DeleteModal from 'components/DeleteModal';
import React from 'react';
import { DynamicTagUI } from 'api/generated';

interface DeleteDynamicTagModalProps {
  shouldDisplay: boolean;
  onClose: (isOpen: boolean) => void;
  onConfirm: () => void;
  dynamicTagToDelete: DynamicTagUI;
  dataTestId?: string;
}

const DeleteDynamicTagModal: React.FC<DeleteDynamicTagModalProps> = ({
  shouldDisplay,
  dynamicTagToDelete,
  onClose,
  onConfirm,
  dataTestId,
}) => {
  if (!shouldDisplay) return null;

  return (
    <DeleteModal
      dataTestId={dataTestId}
      title={`Are you sure you want to delete ${dynamicTagToDelete.name} from ${dynamicTagToDelete.categoryName}?`}
      isOpen={shouldDisplay}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};

DeleteDynamicTagModal.defaultProps = {
  dataTestId: undefined,
};

export default DeleteDynamicTagModal;
