import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { DataSourceTypeEnum, DatasetUpdateResponse } from 'api/generated';
import Button from 'components/Button';
import RadioTabsGroup, {
  RadioTabsGroupOption,
} from 'components/RadioTabsGroup';
import { useAddAssetsActionsContext } from 'pages/datasets/add-assets/context/AddAssetsActionsContext';
import { useAddAssetsNavigationContext } from 'pages/datasets/add-assets/context/AddAssetsNavigationContext';
import { DataSource, PersonToConfigure } from 'pages/datasets/add-assets/types';
import CsvZipUpload from 'pages/datasets/add-assets/views/DataSourceView/CsvZipUpload';
import GsDataSourceInput from 'pages/datasets/add-assets/views/DataSourceView/GsDataSourceInput';
import OtherDataSourceInput from 'pages/datasets/add-assets/views/DataSourceView/OtherDataSourceInput';
import S3DataSourceInput from 'pages/datasets/add-assets/views/DataSourceView/S3DataSourceInput';
import TeammateCanAdd from 'pages/datasets/components/TeammateCanAdd';
import AddAssetsViewContainer from 'pages/datasets/components/AddAssetsViewContainer';
import TextDivider from 'pages/datasets/components/TextDivider';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetDatasetUpdate } from 'queries/dataset-update';
import { useParams } from 'react-router-dom';
import {
  getPathForDataSource,
  isDataSourceProvided,
} from 'pages/datasets/add-assets/utils';

function getInitialSelectedOption(
  dataSourceType: DataSourceTypeEnum | undefined,
  unsupportedDataSource: string | null | undefined,
  teammateEmail: string | null | undefined,
): DataSource | undefined {
  if (dataSourceType) {
    return dataSourceType;
  }
  if (unsupportedDataSource) {
    return 'other';
  }
  if (teammateEmail) {
    return undefined;
  }
  return 's3';
}

/**
 * The main view of the "Data source" step in the create dataset flow.
 */
const DataSourceViewInner = function DataSourceViewInner({
  datasetUpdate,
}: {
  datasetUpdate: DatasetUpdateResponse | undefined;
}) {
  const {
    saveCurrentUsersProgress,
    inviteTeammateToContinue,
    provideOtherDataSource,
    error,
    accessError,
  } = useAddAssetsActionsContext();
  const { setActiveStep, setNext } = useAddAssetsNavigationContext();
  const [s3Prefix, setS3Prefix] = useState<string | null | undefined>(
    datasetUpdate?.dataSourceType === 's3' ? datasetUpdate.dataPath : undefined,
  );
  const [gsPrefix, setGsPrefix] = useState<string | null | undefined>(
    datasetUpdate?.dataSourceType === 'gs' ? datasetUpdate.dataPath : undefined,
  );
  const [csvPath, setCsvPath] = useState<string | null | undefined>(
    datasetUpdate?.dataSourceType === 'csv'
      ? datasetUpdate.dataPath
      : undefined,
  );
  // TODO: Zip files
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [zipPath, setZipPath] = useState<string | null | undefined>(
    datasetUpdate?.dataSourceType === 'zip'
      ? datasetUpdate.dataPath
      : undefined,
  );
  const [otherDataSource, setOtherDataSource] = useState<
    string | null | undefined
  >(datasetUpdate?.unsupportedDataSource);
  const [teammateEmail, setTeammateEmail] = useState<string | null | undefined>(
    datasetUpdate?.invitedUserEmail,
  );
  const [teammateEmailValidationError, setTeammateEmailValidationError] =
    useState<string>();

  const dataSourceOptions: RadioTabsGroupOption<DataSource>[] = useMemo(
    () => [
      { name: 'S3 path prefix', value: 's3' },
      { name: 'Google Storage path prefix', value: 'gs' },
      { name: 'CSV file', value: 'csv' },
      { name: 'Other', value: 'other' },
    ],
    [],
  );
  const [dataSourceOption, setDataSourceOption] = useState<
    RadioTabsGroupOption<DataSource> | undefined | null
  >(
    dataSourceOptions.find(
      (o) =>
        o.value ===
        getInitialSelectedOption(
          datasetUpdate?.dataSourceType,
          datasetUpdate?.unsupportedDataSource,
          teammateEmail,
        ),
    ),
  );

  const [personToSelectDataSource, setPersonToSelectDataSource] =
    useState<PersonToConfigure>(
      !dataSourceOption && datasetUpdate?.invitedUserEmail
        ? 'someone else'
        : 'me',
    );

  useEffect(() => {
    if (dataSourceOption) {
      setPersonToSelectDataSource('me');
    }
  }, [dataSourceOption]);

  useEffect(() => {
    if (personToSelectDataSource === 'someone else') {
      setDataSourceOption(null);
    }
  }, [personToSelectDataSource]);

  useEffect(() => {
    const action = () => {
      // If no user action exists, create one to save the user's progress
      const dataPath = getPathForDataSource(
        dataSourceOption?.value,
        s3Prefix,
        gsPrefix,
        csvPath,
        zipPath,
      );

      const dataSourceType = dataSourceOption?.value;
      if (personToSelectDataSource === 'someone else') {
        if (teammateEmail) {
          inviteTeammateToContinue(teammateEmail);
        }
      } else if (dataSourceType !== 'other') {
        if (
          datasetUpdate?.dataSourceType === dataSourceType &&
          datasetUpdate?.dataPath === dataPath
        ) {
          setActiveStep('Access');
        } else {
          saveCurrentUsersProgress({ dataSourceType, dataPath });
        }
      } else if (otherDataSource) {
        provideOtherDataSource(otherDataSource);
      }
    };

    const disabled = !isDataSourceProvided(
      csvPath,
      dataSourceOption?.value,
      gsPrefix,
      otherDataSource,
      personToSelectDataSource,
      s3Prefix,
      teammateEmail,
      !teammateEmailValidationError,
      zipPath,
    );

    setNext({
      disabled,
      action,
      label: 'Next',
      rightIcon: ArrowRightIcon,
      loadingMessage:
        dataSourceOption?.value === 's3' || dataSourceOption?.value === 'gs'
          ? 'Verifying bucket access...'
          : 'Saving progress...',
    });
  }, [
    personToSelectDataSource,
    dataSourceOption?.value,
    s3Prefix,
    gsPrefix,
    csvPath,
    zipPath,
    teammateEmail,
    teammateEmailValidationError,
    datasetUpdate,
    otherDataSource,
    accessError,
    saveCurrentUsersProgress,
    inviteTeammateToContinue,
    provideOtherDataSource,
  ]);

  return (
    <AddAssetsViewContainer
      title="How do you want to add your data?"
      description="Select how you'd like to upload your data, or ask a colleague to
    upload it for you."
      isCreate
      error={error ?? accessError}
    >
      <div className="center my-8">
        <RadioTabsGroup
          options={dataSourceOptions}
          selected={dataSourceOption}
          setSelected={setDataSourceOption}
          className="justify-center"
        />
      </div>
      {dataSourceOption && (
        <div className="my-4">
          {dataSourceOption?.value === 's3' && (
            <S3DataSourceInput
              setPrefix={setS3Prefix}
              defaultValue={s3Prefix}
            />
          )}
          {dataSourceOption?.value === 'gs' && (
            <GsDataSourceInput
              setPrefix={setGsPrefix}
              defaultValue={gsPrefix}
            />
          )}
          {(dataSourceOption?.value === 'csv' ||
            dataSourceOption?.value === 'zip') && (
            <CsvZipUpload setCsvPath={setCsvPath} />
          )}
          {dataSourceOption?.value === 'other' && (
            <OtherDataSourceInput
              setOtherDataSource={setOtherDataSource}
              defaultValue={otherDataSource}
            />
          )}
        </div>
      )}
      <TextDivider margin="my-8" text="OR" />
      <Button
        onClick={() => setPersonToSelectDataSource('someone else')}
        buttonStyle={
          personToSelectDataSource === 'someone else' && !dataSourceOption
            ? 'soft'
            : 'secondary'
        }
        padding="py-3 px-4"
        className="w-full"
      >
        My teammate can add
      </Button>
      {personToSelectDataSource === 'someone else' && (
        <TeammateCanAdd
          setTeammateEmail={setTeammateEmail}
          emailValidationError={teammateEmailValidationError}
          setEmailValidationError={setTeammateEmailValidationError}
        />
      )}
    </AddAssetsViewContainer>
  );
};

const DataSourceView: React.FC = function DataSourceView() {
  const { datasetUpdateId } = useParams();
  const { data: datasetUpdate, isFetching } =
    useGetDatasetUpdate(datasetUpdateId);

  if (!isFetching) {
    return <DataSourceViewInner datasetUpdate={datasetUpdate} />;
  }
  return null;
};

export default DataSourceView;
