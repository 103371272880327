import { ImageSizeEnum, VisualPrompt } from 'api/generated';
import classNames from 'classnames';
import ProtectedImage from 'components/Image/ProtectedImage';
import React from 'react';

interface ImageSetPreviewProps {
  maxImagesToShow: number;
  // TODO make type more generic
  images: VisualPrompt[];
  className?: string;
}

export const ImageSetPreview: React.FunctionComponent<ImageSetPreviewProps> =
  function ImageSetPreview({ maxImagesToShow, images, className }) {
    const imagesToDisplay =
      images.length <= maxImagesToShow
        ? images
        : images.slice(0, maxImagesToShow);

    const imageElements = imagesToDisplay.map((img) => (
      <ProtectedImage
        url={img.previewImages.thumb.url}
        coactiveImageId={img.coactiveImageId}
        size={ImageSizeEnum.Thumb}
        className="h-full m-0.25"
      />
    ));

    const excessImageCount = images.length - imagesToDisplay.length;

    return (
      <div className={classNames('inline-flex flex-row', className)}>
        {imageElements}
        {excessImageCount > 0 && (
          <div className="flex items-center px-1 font-medium text-gray-600">
            +{excessImageCount}
          </div>
        )}
      </div>
    );
  };

ImageSetPreview.defaultProps = { className: undefined };
