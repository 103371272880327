import { Menu, Transition } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { Permissions } from 'api/generated';
import { useUserContext } from 'context/UserContext';
import useLogout from 'hooks/useLogout';
import React, { Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

interface ProfileMenuProps {
  imgUrl?: string;
  lastName?: string;
  firstName?: string;
}

const ProfileMenu: React.FC<ProfileMenuProps> = function ProfileMenu({
  imgUrl,
  lastName,
  firstName,
}) {
  const { me, hasPermissions } = useUserContext();
  const logout = useLogout(me);
  const initials = useMemo(() => {
    const firstNameLetter = firstName ? firstName.charAt(0).toUpperCase() : '';
    const lastNameLetter = lastName ? lastName.charAt(0).toUpperCase() : '';
    return firstNameLetter + lastNameLetter;
  }, [firstName, lastName]);
  return (
    <Menu as="div" className="relative z-10">
      <div>
        <Menu.Button className="flex text-sm rounded-full focus:outline-none focus:ring-2 text-gray-500 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-blue-600">
          <span className="sr-only">Open user menu</span>
          <div className="h-8 w-8 rounded-full flex justify-center align-center">
            {!!imgUrl && (
              <img
                className="rounded-full bg-gray-500"
                src={imgUrl}
                alt={`Menu for ${firstName || 'the logged in user'}`}
              />
            )}
            {!imgUrl && !!initials && (
              <p className="text-white self-center bg-gray-500">{initials}</p>
            )}
            {!imgUrl && !initials && <UserCircleIcon />}
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-auto rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {!!me?.organization && (
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700 w-full text-left flex flex-row justify-left gap-x-2 items-center',
                  )}
                >
                  {!!me.organization.logoUrl && (
                    <img
                      style={{
                        display: 'block',
                        maxWidth: '29px',
                        maxHeight: '23px',
                        width: 'auto',
                        height: 'auto',
                      }}
                      src={me.organization.logoUrl}
                      alt={me.organization.displayName}
                    />
                  )}{' '}
                  <span>{me.organization.displayName}</span>
                </div>
              )}
            </Menu.Item>
          )}
          {hasPermissions([
            Permissions.Readusers,
            Permissions.Createcredentials,
          ]) && (
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/settings"
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700 w-full text-left',
                  )}
                >
                  Settings
                </Link>
              )}
            </Menu.Item>
          )}
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={logout}
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-sm text-gray-700 w-full text-left',
                )}
              >
                Sign out
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

ProfileMenu.defaultProps = {
  imgUrl: undefined,
  firstName: '',
  lastName: '',
};

export default ProfileMenu;
