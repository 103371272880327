/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicDescription,
    BasicDescriptionFromJSON,
    BasicDescriptionFromJSONTyped,
    BasicDescriptionToJSON,
} from './BasicDescription';
import {
    QueryParameters,
    QueryParametersFromJSON,
    QueryParametersFromJSONTyped,
    QueryParametersToJSON,
} from './QueryParameters';
import {
    QueryStatus,
    QueryStatusFromJSON,
    QueryStatusFromJSONTyped,
    QueryStatusToJSON,
} from './QueryStatus';

/**
 * The base model for any single resource HTTP response
 * @export
 * @interface QueryResponseUI
 */
export interface QueryResponseUI {
    /**
     * The user that created the resource
     * @type {string}
     * @memberof QueryResponseUI
     */
    createdUserId: string;
    /**
     * The created datetime of the resource
     * @type {Date}
     * @memberof QueryResponseUI
     */
    createdDt: string;
    /**
     * The user that last updated the resource
     * @type {string}
     * @memberof QueryResponseUI
     */
    updatedUserId: string;
    /**
     * The datetime the resource was last updated
     * @type {Date}
     * @memberof QueryResponseUI
     */
    updatedDt: string;
    /**
     * The query id
     * @type {string}
     * @memberof QueryResponseUI
     */
    queryId: string;
    /**
     * The SQL query
     * @type {string}
     * @memberof QueryResponseUI
     */
    query: string;
    /**
     * 
     * @type {BasicDescription}
     * @memberof QueryResponseUI
     */
    dataset: BasicDescription;
    /**
     * 
     * @type {BasicDescription}
     * @memberof QueryResponseUI
     */
    embedding: BasicDescription;
    /**
     * 
     * @type {QueryStatus}
     * @memberof QueryResponseUI
     */
    status: QueryStatus;
    /**
     * The time the query started executing
     * @type {Date}
     * @memberof QueryResponseUI
     */
    startDt?: string | null;
    /**
     * The time the query finished executing
     * @type {Date}
     * @memberof QueryResponseUI
     */
    endDt?: string | null;
    /**
     * Defines if the query is in an active state or not
     * @type {boolean}
     * @memberof QueryResponseUI
     */
    isActive?: boolean;
    /**
     * 
     * @type {QueryParameters}
     * @memberof QueryResponseUI
     */
    parameters?: QueryParameters;
}

export function QueryResponseUIFromJSON(json: any): QueryResponseUI {
    return QueryResponseUIFromJSONTyped(json, false);
}

export function QueryResponseUIFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryResponseUI {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdUserId': json['createdUserId'],
        'createdDt': json['createdDt'],
        'updatedUserId': json['updatedUserId'],
        'updatedDt': json['updatedDt'],
        'queryId': json['queryId'],
        'query': json['query'],
        'dataset': BasicDescriptionFromJSON(json['dataset']),
        'embedding': BasicDescriptionFromJSON(json['embedding']),
        'status': QueryStatusFromJSON(json['status']),
        'startDt': !exists(json, 'startDt') ? undefined : json['startDt'] === null ? null : json['startDt'],
        'endDt': !exists(json, 'endDt') ? undefined : json['endDt'] === null ? null : json['endDt'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'parameters': !exists(json, 'parameters') ? undefined : QueryParametersFromJSON(json['parameters']),
    };
}

export function QueryResponseUIToJSON(value?: QueryResponseUI | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdUserId': value.createdUserId,
        'createdDt': value.createdDt,
        'updatedUserId': value.updatedUserId,
        'updatedDt': value.updatedDt,
        'queryId': value.queryId,
        'query': value.query,
        'dataset': BasicDescriptionToJSON(value.dataset),
        'embedding': BasicDescriptionToJSON(value.embedding),
        'status': QueryStatusToJSON(value.status),
        'startDt': value.startDt === undefined ? undefined : value.startDt === null ? null : value.startDt,
        'endDt': value.endDt === undefined ? undefined : value.endDt === null ? null : value.endDt,
        'isActive': value.isActive,
        'parameters': QueryParametersToJSON(value.parameters),
    };
}

