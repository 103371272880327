import React from 'react';
import './index.scss';
import './styles/tailwind.scss';
import './styles/satoshi.scss';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals.js';

const root = createRoot(document.getElementById('root')!);

(async () => {
  const options = {
    streaming: true,
  };

  const ldSdkKey = process.env.REACT_APP_LD_UI_SDK_KEY ?? '';
  const LDProvider = await asyncWithLDProvider({
    clientSideID: ldSdkKey,
    options,
  });

  root.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
