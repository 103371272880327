import { MetadataUploadResponse } from 'api/generated';
import CsvMetadataIllustration from 'assets/illustrations/csv-metadata.svg';
import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import FadeTransition from 'components/FadeTransition';
import FileUpload from 'components/FileUpload';
import MetadataPreview from 'pages/datasets/detail/components/MetadataUpload/MetadataPreview';
import { useAddMetadataMutation } from 'pages/datasets/detail/components/MetadataUpload/queries';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const MetadataUpload = function MetadataUpload({
  datasetId,
  datasetName,
}: {
  datasetId: string;
  datasetName: string;
}) {
  const [uploadResponse, setUploadResponse] =
    useState<MetadataUploadResponse>();
  const [submitError, setSubmitError] = useState();
  const navigate = useNavigate();

  const { mutate: addMetadata } = useAddMetadataMutation(datasetId);

  const [uploadError, setUploadError] = useState<string>();

  const done = () => navigate(`/datasets/${datasetId}`);

  return (
    <div>
      <div className="flex justify-end pb-2">
        <Button type="button" onClick={done} buttonStyle="secondary">
          Cancel
        </Button>
        <FadeTransition appear show={Boolean(uploadResponse)}>
          {uploadResponse && (
            <Button
              type="button"
              className="ml-2"
              onClick={() => {
                addMetadata(
                  {
                    datasetId,
                    addMetadataRequest: { csvPath: uploadResponse.csvPath },
                  },
                  {
                    onSuccess: () => {
                      toast.success(
                        `Successfully added metadata to ${datasetName}`,
                      );
                      done();
                    },
                    onError: async (error: any) => {
                      setSubmitError((await error.response.json()).detail);
                    },
                  },
                );
              }}
            >
              Add Metadata
            </Button>
          )}
        </FadeTransition>
      </div>
      <div className="text-right w-full">
        <ErrorText errorStyle="form">{submitError}</ErrorText>
      </div>
      {!uploadResponse && (
        <FadeTransition show={!uploadResponse} appear>
          <FileUpload
            acceptedTypes={['text/csv', 'application/csv']}
            uploadUrl={`${process.env.REACT_APP_API_HOST}/api/ui/datasets/${datasetId}/upload/csv`}
            onUploadSuccess={(upload) => {
              setUploadResponse(upload[0].response);
            }}
            onUploadError={(upload) => {
              setUploadError(upload[0].response.detail);
            }}
            illustrations={[
              {
                primary: CsvMetadataIllustration,
                secondary: CsvMetadataIllustration,
              },
            ]}
            error={uploadError}
          >
            <p className="font-bold">Add Metadata</p>
            <p className="max-w-80 pointer-events-none">
              Drop a CSV with metadata or{' '}
              <strong className="text-blue-500">browse</strong>.
            </p>
          </FileUpload>
        </FadeTransition>
      )}
      {uploadResponse && (
        <MetadataPreview
          csvPath={uploadResponse.csvPath}
          datasetId={datasetId}
          initialData={uploadResponse.preview}
        />
      )}
    </div>
  );
};

export default MetadataUpload;
