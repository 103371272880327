/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppSharedV0SchemaPagedMetaResponse,
    AppSharedV0SchemaPagedMetaResponseFromJSON,
    AppSharedV0SchemaPagedMetaResponseFromJSONTyped,
    AppSharedV0SchemaPagedMetaResponseToJSON,
} from './AppSharedV0SchemaPagedMetaResponse';
import {
    Column,
    ColumnFromJSON,
    ColumnFromJSONTyped,
    ColumnToJSON,
} from './Column';
import {
    OrgInvitationTableRow,
    OrgInvitationTableRowFromJSON,
    OrgInvitationTableRowFromJSONTyped,
    OrgInvitationTableRowToJSON,
} from './OrgInvitationTableRow';

/**
 * A response containing data to be represented in a table
 * @export
 * @interface OrgInvitationsTableResponse
 */
export interface OrgInvitationsTableResponse {
    /**
     * 
     * @type {AppSharedV0SchemaPagedMetaResponse}
     * @memberof OrgInvitationsTableResponse
     */
    meta: AppSharedV0SchemaPagedMetaResponse;
    /**
     * The table rows
     * @type {Array<OrgInvitationTableRow>}
     * @memberof OrgInvitationsTableResponse
     */
    data: Array<OrgInvitationTableRow>;
    /**
     * The table columns
     * @type {Array<Column>}
     * @memberof OrgInvitationsTableResponse
     */
    columns: Array<Column>;
}

export function OrgInvitationsTableResponseFromJSON(json: any): OrgInvitationsTableResponse {
    return OrgInvitationsTableResponseFromJSONTyped(json, false);
}

export function OrgInvitationsTableResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgInvitationsTableResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': AppSharedV0SchemaPagedMetaResponseFromJSON(json['meta']),
        'data': ((json['data'] as Array<any>).map(OrgInvitationTableRowFromJSON)),
        'columns': ((json['columns'] as Array<any>).map(ColumnFromJSON)),
    };
}

export function OrgInvitationsTableResponseToJSON(value?: OrgInvitationsTableResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': AppSharedV0SchemaPagedMetaResponseToJSON(value.meta),
        'data': ((value.data as Array<any>).map(OrgInvitationTableRowToJSON)),
        'columns': ((value.columns as Array<any>).map(ColumnToJSON)),
    };
}

