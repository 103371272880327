import {
  CreateCategoryOperationRequest,
  CreateDynamicTagTableNewRequest,
  DeleteCategoryRequest,
  DeleteDynamicTagRequest,
  DynamicTagsApi,
  GetCategoriesAsTableRequest,
  GetDynamicTagsAsTableRequest,
  SearchType,
  SimilaritySearchApi,
  UpdateDynamicTagPromptsOperationRequest,
  ValidateCategoryNameRequest,
  ValidateDynamicTagNameRequest,
  VisualPrompt,
} from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';

const LIST_REFRESH_INTERVAL = 5000;
const SEARCH_RESULTS_LENGTH = 100;

const createCategoryMutationKey = ['createDynamicTagCategory'];

export const useCreateCategoryMutation = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    createCategoryMutationKey,
    async (variables: CreateCategoryOperationRequest) => {
      const api = await initializeAPI<DynamicTagsApi>(DynamicTagsApi);
      return api.createCategory(variables);
    },
  );
};

const getCategoriesAsTableQueryKey = (input: any = {}) => [
  'getCategoriesAsTable',
  JSON.stringify(input),
];

export const getCategoriesAsTableQuery = (
  input: GetCategoriesAsTableRequest = {},
) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    getCategoriesAsTableQueryKey(input),
    async () => {
      const api = await initializeAPI<DynamicTagsApi>(DynamicTagsApi);
      return api.getCategoriesAsTable(input);
    },
    {
      cacheTime: 0,
      refetchInterval: LIST_REFRESH_INTERVAL,
      retry: false,
    },
  );
};

const getCategoryQueryKey = (categoryId?: string) => [
  'getCategory',
  categoryId,
];

export const useGetCategoryById = (categoryId?: string) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    getCategoryQueryKey(categoryId),
    async ({ signal }) => {
      const api = await initializeAPI<DynamicTagsApi>(DynamicTagsApi);
      return api.getCategory({ categoryId: categoryId || '' }, { signal });
    },
    { cacheTime: 0, enabled: Boolean(categoryId) },
  );
};

const getDynamicTagsAsTableQueryKey = (input: any = {}) => [
  'getDynamicTagsAsTable',
  JSON.stringify(input),
];

export const getDynamicTagsAsTableQuery = (
  input: GetDynamicTagsAsTableRequest = { categoryId: '' },
) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    getDynamicTagsAsTableQueryKey(input),
    async ({ signal }) => {
      const api = await initializeAPI<DynamicTagsApi>(DynamicTagsApi);
      return api.getDynamicTagsAsTable(input, { signal });
    },
    { cacheTime: 0, refetchInterval: LIST_REFRESH_INTERVAL, retry: false },
  );
};

export const getDynamicTagQueryKey = (dynamicTagId?: string) => [
  'getDynamicTag',
  dynamicTagId,
];

export const useGetDynamicTagById = (
  categoryId?: string,
  dynamicTagId?: string,
) => {
  const { initializeAPI } = useAPIContext();
  return useQuery(
    getDynamicTagQueryKey(dynamicTagId),
    async ({ signal }) => {
      const api = await initializeAPI<DynamicTagsApi>(DynamicTagsApi);
      return api.getDynamicTag(
        { dynamicTagId: dynamicTagId || '', categoryId: categoryId || '' },
        { signal },
      );
    },
    { cacheTime: 0, enabled: Boolean(dynamicTagId) },
  );
};

const createDynamicTagMutationKey = ['createDynamicTag'];

export const useCreateDynamicTagMutation = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    createDynamicTagMutationKey,
    async (variables: CreateDynamicTagTableNewRequest) => {
      const api = await initializeAPI<DynamicTagsApi>(DynamicTagsApi);
      return api.createDynamicTagTableNew(variables);
    },
  );
};

const updateDynamicTagMutationKey = ['updateDynamicTag'];

export const useUpdateDynamicTagMutation = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    updateDynamicTagMutationKey,
    async (variables: UpdateDynamicTagPromptsOperationRequest) => {
      const api = await initializeAPI<DynamicTagsApi>(DynamicTagsApi);
      return api.updateDynamicTagPrompts(variables);
    },
  );
};

export const getTopContentQueryKey = (
  datasetId: string,
  textPrompts: string[],
  visualPrompts: VisualPrompt[],
  searchType: SearchType,
) => [
  'getTopContent',
  datasetId,
  JSON.stringify(textPrompts),
  JSON.stringify(visualPrompts),
  searchType.valueOf(),
];

export const useGetTopContent = (
  datasetId: string,
  textPrompts: string[],
  visualPrompts: VisualPrompt[],
  searchType: SearchType,
) => {
  const { initializeAPI } = useAPIContext();
  const infiniteQuery = useInfiniteQuery(
    getTopContentQueryKey(datasetId, textPrompts, visualPrompts, searchType),
    async ({ signal }) => {
      const api = await initializeAPI<SimilaritySearchApi>(SimilaritySearchApi);

      // TODO: Need to send image path as well for upload flow when that is slated as a task
      const coactiveImageIds = visualPrompts.length
        ? visualPrompts.map((prompt) => prompt.coactiveImageId)
        : null;

      const requestParams = {
        queries: textPrompts,
        coactiveImageIds,
        datasetId,
        searchType,
        limit: SEARCH_RESULTS_LENGTH,
      };

      return api.getImagesWithMultimodalSearch(requestParams, { signal });
    },
    {
      enabled: Boolean(textPrompts?.length || visualPrompts?.length),
      cacheTime: 0,
    },
  );
  return infiniteQuery;
};

const validateCategoryNameMutationKey = ['validateCategoryName'];

export const useValidateCategoryNameMutation = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    validateCategoryNameMutationKey,
    async (request: ValidateCategoryNameRequest) => {
      const api = await initializeAPI<DynamicTagsApi>(DynamicTagsApi);
      return api.validateCategoryName(request);
    },
  );
};

const validateDynamicTagNameMutationKey = ['validateDynamicTagName'];

export const useValidateDynamicTagNameMutation = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    validateDynamicTagNameMutationKey,
    async (request: ValidateDynamicTagNameRequest) => {
      const api = await initializeAPI<DynamicTagsApi>(DynamicTagsApi);
      return api.validateDynamicTagName(request);
    },
  );
};

const deleteCategoryMutationKey = ['deleteCategory'];

export const useDeleteCategoryMutation = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    deleteCategoryMutationKey,
    async (request: DeleteCategoryRequest) => {
      const api = await initializeAPI<DynamicTagsApi>(DynamicTagsApi);
      return api.deleteCategory(request);
    },
  );
};

const deleteDynamicTagMutationKey = ['deleteDynamicTag'];

export const useDeleteDynamicTagMutation = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    deleteDynamicTagMutationKey,
    async (request: DeleteDynamicTagRequest) => {
      const api = await initializeAPI<DynamicTagsApi>(DynamicTagsApi);
      return api.deleteDynamicTag(request);
    },
  );
};
