import {
  CheckCircleIcon,
  PauseIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { getFileSize } from 'utils/FileUtils';
import ClipLoader from 'react-spinners/ClipLoader';
import colors from 'tailwindcss/colors';
import ProgressBar from './ProgressBar';

interface UploadProgressProps {
  file: File;
  progress: number; // 0 - 100
  started: boolean;
  error?: boolean;
  success?: boolean;
  onPause: () => void;
  onCancel: () => void;
  onComplete: () => void;
}

const END_TRANSITION_DURATION = 300;

const UploadProgress: React.FC<UploadProgressProps> = function UploadProgress({
  file,
  progress,
  started,
  error,
  success,
  onPause,
  onCancel,
  onComplete: onCompleteProp,
}) {
  const [progressTransitionComplete, setProgressTransitionComplete] =
    useState(false);
  const complete = progress === 100;
  const completeTimeout = useRef<any>();
  const timeoutStart = useRef<number | undefined>();

  const startOnCompleteTimeout = (cb: () => void, duration: number) => {
    completeTimeout.current = setTimeout(() => {
      completeTimeout.current = undefined;
      cb?.();
    }, duration);
  };

  useEffect(() => {
    if (error || success) {
      setProgressTransitionComplete(true);
    }
  }, [error]);

  const onComplete = useCallback(() => {
    setProgressTransitionComplete(true);
    startOnCompleteTimeout(onCompleteProp, END_TRANSITION_DURATION);
    timeoutStart.current = new Date().getTime();
  }, [onCompleteProp]);

  useEffect(() => {
    // completeTimeout.current will only evaluate to true when the timeout was set but hasn't executed
    if (completeTimeout.current) {
      clearTimeout(completeTimeout.current);
      startOnCompleteTimeout(
        onCompleteProp,
        timeoutStart.current! - new Date().getTime() + END_TRANSITION_DURATION,
      );
    }
    return () => clearTimeout(completeTimeout.current);
  }, [onCompleteProp]);

  useEffect(() => () => clearTimeout(completeTimeout.current), []);

  return (
    <div className="rounded-md bg-white w-full p-4 drop-shadow-md flex flex-col justify-center h-20">
      <div
        className={classNames(
          'flex justify-between align-center transition-all items-center',
        )}
      >
        <div>
          {/* {progressTransitionComplete && <div className="h-1" />} */}
          <div className="text-sm font-semibold">{file.name}</div>
          <div
            className={classNames('text-xs leading-4 transition-all', {
              'pb-0': progressTransitionComplete || !started,
              'pb-3': !progressTransitionComplete,
            })}
          >
            <span>
              {process.env.REACT_APP_MOCK ? getFileSize(file) : '10 KB'}
            </span>
            {Boolean(started) && <span className="px-1">&#8226;</span>}
            {Boolean(complete || success) && <span>Upload Complete</span>}
            {Boolean(!complete && started && !error && !success) && (
              <span>Uploading {Math.round(Math.min(progress, 100))}%</span>
            )}
            {Boolean(error) && <span>Error</span>}
            {/* {progressTransitionComplete && <div className="h-1" />} */}
          </div>
        </div>
        <div className="flex">
          {!progressTransitionComplete && started && (
            <button
              type="button"
              aria-label="Pause"
              onClick={onPause}
              className="w-7 h-7"
              disabled={complete}
            >
              <PauseIcon className="fill-gray-400 hover:fill-gray-500 transition-all" />
            </button>
          )}
          {!progressTransitionComplete && (
            <button
              type="button"
              aria-label="Cancel"
              onClick={onCancel}
              className="w-7 h-7"
              disabled={complete}
            >
              <XCircleIcon className="fill-gray-400 hover:fill-red-600 transition-all" />
            </button>
          )}
          {progressTransitionComplete && !error && !success && (
            <ClipLoader
              cssOverride={{ textAlign: 'center' }}
              color={colors.blue['500']}
              loading
              size={25}
              speedMultiplier={0.75}
            />
          )}
          {progressTransitionComplete && error && (
            <div className="w-7 h-7">
              <XCircleIcon className="fill-red-600" />
            </div>
          )}
          {progressTransitionComplete && success && (
            <div className="w-7 h-7">
              <CheckCircleIcon className="fill-green-600" />
            </div>
          )}
        </div>
      </div>
      <ProgressBar
        progress={error || success ? 100 : progress}
        onComplete={onComplete}
        started={started}
      />
    </div>
  );
};

UploadProgress.defaultProps = {
  error: false,
  success: false,
};

export default UploadProgress;
