import { DateTime } from 'luxon';
import React from 'react';

const Timestamp: React.FC<{
  datetime: string;
}> = function Timestamp({ datetime }) {
  const parsedDt = DateTime.fromISO(datetime, {
    zone: 'utc',
  });
  return (
    <span
      title={parsedDt.toLocaleString({ dateStyle: 'full', timeStyle: 'long' })}
    >
      {parsedDt.toRelative()}
    </span>
  );
};

export default Timestamp;
