/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DatasetStatusEnum,
    DatasetStatusEnumFromJSON,
    DatasetStatusEnumFromJSONTyped,
    DatasetStatusEnumToJSON,
} from './DatasetStatusEnum';
import {
    EncoderNameEnum,
    EncoderNameEnumFromJSON,
    EncoderNameEnumFromJSONTyped,
    EncoderNameEnumToJSON,
} from './EncoderNameEnum';
import {
    KeyframeSamplingMethodEnum,
    KeyframeSamplingMethodEnumFromJSON,
    KeyframeSamplingMethodEnumFromJSONTyped,
    KeyframeSamplingMethodEnumToJSON,
} from './KeyframeSamplingMethodEnum';
import {
    StorageTypeEnum,
    StorageTypeEnumFromJSON,
    StorageTypeEnumFromJSONTyped,
    StorageTypeEnumToJSON,
} from './StorageTypeEnum';

/**
 * A request to create a dataset
 * @export
 * @interface CreateDatasetRequest
 */
export interface CreateDatasetRequest {
    /**
     * The name of the dataset
     * @type {string}
     * @memberof CreateDatasetRequest
     */
    name: string;
    /**
     * The description of the dataset
     * @type {string}
     * @memberof CreateDatasetRequest
     */
    description?: string | null;
    /**
     * An S3 or GCS path prefix for all images and videos to add to the dataset
     * @type {string}
     * @memberof CreateDatasetRequest
     */
    dataPath?: string | null;
    /**
     * A path to a previously uploaded CSV file containing data paths
     * @type {string}
     * @memberof CreateDatasetRequest
     */
    csvPath?: string | null;
    /**
     * The id of the credentials to use to access protected data
     * @type {string}
     * @memberof CreateDatasetRequest
     */
    credentialsId?: string | null;
    /**
     * 
     * @type {StorageTypeEnum}
     * @memberof CreateDatasetRequest
     */
    storageType?: StorageTypeEnum;
    /**
     * An optional S3 path for a CSV file containing any metadata for your images and videos that should be available to query
     * @type {string}
     * @memberof CreateDatasetRequest
     */
    metadataPath?: string | null;
    /**
     * 
     * @type {EncoderNameEnum}
     * @memberof CreateDatasetRequest
     */
    encoder?: EncoderNameEnum;
    /**
     * The name of the encoder to transform your audio data
     * @type {string}
     * @memberof CreateDatasetRequest
     */
    audioEncoder?: string | null;
    /**
     * Enable OCR (optical character recognition) for all assets in this dataset
     * @type {boolean}
     * @memberof CreateDatasetRequest
     */
    ocr?: boolean | null;
    /**
     * 
     * @type {KeyframeSamplingMethodEnum}
     * @memberof CreateDatasetRequest
     */
    keyframeSamplingMethod?: KeyframeSamplingMethodEnum;
    /**
     * Num of keyframes/sec to sample from videos if using uniform sampling method
     * @type {number}
     * @memberof CreateDatasetRequest
     */
    keyframeUniformFpsSamplingRate?: number | null;
    /**
     * 
     * @type {DatasetStatusEnum}
     * @memberof CreateDatasetRequest
     */
    status?: DatasetStatusEnum;
}

export function CreateDatasetRequestFromJSON(json: any): CreateDatasetRequest {
    return CreateDatasetRequestFromJSONTyped(json, false);
}

export function CreateDatasetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDatasetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dataPath': !exists(json, 'dataPath') ? undefined : json['dataPath'],
        'csvPath': !exists(json, 'csvPath') ? undefined : json['csvPath'],
        'credentialsId': !exists(json, 'credentialsId') ? undefined : json['credentialsId'],
        'storageType': !exists(json, 'storageType') ? undefined : StorageTypeEnumFromJSON(json['storageType']),
        'metadataPath': !exists(json, 'metadataPath') ? undefined : json['metadataPath'],
        'encoder': !exists(json, 'encoder') ? undefined : EncoderNameEnumFromJSON(json['encoder']),
        'audioEncoder': !exists(json, 'audioEncoder') ? undefined : json['audioEncoder'],
        'ocr': !exists(json, 'ocr') ? undefined : json['ocr'],
        'keyframeSamplingMethod': !exists(json, 'keyframeSamplingMethod') ? undefined : KeyframeSamplingMethodEnumFromJSON(json['keyframeSamplingMethod']),
        'keyframeUniformFpsSamplingRate': !exists(json, 'keyframeUniformFpsSamplingRate') ? undefined : json['keyframeUniformFpsSamplingRate'],
        'status': !exists(json, 'status') ? undefined : DatasetStatusEnumFromJSON(json['status']),
    };
}

export function CreateDatasetRequestToJSON(value?: CreateDatasetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'dataPath': value.dataPath,
        'csvPath': value.csvPath,
        'credentialsId': value.credentialsId,
        'storageType': StorageTypeEnumToJSON(value.storageType),
        'metadataPath': value.metadataPath,
        'encoder': EncoderNameEnumToJSON(value.encoder),
        'audioEncoder': value.audioEncoder,
        'ocr': value.ocr,
        'keyframeSamplingMethod': KeyframeSamplingMethodEnumToJSON(value.keyframeSamplingMethod),
        'keyframeUniformFpsSamplingRate': value.keyframeUniformFpsSamplingRate,
        'status': DatasetStatusEnumToJSON(value.status),
    };
}

