import TextInput from 'components/TextInput';
import React, { useRef } from 'react';
import EditableTextBase from './EditableTextBase';

interface EditableTextInlineViewProps {
  id: string;
  name: string;
  value: string | undefined | null;
  placeholder?: string;
  updateValue: (value: string) => Promise<void>;
  wrapperClassName?: string;
  inputClassName?: string;
  textDisplayClassName?: string;
  negativeTopMargin?: boolean;
  dataTestId?: string;
}

const EditableTextInlineView: React.FC<EditableTextInlineViewProps> =
  function EditableTextView({
    id,
    name,
    value,
    placeholder,
    updateValue: updateValueProp,
    inputClassName,
    textDisplayClassName,
    wrapperClassName,
    negativeTopMargin,
    dataTestId,
  }) {
    const textInputRef = useRef<HTMLInputElement | null>();
    return (
      <EditableTextBase
        dataTestId={dataTestId}
        id={id}
        value={value}
        placeholder={placeholder}
        updateValue={updateValueProp}
        textInputRef={textInputRef}
        textDisplayClassName={textDisplayClassName}
        wrapperClassName={wrapperClassName}
        negativeTopMargin={negativeTopMargin}
        textInputElement={
          <TextInput
            dataTestId={dataTestId}
            ref={(ref) => {
              textInputRef.current = ref;
            }}
            defaultValue={value ?? undefined}
            label=""
            id={id}
            name={name}
            inputClassName={inputClassName}
          />
        }
      />
    );
  };

EditableTextInlineView.defaultProps = {
  placeholder: undefined,
  wrapperClassName: '',
  inputClassName: '',
  textDisplayClassName: '',
  negativeTopMargin: true,
  dataTestId: undefined,
};

export default EditableTextInlineView;
