/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssetDownloadResponse,
  AssetResponse,
  ErrorResponse,
  HTTPValidationError,
} from '../models';
import {
    AssetDownloadResponseFromJSON,
    AssetDownloadResponseToJSON,
    AssetResponseFromJSON,
    AssetResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface GetImageDownloadUrlRequest {
    coactiveImageId: string;
}

export interface GetMetadataForImageRequest {
    coactiveImageId: string;
}

/**
 * ImageApi - interface
 * 
 * @export
 * @interface ImageApiInterface
 */
export interface ImageApiInterface {
    /**
     * Get image download url
     * @summary Get image download url
     * @param {string} coactiveImageId The coactive image id of the image to download
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApiInterface
     */
    getImageDownloadUrlRaw(requestParameters: GetImageDownloadUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetDownloadResponse>>;

    /**
     * Get image download url
     * Get image download url
     */
    getImageDownloadUrl(requestParameters: GetImageDownloadUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetDownloadResponse>;

    /**
     * Get metadata for image by coactive image id
     * @summary Get metadata for image
     * @param {string} coactiveImageId The coactive image id to fetch metadata for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApiInterface
     */
    getMetadataForImageRaw(requestParameters: GetMetadataForImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetResponse>>;

    /**
     * Get metadata for image by coactive image id
     * Get metadata for image
     */
    getMetadataForImage(requestParameters: GetMetadataForImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetResponse>;

}

/**
 * 
 */
export class ImageApi extends runtime.BaseAPI implements ImageApiInterface {

    /**
     * Get image download url
     * Get image download url
     */
    async getImageDownloadUrlRaw(requestParameters: GetImageDownloadUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetDownloadResponse>> {
        if (requestParameters.coactiveImageId === null || requestParameters.coactiveImageId === undefined) {
            throw new runtime.RequiredError('coactiveImageId','Required parameter requestParameters.coactiveImageId was null or undefined when calling getImageDownloadUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/images/{coactive_image_id}/download`.replace(`{${"coactive_image_id"}}`, encodeURIComponent(String(requestParameters.coactiveImageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetDownloadResponseFromJSON(jsonValue));
    }

    /**
     * Get image download url
     * Get image download url
     */
    async getImageDownloadUrl(requestParameters: GetImageDownloadUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetDownloadResponse> {
        const response = await this.getImageDownloadUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get metadata for image by coactive image id
     * Get metadata for image
     */
    async getMetadataForImageRaw(requestParameters: GetMetadataForImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetResponse>> {
        if (requestParameters.coactiveImageId === null || requestParameters.coactiveImageId === undefined) {
            throw new runtime.RequiredError('coactiveImageId','Required parameter requestParameters.coactiveImageId was null or undefined when calling getMetadataForImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/images/{coactive_image_id}/metadata`.replace(`{${"coactive_image_id"}}`, encodeURIComponent(String(requestParameters.coactiveImageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetResponseFromJSON(jsonValue));
    }

    /**
     * Get metadata for image by coactive image id
     * Get metadata for image
     */
    async getMetadataForImage(requestParameters: GetMetadataForImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetResponse> {
        const response = await this.getMetadataForImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
