import { EmbeddingApi } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useQuery from 'hooks/useQuery';

export const getEmbeddingsQueryKey = (datasetId: string | null) => [
  'getEmbeddingsByDatasetId',
  datasetId,
];
export const useGetEmbeddingsQuery = (datasetId: string | null) => {
  const { initializeAPI } = useAPIContext();
  const query = useQuery(
    getEmbeddingsQueryKey(datasetId),
    async ({ queryKey, signal }) => {
      const [, _datasetId] = queryKey;
      const api = await initializeAPI<EmbeddingApi>(EmbeddingApi);
      return api.getEmbeddingsByDatasetId(
        { datasetId: _datasetId! },
        { signal },
      );
    },
    { enabled: Boolean(datasetId) },
  );
  return query;
};
