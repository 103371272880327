import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/solid';
import ClipboardIconButton from 'components/ClipboardIconButton';
import React, { useState } from 'react';

const SecretValue: React.FC<{
  value: string;
}> = function SecretValue({ value }) {
  const [show, setShow] = useState(false);
  const characters = new Array(value.length).join('*');
  return (
    <span className="flex flex-row gap-2 justify-between">
      {show ? value : characters}
      <span className="flex flex-row gap-2">
        {show ? (
          <EyeSlashIcon
            className="cursor-pointer text-gray-500 delete-button-inner h-6 w-6 justify-center rounded-[.05rem] transition-all hover:text-gray-400"
            onClick={() => setShow(false)}
          />
        ) : (
          <EyeIcon
            className="cursor-pointer text-gray-500 delete-button-inner h-6 w-6 justify-center rounded-[.05rem] transition-all hover:text-gray-400"
            onClick={() => setShow(true)}
          />
        )}
        <ClipboardIconButton
          id="client secret"
          text={value}
          padding="p-0"
          hoverColor="hover:text-blue-600"
        />
      </span>
    </span>
  );
};

export default SecretValue;
