/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface CreateOrgInvitationRequest
 */
export interface CreateOrgInvitationRequest {
    /**
     * The invitee's first name
     * @type {string}
     * @memberof CreateOrgInvitationRequest
     */
    givenName: string;
    /**
     * The invitee's last name
     * @type {string}
     * @memberof CreateOrgInvitationRequest
     */
    familyName: string;
    /**
     * The email to invite
     * @type {string}
     * @memberof CreateOrgInvitationRequest
     */
    email: string;
}

export function CreateOrgInvitationRequestFromJSON(json: any): CreateOrgInvitationRequest {
    return CreateOrgInvitationRequestFromJSONTyped(json, false);
}

export function CreateOrgInvitationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrgInvitationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'givenName': json['givenName'],
        'familyName': json['familyName'],
        'email': json['email'],
    };
}

export function CreateOrgInvitationRequestToJSON(value?: CreateOrgInvitationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'givenName': value.givenName,
        'familyName': value.familyName,
        'email': value.email,
    };
}

