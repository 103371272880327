import { TableCellsIcon, Squares2X2Icon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import './ListViewToggle.scss';

export type ListView = 'table' | 'tile';

function getIcon(view: ListView): FunctionComponent {
  switch (view) {
    case 'tile':
      return Squares2X2Icon;
    case 'table':
      return TableCellsIcon;
    default:
      throw new Error('Unrecognized icon');
  }
}

interface ListViewToggleProps {
  onToggle: (view: ListView) => void;
  activeView: ListView;
  views: ListView[];
}

const ListViewToggle: React.FunctionComponent<ListViewToggleProps> =
  function ListViewToggle({ activeView, onToggle, views }) {
    const toggleState = (view: ListView) => () =>
      activeView !== view && onToggle(view);
    return (
      <div>
        {views.map((view: ListView) => {
          const Icon = getIcon(view) as FunctionComponent<{
            className?: string;
          }>;
          return (
            <button
              key={view}
              type="button"
              onClick={toggleState(view)}
              className='rounded-lg px-1 py-2 duration-300"'
            >
              <Icon
                className={classNames('h-5 w-5 hover:text-gray-500', {
                  'text-gray-500': view === activeView,
                  'text-gray-400': view !== activeView,
                })}
                aria-hidden="true"
              />
            </button>
          );
        })}
      </div>
    );
  };

export default ListViewToggle;
