/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface AddAssetItem
 */
export interface AddAssetItem {
    /**
     * The path to the asset to add
     * @type {string}
     * @memberof AddAssetItem
     */
    path: string;
    /**
     * Optional metadata in a key/value-pair format
     * @type {{ [key: string]: string; }}
     * @memberof AddAssetItem
     */
    metadata?: { [key: string]: string; } | null;
}

export function AddAssetItemFromJSON(json: any): AddAssetItem {
    return AddAssetItemFromJSONTyped(json, false);
}

export function AddAssetItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddAssetItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'path': json['path'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
    };
}

export function AddAssetItemToJSON(value?: AddAssetItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'path': value.path,
        'metadata': value.metadata,
    };
}

