import { AssetResponse, LabelEnum, PagedAssetsResponse } from 'api/generated';
import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import ImageGrid from 'components/ImageGrid';
import React, { useMemo, useState } from 'react';
import { InfiniteData } from 'react-query';
import { LabeledAssetResponse } from 'types/image';

interface InteractiveLabelGridProps {
  images: Array<AssetResponse> | InfiniteData<PagedAssetsResponse> | undefined;
  addLabels: (positive: string[], negative: string[]) => void;
  isAddingLabels: boolean;
  cancel: () => void;
  error?: string;
  fetchNextPage?: () => void;
  isFetchingNextPage?: boolean;
  numRequiredPositiveLabels?: number;
  dataTestId?: string;
}

const InteractiveLabelGrid: React.FunctionComponent<InteractiveLabelGridProps> =
  function InteractiveLabelGrid({
    images: imageInput,
    addLabels,
    isAddingLabels,
    cancel,
    error,
    fetchNextPage,
    isFetchingNextPage,
    numRequiredPositiveLabels: numRequiredPositiveLabelsProp,
    dataTestId,
  }) {
    const numRequiredPositiveLabels = numRequiredPositiveLabelsProp ?? 0;
    const [labeledImages, setLabeledImages] = useState<{
      positive: Set<string>;
      negative: Set<string>;
    }>({ positive: new Set(), negative: new Set() });

    const imagePages = Array.isArray(imageInput)
      ? {
          pages: [
            {
              data: imageInput as Array<AssetResponse>,
              meta: { page: { total: imageInput?.length ?? 0 } },
            } as PagedAssetsResponse,
          ],
        }
      : imageInput;

    const [images, totalImages] = useMemo(
      () => [
        imagePages?.pages.reduce(
          (acc: AssetResponse[], item) => [
            ...acc,
            ...item.data.map((it: AssetResponse) => {
              if (labeledImages.positive.has(it.coactiveImageId)) {
                return { ...it, label: LabelEnum._1 };
              }
              if (labeledImages.negative.has(it.coactiveImageId)) {
                return { ...it, label: LabelEnum._0 };
              }
              return it;
            }),
          ],
          [],
        ) || [],
        imagePages?.pages.length
          ? imagePages.pages[imagePages.pages.length - 1].meta?.page?.total || 0
          : 0,
      ],
      [imagePages, labeledImages],
    );

    const hasMore = totalImages > images?.length;

    const toggleLabel = (image: LabeledAssetResponse) => {
      if (image.label === LabelEnum._1) {
        setLabeledImages((x) => {
          const positive = new Set(x?.positive);
          positive.delete(image.coactiveImageId);
          const negative = new Set(x?.negative);
          negative.add(image.coactiveImageId);
          return { positive, negative };
        });
      } else if (image.label === LabelEnum._0) {
        setLabeledImages((x) => {
          const negative = new Set(x?.negative);
          negative.delete(image.coactiveImageId);
          return { ...x, negative };
        });
      } else {
        setLabeledImages((x) => {
          const positive = new Set(x?.positive);
          positive.add(image.coactiveImageId);
          return { ...x, positive };
        });
      }
    };

    const numPositiveExamples = labeledImages.positive.size;
    const numNegativeExamples = labeledImages.negative.size;

    const selectedExamples = images.filter(
      (img: AssetResponse) =>
        labeledImages.positive.has(img.coactiveImageId) ||
        labeledImages.negative.has(img.coactiveImageId),
    );

    return (
      <>
        <div className="py-5">
          <div className="flex flex-row justify-between items-center">
            <Button type="button" buttonStyle="secondary" onClick={cancel}>
              Cancel
            </Button>
            <div className="flex items-center" data-cy={dataTestId}>
              <div>
                {numPositiveExamples === 0 && numRequiredPositiveLabels > 0 ? (
                  <span className="font-medium text-blue-600">
                    Select at least {numRequiredPositiveLabels} positive
                    examples
                  </span>
                ) : undefined}
                {numPositiveExamples > 0 &&
                numRequiredPositiveLabels > 0 &&
                numPositiveExamples < numRequiredPositiveLabels ? (
                  <span className="font-medium text-blue-600">
                    Select at least{' '}
                    {numRequiredPositiveLabels - numPositiveExamples} more
                    positive example
                    {numRequiredPositiveLabels - numPositiveExamples === 1
                      ? ''
                      : 's'}
                  </span>
                ) : undefined}
                {(numPositiveExamples || numNegativeExamples) &&
                numPositiveExamples >= numRequiredPositiveLabels ? (
                  <span>
                    {numPositiveExamples
                      ? `${numPositiveExamples} positive`
                      : ''}
                    {numPositiveExamples && numNegativeExamples ? ', ' : ' '}
                    {numNegativeExamples
                      ? `${numNegativeExamples} negative `
                      : ''}
                    example
                    {numNegativeExamples === 1 ||
                    (numNegativeExamples === 0 && numPositiveExamples <= 1)
                      ? ''
                      : 's'}{' '}
                    selected
                  </span>
                ) : undefined}
              </div>

              <Button
                type="submit"
                className="ml-4"
                disabled={
                  numPositiveExamples < numRequiredPositiveLabels ||
                  numPositiveExamples + numNegativeExamples === 0
                }
                onClick={() =>
                  addLabels(
                    Array.from(labeledImages.positive),
                    Array.from(labeledImages.negative),
                  )
                }
                loading={isAddingLabels}
              >
                <span data-cy={`${dataTestId}-submit-btn`}>
                  Select Examples
                </span>
              </Button>
            </div>
          </div>
          <ErrorText>{error}</ErrorText>
        </div>
        <ImageGrid
          images={images}
          type="uniform"
          onClick={toggleLabel}
          selected={selectedExamples}
          hasMore={hasMore}
          fetchNextImages={fetchNextPage}
          isLoadingMore={isFetchingNextPage}
          loadMoreButton
          dataTestId={dataTestId}
        />
      </>
    );
  };

InteractiveLabelGrid.defaultProps = {
  error: undefined,
  fetchNextPage: undefined,
  isFetchingNextPage: undefined,
  numRequiredPositiveLabels: 0,
  dataTestId: undefined,
};

export default InteractiveLabelGrid;
