import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

interface SidebarWrapperProps {
  rightSidebarOpen: boolean;
  compact?: boolean;
  footer?: React.ReactNode;
  header?: React.ReactNode;
}

const SidebarWrapper: React.FunctionComponent<
  PropsWithChildren<SidebarWrapperProps>
> = ({ children, compact, footer, header, rightSidebarOpen }) => (
  <Transition.Root show={rightSidebarOpen} className="box-border">
    <Transition.Child
      appear
      className={classNames(
        'pointer-events-auto h-[calc(100vh-4rem)] py-4 box-border px-5 transition-width flex-shrink-0 w-0',
        compact ? 'w-[30rem]' : 'w-[50vw]',
      )}
      enter="transition ease-in-out duration-300 transform"
      enterFrom="w-0"
      enterTo={`${compact ? 'w-[30rem]' : 'w-[50vw]'}`}
      leave="transition ease-in-out duration-300 transform"
      leaveFrom={`${compact ? 'w-[30rem]' : 'w-[50vw]'}`}
      leaveTo="w-0"
    >
      <Transition.Child
        appear
        className="shadow-sm relative w-full border border-gray-200 bg-white py-14 h-full rounded-xl"
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="h-full flex-1 flex flex-col">
          <Transition.Child
            appear
            enter="ease-in-out duration-300 delay-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {header && (
              <div className="h-14 absolute flex items-center justify-end top-0 right-0 px-4">
                {header}
              </div>
            )}
          </Transition.Child>
          {children}
          {footer && (
            <div className="w-full border-t border-slate-200 h-14 absolute flex items-center gap-x-2 bottom-0 right-0 px-4">
              {footer}
            </div>
          )}
        </div>
      </Transition.Child>
      <div className="flex-shrink-0 w-14">
        {/* Placeholder element to force sidebar to shrink to fit close icon */}
      </div>
    </Transition.Child>
  </Transition.Root>
);

SidebarWrapper.defaultProps = {
  compact: false,
  header: undefined,
  footer: undefined,
};

export default SidebarWrapper;
