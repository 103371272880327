/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicDescription,
    BasicDescriptionFromJSON,
    BasicDescriptionFromJSONTyped,
    BasicDescriptionToJSON,
} from './BasicDescription';

/**
 * The base model for any single resource HTTP response
 * @export
 * @interface DynamicTagCategoryTableRowData
 */
export interface DynamicTagCategoryTableRowData {
    /**
     * The user that created the resource
     * @type {string}
     * @memberof DynamicTagCategoryTableRowData
     */
    createdUserId: string;
    /**
     * The created datetime of the resource
     * @type {Date}
     * @memberof DynamicTagCategoryTableRowData
     */
    createdDt: string;
    /**
     * The user that last updated the resource
     * @type {string}
     * @memberof DynamicTagCategoryTableRowData
     */
    updatedUserId: string;
    /**
     * The datetime the resource was last updated
     * @type {Date}
     * @memberof DynamicTagCategoryTableRowData
     */
    updatedDt: string;
    /**
     * The unique identifier for the category
     * @type {string}
     * @memberof DynamicTagCategoryTableRowData
     */
    categoryId: string;
    /**
     * The name of the category
     * @type {string}
     * @memberof DynamicTagCategoryTableRowData
     */
    name: string;
    /**
     * The category probability table paths
     * @type {object}
     * @memberof DynamicTagCategoryTableRowData
     */
    probabilityTablePaths: object;
    /**
     * The dynamic tags id related to the category
     * @type {Array<string>}
     * @memberof DynamicTagCategoryTableRowData
     */
    dynamicTagIds?: Array<string> | null;
    /**
     * The datasets list
     * @type {Array<BasicDescription>}
     * @memberof DynamicTagCategoryTableRowData
     */
    datasets: Array<BasicDescription>;
    /**
     * The dynamic tags dictionary
     * @type {{ [key: string]: string; }}
     * @memberof DynamicTagCategoryTableRowData
     */
    dynamicTagsById: { [key: string]: string; };
}

export function DynamicTagCategoryTableRowDataFromJSON(json: any): DynamicTagCategoryTableRowData {
    return DynamicTagCategoryTableRowDataFromJSONTyped(json, false);
}

export function DynamicTagCategoryTableRowDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DynamicTagCategoryTableRowData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdUserId': json['createdUserId'],
        'createdDt': json['createdDt'],
        'updatedUserId': json['updatedUserId'],
        'updatedDt': json['updatedDt'],
        'categoryId': json['categoryId'],
        'name': json['name'],
        'probabilityTablePaths': json['probabilityTablePaths'],
        'dynamicTagIds': !exists(json, 'dynamicTagIds') ? undefined : json['dynamicTagIds'],
        'datasets': ((json['datasets'] as Array<any>).map(BasicDescriptionFromJSON)),
        'dynamicTagsById': json['dynamicTagsById'],
    };
}

export function DynamicTagCategoryTableRowDataToJSON(value?: DynamicTagCategoryTableRowData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdUserId': value.createdUserId,
        'createdDt': value.createdDt,
        'updatedUserId': value.updatedUserId,
        'updatedDt': value.updatedDt,
        'categoryId': value.categoryId,
        'name': value.name,
        'probabilityTablePaths': value.probabilityTablePaths,
        'dynamicTagIds': value.dynamicTagIds,
        'datasets': ((value.datasets as Array<any>).map(BasicDescriptionToJSON)),
        'dynamicTagsById': value.dynamicTagsById,
    };
}

