/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const SearchType = {
    Visual: 'visual',
    Image: 'image',
    Video: 'video',
    Audio: 'audio'
} as const;
export type SearchType = typeof SearchType[keyof typeof SearchType];


export function SearchTypeFromJSON(json: any): SearchType {
    return SearchTypeFromJSONTyped(json, false);
}

export function SearchTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchType {
    return json as SearchType;
}

export function SearchTypeToJSON(value?: SearchType | null): any {
    return value as any;
}

