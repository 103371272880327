import React from 'react';
import FadeTransition from 'components/FadeTransition';
import createPlaceholderArray from 'utils/createPlaceholderArray';
import LoadingBlock from 'components/LoadingBlock';

interface ErrorLogsProps {
  show: boolean;
  logs: string | null | undefined;
}

const ErrorLogs: React.FC<ErrorLogsProps> = function ExecutedQueryView({
  show,
  logs,
}: ErrorLogsProps) {
  return (
    <FadeTransition appear show={show} exit={false}>
      <div className="my-2 p-3 whitespace-pre-line rounded-sm text-black bg-white font-mono text-xs">
        {logs ||
          createPlaceholderArray(16).map((key) => (
            <LoadingBlock
              key={key}
              style={{ width: `${Math.random() * 60 + 40}%` }}
              className="h-1.5 m-4"
            />
          ))}
      </div>
    </FadeTransition>
  );
};

export default ErrorLogs;
