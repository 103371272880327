/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextPrompt,
    TextPromptFromJSON,
    TextPromptFromJSONTyped,
    TextPromptToJSON,
} from './TextPrompt';
import {
    VisualPrompt,
    VisualPromptFromJSON,
    VisualPromptFromJSONTyped,
    VisualPromptToJSON,
} from './VisualPrompt';

/**
 * The base model for any single resource HTTP response
 * @export
 * @interface DynamicTagUI
 */
export interface DynamicTagUI {
    /**
     * The user that created the resource
     * @type {string}
     * @memberof DynamicTagUI
     */
    createdUserId: string;
    /**
     * The created datetime of the resource
     * @type {Date}
     * @memberof DynamicTagUI
     */
    createdDt: string;
    /**
     * The user that last updated the resource
     * @type {string}
     * @memberof DynamicTagUI
     */
    updatedUserId: string;
    /**
     * The datetime the resource was last updated
     * @type {Date}
     * @memberof DynamicTagUI
     */
    updatedDt: string;
    /**
     * The unique identifier of the dynamic tag
     * @type {string}
     * @memberof DynamicTagUI
     */
    dynamicTagId: string;
    /**
     * The name of the dynamic tag
     * @type {string}
     * @memberof DynamicTagUI
     */
    name: string;
    /**
     * The list of text prompts for this dynamic tag
     * @type {Array<TextPrompt>}
     * @memberof DynamicTagUI
     */
    textPrompts: Array<TextPrompt> | null;
    /**
     * The list of visual prompts for this dynamic tag
     * @type {Array<VisualPrompt>}
     * @memberof DynamicTagUI
     */
    visualPrompts: Array<VisualPrompt> | null;
    /**
     * Category name associated with tag
     * @type {string}
     * @memberof DynamicTagUI
     */
    categoryName: string;
    /**
     * Dataset ID associated with tag
     * @type {string}
     * @memberof DynamicTagUI
     */
    datasetId: string;
    /**
     * Dataset name associated with tag
     * @type {string}
     * @memberof DynamicTagUI
     */
    datasetName: string;
    /**
     * Can associated dataset(s) be audio searched
     * @type {boolean}
     * @memberof DynamicTagUI
     */
    areDatasetsAudioSearchable: boolean;
}

export function DynamicTagUIFromJSON(json: any): DynamicTagUI {
    return DynamicTagUIFromJSONTyped(json, false);
}

export function DynamicTagUIFromJSONTyped(json: any, ignoreDiscriminator: boolean): DynamicTagUI {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdUserId': json['createdUserId'],
        'createdDt': json['createdDt'],
        'updatedUserId': json['updatedUserId'],
        'updatedDt': json['updatedDt'],
        'dynamicTagId': json['dynamicTagId'],
        'name': json['name'],
        'textPrompts': (json['textPrompts'] === null ? null : (json['textPrompts'] as Array<any>).map(TextPromptFromJSON)),
        'visualPrompts': (json['visualPrompts'] === null ? null : (json['visualPrompts'] as Array<any>).map(VisualPromptFromJSON)),
        'categoryName': json['categoryName'],
        'datasetId': json['datasetId'],
        'datasetName': json['datasetName'],
        'areDatasetsAudioSearchable': json['areDatasetsAudioSearchable'],
    };
}

export function DynamicTagUIToJSON(value?: DynamicTagUI | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdUserId': value.createdUserId,
        'createdDt': value.createdDt,
        'updatedUserId': value.updatedUserId,
        'updatedDt': value.updatedDt,
        'dynamicTagId': value.dynamicTagId,
        'name': value.name,
        'textPrompts': (value.textPrompts === null ? null : (value.textPrompts as Array<any>).map(TextPromptToJSON)),
        'visualPrompts': (value.visualPrompts === null ? null : (value.visualPrompts as Array<any>).map(VisualPromptToJSON)),
        'categoryName': value.categoryName,
        'datasetId': value.datasetId,
        'datasetName': value.datasetName,
        'areDatasetsAudioSearchable': value.areDatasetsAudioSearchable,
    };
}

