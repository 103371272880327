import { XMarkIcon } from '@heroicons/react/24/solid';
import { AssetResponse } from 'api/generated';
import classNames from 'classnames';
import ImageGrid from 'components/ImageGrid';
import React, { useState } from 'react';
import AddVisualPrompts from './AddVisualPrompts';

interface VisualPromptsProps {
  visualPrompts: AssetResponse[];
  setVisualPrompts: (visualPrompts: AssetResponse[]) => void;
  setIsEditingPrompts: (isEditingPrompts: boolean) => void;
  datasetId: string;
  dataTestId?: string;
}
const VisualPrompts: React.FC<VisualPromptsProps> = ({
  visualPrompts,
  setVisualPrompts,
  setIsEditingPrompts,
  datasetId,
  dataTestId,
}: VisualPromptsProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleRemoveVisualPrompt = (imageId: string) => {
    setIsEditingPrompts(true);
    setVisualPrompts(
      visualPrompts.filter((prompt) => prompt.coactiveImageId !== imageId),
    );
  };

  const renderTileButton = (img: AssetResponse) => (
    <button
      onClick={() => handleRemoveVisualPrompt(img.coactiveImageId)}
      type="button"
      className="hover:scale-110 rounded-full flex justify-center items-center bg-black/40 m-1 p-0.5 "
    >
      <XMarkIcon className="h-4 w-4 text-white" />
    </button>
  );

  return (
    <>
      <AddVisualPrompts
        dataTestId={dataTestId}
        datasetId={datasetId}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        setVisualPrompts={setVisualPrompts}
        visualPrompts={visualPrompts}
        setIsEditingPrompts={setIsEditingPrompts}
      />
      <div className="mb-2 flex items-center justify-between">
        <h2 className="text-start text-lg font-semibold text-gray-800">
          Visual prompts
        </h2>
        {visualPrompts.length > 0 && (
          <p
            className="text-end"
            data-cy={
              dataTestId ? `${dataTestId}-visual-prompt-count` : undefined
            }
          >
            {visualPrompts.length}
          </p>
        )}
      </div>
      <div
        className={classNames(
          'flex flex-wrap h-full p-2 overflow-auto bg-white shadow-sm w-full sm:text-sm border border-gray-200 rounded-md',
        )}
      >
        <ImageGrid
          dataTestId={dataTestId}
          prefix={
            <button
              onClick={() => setIsModalOpen(true)}
              className="h-full font-medium w-full border rounded-md text-green-500 border-dashed border-green-400"
              type="button"
              data-cy={
                dataTestId
                  ? `${dataTestId}-open-search-modal-button`
                  : undefined
              }
            >
              <span className="text-lg">+</span> Add
            </button>
          }
          // TODO fix columnCount issue on smaller containers
          placeholders
          columnCount={6}
          type="uniform"
          images={visualPrompts}
          tileButton={renderTileButton}
        />
      </div>
    </>
  );
};

VisualPrompts.defaultProps = {
  dataTestId: undefined,
};

export default VisualPrompts;
