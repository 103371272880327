import { LabelApi } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useQuery from 'hooks/useQuery';

export const useGetCandidatesFromUploads = (
  conceptVersionId: string,
  filenames: string[],
) => {
  const { initializeAPI } = useAPIContext();
  const requestParams = {
    conceptVersionId,
    filenames: filenames!,
  };
  const query = useQuery(
    ['GetCandidatesFromUploads', conceptVersionId],
    async ({ signal }) => {
      const api = await initializeAPI<LabelApi>(LabelApi);
      return api.getCandidatesFromUploads(requestParams, { signal });
    },
    {
      enabled: Boolean(filenames.length),
      cacheTime: 0,
    },
  );
  return query;
};
