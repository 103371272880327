import React, { PropsWithChildren } from 'react';
import { ClipLoader } from 'react-spinners';
import colors from 'tailwindcss/colors';

interface ChartWrapperProps {
  title: string;
  isLoading?: boolean;
  subtitle?: string | React.ReactNode;
  titleIcon?: React.ReactNode;
}

const ChartWrapper: React.FunctionComponent<
  PropsWithChildren<ChartWrapperProps>
> = ({ children, isLoading, subtitle, title, titleIcon }) => (
  <>
    <div className="mb-8">
      <div className="flex flex-col gap-1">
        <div className="flex flex-row items-center gap-4">
          <h2 className="font-semibold text-xl text-gray-900">{title}</h2>
          {isLoading ? (
            <ClipLoader
              color={colors.emerald['500']}
              loading
              size={25}
              speedMultiplier={0.75}
            />
          ) : (
            titleIcon
          )}
        </div>
        {subtitle && <div className="text-gray-500 text-sm">{subtitle}</div>}
      </div>
    </div>
    {children}
  </>
);

ChartWrapper.defaultProps = {
  isLoading: false,
  subtitle: undefined,
  titleIcon: undefined,
};

export default ChartWrapper;
