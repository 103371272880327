import { RefObject, useEffect, useRef } from 'react';

function useMounted(): {
  mounted: RefObject<boolean>;
} {
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return { mounted };
}

export default useMounted;
