import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import './index.scss';
import { QueryStatus } from 'api/generated';

interface StopIconButtonProps {
  id: string;
  status: string; // todo: use enum type
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

const StopIconButton: React.FC<StopIconButtonProps> = function StopIconButton({
  id,
  status,
  onClick,
  className,
}: StopIconButtonProps) {
  return (
    <>
      <button
        type="button"
        data-tip
        data-for={`stopquery-${id}`}
        className={classNames(
          'stop-button-outer inline-flex items-center',
          className,
        )}
        onClick={onClick}
      >
        <div className="p-1">
          <div className="stop-button-inner h-[0.65rem] w-[0.65rem] justify-center rounded-[.05rem] bg-gray-500 outline outline-2 outline-offset-[.22rem] outline-gray-500 transition-all" />
        </div>
      </button>
      <Tooltip id={`stopquery-${id}`}>
        <span>
          {status === QueryStatus.Running ? 'Stop Query' : 'Cancel Query'}
        </span>
      </Tooltip>
    </>
  );
};

StopIconButton.defaultProps = {
  className: undefined,
};

export default StopIconButton;
