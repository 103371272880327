import React, { PropsWithChildren } from 'react';

/**
 * A <h2> element in the dataset creation flow.
 */
const Subtitle = function Subtitle({ children }: PropsWithChildren<{}>) {
  return <h2 className="text-lg font-medium">{children}</h2>;
};

export default Subtitle;
