/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import { LockClosedIcon, UserIcon } from '@heroicons/react/24/solid';
import { Permissions } from 'api/generated';
import Main from 'components/Main';
import PageHeader from 'components/PageHeader';
import RequireAuth from 'components/RequireAuth';
import VerticalNavigation, {
  NavigationItem,
} from 'components/VerticalNavigation';
import { useUserContext } from 'context/UserContext';
import Page404 from 'pages/Page404';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import Credentials from './credentials';
import Users from './users';

const items: NavigationItem[] = [
  {
    permissions: [Permissions.Readusers],
    title: 'Users',
    href: '/settings/users',
    icon: UserIcon,
  },
  {
    permissions: [Permissions.Createcredentials],
    title: 'Credentials',
    href: '/settings/credentials',
    icon: LockClosedIcon,
  },
];

const SettingsRouter = function SettingsRouter() {
  const { hasPermissions, isTrialUser } = useUserContext();
  const navigate = useNavigate();
  const baseSettingsPage = useMatch({ path: '/settings', end: true });
  useEffect(() => {
    if (baseSettingsPage) {
      const startingPage = items.find((item) =>
        hasPermissions(item.permissions),
      );
      if (startingPage) {
        navigate(startingPage.href);
      } else {
        navigate('/');
      }
    }
  }, [baseSettingsPage]);
  return (
    <Main>
      <PageHeader title={<h1>Settings</h1>} backButtonTo="" />
      <div className="max-w-8xl mx-auto flex flex-row gap-4">
        {!isTrialUser && (
          <div className="mt-6">
            <VerticalNavigation items={items} />
          </div>
        )}
        <div className="w-full min-w-0">
          <Routes>
            {!isTrialUser && (
              <Route
                path="/credentials"
                element={
                  <RequireAuth
                    requiredPermissions={[Permissions.Readcredentials]}
                  >
                    <Credentials />
                  </RequireAuth>
                }
              />
            )}
            <Route
              path="/users"
              element={
                <RequireAuth requiredPermissions={[Permissions.Readusers]}>
                  <Users />
                </RequireAuth>
              }
            />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </div>
      </div>
    </Main>
  );
};

export default SettingsRouter;
