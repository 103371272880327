/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DataSourceTypeEnum,
    DataSourceTypeEnumFromJSON,
    DataSourceTypeEnumFromJSONTyped,
    DataSourceTypeEnumToJSON,
} from './DataSourceTypeEnum';

/**
 * Base model for all schema
 * @export
 * @interface UpdateDatasetUpdateRequest
 */
export interface UpdateDatasetUpdateRequest {
    /**
     * 
     * @type {DataSourceTypeEnum}
     * @memberof UpdateDatasetUpdateRequest
     */
    dataSourceType?: DataSourceTypeEnum;
    /**
     * An unsupported data source identified by the user
     * @type {string}
     * @memberof UpdateDatasetUpdateRequest
     */
    unsupportedDataSource?: string | null;
    /**
     * Data source prefix to configure access
     * @type {string}
     * @memberof UpdateDatasetUpdateRequest
     */
    dataPath?: string | null;
    /**
     * User email to invite to help configure the dataset update
     * @type {string}
     * @memberof UpdateDatasetUpdateRequest
     */
    invitedUserEmail?: string | null;
    /**
     * The storage credentials id
     * @type {string}
     * @memberof UpdateDatasetUpdateRequest
     */
    credentialsId?: string | null;
}

export function UpdateDatasetUpdateRequestFromJSON(json: any): UpdateDatasetUpdateRequest {
    return UpdateDatasetUpdateRequestFromJSONTyped(json, false);
}

export function UpdateDatasetUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDatasetUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataSourceType': !exists(json, 'dataSourceType') ? undefined : DataSourceTypeEnumFromJSON(json['dataSourceType']),
        'unsupportedDataSource': !exists(json, 'unsupportedDataSource') ? undefined : json['unsupportedDataSource'],
        'dataPath': !exists(json, 'dataPath') ? undefined : json['dataPath'],
        'invitedUserEmail': !exists(json, 'invitedUserEmail') ? undefined : json['invitedUserEmail'],
        'credentialsId': !exists(json, 'credentialsId') ? undefined : json['credentialsId'],
    };
}

export function UpdateDatasetUpdateRequestToJSON(value?: UpdateDatasetUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataSourceType': DataSourceTypeEnumToJSON(value.dataSourceType),
        'unsupportedDataSource': value.unsupportedDataSource,
        'dataPath': value.dataPath,
        'invitedUserEmail': value.invitedUserEmail,
        'credentialsId': value.credentialsId,
    };
}

