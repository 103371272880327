import Button from 'components/Button';
import React from 'react';

interface SearchResultsHeaderProps {
  clearSearch: () => void;
  searchResultsCount?: number;
  action?: {
    text: string;
    execute: () => void;
  };
}

const SearchResultsHeader: React.FC<SearchResultsHeaderProps> =
  function TextSearchResults({ clearSearch, searchResultsCount, action }) {
    return (
      <div className="flex justify-between items-end my-8">
        <div>
          <h2
            data-cy="search-result-title"
            className="text-lg font-bold inline"
          >
            Search Results
          </h2>
          {searchResultsCount != null && (
            <>
              <span className="text-gray-400"> &#x2022; </span>
              <span className="text-sm text-gray-500 font-bold">
                Showing{' '}
                <span data-cy="search-results-count">
                  {searchResultsCount.toLocaleString()}
                </span>{' '}
                results
              </span>
            </>
          )}
        </div>
        <div>
          <Button
            dataTestId="search-results-clear"
            type="button"
            onClick={clearSearch}
            buttonStyle="secondary"
          >
            Clear
          </Button>
          {action && (
            <Button type="button" onClick={action.execute} className="ml-2">
              {action.text}
            </Button>
          )}
        </div>
      </div>
    );
  };

SearchResultsHeader.defaultProps = {
  searchResultsCount: undefined,
  action: undefined,
};

export default SearchResultsHeader;
