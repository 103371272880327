import {
  ChartBarIcon,
  ExclamationCircleIcon,
  PhotoIcon,
  TableCellsIcon,
} from '@heroicons/react/24/solid';
import { QueryResponseUI, QueryStatus, QueryTableRowUI } from 'api/generated';
import QueryResult from 'components/QueryResult';
import Tabs from 'components/Tabs';
import ExecutedQueryWorkspace from 'pages/queries/ExecutedQueryView/ExecutedQueryWorkspace';
import { Tab } from 'pages/queries/ExecutedQueryView/types';
import {
  Creator,
  useGetQueryWithoutResults,
  useGetQueryWithoutResultsQueryKey,
} from 'pages/queries/queries';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import '../CodeMirror.scss';
import { ACTIVE_QUERY_UPDATE_INTERVAL } from '../utils';

function getTabs(
  query: QueryResponseUI | undefined,
  hasImages: boolean,
  creator: Creator,
): any[] {
  const navigate = useNavigate();

  const tabs: any[] = [];
  const creatorParam = creator.toString();
  if (query?.status === QueryStatus.Error) {
    tabs.push({
      name: Tab.Error.toString(),
      action: () => {
        navigate(`/queries/${creatorParam}/${query?.queryId}/error`);
      },
      icon: ExclamationCircleIcon,
      color: 'red',
    });
  } else {
    tabs.push({
      name: Tab.Results.toString(),
      action: () => {
        navigate(`/queries/${creatorParam}/${query?.queryId}/results`);
      },
      icon: TableCellsIcon,
    });
    if (query?.status === QueryStatus.Complete && hasImages) {
      tabs.push({
        name: Tab.Content.toString(),
        icon: PhotoIcon,
        action: () => {
          navigate(
            `/queries/${creatorParam}/${creatorParam}/${query?.queryId}/content`,
          );
        },
      });
    }
    if (query?.status === QueryStatus.Complete) {
      tabs.push({
        name: Tab.Chart.toString(),
        action: () => {
          navigate(`/queries/${creatorParam}/${query?.queryId}/charts`);
        },
        icon: ChartBarIcon,
      });
    }
  }
  return tabs;
}

type SidebarData = QueryTableRowUI & { datasetId: string; embeddingId: string };

interface ExecutedQueryViewProps {
  queryId: string;
  setSidebarData: (data: SidebarData) => void;
  runQuery: (query: string, embeddingId: string) => void;
  creator: Creator;
  backToWorkspace?: () => void;
}

const ExecutedQueryView: React.FC<ExecutedQueryViewProps> =
  function ExecutedQueryView({
    queryId,
    setSidebarData,
    runQuery,
    backToWorkspace,
    creator,
  }: ExecutedQueryViewProps) {
    const params = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const {
      refetch,
      data: query,
      isLoading,
    } = useGetQueryWithoutResults(queryId || '', {
      enabled: true,
      onError: backToWorkspace,
      notifyOnChangeProps: ['data'],
    });
    const [embeddingId, setEmbeddingId] = useState<string | null>(null);
    const [hasImages, setHasImages] = useState(false);
    const [activeTab, setActiveTab] = useState<Tab>(Tab.Results);

    useEffect(() => {
      let interval;
      if (query?.isActive) {
        interval = setInterval(() => refetch(), ACTIVE_QUERY_UPDATE_INTERVAL);
      }
      return () => clearInterval(interval);
    }, [query?.isActive]);

    useEffect(
      () => () => {
        const queryKey = useGetQueryWithoutResultsQueryKey(queryId);
        // Cancel pending requests.
        queryClient.cancelQueries(queryKey);

        // Remove query data from the cache if it's still active so it's immediately
        // refetched.
        const queryData = queryClient.getQueryData<QueryResponseUI>(queryKey);
        if (queryId && queryData?.isActive) {
          queryClient.removeQueries(queryKey);
        }
      },
      [queryId],
    );

    useEffect(() => {
      const parts = params?.['*']?.split('/');
      const uuid = parts && parts.length > 1 ? parts[1] : undefined;
      const tabStr = parts && parts.length > 2 ? parts[2] : undefined;
      const tab = tabStr ? Tab.fromString(tabStr) : undefined;
      if (
        uuid &&
        query?.queryId === uuid &&
        query?.status === QueryStatus.Error
      ) {
        setActiveTab(Tab.Error);
        if (tab !== Tab.Error) {
          navigate(
            `/queries/${creator}/${uuid}/${Tab.Error.toLowerCaseString()}`,
          );
        }
      } else if (
        uuid &&
        query?.queryId === uuid &&
        query?.status !== QueryStatus.Error
      ) {
        if (!tab || tab === Tab.Error) {
          setActiveTab(Tab.Results);
          navigate(`${uuid}/${creator}/${Tab.Results.toLowerCaseString()}`);
        } else if (tab) {
          setActiveTab(tab);
        }
      }
    }, [query?.status, params, creator]);

    useEffect(() => {
      if (embeddingId !== query?.embedding.id) {
        setEmbeddingId(query?.embedding.id ?? null);
      }
    }, [query?.embedding.id]);

    const workspace = useMemo(
      () => (
        <ExecutedQueryWorkspace
          queryId={query?.queryId}
          datasetId={query?.dataset.id}
          embeddingId={embeddingId}
          query={query?.query}
          runQuery={query?.queryId ? runQuery : undefined}
        />
      ),
      [runQuery, query?.queryId, query?.dataset.id, embeddingId],
    );

    const setSidebarDataFn = useCallback(
      (data: QueryTableRowUI) => {
        setSidebarData({
          ...data,
          datasetId: query?.dataset.id!,
          embeddingId: embeddingId!,
        });
      },
      [setSidebarData, query?.dataset.id, embeddingId],
    );

    return (
      <>
        {workspace}
        <div className="pb-8">
          <Tabs
            tabs={getTabs(query, hasImages, creator)}
            onClick={(tab) => {
              navigate(`/queries/${creator}/${queryId}/${tab.toLowerCase()}`);
            }}
            active={activeTab.toString()}
            transition
          />
          <QueryResult
            queryId={queryId}
            query={query}
            isLoading={isLoading}
            refetch={refetch}
            setSidebarData={setSidebarDataFn}
            runQuery={runQuery}
            embeddingId={embeddingId}
            activeTab={activeTab}
            basePath="/:creator/:queryId/"
            updateIntervalMs={ACTIVE_QUERY_UPDATE_INTERVAL}
            setHasImages={setHasImages}
          />
        </div>
      </>
    );
  };

ExecutedQueryView.defaultProps = {
  backToWorkspace: undefined,
};

export default ExecutedQueryView;
