import RadioTabsGroup, {
  RadioTabsGroupOption,
} from 'components/RadioTabsGroup';
import ConfigureGsAccess from 'pages/datasets/add-assets/views/AccessView/ConfigureGsAccess';
import ConfigureS3Access from 'pages/datasets/add-assets/views/AccessView/ConfigureS3Access';

import { useAddAssetsActionsContext } from 'pages/datasets/add-assets/context/AddAssetsActionsContext';
import AddAssetsViewContainer from 'pages/datasets/components/AddAssetsViewContainer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useAddAssetsNavigationContext } from 'pages/datasets/add-assets/context/AddAssetsNavigationContext';
import TeammateCanAdd from 'pages/datasets/components/TeammateCanAdd';
import {
  useGetDatasetUpdate,
  useIsDatasetUpdateMutatingOrFetching,
} from 'queries/dataset-update';
import { useParams } from 'react-router-dom';
import { useIsDatasetMutatingOrFetching } from 'queries/datasets';
import { PersonToConfigure } from 'pages/datasets/add-assets/types';

/**
 * The main view of the "Access" step in the create dataset/add assets flow.
 */
const AccessView = function AccessView() {
  const { datasetUpdateId } = useParams();
  const { data: datasetUpdate } = useGetDatasetUpdate(datasetUpdateId);
  const {
    saveCurrentUsersProgress,
    inviteTeammateToContinue,
    error,
    accessError,
  } = useAddAssetsActionsContext();
  const { setNext } = useAddAssetsNavigationContext();
  const accessOptions: RadioTabsGroupOption<PersonToConfigure>[] = useMemo(
    () => [
      { name: 'I can grant access', value: 'me' },
      { name: 'My teammate can grant access', value: 'someone else' },
    ],
    [],
  );
  const [teammateEmail, setTeammateEmail] = useState<string | null | undefined>(
    datasetUpdate?.invitedUserEmail,
  );
  const [teammateEmailValidationError, setTeammateEmailValidationError] =
    useState<string>();
  const [credentialsId, setCredentialsId] = useState<string>();
  const [selectedPersonToConfigure, setSelectedPersonToConfigure] = useState<
    RadioTabsGroupOption<PersonToConfigure>
  >(accessOptions[0]);
  const [publicAccessConfigured, setPublicAccessConfigured] = useState(false);

  const createDataset = useCallback(() => {
    saveCurrentUsersProgress({
      credentialsId,
    });
  }, [saveCurrentUsersProgress, datasetUpdate?.dataPath, credentialsId]);
  const datasetUpdateLoading = useIsDatasetUpdateMutatingOrFetching();
  const datasetLoading = useIsDatasetMutatingOrFetching();

  const createDatasetDisabled =
    datasetUpdateLoading ||
    datasetLoading ||
    (!publicAccessConfigured && !credentialsId);

  useEffect(() => {
    if (selectedPersonToConfigure?.value === 'me') {
      setNext({
        disabled: createDatasetDisabled,
        action: createDataset,
        label: 'Create dataset',
        rightIcon: CheckIcon,
        loadingMessage: 'Checking access...',
      });
    } else {
      setNext({
        disabled: !teammateEmail || Boolean(teammateEmailValidationError),
        action: () => {
          if (teammateEmail) {
            inviteTeammateToContinue(teammateEmail);
          }
        },
      });
    }
  }, [
    createDataset,
    createDatasetDisabled,
    inviteTeammateToContinue,
    selectedPersonToConfigure?.value,
    setNext,
    teammateEmail,
    teammateEmailValidationError,
  ]);

  const isS3 =
    selectedPersonToConfigure?.value === 'me' &&
    datasetUpdate?.dataSourceType === 's3' &&
    datasetUpdate?.dataPath;
  const isGs =
    selectedPersonToConfigure?.value === 'me' &&
    datasetUpdate?.dataSourceType === 'gs' &&
    datasetUpdate?.dataPath;
  return (
    <AddAssetsViewContainer
      title={
        datasetUpdate?.dataSourceType === 's3'
          ? 'Grant access to your Amazon S3 dataset'
          : 'Grant access to your Google Services dataset'
      }
      description={
        <>
          {datasetUpdate?.dataSourceType === 's3' ? (
            <p>
              Before starting, make sure your data is stored in an Amazon S3
              bucket.
            </p>
          ) : (
            <p>
              Before starting, make sure your data is stored in a Google Storage
              bucket.
            </p>
          )}
          <p className="italic">
            Coactive will only access your data via a user access key
          </p>
        </>
      }
      isCreate
      error={error ?? accessError}
    >
      <div className="flex justify-center items-center py-6">
        <RadioTabsGroup
          options={accessOptions}
          selected={selectedPersonToConfigure}
          setSelected={setSelectedPersonToConfigure}
        />
      </div>
      {isS3 && (
        <ConfigureS3Access
          createDataset={createDataset}
          createDatasetDisabled={createDatasetDisabled}
          prefix={datasetUpdate.dataPath!}
          publicAccessConfigured={publicAccessConfigured}
          setCredentialsId={setCredentialsId}
          setPublicAccessConfigured={setPublicAccessConfigured}
        />
      )}
      {isGs && (
        <ConfigureGsAccess
          createDataset={createDataset}
          createDatasetDisabled={createDatasetDisabled}
          publicAccessConfigured={publicAccessConfigured}
          setCredentialsId={setCredentialsId}
          setPublicAccessConfigured={setPublicAccessConfigured}
        />
      )}
      {selectedPersonToConfigure?.value === 'someone else' && (
        <TeammateCanAdd
          setTeammateEmail={setTeammateEmail}
          emailValidationError={teammateEmailValidationError}
          setEmailValidationError={setTeammateEmailValidationError}
        />
      )}
    </AddAssetsViewContainer>
  );
};

export default AccessView;
