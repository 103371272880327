/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConceptLabelRequest,
    ConceptLabelRequestFromJSON,
    ConceptLabelRequestFromJSONTyped,
    ConceptLabelRequestToJSON,
} from './ConceptLabelRequest';

/**
 * Base model for all schema
 * @export
 * @interface UpdateConceptRequest
 */
export interface UpdateConceptRequest {
    /**
     * An optional replacement name for the concept
     * @type {string}
     * @memberof UpdateConceptRequest
     */
    name?: string | null;
    /**
     * An optional replacement description for the concept
     * @type {string}
     * @memberof UpdateConceptRequest
     */
    description?: string | null;
    /**
     * An optional replacement decision threshold for the concept
     * @type {number}
     * @memberof UpdateConceptRequest
     */
    threshold?: number | null;
    /**
     * Labels to create/update/delete
     * @type {Array<ConceptLabelRequest>}
     * @memberof UpdateConceptRequest
     */
    labels?: Array<ConceptLabelRequest> | null;
    /**
     * How strongly the model adapts to the training labels. Regularization strength. Lower value means the model fits more heavily to the training data 
     * @type {number}
     * @memberof UpdateConceptRequest
     */
    regularization?: number | null;
}

export function UpdateConceptRequestFromJSON(json: any): UpdateConceptRequest {
    return UpdateConceptRequestFromJSONTyped(json, false);
}

export function UpdateConceptRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateConceptRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'threshold': !exists(json, 'threshold') ? undefined : json['threshold'],
        'labels': !exists(json, 'labels') ? undefined : (json['labels'] === null ? null : (json['labels'] as Array<any>).map(ConceptLabelRequestFromJSON)),
        'regularization': !exists(json, 'regularization') ? undefined : json['regularization'],
    };
}

export function UpdateConceptRequestToJSON(value?: UpdateConceptRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'threshold': value.threshold,
        'labels': value.labels === undefined ? undefined : (value.labels === null ? null : (value.labels as Array<any>).map(ConceptLabelRequestToJSON)),
        'regularization': value.regularization,
    };
}

