/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetResponse,
    AssetResponseFromJSON,
    AssetResponseFromJSONTyped,
    AssetResponseToJSON,
} from './AssetResponse';

/**
 * A list of selected images or video keyframes to label to improve the accuracy of the
 * concept
 * @export
 * @interface CandidatesToLabelResponse
 */
export interface CandidatesToLabelResponse {
    /**
     * Images and video keyframes to label
     * @type {Array<AssetResponse>}
     * @memberof CandidatesToLabelResponse
     */
    data: Array<AssetResponse>;
}

export function CandidatesToLabelResponseFromJSON(json: any): CandidatesToLabelResponse {
    return CandidatesToLabelResponseFromJSONTyped(json, false);
}

export function CandidatesToLabelResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidatesToLabelResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(AssetResponseFromJSON)),
    };
}

export function CandidatesToLabelResponseToJSON(value?: CandidatesToLabelResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(AssetResponseToJSON)),
    };
}

