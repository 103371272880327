import classNames from 'classnames';
import React, { RefObject, useEffect, useMemo, useRef } from 'react';
import './index.scss';

interface ResizerProps {
  element: RefObject<HTMLElement | null | undefined>;
  type: 'bottom' | 'right' | 'left';
  onResize?: (size: number) => void;
}

const Resizer: React.FC<ResizerProps> = function Resizer({
  element,
  type,
  onResize,
}) {
  // The current position of mouse
  const x = useRef(0);
  const y = useRef(0);

  // The dimension of the element
  const w = useRef(0);
  const h = useRef(0);

  const mouseMoveHandler = useMemo(
    () => (e: MouseEvent) => {
      if (!element.current) return;

      // How far the mouse has been moved
      const dx = e.clientX - x.current;
      const dy = e.clientY - y.current;

      // Adjust the dimension of element
      const elem = element;
      if (type !== 'bottom' && elem.current) {
        elem.current.style.width = `${w.current + dx}px`;
      }
      if (type === 'bottom' && elem.current) {
        elem.current.style.height = `${h.current + dy}px`;
        onResize?.(h.current + dy);
      }
    },
    [],
  );

  const mouseUpHandler = useMemo(
    () => () => {
      // Remove the handlers of `mousemove` and `mouseup`
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    },
    [],
  );

  // Handle the mousedown event
  // that's triggered when user drags the resizer
  const mouseDownHandler = (e) => {
    if (!element.current) return;

    // Get the current mouse position
    x.current = e.clientX;
    y.current = e.clientY;

    // Calculate the dimension of element
    const styles = window.getComputedStyle(element.current);
    w.current = parseInt(styles.width, 10);
    h.current = parseInt(styles.height, 10);

    // Attach the listeners to `document`
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  useEffect(
    () => () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    },
    [],
  );

  return (
    <div
      aria-hidden="true"
      className={classNames(type === 'bottom' && 'resizer-b')}
      onMouseDown={mouseDownHandler}
    />
  );
};

Resizer.defaultProps = {
  onResize: undefined,
};

export default Resizer;
