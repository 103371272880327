import FileSaver from 'file-saver';

export const downloadAssetWithUrl = async (
  downloadUrl: string,
  fileName: string,
) => {
  if (!downloadUrl) {
    throw new Error('Download URL is missing');
  }

  try {
    const response = await fetch(downloadUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch the file: ${response.statusText}`);
    }

    const blob = await response.blob();
    FileSaver.saveAs(blob, fileName);
  } catch (error: any) {
    throw new Error(`Error fetching the file: ${error.message}`);
  }
};
