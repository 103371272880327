import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { QueryConceptDataUI } from 'api/generated';
import classNames from 'classnames';
import React from 'react';

const ConceptClassification: React.FC<{
  classification: QueryConceptDataUI;
  padding?: string;
}> = function ConceptClassification({ classification, padding }) {
  return (
    <div key={classification.id} className={classNames('text-sm', padding)}>
      <div className="flex items-center max-w-full justify-start">
        {classification.decision ? (
          <CheckCircleIcon className="h-5 w-5 fill-green-600 inline-block shrink-0" />
        ) : (
          <XCircleIcon className="h-5 w-5 fill-red-600 inline-block shrink-0" />
        )}
        <span className="pl-1 font-medium overflow-hidden text-ellipsis">
          {classification.name}
        </span>
      </div>
      <div className="h-2 bg-gray-300 w-full rounded-sm">
        <div
          className={classNames('h-full rounded-sm', {
            'bg-yellow-500':
              Math.abs(
                classification.probabilityScore -
                  (classification.threshold ?? 0.8),
              ) < 0.1,
            'bg-green-500':
              classification.decision &&
              Math.abs(
                classification.probabilityScore -
                  (classification.threshold ?? 0.8),
              ) >= 0.1,
            'bg-red-500':
              !classification.decision &&
              Math.abs(
                classification.probabilityScore -
                  (classification.threshold ?? 0.8),
              ) >= 0.1,
          })}
          style={{ width: `${classification.probabilityScore * 100}%` }}
        >
          <div />
        </div>
      </div>
    </div>
  );
};

ConceptClassification.defaultProps = {
  padding: undefined,
};

export default ConceptClassification;
