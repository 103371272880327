import React, { PropsWithChildren, useEffect } from 'react';
import FadeTransition from 'components/FadeTransition';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useAddAssetsNavigationContext } from 'pages/datasets/add-assets/context/AddAssetsNavigationContext';
import ToolbarButton from 'pages/datasets/components/ToolbarButton';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface PageProps {
  title: string;
  close?: () => void;
  rightColumn?: React.ReactElement;
}

const Page: React.FC<PropsWithChildren<PageProps>> = function Page({
  children,
  rightColumn,
  title,
}) {
  const { next } = useAddAssetsNavigationContext();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return ReactDOM.createPortal(
    <FadeTransition
      show
      appear
      className="relative pointer-events-auto h-screen overflow-auto"
    >
      <div className="top-0 right-0 left-0 bottom-0 min-h-full bg-white">
        <div className="relative py-8 font-medium text-center border-b-[1px] border-slate-200">
          <div className="absolute left-8 top-0 h-full flex items-center">
            <button
              type="button"
              onClick={() => {
                navigate(-1);
              }}
              className="rounded-md bg-gray-50 p-2"
            >
              <XMarkIcon className="fill-gray-500 h-5 w-5 [&>path]:stroke-[2]" />
            </button>
          </div>
          {title}
          <div className="absolute right-8 top-0 h-full flex items-center">
            {next && (
              <ToolbarButton
                label={next.label}
                action={next.action}
                loadingMessage={next.loadingMessage}
                rightIcon={next.rightIcon}
                leftIcon={next.leftIcon}
                disabled={next.disabled}
              />
            )}
          </div>
        </div>
        <div className="flex">
          <div className="mx-auto max-w-screen-lg w-full">{children}</div>
          {rightColumn && (
            <div className="border-l-[1px] border-slate-200">{rightColumn}</div>
          )}
        </div>
      </div>
    </FadeTransition>,
    document.getElementById('c-fullscreen-popup')!,
  );
};

export default Page;
