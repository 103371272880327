import {
  ArrowDownIcon,
  ArrowUpIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import {
  Concept,
  MetadataKey,
  MetadataType,
  NoCodeQuerySortItem,
  SortDirection,
} from 'api/generated';
import Button from 'components/Button';
import FilterSubjectDropdown from 'components/DatasetSearch/AdvancedSearch/FilterSubjectDropdown';
import Dropdown from 'components/Dropdown';
import React from 'react';

interface SortByRowProps {
  onChange: (data: Partial<NoCodeQuerySortItem> | undefined) => void;
  concepts: Concept[];
  metadataKeys: MetadataKey[];
  value: Partial<NoCodeQuerySortItem> | undefined;
  columnsLoading: boolean;
}

const SortByRow: React.FC<SortByRowProps> = function FilterRow({
  onChange,
  concepts,
  metadataKeys,
  value,
  columnsLoading,
}) {
  const defaultMetadataKeys =
    value?.field && !value?.conceptId
      ? [
          {
            key: value?.field,
            type: MetadataType.String,
          } as MetadataKey,
        ]
      : [];

  const defaultConcepts = value?.conceptId
    ? [
        {
          conceptId: value?.conceptId,
          name: value?.field ?? value?.conceptId,
        },
      ]
    : [];
  return (
    <div className="text-sm group flex w-full hover:bg-gray-100 py-2 px-4 items-center">
      <div className="grow flex items-center">
        <FilterSubjectDropdown
          selected={value?.conceptId ?? value?.field}
          onChange={({ concept, metadataKey }) => {
            onChange(
              concept || metadataKey
                ? {
                    field: concept?.name ?? metadataKey?.key,
                    direction: value?.direction,
                    conceptId: concept?.conceptId,
                  }
                : undefined,
            );
          }}
          concepts={concepts?.length ? concepts : defaultConcepts}
          metadataKeys={
            metadataKeys?.length ? metadataKeys : defaultMetadataKeys
          }
          placeholder="Sort by..."
          loading={columnsLoading}
        />
        <div className="ml-2 inline-block">
          <Dropdown
            options={[
              {
                label: 'Descending',
                value: 'desc',
                icon: { component: ArrowDownIcon },
              },
              {
                label: 'Ascending',
                value: 'asc',
                icon: { component: ArrowUpIcon },
              },
            ]}
            onChange={(direction) => {
              onChange({ ...value, direction: direction as SortDirection });
            }}
            selected={value?.direction}
            dropdownStyle="inline"
          />
        </div>
      </div>
      <div className="shrink-0 grow-0 hidden group-hover:flex items-center justify-center">
        <Button
          icon={XMarkIcon}
          onClick={() => onChange(undefined)}
          buttonStyle="link"
          padding="p-0"
          textColor="text-gray-500"
        />
      </div>
    </div>
  );
};

SortByRow.defaultProps = {};

export default SortByRow;
