import TextInput from 'components/TextInput';
import React, { ChangeEvent } from 'react';

interface OtherDataSourceInputProps {
  defaultValue: string | null | undefined;
  setOtherDataSource: (prefix: string) => void;
}

/**
 * The input component for other data sources not yet supported by Coactive.
 */
const OtherDataSourceInput: React.FC<OtherDataSourceInputProps> =
  function Other({ defaultValue, setOtherDataSource }) {
    return (
      <TextInput
        label="Where do you keep your data?"
        id="other-data-source"
        name="other data source"
        placeholder="Dropbox"
        description="It's okay if you do not know. Someone from Coactive will help you add your data."
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setOtherDataSource(e.target.value)
        }
        defaultValue={defaultValue ?? ''}
      />
    );
  };

export default OtherDataSourceInput;
