import classNames from 'classnames';
import FadeTransition from 'components/FadeTransition';
import React, { useEffect, useRef } from 'react';

export interface Tab {
  name: string;
  icon?: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>;
  color?: 'blue' | 'red';
}

interface PillTabsProps {
  tabs: Tab[];
  active: string;
  onClick: (name: string) => void;
  transition?: boolean;
  space?: string; // Tailwind "space-x-..."" class
  tabPadding?: string; // Tailwind "p-..." class
  disabled?: boolean;
  className?: string;
}

const PillTabs: React.FunctionComponent<PillTabsProps> = function PillTabs({
  active,
  tabs,
  onClick,
  transition,
  space,
  tabPadding,
  disabled,
  className,
}) {
  const isFirstMount = useRef(true);

  useEffect(() => {
    isFirstMount.current = false;
  });

  return (
    <div className={className}>
      <div className="inline-block max-w-full">
        <div className="">
          <nav
            className={classNames(
              'flex rounded-xl bg-gray-200 p-1',
              space ?? 'space-x-1',
            )}
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <FadeTransition
                appear={!isFirstMount.current && transition}
                show
                key={tab.name}
              >
                <button
                  type="button"
                  onClick={() => onClick(tab.name)}
                  className={classNames(
                    'group inline-flex rounded-lg text-sm font-medium leading-5',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                    tabPadding ?? 'py-2.5 px-4',
                    tab.name === active
                      ? 'bg-white shadow text-blue-600'
                      : 'text-gray-500 hover:bg-white/[0.12] hover:text-blue-500',
                  )}
                  aria-current={active === tab.name ? 'page' : undefined}
                  disabled={disabled}
                >
                  {tab.icon ? (
                    <tab.icon
                      className={classNames(
                        active === tab.name
                          ? [
                              tab.color !== 'red' ? 'text-blue-500' : undefined,
                              tab.color === 'red' ? 'text-red-500 ' : undefined,
                            ].filter((s) => Boolean(s))
                          : 'text-gray-500 group-hover:text-blue-500',
                        '-ml-0.5 mr-2 h-5 w-5',
                      )}
                      aria-hidden="true"
                    />
                  ) : undefined}
                  <span>{tab.name}</span>
                </button>
              </FadeTransition>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

PillTabs.defaultProps = {
  transition: false,
  space: undefined,
  tabPadding: undefined,
  disabled: false,
  className: undefined,
};

export default PillTabs;
