/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum to store encoder public names
 * @export
 */
export const EncoderNameEnum = {
    Multimodal: 'multimodal',
    MultimodalTxSmall1: 'multimodal-tx-small1',
    MultimodalTxSmall2: 'multimodal-tx-small2',
    MultimodalTxLarge1: 'multimodal-tx-large1',
    MultimodalTxLarge2: 'multimodal-tx-large2',
    MultimodalTxLarge3: 'multimodal-tx-large3'
} as const;
export type EncoderNameEnum = typeof EncoderNameEnum[keyof typeof EncoderNameEnum];


export function EncoderNameEnumFromJSON(json: any): EncoderNameEnum {
    return EncoderNameEnumFromJSONTyped(json, false);
}

export function EncoderNameEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): EncoderNameEnum {
    return json as EncoderNameEnum;
}

export function EncoderNameEnumToJSON(value?: EncoderNameEnum | null): any {
    return value as any;
}

