import React, { useState } from 'react';
import Dropdown from 'components/Dropdown';
import TextInput from 'components/TextInput';
import { MetadataData } from 'components/DatasetSearch/AdvancedSearch/types';
import { Comparator } from 'api/generated';
import {
  MetadataComparator,
  getLabelForComparator,
  getMetadataMatchOptions,
} from 'components/DatasetSearch/utils';
import DateTimePicker from 'components/DatasetSearch/AdvancedSearch/FilterRow/DateTimePicker';

const MetadataFilter: React.FC<{
  data: MetadataData[];
  onChange: (d: MetadataData[]) => void;
}> = function MetadataFilter({ data, onChange }) {
  const [error, setError] = useState<string>();
  const isDateRange = data.length > 1;
  return (
    <div className="inline space-x-2">
      <Dropdown
        selected={
          isDateRange ? MetadataComparator.InDateRange : data[0].comparator
        }
        onChange={(v) => {
          if (v === MetadataComparator.InDateRange) {
            onChange([
              { ...data[0], value: null, comparator: Comparator.GreaterThan },
              {
                key: data[0].key,
                value: null,
                type: data[0].type,
                comparator: Comparator.LessThan,
              },
            ]);
          } else {
            onChange([{ ...data[0], comparator: v }]);
          }
        }}
        options={getMetadataMatchOptions(data[0].type).map((t) => ({
          value: t,
          label: getLabelForComparator(t, data[0].type),
        }))}
        dropdownStyle="inline"
      />
      <div className="inline">
        {data[0].type === 'datetime' && (
          <DateTimePicker data={data ?? []} onChange={onChange} />
        )}
        {data[0].type !== 'datetime' && (
          <TextInput
            error={error}
            id={`${data[0].key}-metadata-matcher`}
            label=""
            name={`${data[0].key} metadata matcher`}
            value={data[0].value ?? ''}
            onChange={(e) => {
              const value = e.target.value.trim();
              if (data[0].type === 'number') {
                const isValidNumber = !Number.isNaN(parseFloat(value));
                setError(
                  value && !isValidNumber ? 'Invalid number' : undefined,
                );
                onChange([
                  { ...data[0], value: isValidNumber ? `${value}` : '' },
                ]);
              } else {
                onChange([{ ...data[0], value }]);
              }
            }}
            textInputStyle="inline"
          />
        )}
      </div>
    </div>
  );
};

export default MetadataFilter;
