import { Concept, MetadataKey } from 'api/generated';
import CategorizedDropdown, {
  CategorizedDropdownOptionGroup,
} from 'components/CategorizedDropdown';
import { FilterType } from 'components/DatasetSearch/AdvancedSearch/types';
import { MetadataFieldNames } from 'components/Metadata/MetadataSection';
import React, { useMemo } from 'react';
import { sortCaseInsensitive } from 'utils/ListUtils';

interface FilterSubjectDropdownProps {
  selected: string | undefined;
  onChange: (value: { concept?: Concept; metadataKey?: MetadataKey }) => void;
  concepts: Omit<Concept, 'threshold'>[];
  metadataKeys: MetadataKey[];
  loading: boolean;
  placeholder?: string;
}

const FilterSubjectDropdown: React.FC<FilterSubjectDropdownProps> =
  function FilterSubjectDropdown({
    selected,
    onChange,
    concepts,
    metadataKeys,
    placeholder,
    loading,
  }) {
    const sortedConcepts = useMemo(() => {
      const cs = [...concepts];
      cs.sort((c1, c2) =>
        c1.name.toUpperCase() < c2.name.toUpperCase() ? -1 : 1,
      );
      return cs;
    }, [concepts, selected]);

    const sortedMetadataKeys = useMemo(
      () => sortCaseInsensitive(metadataKeys, (mk) => mk.key.toUpperCase()),
      [metadataKeys],
    );

    const conceptsMap = sortedConcepts.reduce((acc, c) => {
      acc[c.conceptId] = c;
      return acc;
    }, {});

    const metadataMap = sortedMetadataKeys.reduce((acc, k) => {
      acc[k.key] = k;
      return acc;
    }, {});

    return (
      <CategorizedDropdown
        optionsGroups={
          [
            {
              value: 'Concept',
              label: 'Concept',
              options: sortedConcepts.map((c) => ({
                label: c.name,
                value: c.conceptId,
              })),
            },
            {
              value: 'Metadata',
              label: 'Metadata',
              options: sortedMetadataKeys.map((mk) => ({
                label: MetadataFieldNames[mk.key] || mk.key,
                value: mk.key,
              })),
            },
          ] as CategorizedDropdownOptionGroup<FilterType>[]
        }
        dropdownStyle="inline"
        onChange={(_: FilterType, id: string) => {
          const concept = conceptsMap[id];
          if (concept) {
            onChange({ concept });
            return;
          }
          const metadataKey = metadataMap[id];
          if (metadataKey) {
            onChange({ metadataKey });
          }
        }}
        selected={selected}
        defaultSelectedGroup="Concept"
        placeholder={placeholder}
        loading={loading}
      />
    );
  };

FilterSubjectDropdown.defaultProps = {
  placeholder: 'Select one',
};

export default FilterSubjectDropdown;
