/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicDescription,
    BasicDescriptionFromJSON,
    BasicDescriptionFromJSONTyped,
    BasicDescriptionToJSON,
} from './BasicDescription';

/**
 * A response object for creating a category
 * @export
 * @interface CreateCategoryResponse
 */
export interface CreateCategoryResponse {
    /**
     * The user that created the resource
     * @type {string}
     * @memberof CreateCategoryResponse
     */
    createdUserId: string;
    /**
     * The created datetime of the resource
     * @type {Date}
     * @memberof CreateCategoryResponse
     */
    createdDt: string;
    /**
     * The user that last updated the resource
     * @type {string}
     * @memberof CreateCategoryResponse
     */
    updatedUserId: string;
    /**
     * The datetime the resource was last updated
     * @type {Date}
     * @memberof CreateCategoryResponse
     */
    updatedDt: string;
    /**
     * The name of the category
     * @type {string}
     * @memberof CreateCategoryResponse
     */
    name: string;
    /**
     * The unique identifier for the category
     * @type {string}
     * @memberof CreateCategoryResponse
     */
    categoryId: string;
    /**
     * The dynamic tags within the category
     * @type {Array<BasicDescription>}
     * @memberof CreateCategoryResponse
     */
    dynamicTags?: Array<BasicDescription> | null;
}

export function CreateCategoryResponseFromJSON(json: any): CreateCategoryResponse {
    return CreateCategoryResponseFromJSONTyped(json, false);
}

export function CreateCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCategoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdUserId': json['createdUserId'],
        'createdDt': json['createdDt'],
        'updatedUserId': json['updatedUserId'],
        'updatedDt': json['updatedDt'],
        'name': json['name'],
        'categoryId': json['categoryId'],
        'dynamicTags': !exists(json, 'dynamicTags') ? undefined : (json['dynamicTags'] === null ? null : (json['dynamicTags'] as Array<any>).map(BasicDescriptionFromJSON)),
    };
}

export function CreateCategoryResponseToJSON(value?: CreateCategoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdUserId': value.createdUserId,
        'createdDt': value.createdDt,
        'updatedUserId': value.updatedUserId,
        'updatedDt': value.updatedDt,
        'name': value.name,
        'categoryId': value.categoryId,
        'dynamicTags': value.dynamicTags === undefined ? undefined : (value.dynamicTags === null ? null : (value.dynamicTags as Array<any>).map(BasicDescriptionToJSON)),
    };
}

