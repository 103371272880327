/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A UI request to create a dynamic tag table for a dataset
 * @export
 * @interface CreateDynamicTagRequest
 */
export interface CreateDynamicTagRequest {
    /**
     * name of the dynamic tag
     * @type {string}
     * @memberof CreateDynamicTagRequest
     */
    name: string;
    /**
     * data from frontend used to create text_prompts
     * @type {Array<string>}
     * @memberof CreateDynamicTagRequest
     */
    textPromptData?: Array<string> | null;
    /**
     * data from frontend used to create visual_prompts
     * @type {Array<{ [key: string]: string; }>}
     * @memberof CreateDynamicTagRequest
     */
    visualPromptData?: Array<{ [key: string]: string; }> | null;
}

export function CreateDynamicTagRequestFromJSON(json: any): CreateDynamicTagRequest {
    return CreateDynamicTagRequestFromJSONTyped(json, false);
}

export function CreateDynamicTagRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDynamicTagRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'textPromptData': !exists(json, 'textPromptData') ? undefined : json['textPromptData'],
        'visualPromptData': !exists(json, 'visualPromptData') ? undefined : json['visualPromptData'],
    };
}

export function CreateDynamicTagRequestToJSON(value?: CreateDynamicTagRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'textPromptData': value.textPromptData,
        'visualPromptData': value.visualPromptData,
    };
}

