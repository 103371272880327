import React from 'react';

interface BannerProps {
  expirationDate: string;
}

const Banner: React.FC<BannerProps> = function Banner({ expirationDate }) {
  return (
    <div className="bg-green-100 relative flex items-center gap-x-6 overflow-hidden px-6 py-2.5 z-0 h-auto">
      <div className="flex justify-center flex-1">
        <p className="text-sm leading-6 text-gray-900">
          You are on a private alpha trial of Coactive which ends{' '}
          {new Date(expirationDate).toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
          })}
          . Please help us by&nbsp;
          <a
            href="https://surveys.hotjar.com/f5614c75-0a68-4c4b-8e06-c122589e52a5"
            className="whitespace-nowrap font-semibold text-blue-600 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            sharing feedback<span aria-hidden="true">&rarr;</span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default Banner;
