import { AuthApi } from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';

export const useCreateSdkCredentials = () => {
  const { initializeAPI } = useAPIContext();

  return useMutation(['createSdkCredentials'], async () => {
    const api = await initializeAPI<AuthApi>(AuthApi);
    return api.createSdkCredentials();
  });
};

export const useGetSdkCredentials = () => {
  const { initializeAPI } = useAPIContext();
  return useQuery(['getSdkCredentials'], async ({ signal }) => {
    const api = await initializeAPI<AuthApi>(AuthApi);
    return api.getSdkCredentials({ signal });
  });
};
