/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A request to classify images for a the provided concept ids or all the concepts for the
 * dataset for which the image belongs.
 * @export
 * @interface ClassificationRequest
 */
export interface ClassificationRequest {
    /**
     * The coactive image id of the image to classify
     * @type {string}
     * @memberof ClassificationRequest
     */
    coactiveImageId: string;
    /**
     * Concept ids to use to classify an image
     * @type {Array<string>}
     * @memberof ClassificationRequest
     */
    conceptIds?: Array<string> | null;
}

export function ClassificationRequestFromJSON(json: any): ClassificationRequest {
    return ClassificationRequestFromJSONTyped(json, false);
}

export function ClassificationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassificationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coactiveImageId': json['coactiveImageId'],
        'conceptIds': !exists(json, 'conceptIds') ? undefined : json['conceptIds'],
    };
}

export function ClassificationRequestToJSON(value?: ClassificationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coactiveImageId': value.coactiveImageId,
        'conceptIds': value.conceptIds,
    };
}

