// Originally taken from https://codemirror.net/2/demo/formatting.html

import { EditorState, EditorView } from '@uiw/react-codemirror';
import {
  editorHasSelection,
  getSelectedText,
} from 'pages/queries/codemirror/utils';
import { format } from 'sql-formatter';

// import { EditorState, Extension } from "@uiw/react-codemirror";

//   CodeMirror.extendMode("css", {
//     commentStart: "/*",
//     commentEnd: "*/",
//     newlineAfterToken: function(type, content) {
//       return /^[;{}]$/.test(content);
//     }
//   });

//   const ext: Extension;

//   // Applies automatic formatting to the specified range
//   CodeMirror.defineExtension("autoFormatRange", function (from, to) {
//     var cm = this;
//     var outer = cm.getMode(), text = cm.getRange(from, to).split("\n");
//     var state = CodeMirror.copyState(outer, cm.getTokenAt(from).state);
//     var tabSize = cm.getOption("tabSize");

//     var out = "", lines = 0, atSol = from.ch == 0;
//     function newline() {
//       out += "\n";
//       atSol = true;
//       ++lines;
//     }

//     for (var i = 0; i < text.length; ++i) {
//       var stream = new CodeMirror.StringStream(text[i], tabSize);
//       while (!stream.eol()) {
//         var inner = CodeMirror.innerMode(outer, state);
//         var style = outer.token(stream, state), cur = stream.current();
//         stream.start = stream.pos;
//         if (!atSol || /\S/.test(cur)) {
//           out += cur;
//           atSol = false;
//         }
//         if (!atSol && inner.mode.newlineAfterToken &&
//             inner.mode.newlineAfterToken(style, cur, stream.string.slice(stream.pos) || text[i+1] || "", inner.state))
//           newline();
//       }
//       if (!stream.pos && outer.blankLine) outer.blankLine(state);
//       if (!atSol) newline();
//     }

//     cm.operation(function () {
//       cm.replaceRange(out, from, to);
//       for (var cur = from.line + 1, end = from.line + lines; cur <= end; ++cur)
//         cm.indentLine(cur, "smart");
//       cm.setSelection(from, cm.getCursor(false));
//     });
//   });
// }

// https://codemirror.net/6/examples/config/

// const autoLanguage = EditorState.transactionExtender.of((tr: Input) => {
//   if (!tr.docChanged) return null;
//   const docIsHTML = /^\s*</.test(tr.newDoc.sliceString(0, 100));
//   const stateIsHTML = tr.startState.facet(language) == htmlLanguage;
//   if (docIsHTML == stateIsHTML) return null;
//   return {
//     effects: {
//       from: 0,
//       to: 1,
//       insert: 'a',
//     },
//   };
// });

// defineExtension('getSearchCursor', function (query, pos, caseFold) {
//   return new SearchCursor(this.doc, query, pos, caseFold);
// });

// AutoFormat

export default function autoFormatSql(
  state: EditorState | null | undefined,
  view: EditorView | null | undefined,
) {
  if (!view || !state) {
    return;
  }
  if (!editorHasSelection(state)) {
    const val = state.doc.toString();
    const formattedText =
      val &&
      format(val, {
        keywordCase: 'upper',
        linesBetweenQueries: 2, // Defaults to 1
      });
    view.dispatch({
      changes: {
        from: 0,
        to: state.doc.length,
        insert: formattedText,
      },
    });
  } else if (view && state) {
    const selectedText = getSelectedText(state);
    const formattedText = format(selectedText);
    view.dispatch(state.replaceSelection(formattedText));
  }
}
