/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface CreateVersionRequest
 */
export interface CreateVersionRequest {
    /**
     * The id of the parent concept for the version
     * @type {string}
     * @memberof CreateVersionRequest
     */
    conceptId: string;
    /**
     * Name of the version
     * @type {string}
     * @memberof CreateVersionRequest
     */
    name: string;
    /**
     * Optional description of the version
     * @type {string}
     * @memberof CreateVersionRequest
     */
    description?: string | null;
}

export function CreateVersionRequestFromJSON(json: any): CreateVersionRequest {
    return CreateVersionRequestFromJSONTyped(json, false);
}

export function CreateVersionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateVersionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conceptId': json['conceptId'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function CreateVersionRequestToJSON(value?: CreateVersionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conceptId': value.conceptId,
        'name': value.name,
        'description': value.description,
    };
}

