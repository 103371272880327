import TextArea from 'components/TextArea';
import React, { useRef } from 'react';
import EditableTextBase from './EditableTextBase';

interface EditableTextViewProps {
  id: string;
  name: string;
  value: string | undefined | null;
  placeholder?: string;
  updateValue: (value: string) => Promise<void>;
  dataTestId?: string;
}

const EditableTextView: React.FC<EditableTextViewProps> =
  function EditableTextView({
    id,
    name,
    value,
    placeholder,
    updateValue: updateValueProp,
    dataTestId,
  }) {
    const textInputRef = useRef<HTMLTextAreaElement | null>();
    return (
      <EditableTextBase
        id={id}
        value={value}
        placeholder={placeholder}
        updateValue={updateValueProp}
        textInputRef={textInputRef}
        dataTestId={dataTestId}
        textInputElement={
          <TextArea
            ref={(ref) => {
              textInputRef.current = ref;
            }}
            defaultValue={value ?? undefined}
            label=""
            id={id}
            name={name}
            className="max-w-xl text-sm"
          />
        }
      />
    );
  };

EditableTextView.defaultProps = {
  placeholder: undefined,
  dataTestId: undefined,
};

export default EditableTextView;
