import { ArrowUpIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import SidebarContext from 'context/SidebarContext';
import React, { useContext, useEffect, useState } from 'react';

export const ScrollToTop = function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const { rightSidebarOpen } = useContext(SidebarContext);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div
      className={classNames('fixed bottom-3  transition-all', {
        'right-3': !rightSidebarOpen,
        'right-1/2 z-30': rightSidebarOpen,
      })}
    >
      <button
        type="button"
        onClick={scrollToTop}
        className={classNames(
          isVisible ? 'opacity-100' : 'opacity-0',
          'bg-green-600 hover:bg-green-700 focus:ring-green-500 inline-flex items-center rounded-full p-3 text-white shadow-sm transition-opacity focus:outline-none focus:ring-2 focus:ring-offset-2',
        )}
      >
        <ArrowUpIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  );
};
