/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The base model for any single resource HTTP response
 * @export
 * @interface VersionResponse
 */
export interface VersionResponse {
    /**
     * The user that created the resource
     * @type {string}
     * @memberof VersionResponse
     */
    createdUserId: string;
    /**
     * The created datetime of the resource
     * @type {Date}
     * @memberof VersionResponse
     */
    createdDt: string;
    /**
     * The user that last updated the resource
     * @type {string}
     * @memberof VersionResponse
     */
    updatedUserId: string;
    /**
     * The datetime the resource was last updated
     * @type {Date}
     * @memberof VersionResponse
     */
    updatedDt: string;
    /**
     * The id of the parent concept for the version
     * @type {string}
     * @memberof VersionResponse
     */
    conceptId: string;
    /**
     * Name of the version
     * @type {string}
     * @memberof VersionResponse
     */
    name: string;
    /**
     * Optional description of the version
     * @type {string}
     * @memberof VersionResponse
     */
    description?: string | null;
    /**
     * The id of the version
     * @type {string}
     * @memberof VersionResponse
     */
    versionId: string;
}

export function VersionResponseFromJSON(json: any): VersionResponse {
    return VersionResponseFromJSONTyped(json, false);
}

export function VersionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdUserId': json['createdUserId'],
        'createdDt': json['createdDt'],
        'updatedUserId': json['updatedUserId'],
        'updatedDt': json['updatedDt'],
        'conceptId': json['conceptId'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'versionId': json['versionId'],
    };
}

export function VersionResponseToJSON(value?: VersionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdUserId': value.createdUserId,
        'createdDt': value.createdDt,
        'updatedUserId': value.updatedUserId,
        'updatedDt': value.updatedDt,
        'conceptId': value.conceptId,
        'name': value.name,
        'description': value.description,
        'versionId': value.versionId,
    };
}

