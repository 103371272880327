/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ImageSizeEnum = {
    Full: 'full',
    Regular: 'regular',
    Small: 'small',
    Thumb: 'thumb'
} as const;
export type ImageSizeEnum = typeof ImageSizeEnum[keyof typeof ImageSizeEnum];


export function ImageSizeEnumFromJSON(json: any): ImageSizeEnum {
    return ImageSizeEnumFromJSONTyped(json, false);
}

export function ImageSizeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageSizeEnum {
    return json as ImageSizeEnum;
}

export function ImageSizeEnumToJSON(value?: ImageSizeEnum | null): any {
    return value as any;
}

