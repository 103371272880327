import React, { PropsWithChildren } from 'react';
import createPlaceholderArray from 'utils/createPlaceholderArray';
import './index.scss';

const LoadingText: React.FunctionComponent<PropsWithChildren> =
  function LoadingText({ children }) {
    return (
      <div className="loading">
        {children}
        {createPlaceholderArray(3).map((key) => (
          <span key={key} className={`ellipsis-${key}`}>
            <span className="inline-block h-[4px] w-[4px] bg-black rounded-full ml-[5px]" />
          </span>
        ))}
      </div>
    );
  };

export default LoadingText;
