import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { UploadResponse } from 'api/generated';
import FileUpload, { CompleteUpload } from 'components/FileUpload';
import { useUserContext } from 'context/UserContext';
import React, { useMemo, useState } from 'react';

interface CsvZipUploadProps {
  setCsvPath: (path: string) => void;
  // setZipPath: (path: string) => void;
}

const CsvZipUpload: React.FC<CsvZipUploadProps> = function CsvZipUpload({
  setCsvPath,
}) {
  const [uploadError, setUploadError] = useState<string>();
  const { isTrialUser } = useUserContext();
  // TODO: track zip path

  const fileUpload = useMemo(
    () => (
      <FileUpload
        uploadUrl={`${process.env.REACT_APP_API_HOST}/api/ui/datasets/upload/csv`}
        onUploadSuccess={(upload: CompleteUpload<UploadResponse>[]) => {
          if (upload[0].response.csvPath) {
            setCsvPath(upload[0].response.csvPath);
          }
        }}
        onUploadError={(upload) => {
          setUploadError(upload[0].response?.detail);
        }}
        acceptedTypes={['text/csv', 'application/csv']}
        error={uploadError}
        icon={PlusCircleIcon}
      >
        <p className="max-w-80 pointer-events-none text-md font-medium">
          <span className="text-green-500">Upload CSV file</span> or drag and
          drop
        </p>
        <>
          {isTrialUser ? (
            <p className="text-xs text-gray-500 mt-1">
              Up to 50,000 images or 50 minutes of video
            </p>
          ) : null}
          <p className="text-xs text-gray-500 my-1">
            This CSV must contain only public URLs
          </p>
        </>
      </FileUpload>
    ),
    [uploadError],
  );
  return fileUpload;
};

export default CsvZipUpload;
