import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { Permissions } from 'api/generated';
import { useUserContext } from 'context/UserContext';
import Page403 from 'pages/Page403';

interface RequireAuthProps {
  requiredPermissions?: Permissions[];
}
const RequireAuth: React.FC<PropsWithChildren<RequireAuthProps>> = ({
  children,
  requiredPermissions,
}) => {
  const { isAuthenticated } = useAuth0();
  const { hasPermissions } = useUserContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { state: { redirectUri: window.location.origin } });
    }
  }, [isAuthenticated]);
  const hasSufficientPermissions = useMemo(
    () => !requiredPermissions || hasPermissions(requiredPermissions),
    [requiredPermissions, hasPermissions],
  );
  if (!hasSufficientPermissions) {
    return <Page403 />;
  }
  return isAuthenticated ? (
    <div className="h-full w-full">{children}</div>
  ) : null;
};

RequireAuth.defaultProps = {
  requiredPermissions: undefined,
};

export default RequireAuth;
