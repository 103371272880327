import ImageGrid from 'components/ImageGrid';
import { useGetSimilarImages } from 'pages/similarity-search/queries';
import React, { useEffect, useState } from 'react';
import { AssetResponse } from 'api/generated';
import LoadingGrid from 'components/LoadingGrid';

interface SimilarImagesGridProps {
  datasetId: string | null;
  imageId: string | undefined;
  onClick?: (img: AssetResponse) => void;
}

const DEFAULT_PAGE_SIZE = 9;

const SimilarImagesGrid: React.FunctionComponent<SimilarImagesGridProps> =
  function SimilarImagesGrid({ datasetId, imageId, onClick }) {
    const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
    const shouldFetch = imageId != null;
    const { data: similarImages, isFetching } = useGetSimilarImages(
      {
        datasetId: datasetId!,
        coactiveImageId: imageId!,
      },
      pageSize,
      // initial value for page
      undefined,
      shouldFetch,
    );

    useEffect(() => {
      if (imageId) {
        setPageSize(DEFAULT_PAGE_SIZE);
      }
    }, [imageId]);

    return (
      <div className="border-t border-slate-200 mt-4 pt-4 pb-6">
        <div className="text-gray-700 text-lg font-medium mb-4">
          Related content
        </div>
        {!isFetching && similarImages ? (
          <ImageGrid
            columnCount={3}
            images={similarImages?.data}
            loadMoreButton={
              similarImages?.meta?.page?.currentPage !==
              similarImages?.meta?.page?.lastPage
            }
            onClick={onClick}
            type="uniform"
            fetchNextImages={() =>
              setPageSize((prevState) => prevState + DEFAULT_PAGE_SIZE)
            }
          />
        ) : (
          <LoadingGrid columnCount={3} tileCount={pageSize} />
        )}
      </div>
    );
  };

SimilarImagesGrid.defaultProps = {
  onClick: undefined,
};

export default SimilarImagesGrid;
