import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { AssetResponse } from 'api/generated';

export interface SidebarAssetContextState {
  selectedAsset: AssetResponse | undefined;
  setSelectedAsset: (asset: AssetResponse | undefined) => void;
}

const SidebarAssetContext = createContext({
  selectedAsset: undefined,
  setSelectedAsset: () => null,
} as SidebarAssetContextState);

export const SidebarAssetContextWrapper: React.FC<PropsWithChildren> =
  function SidebarAssetContextWrapper({ children }) {
    const [selectedAsset, setSelectedAsset] = useState<
      AssetResponse | undefined
    >(undefined);

    const value = useMemo(
      () => ({
        selectedAsset,
        setSelectedAsset,
      }),
      [selectedAsset],
    );

    return (
      <SidebarAssetContext.Provider value={value}>
        {children}
      </SidebarAssetContext.Provider>
    );
  };

export function useSidebarAssetContext() {
  return useContext(SidebarAssetContext);
}

export default SidebarAssetContext;
