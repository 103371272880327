export class Tab {
  static Chart = new Tab('Chart');

  static Error = new Tab('Error');

  static Content = new Tab('Content');

  static Results = new Tab('Results');

  private name: string;

  constructor(name) {
    this.name = name;
  }

  static fromString(str: string): Tab | undefined {
    switch (str.toLowerCase()) {
      case Tab.Chart.name.toLowerCase():
        return Tab.Chart;
      case Tab.Error.name.toLowerCase():
        return Tab.Error;
      case Tab.Content.name.toLowerCase():
        return Tab.Content;
      case Tab.Results.name.toLowerCase():
        return Tab.Results;
      default:
        return undefined;
    }
  }

  toString(): string {
    return this.name;
  }

  toLowerCaseString(): string {
    return this.name.toLowerCase();
  }
}
