import React from 'react';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';

const CheckIcon: React.FC<React.SVGProps<SVGSVGElement>> = function CheckIcon(
  props,
) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export interface RadioTabsGroupOption<T extends string> {
  value: T;
  name: string;
  description?: string;
}

interface RadioTabsGroupProps<T extends string> {
  options: RadioTabsGroupOption<T>[];
  selected: RadioTabsGroupOption<T> | undefined | null;
  setSelected: (selected: RadioTabsGroupOption<T>) => void;
  className?: string;
  gap?: string;
  showCheckIcon?: boolean;
}

const RadioTabsGroup = function RadioTabsGroup<T extends string>({
  className,
  gap,
  options,
  selected,
  setSelected,
  showCheckIcon,
}: RadioTabsGroupProps<T>) {
  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
      <div className={classNames('flex flex-wrap', gap, className)}>
        {options.map((option) => (
          <RadioGroup.Option
            key={option.value}
            value={option}
            className={({ active, checked }) =>
              `${
                active
                  ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-green-300'
                  : ''
              }
                  ${
                    checked
                      ? 'bg-green-100 text-green-800 border-none'
                      : 'bg-white border-gray-200 hover:bg-gray-50'
                  }
                    relative inline-flex cursor-pointer rounded-lg px-4 py-3 border focus:outline-none`
            }
          >
            {({ checked }) => (
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center">
                  <div className="text-sm">
                    <RadioGroup.Label
                      as="p"
                      className={`font-medium ${
                        checked ? 'text-green-800' : 'text-gray-900'
                      }`}
                    >
                      {option.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={`inline ${
                        checked ? 'text-green-100' : 'text-gray-500'
                      }`}
                    >
                      <span>{option.description}</span>
                    </RadioGroup.Description>
                  </div>
                </div>
                {showCheckIcon && checked && (
                  <div className="shrink-0 text-white">
                    <CheckIcon className="h-6 w-6" />
                  </div>
                )}
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

RadioTabsGroup.defaultProps = {
  className: undefined,
  showCheckIcon: false,
  gap: 'gap-x-3 gap-y-2',
};

export default RadioTabsGroup;
