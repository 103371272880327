/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ColumnDisplayType = {
    Default: 'default',
    Action: 'action',
    Image: 'image',
    RelativeTimestamp: 'relative_timestamp',
    Status: 'status',
    Secret: 'secret',
    Pill: 'pill',
    BadgeList: 'badge_list',
    ImageSetPreview: 'image_set_preview'
} as const;
export type ColumnDisplayType = typeof ColumnDisplayType[keyof typeof ColumnDisplayType];


export function ColumnDisplayTypeFromJSON(json: any): ColumnDisplayType {
    return ColumnDisplayTypeFromJSONTyped(json, false);
}

export function ColumnDisplayTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnDisplayType {
    return json as ColumnDisplayType;
}

export function ColumnDisplayTypeToJSON(value?: ColumnDisplayType | null): any {
    return value as any;
}

