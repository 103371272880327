/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface AuthUser
 */
export interface AuthUser {
    /**
     * datetime when user was created
     * @type {Date}
     * @memberof AuthUser
     */
    createdAt: string;
    /**
     * The email of the user
     * @type {string}
     * @memberof AuthUser
     */
    email?: string | null;
    /**
     * The verification status of the user email
     * @type {boolean}
     * @memberof AuthUser
     */
    emailVerified?: boolean | null;
    /**
     * The name of the user
     * @type {string}
     * @memberof AuthUser
     */
    name: string;
    /**
     * The nickname of the user
     * @type {string}
     * @memberof AuthUser
     */
    nickname: string;
    /**
     * The profile picture url of the user
     * @type {string}
     * @memberof AuthUser
     */
    picture: string;
    /**
     * The last updated date of the user
     * @type {Date}
     * @memberof AuthUser
     */
    updatedAt: string;
    /**
     * The user_id of the user to create
     * @type {string}
     * @memberof AuthUser
     */
    userId: string;
    /**
     * The last login time of the user
     * @type {Date}
     * @memberof AuthUser
     */
    lastLogin?: string | null;
    /**
     * List of the user's roles
     * @type {Array<string>}
     * @memberof AuthUser
     */
    roles?: Array<string> | null;
}

export function AuthUserFromJSON(json: any): AuthUser {
    return AuthUserFromJSONTyped(json, false);
}

export function AuthUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': json['createdAt'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'emailVerified': !exists(json, 'emailVerified') ? undefined : json['emailVerified'],
        'name': json['name'],
        'nickname': json['nickname'],
        'picture': json['picture'],
        'updatedAt': json['updatedAt'],
        'userId': json['userId'],
        'lastLogin': !exists(json, 'lastLogin') ? undefined : json['lastLogin'] === null ? null : json['lastLogin'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
    };
}

export function AuthUserToJSON(value?: AuthUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt,
        'email': value.email,
        'emailVerified': value.emailVerified,
        'name': value.name,
        'nickname': value.nickname,
        'picture': value.picture,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'lastLogin': value.lastLogin === undefined ? undefined : value.lastLogin === null ? null : value.lastLogin,
        'roles': value.roles,
    };
}

