/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A request to update the metadata a dataset
 * @export
 * @interface UpdateDatasetRequest
 */
export interface UpdateDatasetRequest {
    /**
     * New name for the dataset
     * @type {string}
     * @memberof UpdateDatasetRequest
     */
    name?: string | null;
    /**
     * New description for the dataset
     * @type {string}
     * @memberof UpdateDatasetRequest
     */
    description?: string | null;
}

export function UpdateDatasetRequestFromJSON(json: any): UpdateDatasetRequest {
    return UpdateDatasetRequestFromJSONTyped(json, false);
}

export function UpdateDatasetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDatasetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function UpdateDatasetRequestToJSON(value?: UpdateDatasetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
    };
}

