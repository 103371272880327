import TextInput from 'components/TextInput';
import { useUserContext } from 'context/UserContext';
import Subtitle from 'pages/datasets/components/Subtitle';
import { useGetDatasetById } from 'queries/datasets';
import React, { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

interface TeammateCanAddProps {
  emailValidationError: string | undefined;
  setEmailValidationError: (message: string | undefined) => void;
  setTeammateEmail: (email: string) => void;
}

/**
 * The component inviting a teammate to help create the dataset.
 */
const TeammateCanAdd = function TeammateCanAdd({
  emailValidationError,
  setEmailValidationError,
  setTeammateEmail,
}: TeammateCanAddProps) {
  const { datasetId } = useParams();
  const { data: dataset } = useGetDatasetById(datasetId);
  const { auth0User } = useUserContext();

  const inputRef = useRef<HTMLInputElement>();

  // Focus the input on mount for accessibility
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const validateEmail = useCallback(
    (value: string) => {
      const regex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const invalidFormatMessage =
        !value || regex.test(value)
          ? undefined
          : 'The provided email is not valid';
      const isCurrentUserEmailMessage =
        value && value === auth0User?.email
          ? 'You cannot invite yourself'
          : undefined;
      setEmailValidationError(
        invalidFormatMessage ?? isCurrentUserEmailMessage,
      );
      return Promise.resolve({
        valid: {
          value: !invalidFormatMessage && !isCurrentUserEmailMessage,
          message: invalidFormatMessage ?? isCurrentUserEmailMessage,
        },
        unique: { value: true },
      });
    },
    [setEmailValidationError],
  );

  return (
    <div className="pt-8 space-y-8">
      <div>
        <Subtitle>Invite your teammate to complete {dataset?.name}</Subtitle>
        <p className="text-sm">
          We&apos;ll provide your dataset name, description, and an overview of
          Coactive. Your teammate will not receive promotional emails from
          Coactive.
        </p>
      </div>
      <div className="max-w-md">
        <TextInput
          ref={inputRef}
          label="Teammate's email"
          id="teammates-email"
          name="teammate's email"
          placeholder="you@example.com"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setTeammateEmail(e.target.value)
          }
          validation={validateEmail}
          executeValidationOnBlur
          error={emailValidationError}
        />
      </div>
    </div>
  );
};

export default TeammateCanAdd;
