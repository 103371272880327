/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetResponse,
    AssetResponseFromJSON,
    AssetResponseFromJSONTyped,
    AssetResponseToJSON,
} from './AssetResponse';

/**
 * Base model for all schema
 * @export
 * @interface SimilarLabelCandidatesResponse
 */
export interface SimilarLabelCandidatesResponse {
    /**
     * Similar images (if the request contains an image file)
     * @type {Array<AssetResponse>}
     * @memberof SimilarLabelCandidatesResponse
     */
    similarImages?: Array<AssetResponse> | null;
    /**
     * The paths the images were uploaded to (if the request contains image files)
     * @type {Array<string>}
     * @memberof SimilarLabelCandidatesResponse
     */
    imagePaths?: Array<string> | null;
}

export function SimilarLabelCandidatesResponseFromJSON(json: any): SimilarLabelCandidatesResponse {
    return SimilarLabelCandidatesResponseFromJSONTyped(json, false);
}

export function SimilarLabelCandidatesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilarLabelCandidatesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'similarImages': !exists(json, 'similarImages') ? undefined : (json['similarImages'] === null ? null : (json['similarImages'] as Array<any>).map(AssetResponseFromJSON)),
        'imagePaths': !exists(json, 'imagePaths') ? undefined : json['imagePaths'],
    };
}

export function SimilarLabelCandidatesResponseToJSON(value?: SimilarLabelCandidatesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'similarImages': value.similarImages === undefined ? undefined : (value.similarImages === null ? null : (value.similarImages as Array<any>).map(AssetResponseToJSON)),
        'imagePaths': value.imagePaths,
    };
}

