import classNames from 'classnames';
import React from 'react';

interface TextDividerProps {
  text: string;
  margin?: string;
}

const TextDivider = function TextDivider({ text, margin }: TextDividerProps) {
  return (
    <div className={classNames('flex items-center', margin)}>
      <div className="h-[1px] bg-gray-200 grow" />
      <div className="grow-0 px-4 text-slate-500 font-medium">{text}</div>
      <div className="h-[1px] bg-gray-200 grow" />
    </div>
  );
};

TextDivider.defaultProps = {
  margin: undefined,
};

export default TextDivider;
