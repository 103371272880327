import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

export interface SensitiveImageContextState {
  getImageRevealed: (coactiveImageId: string) => boolean;
  setImageRevealed: (coactiveImageId: string, revealed: boolean) => void;
}
const SensitiveImageContext = createContext({
  getImageRevealed: () => false,
  setImageRevealed: () => null,
} as SensitiveImageContextState);

export const SensitiveImageContextWrapper: React.FC<PropsWithChildren> =
  function SensitiveImageContextWrapper({ children }) {
    const [imagesRevealed, setImagesRevealed] = useState({});
    const location = useLocation();

    useEffect(() => {
      setImagesRevealed({});
    }, [location.pathname]);

    const value = useMemo(
      () => ({
        getImageRevealed: (coactiveImageId: string) =>
          imagesRevealed[coactiveImageId],
        setImageRevealed: (coactiveImageId: string, revealed: boolean) => {
          setImagesRevealed({ ...imagesRevealed, [coactiveImageId]: revealed });
        },
      }),
      [imagesRevealed],
    );

    return (
      <SensitiveImageContext.Provider value={value}>
        {children}
      </SensitiveImageContext.Provider>
    );
  };

export function useSensitiveImageContext() {
  return useContext(SensitiveImageContext);
}

export default SensitiveImageContext;
