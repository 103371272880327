/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateDatasetUpdateRequest,
  DatasetUpdateResponse,
  HTTPValidationError,
  ResendDatasetUpdateInvitationsRequest,
  UpdateDatasetUpdateRequest,
  UpdateDatasetUpdateResponse,
} from '../models';
import {
    CreateDatasetUpdateRequestFromJSON,
    CreateDatasetUpdateRequestToJSON,
    DatasetUpdateResponseFromJSON,
    DatasetUpdateResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    ResendDatasetUpdateInvitationsRequestFromJSON,
    ResendDatasetUpdateInvitationsRequestToJSON,
    UpdateDatasetUpdateRequestFromJSON,
    UpdateDatasetUpdateRequestToJSON,
    UpdateDatasetUpdateResponseFromJSON,
    UpdateDatasetUpdateResponseToJSON,
} from '../models';

export interface CreateDatasetUpdateOperationRequest {
    createDatasetUpdateRequest: CreateDatasetUpdateRequest;
}

export interface DeleteDatasetUpdateRequest {
    datasetUpdateId: string;
}

export interface GetDatasetUpdateRequest {
    datasetUpdateId: string;
}

export interface GetDatasetUpdatesRequest {
    datasetId: string;
}

export interface ResendDatasetUpdateInvitationsOperationRequest {
    resendDatasetUpdateInvitationsRequest: ResendDatasetUpdateInvitationsRequest;
}

export interface UpdateDatasetUpdateOperationRequest {
    datasetUpdateId: string;
    updateDatasetUpdateRequest: UpdateDatasetUpdateRequest;
}

/**
 * DatasetUpdatesApi - interface
 * 
 * @export
 * @interface DatasetUpdatesApiInterface
 */
export interface DatasetUpdatesApiInterface {
    /**
     * Create a request for another user to configure access to a data source
     * @summary Create data access action for another user
     * @param {CreateDatasetUpdateRequest} createDatasetUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetUpdatesApiInterface
     */
    createDatasetUpdateRaw(requestParameters: CreateDatasetUpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateDatasetUpdateResponse>>;

    /**
     * Create a request for another user to configure access to a data source
     * Create data access action for another user
     */
    createDatasetUpdate(requestParameters: CreateDatasetUpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateDatasetUpdateResponse>;

    /**
     * Delete a dataset update
     * @summary Delete a dataset update
     * @param {string} datasetUpdateId Dataset update id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetUpdatesApiInterface
     */
    deleteDatasetUpdateRaw(requestParameters: DeleteDatasetUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Delete a dataset update
     * Delete a dataset update
     */
    deleteDatasetUpdate(requestParameters: DeleteDatasetUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Get dataset update
     * @summary Get dataset update
     * @param {string} datasetUpdateId The dataset update id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetUpdatesApiInterface
     */
    getDatasetUpdateRaw(requestParameters: GetDatasetUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetUpdateResponse>>;

    /**
     * Get dataset update
     * Get dataset update
     */
    getDatasetUpdate(requestParameters: GetDatasetUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetUpdateResponse>;

    /**
     * Get all incomplete updates for a dataset
     * @summary Get all updates for a dataset
     * @param {string} datasetId The dataset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetUpdatesApiInterface
     */
    getDatasetUpdatesRaw(requestParameters: GetDatasetUpdatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetUpdateResponse>>>;

    /**
     * Get all incomplete updates for a dataset
     * Get all updates for a dataset
     */
    getDatasetUpdates(requestParameters: GetDatasetUpdatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetUpdateResponse>>;

    /**
     * Resend invitations for dataset updates
     * @summary Resend invitations for dataset updates
     * @param {ResendDatasetUpdateInvitationsRequest} resendDatasetUpdateInvitationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetUpdatesApiInterface
     */
    resendDatasetUpdateInvitationsRaw(requestParameters: ResendDatasetUpdateInvitationsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Resend invitations for dataset updates
     * Resend invitations for dataset updates
     */
    resendDatasetUpdateInvitations(requestParameters: ResendDatasetUpdateInvitationsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Update a dataset update
     * @summary Update a dataset update
     * @param {string} datasetUpdateId Dataset update id
     * @param {UpdateDatasetUpdateRequest} updateDatasetUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetUpdatesApiInterface
     */
    updateDatasetUpdateRaw(requestParameters: UpdateDatasetUpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateDatasetUpdateResponse>>;

    /**
     * Update a dataset update
     * Update a dataset update
     */
    updateDatasetUpdate(requestParameters: UpdateDatasetUpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateDatasetUpdateResponse>;

}

/**
 * 
 */
export class DatasetUpdatesApi extends runtime.BaseAPI implements DatasetUpdatesApiInterface {

    /**
     * Create a request for another user to configure access to a data source
     * Create data access action for another user
     */
    async createDatasetUpdateRaw(requestParameters: CreateDatasetUpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateDatasetUpdateResponse>> {
        if (requestParameters.createDatasetUpdateRequest === null || requestParameters.createDatasetUpdateRequest === undefined) {
            throw new runtime.RequiredError('createDatasetUpdateRequest','Required parameter requestParameters.createDatasetUpdateRequest was null or undefined when calling createDatasetUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dataset-updates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDatasetUpdateRequestToJSON(requestParameters.createDatasetUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateDatasetUpdateResponseFromJSON(jsonValue));
    }

    /**
     * Create a request for another user to configure access to a data source
     * Create data access action for another user
     */
    async createDatasetUpdate(requestParameters: CreateDatasetUpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateDatasetUpdateResponse> {
        const response = await this.createDatasetUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a dataset update
     * Delete a dataset update
     */
    async deleteDatasetUpdateRaw(requestParameters: DeleteDatasetUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.datasetUpdateId === null || requestParameters.datasetUpdateId === undefined) {
            throw new runtime.RequiredError('datasetUpdateId','Required parameter requestParameters.datasetUpdateId was null or undefined when calling deleteDatasetUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dataset-updates/{dataset_update_id}`.replace(`{${"dataset_update_id"}}`, encodeURIComponent(String(requestParameters.datasetUpdateId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete a dataset update
     * Delete a dataset update
     */
    async deleteDatasetUpdate(requestParameters: DeleteDatasetUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteDatasetUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get dataset update
     * Get dataset update
     */
    async getDatasetUpdateRaw(requestParameters: GetDatasetUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetUpdateResponse>> {
        if (requestParameters.datasetUpdateId === null || requestParameters.datasetUpdateId === undefined) {
            throw new runtime.RequiredError('datasetUpdateId','Required parameter requestParameters.datasetUpdateId was null or undefined when calling getDatasetUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dataset-updates/{dataset_update_id}`.replace(`{${"dataset_update_id"}}`, encodeURIComponent(String(requestParameters.datasetUpdateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatasetUpdateResponseFromJSON(jsonValue));
    }

    /**
     * Get dataset update
     * Get dataset update
     */
    async getDatasetUpdate(requestParameters: GetDatasetUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetUpdateResponse> {
        const response = await this.getDatasetUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all incomplete updates for a dataset
     * Get all updates for a dataset
     */
    async getDatasetUpdatesRaw(requestParameters: GetDatasetUpdatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetUpdateResponse>>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling getDatasetUpdates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/datasets/{dataset_id}/dataset-updates`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DatasetUpdateResponseFromJSON));
    }

    /**
     * Get all incomplete updates for a dataset
     * Get all updates for a dataset
     */
    async getDatasetUpdates(requestParameters: GetDatasetUpdatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetUpdateResponse>> {
        const response = await this.getDatasetUpdatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend invitations for dataset updates
     * Resend invitations for dataset updates
     */
    async resendDatasetUpdateInvitationsRaw(requestParameters: ResendDatasetUpdateInvitationsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.resendDatasetUpdateInvitationsRequest === null || requestParameters.resendDatasetUpdateInvitationsRequest === undefined) {
            throw new runtime.RequiredError('resendDatasetUpdateInvitationsRequest','Required parameter requestParameters.resendDatasetUpdateInvitationsRequest was null or undefined when calling resendDatasetUpdateInvitations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dataset-updates/resend-invitations`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ResendDatasetUpdateInvitationsRequestToJSON(requestParameters.resendDatasetUpdateInvitationsRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Resend invitations for dataset updates
     * Resend invitations for dataset updates
     */
    async resendDatasetUpdateInvitations(requestParameters: ResendDatasetUpdateInvitationsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.resendDatasetUpdateInvitationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a dataset update
     * Update a dataset update
     */
    async updateDatasetUpdateRaw(requestParameters: UpdateDatasetUpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateDatasetUpdateResponse>> {
        if (requestParameters.datasetUpdateId === null || requestParameters.datasetUpdateId === undefined) {
            throw new runtime.RequiredError('datasetUpdateId','Required parameter requestParameters.datasetUpdateId was null or undefined when calling updateDatasetUpdate.');
        }

        if (requestParameters.updateDatasetUpdateRequest === null || requestParameters.updateDatasetUpdateRequest === undefined) {
            throw new runtime.RequiredError('updateDatasetUpdateRequest','Required parameter requestParameters.updateDatasetUpdateRequest was null or undefined when calling updateDatasetUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/dataset-updates/{dataset_update_id}`.replace(`{${"dataset_update_id"}}`, encodeURIComponent(String(requestParameters.datasetUpdateId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDatasetUpdateRequestToJSON(requestParameters.updateDatasetUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateDatasetUpdateResponseFromJSON(jsonValue));
    }

    /**
     * Update a dataset update
     * Update a dataset update
     */
    async updateDatasetUpdate(requestParameters: UpdateDatasetUpdateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateDatasetUpdateResponse> {
        const response = await this.updateDatasetUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
