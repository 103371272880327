import { AssetResponse, AssetType } from 'api/generated';
import { SearchType } from 'api/generated/models/SearchType';
import { SelectedOption } from 'components/Combobox';
import { SearchMode } from 'components/DatasetSearch/AdvancedSearch/types';
import TextSearchResults from 'components/DatasetSearch/DatasetTextSearch/TextSearchResults';
import { DropdownOption } from 'components/Dropdown';
import TextSearch, { AssetTypeOption } from 'components/TextSearch';
import {
  getSearchImagesQueryKey,
  useSearchImagesQuery,
} from 'pages/datasets/detail/components/queries';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { MetadataFilter } from 'types/metadata';

function convertDropdownOptionToAssetType(
  option: AssetTypeOption | undefined,
): AssetType | undefined {
  if (option === 'Images') return AssetType.Image;
  if (option === 'Videos') return AssetType.Video;
  if (option === 'All content') return undefined;
  return undefined;
}

interface DatasetTextSearchProps {
  datasetId: string;
  onClick?: (img: AssetResponse) => void;
  defaultAssetType?: AssetTypeOption;
  setSearchActive?: (active: boolean) => void;
  labelResults?: (positive: string[], negative: string[]) => void;
  isLabelingResults?: boolean;
  hideHeader?: boolean;
  searchType: SearchType;
  searchMode: SearchMode;
  setSearchMode: (searchMode: SearchMode) => void;
  searchModeOptions: DropdownOption<SearchMode>[];
  searchContainerClassName?: string;
  setIsUsingSearchEndpoint?: (isUsingSearchEndpoint: boolean) => void;
  setDatasetViewAssetType?: (datasetViewAssetType: AssetTypeOption) => void;
  isUsingSearchEndpoint?: boolean;
  datasetViewAssetType?: AssetTypeOption;
  searchActive?: boolean;
  hasToggleGridResults?: boolean;
  disabledResults?: AssetResponse[];
  disabledResultMessage?: string;
  dataTestId?: string;
}

const DatasetTextSearch: React.FC<DatasetTextSearchProps> =
  function DatasetTextSearch({
    datasetId,
    onClick,
    defaultAssetType,
    setSearchActive,
    labelResults,
    isLabelingResults,
    hideHeader,
    searchType,
    searchMode,
    setSearchMode,
    searchModeOptions,
    searchContainerClassName,
    setIsUsingSearchEndpoint,
    setDatasetViewAssetType,
    isUsingSearchEndpoint,
    datasetViewAssetType,
    searchActive,
    hasToggleGridResults,
    disabledResultMessage,
    disabledResults,
    dataTestId,
  }) {
    const [textQuery, setTextQuery] = useState('');
    const [shouldRefetchSearch, setShouldRefetchSearch] =
      useState<boolean>(false);
    const queryClient = useQueryClient();
    const [selectedConcepts, setSelectedConcepts] = useState<SelectedOption[]>(
      [],
    );
    const [metadataFilters, setMetadataFilters] = useState<MetadataFilter[]>(
      [],
    );
    const selectedConceptIds = useMemo(
      () => selectedConcepts.map((concept) => concept.value),
      [selectedConcepts],
    );
    const [assetType, setAssetType] = useState<AssetTypeOption | undefined>(
      defaultAssetType,
    );
    const { data, refetch, isLoading, isRefetching, isError } =
      useSearchImagesQuery(
        textQuery,
        datasetId,
        searchType,
        convertDropdownOptionToAssetType(assetType) ||
          convertDropdownOptionToAssetType(datasetViewAssetType),
        selectedConceptIds.length > 0 ? selectedConceptIds : undefined,
        metadataFilters.length > 0
          ? JSON.stringify(metadataFilters)
          : undefined,
      );
    const clearSearch = useCallback(() => {
      setTextQuery('');
      setSelectedConcepts([]);
      setMetadataFilters([]);
      queryClient.resetQueries(getSearchImagesQueryKey(datasetId));
    }, [datasetId]);

    useEffect(() => {
      if (shouldRefetchSearch) {
        refetch();
        setShouldRefetchSearch(false);
      }
    }, [shouldRefetchSearch]);

    return (
      <>
        <TextSearch
          datasetId={datasetId}
          assetType={assetType}
          setAssetType={setAssetType}
          setSearchActive={setSearchActive}
          error={
            isError
              ? 'An error occurred while executing the search. Try again.'
              : undefined
          }
          searchMode={searchMode}
          setSearchMode={setSearchMode}
          searchModeOptions={searchModeOptions}
          searchContainerClassName={searchContainerClassName}
          executeSearch={setShouldRefetchSearch}
          isLoading={isLoading}
          isRefetching={isRefetching}
          isLoaded={Boolean(data)}
          clearSearch={clearSearch}
          selectedConcepts={selectedConcepts}
          setSelectedConcepts={setSelectedConcepts}
          setIsUsingSearchEndpoint={setIsUsingSearchEndpoint}
          setDatasetViewAssetType={setDatasetViewAssetType}
          isUsingSearchEndpoint={isUsingSearchEndpoint}
          searchActive={searchActive}
          metadataFilters={metadataFilters}
          setMetadataFilters={setMetadataFilters}
          datasetViewAssetType={datasetViewAssetType}
          textQuery={textQuery}
          setTextQuery={setTextQuery}
          dataTestId={dataTestId}
        />
        {data && (
          <TextSearchResults
            onClick={onClick}
            results={data}
            isLoading={isLoading}
            isRefetching={isRefetching}
            clearSearch={clearSearch}
            hideHeader={hideHeader}
            labelResults={labelResults}
            isLabelingResults={isLabelingResults}
            hasToggleGridResults={hasToggleGridResults}
            disabledResults={disabledResults}
            disabledResultMessage={disabledResultMessage}
            dataTestId={`${dataTestId}-results`}
          />
        )}
      </>
    );
  };

DatasetTextSearch.defaultProps = {
  searchActive: false,
  onClick: undefined,
  setSearchActive: undefined,
  defaultAssetType: undefined,
  labelResults: undefined,
  isLabelingResults: false,
  hideHeader: false,
  searchContainerClassName: undefined,
  setIsUsingSearchEndpoint: undefined,
  setDatasetViewAssetType: undefined,
  isUsingSearchEndpoint: false,
  datasetViewAssetType: undefined,
  hasToggleGridResults: undefined,
  disabledResultMessage: undefined,
  disabledResults: undefined,
  dataTestId: undefined,
};

export default DatasetTextSearch;
