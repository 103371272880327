/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppSharedV1SchemaPagedLinksResponse,
    AppSharedV1SchemaPagedLinksResponseFromJSON,
    AppSharedV1SchemaPagedLinksResponseFromJSONTyped,
    AppSharedV1SchemaPagedLinksResponseToJSON,
} from './AppSharedV1SchemaPagedLinksResponse';
import {
    AppSharedV1SchemaPagedPageDetailsResponse,
    AppSharedV1SchemaPagedPageDetailsResponseFromJSON,
    AppSharedV1SchemaPagedPageDetailsResponseFromJSONTyped,
    AppSharedV1SchemaPagedPageDetailsResponseToJSON,
} from './AppSharedV1SchemaPagedPageDetailsResponse';
import {
    SortItem,
    SortItemFromJSON,
    SortItemFromJSONTyped,
    SortItemToJSON,
} from './SortItem';

/**
 * Metadata about the paginated response
 * @export
 * @interface AppSharedV1SchemaPagedMetaResponse
 */
export interface AppSharedV1SchemaPagedMetaResponse {
    /**
     * 
     * @type {AppSharedV1SchemaPagedPageDetailsResponse}
     * @memberof AppSharedV1SchemaPagedMetaResponse
     */
    page: AppSharedV1SchemaPagedPageDetailsResponse;
    /**
     * 
     * @type {AppSharedV1SchemaPagedLinksResponse}
     * @memberof AppSharedV1SchemaPagedMetaResponse
     */
    links: AppSharedV1SchemaPagedLinksResponse;
    /**
     * Ordered list of fields to sort on, order of list determines priority
     * @type {Array<SortItem>}
     * @memberof AppSharedV1SchemaPagedMetaResponse
     */
    sort: Array<SortItem>;
}

export function AppSharedV1SchemaPagedMetaResponseFromJSON(json: any): AppSharedV1SchemaPagedMetaResponse {
    return AppSharedV1SchemaPagedMetaResponseFromJSONTyped(json, false);
}

export function AppSharedV1SchemaPagedMetaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppSharedV1SchemaPagedMetaResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': AppSharedV1SchemaPagedPageDetailsResponseFromJSON(json['page']),
        'links': AppSharedV1SchemaPagedLinksResponseFromJSON(json['links']),
        'sort': ((json['sort'] as Array<any>).map(SortItemFromJSON)),
    };
}

export function AppSharedV1SchemaPagedMetaResponseToJSON(value?: AppSharedV1SchemaPagedMetaResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': AppSharedV1SchemaPagedPageDetailsResponseToJSON(value.page),
        'links': AppSharedV1SchemaPagedLinksResponseToJSON(value.links),
        'sort': ((value.sort as Array<any>).map(SortItemToJSON)),
    };
}

