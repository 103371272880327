/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DatasetQuerySearchParametersResponse,
  DatasetSearchRequest,
  ErrorResponse,
  HTTPValidationError,
  NoCodeRunQueryRequest,
  QueryListResponseUI,
  QueryRequestUI,
  QueryResponseUI,
  QueryResultResponseUI,
  QueryResultsDownloadUrlResponse,
  QueryType,
  SearchResponseUI,
} from '../models';
import {
    DatasetQuerySearchParametersResponseFromJSON,
    DatasetQuerySearchParametersResponseToJSON,
    DatasetSearchRequestFromJSON,
    DatasetSearchRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    NoCodeRunQueryRequestFromJSON,
    NoCodeRunQueryRequestToJSON,
    QueryListResponseUIFromJSON,
    QueryListResponseUIToJSON,
    QueryRequestUIFromJSON,
    QueryRequestUIToJSON,
    QueryResponseUIFromJSON,
    QueryResponseUIToJSON,
    QueryResultResponseUIFromJSON,
    QueryResultResponseUIToJSON,
    QueryResultsDownloadUrlResponseFromJSON,
    QueryResultsDownloadUrlResponseToJSON,
    QueryTypeFromJSON,
    QueryTypeToJSON,
    SearchResponseUIFromJSON,
    SearchResponseUIToJSON,
} from '../models';

export interface DeleteQueryByIdRequest {
    queryId: string;
}

export interface ExecuteNoCodeQueryRequest {
    noCodeRunQueryRequest: NoCodeRunQueryRequest;
}

export interface ExecuteQueryRequest {
    queryRequestUI: QueryRequestUI;
}

export interface GetQueriesRequest {
    offset?: number | null;
    limit?: number | null;
    ids?: Array<string> | null;
    userIds?: Array<string> | null;
    excludeUserIds?: Array<string> | null;
    datasetId?: string | null;
    excludeNoCode?: boolean | null;
    excludeCode?: boolean | null;
    queryTypes?: Array<QueryType> | null;
}

export interface GetQueryByIdRequest {
    queryId: string;
    offset?: number | null;
    limit?: number | null;
    excludeResults?: boolean | null;
}

export interface GetQueryResultsCsvRequest {
    queryId: string;
    offset?: number | null;
    limit?: number | null;
}

export interface GetQueryResultsCsvDownloadUrlRequest {
    queryId: string;
}

export interface GetQueryableColumnsRequest {
    datasetId: string;
}

export interface SearchDatasetRequest {
    datasetSearchRequest: DatasetSearchRequest;
}

export interface StopQueryExecutionRequest {
    queryId: string;
}

/**
 * QueryApi - interface
 * 
 * @export
 * @interface QueryApiInterface
 */
export interface QueryApiInterface {
    /**
     * Delete query by id
     * @summary Delete query by id
     * @param {string} queryId The unique identifier for the query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryApiInterface
     */
    deleteQueryByIdRaw(requestParameters: DeleteQueryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Delete query by id
     * Delete query by id
     */
    deleteQueryById(requestParameters: DeleteQueryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Create and enqueue the execution of a SQL query
     * @summary Create and enqueue a SQL query
     * @param {NoCodeRunQueryRequest} noCodeRunQueryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryApiInterface
     */
    executeNoCodeQueryRaw(requestParameters: ExecuteNoCodeQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryResponseUI>>;

    /**
     * Create and enqueue the execution of a SQL query
     * Create and enqueue a SQL query
     */
    executeNoCodeQuery(requestParameters: ExecuteNoCodeQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryResponseUI>;

    /**
     * Create and enqueue the execution of a SQL query
     * @summary Create and enqueue a SQL query
     * @param {QueryRequestUI} queryRequestUI 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryApiInterface
     */
    executeQueryRaw(requestParameters: ExecuteQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryResponseUI>>;

    /**
     * Create and enqueue the execution of a SQL query
     * Create and enqueue a SQL query
     */
    executeQuery(requestParameters: ExecuteQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryResponseUI>;

    /**
     * Get paginated list of queries. If query ids are provided, the returned list will include only those queries. Queries not found from the provided ids list will be ignored.
     * @summary Get paginated list of queries
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {Array<string>} [ids] Query ids to include in the result
     * @param {Array<string>} [userIds] Creator of the queries to return
     * @param {Array<string>} [excludeUserIds] Creator of the queries to return
     * @param {string} [datasetId] Dataset id
     * @param {boolean} [excludeNoCode] Exclude all no code queries
     * @param {boolean} [excludeCode] Exclude all SQL queries
     * @param {Array<QueryType>} [queryTypes] Types of queries to include
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryApiInterface
     */
    getQueriesRaw(requestParameters: GetQueriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryListResponseUI>>;

    /**
     * Get paginated list of queries. If query ids are provided, the returned list will include only those queries. Queries not found from the provided ids list will be ignored.
     * Get paginated list of queries
     */
    getQueries(requestParameters: GetQueriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryListResponseUI>;

    /**
     * Get query by id including paginated query results if the query execution is complete
     * @summary Get query by id, including results
     * @param {string} queryId The unique identifier for the query
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {boolean} [excludeResults] Optionally exclude results from the response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryApiInterface
     */
    getQueryByIdRaw(requestParameters: GetQueryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryResultResponseUI>>;

    /**
     * Get query by id including paginated query results if the query execution is complete
     * Get query by id, including results
     */
    getQueryById(requestParameters: GetQueryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryResultResponseUI>;

    /**
     * Get query results in CSV format
     * @summary Get query results in CSV format
     * @param {string} queryId The unique identifier for the query
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryApiInterface
     */
    getQueryResultsCsvRaw(requestParameters: GetQueryResultsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Get query results in CSV format
     * Get query results in CSV format
     */
    getQueryResultsCsv(requestParameters: GetQueryResultsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Get query results download url
     * @summary Get a url to download query results in CSV format
     * @param {string} queryId The unique identifier for the query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryApiInterface
     */
    getQueryResultsCsvDownloadUrlRaw(requestParameters: GetQueryResultsCsvDownloadUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryResultsDownloadUrlResponse>>;

    /**
     * Get query results download url
     * Get a url to download query results in CSV format
     */
    getQueryResultsCsvDownloadUrl(requestParameters: GetQueryResultsCsvDownloadUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryResultsDownloadUrlResponse>;

    /**
     * Get queryable columns for dataset (assumes embedding with the most concepts)
     * @summary Get queryable columns for dataset (assumes embedding with the most concepts)
     * @param {string} datasetId The unique identifier for the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryApiInterface
     */
    getQueryableColumnsRaw(requestParameters: GetQueryableColumnsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetQuerySearchParametersResponse>>;

    /**
     * Get queryable columns for dataset (assumes embedding with the most concepts)
     * Get queryable columns for dataset (assumes embedding with the most concepts)
     */
    getQueryableColumns(requestParameters: GetQueryableColumnsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetQuerySearchParametersResponse>;

    /**
     * Execute a dataset search
     * @summary Execute a dataset search
     * @param {DatasetSearchRequest} datasetSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryApiInterface
     */
    searchDatasetRaw(requestParameters: SearchDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchResponseUI>>;

    /**
     * Execute a dataset search
     * Execute a dataset search
     */
    searchDataset(requestParameters: SearchDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchResponseUI>;

    /**
     * Stop query execution
     * @summary Stop query execution
     * @param {string} queryId The unique identifier for the query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryApiInterface
     */
    stopQueryExecutionRaw(requestParameters: StopQueryExecutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryResponseUI>>;

    /**
     * Stop query execution
     * Stop query execution
     */
    stopQueryExecution(requestParameters: StopQueryExecutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryResponseUI>;

}

/**
 * 
 */
export class QueryApi extends runtime.BaseAPI implements QueryApiInterface {

    /**
     * Delete query by id
     * Delete query by id
     */
    async deleteQueryByIdRaw(requestParameters: DeleteQueryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.queryId === null || requestParameters.queryId === undefined) {
            throw new runtime.RequiredError('queryId','Required parameter requestParameters.queryId was null or undefined when calling deleteQueryById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/queries/{query_id}`.replace(`{${"query_id"}}`, encodeURIComponent(String(requestParameters.queryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete query by id
     * Delete query by id
     */
    async deleteQueryById(requestParameters: DeleteQueryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteQueryByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create and enqueue the execution of a SQL query
     * Create and enqueue a SQL query
     */
    async executeNoCodeQueryRaw(requestParameters: ExecuteNoCodeQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryResponseUI>> {
        if (requestParameters.noCodeRunQueryRequest === null || requestParameters.noCodeRunQueryRequest === undefined) {
            throw new runtime.RequiredError('noCodeRunQueryRequest','Required parameter requestParameters.noCodeRunQueryRequest was null or undefined when calling executeNoCodeQuery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/queries/no-code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NoCodeRunQueryRequestToJSON(requestParameters.noCodeRunQueryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryResponseUIFromJSON(jsonValue));
    }

    /**
     * Create and enqueue the execution of a SQL query
     * Create and enqueue a SQL query
     */
    async executeNoCodeQuery(requestParameters: ExecuteNoCodeQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryResponseUI> {
        const response = await this.executeNoCodeQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create and enqueue the execution of a SQL query
     * Create and enqueue a SQL query
     */
    async executeQueryRaw(requestParameters: ExecuteQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryResponseUI>> {
        if (requestParameters.queryRequestUI === null || requestParameters.queryRequestUI === undefined) {
            throw new runtime.RequiredError('queryRequestUI','Required parameter requestParameters.queryRequestUI was null or undefined when calling executeQuery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/queries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryRequestUIToJSON(requestParameters.queryRequestUI),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryResponseUIFromJSON(jsonValue));
    }

    /**
     * Create and enqueue the execution of a SQL query
     * Create and enqueue a SQL query
     */
    async executeQuery(requestParameters: ExecuteQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryResponseUI> {
        const response = await this.executeQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get paginated list of queries. If query ids are provided, the returned list will include only those queries. Queries not found from the provided ids list will be ignored.
     * Get paginated list of queries
     */
    async getQueriesRaw(requestParameters: GetQueriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryListResponseUI>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.userIds) {
            queryParameters['user_ids'] = requestParameters.userIds;
        }

        if (requestParameters.excludeUserIds) {
            queryParameters['exclude_user_ids'] = requestParameters.excludeUserIds;
        }

        if (requestParameters.datasetId !== undefined) {
            queryParameters['dataset_id'] = requestParameters.datasetId;
        }

        if (requestParameters.excludeNoCode !== undefined) {
            queryParameters['exclude_no_code'] = requestParameters.excludeNoCode;
        }

        if (requestParameters.excludeCode !== undefined) {
            queryParameters['exclude_code'] = requestParameters.excludeCode;
        }

        if (requestParameters.queryTypes) {
            queryParameters['query_types'] = requestParameters.queryTypes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/queries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryListResponseUIFromJSON(jsonValue));
    }

    /**
     * Get paginated list of queries. If query ids are provided, the returned list will include only those queries. Queries not found from the provided ids list will be ignored.
     * Get paginated list of queries
     */
    async getQueries(requestParameters: GetQueriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryListResponseUI> {
        const response = await this.getQueriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get query by id including paginated query results if the query execution is complete
     * Get query by id, including results
     */
    async getQueryByIdRaw(requestParameters: GetQueryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryResultResponseUI>> {
        if (requestParameters.queryId === null || requestParameters.queryId === undefined) {
            throw new runtime.RequiredError('queryId','Required parameter requestParameters.queryId was null or undefined when calling getQueryById.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.excludeResults !== undefined) {
            queryParameters['exclude_results'] = requestParameters.excludeResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/queries/{query_id}`.replace(`{${"query_id"}}`, encodeURIComponent(String(requestParameters.queryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryResultResponseUIFromJSON(jsonValue));
    }

    /**
     * Get query by id including paginated query results if the query execution is complete
     * Get query by id, including results
     */
    async getQueryById(requestParameters: GetQueryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryResultResponseUI> {
        const response = await this.getQueryByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get query results in CSV format
     * Get query results in CSV format
     */
    async getQueryResultsCsvRaw(requestParameters: GetQueryResultsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.queryId === null || requestParameters.queryId === undefined) {
            throw new runtime.RequiredError('queryId','Required parameter requestParameters.queryId was null or undefined when calling getQueryResultsCsv.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/queries/{query_id}/csv`.replace(`{${"query_id"}}`, encodeURIComponent(String(requestParameters.queryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get query results in CSV format
     * Get query results in CSV format
     */
    async getQueryResultsCsv(requestParameters: GetQueryResultsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getQueryResultsCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get query results download url
     * Get a url to download query results in CSV format
     */
    async getQueryResultsCsvDownloadUrlRaw(requestParameters: GetQueryResultsCsvDownloadUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryResultsDownloadUrlResponse>> {
        if (requestParameters.queryId === null || requestParameters.queryId === undefined) {
            throw new runtime.RequiredError('queryId','Required parameter requestParameters.queryId was null or undefined when calling getQueryResultsCsvDownloadUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/queries/{query_id}/csv/url`.replace(`{${"query_id"}}`, encodeURIComponent(String(requestParameters.queryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryResultsDownloadUrlResponseFromJSON(jsonValue));
    }

    /**
     * Get query results download url
     * Get a url to download query results in CSV format
     */
    async getQueryResultsCsvDownloadUrl(requestParameters: GetQueryResultsCsvDownloadUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryResultsDownloadUrlResponse> {
        const response = await this.getQueryResultsCsvDownloadUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get queryable columns for dataset (assumes embedding with the most concepts)
     * Get queryable columns for dataset (assumes embedding with the most concepts)
     */
    async getQueryableColumnsRaw(requestParameters: GetQueryableColumnsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetQuerySearchParametersResponse>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId','Required parameter requestParameters.datasetId was null or undefined when calling getQueryableColumns.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/queries/{dataset_id}/columns`.replace(`{${"dataset_id"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatasetQuerySearchParametersResponseFromJSON(jsonValue));
    }

    /**
     * Get queryable columns for dataset (assumes embedding with the most concepts)
     * Get queryable columns for dataset (assumes embedding with the most concepts)
     */
    async getQueryableColumns(requestParameters: GetQueryableColumnsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetQuerySearchParametersResponse> {
        const response = await this.getQueryableColumnsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Execute a dataset search
     * Execute a dataset search
     */
    async searchDatasetRaw(requestParameters: SearchDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchResponseUI>> {
        if (requestParameters.datasetSearchRequest === null || requestParameters.datasetSearchRequest === undefined) {
            throw new runtime.RequiredError('datasetSearchRequest','Required parameter requestParameters.datasetSearchRequest was null or undefined when calling searchDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/queries/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DatasetSearchRequestToJSON(requestParameters.datasetSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchResponseUIFromJSON(jsonValue));
    }

    /**
     * Execute a dataset search
     * Execute a dataset search
     */
    async searchDataset(requestParameters: SearchDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchResponseUI> {
        const response = await this.searchDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Stop query execution
     * Stop query execution
     */
    async stopQueryExecutionRaw(requestParameters: StopQueryExecutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryResponseUI>> {
        if (requestParameters.queryId === null || requestParameters.queryId === undefined) {
            throw new runtime.RequiredError('queryId','Required parameter requestParameters.queryId was null or undefined when calling stopQueryExecution.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/queries/{query_id}/stop`.replace(`{${"query_id"}}`, encodeURIComponent(String(requestParameters.queryId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryResponseUIFromJSON(jsonValue));
    }

    /**
     * Stop query execution
     * Stop query execution
     */
    async stopQueryExecution(requestParameters: StopQueryExecutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryResponseUI> {
        const response = await this.stopQueryExecutionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
