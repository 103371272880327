/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface SDKCredentialResponse
 */
export interface SDKCredentialResponse {
    /**
     * The API client id
     * @type {string}
     * @memberof SDKCredentialResponse
     */
    clientId: string;
    /**
     * The API client secret
     * @type {string}
     * @memberof SDKCredentialResponse
     */
    clientSecret: string;
    /**
     * Audience
     * @type {string}
     * @memberof SDKCredentialResponse
     */
    audience: string;
}

export function SDKCredentialResponseFromJSON(json: any): SDKCredentialResponse {
    return SDKCredentialResponseFromJSONTyped(json, false);
}

export function SDKCredentialResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SDKCredentialResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'clientSecret': json['clientSecret'],
        'audience': json['audience'],
    };
}

export function SDKCredentialResponseToJSON(value?: SDKCredentialResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'clientSecret': value.clientSecret,
        'audience': value.audience,
    };
}

