import { Concept, ConceptParameter, MetadataParameter } from 'api/generated';

export type Classification = 'Positive' | 'Negative';

export enum ConceptFilterType {
  Positive = 'positive',
  Negative = 'negative',
  ProbGreaterThan = 'probability greater than',
  ProbLessThan = 'probability less than',
  ProbBetween = 'probability between',
}

export interface ConceptData
  extends Omit<Concept, 'id' | 'name' | 'threshold'>,
    ConceptParameter {
  type?: ConceptFilterType;
}

export interface MetadataData extends MetadataParameter {}

export type FilterType = 'Concept' | 'Metadata';

export interface FilterCondition {
  id: string;
  concepts?: ConceptData[];
  metadataKeys?: MetadataData[];
}

export type SearchMode = 'advanced-visual' | 'visual' | 'audio';
