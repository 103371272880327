/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CandidatesToLabelResponse,
  ErrorResponse,
  HTTPValidationError,
  LabelEnum,
  PagedConceptVersionLabelsResponse,
  PagedDataResponseAssetResponse,
  SimilarLabelCandidatesResponse,
  UpdateConceptVersionLabelsRequest,
  UploadResponse,
} from '../models';
import {
    CandidatesToLabelResponseFromJSON,
    CandidatesToLabelResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    LabelEnumFromJSON,
    LabelEnumToJSON,
    PagedConceptVersionLabelsResponseFromJSON,
    PagedConceptVersionLabelsResponseToJSON,
    PagedDataResponseAssetResponseFromJSON,
    PagedDataResponseAssetResponseToJSON,
    SimilarLabelCandidatesResponseFromJSON,
    SimilarLabelCandidatesResponseToJSON,
    UpdateConceptVersionLabelsRequestFromJSON,
    UpdateConceptVersionLabelsRequestToJSON,
    UploadResponseFromJSON,
    UploadResponseToJSON,
} from '../models';

export interface AddConceptVersionLabelsFromCsvRequest {
    conceptVersionId: string;
    file: Blob;
    dzuuid?: string | null;
    dzchunkindex?: number | null;
    dzchunkbyteoffset?: number | null;
    dztotalchunkcount?: number | null;
    dztotalfilesize?: number | null;
}

export interface GetCandidatesFromUploadsRequest {
    conceptVersionId: string;
    filenames: Array<string>;
}

export interface GetConceptVersionLabelsRequest {
    conceptVersionId: string;
    offset?: number | null;
    limit?: number | null;
    labelsToInclude?: Array<LabelEnum> | null;
}

export interface GetConceptVersionLabelsForImageRequest {
    coactiveImageId: string;
    offset?: number | null;
    limit?: number | null;
    conceptVersionsToInclude?: Array<string> | null;
}

export interface GetLabelCandidatesByImagePathsRequest {
    conceptVersionId: string;
    imagePaths: Array<string>;
    offset?: number | null;
    limit?: number | null;
}

export interface GetLabelCandidatesForConceptIdRequest {
    conceptId: string;
    limit?: number | null;
}

export interface GetLabelCandidatesForConceptVersionIdRequest {
    conceptVersionId: string;
    limit?: number | null;
}

export interface UpdateConceptVersionLabelsOperationRequest {
    conceptVersionId: string;
    updateConceptVersionLabelsRequest: UpdateConceptVersionLabelsRequest;
}

export interface UpdateLabelsWithConceptIdRequest {
    conceptId: string;
    updateConceptVersionLabelsRequest: UpdateConceptVersionLabelsRequest;
}

export interface UploadImageForSimilaritySearchRequest {
    conceptVersionId: string;
    file: Blob;
    dzuuid?: string | null;
    dzchunkindex?: number | null;
    dzchunkbyteoffset?: number | null;
    dztotalchunkcount?: number | null;
    dztotalfilesize?: number | null;
}

/**
 * LabelApi - interface
 * 
 * @export
 * @interface LabelApiInterface
 */
export interface LabelApiInterface {
    /**
     * Add labels to concept from CSV
     * @summary Add labels to concept from CSV
     * @param {string} conceptVersionId The concept version id of the labels to upload
     * @param {Blob} file CSV file containing labels to add to concept
     * @param {string} [dzuuid] Chunked upload id
     * @param {number} [dzchunkindex] Chunked upload index of chunk
     * @param {number} [dzchunkbyteoffset] Chunked upload bytes offset of chunk
     * @param {number} [dztotalchunkcount] Chunked upload total number of chunks
     * @param {number} [dztotalfilesize] Chunked upload total file size
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LabelApiInterface
     */
    addConceptVersionLabelsFromCsvRaw(requestParameters: AddConceptVersionLabelsFromCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Add labels to concept from CSV
     * Add labels to concept from CSV
     */
    addConceptVersionLabelsFromCsv(requestParameters: AddConceptVersionLabelsFromCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Obtain candidates for labelling from already uploaded images
     * @summary Obtain candidates for labelling from already uploaded images
     * @param {string} conceptVersionId The concept version id of the labels to upload
     * @param {Array<string>} filenames The names of the files to use as seeds for candidate retrieval
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApiInterface
     */
    getCandidatesFromUploadsRaw(requestParameters: GetCandidatesFromUploadsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimilarLabelCandidatesResponse>>;

    /**
     * Obtain candidates for labelling from already uploaded images
     * Obtain candidates for labelling from already uploaded images
     */
    getCandidatesFromUploads(requestParameters: GetCandidatesFromUploadsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimilarLabelCandidatesResponse>;

    /**
     * Get labels for the concept version
     * @summary Get labels for the concept version
     * @param {string} conceptVersionId The concept version id
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {Array<LabelEnum>} [labelsToInclude] List of labels types to include, leave blank to return all
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApiInterface
     */
    getConceptVersionLabelsRaw(requestParameters: GetConceptVersionLabelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedConceptVersionLabelsResponse>>;

    /**
     * Get labels for the concept version
     * Get labels for the concept version
     */
    getConceptVersionLabels(requestParameters: GetConceptVersionLabelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedConceptVersionLabelsResponse>;

    /**
     * Get the concept version labels for an image
     * @summary Get the concept version labels for an image
     * @param {string} coactiveImageId Coactive image id of the labels to return
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {Array<string>} [conceptVersionsToInclude] Concept versions to include in the list response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApiInterface
     */
    getConceptVersionLabelsForImageRaw(requestParameters: GetConceptVersionLabelsForImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedConceptVersionLabelsResponse>>;

    /**
     * Get the concept version labels for an image
     * Get the concept version labels for an image
     */
    getConceptVersionLabelsForImage(requestParameters: GetConceptVersionLabelsForImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedConceptVersionLabelsResponse>;

    /**
     * Returns top candidates to label paginated
     * @summary Returns top candidates to label paginated
     * @param {string} conceptVersionId The concept version id to get candidates to label
     * @param {Array<string>} imagePaths The image path to the image to get similar images
     * @param {number} [offset] Starting index to return
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LabelApiInterface
     */
    getLabelCandidatesByImagePathsRaw(requestParameters: GetLabelCandidatesByImagePathsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedDataResponseAssetResponse>>;

    /**
     * Returns top candidates to label paginated
     * Returns top candidates to label paginated
     */
    getLabelCandidatesByImagePaths(requestParameters: GetLabelCandidatesByImagePathsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedDataResponseAssetResponse>;

    /**
     * Get candidates to label to help refine the latest concept version
     * @summary Get candidates to label for the concept version
     * @param {string} conceptId The concept id to get label candidates for
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApiInterface
     */
    getLabelCandidatesForConceptIdRaw(requestParameters: GetLabelCandidatesForConceptIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CandidatesToLabelResponse>>;

    /**
     * Get candidates to label to help refine the latest concept version
     * Get candidates to label for the concept version
     */
    getLabelCandidatesForConceptId(requestParameters: GetLabelCandidatesForConceptIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CandidatesToLabelResponse>;

    /**
     * Get candidates to label to help refine the concept version
     * @summary Get candidates to label for the concept version
     * @param {string} conceptVersionId The concept version id to get candidates to label
     * @param {number} [limit] Max number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApiInterface
     */
    getLabelCandidatesForConceptVersionIdRaw(requestParameters: GetLabelCandidatesForConceptVersionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CandidatesToLabelResponse>>;

    /**
     * Get candidates to label to help refine the concept version
     * Get candidates to label for the concept version
     */
    getLabelCandidatesForConceptVersionId(requestParameters: GetLabelCandidatesForConceptVersionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CandidatesToLabelResponse>;

    /**
     * Update labels of a concept version
     * @summary Update labels of a concept version
     * @param {string} conceptVersionId The concept version id of the labels to modify
     * @param {UpdateConceptVersionLabelsRequest} updateConceptVersionLabelsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApiInterface
     */
    updateConceptVersionLabelsRaw(requestParameters: UpdateConceptVersionLabelsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Update labels of a concept version
     * Update labels of a concept version
     */
    updateConceptVersionLabels(requestParameters: UpdateConceptVersionLabelsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Update labels with concept id
     * @summary Update Labels With Concept Id
     * @param {string} conceptId The concept id of the labels to modify
     * @param {UpdateConceptVersionLabelsRequest} updateConceptVersionLabelsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelApiInterface
     */
    updateLabelsWithConceptIdRaw(requestParameters: UpdateLabelsWithConceptIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>>;

    /**
     * Update labels with concept id
     * Update Labels With Concept Id
     */
    updateLabelsWithConceptId(requestParameters: UpdateLabelsWithConceptIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any>;

    /**
     * Upload an image to later use it to perform similarity search
     * @summary Upload an image for similarity search
     * @param {string} conceptVersionId The concept version id of the labels to upload
     * @param {Blob} file CSV file containing labels to add to concept
     * @param {string} [dzuuid] Chunked upload id
     * @param {number} [dzchunkindex] Chunked upload index of chunk
     * @param {number} [dzchunkbyteoffset] Chunked upload bytes offset of chunk
     * @param {number} [dztotalchunkcount] Chunked upload total number of chunks
     * @param {number} [dztotalfilesize] Chunked upload total file size
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LabelApiInterface
     */
    uploadImageForSimilaritySearchRaw(requestParameters: UploadImageForSimilaritySearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadResponse>>;

    /**
     * Upload an image to later use it to perform similarity search
     * Upload an image for similarity search
     */
    uploadImageForSimilaritySearch(requestParameters: UploadImageForSimilaritySearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadResponse>;

}

/**
 * 
 */
export class LabelApi extends runtime.BaseAPI implements LabelApiInterface {

    /**
     * Add labels to concept from CSV
     * Add labels to concept from CSV
     */
    async addConceptVersionLabelsFromCsvRaw(requestParameters: AddConceptVersionLabelsFromCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.conceptVersionId === null || requestParameters.conceptVersionId === undefined) {
            throw new runtime.RequiredError('conceptVersionId','Required parameter requestParameters.conceptVersionId was null or undefined when calling addConceptVersionLabelsFromCsv.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addConceptVersionLabelsFromCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.dzuuid !== undefined) {
            formParams.append('dzuuid', requestParameters.dzuuid as any);
        }

        if (requestParameters.dzchunkindex !== undefined) {
            formParams.append('dzchunkindex', requestParameters.dzchunkindex as any);
        }

        if (requestParameters.dzchunkbyteoffset !== undefined) {
            formParams.append('dzchunkbyteoffset', requestParameters.dzchunkbyteoffset as any);
        }

        if (requestParameters.dztotalchunkcount !== undefined) {
            formParams.append('dztotalchunkcount', requestParameters.dztotalchunkcount as any);
        }

        if (requestParameters.dztotalfilesize !== undefined) {
            formParams.append('dztotalfilesize', requestParameters.dztotalfilesize as any);
        }

        const response = await this.request({
            path: `/api/ui/versions/{concept_version_id}/labels/upload/csv`.replace(`{${"concept_version_id"}}`, encodeURIComponent(String(requestParameters.conceptVersionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Add labels to concept from CSV
     * Add labels to concept from CSV
     */
    async addConceptVersionLabelsFromCsv(requestParameters: AddConceptVersionLabelsFromCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.addConceptVersionLabelsFromCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Obtain candidates for labelling from already uploaded images
     * Obtain candidates for labelling from already uploaded images
     */
    async getCandidatesFromUploadsRaw(requestParameters: GetCandidatesFromUploadsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimilarLabelCandidatesResponse>> {
        if (requestParameters.conceptVersionId === null || requestParameters.conceptVersionId === undefined) {
            throw new runtime.RequiredError('conceptVersionId','Required parameter requestParameters.conceptVersionId was null or undefined when calling getCandidatesFromUploads.');
        }

        if (requestParameters.filenames === null || requestParameters.filenames === undefined) {
            throw new runtime.RequiredError('filenames','Required parameter requestParameters.filenames was null or undefined when calling getCandidatesFromUploads.');
        }

        const queryParameters: any = {};

        if (requestParameters.filenames) {
            queryParameters['filenames'] = requestParameters.filenames;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/versions/{concept_version_id}/labels/candidates_from_uploads`.replace(`{${"concept_version_id"}}`, encodeURIComponent(String(requestParameters.conceptVersionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimilarLabelCandidatesResponseFromJSON(jsonValue));
    }

    /**
     * Obtain candidates for labelling from already uploaded images
     * Obtain candidates for labelling from already uploaded images
     */
    async getCandidatesFromUploads(requestParameters: GetCandidatesFromUploadsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimilarLabelCandidatesResponse> {
        const response = await this.getCandidatesFromUploadsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get labels for the concept version
     * Get labels for the concept version
     */
    async getConceptVersionLabelsRaw(requestParameters: GetConceptVersionLabelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedConceptVersionLabelsResponse>> {
        if (requestParameters.conceptVersionId === null || requestParameters.conceptVersionId === undefined) {
            throw new runtime.RequiredError('conceptVersionId','Required parameter requestParameters.conceptVersionId was null or undefined when calling getConceptVersionLabels.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.labelsToInclude) {
            queryParameters['labels_to_include'] = requestParameters.labelsToInclude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/versions/{concept_version_id}/labels`.replace(`{${"concept_version_id"}}`, encodeURIComponent(String(requestParameters.conceptVersionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedConceptVersionLabelsResponseFromJSON(jsonValue));
    }

    /**
     * Get labels for the concept version
     * Get labels for the concept version
     */
    async getConceptVersionLabels(requestParameters: GetConceptVersionLabelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedConceptVersionLabelsResponse> {
        const response = await this.getConceptVersionLabelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the concept version labels for an image
     * Get the concept version labels for an image
     */
    async getConceptVersionLabelsForImageRaw(requestParameters: GetConceptVersionLabelsForImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedConceptVersionLabelsResponse>> {
        if (requestParameters.coactiveImageId === null || requestParameters.coactiveImageId === undefined) {
            throw new runtime.RequiredError('coactiveImageId','Required parameter requestParameters.coactiveImageId was null or undefined when calling getConceptVersionLabelsForImage.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.conceptVersionsToInclude) {
            queryParameters['concept_versions_to_include'] = requestParameters.conceptVersionsToInclude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/images/{coactive_image_id}/labels`.replace(`{${"coactive_image_id"}}`, encodeURIComponent(String(requestParameters.coactiveImageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedConceptVersionLabelsResponseFromJSON(jsonValue));
    }

    /**
     * Get the concept version labels for an image
     * Get the concept version labels for an image
     */
    async getConceptVersionLabelsForImage(requestParameters: GetConceptVersionLabelsForImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedConceptVersionLabelsResponse> {
        const response = await this.getConceptVersionLabelsForImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns top candidates to label paginated
     * Returns top candidates to label paginated
     */
    async getLabelCandidatesByImagePathsRaw(requestParameters: GetLabelCandidatesByImagePathsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedDataResponseAssetResponse>> {
        if (requestParameters.conceptVersionId === null || requestParameters.conceptVersionId === undefined) {
            throw new runtime.RequiredError('conceptVersionId','Required parameter requestParameters.conceptVersionId was null or undefined when calling getLabelCandidatesByImagePaths.');
        }

        if (requestParameters.imagePaths === null || requestParameters.imagePaths === undefined) {
            throw new runtime.RequiredError('imagePaths','Required parameter requestParameters.imagePaths was null or undefined when calling getLabelCandidatesByImagePaths.');
        }

        const queryParameters: any = {};

        if (requestParameters.imagePaths) {
            queryParameters['image_paths'] = requestParameters.imagePaths;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/versions/{concept_version_id}/simsearch`.replace(`{${"concept_version_id"}}`, encodeURIComponent(String(requestParameters.conceptVersionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedDataResponseAssetResponseFromJSON(jsonValue));
    }

    /**
     * Returns top candidates to label paginated
     * Returns top candidates to label paginated
     */
    async getLabelCandidatesByImagePaths(requestParameters: GetLabelCandidatesByImagePathsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedDataResponseAssetResponse> {
        const response = await this.getLabelCandidatesByImagePathsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get candidates to label to help refine the latest concept version
     * Get candidates to label for the concept version
     */
    async getLabelCandidatesForConceptIdRaw(requestParameters: GetLabelCandidatesForConceptIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CandidatesToLabelResponse>> {
        if (requestParameters.conceptId === null || requestParameters.conceptId === undefined) {
            throw new runtime.RequiredError('conceptId','Required parameter requestParameters.conceptId was null or undefined when calling getLabelCandidatesForConceptId.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/concepts/{concept_id}/candidates`.replace(`{${"concept_id"}}`, encodeURIComponent(String(requestParameters.conceptId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidatesToLabelResponseFromJSON(jsonValue));
    }

    /**
     * Get candidates to label to help refine the latest concept version
     * Get candidates to label for the concept version
     */
    async getLabelCandidatesForConceptId(requestParameters: GetLabelCandidatesForConceptIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CandidatesToLabelResponse> {
        const response = await this.getLabelCandidatesForConceptIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get candidates to label to help refine the concept version
     * Get candidates to label for the concept version
     */
    async getLabelCandidatesForConceptVersionIdRaw(requestParameters: GetLabelCandidatesForConceptVersionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CandidatesToLabelResponse>> {
        if (requestParameters.conceptVersionId === null || requestParameters.conceptVersionId === undefined) {
            throw new runtime.RequiredError('conceptVersionId','Required parameter requestParameters.conceptVersionId was null or undefined when calling getLabelCandidatesForConceptVersionId.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/versions/{concept_version_id}/candidates`.replace(`{${"concept_version_id"}}`, encodeURIComponent(String(requestParameters.conceptVersionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CandidatesToLabelResponseFromJSON(jsonValue));
    }

    /**
     * Get candidates to label to help refine the concept version
     * Get candidates to label for the concept version
     */
    async getLabelCandidatesForConceptVersionId(requestParameters: GetLabelCandidatesForConceptVersionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CandidatesToLabelResponse> {
        const response = await this.getLabelCandidatesForConceptVersionIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update labels of a concept version
     * Update labels of a concept version
     */
    async updateConceptVersionLabelsRaw(requestParameters: UpdateConceptVersionLabelsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.conceptVersionId === null || requestParameters.conceptVersionId === undefined) {
            throw new runtime.RequiredError('conceptVersionId','Required parameter requestParameters.conceptVersionId was null or undefined when calling updateConceptVersionLabels.');
        }

        if (requestParameters.updateConceptVersionLabelsRequest === null || requestParameters.updateConceptVersionLabelsRequest === undefined) {
            throw new runtime.RequiredError('updateConceptVersionLabelsRequest','Required parameter requestParameters.updateConceptVersionLabelsRequest was null or undefined when calling updateConceptVersionLabels.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/versions/{concept_version_id}/labels`.replace(`{${"concept_version_id"}}`, encodeURIComponent(String(requestParameters.conceptVersionId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateConceptVersionLabelsRequestToJSON(requestParameters.updateConceptVersionLabelsRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Update labels of a concept version
     * Update labels of a concept version
     */
    async updateConceptVersionLabels(requestParameters: UpdateConceptVersionLabelsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateConceptVersionLabelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update labels with concept id
     * Update Labels With Concept Id
     */
    async updateLabelsWithConceptIdRaw(requestParameters: UpdateLabelsWithConceptIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.conceptId === null || requestParameters.conceptId === undefined) {
            throw new runtime.RequiredError('conceptId','Required parameter requestParameters.conceptId was null or undefined when calling updateLabelsWithConceptId.');
        }

        if (requestParameters.updateConceptVersionLabelsRequest === null || requestParameters.updateConceptVersionLabelsRequest === undefined) {
            throw new runtime.RequiredError('updateConceptVersionLabelsRequest','Required parameter requestParameters.updateConceptVersionLabelsRequest was null or undefined when calling updateLabelsWithConceptId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/concepts/{concept_id}/labels`.replace(`{${"concept_id"}}`, encodeURIComponent(String(requestParameters.conceptId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateConceptVersionLabelsRequestToJSON(requestParameters.updateConceptVersionLabelsRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Update labels with concept id
     * Update Labels With Concept Id
     */
    async updateLabelsWithConceptId(requestParameters: UpdateLabelsWithConceptIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateLabelsWithConceptIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload an image to later use it to perform similarity search
     * Upload an image for similarity search
     */
    async uploadImageForSimilaritySearchRaw(requestParameters: UploadImageForSimilaritySearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadResponse>> {
        if (requestParameters.conceptVersionId === null || requestParameters.conceptVersionId === undefined) {
            throw new runtime.RequiredError('conceptVersionId','Required parameter requestParameters.conceptVersionId was null or undefined when calling uploadImageForSimilaritySearch.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadImageForSimilaritySearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.dzuuid !== undefined) {
            formParams.append('dzuuid', requestParameters.dzuuid as any);
        }

        if (requestParameters.dzchunkindex !== undefined) {
            formParams.append('dzchunkindex', requestParameters.dzchunkindex as any);
        }

        if (requestParameters.dzchunkbyteoffset !== undefined) {
            formParams.append('dzchunkbyteoffset', requestParameters.dzchunkbyteoffset as any);
        }

        if (requestParameters.dztotalchunkcount !== undefined) {
            formParams.append('dztotalchunkcount', requestParameters.dztotalchunkcount as any);
        }

        if (requestParameters.dztotalfilesize !== undefined) {
            formParams.append('dztotalfilesize', requestParameters.dztotalfilesize as any);
        }

        const response = await this.request({
            path: `/api/ui/versions/{concept_version_id}/labels/upload/image`.replace(`{${"concept_version_id"}}`, encodeURIComponent(String(requestParameters.conceptVersionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadResponseFromJSON(jsonValue));
    }

    /**
     * Upload an image to later use it to perform similarity search
     * Upload an image for similarity search
     */
    async uploadImageForSimilaritySearch(requestParameters: UploadImageForSimilaritySearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadResponse> {
        const response = await this.uploadImageForSimilaritySearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
