import Datepicker from '@coactive-steph/react-tailwindcss-datepicker';
import { MetadataData } from 'components/DatasetSearch/AdvancedSearch/types';
import {
  convertLocalDateToUTC,
  convertUTCDateToLocal,
} from 'components/DatasetSearch/utils';
import React from 'react';

// These formats must match
const DAYJS_DISPLAY_FORMAT = 'MMM D, YYYY h:mma';

const DateTimePicker: React.FC<{
  data: MetadataData[];
  onChange: (d: MetadataData[]) => void;
}> = function DateTimePicker({ data, onChange }) {
  const isDateRange = data.length > 1;
  const utcStartDate = data[0].value ?? null;
  const utcEndDate = (isDateRange ? data[1].value : data[0].value) ?? null;
  return (
    <Datepicker
      containerClassName="relative inline-block"
      inputClassName={
        `relative transition-all duration-300 py-2 pr-12 border-gray-300 shadow-sm ` +
        `border-gray-300 rounded-lg tracking-wide text-sm placeholder-gray-500 bg-white focus:border-blue-600 ` +
        `disabled:opacity-40 disabled:cursor-not-allowed focus:border-blue-500 ` +
        `focus:ring-blue-600 box-border ${
          isDateRange ? 'min-w-[24rem]' : 'min-w-[16rem]'
        }`
      }
      toggleClassName={
        'absolute right-0 top-0 h-full px-3 text-gray-400 focus:outline-none ' +
        'disabled:opacity-40 disabled:cursor-not-allowed'
      }
      separator="to"
      showShortcuts
      placeholder={isDateRange ? 'MM/DD/YYYY to MM/DD/YYYY' : 'MM/DD/YYYY'}
      displayFormat={DAYJS_DISPLAY_FORMAT}
      value={{
        startDate: utcStartDate ? convertUTCDateToLocal(utcStartDate) : null,
        endDate: utcEndDate ? convertUTCDateToLocal(utcEndDate) : null,
      }}
      asSingle={!isDateRange}
      useRange={isDateRange}
      onChange={(value) => {
        onChange(
          isDateRange
            ? [
                {
                  ...data[0],
                  value: convertLocalDateToUTC(value?.startDate?.toString()),
                },
                {
                  ...data[1],
                  value: convertLocalDateToUTC(value?.endDate?.toString()),
                },
              ]
            : [
                {
                  ...data[0],
                  value: convertLocalDateToUTC(value?.startDate?.toString()),
                },
              ],
        );
      }}
    />
  );
};

export default DateTimePicker;
