/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Metadata about a video shot
 * @export
 * @interface Shot
 */
export interface Shot {
    /**
     * The unique shot id
     * @type {string}
     * @memberof Shot
     */
    id: string;
    /**
     * The start time in milliseconds from the beginning of the video
     * @type {number}
     * @memberof Shot
     */
    startTimeMs: number;
    /**
     * The end time in milliseconds from the beginning of the video
     * @type {number}
     * @memberof Shot
     */
    endTimeMs: number;
}

export function ShotFromJSON(json: any): Shot {
    return ShotFromJSONTyped(json, false);
}

export function ShotFromJSONTyped(json: any, ignoreDiscriminator: boolean): Shot {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'startTimeMs': json['startTimeMs'],
        'endTimeMs': json['endTimeMs'],
    };
}

export function ShotToJSON(value?: Shot | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'startTimeMs': value.startTimeMs,
        'endTimeMs': value.endTimeMs,
    };
}

