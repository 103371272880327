import React from 'react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  YAxis,
  XAxis,
  LabelList,
} from 'recharts';
import colors from 'tailwindcss/colors';
import {
  BAR_CHART_MARGINS,
  BAR_SIZE,
  LOG_SCALE,
  Y_AXIS_PADDING,
} from './constants';
import PredictionChartCustomTick from './PredictionChartCustomTick';
import PlaceholderLabels from './PlaceholderLabels';

interface ConceptPredictionSkeletonLoaderProps {
  domainEnd: number;
  referenceLinesCoordinates: number[];
}

const ConceptPredictionSkeletonLoader: React.FC<
  ConceptPredictionSkeletonLoaderProps
> = ({ domainEnd, referenceLinesCoordinates }) => {
  const placeholderData = Array(5).fill({
    name: '',
    value: domainEnd,
  });

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight="20rem">
      <BarChart
        data={placeholderData}
        layout="vertical"
        margin={BAR_CHART_MARGINS}
      >
        <XAxis
          axisLine={false}
          scale={LOG_SCALE}
          domain={[1, domainEnd]}
          type="number"
          tickLine={{ stroke: 'white' }}
          interval={0}
          tick={
            <PredictionChartCustomTick
              placeholder
              referenceLinesCoordinates={referenceLinesCoordinates}
            />
          }
        />
        <YAxis
          padding={Y_AXIS_PADDING}
          tickLine={false}
          yAxisId={0}
          tick={false}
          type="category"
        />
        <Bar
          barSize={BAR_SIZE}
          dataKey="value"
          fill={colors.transparent}
          radius={[0, 5, 5, 0]}
        >
          <LabelList
            className="text"
            dataKey="name"
            position="left"
            width={500}
            content={<PlaceholderLabels />}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ConceptPredictionSkeletonLoader;
