/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A classification of an asset for a single concept
 * @export
 * @interface ClassificationScore
 */
export interface ClassificationScore {
    /**
     * The concept name
     * @type {string}
     * @memberof ClassificationScore
     */
    conceptName: string;
    /**
     * The concept id
     * @type {string}
     * @memberof ClassificationScore
     */
    conceptId: string;
    /**
     * The threshold used to determine if it's a positive example
     * @type {number}
     * @memberof ClassificationScore
     */
    threshold: number;
    /**
     * If the sample is classified as positive
     * @type {boolean}
     * @memberof ClassificationScore
     */
    positive: boolean;
    /**
     * The classification score in the range [0, 1]
     * @type {number}
     * @memberof ClassificationScore
     */
    score: number;
}

export function ClassificationScoreFromJSON(json: any): ClassificationScore {
    return ClassificationScoreFromJSONTyped(json, false);
}

export function ClassificationScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassificationScore {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conceptName': json['conceptName'],
        'conceptId': json['conceptId'],
        'threshold': json['threshold'],
        'positive': json['positive'],
        'score': json['score'],
    };
}

export function ClassificationScoreToJSON(value?: ClassificationScore | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conceptName': value.conceptName,
        'conceptId': value.conceptId,
        'threshold': value.threshold,
        'positive': value.positive,
        'score': value.score,
    };
}

