import React, { Fragment, ReactElement } from 'react';
import { Listbox, Portal, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { usePopper } from 'hooks/usePopper';
import { Placement } from '@popperjs/core';

export interface MultiSelectOption {
  value: string;
  key: string;
  label: string;
}

export interface MultiSelectButtonProps {
  options: MultiSelectOption[];
  value: string[];
  onChange: (value: string[]) => void;
  popoverPlacement: Placement;
  buttonLabel: ReactElement;
  buttonClassName?: string;
  srOnlyLabel?: string; // Label only available to screen readers (accessibility)
}

const MultiSelectButton: React.FC<MultiSelectButtonProps> = ({
  options,
  value,
  onChange,
  popoverPlacement,
  buttonLabel,
  buttonClassName,
  srOnlyLabel,
}) => {
  const [trigger, container] = usePopper({
    placement: popoverPlacement,
    strategy: 'fixed',
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
  });

  return (
    <Listbox multiple value={value} onChange={onChange}>
      <>
        <Listbox.Button
          className={classNames(
            'p-1 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-1 focus:ring-green-500',
            buttonClassName,
          )}
          ref={trigger}
        >
          <span className="sr-only">{srOnlyLabel}</span>
          {buttonLabel}
        </Listbox.Button>
        <Portal>
          <div ref={container}>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Listbox.Options className="absolute rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1" id="hello">
                  {options.map((option) => (
                    <Listbox.Option
                      value={option.value}
                      key={option.key}
                      className={classNames()}
                    >
                      {({ selected, active }) => (
                        <div
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'flex justify-start items-center cursor-pointer p-4 px-3 min-w-[8rem] text-sm w-full normal-case',
                          )}
                        >
                          <div className="w-full flex justify-between items-center">
                            {option.label}
                            {selected && (
                              <CheckIcon className="h-4 w-6 ml-0 text-gray-400" />
                            )}
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </div>
              </Listbox.Options>
            </Transition>
          </div>
        </Portal>
      </>
    </Listbox>
  );
};

MultiSelectButton.defaultProps = {
  buttonClassName: undefined,
  srOnlyLabel: undefined,
};

export default MultiSelectButton;
